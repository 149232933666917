import React, { useEffect } from "react";
import { GoogleApiWrapper } from "google-maps-react";

const AddCity = ({ google, stateData, setStateData, formik }) => {
  const searchCities = () => {
    const maps = google?.maps;

    let options = {
      types: ["(cities)"],
      componentRestrictions: { country: "in" },
    };

    let input = document.getElementById("city");
    let autocomplete = new maps.places.Autocomplete(input, options);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      formik.setFieldValue("isCitySelected", true);
      setStateData(place?.name);
      formik.setFieldValue("lat", place.geometry.location.lat());
      formik.setFieldValue("lng", place.geometry.location.lng());
      formik.setFieldValue("locationName", place?.formatted_address);
    });
  };

  useEffect(() => {
    searchCities();
  }, []);

  return (
    <>
      <label htmlFor="city" className="font12x500 form-label mb-1">
        City*
      </label>
      <input
        type="text"
        className="form-control shadow-none"
        id="city"
        name="city"
        autoComplete="off"
        value={stateData}
        onChange={(e) => {
          setStateData(e.target.value);
        }}
        onKeyDown={() => {
          formik.setFieldValue("isCitySelected", false);
        }}
      />
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: `${process.env.REACT_APP_API_MAP}`,
})(AddCity);
