import axios from "axios";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import useAuthContext from "../../hooks/useAuthContext";

import { authHeader, SALON_REMOVE_COUPON } from "../../utils/Constants";
import { CartContext } from "../../utils/Private Routes/MasterSalonPrivateRoutes";
import Loader from "../Loader";

const CouponsList = ({ couponList, checkCoupon, loader }) => {
  const { token } = useAuthContext();
  const { cart, getCart } = useContext(CartContext);

  const functions = {
    removeCouponFromCart: async () => {
      if (token) {
        try {
          const res = await axios.put(
            `${SALON_REMOVE_COUPON}`,
            {},
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            getCart();
            toast.success("Coupon Removed", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
  };

  return (
    <>
      <div className="my-3">
        <div className="d-flex justify-content-between">
          <p className="mb-0 font16x700">Available coupons</p>
          <button
            className="mb-0 font16x700"
            onClick={() => {
              functions?.removeCouponFromCart();
            }}
          >
            REMOVE
          </button>
        </div>

        {couponList?.length !== 0 ? (
          couponList?.map((coupon, i) => {
            return (
              !coupon?.isApplied && (
                <div
                  className="couponsList mt-1 mb-2"
                  key={i}
                  style={{
                    border:
                      cart?.appliedSalonCoupon?._id === coupon?._id &&
                      "1px solid var(--btn_primary)",
                  }}
                >
                  <div className="py-2 px-3">
                    <div
                      className="d-flex justify-content-between align-items-center pb-2"
                      style={{
                        borderBottom: "1px solid #EBEBEB",
                      }}
                    >
                      <p className="mb-0 couponCode font16x500">
                        {coupon?.code}
                      </p>

                      {cart?.appliedSalonCoupon?._id === coupon?._id ? (
                        <button
                          className="mb-0 font14x500 ms-3"
                          style={{
                            color: "var(--btn_primary)",
                            cursor: "no-drop",
                          }}
                        >
                          APPLIED
                        </button>
                      ) : (
                        <button
                          className="mb-0 font14x500 ms-3"
                          style={{
                            color: "var(--btn_primary)",
                          }}
                          onClick={() => {
                            checkCoupon(coupon?.code, coupon?.minValue);
                          }}
                        >
                          APPLY
                        </button>
                      )}
                    </div>
                    <div className="pt-2">
                      <p className="mb-1 font14x400">{coupon?.description}</p>
                      <p className="mb-0 font12x400">{coupon?.terms}</p>
                    </div>
                  </div>
                </div>
              )
            );
          })
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Coupon Found"
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CouponsList;
