import React, { useEffect, useState } from "react";
import Layout from "../../../../Components/Layout";
import useFetch from "../../../../hooks/useFetch";
import { ORDER_SEARCH, serviceSteps } from "../../../../utils/Constants";
import { getAddedOnDate } from "../../../../Functions/dateTime";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../../Components/Pagination";
import InputDate from "../../../../Shared Components/InputDate";
import DropdownSmall from "../../../../Shared Components/DropdownSmall";
import SearchInput2 from "../../../../Shared Components/SearchInput2";
import moment from "moment";
import Loader from "../../../../Components/Loader";
import PageNav from "../../../../Components/PageNav";
import { ordersPageLinks } from "../Product";
import { toast, ToastContainer } from "react-toastify";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileAllServiceOrderFilters from "../../../../Components/Mobile/Admin/mobileAllServiceOrderFilter";
import SearchInput from "../../../../Shared Components/SearchInput";
import WhatsappBtn from "../../../../Shared Components/WhatsappBtn";
import ViewDataBtn from "../../../../Shared Components/ViewDataBtn";

const ServiceOrder = () => {
  const [param, setParam] = useState({});
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [paymentMode, setPaymentMode] = useState("");
  // console.log("paymentMode", paymentMode);

  const navigate = useNavigate();
  const functions = {
    viewServiceOrderDetails: (order) => {
      navigate(`/admin/order/service/${order?._id}`);
    },
    addParam: () => {
      if (param.fromDate && param.tillDate) {
        const checkDate = moment(param.tillDate, "YYYY-MM-DD").isAfter(
          moment(param.fromDate, "YYYY-MM-DD")
        );
        if (checkDate) {
          setFilter({
            ...filters,
            ...param,
          });
        } else {
          toast.error("From date must be less than till date.", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    },
    resetParam: () => {
      setParam({});
      delete filters.q;
      delete filters["user.mobile"];
      delete filters.fromDate;
      delete filters.tillDate;
      delete filters["serviceOrders.servicesProcessingStatus"];
      delete filters["serviceOrders.servicesProcessingStatusName"];
      delete filters["serviceOrders.serviceType"];
      getData();
    },
  };

  const [filters, setFilter] = useState({
    serviceOrders: "notNull",
    page: 1,
    per_page: 10,
    qrCode: false,
  });
  console.log(filters);

  const [orders, totalResult, error, loader, getData] = useFetch({
    url: `${ORDER_SEARCH}`,
    resultField: "orders",
    parameter: filters,
  });

  useEffect(() => {
    window.localStorage.removeItem("salon_details");
    getData();
  }, [filters]);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Service Orders"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileAllServiceOrderFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          param={param}
          setParam={setParam}
          addParam={functions.addParam}
          resetParam={functions.resetParam}
          filters={filters}
          setFilter={setFilter}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Service Orders"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Service Orders"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper">
        <div className="service-navigation-neworder-button-container">
          <div className="service-navigation-neworder-button">
            <PageNav links={ordersPageLinks} />
          </div>
        </div>

        <div className="filter-container container mt-4 ms-0 ps-0 mb-3">
          <div className="d-flex justify-content-between align-items-end flex-wrap">
            {/* search */}
            {/* <SearchInput2
              type="text"
              placeholder="Search by Order Id, user name"
              stateData={param}
              setStateData={setParam}
              parameter1="orderId"
              parameter2="user.name"
              width={190}
              addParam={functions.addParam}
              resetParam={functions.resetParam}
            /> */}
            <SearchInput
              type="text"
              placeholder="Search by user mobile, user name"
              stateData={param}
              setStateData={setParam}
              parameter1="user.name"
              parameter2="user.mobile"
              width={190}
              addParam={functions.addParam}
              resetParam={functions.resetParam}
            />

            {/* Service Venue */}
            <DropdownSmall
              data={[
                { value: "At Home", label: "At Home" },
                { value: "At Salon", label: "At Salon" },
              ]}
              label="Service Venue"
              htmlFor="order"
              type="text"
              placeholder="All Orders"
              stateData={filters["serviceOrders.serviceType"]}
              setStateData={(e) => {
                setFilter({
                  ...filters,
                  "serviceOrders.serviceType": e,
                });
              }}
            />

            {/* Payment Mode */}
            <DropdownSmall
              data={[
                { value: "COD", label: "COD" },
                { value: "PayU", label: "PayU" },
              ]}
              label="Payment Mode"
              htmlFor="order"
              type="text"
              placeholder="All Orders"
              stateData={paymentMode}
              setStateData={(e) => {
                setPaymentMode(e);
              }}
            />

            {/* Order Status */}
            <DropdownSmall
              data={[
                { value: "Pending", label: "Pending" },
                { value: "Confirmed", label: "Confirmed" },
                { value: "In Transit", label: "In Transit" },
                { value: "Started", label: "Started" },
                { value: "Ended", label: "Ended" },
                { value: "Cancelled", label: "Cancelled" },
              ]}
              label="Order Status"
              htmlFor="Sort"
              type="text"
              placeholder="Sort By"
              stateData={filters["serviceOrders.servicesProcessingStatusName"]}
              setStateData={(e) => {
                setFilter({
                  ...filters,
                  "serviceOrders.servicesProcessingStatusName": e,
                  "serviceOrders.servicesProcessingStatus":
                    e === "Pending"
                      ? "0"
                      : e === "Confirmed"
                      ? "1"
                      : e === "In Transit"
                      ? "2"
                      : e === "Started"
                      ? "3"
                      : e === "Ended"
                      ? "4"
                      : "5",
                });
              }}
            />

            {/* date from */}
            <InputDate
              label="From"
              htmlFor="from"
              type="date"
              stateData={param?.fromDate}
              setStateData={(e) => {
                setParam({
                  ...param,
                  fromDate: moment(e).format("YYYY-MM-DD"),
                });
              }}
            />

            {/* date to */}
            <InputDate
              label="To"
              htmlFor="to"
              type="date"
              stateData={param?.tillDate}
              setStateData={(e) => {
                setParam({
                  ...param,
                  tillDate: moment(e).format("YYYY-MM-DD"),
                });
              }}
            />

            <button
              className="btn filterBtn shadow-none"
              onClick={functions.addParam}
            >
              Apply
            </button>
            <button
              className="btn resetBtn shadow-none"
              onClick={functions.resetParam}
            >
              Reset
            </button>
          </div>
        </div>

        {orders?.length !== 0 ? (
          <>
            <div className="container desktop dataTable ms-0 ps-0">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Order Id</th>
                    <th scope="col">Customer Details</th>
                    <th scope="col">Store Name</th>
                    <th scope="col">Stylist Assigned</th>
                    <th scope="col">Service Venue</th>
                    <th scope="col">Order Date</th>
                    <th scope="col">Appointment Detail</th>
                    <th scope="col">Total Amount</th>
                    <th scope="col">Payment Mode</th>
                    <th scope="col">Order Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {orders?.map((order, i) => {
                    return (
                      <tr key={i}>
                        <td>{order?.serviceOrders?.orderId}</td>
                        <td>
                          {order?.user?.name} <br />
                          {order?.user?.mobile}
                        </td>
                        <td>{`${order?.salon?.salonId}_${order?.salon?.name}`}</td>
                        <td>{order?.serviceOrders?.stylist?.name ?? "--"}</td>
                        <td>{order?.serviceOrders?.serviceType}</td>
                        <td>{getAddedOnDate(order?.orderDate)}</td>
                        <td>
                          {order?.serviceOrders?.slotTime},{" "}
                          {getAddedOnDate(order?.serviceOrders?.slotDate)}
                        </td>
                        {order?.salon?.country?.htmlSymbol !== "" ? (
                          <td>{`${String.fromCharCode(
                            order?.salon?.country?.htmlSymbol
                          )} ${order?.cartCalculations?.services?.grandTotal?.toFixed(
                            2
                          )}`}</td>
                        ) : (
                          <td>{`${
                            order?.salon?.country?.currency
                          } ${order?.cartCalculations?.services?.grandTotal?.toFixed(
                            2
                          )}`}</td>
                        )}
                        <td>{order?.transactions[0]?.paymentMode}</td>
                        <td className="text-capitalize">
                          {
                            serviceSteps[
                              Number(
                                order?.serviceOrders?.servicesProcessingStatus
                              )
                            ]
                          }
                        </td>
                        <td>
                          <div className="d-flex gap-3">
                            <WhatsappBtn
                              customerMobile={order?.user?.mobile}
                              orderId={order?._id}
                              salonMobile={order?.salon?.contactNumber}
                              serviceType="service"
                            />

                            <button
                              type="button"
                              className="btn font14x400 filterBtn shadow-none me-2"
                              onClick={() =>
                                functions.viewServiceOrderDetails(order)
                              }
                              disabled={
                                order?.transactions[
                                  order?.transactions?.length - 1
                                ]?.paymentStatus === "Success"
                                  ? false
                                  : true
                              }
                            >
                              View
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="container mobile dataTable ms-0 ps-0">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Action</th>
                    <th scope="col">Customer Details</th>
                    <th scope="col">Whatsapp</th>
                    <th scope="col">Appointment Detail</th>
                    <th scope="col">Total Amount</th>
                    <th scope="col">Order Status</th>
                    <th scope="col">Payment Mode</th>
                    <th scope="col">Stylist Assigned</th>
                    <th scope="col">Service Venue</th>
                    <th scope="col">Order Id</th>
                    <th scope="col">Order Date</th>
                  </tr>
                </thead>
                <tbody>
                  {orders?.map((order, i) => {
                    return (
                      <tr
                        key={i}
                        // onClick={() => functions.viewServiceOrderDetails(order)}
                        // style={{
                        //   pointerEvents:
                        //     order?.transactions[order?.transactions?.length - 1]
                        //       ?.paymentStatus === "Success"
                        //       ? "unset"
                        //       : "none",
                        //   color:
                        //     order?.transactions[order?.transactions?.length - 1]
                        //       ?.paymentStatus !== "Success" && "grey",
                        // }}
                      >
                        <td>
                          <ViewDataBtn
                            handleClick={() =>
                              functions.viewServiceOrderDetails(order)
                            }
                            isDisabled={
                              order?.transactions[
                                order?.transactions?.length - 1
                              ]?.paymentStatus === "Success"
                                ? false
                                : true
                            }
                          />
                        </td>
                        <td>
                          {order?.user?.name} <br />
                          {order?.user?.mobile}
                        </td>
                        <td>
                          <WhatsappBtn
                            customerMobile={order?.user?.mobile}
                            orderId={order?._id}
                            salonMobile={order?.salon?.contactNumber}
                            serviceType="service"
                          />
                        </td>
                        <td>
                          {getAddedOnDate(order?.serviceOrders?.slotDate)}
                          <br />
                          {order?.serviceOrders?.slotTime}
                        </td>
                        {order?.salon?.country?.htmlSymbol !== "" ? (
                          <td>{`${String.fromCharCode(
                            order?.salon?.country?.htmlSymbol
                          )} ${order?.cartCalculations?.services?.grandTotal?.toFixed(
                            2
                          )}`}</td>
                        ) : (
                          <td>{`${
                            order?.salon?.country?.currency
                          } ${order?.cartCalculations?.services?.grandTotal?.toFixed(
                            2
                          )}`}</td>
                        )}
                        <td className="text-capitalize">
                          {
                            serviceSteps[
                              Number(
                                order?.serviceOrders?.servicesProcessingStatus
                              )
                            ]
                          }
                        </td>
                        <td>{order?.serviceOrders?.paymentMode}</td>
                        <td>{order?.serviceOrders?.stylist?.name ?? "--"}</td>
                        <td>{order?.serviceOrders?.serviceType}</td>
                        <td>{order?.serviceOrders?.orderId}</td>
                        <td>{getAddedOnDate(order?.orderDate)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Service Orders Found"
            )}
          </div>
        )}

        {orders?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      <ToastContainer />
    </>
  );
};

export default ServiceOrder;
