import React from "react";
import usePagination, { DOTS } from "../../hooks/usePagination";

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <>
      <div className="d-flex pb-3 pagination-sub-container">
        <div
          className={`${
            currentPage === 1 ? "paginationBtn disabled" : "paginationBtn"
          }`}
          onClick={onPrevious}
        >
          <img src="../../Assets/lessthen.svg" alt="lessthen" width={7} />
        </div>
        <div className="d-flex">
          {/* {[...Array(totalPages)].map((v, pageNo) => {
            console.log("pageNo: ", pageNo);
            return (
              <div
                key={pageNo}
                className={`${
                  currentPage === pageNo + 1
                    ? "paginationBtn selected"
                    : "paginationBtn"
                }`}
                onClick={() => selectedPage(pageNo)}
              >
                <span>{pageNo + 1}</span>
              </div>
            );
          })} */}
          {paginationRange.map((pageNumber) => {
            if (pageNumber === DOTS) {
              return (
                <div key={Math.random(pageNumber)} className="paginationDots">
                  <span>{pageNumber}</span>
                </div>
              );
            }
            return (
              <div
                key={pageNumber}
                className={`${
                  pageNumber === currentPage
                    ? "paginationBtn selected"
                    : "paginationBtn"
                }`}
                onClick={() => onPageChange(pageNumber)}
              >
                <span>{pageNumber}</span>
              </div>
            );
          })}
        </div>
        <div
          className={`${
            currentPage === totalCount
              ? "paginationBtn disabled"
              : "paginationBtn"
          }`}
          onClick={onNext}
        >
          <img src="../../Assets/greaterthen.svg" alt="greaterthen" width={7} />
        </div>
      </div>
    </>
  );
};

export default Pagination;
