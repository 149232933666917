import React from "react";
import Loader from "../Loader";

const Categories = (props) => {
  const { data, loader, filters, setFilter } = props;

  const functions = {
    setCategory: (id) => {
      if (filters?.category && filters?.category === id) {
        return;
      } else {
        setFilter((old) => {
          return {
            ...old,
            category: id,
          };
        });
      }
    },
  };
  return (
    <>
      <div className="categoryContainer mt-4">
        {loader ? (
          <div className="d-flex align-items-center">
            <span className="">
              <Loader height="20" width="20" color="#772286" />
            </span>
          </div>
        ) : (
          <div className="checkBoxContainer2">
            {data?.map((item, i) => {
              return (
                <button
                  key={i}
                  type="button"
                  className={`${
                    filters?.category === item?._id
                      ? "categoryPicker categorySelected"
                      : "categoryPicker"
                  }`}
                  value={filters?.category}
                  onClick={() => functions.setCategory(item?._id)}
                >
                  {item?.name}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default Categories;
