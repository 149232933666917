import React, { useContext, useState } from "react";
import { BiCurrentLocation } from "react-icons/bi";
import CustomMap from "./google map";
import ChangeLocation from "./google map/ChangeLocation";
import { registrationContext } from "../../../Pages/Salon/Master Salon/RegistrationForm";

const Location = () => {
  const { location, functions } = useContext(registrationContext);
  const latitude = window.localStorage.getItem("latitude");
  const longitude = window.localStorage.getItem("longitude");
  const [showModal, setShowModal] = useState(false);

  const getMyCurrentLocation = () => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition((p) => {
        window.localStorage.setItem("latitude", p.coords.latitude);
        window.localStorage.setItem("longitude", p.coords.longitude);
        functions.handleLocation(p.coords.latitude, p.coords.longitude);
      });
    }
  };

  return (
    <>
      <div className="mb-3">
        <label htmlFor="country" className="font12x500 form-label mb-1">
          Select Location*
        </label>
        <div className="mapContainer mb-4">
          <div className="mapWrapper">
            <CustomMap
              google={window.google}
              handleLocation={functions.handleLocation}
              latitude={latitude}
              longitude={longitude}
            />
          </div>
          <div
            title="current location"
            className="currentLocation"
            onClick={() => {
              getMyCurrentLocation();
            }}
          >
            <BiCurrentLocation />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-8">
            <p className="mb-0 font14x400">{location.locationName}</p>
          </div>
          <div className="col-12 col-sm-4 d-flex align-items-center mt-3 mt-sm-0">
            <div
              className="btn locationBtn shadow-none"
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
            >
              Change
            </div>
          </div>
        </div>
      </div>

      {/* modal */}
      {showModal && (
        <ChangeLocation
          setShowModal={setShowModal}
          google={window.google}
          handleLocation={functions.handleLocation}
        />
      )}
    </>
  );
};

export default Location;
