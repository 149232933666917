import React, { useEffect } from "react";
import { GoogleApiWrapper } from "google-maps-react";

const SearchCity = (props) => {
  const { width, google, stateData, setStateData } = props;

  const searchCities = () => {
    const maps = google?.maps;

    console.log("maps", maps);

    let options = {
      types: ["(cities)"],
      componentRestrictions: { country: "in" },
    };

    let input = document.getElementById("searchCity");
    let autocomplete = new maps.places.Autocomplete(input, options);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      setStateData(place?.name);
    });
  };

  useEffect(() => {
    searchCities();
  }, []);

  return (
    <>
      <div className="searchWrapper" style={{ width: width }}>
        <div className="w-100">
          <input
            type="text"
            className="form-control shadow-none"
            autoComplete="off"
            placeholder="Search city"
            id="searchCity"
            value={stateData ?? ""}
            onChange={(e) => {
              setStateData(e.target.value);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: `${process.env.REACT_APP_API_MAP}`,
})(SearchCity);
