import React, { useState } from "react";
import useAuthContext from "../../../hooks/useAuthContext";
import axios from "axios";
import { authHeader, SALON_UPDATE } from "../../../utils/Constants";
import { toast } from "react-toastify";
import Select from "react-select";

const options = [
  { value: "pending", label: "pending" },
  { value: "verified", label: "verified" },
  { value: "not-verified", label: "not-verified" },
  { value: "de-listed", label: "de-listed" },
];

const UpdateRecord = (props) => {
  const { isUpdateModalOpen, setIsUpdateModalOpen, apiCall, temp } = props;

  const [updateData, setUpdateData] = useState({
    salonStatus: temp.salonStatus,
    isActive: temp.isActive,
    status: temp.status,
  });
  console.log(updateData);

  const setData = (e) => {
    const value = e;

    if (value === "pending") {
      setUpdateData({
        salonStatus: value,
        isActive: false,
        status: 0,
      });
    } else if (value === "verified") {
      setUpdateData({
        salonStatus: value,
        isActive: true,
        status: 1,
      });
    } else if (value === "not-verified") {
      setUpdateData({
        salonStatus: value,
        isActive: false,
        status: 3,
      });
    } else {
      setUpdateData({
        salonStatus: value,
        isActive: false,
        status: 3,
      });
    }
  };

  const setPositionToBody = () => {
    setIsUpdateModalOpen(false);
    document.body.style.overflow = "unset";
  };

  const { token } = useAuthContext();

  const updateSalonData = async () => {
    const rFormData = new FormData();
    rFormData.append("salonStatus", updateData.salonStatus);
    rFormData.append("isActive", updateData.isActive);
    rFormData.append("status", updateData.status);
    if (token) {
      try {
        const res = await axios.put(
          `${SALON_UPDATE}${temp._id}/form`,
          rFormData,
          {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );
        if (res.status === 200) {
          setPositionToBody();
          apiCall();
          toast.success("Application Status Changed", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          console.log("error", res.status);
        }
      } catch (error) {
        console.log("catch error: ", error);
        if (error.response.status === 502) {
          toast.error(error.response.data.message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    }
  };

  const styles = {
    control: (styles, { isFocused }) => {
      return {
        ...styles,
        borderRadius: "8px",
        textAlign: "left",
        border: "1px solid var(--border_primary)",
        border: isFocused
          ? "1px solid var(--border_primary)"
          : "1px solid var(--border_primary)",
        boxShadow: "none",
        cursor: "pointer",
        height: "48px",
      };
    },
    indicatorSeparator: (styles) => {
      return {
        display: "none",
      };
    },
    placeholder: (styles) => {
      return {
        ...styles,
        fontSize: "16px",
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        fontSize: "16px",
        textTransform: "capitalize",
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        borderRadius: "8px",
        margin: 0,
        backgroundColor: "var(--border_secondary)",
      };
    },
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        color: "#000",
        backgroundColor: isSelected && "#fff",
        borderBottom: "1px solid var(--border_primary)",
        textAlign: "left",
        margin: 0,
        cursor: "pointer",
        fontSize: "16px",
        padding: "10px 11px",
        textTransform: "capitalize",
        ":last-child": {
          borderBottom: "none",
        },
      };
    },
  };

  return (
    <>
      <div
        className={
          isUpdateModalOpen
            ? "deleteModalWrapper deleteModalWrapper-active"
            : "deleteModalWrapper"
        }
      >
        <div
          className="deleteModalDiv"
          onClick={() => setPositionToBody()}
        ></div>
        <div className="deleteModalContainer d-flex align-items-center flex-column justify-content-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div
                  className="selectDropdown"
                  style={{
                    width: "300px",
                  }}
                >
                  <Select
                    options={options}
                    placeholder="Select Application Status"
                    styles={styles}
                    name="applicationStatus"
                    value={options.filter((option) => {
                      return option.value === updateData.salonStatus;
                    })}
                    onChange={(e) => {
                      setData(e.value);
                    }}
                  />
                </div>

                <div className="">
                  <button
                    className="btn addBtn shadow-none mt-3"
                    onClick={() => updateSalonData()}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateRecord;
