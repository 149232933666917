import React from "react";
import useLoading from "../../../hooks/useLoading";
import { countrySchema } from "../../../utils/validationschema";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import axios from "axios";
import UpdationDetails from "../../../Shared Components/UpdationDetails";
import InputError from "../../../Shared Components/InputError";
import SubmitButton from "../../../Shared Components/SubmitButton";
import InputFieldLarge from "../../../Shared Components/InputFieldLarge";
import { CgClose } from "react-icons/cg";
import useAuthContext from "../../../hooks/useAuthContext";
import { CREATE_COUNTRY, UPDATE_COUNTRY } from "../../../utils/Constants";
import StaticDropdown from "../../../Shared Components/StaticDropdown";
import { Country } from "country-state-city";

const allCountry = [];
Country.getAllCountries().map((country) => {
  allCountry.push({
    label: country.name,
    value: country.isoCode,
  });
});

const CountryForm = ({ state, setState, apiCall }) => {
  const { token } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();

  const initialValues = {
    name: state?.data?.name ?? "",
    phoneCode: state?.data?.phoneCode ?? "",
    acronym: state?.data?.acronym ?? "",
    currency: state?.data?.currency ?? "",
    htmlSymbol: state?.data?.htmlSymbol ?? "",
  };

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.position = "unset";
  };

  const settingOtherInfoBasedOnCountry = (countryCode) => {
    const countryDetails = Country.getCountryByCode(countryCode);

    formik.setFieldValue("phoneCode", countryDetails?.phonecode);
    formik.setFieldValue("acronym", countryDetails?.isoCode);
    formik.setFieldValue("currency", countryDetails?.currency);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: countrySchema,
    onSubmit: (data) => {
      // console.log("data", data);
      formSubmit(data);
    },
  });

  const formSubmit = async (data) => {
    console.log("form submit called");

    let finalObj = {
      ...data,
    };

    if (token) {
      if (state.data === undefined) {
        try {
          showLoader();
          const res = await axios.post(`${CREATE_COUNTRY}`, finalObj, {
            //headers: authFormHeader,
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Country Added", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      } else {
        try {
          showLoader();
          console.log("update api called");
          const res = await axios.put(
            `${UPDATE_COUNTRY}/${state?.data?._id}`,
            {
              htmlSymbol: data.htmlSymbol,
              phoneCode: data.phoneCode,
            },
            {
              //headers: authFormHeader,
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Country Updated", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">
              {!state.data ? "Add New Country" : "Update Country"}
            </h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit}>
              {/* Country */}
              <div className="mb-3">
                {state.data ? (
                  <InputFieldLarge
                    label="Country*"
                    htmlFor="country"
                    type="text"
                    name="country"
                    stateData={formik.values.name}
                    setStateData={formik.handleChange}
                    onBlur={formik.handleBlur}
                    readOnly={true}
                  />
                ) : (
                  <>
                    <StaticDropdown
                      data={allCountry}
                      label="Country*"
                      htmlFor="country"
                      type="text"
                      placeholder="Select Country"
                      name="name"
                      stateData={formik.values.acronym}
                      setStateData={(e, x) => {
                        formik.setFieldValue("name", x);
                        settingOtherInfoBasedOnCountry(e);
                      }}
                      onBlur={() => {
                        formik.setFieldTouched("name", true);
                      }}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <InputError error={formik.errors.name} />
                    ) : null}
                  </>
                )}
              </div>

              {/* acronym */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Acronym*"
                  htmlFor="acronym"
                  type="text"
                  name="acronym"
                  stateData={formik.values.acronym}
                  setStateData={formik.handleChange}
                  onBlur={formik.handleBlur}
                  readOnly={true}
                />
                {formik.touched.acronym && formik.errors.acronym ? (
                  <InputError error={formik.errors.acronym} />
                ) : null}
              </div>

              <div className="mb-3">
                <InputFieldLarge
                  label="Phone Code*"
                  htmlFor="phoneCode"
                  type="text"
                  name="phoneCode"
                  stateData={formik.values.phoneCode}
                  setStateData={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.phoneCode && formik.errors.phoneCode ? (
                  <InputError error={formik.errors.phoneCode} />
                ) : null}
              </div>

              <div className="mb-3">
                <InputFieldLarge
                  label="Currency*"
                  htmlFor="currency"
                  type="text"
                  name="currency"
                  stateData={formik.values.currency}
                  setStateData={formik.handleChange}
                  onBlur={formik.handleBlur}
                  readOnly={true}
                />
                {formik.touched.currency && formik.errors.currency ? (
                  <InputError error={formik.errors.currency} />
                ) : null}
              </div>

              <div className="mb-3">
                <InputFieldLarge
                  label="Html Symbol*"
                  htmlFor="htmlSymbol"
                  type="text"
                  name="htmlSymbol"
                  stateData={formik.values.htmlSymbol}
                  setStateData={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.htmlSymbol && formik.errors.htmlSymbol ? (
                  <InputError error={formik.errors.htmlSymbol} />
                ) : null}
              </div>

              <SubmitButton
                data={!state.data}
                loader={loader}
                create="Submit"
                update="Update"
              />
            </form>

            {state?.data && <UpdationDetails data={state?.data} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default CountryForm;
