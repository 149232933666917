import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import CouponForm from "../../../../Components/Admin/Coupon Form";
import { RiDeleteBin6Line } from "react-icons/ri";
import Layout from "../../../../Components/Layout";
import DropdownSmall from "../../../../Shared Components/DropdownSmall";
import useAuthContext from "../../../../hooks/useAuthContext";
import useFetch from "../../../../hooks/useFetch";
import {
  COUPON_DELETE,
  COUPON_SEARCH,
  SALON_SEARCH,
} from "../../../../utils/Constants";
import SearchInput2 from "../../../../Shared Components/SearchInput2";
import DeleteModal from "../../../../Components/DeleteRecord";
import { getAddedOnDateTime } from "../../../../Functions/dateTime";
import Pagination from "../../../../Components/Pagination";
import Loader from "../../../../Components/Loader";
import PageNav from "../../../../Components/PageNav";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";
import MobileCouponFilters from "../../../../Components/Mobile/Admin/mobileCouponFilter";

export const marketingLinks = [
  {
    link: "/admin/app-coupons",
    label: "Coupons",
  },
  {
    link: "/admin/couponsForSalon",
    label: "Store Coupons",
  },
  {
    link: "/admin/offer&deals",
    label: "Offers & Deals",
  },
];

const Coupons = () => {
  const [commonData, setCommonData] = useState({
    data: {},
    modal: false,
  });

  const onEdit = (item) => {
    setCommonData({
      data: item,
      modal: true,
    });
    document.body.style.overflow = "hidden";
  };
  const onAdd = () => {
    setCommonData({
      data: undefined,
      modal: true,
    });
    document.body.style.overflow = "hidden";
  };

  const [salons, totalResult2, error2, loader2, getSalons] = useFetch({
    url: SALON_SEARCH,
    resultField: "salons",
    parameter: {
      f: "salonId,name",
      status: 1,
    },
  });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [id, setId] = useState("");
  const onDelete = (id) => {
    setIsDeleteModalOpen(true);
    document.body.style.overflow = "hidden";
    setId(id);
  };

  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
    sort_by: "order",
    sort_order: "desc",
  });
  console.log(filters);

  const [param, setParam] = useState({ show: false });
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const addParam = () => {
    setFilter({
      ...filters,
      ...param,
    });
  };

  const resetParam = () => {
    setParam({ show: false });
    delete filters.status;
    delete filters.conditionType;
    delete filters.q;
    delete filters.statusName;
    getData();
  };

  const [coupons, totalResult, error, loader, getData] = useFetch({
    url: `${COUPON_SEARCH}`,
    resultField: "coupons",
    parameter: filters,
  });

  useEffect(() => {
    window.localStorage.removeItem("salon_details");
    getData();
  }, [filters]);
  useEffect(() => {
    getSalons();
  }, []);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Coupons"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileCouponFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          onClick={onAdd}
          resetParam={resetParam}
          filters={filters}
          setFilter={setFilter}
          addParam={addParam}
          param={param}
          setParam={setParam}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Coupons"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Coupons"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper">
        <div>
          <div className="service-navigation-neworder-button-container">
            <div className="service-navigation-neworder-button d-flex justify-content-between pe-2">
              <PageNav links={marketingLinks} />
              <div className="desktopButton">
                <button className="btn addBtn shadow-none" onClick={onAdd}>
                  Create New Coupon
                </button>
              </div>
            </div>
          </div>

          <div className="filter-container container mt-4 ms-0 ps-0 mb-3">
            <div className="row">
              <div className="col-5 col-lg-5 d-flex align-items-end">
                <SearchInput2
                  type="text"
                  placeholder="Search by title"
                  stateData={param}
                  setStateData={setParam}
                  parameter1="title"
                  parameter2={undefined}
                  addParam={addParam}
                  resetParam={resetParam}
                />
              </div>
              <div className="col-12 col-lg-7 d-flex justify-content-lg-end align-items-end">
                {/* sort by status */}
                <DropdownSmall
                  data={[
                    { value: "All", label: "All" },
                    { value: "Active", label: "Active" },
                    { value: "Inactive", label: "Inactive" },
                  ]}
                  label="Sort By Status"
                  htmlFor="status"
                  type="text"
                  stateData={filters?.statusName}
                  setStateData={(e) => {
                    if (e === "All") {
                      delete filters?.status;
                      setFilter({
                        ...filters,
                        statusName: e,
                      });
                    } else {
                      setFilter({
                        ...filters,
                        statusName: e === "Active" ? "Active" : "Inactive",
                        status: e === "Active" ? 1 : 2,
                      });
                    }
                  }}
                />

                <div className="ms-3">
                  {/* Condition Type */}
                  <DropdownSmall
                    data={[
                      { value: "Percentage", label: "Percentage" },
                      { value: "Discount", label: "Discount" },
                    ]}
                    label="Condition Type"
                    htmlFor="cType"
                    type="text"
                    stateData={filters?.conditionType}
                    setStateData={(e) => {
                      setFilter({
                        ...filters,
                        conditionType: e,
                      });
                    }}
                  />
                </div>

                <button
                  className="btn resetBtn shadow-none ms-3"
                  onClick={resetParam}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>

        {coupons?.length !== 0 ? (
          <div className="container dataTable ms-0 ps-0">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Coupon Code</th>
                  <th scope="col">Order</th>
                  <th scope="col">Condition</th>
                  <th scope="col">Valid from</th>
                  <th scope="col">Valid till</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {coupons?.map((coupon, i) => {
                  return (
                    <tr key={i}>
                      <td>{coupon?.title}</td>
                      <td>
                        <span className="coupon">{coupon?.code}</span>
                      </td>
                      <td>{coupon?.order}</td>
                      <td>{coupon?.conditionType}</td>
                      <td>{getAddedOnDateTime(coupon?.validFrom)}</td>
                      <td>{getAddedOnDateTime(coupon?.validTo)}</td>
                      <td>
                        <p
                          className="status mb-0"
                          style={{
                            backgroundColor: `${
                              coupon?.isActive === true
                                ? "var(--status_color2)"
                                : "var(--status_color1)"
                            }`,
                          }}
                        >
                          {`${
                            coupon?.isActive === true ? "Active" : "Inactive"
                          }`}
                        </p>
                      </td>
                      <td>
                        <button
                          className="btn font14x400 filterBtn shadow-none me-2"
                          onClick={() => {
                            onEdit(coupon);
                          }}
                        >
                          Edit
                        </button>
                        <span
                          className="deleteBtn"
                          onClick={() => {
                            onDelete(coupon?._id);
                          }}
                        >
                          <RiDeleteBin6Line />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Coupon Found"
            )}
          </div>
        )}

        {coupons?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      {/* delete modal */}
      {isDeleteModalOpen && (
        <DeleteModal
          id={id}
          apiCall={getData}
          state={isDeleteModalOpen}
          setState={setIsDeleteModalOpen}
          url={COUPON_DELETE}
          text="Coupon"
        />
      )}

      {/* coupon form add and update both */}
      {commonData.modal && (
        <CouponForm
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
          data={salons}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default Coupons;
