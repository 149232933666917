import { useFormik } from "formik";
import React, { useState } from "react";
import useAuthContext from "../../hooks/useAuthContext";
import useLoading from "../../hooks/useLoading";
import { editSalonAddresses } from "../../utils/validationschema";
import { CgClose } from "react-icons/cg";
import { HiOutlineArrowLeft } from "react-icons/hi";
import InputField from "../RegistrationForm/sub components/InputField";
import InputError from "../../Shared Components/InputError";
import SubmitButton from "../../Shared Components/SubmitButton";
import Location from "./LocationMap";
import UpdationDetails from "../../Shared Components/UpdationDetails";
import {
  authHeader,
  CREATE_USER_ADDRESSES,
  UPDATE_USER_ADDRESSES,
} from "../../utils/Constants";
import { toast } from "react-toastify";
import axios from "axios";

const EditAddressSalon = ({ state, setState, apiCall }) => {
  const { token, salonDetails } = useAuthContext();
  const [isChange, setIsChange] = useState(false);

  const [newAddress, setNewAddress] = useState({
    city: state.data?.city ?? "",
    state: state.data?.state ?? "",
    country: state.data?.country ?? "",
    pincode: state.data?.pincode ?? "",
    lat: state.data?.lat ?? salonDetails?.location?.lat,
    lng: state.data?.lng ?? salonDetails?.location?.lng,
    locationName:
      state.data?.locationName ?? salonDetails?.location?.locationName,
  });

  const initialValues = {
    line_1: state.data?.line_1 ?? "",
    line_2: state.data?.line_2 ?? "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: editSalonAddresses,
    onSubmit: (data) => {
      // console.log("data", data);
      formSubmit(data);
    },
  });

  const [loader, showLoader, hideLoader] = useLoading();

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const formSubmit = async (data) => {
    console.log("form submit called");

    if (token) {
      if (state.data === undefined) {
        try {
          showLoader();
          const res = await axios.post(
            `${CREATE_USER_ADDRESSES}`,
            {
              line_1: data?.line_1,
              line_2: data?.line_2,
              city: newAddress?.city,
              state: newAddress?.state,
              country: newAddress?.country,
              pincode: newAddress?.pincode,
              completeAddress: `${data?.line_1}, ${data?.line_2}, ${newAddress?.city}, ${newAddress?.state}, ${newAddress?.country}, ${newAddress?.pincode}`,
              lat: newAddress?.lat,
              lng: newAddress?.lng,
              status: 1,
              locationName: newAddress?.locationName,
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            {
              state?.comingFrom === "cart"
                ? setState({
                    ...state,
                    step: 4,
                  })
                : setPositionToBody();
            }
            {
              !state?.comingFrom && apiCall();
            }

            hideLoader();
            toast.success("Address Added", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res?.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      } else {
        try {
          showLoader();
          console.log("update api called");
          const res = await axios.put(
            `${UPDATE_USER_ADDRESSES}${state.data._id}`,
            {
              line_1: data?.line_1,
              line_2: data?.line_2,
              city: newAddress?.city,
              state: newAddress?.state,
              country: newAddress?.country,
              pincode: newAddress?.pincode,
              completeAddress: `${data?.line_1}, ${data?.line_2}, ${newAddress?.city}, ${newAddress?.state}, ${newAddress?.country}, ${newAddress?.pincode}`,
              lat: newAddress?.lat,
              lng: newAddress?.lng,
              status: 1,
              locationName: newAddress?.locationName,
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            {
              state?.comingFrom === "cart"
                ? setState({
                    ...state,
                    step: 4,
                  })
                : setPositionToBody();
            }
            {
              !state?.comingFrom && apiCall();
            }

            hideLoader();
            toast.success("Address Updated", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res?.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div
            style={{ zIndex: isChange ? "0" : "10" }}
            className="formHeadingDiv"
          >
            <h1 className="font22x500 mb-0">
              {!state.data ? "Add New Address" : "Edit Address"}
            </h1>
            {state?.comingFrom === "cart" ? (
              <span
                onClick={() => {
                  setState({
                    ...state,
                    step: 4,
                  });
                }}
              >
                <HiOutlineArrowLeft />
              </span>
            ) : (
              <span onClick={setPositionToBody}>
                <CgClose />
              </span>
            )}
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit}>
              <Location
                setIsChange={setIsChange}
                newAddress={newAddress}
                setNewAddress={setNewAddress}
              />

              {/* line_1 */}
              <div className="mb-3">
                <InputField
                  label="Address Line 1*"
                  htmlFor="line1"
                  type="text"
                  name="line_1"
                  registrationData={formik.values.line_1}
                  setRegistrationData={formik.handleChange}
                />
                {formik.errors.line_1 ? (
                  <InputError error={formik.errors.line_1} />
                ) : null}
              </div>

              {/* line_2 */}
              <div className="mb-3">
                <InputField
                  label="Address Line 2 (optional)"
                  htmlFor="line2"
                  type="text"
                  name="line_2"
                  registrationData={formik.values.line_2}
                  setRegistrationData={formik.handleChange}
                />
                {formik.errors.line_2 ? (
                  <InputError error={formik.errors.line_2} />
                ) : null}
              </div>

              {/* city */}
              <div className="mb-3">
                <InputField
                  label="City*"
                  htmlFor="city"
                  type="text"
                  name="city"
                  registrationData={newAddress?.city}
                  readOnly={true}
                />
              </div>

              {/* state */}
              <div className="mb-3">
                <InputField
                  label="State*"
                  htmlFor="state"
                  type="text"
                  name="state"
                  registrationData={newAddress?.state}
                  readOnly={true}
                />
              </div>

              {/* country */}
              <div className="mb-3">
                <label htmlFor="country" className="font12x500 form-label mb-1">
                  Country*
                </label>
                <input
                  type="text"
                  className="form-control shadow-none"
                  id="country"
                  name="country"
                  value={newAddress?.country}
                  readOnly={true}
                />
              </div>

              {/* pincode */}
              <div className="mb-3">
                <InputField
                  label="Pincode*"
                  htmlFor="pin"
                  type="tel"
                  name="pincode"
                  registrationData={newAddress?.pincode}
                  readOnly={true}
                />
              </div>

              <SubmitButton
                data={!state.data}
                loader={loader}
                create="Add Address"
                update="Update Address"
              />
            </form>

            {state?.data && <UpdationDetails data={state?.data} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAddressSalon;
