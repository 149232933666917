import React from "react";

const InputField = (props) => {
  const {
    label,
    maxLength,
    htmlFor,
    type,
    name,
    registrationData,
    setRegistrationData,
    reference,
    changeBehaviour,
    readOnly,
  } = props;

  return (
    <>
      <label htmlFor={htmlFor} className="font12x500 form-label mb-1">
        {label}
      </label>
      <input
        ref={reference}
        type={type}
        className="form-control shadow-none"
        id={htmlFor}
        name={name}
        maxLength={maxLength}
        onInput={changeBehaviour}
        value={registrationData}
        // onChange={(e) => {
        //   setRegistrationData(e.target.value);
        // }}
        onChange={setRegistrationData}
        autoComplete="off"
        readOnly={readOnly}
      />
    </>
  );
};

export default InputField;
