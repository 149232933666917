import moment from "moment";
import { useEffect, useState } from "react";
import InputDate from "../../../Shared Components/InputDate";

function MobileReviewFilters(props) {
  const { param, setParam, addParam, resetParam } = props;
  const [animateFilter, setAnimateFilter] = useState({
    transform: "translate(100%)",
  });

  useEffect(() => {
    if (props.isOpenFilter) {
      setAnimateFilter({
        transform: "translate(0%)",
      });
    } else {
      setAnimateFilter({
        transform: "translate(100%)",
      });
    }
  }, [props.isOpenFilter]);

  return (
    <div style={animateFilter} className="mobile-bydate-filters-container">
      <div
        onClick={() => {
          props.setIsOpenFilter(false);
        }}
        className="left-filter-section"
      ></div>
      <div className="right-filter-section">
        <div className="right-filter-sub-container pt-3 pb-3">
          <p className="title">Filter</p>

          <div className="">
            <button className="bydate-filter-button d-flex justify-content-between align-items-center bg-white border-0 w-100">
              <p className="mb-0">By Date</p>
              <p className="mb-0">-</p>
            </button>

            <div className="right-date-filter-container">
              <div className="mb-2">
                <InputDate
                  label="From"
                  htmlFor="from"
                  type="date"
                  stateData={param?.fromDate}
                  setStateData={(e) => {
                    setParam({
                      ...param,
                      fromDate: moment(e).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </div>
              <div>
                <InputDate
                  label="To"
                  htmlFor="to"
                  type="date"
                  stateData={param?.tillDate}
                  setStateData={(e) => {
                    setParam({
                      ...param,
                      tillDate: moment(e).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mt-1">
            <button className="apply" onClick={addParam}>
              Apply
            </button>
            <button className="reset" onClick={resetParam}>
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileReviewFilters;
