import React, { useEffect, useState } from "react";
import Layout from "../../../../Components/Layout";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteModal from "../../../../Components/DeleteRecord";
import Pagination from "../../../../Components/Pagination";
import {
  SALON_SERVICES_DELETE,
  SALON_SERVICES_SEARCH,
  SERVICE_CATEGORY,
  BRAND_LIST,
  SERVICE_NAME_LIST,
  SYNC_SERVICES,
  SERVICE_CATEGORY_SEARCH,
  authHeader,
  ADMIN,
} from "../../../../utils/Constants";
import { getAddedOnDateTime } from "../../../../Functions/dateTime";
import ServiceForm from "../../../../Components/ServiceForm";
import { toast, ToastContainer } from "react-toastify";
import useFetch from "../../../../hooks/useFetch";
import DropdownSmallDynamic from "../../../../Shared Components/DropdownSmallDynamic";
import useCategory from "../../../../hooks/useCategory";
import Loader from "../../../../Components/Loader";
import SearchInput2 from "../../../../Shared Components/SearchInput2";
import DropdownSmall from "../../../../Shared Components/DropdownSmall";
import serviceImage from "../../../../uploads/service/service1.jpg";
import useAuthContext from "../../../../hooks/useAuthContext";
import axios from "axios";
import useLoading from "../../../../hooks/useLoading";
import UploadServiceForm from "../../../../Components/ServiceForm/UploadServiceForm";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileServiceFilters from "../../../../Components/Mobile/mobileServiceFilter";

const Services = () => {
  const [commonData, setCommonData] = useState({
    data: {},
    modal: false,
    check: "",
  });

  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
    sort_by: "order",
    sort_order: "desc",
  });
  console.log(filters);

  const [param, setParam] = useState({
    show: false,
  });
  console.log("param", param);

  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const salonDetails = JSON.parse(localStorage.getItem("salon_details"));

  const [services, totalResult, error, loader, getData] = useFetch({
    url: `${SALON_SERVICES_SEARCH}salon=${salonDetails?._id}`,
    resultField: "services",
    parameter: filters,
  });

  const { token, role } = useAuthContext();
  const [syncLoader, showLoader, hideLoader] = useLoading();

  const functions = {
    addParam: () => {
      setFilter({
        ...filters,
        ...param,
      });
    },
    resetParam: () => {
      setParam({
        show: false,
      });
      delete filters.category;
      delete filters.categoryName;
      delete filters.status;
      delete filters.statusName;
      delete filters.q;
      getData();
    },
    syncService: async () => {
      if (token) {
        try {
          showLoader();
          const res = await axios.post(
            `${SYNC_SERVICES}${salonDetails?._id}/service/sync`,
            {},
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            getData();
            hideLoader();
            toast.success("Services Synced", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
  };

  //custom hook for getting category
  const [category, loaderSmall, getCategory] = useCategory({
    url: SERVICE_CATEGORY_SEARCH,
    resultField: "serviceCategories",
  });
  //custom hook for getting brands
  const [brands, loader2, getBrands] = useCategory({
    url: BRAND_LIST,
    resultField: "brands",
  });
  //custom hook for getting services name
  const [serviceNames, loader3, getServiceNames] = useCategory({
    url: SERVICE_NAME_LIST,
    resultField: "serviceNames",
  });

  const onEdit = (item, check) => {
    setCommonData({
      data: item,
      modal: true,
      check: check,
    });
    document.body.style.overflow = "hidden";
  };
  const onAdd = (check) => {
    setCommonData({
      data: undefined,
      modal: true,
      check: check ? check : "",
    });
    document.body.style.overflow = "hidden";
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [id, setId] = useState("");
  const onDelete = (id) => {
    setIsDeleteModalOpen(true);
    document.body.style.overflow = "hidden";
    setId(id);
  };

  useEffect(() => {
    getData();
  }, [filters]);
  useEffect(() => {
    getCategory();
    getBrands();
    getServiceNames();
  }, []);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Services"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileServiceFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          category={category}
          onClick={() => {
            onAdd("add_service");
          }}
          filters={filters}
          setFilter={setFilter}
          resetParam={functions.resetParam}
          uploadService={() => {
            onAdd("upload_service");
          }}
          syncService={functions.syncService}
          services={services}
          syncLoader={syncLoader}
          param={param}
          setParam={setParam}
          addParam={functions.addParam}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Services"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Services"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper clients-page">
        <div>
          <div className="container ms-0 ps-0 d-flex justify-content-between align-items-center mb-2">
            <h3 className="font22x500 pageHeading mb-0">Services</h3>
            <div className="desktopButton">
              <button
                className="btn addBtn shadow-none"
                onClick={() => {
                  onAdd("add_service");
                }}
              >
                Add New Service
              </button>
              {role === ADMIN && (
                <button
                  className="btn addBtn shadow-none ms-2"
                  onClick={() => {
                    onAdd("upload_service");
                  }}
                >
                  Upload
                </button>
              )}

              {salonDetails?.masterSalon && services?.length === 0 && (
                <button
                  className="btn addBtn shadow-none ms-3"
                  onClick={functions.syncService}
                >
                  Sync
                  {syncLoader && <Loader height="25" width="25" color="#fff" />}
                </button>
              )}
            </div>
          </div>
          <div className="filter-container container ms-0 ps-0 mb-3">
            <div className="row">
              <div className="col-12 col-lg-5 d-flex align-items-end">
                <SearchInput2
                  type="text"
                  placeholder="Search by name / service id"
                  stateData={param}
                  setStateData={setParam}
                  parameter1="serviceId"
                  parameter2="name"
                  addParam={functions.addParam}
                  resetParam={functions.resetParam}
                />
              </div>
              <div className="col-12 col-lg-7 d-flex justify-content-lg-end align-items-end">
                {/* status */}
                <DropdownSmall
                  data={[
                    { value: "All", label: "All" },
                    { value: "Active", label: "Active" },
                    { value: "Inactive", label: "Inactive" },
                  ]}
                  label="Status"
                  placeholder="Id Status"
                  htmlFor="status"
                  type="text"
                  stateData={filters?.statusName}
                  setStateData={(e) => {
                    if (e === "All") {
                      delete filters?.status;
                      setFilter({
                        ...filters,
                        statusName: e,
                      });
                    } else {
                      setFilter({
                        ...filters,
                        statusName: e === "Active" ? "Active" : "Inactive",
                        status: e === "Active" ? 1 : 2,
                      });
                    }
                  }}
                />

                <div className="ms-3">
                  {/* category */}
                  <DropdownSmallDynamic
                    data={category}
                    loader={loaderSmall}
                    label="Category*"
                    htmlFor="category"
                    type="text"
                    placeholder="Select Category"
                    stateData={filters?.categoryName}
                    setStateData={(e, x) => {
                      if (e === "" && filters.category) {
                        delete filters.category;
                        setFilter({
                          ...filters,
                          categoryName: x,
                        });
                      } else {
                        setFilter({
                          ...filters,
                          category: e,
                          categoryName: x,
                        });
                      }
                    }}
                  />
                </div>
                <button
                  className="btn resetBtn shadow-none ms-3"
                  onClick={functions.resetParam}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>

        {services?.length !== 0 ? (
          <>
            <div className="container desktop dataTable ms-0 ps-0">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">SL.No.</th>
                    <th scope="col">Image</th>
                    <th scope="col">Service Id</th>
                    <th scope="col">Order</th>
                    <th scope="col">Service Name</th>
                    <th scope="col">Service Place</th>
                    <th scope="col">Service Category</th>
                    {/* <th scope="col">Service Duration</th> */}
                    <th scope="col">Home Price</th>
                    <th scope="col">Salon Price</th>
                    <th scope="col">Added On</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {services?.map((item, i) => {
                    return (
                      <tr key={item?._id}>
                        <td>
                          {(filters.page - 1) * filters.per_page + (i + 1)}
                        </td>
                        <td>
                          {item?.image === null ? (
                            <img
                              src={serviceImage}
                              alt="service"
                              width={72}
                              height={48}
                              style={{ borderRadius: "4px" }}
                            />
                          ) : (
                            <img
                              src={`${process.env.REACT_APP_Image_BaseURL}${item?.image}`}
                              alt="service"
                              width={72}
                              height={48}
                              style={{ borderRadius: "4px" }}
                            />
                          )}
                        </td>
                        <td>{item?.serviceId}</td>
                        <td>{item?.order}</td>
                        <td>{item?.name}</td>
                        <td>
                          {item?.isSalon
                            ? "At Salon"
                            : item?.isHome && "At Home"}
                        </td>
                        <td>{item?.category?.name}</td>
                        {/* <td>{getPrettyFormat(item?.duration)}</td> */}
                        {salonDetails?.country?.htmlSymbol !== "" ? (
                          <td>
                            {item?.isHome
                              ? `${String.fromCharCode(
                                  salonDetails?.country?.htmlSymbol
                                )} ${item?.homeDiscountPrice}`
                              : "--"}
                          </td>
                        ) : (
                          <td>
                            {item?.isHome
                              ? `${salonDetails?.country?.currency} ${item?.homeDiscountPrice}`
                              : "--"}
                          </td>
                        )}
                        {salonDetails?.country?.htmlSymbol !== "" ? (
                          <td>
                            {item?.isSalon
                              ? `${String.fromCharCode(
                                  salonDetails?.country?.htmlSymbol
                                )} ${item?.salonDiscountPrice}`
                              : "--"}
                          </td>
                        ) : (
                          <td>
                            {item?.isSalon
                              ? `${salonDetails?.country?.currency} ${item?.salonDiscountPrice}`
                              : "--"}
                          </td>
                        )}
                        <td>{getAddedOnDateTime(item?.createdAt)}</td>
                        <td>
                          <p
                            className="status mb-0"
                            style={{
                              backgroundColor: `${
                                item?.isActive === true
                                  ? "var(--status_color2)"
                                  : "var(--status_color1)"
                              }`,
                            }}
                          >
                            {`${
                              item?.isActive === true ? "Active" : "Inactive"
                            }`}
                          </p>
                        </td>
                        <td>
                          <button
                            className="btn font14x400 filterBtn shadow-none me-2"
                            onClick={() => {
                              onEdit(item, "add_service");
                            }}
                          >
                            Edit
                          </button>
                          <span
                            className="deleteBtn"
                            onClick={() => {
                              onDelete(item?._id);
                            }}
                          >
                            <RiDeleteBin6Line />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="container mobile dataTable ms-0 ps-0">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Order</th>
                    <th scope="col">Service</th>
                    <th scope="col">Salon Price</th>
                    <th scope="col">Home Price</th>
                    <th scope="col">Status</th>
                    <th scope="col">Image</th>
                    <th scope="col">Service Id</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {services?.map((item, i) => {
                    return (
                      <tr key={item?._id}>
                        <td>{item?.order}</td>
                        <td>
                          {item?.name}
                          <br />
                          {item?.category?.name}
                        </td>
                        {salonDetails?.country?.htmlSymbol !== "" ? (
                          <td>
                            {item?.isSalon
                              ? `${String.fromCharCode(
                                  salonDetails?.country?.htmlSymbol
                                )} ${item?.salonDiscountPrice}`
                              : "--"}
                          </td>
                        ) : (
                          <td>
                            {item?.isSalon
                              ? `${salonDetails?.country?.currency} ${item?.salonDiscountPrice}`
                              : "--"}
                          </td>
                        )}
                        {salonDetails?.country?.htmlSymbol !== "" ? (
                          <td>
                            {item?.isHome
                              ? `${String.fromCharCode(
                                  salonDetails?.country?.htmlSymbol
                                )} ${item?.homeDiscountPrice}`
                              : "--"}
                          </td>
                        ) : (
                          <td>
                            {item?.isHome
                              ? `${salonDetails?.country?.currency} ${item?.homeDiscountPrice}`
                              : "--"}
                          </td>
                        )}
                        <td>
                          <p
                            className="status mb-0"
                            style={{
                              backgroundColor: `${
                                item?.isActive === true
                                  ? "var(--status_color2)"
                                  : "var(--status_color1)"
                              }`,
                            }}
                          >
                            {`${
                              item?.isActive === true ? "Active" : "Inactive"
                            }`}
                          </p>
                        </td>
                        <td>
                          {item?.image === null ? (
                            <img
                              src={serviceImage}
                              alt="service"
                              width={72}
                              height={48}
                              style={{ borderRadius: "4px" }}
                            />
                          ) : (
                            <img
                              src={`${process.env.REACT_APP_Image_BaseURL}${item?.image}`}
                              alt="service"
                              width={72}
                              height={48}
                              style={{ borderRadius: "4px" }}
                            />
                          )}
                        </td>
                        <td>{item?.serviceId}</td>
                        <td>
                          <button
                            className="btn font14x400 filterBtn shadow-none me-2"
                            onClick={() => {
                              onEdit(item, "add_service");
                            }}
                          >
                            Edit
                          </button>
                          <span
                            className="deleteBtn"
                            onClick={() => {
                              onDelete(item?._id);
                            }}
                          >
                            <RiDeleteBin6Line />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Service Found"
            )}
          </div>
        )}

        {services?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      {/* delete modal */}
      {isDeleteModalOpen && (
        <DeleteModal
          state={isDeleteModalOpen}
          setState={setIsDeleteModalOpen}
          url={SALON_SERVICES_DELETE}
          id={id}
          text="Service"
          apiCall={getData}
        />
      )}

      {/* service form add and update both */}
      {commonData.modal && commonData.check === "add_service" && (
        <ServiceForm
          data={category}
          loader={loaderSmall}
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
          brandData={brands}
          serviceNameData={serviceNames}
          id={salonDetails._id}
          currencySymbol={salonDetails?.address?.country}
        />
      )}

      {commonData.modal && commonData.check === "upload_service" && (
        <UploadServiceForm
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
          id={salonDetails?._id}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default Services;
