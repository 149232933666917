import React, { useRef, useState } from "react";
import Notification from "./Components/Notification";
import { onMessageListener } from "./firebase";
import AllRoutes from "./Routes";
import { MASTER_SALON } from "./utils/Constants";
import mySound from "./uploads/Sound/notificationSound.mp3";
import { useLocation } from "react-router-dom";

const App = () => {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({});
  const role = JSON.parse(localStorage.getItem("role"));
  const audioPlayer = useRef(null);
  const { pathname } = useLocation();

  if (!pathname.includes("/registration") && role === MASTER_SALON) {
    onMessageListener()
      .then((payload) => {
        setShow(true);
        setNotification({});
        setNotification({
          title: payload.data.title,
          body: payload.data.body,
          link: payload.data.link,
        });
        console.log(payload);
        playAudio();
      })
      .catch((err) => console.log("failed: ", err));
  }

  function playAudio() {
    audioPlayer.current.play();
  }

  return (
    <>
      <AllRoutes />

      {!pathname.includes("/registration") && role === MASTER_SALON && (
        <audio ref={audioPlayer} src={mySound} autoPlay={false} />
      )}

      {!pathname.includes("/registration") && role === MASTER_SALON && show && (
        <Notification
          notification={notification}
          show={show}
          setShow={setShow}
        />
      )}
    </>
  );
};

export default App;
