import React from "react";

const UnblockAllSlots = ({ state, setState, data }) => {
  const functions = {
    putAllTimeIntoState: (e) => {
      if (state?.time) {
        if (e.target.checked) {
          const time = state?.time?.map((item) => {
            return {
              ...item,
              status: 1,
            };
          });
          setState({
            ...state,
            unblockAllStots: e.target.checked,
            time: time,
          });
        } else {
          //   const time = state?.time?.map((item) => {
          //     return {
          //       ...item,
          //       status: 0,
          //     };
          //   });
          setState({
            ...state,
            unblockAllStots: e.target.checked,
            time: data,
          });
        }
      }
      // if (data) {
      //   if (e.target.checked) {
      //     data?.map((itm) => {
      //       if (itm?.status !== 0) {
      //         setState((old) => {
      //           return {
      //             ...old,
      //             unblockAllStots: e.target.checked,
      //             time: [...old.time, itm.time],
      //           };
      //         });
      //       }
      //     });
      //   } else {
      //     setState({
      //       ...state,
      //       unblockAllStots: e.target.checked,
      //       time: [],
      //     });
      //   }
      // }
    },
  };

  return (
    <>
      <div className="mb-3">
        <div className="agreeCheck form-check d-flex align-items-center p-0">
          <input
            type="checkbox"
            className="me-2"
            id="exampleCheck2"
            name="disableDate"
            checked={state.blockAllStots}
            onChange={(e) => {
              functions.putAllTimeIntoState(e);
            }}
          />
          <label
            className="font14x400 form-check-label"
            htmlFor="exampleCheck2"
          >
            Unblock All Slots
          </label>
        </div>
      </div>
    </>
  );
};

export default UnblockAllSlots;
