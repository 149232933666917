import React from "react";
import HomeServiceOrders from "./HomeServiceOrders";
import SalonServiceOrders from "./SalonServiceOrders";
import TotalProductOrders from "./TotalProductOrders";
import TotalServiceOrders from "./TotalServiceOrders";

const AllDataOrders = (props) => {
  const { ordersCount, loader } = props;
  return (
    <>
      <div className="order-graph-container paymentsStatsWrapper mt-3 me-3">
        <h3 className="font22x500 pageHeading mb-4">Orders</h3>
        <div className="tabsWrapper">
          <SalonServiceOrders ordersCount={ordersCount} loader={loader} />
          <HomeServiceOrders ordersCount={ordersCount} loader={loader} />
          <TotalServiceOrders ordersCount={ordersCount} loader={loader} />
          <TotalProductOrders ordersCount={ordersCount} loader={loader} />
        </div>
      </div>
    </>
  );
};

export default AllDataOrders;
