import React from "react";
import Header from "../../Components/Header";
import Sidenav from "../../Components/Sidenav";
import {
  CHILD_SALON,
  MASTER_SALON,
  MASTER_SALON_USER,
} from "../../utils/Constants";
import HeaderAdmin from "../Header/HeaderAdmin";

const Layout = () => {
  const role = JSON.parse(localStorage.getItem("role"));
  return (
    <>
      <Sidenav />
      {role === MASTER_SALON ||
      role === CHILD_SALON ||
      role === MASTER_SALON_USER ? (
        <Header />
      ) : (
        <HeaderAdmin />
      )}
    </>
  );
};

export default Layout;
