import React, { useEffect } from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";
import useAuthContext from "../../hooks/useAuthContext";
import useFetch from "../../hooks/useFetch";
import { LOGGED_IN_USER_ADDRESSES } from "../../utils/Constants";
import Loader from "../Loader";
import AddressesList from "./AddressesList";

const ShippingAddress = ({ state, setState }) => {
  const setPositionToBody = () => {
    setState({
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const { token } = useAuthContext();

  const [userAddresses, totalResult, error, loader, getData] = useFetch({
    url: `${LOGGED_IN_USER_ADDRESSES}`,
    resultField: "userAddresses",
  });
  useEffect(() => {
    getData();
  }, [token]);

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv"></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">Select Shipping Address</h1>
            <span
              onClick={() => {
                setState({
                  ...state,
                  step: 3,
                });
              }}
            >
              <HiOutlineArrowLeft />
            </span>
          </div>
          <div className="formContent">
            {/* add new address button */}
            <button
              type="Submit"
              className="btn filterBtnBig shadow-none my-3 font16x500"
              onClick={() => {
                setState({
                  ...state,
                  step: 5,
                  data: undefined,
                  comingFrom: "cart",
                });
              }}
            >
              Add New Address
            </button>

            {loader ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: "100%", padding: "20px 0 20px 0" }}
              >
                <Loader height="40" width="40" color="#772286" />
              </div>
            ) : (
              userAddresses?.map((address, i) => {
                return (
                  <AddressesList
                    address={address}
                    key={i}
                    index={i}
                    state={state}
                    setState={setState}
                    getData={getData}
                  />
                );
              })
            )}

            {/* proceed button */}
            {/* <button
              type="Submit"
              className="btn OtpBtn shadow-none mt-4 font16x500"
              onClick={() => {
                setState({
                  ...state,
                  step: 3,
                });
              }}
            >
              Proceed
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingAddress;
