import React, { useContext } from "react";
import { CartContext } from "../../utils/Private Routes/MasterSalonPrivateRoutes";
import Loader from "../Loader";

const CartHeader = ({ onClick, displayOn }) => {
  const { cart, cartLoader } = useContext(CartContext);

  return (
    <>
      {displayOn === "header" ? (
        <div className="d-flex align-items-center" onClick={onClick}>
          <div className="cartImageWrapper">
            <img src="/Assets/cart.svg" alt="cart" width={48} height={48} />
            {cartLoader ? (
              <div className="d-flex cartCount align-items-center">
                <Loader height="10" width="10" color="#fff" />
              </div>
            ) : (
              <div className="cartCount font12x500">
                {cart
                  ? cart?.products?.length === 0
                    ? 0
                    : cart?.products?.length
                  : 0}
              </div>
            )}
          </div>
          <p className="mb-0 font16x500 ms-2">Cart</p>
        </div>
      ) : (
        <div className="cartImageWrapperPage" onClick={onClick}>
          <img src="/Assets/cart.svg" alt="cart" width={48} height={48} />
          {cartLoader ? (
            <div className="d-flex cartCount align-items-center">
              <Loader height="10" width="10" color="#fff" />
            </div>
          ) : (
            <div className="cartCount font12x500">
              {cart
                ? cart?.products?.length === 0
                  ? 0
                  : cart?.products?.length
                : 0}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CartHeader;
