import React from "react";
import Loader from "../../Loader";

const SlotTimeSelection = ({ state, setState, data, loader }) => {
  const functions = {
    getTime: (id) => {
      // if (state.time.includes(inputTime)) {
      //   const newData = state.time.filter((ele) => {
      //     return ele !== inputTime;
      //   });
      //   setState({
      //     ...state,
      //     time: newData,
      //   });
      // } else {
      //   setState({
      //     ...state,
      //     time: [...state.time, inputTime],
      //   });
      // }

      const time = state?.time?.map((item) => {
        if (item._id === id) {
          return {
            ...item,
            status: item?.status === 1 ? 0 : 1,
          };
        }
        return item;
      });
      setState({
        ...state,
        time: time,
      });
    },
  };

  return (
    <>
      <label className="font16x500 form-label mb-3 labelBig">
        Pick a slot to Unblock*
      </label>
      {state?.time ? (
        <div className="checkBoxContainer">
          {state?.time?.map((itm, i) => {
            return (
              <button
                key={i}
                type="button"
                className={`${
                  itm?.status === 0
                    ? "slotTimePicker slotTimePickerSelected"
                    : "slotTimePicker"
                }`}
                value={state.time}
                onClick={() => functions.getTime(itm?._id)}
                // disabled={itm?.status === 1 && true}
              >
                {itm?.time}
              </button>
            );
          })}
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100%", padding: "20px 0 20px 0" }}
        >
          {loader ? (
            <Loader height="40" width="40" color="#772286" />
          ) : (
            "Not Found :("
          )}
        </div>
      )}
    </>
  );
};

export default SlotTimeSelection;
