import React from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "../Pages/Common/Error 404";
import Login from "../Pages/Common/Login";
import Dashboard from "../Pages/Salon/Master Salon/Dashboard";
import RegistrationForm from "../Pages/Salon/Master Salon/RegistrationForm";
import Profile from "../Pages/Salon/Master Salon/Profile";
import Services from "../Pages/Salon/Master Salon/Services";
import Products from "../Pages/Salon/Master Salon/Products";
import AllSalons from "../Pages/Admin/Salons";
import Stylist from "../Pages/Salon/Master Salon/Stylist";
import Payment from "../Pages/Salon/Master Salon/Payment";
import AdminPrivateRoutes from "../utils/Private Routes/AdminPrivateRoutes";
import MasterSalonPrivateRoutes from "../utils/Private Routes/MasterSalonPrivateRoutes";
import AdminDashboard from "../Pages/Admin/Dashboard";
import ProductOrder from "../Pages/Admin/Orders/Product";
import ServiceOrder from "../Pages/Admin/Orders/Service";
import BannerLink from "../Pages/Admin/Home Page/Banner&Link";
import Coupons from "../Pages/Admin/Home Page/Coupons";
import OfferDeals from "../Pages/Admin/Home Page/Offer&Deals";
import Users from "../Pages/Admin/Users";
import AddProduct from "../Pages/Admin/Add Product";
import ReviewRating from "../Pages/Salon/Master Salon/Reviews&Ratings";
import ViewOrderProduct from "../Pages/Admin/Orders/Product/viewOrderProduct";
import ViewOrderService from "../Pages/Admin/Orders/Service/viewOrderService";
import Transaction from "../Pages/Admin/Transactions";
import SalonTransaction from "../Pages/Salon/Master Salon/Transactions";
import Faq from "../Pages/Admin/Home Page/FAQ";
import DynamicPages from "../Pages/Admin/Home Page/Dynamic Pages";
import DisableSlot from "../Pages/Salon/Master Salon/DisableSlot";
import SalonServiceOrder from "../Pages/Salon/Master Salon/Order/Service";
import SalonProductOrder from "../Pages/Salon/Master Salon/Order/Product";
import ViewSalonProductOrder from "../Pages/Salon/Master Salon/Order/Product/ViewSalonProductOrder";
import ViewSalonServiceOrder from "../Pages/Salon/Master Salon/Order/Service/ViewSalonServiceOrder";
import SalonCategory from "../Pages/Admin/Home Page/Categories/Salon";
import ServiceCategory from "../Pages/Admin/Home Page/Categories/Service";
import ProductCategory from "../Pages/Admin/Home Page/Categories/Product";
import BuyProduct from "../Pages/Salon/Master Salon/Buy Product";
import Offer from "../Pages/Salon/Master Salon/Offer";
import SalonCoupons from "../Pages/Admin/Home Page/Salon Coupons";
import SalonQRProductOrder from "../Pages/Salon/Master Salon/Order/QR Product Order";
import ViewSalonQRProductOrder from "../Pages/Salon/Master Salon/Order/QR Product Order/ViewSalonProductOrder";
import SalonQRServiceOrder from "../Pages/Salon/Master Salon/Order/QR Service Order";
import ViewSalonQRServiceOrder from "../Pages/Salon/Master Salon/Order/QR Service Order/ViewSalonServiceOrder";
import QRProductOrder from "../Pages/Admin/Orders/QR Product Order";
import ViewQROrderProduct from "../Pages/Admin/Orders/QR Product Order/viewOrderProduct";
import ViewQROrderService from "../Pages/Admin/Orders/QR Service Order/viewOrderService";
import QRServiceOrder from "../Pages/Admin/Orders/QR Service Order";
import AdminProductOrder from "../Pages/Admin/Orders/Admin Order";
import ViewAdminOrderProduct from "../Pages/Admin/Orders/Admin Order/viewOrderProduct";
import AdminTransaction from "../Pages/Admin/Admin Transactions";
import Wallet from "../Pages/Salon/Master Salon/Wallet";
import SalonBooking from "../Pages/Salon/Master Salon/Booking";
import YourOrder from "../Pages/Salon/Master Salon/Order/YourOrder";
import ViewSalonOrder from "../Pages/Salon/Master Salon/Order/YourOrder/ViewSalonOrder";
import Brand from "../Pages/Admin/Home Page/Categories/Brand";
import ProductTransaction from "../Pages/Salon/Master Salon/Products/Product Transactions";
import Clients from "../Pages/Salon/Master Salon/Clients";
import ServiceName from "../Pages/Admin/Home Page/Categories/Service Name";
import ProductTransactionAdmin from "../Pages/Admin/Add Product/Product Transactions";
import Membership from "../Pages/Admin/Membership";
import MembershipOrder from "../Pages/Admin/Orders/Membership Order";
import MembershipTransaction from "../Pages/Admin/Transactions/Membership Transaction";
import TemporaryUsers from "../Pages/Admin/Users/Temporary Users";
import Lead from "../Pages/Admin/Lead";
import CountryView from "../Pages/Admin/Home Page/Categories/Country/Index";

const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />

        {/*--- Master Store routes ---*/}
        <Route element={<MasterSalonPrivateRoutes />}>
          <Route path="/salon/dashboard" element={<Dashboard />} />
          <Route path="/registration" element={<RegistrationForm />} />
          <Route path="/salon/store" element={<Profile />} />
          <Route path="/salon/service" element={<Services />} />
          <Route path="/salon/product" element={<Products />} />
          <Route path="/salon/clients" element={<Clients />} />
          <Route
            path="/salon/product-transactions"
            element={<ProductTransaction />}
          />
          <Route path="/salon/your-orders" element={<YourOrder />} />
          <Route path="/salon/your-orders/:id" element={<ViewSalonOrder />} />
          <Route path="/salon/order/product" element={<SalonProductOrder />} />
          <Route
            path="/salon/order/product/:id"
            element={<ViewSalonProductOrder />}
          />
          <Route path="/salon/order/service" element={<SalonServiceOrder />} />
          <Route
            path="/salon/order/service/:id"
            element={<ViewSalonServiceOrder />}
          />
          <Route
            path="/salon/qr-order/product"
            element={<SalonQRProductOrder />}
          />
          <Route
            path="/salon/qr-order/product/:id"
            element={<ViewSalonQRProductOrder />}
          />
          <Route
            path="/salon/qr-order/service"
            element={<SalonQRServiceOrder />}
          />
          <Route
            path="/salon/qr-order/service/:id"
            element={<ViewSalonQRServiceOrder />}
          />
          <Route path="/salon/stylist" element={<Stylist />} />
          <Route path="/salon/review&rating" element={<ReviewRating />} />
          <Route path="/salon/booking" element={<SalonBooking />} />
          <Route path="/salon/buy/product" element={<BuyProduct />} />
          <Route path="/salon/disableSlot" element={<DisableSlot />} />
          <Route path="/salon/payment" element={<Payment />} />
          <Route path="/salon/offer" element={<Offer />} />
          <Route path="/salon/wallet" element={<Wallet />} />
        </Route>

        {/*--- Admin routes ---*/}
        <Route element={<AdminPrivateRoutes />}>
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/lead" element={<Lead />} />
          <Route path="/admin/order/product" element={<ProductOrder />} />
          <Route
            path="/admin/order/product/:id"
            element={<ViewOrderProduct />}
          />
          <Route path="/admin/order/service" element={<ServiceOrder />} />
          <Route
            path="/admin/order/service/:id"
            element={<ViewOrderService />}
          />
          <Route path="/admin/qr-order/product" element={<QRProductOrder />} />
          <Route
            path="/admin/qr-order/product/:id"
            element={<ViewQROrderProduct />}
          />
          <Route path="/admin/qr-order/service" element={<QRServiceOrder />} />
          <Route
            path="/admin/qr-order/service/:id"
            element={<ViewQROrderService />}
          />
          <Route
            path="/admin/admin-order/product"
            element={<AdminProductOrder />}
          />
          <Route
            path="/admin/admin-order/product/:id"
            element={<ViewAdminOrderProduct />}
          />
          <Route path="/admin/membership-order" element={<MembershipOrder />} />
          <Route path="/admin/banner&link" element={<BannerLink />} />
          <Route path="/admin/salonCategory" element={<SalonCategory />} />
          <Route path="/admin/serviceCategory" element={<ServiceCategory />} />
          <Route path="/admin/productCategory" element={<ProductCategory />} />
          <Route path="/admin/brand" element={<Brand />} />
          <Route path="/admin/country" element={<CountryView />} />
          <Route path="/admin/service-name" element={<ServiceName />} />
          <Route path="/admin/faq" element={<Faq />} />
          <Route path="/admin/dynamicPages" element={<DynamicPages />} />
          <Route path="/admin/app-coupons" element={<Coupons />} />
          <Route path="/admin/couponsForSalon" element={<SalonCoupons />} />
          <Route path="/admin/offer&deals" element={<OfferDeals />} />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/temporary-users" element={<TemporaryUsers />} />
          <Route path="/admin/addProduct" element={<AddProduct />} />
          <Route
            path="/admin/product-transactions"
            element={<ProductTransactionAdmin />}
          />
          <Route path="/admin/allsalons" element={<AllSalons />} />
          <Route path="/admin/transactions" element={<Transaction />} />
          <Route
            path="/admin/admin-transactions"
            element={<AdminTransaction />}
          />
          <Route path="/admin/membership" element={<Membership />} />
          <Route
            path="/admin/membership-transactions"
            element={<MembershipTransaction />}
          />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
