import React, { useRef, useState } from "react";
import useLoading from "../../../hooks/useLoading";
import { categorySchema } from "../../../utils/validationschema";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import axios from "axios";
import UpdationDetails from "../../../Shared Components/UpdationDetails";
import InputError from "../../../Shared Components/InputError";
import SubmitButton from "../../../Shared Components/SubmitButton";
import InputFieldLarge from "../../../Shared Components/InputFieldLarge";
import { CgClose } from "react-icons/cg";
import useAuthContext from "../../../hooks/useAuthContext";
import {
  authFormHeader,
  CREATE_SERVICE_CATEGORY,
  SERVICE_CATEGORY_UPDATE,
} from "../../../utils/Constants";
import RadioButton from "../../../Shared Components/RadioButton";

const ServiceCategoryForm = ({ state, setState, apiCall }) => {
  const initialValues = {
    title: state?.data?.name ?? "",
    order: state?.data?.order ?? 1,
    statusName: state?.data?.status === 1 ? "Active" : "Inactive",
    gender: state?.data?.gender ?? "Female",
    image: "",
  };
  const [oldImagePath, setoldImagePath] = useState("null");

  const formik = useFormik({
    initialValues,
    validationSchema: categorySchema,
    onSubmit: (data) => {
      // console.log("data", data);
      formSubmit(data);
    },
  });

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const serviceCatImage = useRef(null);
  const handleSingleImg = (e) => {
    e.preventDefault();
    serviceCatImage.current.click();
  };
  const [singleImage, setSingleImage] = useState(
    state?.data?.image
      ? `${process.env.REACT_APP_Image_BaseURL}${state?.data?.image}`
      : ""
  );
  const imageDataURL = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSingleImage(URL.createObjectURL(e.target.files[0]));
      formik.setFieldValue("image", Array.from(e.target.files));
    }
  };
  const deleteImage = (index) => {
    if (index === -1) {
      formik.setFieldValue("image", "");
      setSingleImage("");
      setoldImagePath(state?.data?.image ? state?.data?.image : null);
      serviceCatImage.current.value = "";
    }
  };

  const { token } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();

  const formSubmit = async (data) => {
    console.log("form submit called");

    //form data
    const sFormData = new FormData();
    sFormData.append("name", data.title);
    sFormData.append("image", data.image[0]);
    sFormData.append("order", data.order);
    sFormData.append("gender", data.gender);
    if (state.data) {
      sFormData.append("updateImage", oldImagePath);
    }
    // sFormData.append("status", data.statusName === "Active" ? 1 : 2);

    if (token) {
      if (state.data === undefined) {
        try {
          showLoader();
          const res = await axios.post(
            `${CREATE_SERVICE_CATEGORY}/form`,
            sFormData,
            {
              //headers: authFormHeader,
              headers: {
                "Content-type": "multipart/form-data",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Service Category Added", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      } else {
        try {
          showLoader();
          console.log("update api called");
          const res = await axios.put(
            `${SERVICE_CATEGORY_UPDATE}${state?.data?._id}/form`,
            sFormData,
            {
              //headers: authFormHeader,
              headers: {
                "Content-type": "multipart/form-data",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Service Category Updated", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">
              {!state.data ? "Add New Category" : "Update Category"}
            </h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit}>
              {/* title */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Title*"
                  htmlFor="title"
                  type="text"
                  name="title"
                  stateData={formik.values.title}
                  setStateData={formik.handleChange}
                />
                {formik.errors.title ? (
                  <InputError error={formik.errors.title} />
                ) : null}
              </div>

              {/* service category image */}
              <div className="mb-3">
                <div className="mb-0">
                  <label
                    htmlFor="formFile"
                    className="font12x500 mb-1 form-label"
                  >
                    Image
                  </label>
                  <input
                    ref={serviceCatImage}
                    className="form-control shadow-none"
                    type="file"
                    id="formFile"
                    name="image"
                    accept={".png, .jpg, .jpeg"}
                    hidden
                    onChange={(e) => {
                      imageDataURL(e);
                    }}
                  />
                </div>
                <div className="uploadImg d-flex align-items-center mb-3">
                  <div
                    className="uploadBtn font12x400 me-3"
                    onClick={(e) => {
                      handleSingleImg(e);
                    }}
                  >
                    Upload
                  </div>
                  <span className="font12x400">
                    Allowed file types: jpg, jpeg, png
                  </span>
                </div>
                {singleImage && (
                  <div className="imageWrapper">
                    <img
                      src={singleImage}
                      alt="delete"
                      width={61}
                      height={61}
                    />
                    <div
                      className="deleteDiv"
                      onClick={() => {
                        deleteImage(-1);
                      }}
                    >
                      <img src="/Assets/delete.svg" alt="delete" />
                    </div>
                  </div>
                )}
                {formik.errors.image ? (
                  <InputError error={formik.errors.image} />
                ) : null}
              </div>

              {/* gender type */}
              <div className="mb-4">
                <div className="mb-1">
                  <label
                    htmlFor="atsalon"
                    className="font12x500 form-label mb-1"
                  >
                    Gender Type*
                  </label>

                  <div className="container-fluid px-0">
                    <div className="row">
                      <RadioButton
                        label="Male"
                        htmlFor="gender"
                        name="gender"
                        value="Male"
                        stateData={formik.values.gender}
                        setStateData={(e) => {
                          formik.setFieldValue("gender", e);
                        }}
                      />

                      <RadioButton
                        label="Female"
                        htmlFor="female"
                        name="gender"
                        value="Female"
                        stateData={formik.values.gender}
                        setStateData={(e) => {
                          formik.setFieldValue("gender", e);
                        }}
                      />
                    </div>
                  </div>
                  {formik.errors.gender ? (
                    <InputError error={formik.errors.gender} />
                  ) : null}
                </div>
              </div>

              {/* order */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Order*"
                  htmlFor="order"
                  type="tel"
                  name="order"
                  stateData={formik.values.order}
                  setStateData={formik.handleChange}
                />
                {formik.errors.order ? (
                  <InputError error={formik.errors.order} />
                ) : null}
              </div>

              {/* status */}
              {/* <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: "Active", label: "Active" },
                    { value: "Inactive", label: "Inactive" },
                  ]}
                  label="Status*"
                  htmlFor="status"
                  type="text"
                  placeholder="Select Status"
                  name="statusName"
                  stateData={formik.values.statusName}
                  setStateData={(e) => {
                    formik.setFieldValue("statusName", e);
                  }}
                />
                {formik.errors.statusName ? (
                  <InputError error={formik.errors.statusName} />
                ) : null}
              </div> */}

              <SubmitButton
                data={!state.data}
                loader={loader}
                create="Submit"
                update="Update"
              />
            </form>

            {state?.data && <UpdationDetails data={state?.data} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceCategoryForm;
