import React, { useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import useAuthContext from "../../hooks/useAuthContext";
import {
  authHeader,
  DELETE_USER_ADDRESSES,
  UPDATE_USER_ADDRESSES,
} from "../../utils/Constants";
import DeleteModal from "../DeleteRecord";
import { toast } from "react-toastify";
import axios from "axios";

const AddressesList = ({ address, index, state, setState, getData }) => {
  const { token } = useAuthContext();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [id, setId] = useState("");
  const functions = {
    onDelete: (id) => {
      setIsDeleteModalOpen(true);
      document.body.style.overflow = "hidden";
      setId(id);
    },
    setDefaultAddress: async (id) => {
      if (token) {
        try {
          const res = await axios.put(
            `${UPDATE_USER_ADDRESSES}${id}`,
            {
              isDefault: true,
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setState({
              ...state,
              step: 3,
            });
            toast.success("Default Address Set", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
  };

  return (
    <>
      <div className="addressWrapper mt-3">
        <div className="addressWrapperHeader d-flex justify-content-between align-items-center">
          <h3 className="font14x500 mb-0">
            {address?.isDefault
              ? `Address ${index + 1} (Default)`
              : `Address ${index + 1}`}
          </h3>
          <div className="">
            <span
              className="editBtn"
              onClick={() => {
                setState({
                  ...state,
                  step: 5,
                  data: address,
                  comingFrom: "cart",
                });
              }}
            >
              <MdOutlineEdit />
            </span>
            <span
              className="deleteBtn ms-3"
              onClick={() => {
                functions.onDelete(address?._id);
              }}
              style={{ fontSize: 16 }}
            >
              <RiDeleteBin6Line />
            </span>
          </div>
        </div>
        <div className="addressContent font14x400">
          <div className="">
            <p className="mb-0">{address?.line_1}</p>
            {address?.line_2 !== "" && (
              <p className="mb-0">{address?.line_2}</p>
            )}
            <p className="mb-0">{address?.city}</p>
            <p className="mb-0">{address?.state}</p>
            <p className="mb-0">{address?.country}</p>
            <p className="mb-0">{address?.pincode}</p>
          </div>
          <div className="text-end">
            {!address?.isDefault && (
              <button
                className="btn setDefault shadow-none font12x400"
                onClick={() => {
                  functions.setDefaultAddress(address?._id);
                }}
              >
                Set as Default
              </button>
            )}

            {state?.selectedAddressId !== address?._id && (
              <button
                className="btn selectAdd shadow-none font12x400 ms-2"
                onClick={() => {
                  setState({
                    ...state,
                    step: 3,
                    shippingAddress: address,
                  });
                }}
              >
                Select Address
              </button>
            )}
          </div>
        </div>
      </div>

      {/* delete modal */}
      {isDeleteModalOpen && (
        <DeleteModal
          state={isDeleteModalOpen}
          setState={setIsDeleteModalOpen}
          state2={state}
          setState2={setState}
          url={DELETE_USER_ADDRESSES}
          id={id}
          text="Address"
          apiCall={getData}
        />
      )}
    </>
  );
};

export default AddressesList;
