import axios from "axios";
import React, { useRef, useState } from "react";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import useAuthContext from "../../../hooks/useAuthContext";
import DynamicDropdown from "../../../Shared Components/DynamicDropdown";
import InputFieldLarge from "../../../Shared Components/InputFieldLarge";
import StaticDropdown from "../../../Shared Components/StaticDropdown";
import UpdationDetails from "../../../Shared Components/UpdationDetails";
import {
  authFormHeader,
  OFFER_DEAL_ADD,
  OFFER_DEAL_UPDATE,
} from "../../../utils/Constants";
import { useFormik } from "formik";
import InputError from "../../../Shared Components/InputError";
import { offerSchema } from "../../../utils/validationschema";
import SubmitButton from "../../../Shared Components/SubmitButton";
import useLoading from "../../../hooks/useLoading";

const OfferForm = ({ state, setState, apiCall, data }) => {
  const initialValues = {
    title: state?.data?.title ?? "",
    couponCode: state?.data?.couponCode ?? "",
    order: state?.data?.order ?? 1,
    category: state?.data?.category?.name ?? "",
    statusName: state?.data?.isActive === true ? "Active" : "Inactive",
    description: state?.data?.description ?? "",
    image: "",
    terms: state?.data?.terms ?? "",
  };
  const [oldImagePath, setoldImagePath] = useState("null");

  const formik = useFormik({
    initialValues,
    validationSchema: offerSchema,
    onSubmit: (data) => {
      formSubmit(data);
    },
  });

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const offerImage = useRef(null);
  const handleSingleImg = (e) => {
    e.preventDefault();
    offerImage.current.click();
  };

  const [singleImage, setSingleImage] = useState(
    state?.data?.image
      ? `${process.env.REACT_APP_Image_BaseURL}${state?.data?.image}`
      : ""
  );
  const imageDataURL = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSingleImage(URL.createObjectURL(e.target.files[0]));
      formik.setFieldValue("image", Array.from(e.target.files));
    }
  };
  const deleteImage = (index) => {
    if (index === -1) {
      formik.setFieldValue("image", "");
      setSingleImage("");
      setoldImagePath(state?.data?.image ? state?.data?.image : null);
      offerImage.current.value = "";
    }
  };

  const { token } = useAuthContext();

  const [categoryId, setCategoryId] = useState(
    state?.data?.category?._id ?? ""
  );
  const [loader, showLoader, hideLoader] = useLoading();

  const formSubmit = async (data) => {
    console.log("form submit called");

    //form data
    const sFormData = new FormData();
    sFormData.append("title", data.title);
    sFormData.append("image", data.image[0]);
    sFormData.append("category", categoryId);
    sFormData.append("description", data.description);
    sFormData.append("order", data.order);
    sFormData.append("couponCode", data.couponCode);
    sFormData.append("terms", data.terms);
    sFormData.append("isActive", data.statusName === "Active" ? true : false);
    sFormData.append("status", data.statusName === "Active" ? 1 : 2);
    if (state.data) {
      sFormData.append("updateImage", oldImagePath);
    }

    if (token) {
      if (state.data === undefined) {
        try {
          showLoader();
          const res = await axios.post(`${OFFER_DEAL_ADD}`, sFormData, {
            //headers: authFormHeader,
            headers: {
              "Content-type": "multipart/form-data",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Offer Added", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      } else {
        try {
          showLoader();
          console.log("update api called");
          const res = await axios.put(
            `${OFFER_DEAL_UPDATE}${state.data._id}/form`,
            sFormData,
            {
              //headers: authFormHeader,
              headers: {
                "Content-type": "multipart/form-data",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Offer Updated", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">
              {!state.data ? "Create New Offer" : "Edit Offer"}
            </h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
              {/* Title* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Title*"
                  htmlFor="title"
                  type="text"
                  name="title"
                  stateData={formik.values.title}
                  setStateData={formik.handleChange}
                />
                {formik.errors.title ? (
                  <InputError error={formik.errors.title} />
                ) : null}
              </div>

              {/* Coupon Code* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Coupon Code*"
                  htmlFor="Coupon Code"
                  type="text"
                  name="couponCode"
                  stateData={formik.values.couponCode}
                  setStateData={formik.handleChange}
                />
                {formik.errors.couponCode ? (
                  <InputError error={formik.errors.couponCode} />
                ) : null}
              </div>

              {/* order */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Order*"
                  htmlFor="order"
                  type="tel"
                  name="order"
                  stateData={formik.values.order}
                  setStateData={formik.handleChange}
                  width="120px"
                />
                {formik.errors.order ? (
                  <InputError error={formik.errors.order} />
                ) : null}
              </div>

              {/* offer image */}
              <div className="mb-3">
                <div className="mb-0">
                  <label
                    htmlFor="formFile"
                    className="font12x500 mb-1 form-label"
                  >
                    Image
                  </label>
                  <input
                    ref={offerImage}
                    className="form-control shadow-none"
                    type="file"
                    id="formFile"
                    name="image"
                    accept={".png, .jpg, .jpeg"}
                    hidden
                    onChange={(e) => {
                      imageDataURL(e);
                    }}
                  />
                </div>
                <div className="uploadImg d-flex align-items-center mb-2">
                  <div
                    className="uploadBtn font12x400 me-3"
                    onClick={(e) => {
                      handleSingleImg(e);
                    }}
                  >
                    Upload
                  </div>
                  <span className="font12x400">
                    Allowed file types: jpg, jpeg, png
                  </span>
                </div>
                {singleImage && (
                  <div className="imageWrapper">
                    <img
                      src={singleImage}
                      alt="delete"
                      width={61}
                      height={61}
                    />
                    <div
                      className="deleteDiv"
                      onClick={() => {
                        deleteImage(-1);
                      }}
                    >
                      <img src="/Assets/delete.svg" alt="delete" />
                    </div>
                  </div>
                )}
                {formik.errors.image ? (
                  <InputError error={formik.errors.image} />
                ) : null}
              </div>

              {/* Category */}
              <div className="mb-3">
                <DynamicDropdown
                  data={data}
                  setCategoryId={setCategoryId}
                  label="Category*"
                  htmlFor="Category"
                  type="text"
                  name="category"
                  placeholder="Select Category"
                  stateData={formik.values.category}
                  setStateData={(e) => {
                    formik.setFieldValue("category", e);
                  }}
                />
                {formik.errors.category ? (
                  <InputError error={formik.errors.category} />
                ) : null}
              </div>

              {/* status */}
              <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: "Active", label: "Active" },
                    { value: "Inactive", label: "Inactive" },
                  ]}
                  label="Status*"
                  htmlFor="status"
                  type="text"
                  placeholder="Select Status"
                  name="statusName"
                  stateData={formik.values.statusName}
                  setStateData={(e) => {
                    formik.setFieldValue("statusName", e);
                  }}
                />
                {formik.errors.statusName ? (
                  <InputError error={formik.errors.statusName} />
                ) : null}
              </div>

              {/* description */}
              <div className="mb-3">
                <label
                  htmlFor="floatingTextarea2"
                  className="font12x500 mb-1 form-label"
                >
                  Description*
                </label>
                <textarea
                  className="form-control shadow-none"
                  id="floatingTextarea2"
                  style={{ height: "180px" }}
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
                {formik.errors.description ? (
                  <InputError error={formik.errors.description} />
                ) : null}
              </div>

              {/* Terms* */}
              <div className="mb-3">
                <label
                  htmlFor="floatingTextarea2"
                  className="font12x500 mb-1 form-label"
                >
                  Terms*
                </label>
                <textarea
                  className="form-control shadow-none"
                  id="floatingTextarea2"
                  style={{ height: "180px" }}
                  name="terms"
                  value={formik.values.terms}
                  onChange={formik.handleChange}
                />
                {formik.errors.terms ? (
                  <InputError error={formik.errors.terms} />
                ) : null}
              </div>

              <SubmitButton
                data={!state.data}
                loader={loader}
                create="Create"
                update="Update"
              />
            </form>

            {state?.data && <UpdationDetails data={state?.data} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferForm;
