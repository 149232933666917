import React, { useState } from "react";
import Loader from "../Components/Loader";

const useLoading = () => {
  const [loading, setLoading] = useState(false);
  return [
    loading && <Loader />,
    () => setLoading(true),
    () => setLoading(false),
  ];
};

export default useLoading;
