import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import InputFieldLarge from "./InputFieldLarge";

const CustomDropdown = ({
  formik,
  apiData,
  name,
  label,
  htmlFor,
  stateData,
}) => {
  const [data, setData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const fn = {
    openDropdown: () => {
      setIsOpen(!isOpen);
    },
    closeDropdown: () => {
      setIsOpen(false);
    },
    createFilterArray: (value) => {
      let array = apiData;
      const filterData = array?.filter((item) => {
        return item?.name?.toLowerCase().includes(value.toLowerCase());
      });
      setData(filterData);
    },
    setValueToState: (value) => {
      formik.setFieldValue(name, value);
      setIsOpen(false);
    },
  };

  useEffect(() => {
    fn.createFilterArray(stateData);
  }, [stateData]);

  return (
    <>
      <OutsideClickHandler onOutsideClick={fn.closeDropdown}>
        <div onClick={fn.openDropdown}>
          <InputFieldLarge
            label={label}
            htmlFor={htmlFor}
            type="text"
            name={name}
            stateData={stateData}
            setStateData={(e) => {
              formik.setFieldValue(name, e.target.value);
              fn.createFilterArray(e.target.value);
            }}
          />
        </div>
        {isOpen && data?.length !== 0 && (
          <div className="dropdownWrapper">
            <ul>
              {data?.map((item, i) => {
                return (
                  <li
                    key={i}
                    onClick={(e) => {
                      fn.setValueToState(e.target.innerText);
                    }}
                  >
                    {item?.name}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </OutsideClickHandler>
    </>
  );
};

export default CustomDropdown;
