import React, { useEffect, useCallback } from "react";
import { BiCurrentLocation } from "react-icons/bi";

const ChangeLocation = (props) => {
  const {
    setShowModal,
    google,
    handleLocation,
    latitude,
    longitude,
    setNewAddress,
    setIsChange,
  } = props;

  const callback = useCallback(() => {
    setIsChange(false);
  }, []);

  const renderMap = () => {
    const maps = google.maps;

    console.log("maps", maps);

    let map = new maps.Map(window.document.getElementById("map"), {
      center: {
        lat: Number(latitude),
        lng: Number(longitude),
      },
      zoom: 13,
    });

    let input = document.getElementById("places");
    let autocomplete = new maps.places.Autocomplete(input);
    autocomplete.bindTo("bounds", map);
    var marker = new google.maps.Marker({
      map: map,
      position: { lat: Number(latitude), lng: Number(longitude) },
      anchorPoint: new google.maps.Point(0, -29),
    });

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      map.setCenter(place.geometry.location);
      marker.setPosition(place.geometry.location);
      marker.setVisible(true);

      setNewAddress((old) => {
        return {
          ...old,
          lat: marker.getPosition().lat(),
          lng: marker.getPosition().lng(),
        };
      });

      handleLocation(marker.getPosition().lat(), marker.getPosition().lng());
      setShowModal(false);
    });
  };

  useEffect(() => {
    renderMap();
  }, []);

  useEffect(() => {
    // this is for mount
    setIsChange(true);

    // return for unmount
    return callback;
  }, []);

  return (
    <>
      <div className="modalContainer">
        <div
          className="modalBack"
          onClick={() => {
            setShowModal(false);
          }}
        ></div>
        <div className="modalContent">
          <div className="row">
            <div className="col-12">
              <div className="map" id="map"></div>
              <div className="locationWrapper d-flex align-items-center">
                <BiCurrentLocation />
                <input
                  type="text"
                  className="form-control shadow-none"
                  id="places"
                  placeholder="Search a location"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeLocation;
