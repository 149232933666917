import React from "react";
import Loader from "../../../Loader";

const TotalServiceRevenue = (props) => {
  const { revenueCalculation, loader } = props;
  let total =
    revenueCalculation?.servicesAtSalon + revenueCalculation?.servicesAtHome;
  const salonNew = JSON.parse(localStorage.getItem("salon_details"));

  return (
    <>
      <div className="statsTabWrapper">
        <p
          className="font14x500 mb-4"
          style={{
            color: "var(--text3)",
          }}
        >
          Total Service Revenue
        </p>
        <span
          className="font36x700"
          style={{
            color: "var(--text2)",
          }}
        >
          {loader ? (
            <span className="d-flex align-items-center">
              {`${
                salonNew?.country?.htmlSymbol !== ""
                  ? String.fromCharCode(salonNew?.country?.htmlSymbol)
                  : salonNew?.country?.currency
              } 0`}
              <span className="ms-2">
                <Loader height="20" width="20" color="#772286" />
              </span>
            </span>
          ) : (
            `${
              salonNew?.country?.htmlSymbol !== ""
                ? String.fromCharCode(salonNew?.country?.htmlSymbol)
                : salonNew?.country?.currency
            }${parseInt(total).toLocaleString()}`
          )}
        </span>
      </div>
    </>
  );
};

export default TotalServiceRevenue;
