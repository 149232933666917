import React, { useEffect, useState } from "react";
import Layout from "../../../Components/Layout";
import { RiDeleteBin6Line } from "react-icons/ri";
import useFetch from "../../../hooks/useFetch";
import { MEMBERSHIP_DELETE, MEMBERSHIP_LIST } from "../../../utils/Constants";
import { ToastContainer } from "react-toastify";
import DeleteModal from "../../../Components/DeleteRecord";
import Loader from "../../../Components/Loader";
import Pagination from "../../../Components/Pagination";
import MembershipForm from "../../../Components/Admin/Membership Form";
import DropdownSmall from "../../../Shared Components/DropdownSmall";
import MobileSalonDashboardHeader from "../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileFilterHeader from "../../../Components/mobilefilterheader";
import MobileMembershipFilters from "../../../Components/Mobile/Admin/mobileMembershipFilter";

const Membership = () => {
  const [commonData, setCommonData] = useState({
    data: {},
    modal: false,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState("");

  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
  });
  console.log(filters);

  const [param, setParam] = useState({ show: false });
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const functions = {
    onEdit: (item) => {
      setCommonData({
        data: item,
        modal: true,
      });
      document.body.style.overflow = "hidden";
    },
    onDelete: (id) => {
      console.log("id:", id);
      setIsModalOpen(true);
      document.body.style.overflow = "hidden";
      setId(id);
    },
    onAdd: () => {
      setCommonData({
        data: undefined,
        modal: true,
      });
      document.body.style.overflow = "hidden";
    },
    addParam: () => {
      setFilter({
        ...filters,
        ...param,
      });
    },
    resetParam: () => {
      setParam({});
      delete filters.statusName;
      delete filters.status;
      getData();
    },
  };

  const [memberships, totalResult, error, loader, getData] = useFetch({
    url: `${MEMBERSHIP_LIST}`,
    resultField: "memberships",
    parameter: filters,
  });

  useEffect(() => {
    window.localStorage.removeItem("salon_details");
    getData();
  }, [filters]);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Membership"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileMembershipFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          onClick={functions.onAdd}
          resetParam={functions.resetParam}
          filters={filters}
          setFilter={setFilter}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Membership"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Membership"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper clients-page">
        <div>
          <div className="container ms-0 ps-0 d-flex justify-content-between align-items-center mb-4">
            <h3 className="font22x500 pageHeading mb-0">Membership List</h3>
            <div className="desktopButton">
              <button
                className="btn addBtn shadow-none"
                onClick={functions.onAdd}
              >
                Create Membership
              </button>
            </div>
          </div>

          <div className="filter-container container mt-4 ms-0 ps-0 mb-3">
            <div className="d-flex align-items-end">
              {/* <SearchInput2
                  type="text"
                  placeholder="Search by title"
                  stateData={param}
                  setStateData={setParam}
                  parameter1="title"
                  parameter2={undefined}
                  addParam={functions.addParam}
                  resetParam={functions.resetParam}
                /> */}
              {/* status */}
              <DropdownSmall
                data={[
                  { value: "All", label: "All" },
                  { value: "Active", label: "Active" },
                  { value: "Inactive", label: "Inactive" },
                ]}
                label="Status"
                placeholder="Id Status"
                htmlFor="status"
                type="text"
                stateData={filters?.statusName}
                setStateData={(e) => {
                  if (e === "All") {
                    delete filters?.status;
                    setFilter({
                      ...filters,
                      statusName: e,
                    });
                  } else {
                    setFilter({
                      ...filters,
                      statusName: e === "Active" ? "Active" : "Inactive",
                      status: e === "Active" ? 1 : 2,
                    });
                  }
                }}
              />

              <button
                className="btn resetBtn shadow-none ms-3"
                onClick={functions.resetParam}
              >
                Reset
              </button>
            </div>
          </div>
        </div>

        {memberships?.length !== 0 ? (
          <div className="container dataTable ms-0 ps-0">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Package Name</th>
                  <th scope="col">Price</th>
                  <th scope="col">Validity</th>
                  <th scope="col">Service Discount</th>
                  <th scope="col">Product Discount</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {memberships?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item?.name}</td>
                      <td>{item?.price}</td>
                      <td>{`${item?.validity} days`}</td>
                      <td>{`${item?.serviceDiscount}% off`}</td>
                      <td>{`${item?.productDiscount}% off`}</td>
                      <td>
                        <p
                          className="status mb-0"
                          style={{
                            backgroundColor: `${
                              item?.status === 1
                                ? "var(--status_color2)"
                                : "var(--status_color1)"
                            }`,
                          }}
                        >
                          {`${item?.status === 1 ? "Active" : "Inactive"}`}
                        </p>
                      </td>
                      <td>
                        <button
                          className="btn font14x400 filterBtn shadow-none me-2"
                          onClick={() => {
                            functions.onEdit(item);
                          }}
                        >
                          Edit
                        </button>
                        <span
                          className="deleteBtn"
                          onClick={() => {
                            functions.onDelete(item?._id);
                          }}
                        >
                          <RiDeleteBin6Line />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Membership Found"
            )}
          </div>
        )}

        {memberships?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      {/* delete modal */}
      {isModalOpen && (
        <DeleteModal
          state={isModalOpen}
          setState={setIsModalOpen}
          url={MEMBERSHIP_DELETE}
          id={id}
          text="Membership"
          apiCall={getData}
        />
      )}

      {/* Membership form add and update both */}
      {commonData.modal && (
        <MembershipForm
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default Membership;
