import React, { useContext } from "react";
import { registrationContext } from "../../Pages/Salon/Master Salon/RegistrationForm";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";

const MainFile = () => {
  const { step } = useContext(registrationContext);

  switch (step) {
    case 1:
      return (
        <main>
          <div className="container">
            <div className="row ps-3 pe-3">
              <div className="registerContainer col-12 col-lg-6">
                <div>
                  <h3 className="font22x500">Step {step}</h3>
                </div>
                <div className="mt-4">
                  <Step1 />
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    case 2:
      return (
        <main>
          <div className="container">
            <div className="row ps-3 pe-3">
              <div className="registerContainer col-12 col-lg-6">
                <div>
                  <h3 className="font22x500">Step {step}</h3>
                </div>
                <div className="mt-4">
                  <Step2 />
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    case 3:
      return (
        <main>
          <div className="container">
            <div className="row ps-3 pe-3">
              <div className="registerContainer col-12 col-lg-6">
                <div>
                  <h3 className="font22x500">Step {step}</h3>
                </div>
                <div className="mt-4">
                  <Step3 />
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    default: //nothing to render
  }
};

export default MainFile;
