import React from "react";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import InputError from "../../Shared Components/InputError";
import { useFormik } from "formik";
import {
  cancelOrderAdminSchema,
  serviceSchema,
} from "../../utils/validationschema";
import useLoading from "../../hooks/useLoading";
import Loader from "../Loader";
import useAuthContext from "../../hooks/useAuthContext";
import axios from "axios";
import { ADMIN_ORDER_UPDATE, authHeader } from "../../utils/Constants";

const CancelOrderForm = ({ state, setState, apiCall }) => {
  const [loader, showLoader, hideLoader] = useLoading();
  const { token } = useAuthContext();

  console.log("state", state);

  const initialValues = {
    cancelReason: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: cancelOrderAdminSchema,
    onSubmit: (data) => {
      functions.formSubmit(data);
      // console.log("data", data);
    },
  });

  const functions = {
    setPositionToBody: () => {
      setState({
        ...state,
        modal: false,
      });
      document.body.style.overflow = "unset";
    },
    formSubmit: async (data) => {
      if (token) {
        try {
          showLoader();
          const res = await axios.put(
            `${ADMIN_ORDER_UPDATE}${state?.data?._id}`,
            {
              orderProcessingStatus: 4,
              cancelReason: data?.cancelReason,
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );
          if (res.status === 200) {
            apiCall();
            functions.setPositionToBody();
            hideLoader();
            toast.success("Order Cancelled", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
  };

  return (
    <>
      <div
        className={
          state?.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={functions.setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">Cancel your order</h1>
            <span onClick={functions.setPositionToBody}>
              <CgClose />
            </span>
          </div>

          <div className="formContent">
            <form onSubmit={formik.handleSubmit}>
              {/* cancelReason */}
              <div className="mb-3">
                <label
                  htmlFor="floatingTextarea2"
                  className="font12x500 mb-1 form-label"
                >
                  Reason for cancellation? (optional)
                </label>
                <textarea
                  className="form-control shadow-none"
                  id="floatingTextarea2"
                  style={{ height: "180px" }}
                  name="cancelReason"
                  value={formik.values.cancelReason}
                  onChange={formik.handleChange}
                />
                {formik.errors.cancelReason ? (
                  <InputError error={formik.errors.cancelReason} />
                ) : null}
              </div>

              <button
                type="submit"
                className="btn OtpBtn shadow-none font16x500"
              >
                {loader ? (
                  <div className="d-flex justify-content-center">
                    <Loader height="25" width="25" color="#fff" />
                  </div>
                ) : (
                  "Cancel Order"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancelOrderForm;
