import { useFormik } from "formik";
import React from "react";
import useAuthContext from "../../hooks/useAuthContext";
import useLoading from "../../hooks/useLoading";
import InputError from "../../Shared Components/InputError";
import InputFieldLarge from "../../Shared Components/InputFieldLarge";
import StaticDropdown from "../../Shared Components/StaticDropdown";
import SubmitButton from "../../Shared Components/SubmitButton";
import UpdationDetails from "../../Shared Components/UpdationDetails";
import { SalonOfferSchema } from "../../utils/validationschema";
import { CgClose } from "react-icons/cg";
import {
  authHeader,
  SALON_OFFER_CREATE,
  SALON_OFFER_UPDATE,
} from "../../utils/Constants";
import { toast } from "react-toastify";
import axios from "axios";

const SalonOfferForm = ({ state, setState, apiCall, id }) => {
  const initialValues = {
    title: state?.data?.title ?? "",
    order: state?.data?.order ?? 1,
    statusName: state?.data?.status === 1 ? "Active" : "Inactive",
    description: state?.data?.description ?? "",
    details: state?.data?.offerDetails ?? "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: SalonOfferSchema,
    onSubmit: (data) => {
      // console.log("data", data);
      formSubmit(data);
    },
  });

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.overflow = "unset";
  };
  const { token } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();

  const formSubmit = async (data) => {
    console.log("form submit called");

    //form data
    // const sFormData = new FormData();
    // sFormData.append("title", data.title);
    // sFormData.append("description", data.description);
    // sFormData.append("offerDetails", data.details);
    // sFormData.append("isActive", data.statusName === "Active" ? true : false);
    // sFormData.append("status", data.statusName === "Active" ? 1 : 0);

    if (token) {
      if (state.data === undefined) {
        try {
          showLoader();
          const res = await axios.post(
            `${SALON_OFFER_CREATE}${!state.data ? id : state.data._id}/offer`,
            {
              title: data.title,
              order: data.order,
              description: data.description,
              status: data.statusName === "Active" ? 1 : 2,
              offerDetails: data.details,
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Offer Added", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      } else {
        try {
          showLoader();
          console.log("update api called");
          const res = await axios.put(
            `${SALON_OFFER_UPDATE}${state.data._id}`,
            {
              title: data.title,
              order: data.order,
              description: data.description,
              status: data.statusName === "Active" ? 1 : 2,
              offerDetails: data.details,
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Offer Updated", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">
              {!state.data ? "Create New Offer" : "Edit Offer"}
            </h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
              {/* Title* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Title*"
                  htmlFor="title"
                  type="text"
                  name="title"
                  stateData={formik.values.title}
                  setStateData={formik.handleChange}
                />
                {formik.errors.title ? (
                  <InputError error={formik.errors.title} />
                ) : null}
              </div>

              {/* description* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Description*"
                  htmlFor="description"
                  type="text"
                  name="description"
                  stateData={formik.values.description}
                  setStateData={formik.handleChange}
                />
                {formik.errors.description ? (
                  <InputError error={formik.errors.description} />
                ) : null}
              </div>

              {/* order */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Order*"
                  htmlFor="order"
                  type="tel"
                  name="order"
                  stateData={formik.values.order}
                  setStateData={formik.handleChange}
                  width="120px"
                />
                {formik.errors.order ? (
                  <InputError error={formik.errors.order} />
                ) : null}
              </div>

              {/* details */}
              <div className="mb-3">
                <label
                  htmlFor="floatingTextarea2"
                  className="font12x500 mb-1 form-label"
                >
                  Offer Details*
                </label>
                <textarea
                  className="form-control shadow-none"
                  id="floatingTextarea2"
                  style={{ height: "180px" }}
                  name="details"
                  value={formik.values.details}
                  onChange={formik.handleChange}
                />
                {formik.errors.details ? (
                  <InputError error={formik.errors.details} />
                ) : null}
              </div>

              {/* status */}
              <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: "Active", label: "Active" },
                    { value: "Inactive", label: "Inactive" },
                  ]}
                  label="Status*"
                  htmlFor="status"
                  type="text"
                  placeholder="Select Status"
                  name="statusName"
                  stateData={formik.values.statusName}
                  setStateData={(e) => {
                    formik.setFieldValue("statusName", e);
                  }}
                />
                {formik.errors.statusName ? (
                  <InputError error={formik.errors.statusName} />
                ) : null}
              </div>

              <SubmitButton
                data={!state.data}
                loader={loader}
                create="Create"
                update="Update"
              />
            </form>

            {state?.data && <UpdationDetails data={state?.data} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default SalonOfferForm;
