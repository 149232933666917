import React, { useContext, useMemo } from "react";
import { HiArrowNarrowUp } from "react-icons/hi";
import { ReviewMonitoringContext } from "../../../Pages/Salon/Master Salon/Reviews&Ratings";
import Loader from "../../Loader";

const RatingChange = () => {
  const { analytics, loader } = useContext(ReviewMonitoringContext);

  const change = useMemo(() => {
    if (
      analytics?.totalRatings?.length !== 0 &&
      analytics?.currentMonthRatings?.length !== 0
    ) {
      let ratingTillPreviousMonth =
        (analytics?.totalRatings && analytics?.totalRatings[0]?.ratingSum) -
        (analytics?.currentMonthRatings &&
          analytics?.currentMonthRatings[0]?.ratingSum);

      let countTillPreviousMonth =
        (analytics?.totalRatings && analytics?.totalRatings[0]?.count) -
        (analytics?.currentMonthRatings &&
          analytics?.currentMonthRatings[0]?.count);

      let totalRatingTillPreviousMonth =
        ratingTillPreviousMonth / countTillPreviousMonth;

      let totalRatingTillDate =
        (analytics?.totalRatings && analytics?.totalRatings[0]?.ratingSum) /
        (analytics?.totalRatings && analytics?.totalRatings[0]?.count);

      let change = totalRatingTillDate - totalRatingTillPreviousMonth;

      return ((change / totalRatingTillPreviousMonth) * 100).toFixed(2);
    } else {
      return "0";
    }
  }, [analytics?.totalRatings, analytics?.currentMonthRatings]);

  return (
    <>
      <div className="statsTabWrapper">
        <h4 className="font14x500" style={{ color: "var(--text3)" }}>
          RATING CHANGE
        </h4>
        <h5 className="font12x500" style={{ color: "var(--text4)" }}>
          Activity this month
        </h5>
        <div className="d-flex align-items-center justify-content-between mt-3">
          <span
            className="font36x700 mb-0 me-3"
            style={{
              color: "var(--text2)",
            }}
          >
            {loader ? (
              <span className="d-flex align-items-center">
                0%
                <span className="ms-2">
                  <Loader height="20" width="20" color="#772286" />
                </span>
              </span>
            ) : (
              `${
                analytics?.currentMonthRatings?.length === 0
                  ? "0"
                  : analytics?.currentMonthRatings
                  ? isNaN(change)
                    ? "0"
                    : change
                  : "0"
              }%`
            )}
          </span>
          <div className="d-flex align-items-center">
            <span className="percentGrowth d-flex align-items-center me-2">
              <HiArrowNarrowUp className="me-1" />{" "}
              {`${
                analytics?.currentMonthRatings?.length === 0
                  ? "0"
                  : analytics?.currentMonthRatings
                  ? isNaN(change)
                    ? "0"
                    : change
                  : "0"
              }%`}
            </span>
            <span className="font12x400" style={{ color: "var(--text4)" }}>
              {`from ${
                analytics?.currentMonthRatings?.length === 0
                  ? "0"
                  : analytics?.currentMonthRatings
                  ? analytics?.currentMonthRatings[0]?.count
                  : "0"
              }`}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default RatingChange;
