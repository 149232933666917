import moment from "moment";
import { useEffect, useState } from "react";
import DropdownSmall from "../../../Shared Components/DropdownSmall";
import DropdownSmallDate from "../../../Shared Components/DropdownSmallDate";
import InputDate from "../../../Shared Components/InputDate";

function MobileSalonDashboardFilters(props) {
  const { onClick, filters, setFilter, param, setParam, addParam, resetParam } =
    props;
  const [animateFilter, setAnimateFilter] = useState({
    transform: "translate(100%)",
  });

  useEffect(() => {
    if (props.isOpenFilter) {
      setAnimateFilter({
        transform: "translate(0%)",
      });
    } else {
      setAnimateFilter({
        transform: "translate(100%)",
      });
    }
  }, [props.isOpenFilter]);

  return (
    <div style={animateFilter} className="mobile-bydate-filters-container">
      <div
        onClick={() => {
          props.setIsOpenFilter(false);
        }}
        className="left-filter-section"
      ></div>
      <div className="right-filter-section">
        <div className="mt-3" onClick={onClick}>
          <button className="apply">Next 7 days</button>
        </div>

        <div className="right-filter-sub-container pt-3 pb-3">
          <p className="title">Filter</p>

          <div className="">
            <button
              className="bydate-filter-button d-flex justify-content-between align-items-center bg-white border-0 w-100"
              style={{
                borderBottom: "1px solid var(--border)",
              }}
            >
              <p className="mb-0">Show Stats</p>
              <p className="mb-0">-</p>
            </button>

            {/* Show Stats */}
            <div className="mt-2 mb-3">
              <DropdownSmallDate
                data={[
                  {
                    value1: `${moment().startOf("month").format("YYYY-MM-DD")}`,
                    value2: `${moment().endOf("month").format("YYYY-MM-DD")}`,
                    label: "This Month",
                  },
                  {
                    value1: `${moment().format("YYYY-MM-DD")}`,
                    value2: `${moment().format("YYYY-MM-DD")}`,
                    label: "Today",
                  },
                  {
                    value1: `${moment()
                      .subtract(1, "days")
                      .format("YYYY-MM-DD")}`,
                    value2: `${moment()
                      .subtract(1, "days")
                      .format("YYYY-MM-DD")}`,
                    label: "Yesterday",
                  },
                ]}
                // label="Show Stats"
                htmlFor="stats"
                type="text"
                placeholder="Show Stats"
                width="auto"
                stateData={filters?.statName}
                setStateData={(a, b, c) => {
                  setFilter({
                    ...filters,
                    statName: c,
                    fromDate: a,
                    tillDate: b,
                  });
                }}
              />
            </div>
          </div>

          <div className="">
            <button
              className="bydate-filter-button d-flex justify-content-between align-items-center bg-white border-0 w-100"
              style={{
                borderBottom: "1px solid var(--border)",
              }}
            >
              <p className="mb-0">On</p>
              <p className="mb-0">-</p>
            </button>

            {/* On */}
            <div className="mt-2 mb-3">
              <DropdownSmall
                data={[
                  {
                    value: true,
                    label: "Store",
                  },
                  {
                    value: false,
                    label: "Groome",
                  },
                ]}
                // label="On"
                htmlFor="code"
                type="text"
                placeholder="Select Code"
                width="auto"
                stateData={filters?.qrCode ? "Store" : "Groome"}
                setStateData={(a) => {
                  setFilter({
                    ...filters,
                    qrCode: a,
                  });
                }}
                isLabelSelected={true}
              />
            </div>
          </div>

          <div className="">
            <button
              className="bydate-filter-button d-flex justify-content-between align-items-center bg-white border-0 w-100"
              style={{
                borderBottom: "1px solid var(--border)",
              }}
            >
              <p className="mb-0">By Date</p>
              <p className="mb-0">-</p>
            </button>

            <div className="right-date-filter-container">
              <div className="mb-2">
                <InputDate
                  label="From"
                  htmlFor="from"
                  type="date"
                  stateData={param?.fromDate}
                  setStateData={(e) => {
                    setParam({
                      ...param,
                      fromDate: moment(e).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </div>
              <div>
                <InputDate
                  label="To"
                  htmlFor="to"
                  type="date"
                  stateData={param?.tillDate}
                  setStateData={(e) => {
                    setParam({
                      ...param,
                      tillDate: moment(e).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mt-1">
            <button className="apply" onClick={addParam}>
              Apply
            </button>
            <button className="reset" onClick={resetParam}>
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileSalonDashboardFilters;
