import axios from "axios";
import React, { useState } from "react";
import { BiCurrentLocation } from "react-icons/bi";
import ChangeLocation from "./ChangeLocation";
import CustomMap from "./CustomMap";

const Location = (props) => {
  const { newAddress, setNewAddress, setIsChange } = props;

  const latitude = newAddress?.lat;
  const longitude = newAddress?.lng;

  const [showModal, setShowModal] = useState(false);

  const functions = {
    getMyCurrentLocation: () => {
      const location = window.navigator && window.navigator.geolocation;

      if (location) {
        location.getCurrentPosition(async (p) => {
          setNewAddress((old) => {
            return {
              ...old,
              lat: p.coords.latitude,
              lng: p.coords.longitude,
            };
          });
          functions.handleLocation(p.coords.latitude, p.coords.longitude);
        });
      }
    },
    handleLocation: async (latitude, longitude) => {
      if (latitude && longitude) {
        const res = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${latitude},${longitude}&key=${process.env.REACT_APP_API_MAP}`
        );

        console.log("res", res);

        if (res.status === 200) {
          setNewAddress((old) => {
            return {
              ...old,
              lat: res.data.results[0].geometry.location.lat,
              lng: res.data.results[0].geometry.location.lng,
              locationName: res.data.results[0].formatted_address,
            };
          });

          res?.data?.results &&
            res.data.results.forEach((addresses) => {
              addresses.address_components.forEach((address) => {
                address.types.forEach((item) => {
                  if (item === "locality") {
                    setNewAddress((old) => {
                      return {
                        ...old,
                        city: address?.long_name,
                      };
                    });
                  }
                  if (item === "administrative_area_level_1") {
                    setNewAddress((old) => {
                      return {
                        ...old,
                        state: address?.long_name,
                      };
                    });
                  }
                  if (item === "country") {
                    setNewAddress((old) => {
                      return {
                        ...old,
                        country: address?.long_name,
                      };
                    });
                  }
                  if (item === "postal_code") {
                    setNewAddress((old) => {
                      return {
                        ...old,
                        pincode: address?.long_name,
                      };
                    });
                  }
                });
              });
            });
        }
      } else {
        console.log("map error");
      }
    },
  };

  // useEffect(() => {
  //   functions.handleLocation(latitude, longitude);
  // }, []);

  return (
    <>
      <div className="mb-3">
        <label htmlFor="country" className="font12x500 form-label mb-1">
          Select Location*
        </label>
        <div className="mapContainer mb-4">
          <div className="mapWrapper">
            <CustomMap
              google={window.google}
              handleLocation={functions.handleLocation}
              latitude={latitude}
              longitude={longitude}
              setNewAddress={setNewAddress}
            />
          </div>
          <div
            title="current location"
            className="currentLocation"
            onClick={() => {
              functions.getMyCurrentLocation();
            }}
          >
            <BiCurrentLocation />
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <p className="mb-0 font14x400">{newAddress?.locationName}</p>
          </div>
          <div className="col-4 d-flex align-items-center">
            <div
              className="btn locationBtn shadow-none"
              name="location_changed"
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
            >
              Change
            </div>
          </div>
        </div>
      </div>

      {/* modal */}
      {showModal && (
        <ChangeLocation
          setIsChange={setIsChange}
          setShowModal={setShowModal}
          google={window.google}
          handleLocation={functions.handleLocation}
          latitude={latitude}
          longitude={longitude}
          setNewAddress={setNewAddress}
        />
      )}
    </>
  );
};

export default Location;
