import React from "react";
import useLoading from "../../hooks/useLoading";
import { authHeader, SALON_UPDATE } from "../../utils/Constants";
import { orderDetailsSchema } from "../../utils/validationschema";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import UpdationDetails from "../../Shared Components/UpdationDetails";
import { CgClose } from "react-icons/cg";
import useAuthContext from "../../hooks/useAuthContext";
import InputFieldLarge from "../../Shared Components/InputFieldLarge";
import InputError from "../../Shared Components/InputError";
import SubmitButton from "../../Shared Components/SubmitButton";

const EditOrderDetails = ({ state, setState, apiCall }) => {
  const salonDetails = JSON.parse(localStorage.getItem("salon_details"));
  const initialValues = {
    deliveryCharges: state?.data?.deliveryCharges ?? 0,
    minOrderValue: state?.data?.minOrderValue ?? 0,
    maxOrderValue: state?.data?.maxOrderValue ?? 0,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: orderDetailsSchema,
    onSubmit: (data) => {
      functions.formSubmit(data);
      // console.log("data", data);
    },
  });

  const [loader, showLoader, hideLoader] = useLoading();
  const { token } = useAuthContext();

  const functions = {
    setPositionToBody: () => {
      setState({
        ...state,
        modal: false,
      });
      document.body.style.overflow = "unset";
    },
    formSubmit: async (data) => {
      const rFormData = new FormData();
      rFormData.append("deliveryCharges", data.deliveryCharges);
      rFormData.append("minOrderValue", data.minOrderValue);
      rFormData.append("maxOrderValue", data.maxOrderValue);
      if (token) {
        try {
          showLoader();
          console.log("update api called");
          const res = await axios.put(
            `${SALON_UPDATE}${state.data._id}/form`,
            rFormData,
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            functions.setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Order Details Updated", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
  };

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={functions.setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">Fill Order Values</h1>
            <span onClick={functions.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit}>
              {/* Delivery Charges (₹)*/}
              <div className="mb-3">
                <InputFieldLarge
                  label={`Delivery Charges (${
                    salonDetails?.country?.htmlSymbol !== ""
                      ? String.fromCharCode(salonDetails?.country?.htmlSymbol)
                      : salonDetails?.country?.currency
                  })*`}
                  htmlFor="deliveryCharges"
                  type="tel"
                  name="deliveryCharges"
                  stateData={formik.values.deliveryCharges}
                  setStateData={formik.handleChange}
                />
                {formik.errors.deliveryCharges ? (
                  <InputError error={formik.errors.deliveryCharges} />
                ) : null}
              </div>

              {/* Waive of the delivery charge (₹)*/}
              <div className="mb-3">
                <InputFieldLarge
                  label={`Min. Order Value(To accept order) (${
                    salonDetails?.country?.htmlSymbol !== ""
                      ? String.fromCharCode(salonDetails?.country?.htmlSymbol)
                      : salonDetails?.country?.currency
                  })*`}
                  htmlFor="minOrderValue"
                  type="tel"
                  name="minOrderValue"
                  stateData={formik.values.minOrderValue}
                  setStateData={formik.handleChange}
                />
                {formik.errors.minOrderValue ? (
                  <InputError error={formik.errors.minOrderValue} />
                ) : null}
              </div>

              {/* Max Order Value (₹)*/}
              <div className="mb-3">
                <InputFieldLarge
                  label={`Min. Order Value(For Free Delivery) (${
                    salonDetails?.country?.htmlSymbol !== ""
                      ? String.fromCharCode(salonDetails?.country?.htmlSymbol)
                      : salonDetails?.country?.currency
                  })*`}
                  htmlFor="maxOrderValue"
                  type="tel"
                  name="maxOrderValue"
                  stateData={formik.values.maxOrderValue}
                  setStateData={formik.handleChange}
                />
                {formik.errors.maxOrderValue ? (
                  <InputError error={formik.errors.maxOrderValue} />
                ) : null}
              </div>

              <SubmitButton
                data={!state.data}
                loader={loader}
                create="Add"
                update="Update"
              />
            </form>

            {state?.data && <UpdationDetails data={state?.data} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditOrderDetails;
