import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import useAuthContext from "../../hooks/useAuthContext";
import useLoading from "../../hooks/useLoading";
import { CLIENTS_LIST, DOWNLOAD_REPORT } from "../../utils/Constants";
import Loader from "../Loader";

const GenerateReportModal = (props) => {
  const {
    state,
    setState,
    filters,
    salonName,
    saveFileName,
    url,
    setFilter,
    getData,
  } = props;
  const { token } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();
  const [loader2, showLoader2, hideLoader2] = useLoading();
  const [parameter, setParameter] = useState({ ...filters });
  const [filename, setFilename] = useState("");
  const [type, setType] = useState("");
  const [error, setError] = useState("");
  console.log("error: ", error);
  console.log("parameter: ", parameter);

  const fn = {
    setPositionToBody: () => {
      setState(false);
      document.body.style.overflow = "unset";
    },
    getDownloadResponse: async () => {
      if (token) {
        try {
          showLoader();
          const res = await axios.get(url, {
            params: parameter,
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });
          if (res.status === 200) {
            console.log("res", res);
            setFilename(res.data.fileName);
            setType(res.data.type);
            hideLoader();
          } else {
            console.log("error", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 500) {
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
            setError(error?.response?.data?.message);
            hideLoader();
          }
        }
      }
    },
    downloadReport: async (link) => {
      //   if (token) {
      //     try {
      //       showLoader2();
      //       const res = await axios.get(
      //         `${DOWNLOAD_REPORT}?downloadFileName=${encodeURIComponent(
      //           fileName
      //         )}&saveFileName=${encodeURIComponent(
      //           `${salonName}_${saveFileName}`
      //         )}`,
      //         {
      //           //headers: authHeader,
      //           headers: {
      //             "Content-Type": "application/json",
      //             Authorization: "Bearer " + token,
      //             "X-Requested-With": "XMLHttpRequest",
      //           },
      //         }
      //       );
      //       if (res.status === 200) {
      //         console.log("res", res);
      //         hideLoader2();
      //       } else {
      //         console.log("error", res.status);
      //       }
      //     } catch (error) {
      //       console.log("catch error: ", error);
      //       if (error?.response?.status === 502) {
      //         toast.error(error?.response?.data?.message, {
      //           position: "bottom-center",
      //           autoClose: 5000,
      //           hideProgressBar: false,
      //           closeOnClick: true,
      //           pauseOnHover: true,
      //         });
      //         hideLoader2();
      //       }
      //     }
      //   }

      window.open(link);
      fn.setPositionToBody();
    },
  };

  useEffect(() => {
    if (filters?.fromDate && filters.tillDate) {
      delete parameter.page;
      delete parameter.per_page;
      parameter.download = true;
      parameter.tz = moment().zone();
      fn.getDownloadResponse();
    } else {
      delete parameter.page;
      delete parameter.per_page;
      parameter.download = true;
      parameter.tz = moment().zone();
      parameter.fromDate = moment().startOf("month").format("YYYY-MM-DD");
      parameter.tillDate = moment().endOf("month").format("YYYY-MM-DD");
      fn.getDownloadResponse();
    }
  }, []);

  return (
    <>
      <div
        className={
          state
            ? "deleteModalWrapper deleteModalWrapper-active"
            : "deleteModalWrapper"
        }
      >
        <div className="deleteModalDiv"></div>
        <div className="deleteModalContainer d-flex align-items-center flex-column justify-content-center pt-3">
          <div className="w-100 text-end">
            <CgClose
              onClick={fn.setPositionToBody}
              style={{
                fontSize: 20,
                cursor: "pointer",
              }}
            />
          </div>
          <p className="mb-4 pt-2 pe-3">
            {filename === ""
              ? `We are generating your report from ${parameter.fromDate} to ${parameter.tillDate}. Please
            wait for a while`
              : `We are generated your report from ${parameter.fromDate} to ${parameter.tillDate}.`}
          </p>
          {loader ? (
            <Loader height="20" width="20" color="#772286" />
          ) : error !== "" ? (
            <p className="mb-0 errorMsg">{error}</p>
          ) : (
            <div className="text-center">
              <button
                className="btn addBtn shadow-none"
                // onClick={() => fn.downloadReport(filename)}
                onClick={() =>
                  fn.downloadReport(
                    `${
                      process.env.REACT_APP_API_URL
                    }/download?downloadFileName=${encodeURIComponent(
                      filename
                    )}&saveFileName=${encodeURIComponent(
                      `${salonName}_${saveFileName}`
                    )}&type=${encodeURIComponent(type)}`
                  )
                }
              >
                {loader2 ? (
                  <div className="d-flex justify-content-center">
                    <Loader height="20" width="20" color="#fff" />
                  </div>
                ) : (
                  "Download"
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GenerateReportModal;
