import React from "react";

const InputFieldLarge = (props) => {
  const {
    label,
    htmlFor,
    type,
    stateData,
    setStateData,
    width,
    maxLength,
    name,
    readOnly,
    onBlur,
  } = props;

  return (
    <>
      <label htmlFor={htmlFor} className="font12x500 form-label mb-1">
        {label}
      </label>
      <input
        type={type}
        className="form-control shadow-none"
        id={htmlFor}
        name={name}
        value={stateData}
        // onChange={(e) => {
        //   setStateData(e.target.value);
        // }}
        onChange={setStateData}
        autoComplete="off"
        style={{ width: width }}
        maxLength={maxLength}
        readOnly={readOnly}
        onBlur={onBlur}
      />
    </>
  );
};

export default InputFieldLarge;
