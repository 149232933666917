import React from "react";
import { CgClose } from "react-icons/cg";
import useAuthContext from "../../hooks/useAuthContext";
import { LeadSchema } from "../../utils/validationschema";
import { useFormik } from "formik";
import InputError from "../../Shared Components/InputError";
import { leadStatus, UPDATE_LEAD } from "../../utils/Constants";
import axios from "axios";
import { toast } from "react-toastify";
import UpdationDetails from "../../Shared Components/UpdationDetails";
import StaticDropdown from "../../Shared Components/StaticDropdown";
import useLoading from "../../hooks/useLoading";
import SubmitButton from "../../Shared Components/SubmitButton";

const LeadForm = ({ state, setState, apiCall }) => {
  const initialValues = {
    statusName: leadStatus[state?.data?.status]?.label,
  };
  const formik = useFormik({
    initialValues,
    validationSchema: LeadSchema,
    onSubmit: (data) => {
      //   console.log("data", data);
      formSubmit(data);
    },
  });

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
      check: "",
    });
    document.body.style.overflow = "unset";
  };

  const { token } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();

  const formSubmit = async (data) => {
    console.log("form submit called");

    if (token) {
      {
        try {
          showLoader();
          console.log("update api called");
          const res = await axios.put(
            `${UPDATE_LEAD}/${state?.data?._id}`,
            {
              status:
                data.statusName === "Pending"
                  ? 0
                  : data.statusName === "Processing"
                  ? 1
                  : 2,
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Lead Updated", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">Update Lead</h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit}>
              {/* status */}
              <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: "Pending", label: "Pending" },
                    { value: "Processing", label: "Processing" },
                    { value: "Served", label: "Served" },
                  ]}
                  label="Status*"
                  htmlFor="status"
                  type="text"
                  placeholder="Select Status"
                  name="statusName"
                  stateData={formik.values.statusName}
                  setStateData={(e) => {
                    formik.setFieldValue("statusName", e);
                  }}
                />
                {formik.errors.statusName ? (
                  <InputError error={formik.errors.statusName} />
                ) : null}
              </div>

              <SubmitButton
                data={!state.data}
                loader={loader}
                create="Submit"
                update="Update"
              />
            </form>

            {state?.data && <UpdationDetails data={state?.data} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadForm;
