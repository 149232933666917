import React from "react";
import HomeServiceRevenue from "./HomeServiceRevenue";
import SalonServiceRevenue from "./SalonServiceRevenue";
import TotalProductRevenue from "./TotalProductRevenue";
import TotalServiceRevenue from "./TotalServiceRevenue";

const AllDataRevenue = (props) => {
  const { revenueCalculation, loader } = props;
  return (
    <>
      <div className="paymentsStatsWrapper mt-5 me-3">
        <h3 className="font22x500 pageHeading mb-4">Revenues</h3>
        <div className="tabsWrapper">
          <SalonServiceRevenue
            revenueCalculation={revenueCalculation}
            loader={loader}
          />
          <HomeServiceRevenue
            revenueCalculation={revenueCalculation}
            loader={loader}
          />
          <TotalServiceRevenue
            revenueCalculation={revenueCalculation}
            loader={loader}
          />
          <TotalProductRevenue
            revenueCalculation={revenueCalculation}
            loader={loader}
          />
        </div>
      </div>
    </>
  );
};

export default AllDataRevenue;
