import React from "react";

const CategorySelection = (props) => {
  const { registrationData, setRegistrationData, data } = props;

  const toggleCategory = (category) => {
    if (registrationData.salonCategory.includes(category)) {
      const newData = registrationData.salonCategory.filter((ele) => {
        return ele !== category;
      });
      setRegistrationData((old) => {
        return {
          ...old,
          salonCategory: newData,
        };
      });
    } else {
      setRegistrationData((old) => {
        return {
          ...old,
          salonCategory: [...registrationData.salonCategory, category],
        };
      });
    }
  };

  return (
    <>
      <div className="categoryCheck">
        <div className="mb-3">
          <label className="font12x500 form-label mb-1">Store Category*</label>
          <div className="checkBoxContainer">
            {data?.map((cat, i) => {
              {
                return (
                  <div className="catCheck" key={cat._id}>
                    <label className="font14x400 form-check-label" htmlFor={i}>
                      <input
                        type="checkbox"
                        className="me-2"
                        id={i}
                        value={i}
                        onChange={() => {
                          toggleCategory(cat._id);
                        }}
                        checked={
                          registrationData.salonCategory.includes(cat._id) &&
                          true
                        }
                      />
                      <span className="font16x500 text-capitalize">
                        {cat.name}
                      </span>
                    </label>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CategorySelection;
