import React from "react";
import { TailSpin } from "react-loader-spinner";

const Loader = ({ height, width, color }) => {
  return (
    <>
      <TailSpin
        height={height}
        width={width}
        color={color}
        ariaLabel="tail-spin-loading"
        radius="2"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </>
  );
};

export default Loader;
