import React, { useContext } from "react";
import { CgClose } from "react-icons/cg";
import CartItem from "./CartItem";
import { AiOutlinePlus } from "react-icons/ai";
import { HiOutlineChevronRight } from "react-icons/hi";
import PaymentDetails from "./PaymentDetails";
import { Link, useLocation } from "react-router-dom";
import { CartContext } from "../../utils/Private Routes/MasterSalonPrivateRoutes";
import Loader from "../Loader";
import CouponSection from "./CouponSection";

const Cart = ({
  state,
  setState,
  addItemIntoCart,
  removeItemFromCart,
  removeItem,
}) => {
  const location = useLocation();

  const { cart, cartLoader } = useContext(CartContext);

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
      step: 0,
    });
    document.body.style.overflow = "unset";
  };
  console.log("state", state);

  return (
    <>
      <div
        className={
          state?.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv"></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">Your Cart</h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            {cartLoader ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: "100%", padding: "20px 0 20px 0" }}
              >
                <Loader height="40" width="40" color="#772286" />
              </div>
            ) : cart ? (
              cart?.products?.length === 0 ? (
                <>
                  <p className="mb-0">Your Cart is empty</p>
                  <div className="addMoreItemsWrapper d-flex align-items-center py-3">
                    <AiOutlinePlus />
                    {location?.pathname === "/salon/buy/product" ? (
                      <p
                        className="mb-0 ms-3 font14x400"
                        onClick={setPositionToBody}
                        style={{ cursor: "pointer" }}
                      >
                        Add Product
                      </p>
                    ) : (
                      <Link to="/salon/buy/product">
                        <p
                          className="mb-0 ms-3 font14x400"
                          onClick={setPositionToBody}
                        >
                          Add Product
                        </p>
                      </Link>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {/* cart items */}
                  {cart?.products?.map((item, i) => {
                    return (
                      <CartItem
                        item={item}
                        key={i}
                        addItemIntoCart={addItemIntoCart}
                        removeItemFromCart={removeItemFromCart}
                        removeItem={removeItem}
                      />
                    );
                  })}

                  {/* add more products */}
                  <div className="addMoreItemsWrapper d-flex align-items-center py-3">
                    <AiOutlinePlus />
                    {location?.pathname === "/salon/buy/product" ? (
                      <p
                        className="mb-0 ms-3 font14x400"
                        onClick={setPositionToBody}
                        style={{ cursor: "pointer" }}
                      >
                        Add more Products
                      </p>
                    ) : (
                      <Link to="/salon/buy/product">
                        <p
                          className="mb-0 ms-3 font14x400"
                          onClick={setPositionToBody}
                        >
                          Add more Products
                        </p>
                      </Link>
                    )}
                  </div>

                  {/* Coupon section */}
                  <CouponSection
                    cart={cart}
                    state={state}
                    setState={setState}
                  />

                  {/* payment details */}
                  <PaymentDetails cart={cart} />

                  {/* proceed btn*/}
                  <div className="proceedWrapper d-flex justify-content-between align-items-center py-2 px-3">
                    <div className="">
                      <p className="mb-0 font12x400">Grand Total</p>
                      <p className="mb-0 font16x500">{`${String.fromCharCode(
                        cart?.products[0]?.product?.currencySymbol
                      )}${cart?.cartCalculations?.products?.grandTotal}`}</p>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setState({
                          ...state,
                          step: 3,
                        });
                      }}
                    >
                      <button type="submit" className="font16x500">
                        Proceed
                        <HiOutlineChevronRight style={{ marginBottom: 3 }} />
                      </button>
                    </div>
                  </div>
                </>
              )
            ) : (
              <>
                <p className="mb-0">Your Cart is empty</p>
                <div className="addMoreItemsWrapper d-flex align-items-center py-3">
                  <AiOutlinePlus />
                  {location?.pathname === "/salon/buy/product" ? (
                    <p
                      className="mb-0 ms-3 font14x400"
                      onClick={setPositionToBody}
                      style={{ cursor: "pointer" }}
                    >
                      Add Product
                    </p>
                  ) : (
                    <Link to="/salon/buy/product">
                      <p
                        className="mb-0 ms-3 font14x400"
                        onClick={setPositionToBody}
                      >
                        Add Product
                      </p>
                    </Link>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
