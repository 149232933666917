import React from "react";

const InputError = (props) => {
  return (
    <>
      <p className="errorDanger mt-1 mb-0">{props.error}</p>
    </>
  );
};

export default InputError;
