import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProgressStep from "../../../../../Components/Admin/OrderService/ProgressStep";
import ProgressStepAtSalon from "../../../../../Components/Admin/OrderService/ProgressStepAtSalon";
import Summary from "../../../../../Components/Admin/OrderService/Summary";
import SummaryAtSalon from "../../../../../Components/Admin/OrderService/SummaryAtSalon";
import Layout from "../../../../../Components/Layout";
import { ToastContainer } from "react-toastify";
import {
  GET_ORDER_BY_ID,
  STYLIST_SEARCH,
} from "../../../../../utils/Constants";
import useFetch from "../../../../../hooks/useFetch";
import UpdationDetails from "../../../../../Shared Components/UpdationDetails";
import Loader from "../../../../../Components/Loader";
import ViewOrderHeader from "../../../../../Components/vieworderheader";
import {
  setAtHomeStatus,
  setAtSalonStatus,
} from "../QR Service Order/ViewSalonServiceOrder";

const ViewSalonServiceOrder = () => {
  const navigate = useNavigate();

  const params = useParams();
  const salonNew = JSON.parse(localStorage.getItem("salon_details"));

  const [order, totalResult, error, loader, getData] = useFetch({
    url: `${GET_ORDER_BY_ID}${params.id}`,
    resultField: "order",
  });

  const [stylists, totalResult2, error2, loader2, getStylistData] = useFetch({
    url: `${STYLIST_SEARCH}`,
    resultField: "stylists",
    parameter: {
      salon: salonNew && salonNew?._id,
      status: 1,
      f: "name,mobile,alternateNumber",
    },
  });

  useEffect(() => {
    if (params.id === "id") {
      navigate(-1);
    } else {
      getData();
      getStylistData();
    }
  }, []);

  return (
    <>
      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <ViewOrderHeader />

      <div className="mobile">
        <div className="order_status_wrapper d-flex justify-content-between">
          <p className="mb-0 font16x500">
            Order#: <span>{order?.serviceOrders?.orderId}</span>
          </p>
          <p className="mb-0 font16x500 text-capitalize">
            {order?.serviceOrders?.serviceType === "At Salon"
              ? setAtSalonStatus(order?.serviceOrders?.servicesProcessingStatus)
              : setAtHomeStatus(order?.serviceOrders?.servicesProcessingStatus)}
          </p>
        </div>
      </div>

      <div className="mainWrapper view-order-page">
        {loader ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "70vh" }}
          >
            <Loader height="40" width="40" color="#772286" />
          </div>
        ) : (
          <>
            <div>
              <h3 className="font22x500 pageHeading mb-0 text-capitalize">
                {`Order Id: ${order?.serviceOrders?.orderId} (${order?.salon?.name}, ${order?.salon?.address?.city})`}
              </h3>
            </div>

            {order?.serviceOrders?.serviceType === "At Salon" ? (
              <ProgressStepAtSalon
                statusTimeStamps={
                  order?.serviceOrders?.servicesStatusTimestamps
                }
                status={order?.serviceOrders?.servicesProcessingStatus}
              />
            ) : (
              <ProgressStep
                statusTimeStamps={
                  order?.serviceOrders?.servicesStatusTimestamps
                }
                status={order?.serviceOrders?.servicesProcessingStatus}
              />
            )}

            {order?.serviceOrders?.serviceType === "At Salon" ? (
              <SummaryAtSalon
                orderDetails={order}
                apiCall={getData}
                stylistDetails={stylists}
              />
            ) : (
              <Summary
                orderDetails={order}
                apiCall={getData}
                stylistDetails={stylists}
              />
            )}
          </>
        )}

        {order && <UpdationDetails data={order?.serviceOrders} />}
      </div>

      <ToastContainer />
    </>
  );
};

export default ViewSalonServiceOrder;
