import React, { useRef, useState } from "react";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import useAuthContext from "../../../hooks/useAuthContext";
import InputFieldLarge from "../../../Shared Components/InputFieldLarge";
import StaticDropdown from "../../../Shared Components/StaticDropdown";
import { sendNotificationSchema } from "../../../utils/validationschema";
import InputError from "../../../Shared Components/InputError";
import useLoading from "../../../hooks/useLoading";
import { useFormik } from "formik";
import Loader from "../../Loader";
import axios from "axios";
import { authFormHeader, SEND_NOTIFICATION } from "../../../utils/Constants";

const SendNotification = ({ state, setState }) => {
  const [loader, showLoader, hideLoader] = useLoading();
  const { token } = useAuthContext();
  const initialValues = {
    title: "",
    description: "",
    sendTo: "",
    image: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: sendNotificationSchema,
    onSubmit: (data) => {
      formSubmit(data);
    },
  });

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
      check: "",
      step: 0,
    });
    document.body.style.overflow = "unset";
  };

  const customerImage = useRef(null);
  const handleSingleImg = (e) => {
    e.preventDefault();
    customerImage.current.click();
  };
  const [singleImage, setSingleImage] = useState("");
  const imageDataURL = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSingleImage(URL.createObjectURL(e.target.files[0]));
      formik.setFieldValue("image", Array.from(e.target.files));
    }
  };
  const deleteImage = (index) => {
    if (index === -1) {
      formik.setFieldValue("image", "");
      setSingleImage("");
      customerImage.current.value = "";
    }
  };

  const formSubmit = async (data) => {
    console.log("form submit called");

    //form data
    const sFormData = new FormData();
    sFormData.append("title", data.title);
    sFormData.append("image", data.image[0]);
    sFormData.append("description", data.description);

    if (token) {
      if (state.data === undefined) {
        try {
          showLoader();
          const res = await axios.post(`${SEND_NOTIFICATION}`, sFormData, {
            params: {
              sendTo: data.sendTo,
            },
            //headers: authFormHeader,
            headers: {
              "Content-type": "multipart/form-data",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });

          if (res.status === 200) {
            setPositionToBody();
            hideLoader();
            toast.success("Notification Send", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">Send Notification</h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
              {/* title* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Title*"
                  htmlFor="title"
                  type="text"
                  name="title"
                  stateData={formik.values.title}
                  setStateData={formik.handleChange}
                />
                {formik.errors.title ? (
                  <InputError error={formik.errors.title} />
                ) : null}
              </div>

              {/* description */}
              <div className="mb-3">
                <label
                  htmlFor="floatingTextarea2"
                  className="font12x500 mb-1 form-label"
                >
                  Description*
                </label>
                <textarea
                  className="form-control shadow-none"
                  id="floatingTextarea2"
                  style={{ height: "200px" }}
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
                {formik.errors.description ? (
                  <InputError error={formik.errors.description} />
                ) : null}
              </div>

              {/* customer image */}
              <div className="mb-3">
                <div className="mb-0">
                  <label
                    htmlFor="formFile"
                    className="font12x500 mb-1 form-label"
                  >
                    Image
                  </label>
                  <input
                    ref={customerImage}
                    className="form-control shadow-none"
                    type="file"
                    id="formFile"
                    name="image"
                    accept={".png, .jpg, .jpeg"}
                    hidden
                    onChange={(e) => {
                      imageDataURL(e);
                    }}
                  />
                </div>
                <div className="uploadImg d-flex align-items-center mb-2">
                  <div
                    className="uploadBtn font12x400 me-3"
                    onClick={(e) => {
                      handleSingleImg(e);
                    }}
                  >
                    Upload
                  </div>
                  <span className="font12x400">
                    Allowed file types: jpg, jpeg, png
                  </span>
                </div>
                {singleImage && (
                  <div className="imageWrapper">
                    <img
                      src={singleImage}
                      alt="delete"
                      width={61}
                      height={61}
                    />
                    <div
                      className="deleteDiv"
                      onClick={() => {
                        deleteImage(-1);
                      }}
                    >
                      <img src="/Assets/delete.svg" alt="delete" />
                    </div>
                  </div>
                )}
                {formik.errors.image ? (
                  <InputError error={formik.errors.image} />
                ) : null}
              </div>

              {/* send to */}
              <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: "all", label: "All customers" },
                    { value: "membership", label: "All membership users" },
                  ]}
                  label="Send to*"
                  htmlFor="send"
                  type="text"
                  placeholder="Select User"
                  name="sendTo"
                  stateData={formik.values.sendTo}
                  setStateData={(e) => {
                    formik.setFieldValue("sendTo", e);
                  }}
                />
                {formik.errors.sendTo ? (
                  <InputError error={formik.errors.sendTo} />
                ) : null}
              </div>

              <button
                type="submit"
                className="btn OtpBtn shadow-none mt-3 font16x500"
              >
                {loader ? (
                  <div className="d-flex justify-content-center">
                    <Loader height="25" width="25" color="#fff" />
                  </div>
                ) : (
                  "Send"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendNotification;
