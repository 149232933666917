import React from "react";

const ColorStar = ({ color }) => {
  return (
    <>
      <svg
        width="32"
        height="29"
        viewBox="0 0 32 29"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 0L19.5922 11.0557H31.2169L21.8123 17.8885L25.4046 28.9443L16 22.1115L6.59544 28.9443L10.1877 17.8885L0.783095 11.0557H12.4078L16 0Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default ColorStar;
