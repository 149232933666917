import React from "react";
import { ADMIN, authHeader, CREATE_SALON_WALLET } from "../../utils/Constants";
import { rechargeSchema } from "../../utils/validationschema";
import { CgClose } from "react-icons/cg";
import useAuthContext from "../../hooks/useAuthContext";
import InputFieldLarge from "../../Shared Components/InputFieldLarge";
import { useFormik } from "formik";
import InputError from "../../Shared Components/InputError";
import axios from "axios";
import { toast } from "react-toastify";
import StaticDropdown from "../../Shared Components/StaticDropdown";
import useLoading from "../../hooks/useLoading";
import Loader from "../Loader";
import PaymentMethods from "../../Shared Components/PaymentMethods";

const MakeRecharge = ({ state, setState, apiCall, id }) => {
  const role = JSON.parse(localStorage.getItem("role"));
  const initialValues = {
    type: role === ADMIN ? "" : "Recharge",
    entryType: role === ADMIN ? "" : "CR",
    amount: 0,
    paymentMode: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: rechargeSchema,
    onSubmit: (data) => {
      //   console.log("data", data);
      formSubmit(data);
    },
  });

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const { token } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();

  const formSubmit = async (data) => {
    if (token) {
      try {
        showLoader();
        const res = await axios.post(
          `${CREATE_SALON_WALLET}${id}`,
          {
            type: data?.type,
            entryType: data?.entryType,
            amount: data?.amount,
          },
          {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );

        if (res.status === 200) {
          setPositionToBody();
          apiCall();
          hideLoader();
          toast.success("Form Submit", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          console.log("else error: ", res.status);
        }
      } catch (error) {
        console.log("catch error: ", error);
        if (error?.response?.status === 502) {
          hideLoader();
          toast.error(error?.response?.data?.message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">Recharge</h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit}>
              {role === ADMIN && (
                <>
                  {/* type */}
                  <div className="mb-3">
                    <StaticDropdown
                      data={[
                        { value: "Recharge", label: "Recharge" },
                        { value: "Penalty", label: "Penalty" },
                      ]}
                      label="Type*"
                      htmlFor="type"
                      type="text"
                      placeholder="Select Status"
                      name="type"
                      stateData={formik.values.type}
                      setStateData={(e) => {
                        formik.setFieldValue("type", e);
                      }}
                    />
                    {formik.errors.type ? (
                      <InputError error={formik.errors.type} />
                    ) : null}
                  </div>

                  {/* entryType */}
                  <div className="mb-3">
                    <StaticDropdown
                      data={[
                        { value: "CR", label: "Credit" },
                        { value: "DR", label: "Debit" },
                      ]}
                      label="Entry Type*"
                      htmlFor="entryType"
                      type="text"
                      placeholder="Select Status"
                      name="entryType"
                      stateData={formik.values.entryType}
                      setStateData={(e) => {
                        formik.setFieldValue("entryType", e);
                      }}
                    />
                    {formik.errors.entryType ? (
                      <InputError error={formik.errors.entryType} />
                    ) : null}
                  </div>
                </>
              )}

              {/* amount */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Amount*"
                  htmlFor="amount"
                  type="tel"
                  name="amount"
                  stateData={formik.values.amount}
                  setStateData={formik.handleChange}
                />
                {formik.errors.amount ? (
                  <InputError error={formik.errors.amount} />
                ) : null}
              </div>

              {/* payment methods */}
              {/* <div className="mb-4">
                <div className="mb-1">
                  <div style={{ borderBottom: "1px solid #EBEBEB" }}>
                    <label
                      htmlFor="atsalon"
                      className="font16x700 form-label mb-1 labelBig"
                    >
                      Payment Methods*
                    </label>
                  </div>

                  <PaymentMethods
                    label="COD"
                    imgPath="/Assets/cash.svg"
                    htmlFor="cod"
                    name="paymentMode"
                    value="COD"
                    stateData={formik.values.paymentMode}
                    setStateData={(e) => {
                      formik.setFieldValue("paymentMode", e);
                    }}
                  />

                  <PaymentMethods
                    label="PayU"
                    imgPath="/Assets/payU.svg"
                    htmlFor="payU"
                    name="paymentMode"
                    value="PayU"
                    stateData={formik.values.paymentMode}
                    setStateData={(e) => {
                      formik.setFieldValue("paymentMode", e);
                    }}
                  />
                </div>
                {formik.errors.paymentMode ? (
                  <InputError error={formik.errors.paymentMode} />
                ) : null}
              </div> */}

              <button
                type="submit"
                className="btn OtpBtn shadow-none font16x500"
              >
                {loader ? (
                  <div className="d-flex justify-content-center">
                    <Loader height="25" width="25" color="#fff" />
                  </div>
                ) : (
                  "Pay Now"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default MakeRecharge;
