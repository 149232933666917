import axios from "axios";
import React from "react";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import useAuthContext from "../../../hooks/useAuthContext";
import InputFieldLarge from "../../../Shared Components/InputFieldLarge";
import StaticDropdown from "../../../Shared Components/StaticDropdown";
import UpdationDetails from "../../../Shared Components/UpdationDetails";
import { authHeader, FAQ_ADD, FAQ_UPDATE } from "../../../utils/Constants";
import { useFormik } from "formik";
import { FAQSchema } from "../../../utils/validationschema";
import InputError from "../../../Shared Components/InputError";
import SubmitButton from "../../../Shared Components/SubmitButton";
import useLoading from "../../../hooks/useLoading";
import ContentEditor from "../Dynamic Page/ContentEditor";

const FaqForm = ({ state, setState, apiCall }) => {
  const initialValues = {
    title: state?.data?.title ?? "",
    statusName: state?.data?.isActive === true ? "Active" : "Inactive",
    description: state?.data?.description ?? "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: FAQSchema,
    onSubmit: (data) => {
      formSubmit(data);
    },
  });

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const { token } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();

  const formSubmit = async (data) => {
    console.log("form submit called");

    //form data
    const sFormData = new FormData();
    sFormData.append("title", data.title);
    sFormData.append("description", data.description);
    sFormData.append("isActive", data.statusName === "Active" ? true : false);
    sFormData.append("status", data.statusName === "Active" ? 1 : 2);

    if (token) {
      if (state.data === undefined) {
        try {
          showLoader();
          const res = await axios.post(`${FAQ_ADD}`, sFormData, {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("FAQ Added", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      } else {
        try {
          showLoader();
          console.log("update api called");
          const res = await axios.put(
            `${FAQ_UPDATE}${state.data._id}`,
            {
              title: data?.title,
              description: data?.description,
              isActive: data?.statusName === "Active" ? true : false,
              status: data?.statusName === "Active" ? 1 : 2,
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("FAQ Updated", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">
              {!state.data ? "New FAQ" : "Edit FAQ"}
            </h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit}>
              {/* Title* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Title*"
                  htmlFor="title"
                  type="text"
                  name="title"
                  stateData={formik.values.title}
                  setStateData={formik.handleChange}
                />
                {formik.errors.title ? (
                  <InputError error={formik.errors.title} />
                ) : null}
              </div>

              {/* text editor */}
              {/* <div className="mb-3">
                <label htmlFor="desc" className="font12x500 form-label mb-1">
                  Desctiption*
                </label>
                <ContentEditor
                  stateData={formik.values.description}
                  setStateData={(e) => {
                    formik.setFieldValue("description", e);
                  }}
                />
                {formik.errors.description ? (
                  <InputError error={formik.errors.description} />
                ) : null}
              </div> */}

              <div className="mb-3">
                <label
                  htmlFor="floatingTextarea2"
                  className="font12x500 mb-1 form-label"
                >
                  Description*
                </label>
                <textarea
                  className="form-control shadow-none"
                  id="floatingTextarea2"
                  style={{ height: "180px" }}
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
                {formik.errors.description ? (
                  <InputError error={formik.errors.description} />
                ) : null}
              </div>

              {/* status */}
              <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: "Active", label: "Active" },
                    { value: "Inactive", label: "Inactive" },
                  ]}
                  label="Status*"
                  htmlFor="status"
                  type="text"
                  placeholder="Select Status"
                  name="statusName"
                  stateData={formik.values.statusName}
                  setStateData={(e) => {
                    formik.setFieldValue("statusName", e);
                  }}
                />
                {formik.errors.statusName ? (
                  <InputError error={formik.errors.statusName} />
                ) : null}
              </div>

              <SubmitButton
                data={!state.data}
                loader={loader}
                create="Create"
                update="Update"
                btnWidth="30%"
              />
            </form>

            {state?.data && <UpdationDetails data={state?.data} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqForm;
