import React, { useContext, useRef } from "react";
import { state } from "../../utils/Constants";
import InputField from "./sub components/InputField";
import Location from "./sub components/Location";
import { useFormik } from "formik";
import { step2Schema } from "../../utils/validationschema";
import InputError from "../../Shared Components/InputError";
import DynamicDropdown from "./sub components/DynamicDropdown";
import { registrationContext } from "../../Pages/Salon/Master Salon/RegistrationForm";

const Step2 = () => {
  const { functions, registrationData, setRegistrationData } =
    useContext(registrationContext);

  const next = () => {
    functions.nextStep();
  };
  const previous = (e) => {
    e.preventDefault();
    functions.prevStep();
  };

  console.log("registrationData", registrationData);
  const formik = useFormik({
    initialValues: registrationData,
    validationSchema: step2Schema,
    onSubmit: (data) => {
      console.log("data", data);
      setRegistrationData({
        ...registrationData,
        salonEmail: data.salonEmail,
        salonContactNumber: data.salonContactNumber,
        line_1: data.line_1,
        line_2: data.line_2,
        city: registrationData.city,
        state: registrationData.state,
        country: registrationData.country,
        pincode: registrationData.pincode,
      });
      next();
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {/* salon email */}
        <div className="mb-3">
          <InputField
            label="Email address (optional)"
            htmlFor="salonemail"
            type="email"
            name="salonEmail"
            registrationData={formik.values.salonEmail}
            setRegistrationData={formik.handleChange}
          />
          {formik.errors.salonEmail ? (
            <InputError error={formik.errors.salonEmail} />
          ) : null}
        </div>

        {/* salon contact number */}
        <div className="mb-3">
          <InputField
            label="Store Contact No*"
            htmlFor="sCNo"
            type="tel"
            name="salonContactNumber"
            registrationData={formik.values.salonContactNumber}
            setRegistrationData={formik.handleChange}
            readOnly={true}
          />
          {formik.errors.salonContactNumber ? (
            <InputError error={formik.errors.salonContactNumber} />
          ) : null}
        </div>

        {/* location */}
        <Location />

        {/* line_1 */}
        <div className="mb-3">
          <InputField
            label="Address Line 1*"
            htmlFor="line1"
            type="text"
            name="line_1"
            registrationData={formik.values.line_1}
            setRegistrationData={formik.handleChange}
          />
          {formik.errors.line_1 ? (
            <InputError error={formik.errors.line_1} />
          ) : null}
        </div>

        {/* line_2 */}
        <div className="mb-3">
          <InputField
            label="Address Line 2 (optional)"
            htmlFor="line2"
            type="text"
            name="line_2"
            registrationData={formik.values.line_2}
            setRegistrationData={formik.handleChange}
          />
          {formik.errors.line_2 ? (
            <InputError error={formik.errors.line_2} />
          ) : null}
        </div>

        {/* city */}
        <div className="mb-3">
          <InputField
            label="City*"
            htmlFor="city"
            type="text"
            name="city"
            registrationData={registrationData.city}
            readOnly={true}
          />
        </div>

        {/* state */}
        <div className="mb-3">
          <InputField
            label="State*"
            htmlFor="state"
            type="text"
            name="state"
            registrationData={registrationData.state}
            readOnly={true}
          />
        </div>

        {/* country */}
        <div className="mb-3">
          <label htmlFor="country" className="font12x500 form-label mb-1">
            Country*
          </label>
          <input
            type="text"
            className="form-control shadow-none"
            id="country"
            name="country"
            value={registrationData.country}
            readOnly={true}
          />
        </div>

        {/* pincode */}
        <div className="mb-3">
          <InputField
            label="Pincode*"
            htmlFor="pin"
            type="tel"
            name="pincode"
            registrationData={registrationData.pincode}
            readOnly={true}
          />
        </div>

        <div className="row mt-4">
          <div className="col-6">
            <button
              className="btn OtpBtn shadow-none"
              onClick={(e) => {
                previous(e);
              }}
            >
              Previous
            </button>
          </div>
          <div className="col-6">
            <button type="submit" className="btn OtpBtn shadow-none">
              Next
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Step2;
