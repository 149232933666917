import { useEffect, useState } from "react";
import SearchInput2 from "../../../../Shared Components/SearchInput2";

function MobileCountryListFilter(props) {
  const { onClick, filters, setFilter, resetParam, addParam, param, setParam } =
    props;
  const [animateFilter, setAnimateFilter] = useState({
    transform: "translate(100%)",
  });

  useEffect(() => {
    if (props.isOpenFilter) {
      setAnimateFilter({
        transform: "translate(0%)",
      });
    } else {
      setAnimateFilter({
        transform: "translate(100%)",
      });
    }
  }, [props.isOpenFilter]);

  return (
    <div style={animateFilter} className="mobile-bydate-filters-container">
      <div
        onClick={() => {
          props.setIsOpenFilter(false);
        }}
        className="left-filter-section"
      ></div>
      <div className="right-filter-section">
        <div className="mt-3" onClick={onClick}>
          <button className="apply">Add New</button>
        </div>

        <div className="right-filter-sub-container pt-3 pb-3">
          <p className="title">Filter</p>

          <div className="mb-4">
            <SearchInput2
              type="text"
              placeholder="Search by name"
              stateData={param}
              setStateData={setParam}
              parameter1="name"
              parameter2={undefined}
              width="auto"
              addParam={addParam}
              resetParam={resetParam}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileCountryListFilter;
