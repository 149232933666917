import React, { useState } from "react";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";

const ContentEditor = ({ stateData, setStateData }) => {
  const [editorState, setEditorState] = useState(
    BraftEditor.createEditorState(stateData)
  );

  const [values, setValues] = useState("");
  const handleChange = (editorState) => {
    setValues(editorState.toHTML());
    setEditorState(values);
    setStateData(values);
  };

  console.log("values", values);

  return (
    <>
      <BraftEditor value={editorState} language="en" onChange={handleChange} />
    </>
  );
};

export default ContentEditor;
