import React from "react";
import Loader from "../../../Loader";

const SalonServiceOrders = (props) => {
  const { ordersCount, loader } = props;
  return (
    <>
      <div className="statsTabWrapper">
        <p
          className="font14x500 mb-4"
          style={{
            color: "var(--text3)",
          }}
        >
          Salon Service Orders
        </p>
        <span
          className="font36x700"
          style={{
            color: "var(--text2)",
          }}
        >
          {loader ? (
            <span className="d-flex align-items-center">
              0
              <span className="ms-2">
                <Loader height="20" width="20" color="#772286" />
              </span>
            </span>
          ) : (
            ordersCount?.servicesAtSalon?.toLocaleString()
          )}
        </span>
      </div>
    </>
  );
};

export default SalonServiceOrders;
