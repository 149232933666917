import React from "react";
import PieChartCategories from "../Categories/PieChartCategories";

const colors = [
  "#97c1a9",
  "#fe7d8f",
  "#cbaacb",
  "#99ccff",
  "#90cbaa",
  "#EC407A",
];

const PaymentType = (props) => {
  const { label, paymentType } = props;
  const salonNew = JSON.parse(localStorage.getItem("salon_details"));

  return (
    <>
      <div className="col-12 col-lg-6">
        <div className="cardWrapper">
          <div className="cardWrapperHeader">
            <h3 className="font18x500 mb-0">{label}</h3>
          </div>
          <div className="cardContent">
            <div className="row">
              <div className="col-12">
                <PieChartCategories categories={paymentType} />
              </div>
              <div className="scrollContainer col-12 d-flex">
                {paymentType?.slice(0, 6)?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="dataTab d-flex align-items-center mb-4 ms-0"
                      style={{
                        color: colors[i],
                      }}
                    >
                      <div>
                        <div
                          style={{
                            backgroundColor: colors[i],
                            width: "33px",
                            height: "6px",
                            borderRadius: 10,
                          }}
                        ></div>
                      </div>
                      <div
                        className="payment-mode font16x500 ms-3 lh-base"
                        style={{
                          width: "max-content",
                        }}
                      >
                        <p className="mb-0 me-2">{item?.name}</p>
                        <p className="mb-0">{`${
                          salonNew?.country?.htmlSymbol !== ""
                            ? String.fromCharCode(salonNew?.country?.htmlSymbol)
                            : salonNew?.country?.currency
                        }${parseInt(item?.revenue).toLocaleString()}`}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentType;
