import React, { useEffect, useState } from "react";
import Layout from "../../../../Components/Layout";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteModal from "../../../../Components/DeleteRecord";
import {
  BRAND_LIST,
  PRODUCT_CATEGORY_SEARCH,
  SALON_PRODUCTS_DELETE,
  SALON_PRODUCTS_SEARCH,
} from "../../../../utils/Constants";
import ProductForm from "../../../../Components/ProductForm";
import { ToastContainer } from "react-toastify";
import useFetch from "../../../../hooks/useFetch";
import DropdownSmallDynamic from "../../../../Shared Components/DropdownSmallDynamic";
import useCategory from "../../../../hooks/useCategory";
import Pagination from "../../../../Components/Pagination";
import Loader from "../../../../Components/Loader";
import SearchInput2 from "../../../../Shared Components/SearchInput2";
import DropdownSmall from "../../../../Shared Components/DropdownSmall";
import productImage from "../../../../uploads/product/product1.jpg";
import PageNav from "../../../../Components/PageNav";
import UpdateStockForm from "../../../../Components/ProductForm/UpdateStockForm";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileProductFilters from "../../../../Components/Mobile/mobileProductFilter";

export const productLinks = [
  {
    link: "/salon/product",
    label: "Product Listing",
  },
  {
    link: "/salon/product-transactions",
    label: "Product Transactions",
  },
];

const Products = () => {
  const [commonData, setCommonData] = useState({
    data: {},
    modal: false,
    check: "",
  });

  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  //custom hook for getting category
  const [category, loaderSmall, getCategory] = useCategory({
    url: PRODUCT_CATEGORY_SEARCH,
    resultField: "productCategories",
  });
  //custom hook for getting brands
  const [brands, loader2, getBrands] = useCategory({
    url: BRAND_LIST,
    resultField: "brands",
  });

  const onEdit = (item) => {
    setCommonData({
      data: item,
      modal: true,
      check: "product",
    });
    document.body.style.overflow = "hidden";
  };
  const onAdd = () => {
    setCommonData({
      data: undefined,
      modal: true,
      check: "product",
    });
    document.body.style.overflow = "hidden";
  };
  const onAddStock = (item) => {
    setCommonData({
      data: {
        _id: item?._id,
        productId: item.productId,
        stock: item?.currentStock,
        name: item?.name,
        price: item?.discountPrice,
      },
      modal: true,
      check: "stock",
    });
    document.body.style.overflow = "hidden";
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [id, setId] = useState("");
  const onDelete = (id) => {
    setIsDeleteModalOpen(true);
    document.body.style.overflow = "hidden";
    setId(id);
  };

  const [filters, setFilter] = useState({
    owner: "salon",
    page: 1,
    per_page: 10,
    sort_by: "order",
    sort_order: "desc",
  });
  console.log(filters);

  const [param, setParam] = useState({
    show: false,
  });
  console.log("param", param);

  const functions = {
    addParam: () => {
      setFilter({
        ...filters,
        ...param,
      });
    },
    resetParam: () => {
      setParam({});
      delete filters.category;
      delete filters.productCategoryName;
      delete filters.status;
      delete filters.statusName;
      delete filters.inStock;
      delete filters.stockStatusName;
      delete filters.productType;
      delete filters.q;
      getData();
    },
  };

  const salonDetails = JSON.parse(localStorage.getItem("salon_details"));

  const [products, totalResult, error, loader, getData] = useFetch({
    url: `${SALON_PRODUCTS_SEARCH}salon=${salonDetails?._id}`,
    resultField: "products",
    parameter: filters,
  });

  useEffect(() => {
    getData();
  }, [filters]);
  useEffect(() => {
    getCategory();
    getBrands();
  }, []);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Products"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileProductFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          category={category}
          onClick={() => {
            onAdd();
          }}
          filters={filters}
          setFilter={setFilter}
          resetParam={functions.resetParam}
          param={param}
          setParam={setParam}
          addParam={functions.addParam}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Products"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Products"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper">
        <div className="service-navigation-neworder-button-container">
          <div className="service-navigation-neworder-button d-flex justify-content-between pe-2">
            <PageNav links={productLinks} />

            <div className="desktopButton">
              <button className="btn addBtn shadow-none" onClick={onAdd}>
                Add New Product
              </button>
            </div>
          </div>
        </div>

        <div className="filter-container container ms-0 ps-0 mb-3">
          <div className="row">
            <div className="col-12 col-lg-5 d-flex align-items-end">
              <SearchInput2
                type="text"
                placeholder="Search by name / product id"
                stateData={param}
                setStateData={setParam}
                parameter1="productId"
                parameter2="name"
                addParam={functions.addParam}
                resetParam={functions.resetParam}
              />
            </div>
            <div className="col-12 col-lg-7 d-flex justify-content-lg-end align-items-end">
              <div className="me-3">
                {/* stock status */}
                <DropdownSmall
                  data={[
                    { value: "In Stock", label: "In Stock" },
                    { value: "Out of Stock", label: "Out of Stock" },
                  ]}
                  label="Stock Status"
                  htmlFor="stockStatus"
                  type="text"
                  placeholder="Sort by Status"
                  stateData={filters?.stockStatusName}
                  setStateData={(e) => {
                    setFilter({
                      ...filters,
                      stockStatusName: e,
                      inStock: e === "In Stock" ? true : false,
                    });
                  }}
                />
              </div>

              <div className="me-3">
                {/* Product Type */}
                <DropdownSmall
                  data={[
                    { value: "Retail", label: "Retail" },
                    { value: "Salon", label: "Salon" },
                  ]}
                  label="Product Type"
                  htmlFor="productType"
                  type="text"
                  placeholder="Product type"
                  stateData={filters?.productType}
                  setStateData={(e) => {
                    setFilter({
                      ...filters,
                      productType: e,
                    });
                  }}
                />
              </div>

              <div className="me-3">
                {/* status */}
                <DropdownSmall
                  data={[
                    { value: "All", label: "All" },
                    { value: "Active", label: "Active" },
                    { value: "Inactive", label: "Inactive" },
                  ]}
                  label="Status"
                  htmlFor="status"
                  type="text"
                  placeholder="Sort by Status"
                  stateData={filters?.statusName}
                  setStateData={(e) => {
                    if (e === "All") {
                      delete filters.status;
                      setFilter({
                        ...filters,
                        statusName: e,
                      });
                    } else {
                      setFilter({
                        ...filters,
                        statusName: e,
                        status: e === "Active" ? 1 : 2,
                      });
                    }
                  }}
                />
              </div>

              {/* category */}
              <DropdownSmallDynamic
                data={category}
                loader={loaderSmall}
                label="Category*"
                htmlFor="category"
                type="text"
                placeholder="Select Category"
                stateData={filters?.productCategoryName}
                setStateData={(e, x) => {
                  if (e === "" && filters.category) {
                    delete filters.category;
                    setFilter({
                      ...filters,
                      productCategoryName: x,
                    });
                  } else {
                    setFilter({
                      ...filters,
                      category: e,
                      productCategoryName: x,
                    });
                  }
                }}
              />

              <button
                className="btn resetBtn shadow-none ms-3"
                onClick={functions.resetParam}
              >
                Reset
              </button>
            </div>
          </div>
        </div>

        {products?.length !== 0 ? (
          <>
            <div className="container desktop dataTable ms-0 ps-0">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">SL.No.</th>
                    <th scope="col">Image</th>
                    <th scope="col">Product Id</th>
                    <th scope="col">Order</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Product Category</th>
                    <th scope="col">Product Type</th>
                    <th scope="col">Stock Status</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {products?.map((item, i) => {
                    return (
                      <tr key={item?._id}>
                        <td>
                          {(filters.page - 1) * filters.per_page + (i + 1)}
                        </td>
                        <td>
                          {item?.image === null ? (
                            <img
                              src={productImage}
                              alt="product"
                              width={72}
                              height={48}
                              style={{ borderRadius: "4px" }}
                            />
                          ) : (
                            <img
                              src={`${process.env.REACT_APP_Image_BaseURL}${item?.image}`}
                              alt="product"
                              width={72}
                              height={48}
                              style={{ borderRadius: "4px" }}
                            />
                          )}
                        </td>
                        <td>{item?.productId}</td>
                        <td>{item?.order}</td>
                        <td>{item?.name}</td>
                        <td>{item?.category?.name}</td>
                        <td>{item?.productType}</td>
                        <td>
                          <p
                            className="status mb-0"
                            style={{
                              backgroundColor: `${
                                item?.currentStock !== 0
                                  ? "var(--status_color2)"
                                  : "var(--status_color1)"
                              }`,
                            }}
                          >
                            {`${
                              item?.currentStock !== 0
                                ? `${item?.currentStock}`
                                : `${item?.currentStock}`
                            }`}
                          </p>
                          <button
                            className="btn font14x400 filterBtnSmall shadow-none ms-2"
                            onClick={() => {
                              onAddStock(item);
                            }}
                          >
                            Update Stock
                          </button>
                        </td>
                        <td>
                          <p
                            className="status mb-0"
                            style={{
                              backgroundColor: `${
                                item?.status === 1
                                  ? "var(--status_color2)"
                                  : "var(--status_color1)"
                              }`,
                            }}
                          >
                            {`${item?.status === 1 ? "Active" : "Inactive"}`}
                          </p>
                        </td>
                        <td>
                          <button
                            className="btn font14x400 filterBtn shadow-none me-2"
                            onClick={() => {
                              onEdit(item);
                            }}
                          >
                            Edit
                          </button>
                          <span
                            className="deleteBtn"
                            onClick={() => {
                              onDelete(item?._id);
                            }}
                          >
                            <RiDeleteBin6Line />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="container mobile dataTable ms-0 ps-0">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Order</th>
                    <th scope="col">Product</th>
                    <th scope="col">Stock Status</th>
                    <th scope="col">Product Type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Image</th>
                    <th scope="col">Product Id</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {products?.map((item, i) => {
                    return (
                      <tr key={item?._id}>
                        <td>{item?.order}</td>
                        <td>
                          {item?.name}
                          <br />
                          {item?.category?.name}
                        </td>
                        <td>
                          <p
                            className="status mb-0"
                            style={{
                              backgroundColor: `${
                                item?.currentStock !== 0
                                  ? "var(--status_color2)"
                                  : "var(--status_color1)"
                              }`,
                            }}
                          >
                            {`${
                              item?.currentStock !== 0
                                ? `${item?.currentStock}`
                                : `${item?.currentStock}`
                            }`}
                          </p>
                          <button
                            className="btn font14x400 filterBtnSmall shadow-none ms-2"
                            onClick={() => {
                              onAddStock(item);
                            }}
                          >
                            Update Stock
                          </button>
                        </td>
                        <td>{item?.productType}</td>
                        <td>
                          <p
                            className="status mb-0"
                            style={{
                              backgroundColor: `${
                                item?.status === 1
                                  ? "var(--status_color2)"
                                  : "var(--status_color1)"
                              }`,
                            }}
                          >
                            {`${item?.status === 1 ? "Active" : "Inactive"}`}
                          </p>
                        </td>
                        <td>
                          {item?.image === null ? (
                            <img
                              src={productImage}
                              alt="product"
                              width={72}
                              height={48}
                              style={{ borderRadius: "4px" }}
                            />
                          ) : (
                            <img
                              src={`${process.env.REACT_APP_Image_BaseURL}${item?.image}`}
                              alt="product"
                              width={72}
                              height={48}
                              style={{ borderRadius: "4px" }}
                            />
                          )}
                        </td>
                        <td>{item?.productId}</td>
                        <td>
                          <button
                            className="btn font14x400 filterBtn shadow-none me-2"
                            onClick={() => {
                              onEdit(item);
                            }}
                          >
                            Edit
                          </button>
                          <span
                            className="deleteBtn"
                            onClick={() => {
                              onDelete(item?._id);
                            }}
                          >
                            <RiDeleteBin6Line />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Product Found"
            )}
          </div>
        )}

        {products?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      {/* delete modal */}
      {isDeleteModalOpen && (
        <DeleteModal
          state={isDeleteModalOpen}
          setState={setIsDeleteModalOpen}
          url={SALON_PRODUCTS_DELETE}
          id={id}
          text="Product"
          apiCall={getData}
        />
      )}

      {/* product form add and update both */}
      {commonData.modal && commonData.check === "product" && (
        <ProductForm
          data={category}
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
          id={salonDetails._id}
          brandData={brands}
          currencySymbol={salonDetails?.address?.country}
        />
      )}
      {commonData.modal && commonData.check === "stock" && (
        <UpdateStockForm
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
          id={salonDetails._id}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default Products;
