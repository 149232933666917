import React from "react";
import PaymentMethods from "../../../Shared Components/PaymentMethods";
import { useFormik } from "formik";
import { orderPaymentModeSchema } from "../../../utils/validationschema";
import InputError from "../../../Shared Components/InputError";
import { toast } from "react-toastify";
import axios from "axios";
import { authHeader, UPDATE_ORDER_STATUS } from "../../../utils/Constants";
import useAuthContext from "../../../hooks/useAuthContext";

const PaymentModeSelection = ({ state, setState, apiCall }) => {
  const setPositionToBody = () => {
    setState({
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const { token } = useAuthContext();

  const initialValues = {
    paymentMode: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: orderPaymentModeSchema,
    onSubmit: (data) => {
      changedStatus(data);
      //   console.log("data", data);
    },
  });

  const changedStatus = async (data) => {
    if (
      //USE THIS WHEN YOU WANT STATUS IS CHANGED STEP BY STEP
      // state?.data?.statusNumber - 1 === Number(state?.data?.actualStatus) ||
      // state?.data?.statusNumber === 4

      //USE THIS WHEN YOU WANT STATUS IS NOT CHANGED STEP BY STEP
      state?.data?.statusNumber > Number(state?.data?.actualStatus)
    ) {
      if (token) {
        try {
          const res = await axios.put(
            `${UPDATE_ORDER_STATUS}${state?.data?._id}/product`,
            {
              productsProcessingStatus: state?.data?.statusNumber.toString(),
              paymentMode: data?.paymentMode,
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );
          if (res.status === 200) {
            apiCall();
            setPositionToBody();
            toast.success("Status Changed", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    } else {
      //USE THIS WHEN YOU WANT STATUS IS CHANGED STEP BY STEP

      // toast.warn("Changed Status Step By Step", {
      //   position: "bottom-center",
      //   autoClose: 1000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      // });

      //USE THIS WHEN YOU WANT STATUS IS NOT CHANGED STEP BY STEP
      toast.warn("You can't go backward", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  return (
    <>
      <div
        className={
          state.modal
            ? "deleteModalWrapper deleteModalWrapper-active"
            : "deleteModalWrapper"
        }
      >
        <div className="deleteModalDiv" onClick={setPositionToBody}></div>
        <div
          className="deleteModalContainer"
          style={{
            width: "600px",
          }}
        >
          <p className="font16x500 mb-4">Select collected payment mode:</p>

          <form onSubmit={formik.handleSubmit}>
            <div className="">
              <PaymentMethods
                label="Cash"
                imgPath="/Assets/cash.svg"
                htmlFor="cash"
                name="paymentMode"
                value="Cash"
                stateData={formik.values.paymentMode}
                setStateData={(e) => {
                  formik.setFieldValue("paymentMode", e);
                }}
              />

              <PaymentMethods
                label="UPI"
                imgPath="/Assets/payU.svg"
                htmlFor="upi"
                name="paymentMode"
                value="UPI"
                stateData={formik.values.paymentMode}
                setStateData={(e) => {
                  formik.setFieldValue("paymentMode", e);
                }}
              />

              <PaymentMethods
                label="Card"
                imgPath="/Assets/payU.svg"
                htmlFor="card"
                name="paymentMode"
                value="Card"
                stateData={formik.values.paymentMode}
                setStateData={(e) => {
                  formik.setFieldValue("paymentMode", e);
                }}
              />
            </div>
            {formik.errors.paymentMode ? (
              <InputError error={formik.errors.paymentMode} />
            ) : null}

            <div className="d-flex mt-3">
              <button
                className="btn filterBtn shadow-none me-3"
                onClick={setPositionToBody}
              >
                Cancel
              </button>
              <button className="btn filterBtn shadow-none" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PaymentModeSelection;
