import React, { useEffect, useState } from "react";
import Layout from "../../../../../Components/Layout";
import { INVENTORY_LIST } from "../../../../../utils/Constants";
import { getAddedOnDateTime } from "../../../../../Functions/dateTime";
import { toast, ToastContainer } from "react-toastify";
import useFetch from "../../../../../hooks/useFetch";
import Pagination from "../../../../../Components/Pagination";
import Loader from "../../../../../Components/Loader";
import SearchInput2 from "../../../../../Shared Components/SearchInput2";
import PageNav from "../../../../../Components/PageNav";
import { productLinks } from "..";
import MobileSalonDashboardSideNavHeader from "../../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileSalonDashboardSideNav from "../../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardHeader from "../../../../../Components/mobilesalondashboardheader";
import MobileProductTransactionFilters from "../../../../../Components/Mobile/mobileProductTransactionFilter";
import MobileFilterHeader from "../../../../../Components/mobilefilterheader";
import InputDate from "../../../../../Shared Components/InputDate";
import moment from "moment";
import GenerateReportModal from "../../../../../Components/Generate Report";

const ProductTransaction = () => {
  const salonDetails = JSON.parse(localStorage.getItem("salon_details"));
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [filters, setFilter] = useState({
    salon: salonDetails?._id && salonDetails?._id,
    page: 1,
    per_page: 10,
  });
  console.log(filters);

  const [param, setParam] = useState({
    show: false,
  });
  console.log("param", param);

  const functions = {
    addParam: () => {
      if (param.fromDate && param.tillDate) {
        const checkDate = moment(param.tillDate, "YYYY-MM-DD").isAfter(
          moment(param.fromDate, "YYYY-MM-DD")
        );
        if (checkDate) {
          setFilter({
            ...filters,
            ...param,
          });
        } else {
          toast.error("From date must be less than till date.", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    },
    resetParam: () => {
      setParam({});
      delete filters.q;
      delete filters.fromDate;
      delete filters.tillDate;
      getData();
    },
    onOpenModal: () => {
      setIsOpen(true);
      document.body.style.overflow = "hidden";
    },
  };

  const [inventories, totalResult, error, loader, getData] = useFetch({
    url: `${INVENTORY_LIST}`,
    resultField: "inventories",
    parameter: filters,
  });

  useEffect(() => {
    getData();
  }, [filters]);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  console.log("timezone", moment().zone());
  // console.log("timezone", moment().ti);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Product Transactions"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileProductTransactionFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          resetParam={functions.resetParam}
          param={param}
          setParam={setParam}
          addParam={functions.addParam}
          generateReport={() => {
            functions.onOpenModal();
          }}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Product Transactions"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Product Transactions"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper">
        <div className="service-navigation-neworder-button-container">
          <div className="service-navigation-neworder-button d-flex justify-content-between pe-2">
            <PageNav links={productLinks} />
            <div className="desktopButton">
              <button
                className="btn addBtn shadow-none"
                onClick={() => {
                  functions.onOpenModal();
                }}
              >
                Generate Report
              </button>
            </div>
          </div>
        </div>

        <div className="filter-container container ms-0 ps-0 mb-3">
          <div className="row">
            <div className="col-12 col-lg-5 d-flex align-items-end mt-4">
              <SearchInput2
                type="text"
                placeholder="Search by product id"
                stateData={param}
                setStateData={setParam}
                parameter1="productId"
                parameter2={undefined}
                addParam={functions.addParam}
                resetParam={functions.resetParam}
              />
            </div>
            <div className="col-12 col-lg-7 d-flex justify-content-lg-end align-items-end">
              {/* date from */}
              <InputDate
                label="From"
                htmlFor="from"
                type="date"
                stateData={param?.fromDate}
                setStateData={(e) => {
                  setParam({
                    ...param,
                    fromDate: moment(e).format("YYYY-MM-DD"),
                  });
                }}
              />

              {/* date to */}
              <div className="ms-3">
                <InputDate
                  label="Till"
                  htmlFor="till"
                  type="date"
                  stateData={param?.tillDate}
                  setStateData={(e) => {
                    setParam({
                      ...param,
                      tillDate: moment(e).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </div>

              <button
                className="btn filterBtn shadow-none ms-3"
                onClick={functions.addParam}
              >
                Apply
              </button>
              <button
                className="btn resetBtn shadow-none ms-3"
                onClick={functions.resetParam}
              >
                Reset
              </button>
            </div>
          </div>
        </div>

        {inventories?.length !== 0 ? (
          <>
            <div className="container desktop dataTable ms-0 ps-0">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">SL.No.</th>
                    <th scope="col">Product Details</th>
                    <th scope="col">Added On</th>
                    <th scope="col">Stock</th>
                    <th scope="col">Price</th>
                    <th scope="col">Total Cost</th>
                    <th scope="col">Status</th>
                    <th scope="col">Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {inventories?.map((item, i) => {
                    return (
                      <tr key={item?._id}>
                        <td>
                          {(filters.page - 1) * filters.per_page + (i + 1)}
                        </td>
                        <td>
                          {item?.productId}
                          <br />
                          {item?.name}
                        </td>
                        <td>{getAddedOnDateTime(item?.createdAt)}</td>
                        <td>{item?.qty}</td>
                        {salonDetails?.country?.htmlSymbol !== "" ? (
                          <td>
                            {item?.price
                              ? `${String.fromCharCode(
                                  salonDetails?.country?.htmlSymbol
                                )} ${item?.price}`
                              : "--"}
                          </td>
                        ) : (
                          <td>
                            {item?.price
                              ? `${salonDetails?.country?.currency} ${item?.price}`
                              : "--"}
                          </td>
                        )}
                        {salonDetails?.country?.htmlSymbol !== "" ? (
                          <td>
                            {item?.totalCost
                              ? `${String.fromCharCode(
                                  salonDetails?.country?.htmlSymbol
                                )} ${item?.totalCost}`
                              : "--"}
                          </td>
                        ) : (
                          <td>
                            {item?.totalCost
                              ? `${salonDetails?.country?.currency} ${item?.totalCost}`
                              : "--"}
                          </td>
                        )}
                        <td>
                          <p
                            className="status mb-0"
                            style={{
                              backgroundColor: `${
                                item?.qtyStatus === "IN"
                                  ? "var(--status_color2)"
                                  : "var(--status_color1)"
                              }`,
                            }}
                          >
                            {`${item?.qtyStatus === "IN" ? "In" : "Out"}`}
                          </p>
                        </td>
                        <td>{item?.comment}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="container mobile dataTable ms-0 ps-0">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Product Details</th>
                    <th scope="col">Status</th>
                    <th scope="col">Stock</th>
                    <th scope="col">Added On</th>
                    <th scope="col">Price</th>
                    <th scope="col">Total Cost</th>
                    <th scope="col">Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {inventories?.map((item, i) => {
                    return (
                      <tr key={item?._id}>
                        <td>
                          {item?.productId}
                          <br />
                          {item?.name}
                        </td>
                        <td>
                          <p
                            className="status mb-0"
                            style={{
                              backgroundColor: `${
                                item?.qtyStatus === "IN"
                                  ? "var(--status_color2)"
                                  : "var(--status_color1)"
                              }`,
                            }}
                          >
                            {`${item?.qtyStatus === "IN" ? "In" : "Out"}`}
                          </p>
                        </td>
                        <td>{item?.qty}</td>
                        <td>
                          {moment(item?.createdAt).format("DD-MMM-YYYY")}
                          <br />
                          {moment(item?.createdAt).format("HH:mm")}
                          <br />
                          {item?.user &&
                            `${item?.user?.name}(${item?.user?.role})`}
                        </td>
                        {salonDetails?.country?.htmlSymbol !== "" ? (
                          <td>
                            {item?.price
                              ? `${String.fromCharCode(
                                  salonDetails?.country?.htmlSymbol
                                )} ${item?.price}`
                              : "--"}
                          </td>
                        ) : (
                          <td>
                            {item?.price
                              ? `${salonDetails?.country?.currency} ${item?.price}`
                              : "--"}
                          </td>
                        )}
                        {salonDetails?.country?.htmlSymbol !== "" ? (
                          <td>
                            {item?.totalCost
                              ? `${String.fromCharCode(
                                  salonDetails?.country?.htmlSymbol
                                )} ${item?.totalCost}`
                              : "--"}
                          </td>
                        ) : (
                          <td>
                            {item?.totalCost
                              ? `${salonDetails?.country?.currency} ${item?.totalCost}`
                              : "--"}
                          </td>
                        )}
                        <td>{item?.comment}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Product Transactions Found"
            )}
          </div>
        )}

        {inventories?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      {isOpen && (
        <GenerateReportModal
          state={isOpen}
          setState={setIsOpen}
          salonName={salonDetails?.name?.split(" ").join("_")}
          saveFileName="Product_Inventories"
          filters={filters}
          setFilter={setFilter}
          getData={getData}
          url={INVENTORY_LIST}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default ProductTransaction;
