import moment from "moment";
import React, { useEffect, useState } from "react";

const DateSelection = ({ state, setState, data }) => {
  const [weekdayNames, setweekdayNames] = useState();
  const [date, setdate] = useState();

  const functions = {
    getWeekNames: () => {
      setweekdayNames(
        Array.apply(null, Array(7)).map((_, i) => {
          return moment().add(i, "days").format("ddd");
        })
      );
    },
    getDate: () => {
      setdate(
        Array.apply(null, Array(7)).map((_, i) => {
          return moment().add(i, "days").format("MMM DD");
        })
      );
    },
    addFormattedDate: (e, i) => {
      if (e.target.checked) {
        setState({
          ...state,
          date: moment().format(
            `yyyy-${
              date && moment().month(date[i].split(" ")[0]).format("MM")
            }-${date && date[i].split(" ")[1]}`
          ),
        });
      }
    },
  };

  useEffect(() => {
    functions.getWeekNames();
    functions.getDate();
  }, []);

  return (
    <>
      <>
        <label className="font16x500 form-label mb-3 labelBig">
          Pick a date to block*
        </label>
        <div className="checkBoxContainer2">
          {[...Array(7)]?.map((v, i) => {
            return (
              <div className="dateCheck" key={i}>
                <label className="font14x400 form-check-label" htmlFor={i}>
                  <input
                    type="radio"
                    className="me-2"
                    id={i}
                    name="pickDate"
                    value={state.date}
                    // checked={
                    //   moment(data).format("YYYY-MM-DD") === state.date
                    //     ? true
                    //     : false
                    // }
                    onChange={(e) => {
                      functions.addFormattedDate(e, i);
                    }}
                  />
                  <span className="dateSelection">
                    <p
                      className="font16x500 mb-1"
                      style={{ color: "var(--btn_primary)" }}
                    >
                      {weekdayNames && weekdayNames[i]}
                    </p>
                    <p className="font16x400 mb-1"> {date && date[i]} </p>
                  </span>
                </label>
              </div>
            );
          })}
        </div>
      </>
    </>
  );
};

export default DateSelection;
