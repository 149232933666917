import {
  ADMIN,
  GET_CHILD_SALONS,
  MASTER_SALON,
  SALON_BY_ID,
} from "../../../utils/Constants";
import salonLogo from "../../../uploads/salon/logo1.jpg";
import useFetch from "../../../hooks/useFetch";
import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useLocation } from "react-router-dom";

function MobileSalonDashboardSideNavHeader(props) {
  const salonDetails = JSON.parse(localStorage.getItem("salon_details"));
  const role = JSON.parse(localStorage.getItem("role"));
  const token = JSON.parse(localStorage.getItem("token"));
  const user = JSON.parse(localStorage.getItem("user"));
  const { pathname } = useLocation();

  const functions = {
    loginAsChildSalon: async (id) => {
      if (token && id) {
        try {
          const res = await axios.get(`${SALON_BY_ID}${id}`, {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });

          if (res.status === 200) {
            window.localStorage.setItem(
              "salon_details",
              JSON.stringify(res?.data?.salon)
            );
            window.location.reload();
          } else {
            console.log("error", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            toast.error(error.response.data.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
  };

  const [childSalons, totalResult, error, loader2, getData] = useFetch({
    url: `${GET_CHILD_SALONS}${
      salonDetails?.masterSalon ? salonDetails?.masterSalon : salonDetails?._id
    }/childSalon`,
    resultField: "childSalons",
  });

  useEffect(() => {
    // console.log(pathname);
    // console.log(pathname.split("/")[1]);
    if (pathname.split("/")[1] === "salon") {
      getData();
    }
  }, []);

  return (
    <>
      <div className="position-sticky top-0 bg-white py-4">
        <div className="d-flex justify-content-end">
          <button
            onClick={() => {
              props.setIsOpenSideNav(false);
            }}
            className="bg-white border-0"
          >
            <img src="/Assets/cross.svg" />
          </button>
        </div>
        {role === ADMIN ? (
          <div className="mt-2">
            <p className="mb-0 font16x400">{`${user?.name ?? ""} admin`}</p>
            {pathname?.includes("/salon") && (
              <div className="d-flex align-items-center">
                {salonDetails?.logo === null ? (
                  <img
                    src={salonLogo}
                    alt="salon"
                    width={24}
                    height={24}
                    style={{ borderRadius: "4px" }}
                  />
                ) : (
                  <img
                    src={`${process.env.REACT_APP_Image_BaseURL}${salonDetails?.logo}`}
                    alt="salon"
                    width={24}
                    height={24}
                    style={{ borderRadius: "4px" }}
                  />
                )}

                <p className="mb-0 ms-2 font16x400">{`${
                  salonDetails?.name ?? "--"
                }, ${salonDetails?.address?.city ?? "--"}`}</p>

                {role === MASTER_SALON && childSalons?.length !== 0 && (
                  <div className="childSalonsDropdown">
                    <img
                      src="/Assets/angleDown.svg"
                      alt="salon"
                      width={25}
                      height={25}
                      className="ms-1"
                    />

                    <div className="hoverChildSalons">
                      <ul className="list-unstyled m-0">
                        {salonDetails?.masterSalon && (
                          <li
                            className="childSalonList"
                            onClick={() => {
                              functions.loginAsChildSalon(
                                salonDetails?.masterSalon
                              );
                            }}
                          >
                            Return to Master
                          </li>
                        )}

                        {childSalons &&
                          childSalons?.map((item) => {
                            return (
                              <li
                                key={item?._id}
                                className="childSalonList"
                                onClick={() => {
                                  functions.loginAsChildSalon(item?._id);
                                }}
                              >{`${item?.salonId}_${item?.name}`}</li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="d-flex align-items-center mt-2">
            {salonDetails?.logo === null ? (
              <img
                src={salonLogo}
                alt="salon"
                width={24}
                height={24}
                style={{ borderRadius: "4px" }}
              />
            ) : (
              <img
                src={`${process.env.REACT_APP_Image_BaseURL}${salonDetails?.logo}`}
                alt="salon"
                width={24}
                height={24}
                style={{ borderRadius: "4px" }}
              />
            )}

            <p className="mb-0 font16x400 ms-2">{`${salonDetails?.name}, ${salonDetails?.address?.city}`}</p>

            {role === MASTER_SALON && childSalons?.length !== 0 && (
              <div className="childSalonsDropdown">
                <img
                  src="/Assets/angleDown.svg"
                  alt="salon"
                  width={25}
                  height={25}
                  className="ms-1"
                />

                <div className="hoverChildSalons">
                  <ul className="list-unstyled m-0">
                    {salonDetails?.masterSalon && (
                      <li
                        className="childSalonList"
                        onClick={() => {
                          functions.loginAsChildSalon(
                            salonDetails?.masterSalon
                          );
                        }}
                      >
                        Return to Master
                      </li>
                    )}

                    {childSalons &&
                      childSalons?.map((item) => {
                        return (
                          <li
                            key={item?._id}
                            className="childSalonList"
                            onClick={() => {
                              functions.loginAsChildSalon(item?._id);
                            }}
                          >{`${item?.salonId}_${item?.name}`}</li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default MobileSalonDashboardSideNavHeader;
