import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuthContext from "../../hooks/useAuthContext";
import useLoading from "../../hooks/useLoading";
import {
  ADMIN,
  authHeader,
  CHILD_SALON,
  CURRENT_LOGGEDIN_USER_ID,
  CURRENT_USER_SALON,
  DEVICE_REGISTRATION,
  ENDUSER,
  LOGIN,
  MASTER_SALON,
  MASTER_SALON_USER,
  SALON_BY_ID,
  USER_BY_ID,
  VERIFY_OTP,
  withoutAuthHeader,
} from "../../utils/Constants";
import Loader from "../Loader";
import { PushNotificationToken } from "../../firebase";
import { handleTime } from "../../Functions/other";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const inputs = Array(4).fill("");

const VerifyOtp = ({ authDetails, number }) => {
  const [tokenFound, setTokenFound] = useState("");

  useEffect(() => {
    PushNotificationToken(setTokenFound);
  }, []);

  const [inputOTP, setInputOTP] = useState("");
  const [OTP, setOTP] = useState(authDetails?.otp);
  const [loader, showLoader, hideLoader] = useLoading();

  const navigate = useNavigate();

  //otp expireTime in seconds
  const [expireTime, setExpireTime] = useState(300);
  const [time, setTime] = useState("00:00");
  useEffect(() => {
    if (expireTime !== 0) {
      setTime(handleTime(expireTime));
      setTimeout(() => {
        setExpireTime(expireTime - 1);
      }, 1000);
    }
  }, [expireTime]);

  const handleKeyBoardFocus = (e) => {
    if ((e.keyCode === 8 || e.keyCode === 46) && e.target.value === "") {
      if (
        e.target &&
        e.target.parentElement &&
        e.target.parentElement.previousElementSibling &&
        e.target.parentElement.previousElementSibling.children[0]
      ) {
        e.target.parentElement.previousElementSibling.children[0].focus();
      }
    }
  };

  const handleOTP = (e) => {
    if (e.target.value.length === 1) {
      if (
        e.target &&
        e.target.parentElement &&
        e.target.parentElement.nextElementSibling &&
        e.target.parentElement.nextElementSibling.children[0]
      ) {
        e.target.parentElement.nextElementSibling.children[0].focus();
      }
    }

    let otpNumber = "";
    const inputsValue = document.getElementsByClassName("otpField");
    for (const e of inputsValue) {
      otpNumber += e.value;
    }
    setInputOTP(otpNumber);
  };

  const { updateToken, updateSalon, updateRole } = useAuthContext();

  const resendOtp = async () => {
    try {
      showLoader();
      const res = await axios.post(
        LOGIN,
        {
          mobile: number,
          country: "6448d92d418c91098079e043",
        },
        {
          headers: withoutAuthHeader,
        }
      );

      if (res.status === 200) {
        setOTP(res.data.otp);
        setExpireTime(300);
        hideLoader();
      }
    } catch (err) {
      console.log("error: ", err);
      if (err.response.status === 502) {
        hideLoader();
        toast.error(err.response.data.message, {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    }
  };

  const verifyOTP = async (e) => {
    e.preventDefault();
    try {
      showLoader();
      const res = await axios.post(
        VERIFY_OTP,
        {
          mobile: authDetails?.mobile,
          hash: authDetails?.hash,
          otp: inputOTP,
          role: "salon",
          country: authDetails?.country,
        },
        {
          headers: withoutAuthHeader,
        }
      );

      if (res.status === 200) {
        if (res.data.role === ENDUSER) {
          toast.warn("You are End User. Please login to your app.", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          // window.location.href = "/";
          hideLoader();
        } else {
          window.localStorage.setItem("token", JSON.stringify(res.data.token));
          updateToken(res.data.token);
          window.localStorage.setItem("role", JSON.stringify(res.data.role));
          updateRole(res.data.role);
          hideLoader();

          if (res.data.role === ADMIN) {
            navigate("/admin/dashboard");
          } else if (
            res.data.role === MASTER_SALON ||
            res.data.role === CHILD_SALON
          ) {
            try {
              const res2 = await axios.get(CURRENT_USER_SALON, {
                //headers: authHeader,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + res.data.token,
                  "X-Requested-With": "XMLHttpRequest",
                },
              });

              if (res2.status === 200) {
                if (res2?.data?.salons[0]?._id) {
                  deviceRegister(res2?.data?.salons[0]?._id, res.data.token);
                }

                if (
                  res.data.role === CHILD_SALON &&
                  res2.data.salons.length === 0
                ) {
                  alert(
                    "Your salon is not onboarded. Please contact your master salon for registration."
                  );
                  window.location.reload();
                } else {
                  if (res2.data.salons.length === 0) {
                    navigate("/registration", {
                      state: {
                        mobile: authDetails?.mobile,
                      },
                    });
                  } else if (
                    res2.data.salons[0].status === 4 ||
                    res2.data.salons[0].salonStatus === "pending"
                  ) {
                    alert("Your application is in under process. Please wait.");
                    window.location.reload();
                  } else {
                    window.localStorage.setItem(
                      "salon_details",
                      JSON.stringify(res2.data.salons[0])
                    );
                    updateSalon(res2.data.salons[0]);
                    console.log("verify screen");
                    navigate("/salon/dashboard");
                  }
                }
              }
            } catch (error) {
              console.log("salon data catch error after verify:", error);
              hideLoader();
            }
          } else if (res.data.role === MASTER_SALON_USER) {
            console.log("role", res.data.role);
            //execute current logged in user api then
            try {
              const res3 = await axios.get(`${CURRENT_LOGGEDIN_USER_ID}`, {
                //headers: authHeader,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + res.data.token,
                  "X-Requested-With": "XMLHttpRequest",
                },
              });

              if (res3.status === 200) {
                //execute user get by id api then
                const res4 = await axios.get(`${USER_BY_ID}${res3?.data?.id}`, {
                  //headers: authHeader,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + res.data.token,
                    "X-Requested-With": "XMLHttpRequest",
                  },
                });

                if (res4.status === 200) {
                  //execute salon get by id api
                  const res5 = await axios.get(
                    `${SALON_BY_ID}${res4?.data?.user?.salon}`,
                    {
                      //headers: authHeader,
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + res.data.token,
                        "X-Requested-With": "XMLHttpRequest",
                      },
                    }
                  );

                  if (res5.status === 200) {
                    window.localStorage.setItem(
                      "salon_details",
                      JSON.stringify(res5.data.salon)
                    );
                    updateSalon(res5.data.salon);
                    navigate("/salon/dashboard");
                  }
                }
              } else {
                console.log("error", res3.status);
              }
            } catch (error) {
              console.log("catch error: ", error);
              if (error.response.status === 502) {
                toast.error(error.response.data.message, {
                  position: "bottom-center",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                });
              }
            }
          }
        }
      }
    } catch (err) {
      if (err?.response?.status >= 400 && err?.response?.status <= 599) {
        hideLoader();
        toast.error(err?.response?.data?.message, {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
      console.log("err", err);
    }
  };

  const deviceRegister = async (salonId, token) => {
    if (token) {
      try {
        const res = await axios.post(
          `${DEVICE_REGISTRATION}${salonId}`,
          {
            deviceId: tokenFound,
            platform: "web",
          },
          {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );

        if (res.status === 200) {
        } else {
          console.log("else error: ", res.status);
        }
      } catch (error) {
        console.log("catch error: ", error);
        if (error?.response?.status === 502) {
          // toast.error(error?.response?.data?.message, {
          //   position: "bottom-center",
          //   autoClose: 1000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          // });
          console.log("Device Registration:", error?.response?.data?.message);
        }
      }
    }
  };

  console.log("OTP is:", OTP);

  return (
    <>
      <main>
        <div className="container">
          <div className="row flex-column ps-3 pe-3">
            <div className="authWrapper col-12 col-lg-5 p-0">
              <div className="authContainer">
                <MdOutlineKeyboardBackspace
                  style={{
                    fontSize: 25,
                    color: "#772286",
                    cursor: "pointer",
                    marginBottom: 10,
                  }}
                  onClick={() => window.location.reload()}
                />
                <div className="text-center mt-2">
                  <img
                    src="/Assets/groomeLogo.png"
                    alt="logo"
                    draggable="false"
                    className="img-fluid"
                    width={200}
                  />
                </div>
                <h3 className="font16x500 text-center mt-5 mb-4">
                  Please verify OTP
                </h3>
                <div>
                  <form onSubmit={verifyOTP}>
                    <div className="row">
                      <div className="col-12 mb-4 d-flex justify-content-center">
                        {inputs.map((item, index) => {
                          return (
                            <div className="otpInputs me-2" key={index}>
                              <input
                                type="tel"
                                className="otpField form-control shadow-none"
                                maxLength={1}
                                onKeyDown={handleKeyBoardFocus}
                                onChange={handleOTP}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn OtpBtn shadow-none font16x500"
                    >
                      {loader ? (
                        <div className="d-flex justify-content-center">
                          <Loader height="25" width="25" color="#fff" />
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </form>
                </div>
              </div>
              <div className="authBoxHint text-center">
                {expireTime === 0 ? (
                  <p className="resendOtp font14x500" onClick={resendOtp}>
                    Resend OTP
                  </p>
                ) : (
                  <p>Resend OTP in {time}</p>
                )}
                <p className="font14x500 mb-0">
                  {`The OTP has been sent to +${authDetails?.phoneCode} ${number}`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default VerifyOtp;
