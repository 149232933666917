import React, { useEffect, useState } from "react";
import { GoCheck } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import { productSteps } from "../../utils/Constants";

const setValue = (status) => {
  switch (status) {
    case 0:
      return 1;
    case 1:
      return 2;
    case 2:
      return 3;
    case 3:
      return 4;
    case 4:
      return 5;
    default:
      return -1;
  }
};

const ProgressStep = ({ status, statusTimeStamps }) => {
  const activeStep = setValue(status);

  const [array, setArray] = useState([]);

  useEffect(() => {
    let array = [];
    if (status === 4) {
      // array.push("pending");
      // array.push("cancelled");

      // setArray(array);
      array.push("pending");
      statusTimeStamps &&
        statusTimeStamps?.map((itm, i) => {
          if (itm !== null) {
            array.push(productSteps[i]);
          }
        });
      setArray(array);
    } else {
      array = productSteps.slice(0, 4);
      setArray(array);
    }
  }, []);

  return (
    <>
      <div className="progressbar-container container ms-0 ps-0 py-5">
        <div className="row">
          <div className="progressBar col-12 col-lg-7 d-flex">
            {array?.map((item, i) => {
              return (
                <div className="StepContainer d-flex" key={item}>
                  <div className="step">
                    {activeStep > i ? (
                      <div className="font16x500 progressCircle">
                        {item === "cancelled" ? <IoMdClose /> : <GoCheck />}
                      </div>
                    ) : activeStep === i ? (
                      <div className="font16x500 progressCircle">{i + 1}</div>
                    ) : (
                      <div
                        className="font16x500 progressCircle"
                        style={{ backgroundColor: "var(--border_primary)" }}
                      >
                        {i + 1}
                      </div>
                    )}

                    <p
                      className="font16x500 mt-2 mb-0 text-capitalize"
                      style={{
                        color: `${activeStep < i ? "var(--text4)" : "#000"}`,
                      }}
                    >
                      {item}
                    </p>
                  </div>
                  <div
                    className="line"
                    style={{
                      // maxWidth: "200px",
                      width: "110px",
                      height: "1px",
                      backgroundColor: "var(--text4)",
                      marginTop: 15,
                    }}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgressStep;
