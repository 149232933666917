import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import useFetch from "../../hooks/useFetch";
import useLoading from "../../hooks/useLoading";
import {
  authHeader,
  CURRENT_LOGGEDIN_USER_ID,
  GET_CHILD_SALONS,
  SALON_BY_ID,
  USER_BY_ID,
} from "../../utils/Constants";
import salonLogo from "../../uploads/salon/logo1.jpg";

const HeaderAdmin = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const user = JSON.parse(localStorage.getItem("user"));
  const [loader, showLoader, hideLoader] = useLoading();

  const functions = {
    logOut: () => {
      window.localStorage.clear();
      window.location.href = "/";
    },
    loginAsChildSalon: async (id) => {
      if (token && id) {
        try {
          showLoader();
          const res = await axios.get(`${SALON_BY_ID}${id}`, {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });

          if (res.status === 200) {
            window.localStorage.setItem(
              "salon_details",
              JSON.stringify(res?.data?.salon)
            );
            hideLoader();
            window.location.reload();
          } else {
            console.log("error", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            hideLoader();
            toast.error(error.response.data.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
    getCurrentUserId: async () => {
      if (token) {
        try {
          showLoader();
          const res = await axios.get(`${CURRENT_LOGGEDIN_USER_ID}`, {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });

          if (res.status === 200) {
            functions.getAdminDetails(res?.data?.id);
          } else {
            console.log("error", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            toast.error(error.response.data.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
    getAdminDetails: async (id) => {
      if (token && id) {
        try {
          showLoader();
          const res = await axios.get(`${USER_BY_ID}${id}`, {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });
          if (res.status === 200) {
            window.localStorage.setItem(
              "user",
              JSON.stringify(res?.data?.user)
            );
          } else {
            console.log("error", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            toast.error(error.response.data.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
  };

  const salonDetails = JSON.parse(localStorage.getItem("salon_details"));
  const { pathname } = useLocation();

  const [childSalons, totalResult, error, loader2, getData] = useFetch({
    url: `${GET_CHILD_SALONS}${
      salonDetails?.masterSalon ? salonDetails?.masterSalon : salonDetails?._id
    }/childSalon`,
    resultField: "childSalons",
  });

  useEffect(() => {
    // console.log(pathname);
    // console.log(pathname.split("/")[1]);
    if (pathname.split("/")[1] === "salon") {
      getData();
    }
  }, []);

  useEffect(() => {
    functions.getCurrentUserId();
  }, []);

  return (
    <>
      <div className="dashboardNavbarWrapperAdmin">
        <div className="container-fluid headerDetails">
          <div
            className={
              pathname.split("/")[1] === "salon"
                ? "menuWrapper d-flex align-items-center justify-content-between"
                : "menuWrapper d-flex align-items-center justify-content-end"
            }
          >
            {pathname.split("/")[1] === "salon" && (
              <div className="d-flex align-items-center">
                {salonDetails?.logo === null ? (
                  <img
                    src={salonLogo}
                    alt="salon"
                    className="me-3"
                    width={36}
                    height={36}
                    style={{ borderRadius: "4px" }}
                  />
                ) : (
                  <img
                    src={`${process.env.REACT_APP_Image_BaseURL}${salonDetails?.logo}`}
                    alt="salon"
                    className="me-3"
                    width={36}
                    height={36}
                    style={{ borderRadius: "4px" }}
                  />
                )}

                <p className="mb-0 font16x400">{`${salonDetails?.name}, ${salonDetails?.address?.city}`}</p>

                {childSalons?.length !== 0 && (
                  <div className="childSalonsDropdown">
                    <img
                      src="/Assets/angleDown.svg"
                      alt="salon"
                      width={25}
                      height={25}
                      className="ms-1"
                    />

                    <div className="hoverChildSalons">
                      <ul className="list-unstyled m-0">
                        {salonDetails?.masterSalon && (
                          <li
                            className="childSalonList"
                            onClick={() => {
                              functions.loginAsChildSalon(
                                salonDetails?.masterSalon
                              );
                            }}
                          >
                            Return to Master
                          </li>
                        )}

                        {childSalons &&
                          childSalons?.map((item) => {
                            return (
                              <li
                                key={item?._id}
                                className="childSalonList"
                                onClick={() => {
                                  functions.loginAsChildSalon(item?._id);
                                }}
                              >{`${item?.salonId}_${item?.name}`}</li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="menuContainer d-flex">
              <div className="">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <p className="font16x500 mb-0 me-1">
                    {`${user?.name ?? ""} Admin`}
                  </p>
                  <img
                    src="/Assets/angleDown.svg"
                    alt="salon"
                    className="me-2 img-fluid"
                  />
                </div>
              </div>
              <div className="hoverMenu">
                <ul className="list-unstyled m-0">
                  <li>
                    <Link to="" onClick={functions.logOut}>
                      Logout
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {salonDetails?.wallet < 0 && (
          <div className="balanceError">
            Your wallet balance is low please contact the admin to recharge.
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderAdmin;
