import React, { useEffect } from "react";
import { GoogleApiWrapper } from "google-maps-react";

const CustomMap = ({ handleLocation, latitude, longitude, google }) => {
  const renderMap2 = () => {
    const maps = google.maps;

    let map = new maps.Map(window.document.getElementById("map2"), {
      center: {
        lat: Number(latitude),
        lng: Number(longitude),
      },
      zoom: 13,
      disableDefaultUI: true,
      gestureHandling: "cooperative",
    });

    let marker = new google.maps.Marker({
      map: map,
      position: { lat: Number(latitude), lng: Number(longitude) },
    });

    map.addListener("click", (clickEvent) => {
      window.localStorage.setItem("latitude", clickEvent.latLng.lat());
      window.localStorage.setItem("longitude", clickEvent.latLng.lng());
      handleLocation(clickEvent.latLng.lat(), clickEvent.latLng.lng());

      marker.setPosition({
        lat: clickEvent.latLng.lat(),
        lng: clickEvent.latLng.lng(),
      });
      map.setZoom(15);
      map.panTo({
        lat: clickEvent.latLng.lat(),
        lng: clickEvent.latLng.lng(),
      });
    });
  };

  useEffect(() => {
    renderMap2();
  }, [latitude, longitude]);

  return <div className="map2" id="map2"></div>;
};

export default GoogleApiWrapper({
  apiKey: `${process.env.REACT_APP_API_MAP}`,
})(CustomMap);
