import React, { useEffect, useState } from "react";
import Layout from "../../../../../Components/Layout";
import useFetch from "../../../../../hooks/useFetch";
import { ORDER_SEARCH, productSteps } from "../../../../../utils/Constants";
import { useNavigate } from "react-router-dom";
import { getAddedOnDate } from "../../../../../Functions/dateTime";
import Pagination from "../../../../../Components/Pagination";
import DropdownSmall from "../../../../../Shared Components/DropdownSmall";
import InputDate from "../../../../../Shared Components/InputDate";
import SearchInput2 from "../../../../../Shared Components/SearchInput2";
import moment from "moment";
import PageNav from "../../../../../Components/PageNav";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../../../Components/Loader";
import { ordersPageLinks } from "../Product";
import MobileSalonDashboardSideNavHeader from "../../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileSalonDashboardHeader from "../../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../../Components/mobilesalondashboardsidenav";
import MobileFilterHeader from "../../../../../Components/mobilefilterheader";
import MobileAllProductOrderFilters from "../../../../../Components/Mobile/Admin/mobileAllProductOrderFilter";
import GenerateReportModal from "../../../../../Components/Generate Report";
import SearchInput from "../../../../../Shared Components/SearchInput";
import WhatsappBtn from "../../../../../Shared Components/WhatsappBtn";
import ViewDataBtn from "../../../../../Shared Components/ViewDataBtn";

const SalonQRProductOrder = () => {
  const salonNew = JSON.parse(localStorage.getItem("salon_details"));
  const token = JSON.parse(localStorage.getItem("token"));
  const [isOpen, setIsOpen] = useState(false);
  const [param, setParam] = useState({});
  console.log("param", param);

  const [paymentMode, setPaymentMode] = useState("");
  // console.log("paymentMode", paymentMode);

  const navigate = useNavigate();
  const functions = {
    viewProductOrderDetails: (order) => {
      navigate(`/salon/qr-order/product/${order?._id}`);
    },
    addParam: () => {
      if (param.fromDate && param.tillDate) {
        const checkDate = moment(param.tillDate, "YYYY-MM-DD").isAfter(
          moment(param.fromDate, "YYYY-MM-DD")
        );
        if (checkDate) {
          setFilter({
            ...filters,
            ...param,
          });
        } else {
          toast.error("From date must be less than till date.", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    },
    resetParam: () => {
      setParam({});
      delete filters.q;
      delete filters["user.mobile"];
      delete filters.fromDate;
      delete filters.tillDate;
      delete filters["productOrders.productsProcessingStatus"];
      delete filters["productOrders.productsProcessingStatusName"];
      getData();
    },
    createNewOrder: (link) => {
      console.log("link", link);
      window.open(link);
    },
    onOpenModal: () => {
      setIsOpen(true);
      document.body.style.overflow = "hidden";
    },
  };

  const [filters, setFilter] = useState({
    "salon._id": salonNew?._id && salonNew?._id,
    productOrders: "notNull",
    page: 1,
    per_page: 10,
    qrCode: true,
  });
  console.log("filters", filters);

  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [orders, totalResult, error, loader, getData] = useFetch({
    url: `${ORDER_SEARCH}`,
    resultField: "orders",
    parameter: filters,
  });

  useEffect(() => {
    getData();
  }, [filters]);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="QR Product Orders"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileAllProductOrderFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          param={param}
          setParam={setParam}
          addParam={functions.addParam}
          resetParam={functions.resetParam}
          filters={filters}
          setFilter={setFilter}
          qr={true}
          createNewOrder={() => {
            functions.createNewOrder(
              `${
                process.env.REACT_APP_NODE_ENV === "development"
                  ? process.env.REACT_APP_TEST_QR_LINK
                  : process.env.REACT_APP_QR_LINK
              }${salonNew?.name?.toLowerCase()?.split(" ").join("-")}-${
                salonNew?._id
              }/portal?id=${token}`
            );
          }}
          generateReport={() => {
            functions.onOpenModal();
          }}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="QR Product Orders"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="QR Product Orders"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper">
        <div className="service-navigation-neworder-button-container">
          <div className="service-navigation-neworder-button d-flex justify-content-between pe-2">
            <PageNav links={ordersPageLinks} />

            <div className="desktopButton">
              <div className="newOrderBtn d-flex gap-3">
                <button
                  className="btn addBtn shadow-none"
                  onClick={() => {
                    functions.createNewOrder(
                      `${
                        process.env.REACT_APP_NODE_ENV === "development"
                          ? process.env.REACT_APP_TEST_QR_LINK
                          : process.env.REACT_APP_QR_LINK
                      }${salonNew?.name?.toLowerCase()?.split(" ").join("-")}-${
                        salonNew?._id
                      }/portal?id=${token}`
                    );
                  }}
                >
                  Create New Order
                </button>
                <button
                  className="btn addBtn shadow-none"
                  onClick={() => {
                    functions.onOpenModal();
                  }}
                >
                  Generate Report
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="filter-container container mt-4 ms-0 ps-0 mb-3">
          <div className="d-flex justify-content-between align-items-end">
            {/* search */}
            {/* <SearchInput2
              type="text"
              placeholder="Search by Order Id, user name"
              stateData={param}
              setStateData={setParam}
              parameter1="orderId"
              parameter2="user.name"
              width={190}
              addParam={functions.addParam}
              resetParam={functions.resetParam}
            /> */}
            <SearchInput
              type="text"
              placeholder="Search by user mobile, user name"
              stateData={param}
              setStateData={setParam}
              parameter1="user.name"
              parameter2="user.mobile"
              width={190}
              addParam={functions.addParam}
              resetParam={functions.resetParam}
            />

            {/* Payment Mode */}
            <DropdownSmall
              data={[
                { value: "COD", label: "COD" },
                { value: "PayU", label: "PayU" },
              ]}
              label="Payment Mode"
              htmlFor="order"
              type="text"
              placeholder="All Orders"
              stateData={paymentMode}
              setStateData={(e) => {
                setPaymentMode(e);
              }}
            />

            {/* Order Status */}
            <DropdownSmall
              data={[
                { value: "Pending", label: "Pending" },
                { value: "Confirmed", label: "Confirmed" },
                { value: "Shipped", label: "Shipped" },
                { value: "Delivered", label: "Delivered" },
                { value: "Cancelled", label: "Cancelled" },
              ]}
              label="Order Status"
              htmlFor="Sort"
              type="text"
              placeholder="Sort By"
              stateData={filters["productOrders.productsProcessingStatusName"]}
              setStateData={(e) => {
                setFilter({
                  ...filters,
                  "productOrders.productsProcessingStatusName": e,
                  "productOrders.productsProcessingStatus":
                    e === "Pending"
                      ? "0"
                      : e === "Confirmed"
                      ? "1"
                      : e === "Shipped"
                      ? "2"
                      : e === "Delivered"
                      ? "3"
                      : "4",
                });
              }}
            />

            {/* date from */}
            <InputDate
              label="From"
              htmlFor="from"
              type="date"
              stateData={param?.fromDate}
              setStateData={(e) => {
                setParam({
                  ...param,
                  fromDate: moment(e).format("YYYY-MM-DD"),
                });
              }}
            />

            {/* date to */}
            <InputDate
              label="To"
              htmlFor="to"
              type="date"
              stateData={param?.tillDate}
              setStateData={(e) => {
                setParam({
                  ...param,
                  tillDate: moment(e).format("YYYY-MM-DD"),
                });
              }}
            />

            <button
              className="btn filterBtn shadow-none"
              onClick={functions.addParam}
            >
              Apply
            </button>
            <button
              className="btn resetBtn shadow-none"
              onClick={functions.resetParam}
            >
              Reset
            </button>
          </div>
        </div>

        {orders?.length !== 0 ? (
          <>
            <div className="container desktop dataTable ms-0 ps-0">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Order Id</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Customer No</th>
                    <th scope="col">Order Date</th>
                    <th scope="col">Total Amount</th>
                    <th scope="col">Payment Mode</th>
                    <th scope="col">Order Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {orders?.map((order, i) => {
                    return (
                      <tr key={i}>
                        <td>{order?.productOrders?.orderId}</td>
                        <td>{order?.user?.name}</td>
                        <td>{order?.user?.mobile}</td>
                        <td>{getAddedOnDate(order?.orderDate)}</td>
                        {order?.salon?.country?.htmlSymbol !== "" ? (
                          <td>{`${String.fromCharCode(
                            order?.salon?.country?.htmlSymbol
                          )} ${order?.cartCalculations?.products?.grandTotal?.toFixed(
                            2
                          )}`}</td>
                        ) : (
                          <td>{`${
                            order?.salon?.country?.currency
                          } ${order?.cartCalculations?.products?.grandTotal?.toFixed(
                            2
                          )}`}</td>
                        )}
                        <td>{order?.productOrders?.paymentMode}</td>
                        <td className="text-capitalize">
                          {
                            productSteps[
                              Number(
                                order?.productOrders?.productsProcessingStatus
                              )
                            ]
                          }
                        </td>
                        <td>
                          <div className="d-flex gap-3">
                            <WhatsappBtn
                              customerMobile={order?.user?.mobile}
                              orderId={order?._id}
                              salonMobile={order?.salon?.contactNumber}
                              serviceType="product"
                            />

                            <button
                              type="button"
                              className="btn font14x400 filterBtn shadow-none me-2"
                              onClick={() =>
                                functions.viewProductOrderDetails(order)
                              }
                              disabled={
                                order?.transactions[
                                  order?.transactions?.length - 1
                                ]?.paymentStatus === "Success"
                                  ? false
                                  : true
                              }
                            >
                              View
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="container mobile dataTable ms-0 ps-0">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Action</th>
                    <th scope="col">Customer Details</th>
                    <th scope="col">Whatsapp</th>
                    <th scope="col">Order Date</th>
                    <th scope="col">Order Id</th>
                    <th scope="col">Total Amount</th>
                    <th scope="col">Payment Mode</th>
                    <th scope="col">Order Status</th>
                  </tr>
                </thead>
                <tbody>
                  {orders?.map((order, i) => {
                    return (
                      <tr
                        key={i}
                        // onClick={() => functions.viewProductOrderDetails(order)}
                        // style={{
                        //   pointerEvents:
                        //     order?.transactions[order?.transactions?.length - 1]
                        //       ?.paymentStatus === "Success"
                        //       ? "unset"
                        //       : "none",
                        //   color:
                        //     order?.transactions[order?.transactions?.length - 1]
                        //       ?.paymentStatus !== "Success" && "grey",
                        // }}
                      >
                        <td>
                          <ViewDataBtn
                            handleClick={() =>
                              functions.viewProductOrderDetails(order)
                            }
                            isDisabled={
                              order?.transactions[
                                order?.transactions?.length - 1
                              ]?.paymentStatus === "Success"
                                ? false
                                : true
                            }
                          />
                        </td>
                        <td>
                          {order?.user?.name} <br />
                          {order?.user?.mobile}
                        </td>
                        <td>
                          <WhatsappBtn
                            customerMobile={order?.user?.mobile}
                            orderId={order?._id}
                            salonMobile={order?.salon?.contactNumber}
                            serviceType="product"
                          />
                        </td>
                        <td>{getAddedOnDate(order?.orderDate)}</td>
                        <td>{order?.productOrders?.orderId}</td>
                        {order?.salon?.country?.htmlSymbol !== "" ? (
                          <td>{`${String.fromCharCode(
                            order?.salon?.country?.htmlSymbol
                          )} ${order?.cartCalculations?.products?.grandTotal?.toFixed(
                            2
                          )}`}</td>
                        ) : (
                          <td>{`${
                            order?.salon?.country?.currency
                          } ${order?.cartCalculations?.products?.grandTotal?.toFixed(
                            2
                          )}`}</td>
                        )}
                        <td>{order?.productOrders?.paymentMode}</td>
                        <td className="text-capitalize">
                          {
                            productSteps[
                              Number(
                                order?.productOrders?.productsProcessingStatus
                              )
                            ]
                          }
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Product Orders Found"
            )}
          </div>
        )}

        {orders?.length !== 0 && (
          <div className="pagination-container d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      {isOpen && (
        <GenerateReportModal
          state={isOpen}
          setState={setIsOpen}
          salonName={salonNew?.name?.split(" ").join("_")}
          saveFileName="QR_Product_Orders"
          filters={filters}
          setFilter={setFilter}
          getData={getData}
          url={ORDER_SEARCH}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default SalonQRProductOrder;
