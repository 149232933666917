import moment from "moment";

export const getAddedOnDateTime = (value) => {
  // //convert string value to date
  // const addDate = new Date(value).toLocaleString();

  // //splitting date and time
  // const timeDateArr = addDate.split(",");

  // //formatted date acc. to UI
  // const formattedDate = moment(timeDateArr[0]).format("DD/MM/YYYY");

  // //formatted time acc. to UI
  // const formattedTime = timeDateArr[1]?.split(":");

  // return `${formattedTime[0]}:${formattedTime[1]}, ${formattedDate}`;

  const formattedDateTime = moment(value).format("HH:mm, DD-MMM-YYYY");
  return formattedDateTime;
};

export const getAddedOnDate = (value) => {
  // //convert string value to date
  // const addDate = new Date(value).toLocaleString();

  // //splitting date and time
  // const DateArr = addDate.split(",");

  // //formatted date acc. to UI
  // const formattedDate = moment(DateArr[0]).format("DD/MM/YYYY");

  // return `${formattedDate}`;

  const formattedDate = moment(value).format("DD-MMM-YYYY");
  return formattedDate;
};

export const getDateTime = (d, t, totalTime) => {
  const addDate = d.split("T")[0];

  const formattedDate = moment(addDate, "YYYY-MM-DD").format("YYYY-MM-DD");

  const [time, modifier] = t.split(" ");
  let min = "00";
  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "PM") {
    if (Math.floor(totalTime % 60) === 0) {
      hours = parseInt(hours, 10) + 12 + Math.floor(totalTime / 60);
    } else {
      if (Math.floor(totalTime / 60) !== 0) {
        hours = parseInt(hours, 10) + 12 + Math.floor(totalTime / 60);
        minutes = parseInt(totalTime % 60);
        min = minutes <= 9 ? "0" + minutes : minutes;
      } else {
        hours = parseInt(hours, 10) + 12 + 1;
      }
    }
  }
  if (modifier === "AM") {
    if (Math.floor(totalTime % 60) === 0) {
      hours = parseInt(hours, 10) + Math.floor(totalTime / 60);
    } else {
      if (Math.floor(totalTime / 60) !== 0) {
        hours = parseInt(hours, 10) + Math.floor(totalTime / 60);
        minutes = parseInt(totalTime % 60);
        min = minutes <= 9 ? "0" + minutes : minutes;
      } else {
        hours = parseInt(hours, 10) + 1;
      }
    }
  }

  const final = `${formattedDate}T${
    hours <= 9 ? "0" + hours : hours
  }:${min}:00+00:00`;

  return final;
};
