import React from "react";

const SelectCountry = ({ countries, formik, setIsOpen }) => {
  const setData = (acronym, phoneCode, countryId) => {
    formik.setFieldValue("isoCode", acronym);
    formik.setFieldValue("inputCountry", acronym);
    formik.setFieldValue("phoneCode", phoneCode);
    formik.setFieldValue("country", countryId);
    setIsOpen(false);
  };

  // const getFlagEmoji = (countryCode) => {
  //   const codePoints = countryCode
  //     .toUpperCase()
  //     .split("")
  //     .map((char) => 127397 + char.charCodeAt());
  //   return String.fromCodePoint(...codePoints);
  // };

  return (
    <>
      <div className="select_country_section">
        {countries?.map((item, i) => {
          return (
            <div
              className="countries"
              key={i}
              onClick={() => {
                setData(item?.acronym, item?.phoneCode, item?._id);
              }}
              style={{
                backgroundColor:
                  formik.values.isoCode === item?.acronym && "var(--bg3)",
              }}
            >
              <p className="mb-0 font14x400">{`${item?.acronym} ${item?.name} +${item?.phoneCode}`}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SelectCountry;
