import { useEffect, useState } from "react";
import FilterRadioButton from "../../../../Shared Components/FilterRadioButton";
import SearchInput2 from "../../../../Shared Components/SearchInput2";
import { activeInstatus, conditionType } from "../../../../utils/Constants";

function MobileCouponFilters(props) {
  const { onClick, resetParam, filters, setFilter, setParam, param, addParam } =
    props;
  const [animateFilter, setAnimateFilter] = useState({
    transform: "translate(100%)",
  });

  useEffect(() => {
    if (props.isOpenFilter) {
      setAnimateFilter({
        transform: "translate(0%)",
      });
    } else {
      setAnimateFilter({
        transform: "translate(100%)",
      });
    }
  }, [props.isOpenFilter]);

  return (
    <div style={animateFilter} className="mobile-bydate-filters-container">
      <div
        onClick={() => {
          props.setIsOpenFilter(false);
        }}
        className="left-filter-section"
      ></div>
      <div className="right-filter-section">
        <div className="right-filter-sub-container pt-3 pb-3">
          <div className="mt-3" onClick={onClick}>
            <button className="apply">Add New</button>
          </div>
          <p className="title">Filter</p>

          <div className="mb-4">
            <SearchInput2
              type="text"
              placeholder="Search by title"
              stateData={param}
              setStateData={setParam}
              parameter1="title"
              parameter2={undefined}
              width="auto"
              addParam={addParam}
              resetParam={resetParam}
            />
          </div>

          <div>
            <button className="bydate-filter-button d-flex justify-content-between align-items-center bg-white border-0 w-100">
              <p className="mb-0 font16x500">Status</p>
              <p className="mb-0 font16x500">-</p>
            </button>

            <div className="right-date-filter-container">
              {/* radio buttons */}
              {activeInstatus?.map((item, i) => {
                return (
                  <FilterRadioButton
                    key={i}
                    label={item?.label}
                    htmlFor={item?.label}
                    name="status"
                    stateData={item?.value}
                    setStateData={(e) => {
                      setFilter({
                        ...filters,
                        status: e,
                      });
                    }}
                  />
                );
              })}
            </div>
          </div>

          <div>
            <button className="bydate-filter-button d-flex justify-content-between align-items-center bg-white border-0 w-100">
              <p className="mb-0 font16x500">Condition Type</p>
              <p className="mb-0 font16x500">-</p>
            </button>

            <div className="right-date-filter-container">
              {/* radio buttons */}
              {conditionType?.map((item, i) => {
                return (
                  <FilterRadioButton
                    key={i}
                    label={item?.label}
                    htmlFor={item?.label}
                    name="status"
                    stateData={item?.value}
                    setStateData={(e) => {
                      setFilter({
                        ...filters,
                        conditionType: e,
                      });
                    }}
                  />
                );
              })}
            </div>
          </div>

          <div>
            <button className="reset" onClick={resetParam}>
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileCouponFilters;
