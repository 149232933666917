import { useNavigate } from "react-router-dom";

function ViewOrderHeader() {
  const navigate = useNavigate();

  const handleClickOnBack = () => {
    navigate(-1);
  };

  return (
    <div className="mobile order-details">
      <div className="view-order-header d-grid">
        <button
          onClick={() => {
            handleClickOnBack();
          }}
        >
          <img src="/Assets/back.svg" />
        </button>

        <p className="mb-0 text-center font16x400">Order Detail</p>
      </div>
    </div>
  );
}

export default ViewOrderHeader;
