import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBsuVTTTxvtweWDRqDLjaEDirGGqX1nGeQ",
  authDomain: "groome-push-notifications.firebaseapp.com",
  projectId: "groome-push-notifications",
  storageBucket: "groome-push-notifications.appspot.com",
  messagingSenderId: "819884967194",
  appId: "1:819884967194:web:407083fe7d503bbcc518a3",
  measurementId: "G-3KGRP3QKSM",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const PushNotificationToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      "BD27l4LG2yFt5MGSrIoVy6zr-mKeNnApLxKO7S3K_dYlWjxBRW7DAxPZ3yRHZFMTk_JJ1uwx-aCtnX4KGwMWuto",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        setTokenFound(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound("");
        // shows on the UI thfalseat permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
