import { useEffect, useState } from "react";
import FilterRadioButton from "../../../../Shared Components/FilterRadioButton";
import SearchInput from "../../../../Shared Components/SearchInput";
import { activeInstatus } from "../../../../utils/Constants";

function MobileUserFilters(props) {
  const {
    addWallet,
    addUser,
    sendNotification,
    resetParam,
    filters,
    setFilter,
    addParam,
    setParam,
    param,
  } = props;

  const [animateFilter, setAnimateFilter] = useState({
    transform: "translate(100%)",
  });

  useEffect(() => {
    if (props.isOpenFilter) {
      setAnimateFilter({
        transform: "translate(0%)",
      });
    } else {
      setAnimateFilter({
        transform: "translate(100%)",
      });
    }
  }, [props.isOpenFilter]);

  return (
    <div style={animateFilter} className="mobile-bydate-filters-container">
      <div
        onClick={() => {
          props.setIsOpenFilter(false);
        }}
        className="left-filter-section"
      ></div>
      <div className="right-filter-section">
        <div className="right-filter-sub-container pt-3 pb-3">
          <div>
            <button className="apply" onClick={addWallet}>
              Wallet Colab
            </button>
            <button className="apply" onClick={sendNotification}>
              Send Notification
            </button>
            <button className="apply" onClick={addUser}>
              Add New User
            </button>
          </div>

          <div className="mt-4">
            <p className="title">Filter</p>

            <div className="mb-4">
              <SearchInput
                type="text"
                placeholder="Search by name/mobile"
                stateData={param}
                setStateData={setParam}
                parameter1="name"
                parameter2="mobile"
                width="auto"
                addParam={addParam}
                resetParam={resetParam}
              />
            </div>

            <div>
              <button className="bydate-filter-button d-flex justify-content-between align-items-center bg-white border-0 w-100">
                <p className="mb-0 font16x500">Status</p>
                <p className="mb-0 font16x500">-</p>
              </button>

              <div className="right-date-filter-container">
                {/* radio buttons */}
                {activeInstatus?.map((item, i) => {
                  return (
                    <FilterRadioButton
                      key={i}
                      label={item?.label}
                      htmlFor={item?.label}
                      name="status"
                      stateData={item?.value}
                      setStateData={(e) => {
                        setFilter({
                          ...filters,
                          status: e,
                        });
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>

          <div>
            <button className="reset" onClick={resetParam}>
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileUserFilters;
