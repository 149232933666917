import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../../Components/Layout";
import useFetch from "../../../../hooks/useFetch";
import { ADMIN_ORDER_BY_ID, productSteps } from "../../../../utils/Constants";
import { ToastContainer } from "react-toastify";
import UpdationDetails from "../../../../Shared Components/UpdationDetails";
import Loader from "../../../../Components/Loader";
import Summary from "../../../../Components/Admin/AdminOrder/Summary";
import ProgressStep from "../../../../Components/Admin/AdminOrder/ProgressStep";
import ViewOrderHeader from "../../../../Components/vieworderheader";

const ViewAdminOrderProduct = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [adminOrder, totalResult, error, loader, getData] = useFetch({
    url: `${ADMIN_ORDER_BY_ID}${params.id}`,
    resultField: "adminOrder",
  });

  useEffect(() => {
    if (params.id === "id") {
      navigate(-1);
    } else {
      window.localStorage.removeItem("salon_details");
      getData();
    }
  }, []);

  return (
    <>
      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <ViewOrderHeader />

      <div className="mobile">
        <div className="order_status_wrapper d-flex justify-content-between">
          <p className="mb-0 font16x500">
            Order#: <span>{adminOrder?.adminOrderId}</span>
          </p>
          <p className="mb-0 font16x500 text-capitalize">
            {productSteps[adminOrder?.orderProcessingStatus]}
          </p>
        </div>
      </div>

      <div className="mainWrapper view-order-page">
        {loader ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "70vh" }}
          >
            <Loader height="40" width="40" color="#772286" />
          </div>
        ) : (
          <>
            <div>
              <h3 className="font22x500 pageHeading mb-0 text-capitalize">
                {`Order Id: ${adminOrder?.adminOrderId} (${adminOrder?.salon?.name}, ${adminOrder?.billingAddress?.city})`}
              </h3>
            </div>
            <ProgressStep
              statusTimeStamps={adminOrder?.orderProcessingTimestamps}
              status={adminOrder?.orderProcessingStatus}
            />
            <Summary orderDetails={adminOrder} apiCall={getData} />
          </>
        )}

        {/* {adminOrder && <UpdationDetails data={adminOrder} />} */}
      </div>

      <ToastContainer />
    </>
  );
};

export default ViewAdminOrderProduct;
