import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import useAuthContext from "../../../hooks/useAuthContext";
import { authHeader, UPDATE_ORDER_STATUS } from "../../../utils/Constants";

const AssignStylistModal = (props) => {
  const { state, setState, apiCall, id, isStylistAssigned } = props;

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const { token } = useAuthContext();

  const changeStylist = async (e) => {
    e.preventDefault();

    if (token) {
      try {
        const res = await axios.put(
          `${UPDATE_ORDER_STATUS}${id}/service`,
          {
            stylist: state?.id,
          },
          {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );
        if (res.status === 200) {
          apiCall();
          setPositionToBody();
          toast.success("Stylist Assigned", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          console.log("else error: ", res.status);
        }
      } catch (error) {
        console.log("catch error: ", error);
        if (error?.response?.status === 502) {
          toast.error(error?.response?.data?.message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state.modal
            ? "deleteModalWrapper deleteModalWrapper-active"
            : "deleteModalWrapper"
        }
      >
        <div className="deleteModalDiv" onClick={setPositionToBody}></div>
        <div className="deleteModalContainer d-flex align-items-center flex-column justify-content-center">
          {isStylistAssigned ? (
            <p className="font16x500 mb-4">
              Are you sure you want to change the Assigned Stylist?
            </p>
          ) : (
            <p className="font16x500 mb-4">Press Yes for assign stylist</p>
          )}

          <div className="d-flex">
            <button
              className="btn filterBtn shadow-none me-3"
              onClick={setPositionToBody}
            >
              Cancel
            </button>
            <button
              className="btn filterBtn shadow-none"
              onClick={changeStylist}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignStylistModal;
