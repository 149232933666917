//for types of salon
export const salonType = ["male", "female", "unisex"];

//for state
// export const state = [];
// City.getCitiesOfCountry("IN").map((itm) => {
//   // return state.push(itm.name);
//   console.log("city", itm.name);
// });

const token = JSON.parse(localStorage.getItem("token"));

export const withoutAuthHeader = {
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};
export const authHeader = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + token,
  "X-Requested-With": "XMLHttpRequest",
};
export const withoutAuthFormHeader = {
  "Content-type": "multipart/form-data",
  "X-Requested-With": "XMLHttpRequest",
};
export const authFormHeader = {
  "Content-type": "multipart/form-data",
  Authorization: "Bearer " + token,
  "X-Requested-With": "XMLHttpRequest",
};

//weekdays
export const Day = [
  {
    sunday: {
      openingTime: "0:00",
      closingTime: "0:00",
      isChecked: false,
    },
    monday: {
      openingTime: "0:00",
      closingTime: "0:00",
      isChecked: false,
    },
    tuesday: {
      openingTime: "0:00",
      closingTime: "0:00",
      isChecked: false,
    },
    wednesday: {
      openingTime: "0:00",
      closingTime: "0:00",
      isChecked: false,
    },
    thursday: {
      openingTime: "0:00",
      closingTime: "0:00",
      isChecked: false,
    },
    friday: {
      openingTime: "0:00",
      closingTime: "0:00",
      isChecked: false,
    },
    saturday: {
      openingTime: "0:00",
      closingTime: "0:00",
      isChecked: false,
    },
  },
];

//roles
export const MASTER_SALON = "salon";
export const CHILD_SALON = "child-salon";
export const ADMIN = "admin";
export const ENDUSER = "end-user";
export const MASTER_SALON_USER = "salon-user";

//API URL's
// export const LOGIN = `${process.env.REACT_APP_API_URL}/auth/register`;
// export const VERIFY_OTP = `${process.env.REACT_APP_API_URL}/auth/verifyUserOtp`;
export const LOGIN = `${process.env.REACT_APP_API_URL}/auth/send-otp`;
export const VERIFY_OTP = `${process.env.REACT_APP_API_URL}/auth/verify-otp`;
export const LOGIN_AUTH = `${process.env.REACT_APP_API_URL}/auth/send-otp/authenticated`;
export const VERIFY_OTP_AUTH = `${process.env.REACT_APP_API_URL}/auth/validate-otp`;
export const SALON_REGISTRATION = `${process.env.REACT_APP_API_URL}/portal/salon/form`;
export const SALONS_LIST = `${process.env.REACT_APP_API_URL}/portal/salon/list`;
export const SALON_BY_ID = `${process.env.REACT_APP_API_URL}/portal/salon/`;
export const CURRENT_USER_SALON = `${process.env.REACT_APP_API_URL}/portal/salon/search?cus=anything`;
export const SERVICE_CATEGORY = `${process.env.REACT_APP_API_URL}/portal/serviceCategory/list`;
export const SERVICE_CATEGORY_UPDATE = `${process.env.REACT_APP_API_URL}/portal/serviceCategory/`;
export const CREATE_SERVICE_CATEGORY = `${process.env.REACT_APP_API_URL}/portal/serviceCategory`;
export const SERVICE_CATEGORY_SEARCH = `${process.env.REACT_APP_API_URL}/portal/serviceCategory/search`;
export const PRODUCT_CATEGORY = `${process.env.REACT_APP_API_URL}/portal/productCategory/list`;
export const PRODUCT_CATEGORY_UPDATE = `${process.env.REACT_APP_API_URL}/portal/productCategory/`;
export const CREATE_PRODUCT_CATEGORY = `${process.env.REACT_APP_API_URL}/portal/productCategory`;
export const PRODUCT_CATEGORY_SEARCH = `${process.env.REACT_APP_API_URL}/portal/productCategory/search`;
export const SALON_SEARCH = `${process.env.REACT_APP_API_URL}/portal/salon/search`;
export const SALON_DELETE = `${process.env.REACT_APP_API_URL}/portal/salon/`;
export const SALON_UPDATE = `${process.env.REACT_APP_API_URL}/portal/salon/`;
export const SALON_SERVICES = `${process.env.REACT_APP_API_URL}/portal/service/list?`;
export const SALON_SERVICES_SEARCH = `${process.env.REACT_APP_API_URL}/portal/service/search?`;
export const SALON_SERVICE_ADD = `${process.env.REACT_APP_API_URL}/portal/salon/`;
export const SALON_SERVICE_UPLOAD = `${process.env.REACT_APP_API_URL}/portal/salon/`;
export const SALON_SERVICES_UPDATE = `${process.env.REACT_APP_API_URL}/portal/service/`;
export const SALON_SERVICES_DELETE = `${process.env.REACT_APP_API_URL}/portal/service/`;
export const SALON_PRODUCTS = `${process.env.REACT_APP_API_URL}/portal/product/list?`;
export const SALON_PRODUCTS_SEARCH = `${process.env.REACT_APP_API_URL}/portal/product/search?`;
export const SALON_CATEGORY = `${process.env.REACT_APP_API_URL}/portal/salonCategory/list`;
export const SALON_CATEGORY_UPDATE = `${process.env.REACT_APP_API_URL}/portal/salonCategory/`;
export const CREATE_SALON_CATEGORY = `${process.env.REACT_APP_API_URL}/portal/salonCategory`;
export const SALON_CATEGORY_SEARCH = `${process.env.REACT_APP_API_URL}/portal/salonCategory/search`;
export const REVIEWS_RATING_ANALYTICS = `${process.env.REACT_APP_API_URL}/portal/review-and-rating/salon/`;
export const SALON_PRODUCT_ADD = `${process.env.REACT_APP_API_URL}/portal/product/form?`;
export const SALON_PRODUCTS_UPDATE = `${process.env.REACT_APP_API_URL}/portal/product/`;
export const SALON_PRODUCTS_DELETE = `${process.env.REACT_APP_API_URL}/portal/product/`;
export const USERS = `${process.env.REACT_APP_API_URL}/portal/user/list/?`;
export const USER_SEARCH = `${process.env.REACT_APP_API_URL}/portal/user/search?`;
export const USER_BY_ID = `${process.env.REACT_APP_API_URL}/portal/user/`;
export const USER_ADD = `${process.env.REACT_APP_API_URL}/portal/user/form?`;
export const USER_UPDATE = `${process.env.REACT_APP_API_URL}/portal/user/`;
export const USER_DELETE = `${process.env.REACT_APP_API_URL}/portal/user/`;
export const STYLISTS = `${process.env.REACT_APP_API_URL}/portal/stylist/list`;
export const STYLIST_SEARCH = `${process.env.REACT_APP_API_URL}/portal/stylist/search`;
export const STYLIST_ADD = `${process.env.REACT_APP_API_URL}/portal/salon/`;
export const STYLIST_UPDATE = `${process.env.REACT_APP_API_URL}/portal/stylist/`;
export const STYLIST_DELETE = `${process.env.REACT_APP_API_URL}/portal/stylist/`;
export const COUPONS = `${process.env.REACT_APP_API_URL}/portal/coupon/list`;
export const COUPON_SEARCH = `${process.env.REACT_APP_API_URL}/portal/coupon/search`;
export const COUPON_ADD = `${process.env.REACT_APP_API_URL}/portal/coupon`;
export const COUPON_UPDATE = `${process.env.REACT_APP_API_URL}/portal/coupon/`;
export const COUPON_DELETE = `${process.env.REACT_APP_API_URL}/portal/coupon/`;
export const BANNERS = `${process.env.REACT_APP_API_URL}/portal/banner/list`;
export const BANNER_SEARCH = `${process.env.REACT_APP_API_URL}/portal/banner/search`;
export const BANNER_ADD = `${process.env.REACT_APP_API_URL}/portal/banner/form`;
export const BANNER_UPDATE = `${process.env.REACT_APP_API_URL}/portal/banner/`;
export const BANNER_DELETE = `${process.env.REACT_APP_API_URL}/portal/banner/`;
export const BANNERS_TYPE = `${process.env.REACT_APP_API_URL}/portal/bannerType/list`;
export const BANNER_TYPE_SEARCH = `${process.env.REACT_APP_API_URL}/portal/bannerType/search`;
export const BANNER_TYPE_ADD = `${process.env.REACT_APP_API_URL}/portal/bannerType/`;
export const BANNER_TYPE_UPDATE = `${process.env.REACT_APP_API_URL}/portal/bannerType/`;
export const BANNER_TYPE_DELETE = `${process.env.REACT_APP_API_URL}/portal/bannerType/`;
export const OFFERS_DEALS = `${process.env.REACT_APP_API_URL}/portal/offerAndDeal/list`;
export const OFFER_DEAL_SEARCH = `${process.env.REACT_APP_API_URL}/portal/offerAndDeal/search`;
export const OFFER_DEAL_ADD = `${process.env.REACT_APP_API_URL}/portal/offerAndDeal/form`;
export const OFFER_DEAL_UPDATE = `${process.env.REACT_APP_API_URL}/portal/offerAndDeal/`;
export const OFFER_DEAL_DELETE = `${process.env.REACT_APP_API_URL}/portal/offerAndDeal/`;
export const OFFERS_DEALS_TYPE = `${process.env.REACT_APP_API_URL}/portal/offerAndDealCategory/list`;
export const OFFER_DEAL_TYPE_SEARCH = `${process.env.REACT_APP_API_URL}/portal/offerAndDealCategory/search`;
export const OFFER_DEAL_TYPE_ADD = `${process.env.REACT_APP_API_URL}/portal/offerAndDealCategory/`;
export const OFFER_DEAL_TYPE_UPDATE = `${process.env.REACT_APP_API_URL}/portal/offerAndDealCategory/`;
export const OFFER_DEAL_TYPE_DELETE = `${process.env.REACT_APP_API_URL}/portal/offerAndDealCategory/`;
export const SALON_OFFER = `${process.env.REACT_APP_API_URL}/portal/salon/`;
export const SALON_OFFER_CREATE = `${process.env.REACT_APP_API_URL}/portal/salon/`;
export const SALON_OFFER_SEARCH = `${process.env.REACT_APP_API_URL}/portal/salon/`;
export const SALON_OFFER_UPDATE = `${process.env.REACT_APP_API_URL}/portal/salon/offer/`;
export const SALON_OFFER_DELETE = `${process.env.REACT_APP_API_URL}/portal/salon/offer/`;
export const FAQS = `${process.env.REACT_APP_API_URL}/portal/faq/list`;
export const FAQ_SEARCH = `${process.env.REACT_APP_API_URL}/portal/faq/search`;
export const FAQ_ADD = `${process.env.REACT_APP_API_URL}/portal/faq/form`;
export const FAQ_UPDATE = `${process.env.REACT_APP_API_URL}/portal/faq/`;
export const FAQ_DELETE = `${process.env.REACT_APP_API_URL}/portal/faq/`;
export const PAYMENT_DETAIL = `${process.env.REACT_APP_API_URL}/portal/paymentDetail/list?`;
export const PAYMENT_DETAIL_SEARCH = `${process.env.REACT_APP_API_URL}/portal/paymentDetail/search?`;
export const PAYMENT_DETAIL_ADD = `${process.env.REACT_APP_API_URL}/portal/salon/`;
export const PAYMENT_DETAIL_UPDATE = `${process.env.REACT_APP_API_URL}/portal/paymentDetail/`;
export const PAYMENT_DETAIL_DELETE = `${process.env.REACT_APP_API_URL}/portal/paymentDetail/`;
export const SLOT_LIST_BY_SALONID = `${process.env.REACT_APP_API_URL}/portal/slotv2/getSlots/`;
export const BLOCK_SLOT_LIST_BY_SALONID = `${process.env.REACT_APP_API_URL}/portal/slotv2/search/`;
export const SLOT_DISABLE = `${process.env.REACT_APP_API_URL}/portal/slotv2/`;
export const ORDER_LIST = `${process.env.REACT_APP_API_URL}/portal/order/list`;
export const ORDER_SEARCH = `${process.env.REACT_APP_API_URL}/portal/order/search`;
export const UPDATE_ORDER_STATUS = `${process.env.REACT_APP_API_URL}/portal/order/`;
export const GET_ORDER_BY_ID = `${process.env.REACT_APP_API_URL}/portal/order/`;
export const TRANSACTION_LIST = `${process.env.REACT_APP_API_URL}/portal/transaction/list`;
export const TRANSACTION_SEARCH = `${process.env.REACT_APP_API_URL}/portal/transaction/search`;
export const TRANSACTION_UPDATE = `${process.env.REACT_APP_API_URL}/portal/dynamicPage/form`;
export const CREATE_DYNAMIC_PAGE = `${process.env.REACT_APP_API_URL}/portal/dynamicPage/form`;
export const DYNAMIC_PAGE_LIST = `${process.env.REACT_APP_API_URL}/portal/dynamicPage/list`;
export const DYNAMIC_PAGE_SEARCH = `${process.env.REACT_APP_API_URL}/portal/dynamicPage/search`;
export const DYNAMIC_PAGE_UPDATE = `${process.env.REACT_APP_API_URL}/portal/dynamicPage/`;
export const DYNAMIC_PAGE_DELETE = `${process.env.REACT_APP_API_URL}/portal/dynamicPage/`;
export const CART_LIST = `${process.env.REACT_APP_API_URL}/portal/cart`;
export const CART_ADD_ITEM = `${process.env.REACT_APP_API_URL}/portal/cart/item/add`;
export const CART_REMOVE_ITEM = `${process.env.REACT_APP_API_URL}/portal/cart/item/remove`;
export const CART_EMPTY = `${process.env.REACT_APP_API_URL}/portal/cart/empty`;
export const CREATE_SALON_COUPON = `${process.env.REACT_APP_API_URL}/portal/coupon/salon`;
export const SALON_COUPON_SEARCH = `${process.env.REACT_APP_API_URL}/portal/coupon/salon/search`;
export const SALON_COUPON_UPDATE = `${process.env.REACT_APP_API_URL}/portal/coupon/salon/`;
export const SALON_COUPON_DELETE = `${process.env.REACT_APP_API_URL}/portal/coupon/salon/`;
export const SALON_COUPON_LIST = `${process.env.REACT_APP_API_URL}/portal/coupon/salon/listOfAvailableCoupons`;
export const SALON_APPLY_COUPON = `${process.env.REACT_APP_API_URL}/portal/coupon/salon/applyCoupon`;
export const SALON_REMOVE_COUPON = `${process.env.REACT_APP_API_URL}/portal/coupon/salon/removeCoupon`;
export const LOGGED_IN_USER_ADDRESSES = `${process.env.REACT_APP_API_URL}/portal/userAddress/loggedInUser`;
export const CREATE_USER_ADDRESSES = `${process.env.REACT_APP_API_URL}/portal/userAddress`;
export const UPDATE_USER_ADDRESSES = `${process.env.REACT_APP_API_URL}/portal/userAddress/`;
export const DELETE_USER_ADDRESSES = `${process.env.REACT_APP_API_URL}/portal/userAddress/`;
export const CREATE_ADMIN_ORDER = `${process.env.REACT_APP_API_URL}/portal/order/admin`;
export const ADMIN_ORDER_SEARCH = `${process.env.REACT_APP_API_URL}/portal/order/admin/search`;
export const ADMIN_ORDER_UPDATE = `${process.env.REACT_APP_API_URL}/portal/order/admin/`;
export const ADMIN_ORDER_BY_ID = `${process.env.REACT_APP_API_URL}/portal/order/admin/`;
export const CREATE_ADMIN_TRANSACTION = `${process.env.REACT_APP_API_URL}/portal/transaction/admin`;
export const ADMIN_TRANSACTION_SEARCH = `${process.env.REACT_APP_API_URL}/portal/transaction/admin/search`;
export const ADMIN_TRANSACTION_UPDATE = `${process.env.REACT_APP_API_URL}/portal/transaction/admin/`;
export const STYLIST_ATTENDANCE_UPDATE = `${process.env.REACT_APP_API_URL}/portal/stylist/`;
export const STYLIST_ATTENDANCE_LIST = `${process.env.REACT_APP_API_URL}/portal/stylist/attendance/search`;
export const ADD_CHILD_SALON = `${process.env.REACT_APP_API_URL}/portal/salon/`;
export const VERIFY_CHILD_SALON = `${process.env.REACT_APP_API_URL}/portal/salon/`;
export const CREATE_CHILD_SALON = `${process.env.REACT_APP_API_URL}/portal/salon/`;
export const GET_CHILD_SALONS = `${process.env.REACT_APP_API_URL}/portal/salon/`;
export const CREATE_BRAND = `${process.env.REACT_APP_API_URL}/portal/brand/form`;
export const UPDATE_BRAND = `${process.env.REACT_APP_API_URL}/portal/brand/`;
export const BRAND_LIST = `${process.env.REACT_APP_API_URL}/portal/brand/search`;
export const INVENTORY_LIST = `${process.env.REACT_APP_API_URL}/portal/inventory/search`;
export const UPDATE_STOCK = `${process.env.REACT_APP_API_URL}/portal/inventory`;
export const CLIENTS_LIST = `${process.env.REACT_APP_API_URL}/portal/order/customers`;
export const CREATE_SERVICE_NAME = `${process.env.REACT_APP_API_URL}/portal/service/name/form`;
export const UPDATE_SERVICE_NAME = `${process.env.REACT_APP_API_URL}/portal/service/name/`;
export const SERVICE_NAME_LIST = `${process.env.REACT_APP_API_URL}/portal/service/name/search`;
export const ADD_REVIEW_ADMIN_ORDER = `${process.env.REACT_APP_API_URL}/portal/review-and-rating/admin-order/`;
export const SALON_DASHBOARD = `${process.env.REACT_APP_API_URL}/portal/dashboard/salon/`;
export const SALON_DASHBOARD_NEXT_SEVEN_DAYS = `${process.env.REACT_APP_API_URL}/portal/dashboard/salon/next-seven-days/`;
export const GET_RATING_AND_REVIEWS = `${process.env.REACT_APP_API_URL}/portal/review-and-rating/order/search`;
export const SEND_NOTIFICATION = `${process.env.REACT_APP_API_URL}/portal/notification/app/form`;
export const DEVICE_REGISTRATION = `${process.env.REACT_APP_API_URL}/portal/salon-device/`;
export const SALON_WALLET = `${process.env.REACT_APP_API_URL}/portal/wallet/salon/search`;
export const UPDATE_STOCK_ADMIN = `${process.env.REACT_APP_API_URL}/portal/inventory/admin`;
export const INVENTORY_LIST_AMDIN = `${process.env.REACT_APP_API_URL}/portal/inventory/admin/search`;
export const CURRENT_LOGGEDIN_USER_ID = `${process.env.REACT_APP_API_URL}/user/loggedInUser/getId`;
export const CREATE_MEMBERSHIP = `${process.env.REACT_APP_API_URL}/portal/membership/form`;
export const MEMBERSHIP_LIST = `${process.env.REACT_APP_API_URL}/portal/membership/search`;
export const MEMBERSHIP_UPDATE = `${process.env.REACT_APP_API_URL}/portal/membership/`;
export const MEMBERSHIP_DELETE = `${process.env.REACT_APP_API_URL}/portal/membership/`;
export const ADD_MANY_USER_WALLET = `${process.env.REACT_APP_API_URL}/portal/wallet/user/many`;
export const ADD_SINGLE_USER_WALLET = `${process.env.REACT_APP_API_URL}/portal/wallet/user/`;
export const USER_WALLET_HISTORY = `${process.env.REACT_APP_API_URL}/portal/wallet/user/search`;
export const MEMBERSHIP_ORDER_LIST = `${process.env.REACT_APP_API_URL}/portal/order/membership/search`;
export const MEMBERSHIP_TRANSACTION_LIST = `${process.env.REACT_APP_API_URL}/portal/transaction/membership/search`;
export const SYNC_SERVICES = `${process.env.REACT_APP_API_URL}/portal/salon/`;
export const CREATE_SALON_WALLET = `${process.env.REACT_APP_API_URL}/portal/wallet/salon/`;
export const TEMPORARY_USER_SEARCH = `${process.env.REACT_APP_API_URL}/portal/user/temporary/search?`;
export const DOWNLOAD_REPORT = `${process.env.REACT_APP_API_URL}/portal/download`;
export const UPDATE_LEAD = `${process.env.REACT_APP_API_URL}/portal/lead`;
export const LEAD_LIST = `${process.env.REACT_APP_API_URL}/portal/lead/search`;
export const LEAD_DELETE = `${process.env.REACT_APP_API_URL}/portal/lead/`;
export const CREATE_COUNTRY = `${process.env.REACT_APP_API_URL}/portal/country`;
export const UPDATE_COUNTRY = `${process.env.REACT_APP_API_URL}/portal/country`;
export const COUNTRY_LIST = `${process.env.REACT_APP_API_URL}/portal/country/search`;
export const COUNTRY_SEARCH = `${process.env.REACT_APP_API_URL}/country/search`;

//service atHome order steps
export const serviceSteps = [
  "pending",
  "confirmed",
  "in transit",
  "started",
  "completed",
  "cancelled",
];

//service atSalon order steps
export const serviceAtSalonSteps = [
  "pending",
  "confirmed",
  "started",
  "completed",
  "cancelled",
];

//product order steps
export const productSteps = [
  "pending",
  "confirmed",
  "shipped",
  "delivered",
  "cancelled",
];

//stylist attendance
export const attandanceBtns = [
  {
    attendance: "P",
    value: 2,
  },
  {
    attendance: "A",
    value: 0,
  },
  {
    attendance: "WO",
    value: 2,
  },
  {
    attendance: "HD",
    value: 1,
  },
];

//salon amenities
export const amenities = [
  {
    Ac: {
      label: "AC",
      isChecked: false,
    },
    CardPayment: { label: "Card Payment", isChecked: false },
    Toilets: { label: "Toilets", isChecked: false },
    ParkingAvailable: { label: "Parking Available", isChecked: false },
  },
];

//active-inactive status
export const activeInstatus = [
  {
    value: 1,
    label: "Active",
  },
  {
    value: 2,
    label: "Inactive",
  },
];

//stick status
export const productStock = [
  {
    value: true,
    label: "In Stock",
  },
  {
    value: false,
    label: "Out of stock",
  },
];

//product type
export const productType = [
  {
    value: "Retail",
    label: "Retail",
  },
  {
    value: "Salon",
    label: "Salon",
  },
];

//application status
export const salonApplicationStatus = [
  {
    value: 0,
    label: "Pending",
  },
  {
    value: 1,
    label: "Verified",
  },
  {
    value: 2,
    label: "Not verified",
  },
];

//coupon condition type
export const conditionType = [
  {
    value: "Percentage",
    label: "Percentage",
  },
  {
    value: "Discount",
    label: "Discount",
  },
];

//payment status type
export const paymentStatus = [
  {
    value: "Initiated",
    label: "Initiated",
  },
  {
    value: "Success",
    label: "Success",
  },
  {
    value: "Cancelled",
    label: "Cancelled",
  },
  {
    value: "Failed",
    label: "Failed",
  },
];

//payment mode type
export const paymentMode = [
  {
    value: "COD",
    label: "COD",
  },
  {
    value: "Online",
    label: "Online",
  },
];

//membership status
export const membershipStatus = [
  {
    value: 1,
    label: "Active",
  },
  {
    value: 2,
    label: "Expired",
  },
];

//product order status
export const productOrderStatus = [
  {
    value: 0,
    label: "Pending",
  },
  {
    value: 1,
    label: "Confirmed",
  },
  {
    value: 2,
    label: "Shipped",
  },
  {
    value: 3,
    label: "Delivered",
  },
  {
    value: 4,
    label: "Cancelled",
  },
];

//service order status
export const serviceOrderStatus = [
  {
    value: 0,
    label: "Pending",
  },
  {
    value: 1,
    label: "Confirmed",
  },
  {
    value: 2,
    label: "In Transit",
  },
  {
    value: 3,
    label: "Started",
  },
  {
    value: 4,
    label: "Completed",
  },
  {
    value: 5,
    label: "Cancelled",
  },
];

//service venue
export const serviceVenue = [
  {
    value: "At Salon",
    label: "At Salon",
  },
  {
    value: "At Home",
    label: "At Home",
  },
];

//salon live status
export const salonLivestatus = [
  {
    value: true,
    label: "Active",
  },
  {
    value: false,
    label: "Inactive",
  },
];

//banner status
export const bannerStatus = [
  {
    value: "Published",
    label: "Published",
  },
  {
    value: "Draft",
    label: "Draft",
  },
];

//banner status
export const leadStatus = [
  {
    value: 0,
    label: "Pending",
  },
  {
    value: 1,
    label: "Processing",
  },
  {
    value: 2,
    label: "Served",
  },
];
