import React, { useState } from "react";
import useAuthContext from "../../hooks/useAuthContext";
import useLoading from "../../hooks/useLoading";
import EditOrderDetails from "../Edit Order Details";
import { toast } from "react-toastify";
import axios from "axios";
import { SALON_UPDATE } from "../../utils/Constants";
import salonLogo from "../../uploads/salon/logo1.jpg";

const HeaderOrderAccepatance = ({ getData }) => {
  const [commonData, setCommonData] = useState({
    data: {},
    modal: false,
  });
  const salonDetails = JSON.parse(localStorage.getItem("salon_details"));
  const { token } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();

  const functions = {
    onEditOrderDetails: (item) => {
      setCommonData({
        data: item,
        modal: true,
      });
      document.body.style.overflow = "hidden";
    },
    updateToggle: async (e) => {
      const rFormData = new FormData();
      rFormData.append("isAcceptingOrder", !salonDetails?.isAcceptingOrder);
      if (token) {
        try {
          showLoader();
          const res = await axios.put(
            `${SALON_UPDATE}${salonDetails._id}/form`,
            rFormData,
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );
          console.log("res", res);
          if (res.status === 200) {
            toast.success("Changed Accepting Order", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
            getData();
            hideLoader();
          } else {
            console.log("error", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            hideLoader();
            toast.error(error.response.data.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
  };

  return (
    <>
      <div className="row mb-4 order-accepting-container">
        <div className="order-accepting-sub-container col-8 d-flex flex-column justify-content-between">
          {/* desktop-salon-details */}
          <div className="desktop-salon-details">
            <div className="salon-image-container d-flex align-items-center ps-4 pe-5">
              {salonDetails?.logo === null ? (
                <img src={salonLogo} alt="logo" width={70} height={70} />
              ) : (
                <img
                  src={`${process.env.REACT_APP_Image_BaseURL}${salonDetails?.logo}`}
                  alt="logo"
                  width={70}
                  height={70}
                  style={{ borderRadius: "4px" }}
                />
              )}
            </div>
            <div className="salonShortDetail ps-4 py-2 px-4 d-flex justify-content-start">
              <div className="">
                <p className="font16x500 mb-1">{salonDetails?.name}</p>
                <div className="d-flex align-items-center">
                  <p className="font14x400 mb-0">SALON</p>
                  <div className="d-flex align-items-center ms-5">
                    <span className="rating font14x500 d-flex align-items-center">
                      {salonDetails?.rating
                        ? salonDetails?.rating.toFixed(1)
                        : "0"}
                      <img
                        src="../../Assets/ratingStar.svg"
                        alt="rating star"
                        className="ms-1"
                      />
                    </span>
                    <span
                      className="font12x500 ms-2"
                      style={{ color: "var(--text1)" }}
                    >
                      {`${
                        salonDetails?.ratingStats?.numberOfRatings
                          ? salonDetails?.ratingStats?.numberOfRatings
                          : "0"
                      } Ratings`}
                    </span>
                  </div>
                </div>
              </div>
              <div className="verticalLine ms-4"></div>
              <div className="px-4">
                <p
                  className="font16x500 mb-1"
                  style={{ color: "var(--text4)" }}
                >
                  Store Type:{" "}
                  <span
                    style={{
                      color: "var(--text2)",
                      textTransform: "capitalize",
                    }}
                  >
                    {salonDetails.type}
                  </span>
                </p>
                <p
                  className="font16x500 mb-0"
                  style={{ color: "var(--text4)" }}
                >
                  Store Category:{" "}
                  {salonDetails?.category?.map((itm, i) => {
                    return (
                      <span key={i} className="categorySalon">
                        {itm?.name}
                      </span>
                    );
                  })}
                </p>
              </div>
            </div>
          </div>

          {/* mobile-salon-details */}

          <div className="mobile-salon-details align-items-center">
            <div className="d-flex align-items-center">
              {salonDetails?.logo === null ? (
                <img src={salonLogo} alt="logo" width={70} height={70} />
              ) : (
                <img
                  src={`${process.env.REACT_APP_Image_BaseURL}${salonDetails?.logo}`}
                  alt="logo"
                  width={70}
                  height={70}
                  style={{ borderRadius: "4px" }}
                />
              )}

              <div className="ms-3">
                <p className="font16x500 mb-1">{salonDetails?.name}</p>
                <div className="d-flex align-items-center">
                  <p className="font14x400 mb-0">SALON</p>
                  <div className="d-flex align-items-center ms-5">
                    <span className="rating font14x500 d-flex align-items-center">
                      {salonDetails?.rating
                        ? salonDetails?.rating.toFixed(1)
                        : "0"}
                      <img
                        src="../../Assets/ratingStar.svg"
                        alt="rating star"
                        className="ms-1"
                      />
                    </span>
                    <span
                      className="font12x500 ms-2"
                      style={{ color: "var(--text1)" }}
                    >
                      {`${
                        salonDetails?.ratingStats?.numberOfRatings
                          ? salonDetails?.ratingStats?.numberOfRatings
                          : "0"
                      } Ratings`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 store-type-category-containert">
              <p className="font16x500 mb-1" style={{ color: "var(--text4)" }}>
                Store Type:{" "}
                <span
                  style={{
                    color: "var(--text2)",
                    textTransform: "capitalize",
                  }}
                >
                  {salonDetails.type}
                </span>
              </p>
              <p className="font16x500 mb-0" style={{ color: "var(--text4)" }}>
                Store Category:{" "}
                {salonDetails?.category?.map((itm, i) => {
                  return (
                    <span key={i} className="categorySalon">
                      {itm?.name}
                    </span>
                  );
                })}
              </p>
            </div>
          </div>

          <div className="d-flex orderAcceptance p-3">
            <div className="col-6">
              <p className="font14x500 mb-1">Accepting Home Services</p>
              <p className="font14x400 mb-1" style={{ color: "var(--text3)" }}>
                People will be able to place orders at your Salon.
              </p>
            </div>
            <div className="col-6">
              <div className="text-end">
                <label className="switchBtn2">
                  <input
                    type="checkbox"
                    className="me-2"
                    defaultChecked={salonDetails?.isAcceptingOrder}
                    onClick={functions.updateToggle}
                  />
                  <span
                    className="openCloseCheck2"
                    style={{
                      backgroundColor: salonDetails?.isAcceptingOrder
                        ? "#11CC93"
                        : "#BCBCBC",
                    }}
                  ></span>

                  <span className="labels" data-on="ON" data-off="OFF"></span>
                </label>
              </div>
              <p
                className="font14x400 mb-1 text-end"
                style={{ color: "var(--text3)" }}
              >
                toggle the button OFF to disable your store
              </p>
            </div>
          </div>
        </div>

        {/* order details */}
        <div className="col-12 col-md-4 order-values-container">
          <div className="cardWrapper">
            <div className="cardWrapperHeader">
              <h3 className="font14x500 mb-0">Order Values</h3>
            </div>
            <div className="cardContent">
              <div className="d-flex">
                <span className="font14x500" style={{ color: "var(--text4)" }}>
                  Delivery Charges:
                </span>
                {salonDetails?.country?.htmlSymbol !== "" ? (
                  <span className="font14x500 ms-2">{`${String.fromCharCode(
                    salonDetails?.country?.htmlSymbol
                  )}${salonDetails?.deliveryCharges}`}</span>
                ) : (
                  <span className="font14x500 ms-2">{`${salonDetails?.country?.currency}${salonDetails?.deliveryCharges}`}</span>
                )}
              </div>
              <div className="d-flex">
                <span className="font14x500" style={{ color: "var(--text4)" }}>
                  Min. Order Value(To accept order):
                </span>
                {salonDetails?.country?.htmlSymbol !== "" ? (
                  <span className="font14x500 ms-2">{`${String.fromCharCode(
                    salonDetails?.country?.htmlSymbol
                  )}${salonDetails?.minOrderValue}`}</span>
                ) : (
                  <span className="font14x500 ms-2">{`${salonDetails?.country?.currency}${salonDetails?.minOrderValue}`}</span>
                )}
              </div>
              <div className="d-flex">
                <span className="font14x500" style={{ color: "var(--text4)" }}>
                  Min. Order Value(For Free Delivery):
                </span>
                {salonDetails?.country?.htmlSymbol !== "" ? (
                  <span className="font14x500 ms-2">{`${String.fromCharCode(
                    salonDetails?.country?.htmlSymbol
                  )}${salonDetails?.maxOrderValue}`}</span>
                ) : (
                  <span className="font14x500 ms-2">{`${salonDetails?.country?.currency}${salonDetails?.maxOrderValue}`}</span>
                )}
              </div>
              <div className="d-flex mt-2">
                <button
                  className="btn smallBtn shadow-none font12x400"
                  onClick={() => {
                    functions.onEditOrderDetails(salonDetails);
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* edit salon form*/}
      {commonData.modal && (
        <EditOrderDetails
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
        />
      )}
    </>
  );
};

export default HeaderOrderAccepatance;
