import moment from "moment";
import React, { useEffect, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import { SALON_DASHBOARD_NEXT_SEVEN_DAYS } from "../../../../utils/Constants";
import Loader from "../../../Loader";
import { Column } from "@ant-design/plots";

const Last7DaysGraph = (props) => {
  const { state, setState } = props;

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const [filters2, setFilter2] = useState({
    fromDate: moment().add(1, "days").format("YYYY-MM-DD"),
    tillDate: moment().add(7, "days").format("YYYY-MM-DD"),
  });

  const salonNew = JSON.parse(localStorage.getItem("salon_details"));

  const [salonDashoardNextSevenDays, totalResult, error, loader, getData] =
    useFetch({
      url: `${SALON_DASHBOARD_NEXT_SEVEN_DAYS}${salonNew?._id}`,
      resultField: "salonDashoardNextSevenDays",
      parameter: filters2,
    });

  useEffect(() => {
    getData();
  }, [filters2]);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (salonDashoardNextSevenDays) {
      setData([]);
      salonDashoardNextSevenDays?.confirmedOrdersPerDay?.map((item) => {
        return setData((old) => {
          return [
            ...old,
            {
              date: moment(item?.date, "YYYY-MM-DD").format("DD MMM"),
              value: item?.servicesAtHome,
              name: "At Home Orders",
            },
            {
              date: moment(item?.date, "YYYY-MM-DD").format("DD MMM"),
              value: item?.servicesAtSalon,
              name: "At Salon Orders",
            },
          ];
        });
      });
    }
  }, [salonDashoardNextSevenDays]);

  const config = {
    data,
    height: 300,
    isGroup: true,
    xField: "date",
    yField: "value",
    seriesField: "name",
    legend: {
      position: "bottom",
    },
    color: ["#97c1a9", "#f88c24"],
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
  };

  return (
    <>
      <div
        className={
          state.modal
            ? "deleteModalWrapper deleteModalWrapper-active"
            : "deleteModalWrapper"
        }
      >
        <div className="deleteModalDiv" onClick={setPositionToBody}></div>
        <div
          className="deleteModalContainer"
          style={{
            padding: "30px 30px",
            width: "70%",
            height: "400px",
          }}
        >
          <div className="d-flex align-items-center">
            <p className="me-3 mb-0 font16x500">Next 7 Days Graph</p>
            {loader && <Loader height="20" width="20" color="#772286" />}
          </div>
          <div className="mt-4">
            <Column {...config} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Last7DaysGraph;
