import React, { useContext } from "react";
import StarRatings from "react-star-ratings";
import { ReviewMonitoringContext } from "../../../Pages/Salon/Master Salon/Reviews&Ratings";
import Loader from "../../Loader";

const TotalRatings = () => {
  const { analytics, loader } = useContext(ReviewMonitoringContext);

  let total_rating =
    analytics?.totalRatings &&
    analytics?.totalRatings[0]?.ratingSum / analytics?.totalRatings[0]?.count;

  return (
    <>
      <div className="statsTabWrapper">
        <h4 className="font14x500" style={{ color: "var(--text3)" }}>
          TOTAL RATING
        </h4>
        <h5 className="font12x500" style={{ color: "var(--text4)" }}>
          All time Stats
        </h5>
        <div className="d-flex align-items-center mt-3">
          <span
            className="font36x700 me-2"
            style={{
              color: "var(--text2)",
            }}
          >
            {loader ? (
              <span className="d-flex align-items-center">
                0
                <span className="ms-2">
                  <Loader height="20" width="20" color="#772286" />
                </span>
              </span>
            ) : analytics?.totalRatings?.length === 0 ? (
              "0"
            ) : analytics?.totalRatings ? (
              total_rating?.toFixed(1)
            ) : (
              "0"
            )}
          </span>

          {analytics?.totalRatings?.length === 0 ? (
            ""
          ) : analytics?.totalRatings ? (
            <StarRatings
              rating={total_rating}
              starEmptyColor="#a6a6a6"
              starRatedColor="#FCC153"
              starDimension="22px"
              numberOfStars={5}
              starSpacing="2"
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default TotalRatings;
