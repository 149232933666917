import React, { useEffect, useState } from "react";
import Select from "react-select";

const StaticDropdown = (props) => {
  const {
    data,
    htmlFor,
    name,
    label,
    stateData,
    setStateData,
    placeholder,
    onBlur,
  } = props;

  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (data) {
      setOptions(data);
    }
  }, [data]);

  const styles = {
    control: (styles, { isFocused }) => {
      return {
        ...styles,
        borderRadius: "8px",
        textAlign: "left",
        border: "1px solid var(--border_primary)",
        border: isFocused
          ? "1px solid var(--border_primary)"
          : "1px solid var(--border_primary)",
        boxShadow: "none",
        cursor: "pointer",
        height: "48px",
      };
    },
    indicatorSeparator: (styles) => {
      return {
        display: "none",
      };
    },
    placeholder: (styles) => {
      return {
        ...styles,
        fontSize: "16px",
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        fontSize: "16px",
        textTransform: "capitalize",
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        borderRadius: "8px",
        margin: 0,
        backgroundColor: "var(--border_secondary)",
      };
    },
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        color: "#000",
        backgroundColor: isSelected && "#fff",
        borderBottom: "1px solid var(--border_primary)",
        textAlign: "left",
        margin: 0,
        cursor: "pointer",
        fontSize: "16px",
        padding: "10px 11px",
        textTransform: "capitalize",
        ":last-child": {
          borderBottom: "none",
        },
      };
    },
  };

  return (
    <>
      <div className="selectDropdown">
        {label && (
          <label htmlFor={htmlFor} className="font12x500 form-label mb-1">
            {label}
          </label>
        )}
        <Select
          options={options}
          placeholder={placeholder}
          styles={styles}
          name={name}
          value={options.filter((option) => {
            return option.value === stateData;
          })}
          onChange={(e) => {
            setStateData(e.value, e.label);
          }}
          onBlur={onBlur}
        />
      </div>
    </>
  );
};

export default StaticDropdown;
