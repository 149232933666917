import React, { useContext, useEffect, useState } from "react";
import {
  authFormHeader,
  CREATE_CHILD_SALON,
  salonType,
  SALON_CATEGORY,
  SALON_REGISTRATION,
} from "../../utils/Constants";
import CategorySelection from "./sub components/CategorySelection";
import TimeSelection from "./sub components/salon timings/TimeSelection";
import axios from "axios";
import useAuthContext from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import useCategory from "../../hooks/useCategory";
import { toast } from "react-toastify";
import InputError from "../../Shared Components/InputError";
import { useFormik } from "formik";
import { step3Schema } from "../../utils/validationschema";
import DynamicDropdown from "./sub components/DynamicDropdown";
import { registrationContext } from "../../Pages/Salon/Master Salon/RegistrationForm";
import { capitalizeString } from "../../Functions/other";
import useLoading from "../../hooks/useLoading";
import Loader from "../Loader";
import StaticDropdown from "../../Shared Components/StaticDropdown";
import AmenitiesSelection from "./sub components/AmenitiesSelection";
import Amenities from "./sub components/Amenities";

const Step3 = () => {
  const { functions, registrationData, setRegistrationData, location } =
    useContext(registrationContext);

  const { token } = useAuthContext();

  const previous = (e) => {
    e.preventDefault();
    functions.prevStep();
  };

  const navigate = useNavigate();
  const [timings, setTimings] = useState();
  console.log("time", timings);

  //custom hook for getting category
  const [category, loaderSmall, getCategory] = useCategory({
    url: SALON_CATEGORY,
    resultField: "salonCategories",
  });
  useEffect(() => {
    getCategory();
  }, [token]);

  const formik = useFormik({
    initialValues: registrationData,
    validationSchema: step3Schema,
    onSubmit: (data) => {
      // console.log("data", data);
      // console.log("location", location);
      // setRegistrationData({
      //   ...registrationData,
      //   amenities: {
      //     isAc: registrationData.amenities.isAc,
      //     isCardPayment: registrationData.amenities.isCardPayment,
      //     isToilets: registrationData.amenities.isToilets,
      //     isParkingAvailable: registrationData.amenities.isParkingAvailable,
      //   },
      // });
      formSubmit(data);
    },
  });

  const [loader, showLoader, hideLoader] = useLoading();
  const formSubmit = async (data) => {
    console.log("form submit called");

    //form data
    const rFormData = new FormData();
    rFormData.append("logo", data.logo[0]);
    rFormData.append("name", data.salonName);
    if (data.salonDescription !== "") {
      rFormData.append("description", data.salonDescription);
    }
    for (let i = 0; i < data.images.length; i++) {
      rFormData.append("images", data.images[i]);
    }
    for (let i = 0; i < timings.length; i++) {
      rFormData.append("timings", timings[i]);
    }
    if (data.salonEmail !== "") {
      rFormData.append("email", data.salonEmail);
    }
    rFormData.append("contactNumber", data.salonContactNumber);
    rFormData.append("line_1", data.line_1);
    if (data.line_2 !== "") {
      rFormData.append("line_2", data.line_2);
    }
    rFormData.append("city", capitalizeString(data.city));
    rFormData.append("state", data.state);
    rFormData.append("pincode", data.pincode);
    rFormData.append("country", data.country);
    rFormData.append("lat", location?.lat);
    rFormData.append("lng", location?.lng);
    rFormData.append("locationName", location?.locationName);
    rFormData.append("type", data.salonType);
    rFormData.append("user", data.user);
    rFormData.append("isAC", registrationData.isAC);
    rFormData.append("isCardPayment", registrationData.isCardPayment);
    rFormData.append("isToilets", registrationData.isToilets);
    rFormData.append("isParkingAvailable", registrationData.isParkingAvailable);
    rFormData.append("isUber", registrationData.isUber);
    rFormData.append("classType", data.classType);
    rFormData.append("priceRating", data.priceRating);
    for (let i = 0; i < data.salonCategory.length; i++) {
      rFormData.append("category", data.salonCategory[i]);
    }

    for (const pair of rFormData.entries()) {
      console.log(`${pair[0]}, ${pair[1]}`);
    }

    if (token) {
      if (data?.user) {
        try {
          showLoader();
          const res = await axios.post(
            `${CREATE_CHILD_SALON}/${data?.masterSalonId}/childSalon/form`,
            rFormData,
            {
              //headers: authFormHeader,
              headers: {
                "Content-type": "multipart/form-data",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            toast.success("Child Store Created", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
            hideLoader();
            document.body.style.overflow = "unset";
            alert("Child salon is registered.");
            navigate("/salon/dashboard");
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          console.log("login");
        }
      } else {
        try {
          showLoader();
          const res = await axios.post(SALON_REGISTRATION, rFormData, {
            //headers: authFormHeader,
            headers: {
              "Content-type": "multipart/form-data",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });

          if (res.status === 200) {
            toast.success("Store Created", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
            hideLoader();
            alert("Your registration is done. Please wait for verification.");
            navigate("/");
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          console.log("login");
        }
      }
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        {/* salon timings */}
        <TimeSelection setTimings={setTimings} />

        {/* categories */}
        <CategorySelection
          data={category}
          registrationData={registrationData}
          setRegistrationData={setRegistrationData}
          formik={formik}
        />

        {/* type of salon */}
        <div className="mb-3">
          <DynamicDropdown
            data={salonType}
            label="Type of Store*"
            htmlFor="state"
            type="text"
            name="salonType"
            placeholder="Select Type"
            stateData={formik.values.salonType}
            setStateData={(e) => {
              formik.setFieldValue("salonType", e);
            }}
          />
          {formik.errors.salonType ? (
            <InputError error={formik.errors.salonType} />
          ) : null}
        </div>

        {/* Class of Salon* */}
        <div className="mb-3">
          <StaticDropdown
            data={[
              { value: "Luxury", label: "Luxury" },
              { value: "Premium", label: "Premium" },
              { value: "Economy", label: "Economy" },
            ]}
            label="Class of Store*"
            htmlFor="classType"
            type="text"
            placeholder="Select Class"
            name="classType"
            stateData={formik.values.classType}
            setStateData={(e) => {
              formik.setFieldValue("classType", e);
            }}
          />
          {formik.errors.classType ? (
            <InputError error={formik.errors.classType} />
          ) : null}
        </div>

        {/* Price rating* */}
        <div className="mb-3">
          <StaticDropdown
            data={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
            ]}
            label="Price Rating*"
            htmlFor="priceRating"
            type="text"
            placeholder="Select Price Rating"
            name="priceRating"
            stateData={formik.values.priceRating}
            setStateData={(e) => {
              formik.setFieldValue("priceRating", e);
            }}
          />
          {formik.errors.priceRating ? (
            <InputError error={formik.errors.priceRating} />
          ) : null}
        </div>

        {/* amenities */}
        <div className="categoryCheck">
          <div className="mb-3">
            <label className="font12x500 form-label mb-1">
              Amenities (Optional)
            </label>
            <div className="checkBoxContainer">
              <Amenities
                value="AC"
                check={registrationData.isAC}
                setRegistrationData={(e) => {
                  setRegistrationData({
                    ...registrationData,
                    isAC: e,
                  });
                }}
              />
              <Amenities
                value="Card Payment"
                check={registrationData.isCardPayment}
                setRegistrationData={(e) => {
                  setRegistrationData({
                    ...registrationData,
                    isCardPayment: e,
                  });
                }}
              />
              <Amenities
                value="Toilets"
                check={registrationData.isToilets}
                setRegistrationData={(e) => {
                  setRegistrationData({
                    ...registrationData,
                    isToilets: e,
                  });
                }}
              />
              <Amenities
                value="Parking Available"
                check={registrationData.isParkingAvailable}
                setRegistrationData={(e) => {
                  setRegistrationData({
                    ...registrationData,
                    isParkingAvailable: e,
                  });
                }}
              />
              <Amenities
                value="Uber"
                check={registrationData.isUber}
                setRegistrationData={(e) => {
                  setRegistrationData({
                    ...registrationData,
                    isUber: e,
                  });
                }}
              />
            </div>
          </div>
        </div>

        {/* agree check */}
        <div className="mb-3">
          <div className="agreeCheck form-check d-flex align-items-center p-0">
            <input
              type="checkbox"
              className="me-2"
              id="exampleCheck1"
              name="tandcCheck"
              checked={formik.values.tandcCheck}
              onChange={(e) => {
                formik.setFieldValue("tandcCheck", e.target.checked);
              }}
            />
            <label
              className="font14x400 form-check-label"
              htmlFor="exampleCheck1"
            >
              I have read and agreed to the{" "}
              <a href="https://getlook.in/custtnc/" target="_blank">
                terms & conditions
              </a>
            </label>
          </div>
          {formik.errors.tandcCheck ? (
            <InputError error={formik.errors.tandcCheck} />
          ) : null}
        </div>

        <div className="row mt-4">
          <div className="col-6">
            <button
              className="btn OtpBtn shadow-none"
              onClick={(e) => {
                previous(e);
              }}
            >
              Previous
            </button>
          </div>
          <div className="col-6">
            <button type="submit" className="btn OtpBtn shadow-none">
              {loader ? (
                <div className="d-flex justify-content-center">
                  <Loader height="25" width="25" color="#fff" />
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Step3;
