import React, { useEffect, useState } from "react";
import VerifyOtp from "../../../Components/VerifyOTP/index";
import axios from "axios";
import { useFormik } from "formik";
import { mobileSchema } from "../../../utils/validationschema";
import {
  ADMIN,
  CHILD_SALON,
  COUNTRY_SEARCH,
  LOGIN,
  MASTER_SALON,
  MASTER_SALON_USER,
  withoutAuthHeader,
} from "../../../utils/Constants";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import InputError from "../../../Shared Components/InputError";
import useLoading from "../../../hooks/useLoading";
import Loader from "../../../Components/Loader";
import SelectCountry from "./SelectCountry";
import { IoMdArrowDropdown } from "react-icons/io";
import OutsideClickHandler from "react-outside-click-handler";

const Login = () => {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token"));
  const role = JSON.parse(localStorage.getItem("role"));
  const salonDetails = JSON.parse(localStorage.getItem("salon_details"));
  const [isOpen, setIsOpen] = useState(false);
  const [countries, setCountries] = useState(null);
  const [countriesTemp, setCountriesTemp] = useState(null);
  useEffect(() => {
    if (token && role === ADMIN) {
      navigate("/admin/dashboard");
    } else if (
      token &&
      salonDetails &&
      (role === MASTER_SALON ||
        role === CHILD_SALON ||
        role === MASTER_SALON_USER)
    ) {
      navigate("/salon/dashboard");
    }
    window.localStorage.removeItem("latitude");
    window.localStorage.removeItem("longitude");
  }, []);

  const getCountries = async () => {
    try {
      const res = await axios.get(COUNTRY_SEARCH, {
        params: {
          sort_order: "asc",
        },
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });
      if (res.status === 200) {
        setCountries(res.data.countries);
        setCountriesTemp(res.data.countries);
      } else {
        console.log("error", res.status);
      }
    } catch (error) {
      console.log("catch error: ", error);
      if (error?.response?.status === 502) {
        toast.error(error?.response?.data?.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    }
  };

  const createFilterArray = (value) => {
    if (value === "") {
      formik.setFieldValue("isoCode", "");
      formik.setFieldValue("phoneCode", "");
      formik.setFieldValue("country", "");
      setCountriesTemp(countries);
    } else {
      let array = countries;
      const filterData = array?.filter((item) => {
        return item?.name?.toLowerCase().includes(value.toLowerCase());
      });
      setCountriesTemp(filterData);
    }
  };

  const [isNumberValid, setIsNumberValid] = useState(false);
  const [authDetails, setAuthDetails] = useState({
    mobile: "",
    otp: "",
    hash: "",
  });

  const [loader, showLoader, hideLoader] = useLoading();

  //storing number
  const initialValues = {
    mobileNumber: "",
    country: "",
    phoneCode: "91",
    isoCode: "IN",
    inputCountry: "IN",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: mobileSchema,
    onSubmit: async (values) => {
      try {
        showLoader();
        const res = await axios.post(
          LOGIN,
          {
            mobile: values.mobileNumber,
            country: values.country,
          },
          {
            headers: withoutAuthHeader,
          }
        );

        if (res.status === 200) {
          setAuthDetails({
            otp: res.data.otp,
            hash: res.data.hash,
            mobile: formik.values.mobileNumber,
            country: formik.values.country,
            phoneCode: formik.values.phoneCode,
          });
          setIsNumberValid(true);
          hideLoader();
        }
      } catch (err) {
        console.log("error: ", err);
        if (err.response.status === 502) {
          hideLoader();
          toast.error(err.response.data.message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    },
  });

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (countries?.length !== 0) {
      countries?.map((item, i) => {
        if (formik.values.isoCode === item.acronym) {
          formik.setFieldValue("country", item?._id);
        }
      });
    }
  }, [countries]);

  return (
    <>
      {isNumberValid ? (
        <VerifyOtp
          number={formik.values.mobileNumber}
          authDetails={authDetails}
        />
      ) : (
        <main>
          <div className="container">
            <div className="row flex-column ps-3 pe-3">
              <div className="authWrapper col-12 col-lg-5 p-0">
                <div className="authContainer">
                  <div className="text-center mt-2">
                    <img
                      src="/Assets/groomeLogo.png"
                      alt="logo"
                      draggable="false"
                      className="img-fluid"
                      width={200}
                    />
                  </div>
                  <h3 className="font16x500 text-center mt-5 mb-4">
                    Log in with OTP
                  </h3>

                  <div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="mb-4">
                        <div className="mb-1 position-relative">
                          <label
                            htmlFor="pNo"
                            className="font12x500 form-label"
                          >
                            Your Phone Number*
                          </label>
                          <div className="d-flex gap-1">
                            <div
                              className="countries_wrapper d-flex gap-1 align-items-center"
                              onClick={() => setIsOpen(true)}
                            >
                              <input
                                type="text"
                                className="countries_input"
                                name="inputCountry"
                                value={formik.values.inputCountry}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "inputCountry",
                                    e.target.value
                                  );
                                  createFilterArray(e.target.value);
                                }}
                                onBlur={formik.handleBlur}
                              />
                              <div>
                                <IoMdArrowDropdown />
                              </div>
                            </div>
                            <div
                              className="input-group"
                              style={{
                                border: "1px solid var(--border_primary)",
                                borderRadius: "8px",
                                overflow: "hidden",
                              }}
                            >
                              <div className="input-group-text border-0">
                                {formik.values.phoneCode}
                              </div>
                              <input
                                type="tel"
                                className="form-control shadow-none font14x500 border-0"
                                name="mobileNumber"
                                id="pNo"
                                value={formik.values.mobileNumber}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="off"
                                style={{
                                  borderRadius: "0 !important",
                                }}
                              />
                            </div>
                          </div>
                          <OutsideClickHandler
                            onOutsideClick={() => setIsOpen(false)}
                          >
                            {isOpen && countriesTemp?.length !== 0 && (
                              <SelectCountry
                                countries={countriesTemp}
                                formik={formik}
                                setIsOpen={setIsOpen}
                              />
                            )}
                          </OutsideClickHandler>
                        </div>
                        {formik.touched.country && formik.errors.country ? (
                          <InputError error={formik.errors.country} />
                        ) : null}
                        {formik.touched.mobileNumber &&
                        formik.errors.mobileNumber ? (
                          <InputError error={formik.errors.mobileNumber} />
                        ) : null}
                      </div>
                      <button
                        type="submit"
                        className="btn OtpBtn shadow-none font16x500"
                      >
                        {loader ? (
                          <div className="d-flex justify-content-center">
                            <Loader height="25" width="25" color="#fff" />
                          </div>
                        ) : (
                          "Get OTP"
                        )}
                      </button>
                    </form>
                  </div>
                </div>
                <div className="authBoxHint text-center">
                  <p className="font14x500 mb-0">
                    You will receive an OTP via SMS
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
      <ToastContainer />
    </>
  );
};

export default Login;
