import React from "react";
import { toast } from "react-toastify";
import useAuthContext from "../hooks/useAuthContext";
import axios from "axios";
import { authHeader, SALON_UPDATE } from "../utils/Constants";

const ToggleButton = ({ showToggleData, temp, apiCall }) => {
  const { token } = useAuthContext();

  const handleChange = async (e) => {
    const value = e.target.checked;

    if (token && temp?.salonStatus === "verified") {
      const rFormData = new FormData();
      rFormData.append("isActive", value);
      rFormData.append("status", value ? 1 : 2);
      try {
        const res = await axios.put(
          `${SALON_UPDATE}${temp._id}/form`,
          rFormData,
          {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );
        if (res.status === 200) {
          toast.success("Live Status Changed", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          apiCall();
        } else {
          console.log("error", res.status);
        }
      } catch (error) {
        console.log("catch error: ", error);
        if (error.response.status === 502) {
          toast.error(error.response.data.message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    } else {
      toast.warn("Application status must be verified to activate salon", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };
  return (
    <>
      <label className="switchBtn">
        <input
          type="checkbox"
          className="me-2"
          defaultChecked={temp?.isActive}
          onChange={(e) => {
            handleChange(e);
            // console.log("e", e);
          }}
          disabled={temp?.salonStatus !== "verified" && true}
        />
        <span
          className="openCloseCheck"
          style={{
            backgroundColor: temp?.isActive ? "#11CC93" : "#BCBCBC",
          }}
        ></span>
        {showToggleData && (
          <span className="labels" data-on="ON" data-off="OFF"></span>
        )}
      </label>
    </>
  );
};

export default ToggleButton;
