import React, { useEffect, useState } from "react";
import { Line } from "@ant-design/plots";
import moment from "moment";

const Chart = ({ statsData }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([]);
    statsData?.map((item, i) => {
      setData((old) => {
        return [
          ...old,
          {
            date: moment(item?.date, "YYYY-MM-DD").format("DD MMM"),
            value: item?.servicesAtHome,
            name: "At Home Orders",
          },
          {
            date: moment(item?.date, "YYYY-MM-DD").format("DD MMM"),
            value: item?.servicesAtSalon,
            name: "At Salon Orders",
          },
          {
            date: moment(item?.date, "YYYY-MM-DD").format("DD MMM"),
            value: item?.products,
            name: "Products",
          },
        ];
      });
    });
  }, [statsData]);

  const config = {
    data,
    height: 300,
    xField: "date",
    yField: "value",
    seriesField: "name",
    color: ["#97c1a9", "#f88c24", "#cbaacb"],
    legend: {
      position: "bottom",
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 3000,
      },
    },
  };

  return (
    <>
      <Line {...config} />
    </>
  );
};

export default Chart;
