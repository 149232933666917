import React, { useEffect, useState } from "react";
import Layout from "../../../Components/Layout";
import { RiDeleteBin6Line } from "react-icons/ri";
import useAuthContext from "../../../hooks/useAuthContext";
import { SALON_DELETE, SALON_SEARCH } from "../../../utils/Constants";
import DeleteModal from "../../../Components/DeleteRecord";
import UpdateRecord from "../../../Components/Admin/UpdateRecord";
import useFetch from "../../../hooks/useFetch";
import { ToastContainer } from "react-toastify";
import DropdownSmall from "../../../Shared Components/DropdownSmall";
import Pagination from "../../../Components/Pagination";
import SearchInput2 from "../../../Shared Components/SearchInput2";
import ToggleButton from "../../../Shared Components/ToggleButton";
import Loader from "../../../Components/Loader";
import salonLogo from "../../../uploads/salon/logo1.jpg";
import MobileSalonDashboardHeader from "../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileFilterHeader from "../../../Components/mobilefilterheader";
import MobileSalonListFilters from "../../../Components/Mobile/Admin/mobileSalonListFilter";
import SearchInput from "../../../Shared Components/SearchInput";

const AllSalons = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [id, setId] = useState("");
  const [temp, setTemp] = useState("");

  const setPositionToBody = (id) => {
    setIsDeleteModalOpen(true);
    document.body.style.overflow = "hidden";
    setId(id);
  };
  const setPositionToBody2 = (item) => {
    setIsUpdateModalOpen(true);
    document.body.style.overflow = "hidden";
    setTemp(item);
  };

  const { updateSalon } = useAuthContext();
  const loginAsSalon = (item) => {
    window.localStorage.setItem("salon_details", JSON.stringify(item));
    updateSalon(item);
    window.open("/salon/dashboard");
  };

  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
  });
  console.log(filters);

  const [param, setParam] = useState({ show: false });
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const addParam = () => {
    setFilter({
      ...filters,
      ...param,
    });
  };

  const resetParam = () => {
    setParam({ show: false });
    delete filters.q;
    delete filters.contactNumber;
    delete filters.isActive;
    delete filters.statusName;
    delete filters.status;
    delete filters.applicationStatusName;
    getData();
  };

  const [salons, totalResult, error, loader, getData] = useFetch({
    url: `${SALON_SEARCH}`,
    resultField: "salons",
    parameter: filters,
  });

  useEffect(() => {
    window.localStorage.removeItem("salon_details");
    getData();
  }, [filters]);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Salons List"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileSalonListFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          resetParam={resetParam}
          filters={filters}
          setFilter={setFilter}
          addParam={addParam}
          setParam={setParam}
          param={param}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Salons List"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Salons List"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>

      <div className="mainWrapper clients-page">
        <div className="container ms-0 ps-0 d-flex justify-content-between align-items-center mb-2">
          <h3 className="font22x500 pageHeading mb-0">Store List</h3>
          {/* <button className="btn addBtn shadow-none">Add New Salon</button> */}
        </div>
        <div className="filter-container container ms-0 ps-0 mb-3">
          <div className="row">
            <div className="col-5 d-flex align-items-end">
              <SearchInput
                type="text"
                placeholder="Search by name/contact number"
                stateData={param}
                setStateData={setParam}
                parameter1="name"
                parameter2="contactNumber"
                addParam={addParam}
                resetParam={resetParam}
              />
            </div>
            <div className="col-7 d-flex justify-content-end align-items-end">
              <div className="me-3">
                {/* status */}
                <DropdownSmall
                  data={[
                    { value: "Pending", label: "Pending" },
                    { value: "Verified", label: "Verified" },
                    { value: "Not Verified", label: "Not Verified" },
                  ]}
                  label="Application Status"
                  placeholder="Id Status"
                  htmlFor="status"
                  type="text"
                  stateData={filters?.applicationStatusName}
                  setStateData={(e) => {
                    if (e === "All") {
                      delete filters?.status;
                      setFilter({
                        ...filters,
                        applicationStatusName: e,
                      });
                    } else {
                      setFilter({
                        ...filters,
                        applicationStatusName:
                          e === "Pending"
                            ? "Pending"
                            : e === "Verified"
                            ? "Verified"
                            : "Not Verified",
                        status: e === "Pending" ? 0 : "Verified" ? 1 : 3,
                      });
                    }
                  }}
                />
              </div>

              {/* status */}
              <DropdownSmall
                data={[
                  { value: "All", label: "All" },
                  { value: "Active", label: "Active" },
                  { value: "Inactive", label: "Inactive" },
                ]}
                label="Live Status"
                placeholder="Id Status"
                htmlFor="status"
                type="text"
                stateData={filters?.statusName}
                setStateData={(e) => {
                  if (e === "All") {
                    delete filters?.isActive;
                    setFilter({
                      ...filters,
                      statusName: e,
                    });
                  } else {
                    setFilter({
                      ...filters,
                      statusName: e === "Active" ? "Active" : "Inactive",
                      isActive: e === "Active" ? true : false,
                    });
                  }
                }}
              />

              <button
                className="btn resetBtn shadow-none ms-3"
                onClick={resetParam}
              >
                Reset
              </button>
            </div>
          </div>
        </div>

        {salons?.length !== 0 ? (
          <div className="container dataTable ms-0 ps-0">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Store Id</th>
                  <th scope="col">Store Name</th>
                  <th scope="col">Phone No</th>
                  <th scope="col">City</th>
                  <th scope="col">Application Status</th>
                  <th scope="col">Store Live Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {salons?.map((item, i) => {
                  return (
                    <tr key={item._id}>
                      <td>{item?.salonId}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          {item?.logo === null ? (
                            <img
                              src={salonLogo}
                              alt="logo"
                              width={52}
                              height={52}
                              style={{
                                borderRadius: "50%",
                                marginRight: "15px",
                              }}
                            />
                          ) : (
                            <img
                              src={`${process.env.REACT_APP_Image_BaseURL}${item?.logo}`}
                              alt="logo"
                              width={52}
                              height={52}
                              style={{
                                borderRadius: "50%",
                                marginRight: "15px",
                              }}
                            />
                          )}
                          {item?.name}
                        </div>
                      </td>
                      <td>{item?.contactNumber}</td>
                      <td className="text-capitalize">{item?.address?.city}</td>
                      <td>
                        <p
                          className="status mb-0 text-capitalize"
                          style={{
                            backgroundColor: `${
                              item?.salonStatus === "pending"
                                ? "#FFE4AF"
                                : item?.salonStatus === "verified"
                                ? "#AFFFC2"
                                : "#FFAFAF"
                            }`,
                          }}
                        >
                          {item?.salonStatus}
                        </p>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <ToggleButton
                            showToggleData={false}
                            temp={item}
                            apiCall={getData}
                          />
                        </div>
                      </td>
                      <td>
                        <button
                          className="btn font14x400 filterBtn shadow-none me-2"
                          onClick={() => {
                            loginAsSalon(item);
                          }}
                        >
                          Login
                        </button>
                        {/* </Link> */}

                        <button
                          className="btn font14x400 filterBtn shadow-none me-2"
                          onClick={() => {
                            setPositionToBody2(item);
                          }}
                        >
                          Update
                        </button>

                        <span
                          className="deleteBtn"
                          onClick={() => {
                            setPositionToBody(item?._id);
                          }}
                        >
                          <RiDeleteBin6Line />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Store Found"
            )}
          </div>
        )}

        {salons?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      {/* delete modal */}
      {isDeleteModalOpen && (
        <DeleteModal
          id={id}
          apiCall={getData}
          state={isDeleteModalOpen}
          setState={setIsDeleteModalOpen}
          url={SALON_DELETE}
          text="Salon"
        />
      )}

      {/* update modal */}
      {isUpdateModalOpen && (
        <UpdateRecord
          temp={temp}
          apiCall={getData}
          isUpdateModalOpen={isUpdateModalOpen}
          setIsUpdateModalOpen={setIsUpdateModalOpen}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default AllSalons;
