import React, { useEffect, useState } from "react";
import Layout from "../../../../../Components/Layout";
import Loader from "../../../../../Components/Loader";
import PageNav from "../../../../../Components/PageNav";
import Pagination from "../../../../../Components/Pagination";
import { getAddedOnDateTime } from "../../../../../Functions/dateTime";
import useFetch from "../../../../../hooks/useFetch";
import { COUNTRY_LIST } from "../../../../../utils/Constants";
import { ToastContainer } from "react-toastify";
import { categoriesLinks } from "../Service";
import SearchInput2 from "../../../../../Shared Components/SearchInput2";
import CountryForm from "../../../../../Components/Admin/Categories/CountryForm";
import MobileSalonDashboardHeader from "../../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileFilterHeader from "../../../../../Components/mobilefilterheader";
import MobileCountryListFilter from "../../../../../Components/Mobile/Admin/mobileCountryListFilter";

const CountryView = () => {
  const [commonData, setCommonData] = useState({
    data: {},
    modal: false,
  });

  const [param, setParam] = useState({ show: false });
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const functions = {
    onEdit: (item) => {
      setCommonData({
        data: item,
        modal: true,
      });
      document.body.style.position = "fixed";
    },
    onAdd: () => {
      setCommonData({
        data: undefined,
        modal: true,
      });
      document.body.style.position = "fixed";
    },
    addParam: () => {
      setFilter({
        ...filters,
        ...param,
      });
    },
    resetParam: () => {
      setParam({ show: false });
      delete filters.q;
      getData();
    },
  };

  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
    sort_by: "order",
    sort_order: "desc",
  });
  console.log(filters);

  const [countries, totalResult, error, loader, getData] = useFetch({
    url: `${COUNTRY_LIST}`,
    resultField: "countries",
    parameter: filters,
  });

  useEffect(() => {
    window.localStorage.removeItem("salon_details");
    getData();
  }, [filters]);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Country List"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileCountryListFilter
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          onClick={functions.onAdd}
          resetParam={functions.resetParam}
          param={param}
          setParam={setParam}
          addParam={functions.addParam}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Country List"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Country List"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper">
        <div>
          <div className="service-navigation-neworder-button-container">
            <div className="service-navigation-neworder-button d-flex justify-content-between pe-2">
              <PageNav links={categoriesLinks} />
              <div className="desktopButton">
                <button
                  className="btn addBtn shadow-none"
                  onClick={functions.onAdd}
                >
                  Add Country
                </button>
              </div>
            </div>
          </div>

          <div className="filter-container container mt-4 ms-0 ps-0 mb-3">
            <div className="row">
              <div className="col-5 col-lg-5 d-flex align-items-end">
                <SearchInput2
                  type="text"
                  placeholder="Search by name"
                  stateData={param}
                  setStateData={setParam}
                  parameter1="name"
                  parameter2={undefined}
                  addParam={functions.addParam}
                  resetParam={functions.resetParam}
                />
              </div>
            </div>
          </div>
        </div>

        {countries?.length !== 0 ? (
          <div className="container dataTable ms-0 ps-0">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Phone Code</th>
                  <th scope="col">Acronym</th>
                  <th scope="col">Currency</th>
                  <th scope="col">Symbol</th>
                  <th scope="col">Updated on</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {countries?.map((item, i) => {
                  return (
                    <tr key={item?._id}>
                      <td>{item?.name}</td>
                      <td>{item?.phoneCode}</td>
                      <td>{item?.acronym}</td>
                      <td>{item?.currency}</td>
                      <td>{`${String.fromCharCode(item?.htmlSymbol)}`}</td>
                      <td>{getAddedOnDateTime(item?.updatedAt)}</td>
                      <td>
                        <button
                          className="btn font14x400 filterBtn shadow-none me-2"
                          onClick={() => {
                            functions.onEdit(item);
                          }}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Country Found"
            )}
          </div>
        )}

        {countries?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      {/* country form add and update both */}
      {commonData.modal && (
        <CountryForm
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default CountryView;
