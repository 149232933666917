import React from "react";
import { getAddedOnDate } from "../Functions/dateTime";

const UpdationDetails = ({ data }) => {
  // console.log("data", data);
  return (
    <>
      <div className="pb-2 pt-4" style={{ color: "var(--text4)" }}>
        <p className="font12x400 mb-0 text-capitalize">
          Last Updated By:{" "}
          <span>
            {data && data?.updatedByUser?.length === 0
              ? data?.user?.role
                ? data?.user?.role
                : "- -"
              : data?.updatedByUser[data?.updatedByUser?.length - 1]?.name
              ? `${
                  data?.updatedByUser[data?.updatedByUser?.length - 1]?.name
                }(${
                  data?.updatedByUser[data?.updatedByUser?.length - 1]?.role
                })`
              : `${data?.updatedByUser[data?.updatedByUser?.length - 1]?.role}`}
          </span>
        </p>
        <p className="font12x400 mb-0">
          Last Updated On: <span>{getAddedOnDate(data?.updatedAt)}</span>
        </p>
      </div>
    </>
  );
};

export default UpdationDetails;
