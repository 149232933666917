import React, { useEffect, useState } from "react";
import Layout from "../../../../Components/Layout";
import useAuthContext from "../../../../hooks/useAuthContext";
import {
  authHeader,
  Day,
  DELETE_USER_ADDRESSES,
  LOGGED_IN_USER_ADDRESSES,
  SALON_BY_ID,
  UPDATE_USER_ADDRESSES,
} from "../../../../utils/Constants";
import RenderMap from "../../../../Components/Map/index";
import EditSalon from "../../../../Components/EditSalonForm";
import { toast, ToastContainer } from "react-toastify";
import salonImage from "../../../../uploads/salon/image1.jpg";
import Loader from "../../../../Components/Loader";
import axios from "axios";
import useLoading from "../../../../hooks/useLoading";
import { QRCodeCanvas } from "qrcode.react";
import EditAddressSalon from "../../../../Components/EditAddressSalon";
import useFetch from "../../../../hooks/useFetch";
import DeleteModal from "../../../../Components/DeleteRecord";
import { useNavigate } from "react-router-dom";
import HeaderOrderAccepatance from "../../../../Components/Salon Details/HeaderOrderAccepatance";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import RightSideSection from "./rightsidesection";

const Profile = () => {
  const [commonData, setCommonData] = useState({
    data: {},
    modal: false,
    diff: "",
  });
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [id, setId] = useState("");

  const salonDetails = JSON.parse(localStorage.getItem("salon_details"));
  const { token } = useAuthContext();

  const [loader, showLoader, hideLoader] = useLoading();

  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [userAddresses, totalResult, error, loader2, getData] = useFetch({
    url: `${LOGGED_IN_USER_ADDRESSES}`,
    resultField: "userAddresses",
  });

  const functions = {
    onEdit: () => {
      setCommonData({
        data: salonDetails,
        modal: true,
        diff: "salon",
      });
      document.body.style.overflow = "hidden";
    },
    getSalonById: async () => {
      if (token) {
        try {
          showLoader();
          const res = await axios.get(`${SALON_BY_ID}${salonDetails?._id}`, {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });

          if (res.status === 200) {
            window.localStorage.setItem(
              "salon_details",
              JSON.stringify(res?.data?.salon)
            );
            hideLoader();
          } else {
            console.log("error", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            hideLoader();
            toast.error(error.response.data.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
    downloadQRCode: () => {
      const qrCodeURL = document
        .getElementById("qrCode")
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      let aEl = document.createElement("a");
      aEl.href = qrCodeURL;
      aEl.download = "QR_Code.png";
      document.body.appendChild(aEl);
      aEl.click();
      document.body.removeChild(aEl);
    },
    onAddAddress: () => {
      setCommonData({
        data: undefined,
        modal: true,
        diff: "address",
      });
      document.body.style.overflow = "hidden";
    },
    onEditAddress: (item) => {
      setCommonData({
        data: item,
        modal: true,
        diff: "address",
      });
      document.body.style.overflow = "hidden";
    },
    setDefaultAddress: async (id) => {
      if (token) {
        try {
          const res = await axios.put(
            `${UPDATE_USER_ADDRESSES}${id}`,
            {
              isDefault: true,
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            getData();
            toast.success("Default Address Set", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
    onDelete: (id) => {
      setIsDeleteModalOpen(true);
      document.body.style.overflow = "hidden";
      setId(id);
    },
  };

  useEffect(() => {
    functions.getSalonById();
    getData();
  }, []);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Store Profile"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <RightSideSection
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          editStore={functions.onEdit}
          gotToPayment={() => navigate("/salon/payment")}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Store Profile"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Store profile"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100%", height: "70vh" }}
        >
          <Loader height="40" width="40" color="#772286" />
        </div>
      ) : (
        <div className="mainWrapper salon-profile-container pb-3">
          <div className="store-document-edit-salon-desktop-container">
            <div className="container ms-0 ps-0 d-flex justify-content-between align-items-center mb-4">
              <h3 className="font22x500 pageHeading mb-0">Store Profile</h3>
              <div>
                <button
                  className="btn addBtn shadow-none me-3 font14x400"
                  onClick={() => navigate("/salon/payment")}
                >
                  Store Documents
                </button>
                <button
                  className="btn addBtn shadow-none font14x400"
                  onClick={functions.onEdit}
                >
                  Edit Store
                </button>
              </div>
            </div>
          </div>
          <div className="salon-profile-sub-container container ms-0 ps-0 mt-5 mb-3">
            <HeaderOrderAccepatance
              salonDetails={salonDetails}
              getData={functions.getSalonById}
            />
            <div className="allDetailsWrapper">
              {/* Details */}
              <div className="cardWrapper grid-col-span-1">
                <div className="cardWrapperHeader">
                  <h3 className="font22x500 mb-0">Details</h3>
                </div>
                <div className="cardContent">
                  <div className="d-flex justify-content-between">
                    <span
                      className="font14x500"
                      style={{ color: "var(--text4)" }}
                    >
                      Store Name
                    </span>
                    <span className="font14x500">{salonDetails?.name}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span
                      className="font14x500"
                      style={{ color: "var(--text4)" }}
                    >
                      Store Contact No.
                    </span>
                    <span className="font14x500">
                      {salonDetails?.contactNumber}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span
                      className="font14x500"
                      style={{ color: "var(--text4)" }}
                    >
                      Email Id
                    </span>
                    <span className="font14x500">
                      {salonDetails?.email ? salonDetails?.email : "--"}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span
                      className="font14x500"
                      style={{ color: "var(--text4)" }}
                    >
                      Store Address
                    </span>
                    <span className="text-end text-capitalize font14x500">
                      {salonDetails?.address?.line_1},{" "}
                      {salonDetails?.address?.line_2 &&
                        `${salonDetails?.address?.line_2}, `}
                      {salonDetails?.address?.city}, <br />{" "}
                      {salonDetails?.address?.state},{" "}
                      {salonDetails?.address?.country},{" "}
                      {salonDetails?.address?.pincode}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span
                      className="font14x500"
                      style={{ color: "var(--text4)" }}
                    >
                      Location
                    </span>
                    <span className="text-end text-capitalize font14x500">
                      {salonDetails?.location?.locationName}
                    </span>
                  </div>
                </div>
              </div>

              {/* map */}
              <div className="cardWrapper grid-col-span-2">
                <div className="cardWrapperHeader">
                  <h3 className="font22x500 mb-0">Location</h3>
                </div>
                <div className="cardContent p-0">
                  <div
                    style={{
                      position: "relative",
                      height: "200px",
                    }}
                  >
                    <RenderMap
                      google={window.google}
                      latitude={salonDetails?.location?.lat}
                      longitude={salonDetails?.location?.lng}
                    />
                  </div>
                </div>
              </div>

              {/* salon photos */}
              <div className="cardWrapper">
                <div className="cardWrapperHeader">
                  <h3 className="font22x500 mb-0">Store Photos</h3>
                </div>
                <div className="cardContent">
                  <div className="salonImages">
                    {salonDetails?.images?.length === 0 ? (
                      <img
                        src={salonImage}
                        alt="image"
                        width={72}
                        height={72}
                        style={{ borderRadius: 8 }}
                      />
                    ) : (
                      salonDetails?.images?.map((itm, i) => {
                        return (
                          <img
                            key={i}
                            src={`${process.env.REACT_APP_Image_BaseURL}${itm}`}
                            alt="image"
                            width={72}
                            height={72}
                            style={{ borderRadius: 8 }}
                          />
                        );
                      })
                    )}
                  </div>
                </div>
              </div>

              {/* description */}
              <div className="cardWrapper grid-col-span-2">
                <div className="cardWrapperHeader">
                  <h3 className="font22x500 mb-0">Description</h3>
                </div>
                <div className="cardContent">
                  <p className="mb-0 lh-sm text-capitalize">
                    {salonDetails?.description
                      ? salonDetails?.description
                      : "--"}
                  </p>
                </div>
              </div>

              {/* timings */}
              <div className="cardWrapper">
                <div className="cardWrapperHeader">
                  <h3 className="font22x500 mb-0">Store Timings</h3>
                </div>
                {Day?.map((itm, i) => {
                  return (
                    <div className="cardContent" key={i}>
                      {Object.keys(itm).map((day, indx) => {
                        return (
                          <div
                            className="d-flex justify-content-between"
                            key={day}
                          >
                            <span
                              className="font14x500 text-capitalize"
                              style={{ color: "var(--text4)" }}
                            >
                              {day}
                            </span>
                            {salonDetails &&
                            salonDetails?.timings[indx] !== "0:00-0:00" ? (
                              <span className="font14x500">
                                {`${
                                  salonDetails?.timings[indx]?.split("-")[0]
                                } TO ${
                                  salonDetails?.timings[indx]?.split("-")[1]
                                }`}
                              </span>
                            ) : (
                              <span className="font14x500">Closed</span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>

              {/* QR Code */}
              <div className="cardWrapper grid-col-span-2">
                <div className="cardWrapperHeader">
                  <h3 className="font22x500 mb-0">QR Code</h3>
                </div>
                <div className="cardContent d-flex">
                  <QRCodeCanvas
                    id="qrCode"
                    value={`${
                      process.env.REACT_APP_NODE_ENV === "development"
                        ? process.env.REACT_APP_TEST_QR_LINK
                        : process.env.REACT_APP_QR_LINK
                    }${salonDetails?.name
                      ?.toLowerCase()
                      ?.split(" ")
                      .join("-")}-${salonDetails?._id}`}
                    size={200}
                    style={{ cursor: "pointer" }}
                    onClick={functions.downloadQRCode}
                    x="none"
                    y="none"
                  />
                  <p className="mb-0 ms-2 lh-sm text-capitalize">
                    Click on the QR to download
                  </p>
                </div>
              </div>

              {/* Address */}
              <div className="cardWrapper grid-col-span-2">
                <div className="cardWrapperHeader d-flex justify-content-between align-items-center">
                  <h3 className="font22x500 mb-0">Addresses</h3>
                  <p
                    className="mb-0 font16x500"
                    style={{
                      cursor: "pointer",
                      color: "var(--text1)",
                    }}
                    onClick={() => {
                      functions.onAddAddress();
                    }}
                  >
                    Add New Address +
                  </p>
                </div>
                <div className="cardContent">
                  <div className="container m-0 p-0">
                    <div className="row">
                      {userAddresses.length !== 0 ? (
                        userAddresses?.map((add, i) => {
                          return (
                            <div className="col-6" key={i}>
                              <div
                                className="addressWrapper"
                                style={{
                                  outline:
                                    add?.isDefault &&
                                    "1px solid var(--btn_secondary)",
                                }}
                              >
                                <div className="addressWrapperHeader">
                                  <h3 className="font14x500 mb-0">
                                    {add?.isDefault
                                      ? `Address ${i + 1} (Default)`
                                      : `Address ${i + 1}`}
                                  </h3>
                                </div>
                                <div className="addressContent">
                                  <p className="mb-0">{add?.line_1}</p>
                                  {add?.line_2 !== "" && (
                                    <p className="mb-0">{add?.line_2}</p>
                                  )}
                                  <p className="mb-0">{add?.city}</p>
                                  <p className="mb-0">{add?.state}</p>
                                  <p className="mb-0">{add?.country}</p>
                                  <p className="mb-0">{add?.pincode}</p>
                                  <div className="d-flex flex-wrap gap-2 mt-2">
                                    <button
                                      className="btn smallBtn shadow-none font12x400"
                                      onClick={() => {
                                        functions.onEditAddress(add);
                                      }}
                                    >
                                      Edit
                                    </button>
                                    <button
                                      className="btn smallBtn shadow-none font12x400"
                                      onClick={() => {
                                        functions.onDelete(add?._id);
                                      }}
                                    >
                                      Delete
                                    </button>
                                    {!add?.isDefault && (
                                      <button
                                        className="btn smallBtn shadow-none font12x400"
                                        onClick={() => {
                                          functions.setDefaultAddress(add?._id);
                                        }}
                                      >
                                        Set as Default
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p>No address found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* edit salon form*/}
      {commonData.modal && commonData.diff === "salon" && (
        <EditSalon
          state={commonData}
          setState={setCommonData}
          apiCall={functions.getSalonById}
        />
      )}

      {/* address form add and update both */}
      {commonData.modal && commonData.diff === "address" && (
        <EditAddressSalon
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
        />
      )}

      {/* delete modal */}
      {isDeleteModalOpen && (
        <DeleteModal
          state={isDeleteModalOpen}
          setState={setIsDeleteModalOpen}
          url={DELETE_USER_ADDRESSES}
          id={id}
          text="Address"
          apiCall={getData}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default Profile;
