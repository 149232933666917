import React from "react";

const InputDate = (props) => {
  const { label, htmlFor, stateData, setStateData, name } = props;
  return (
    <>
      <div className="dashboardInputs">
        <label htmlFor={htmlFor} className="font12x400 form-label mb-0">
          {label}
        </label>
        <div>
          <input
            type="date"
            placeholder="dd/mm/yyyy"
            name={name}
            id={htmlFor}
            value={stateData ?? ""}
            onChange={(e) => {
              setStateData(e.target.value);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default InputDate;
