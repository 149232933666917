import React, { useState } from "react";
import axios from "axios";
import useLoading from "./useLoading";
import { toast } from "react-toastify";
import { authHeader } from "../utils/Constants";

const useFetch = ({ url, resultField, parameter }) => {
  const [fetchData, setfetchData] = useState([]);
  const [totalResult, setTotalResult] = useState(0);
  const [error, setError] = useState("");

  //custom hook for loading
  const [loader, showLoader, hideLoader] = useLoading();

  // const { token } = useAuthContext();
  const token = JSON.parse(localStorage.getItem("token"));

  const logOut = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };

  const getData = async () => {
    setfetchData([]);
    if (token) {
      try {
        showLoader();
        const res = await axios.get(url, {
          params: parameter,
          //headers: authHeader,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });
        if (res.status === 200) {
          setfetchData(res.data[resultField]);
          setTotalResult(res.data.totalResults);
          hideLoader();
        } else {
          console.log("error", res.status);
        }
      } catch (error) {
        console.log("catch error: ", error);
        if (error?.response?.status === 502) {
          toast.error(error?.response?.data?.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          if (error?.response?.data?.message === "invalid algorithm") {
            logOut();
          }
        }
        setError(error.response.data);
      }
    }
  };
  return [fetchData, totalResult, error, loader, getData];
};

export default useFetch;
