import React from "react";
import useAuthContext from "../../hooks/useAuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import { authHeader } from "../../utils/Constants";

const DeleteModal = (props) => {
  const { state, setState, state2, setState2, apiCall, id, url, text } = props;

  const setPositionToBody = () => {
    setState(false);
    document.body.style.overflow = "unset";
  };

  const { token } = useAuthContext();

  const deleteRecord = async () => {
    if (token) {
      try {
        const res = await axios.delete(`${url}${id}`, {
          //headers: authHeader,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });

        if (res.status === 200) {
          setPositionToBody();
          {
            state?.comingFrom === "cart" &&
              setState({
                ...state,
                step: 4,
              });
          }
          apiCall();
          toast.success(`${text} Deleted`, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          console.log("error", res.status);
          // navigate("/login");
        }
      } catch (error) {
        console.log("catch error: ", error);
        if (error.response.status === 502) {
          toast.error(error.response.data.message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state
            ? "deleteModalWrapper deleteModalWrapper-active"
            : "deleteModalWrapper"
        }
      >
        <div className="deleteModalDiv" onClick={setPositionToBody}></div>
        <div className="deleteModalContainer d-flex align-items-center flex-column justify-content-center">
          <p className="mb-4">Are you sure you want to delete?</p>
          <div className="container">
            <div className="row">
              <div className="col-6 text-center">
                <button
                  className="btn addBtn shadow-none"
                  onClick={deleteRecord}
                >
                  Yes
                </button>
              </div>
              <div className="col-6 text-center">
                <button
                  className="btn addBtn shadow-none"
                  onClick={setPositionToBody}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteModal;
