import moment from "moment";
import React, { createContext, useEffect, useState } from "react";
import StarRatings from "react-star-ratings";
import CurrentDateTime from "../../../../Components/Dashboard/CurrentDateTime";
import Layout from "../../../../Components/Layout";
import Loader from "../../../../Components/Loader";
import MobileReviewFilters from "../../../../Components/Mobile/mobileReviewFilter";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import Pagination from "../../../../Components/Pagination";
import ReviewMonitoring from "../../../../Components/ReviewMonitoring";
import { getAddedOnDateTime } from "../../../../Functions/dateTime";
import useFetch from "../../../../hooks/useFetch";
import InputDate from "../../../../Shared Components/InputDate";
import {
  GET_RATING_AND_REVIEWS,
  REVIEWS_RATING_ANALYTICS,
  STYLIST_SEARCH,
} from "../../../../utils/Constants";
import Search from "./Search";

export const ReviewMonitoringContext = createContext();

const ReviewRating = () => {
  const salonNew = JSON.parse(localStorage.getItem("salon_details"));

  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [filters, setFilter] = useState({
    // fromDate: moment().startOf("month").format("YYYY-MM-DD"),
    fromDate: moment(salonNew?.createdAt).format("YYYY-MM-DD"),
    tillDate: moment().endOf("month").format("YYYY-MM-DD"),
  });
  console.log(filters);
  const [analytics, totalResult, error, loader, getData] = useFetch({
    url: `${REVIEWS_RATING_ANALYTICS}${salonNew?._id}/analytics`,
    resultField: "analytics",
    parameter: filters,
  });
  useEffect(() => {
    getData();
  }, [filters]);

  const [filters2, setFilter2] = useState({
    salon: salonNew?._id && salonNew?._id,
    status: 1,
    page: 1,
    per_page: 10,
  });
  console.log(filters2);

  const [param, setParam] = useState({
    show: false,
  });
  console.log("param", param);

  const functions = {
    addParam: () => {
      setFilter2({
        ...filters2,
        ...param,
      });
      setFilter({ ...param, stylist: filters2.stylist });
    },
    resetParam: () => {
      setParam({});
      setFilter({
        fromDate: moment(salonNew?.createdAt).format("YYYY-MM-DD"),
        tillDate: moment().endOf("month").format("YYYY-MM-DD"),
      });
      delete filters2.q;
      delete filters2.fromDate;
      delete filters2.tillDate;
      delete filters2.stylist;
      delete filters2.stylistName;
      delete filters.stylist;
      delete filters.stylistName;
      // delete filters.fromDate;
      // delete filters.tillDate;
      delete filters.show;
      delete filters2.show;
      getData2();
      // getData();
    },
  };
  const [orderReviewsAndRatings, totalResult2, error2, loader2, getData2] =
    useFetch({
      url: `${GET_RATING_AND_REVIEWS}`,
      resultField: "orderReviewsAndRatings",
      parameter: filters2,
    });

  const [stylists, totalResult3, error3, loader3, getStylist] = useFetch({
    url: `${STYLIST_SEARCH}`,
    resultField: "stylists",
    parameter: {
      salon: salonNew?._id,
      f: "name",
    },
  });

  useEffect(() => {
    getData2();
  }, [filters2]);
  useEffect(() => {
    getStylist();
  }, []);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Review & Rating"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileReviewFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          param={param}
          setParam={setParam}
          addParam={functions.addParam}
          resetParam={functions.resetParam}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Review & Rating"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Review & Rating"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper">
        <div className="container ps-lg-0 m-0">
          <div className="row">
            <div className="col-6">
              <div className="desktop">
                <h3 className="font22x500 pageHeading">Review Monitoring</h3>
              </div>
              <div className="mobile mt-3">
                <h3 className="font14x500 pageHeading">Review Monitoring</h3>
              </div>
            </div>
            <div className="col-6 d-flex justify-content-end align-items-center">
              <CurrentDateTime />
            </div>
          </div>
        </div>

        <ReviewMonitoringContext.Provider value={{ analytics, loader }}>
          <ReviewMonitoring />
        </ReviewMonitoringContext.Provider>

        <div className="filter-container container ms-0 ps-0 my-5">
          <div className="row">
            <div className="col-5 d-flex align-items-end">
              <Search
                type="text"
                placeholder="Search by stylist name"
                stateData={filters2?.stylistName}
                setStateData={(e, x) => {
                  setFilter2({
                    ...filters2,
                    stylist: x,
                    stylistName: e,
                  });
                  setFilter({
                    ...filters,
                    stylist: x,
                    stylistName: e,
                  });
                }}
                data={stylists}
              />
            </div>
            <div className="col-12 col-lg-7 d-flex justify-content-lg-end align-items-end">
              <div className="me-3">
                {/* date from */}
                <InputDate
                  label="From"
                  htmlFor="from"
                  type="date"
                  stateData={param?.fromDate}
                  setStateData={(e) => {
                    setParam({
                      ...param,
                      fromDate: moment(e).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </div>

              {/* date to */}
              <InputDate
                label="To"
                htmlFor="to"
                type="date"
                stateData={param?.tillDate}
                setStateData={(e) => {
                  setParam({
                    ...param,
                    tillDate: moment(e).format("YYYY-MM-DD"),
                  });
                }}
              />

              <button
                className="btn filterBtn shadow-none ms-3"
                onClick={functions.addParam}
              >
                Apply
              </button>
              <button
                className="btn resetBtn shadow-none ms-3"
                onClick={functions.resetParam}
              >
                Reset
              </button>
            </div>
          </div>
        </div>

        <div className="mobile container ms-0 ps-0 mt-3">
          <div className="row">
            <div className="col-8 px-4">
              <Search
                type="text"
                placeholder="Search by stylist name"
                stateData={filters2?.stylistName}
                setStateData={(e, x) => {
                  setFilter2({
                    ...filters2,
                    stylist: x,
                    stylistName: e,
                  });
                  setFilter({
                    ...filters,
                    stylist: x,
                    stylistName: e,
                  });
                }}
                data={stylists}
              />
            </div>
          </div>
        </div>

        {orderReviewsAndRatings?.length !== 0 ? (
          <>
            <div className="container desktop dataTable ms-0 ps-0">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">SL.No.</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col" style={{ maxWidth: "40%" }}>
                      Reviews
                    </th>
                    <th scope="col">Ratings</th>
                    <th scope="col">Stylist Name</th>
                    <th scope="col">Added On</th>
                  </tr>
                </thead>
                <tbody>
                  {orderReviewsAndRatings?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item?.user?.name}</td>
                        <td style={{ paddingRight: "40px !important" }}>
                          {item?.review}
                        </td>
                        <td>
                          <StarRatings
                            rating={item?.rating}
                            starEmptyColor="#a6a6a6"
                            starRatedColor="#FCC153"
                            starDimension="22px"
                            numberOfStars={5}
                            starSpacing="2px"
                          />
                        </td>
                        <td>{item?.stylist?.name}</td>
                        <td>{getAddedOnDateTime(item?.createdAt)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="container mobile dataTable ms-0 ps-0">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Stylist Name</th>
                    <th scope="col">Ratings</th>
                    <th scope="col" style={{ maxWidth: "40%" }}>
                      Reviews
                    </th>
                    <th scope="col">Added On</th>
                  </tr>
                </thead>
                <tbody>
                  {orderReviewsAndRatings?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item?.user?.name}</td>
                        <td>{item?.stylist?.name}</td>
                        <td>
                          <StarRatings
                            rating={item?.rating}
                            starEmptyColor="#a6a6a6"
                            starRatedColor="#FCC153"
                            starDimension="22px"
                            numberOfStars={5}
                            starSpacing="2px"
                          />
                        </td>
                        <td style={{ paddingRight: "40px !important" }}>
                          {item?.review}
                        </td>
                        <td>
                          {moment(item?.createdAt).format("DD-MMM-YYYY")}
                          <br />
                          {moment(item?.createdAt).format("HH:mm")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader2 ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Reviews Found"
            )}
          </div>
        )}

        {orderReviewsAndRatings?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters2.page}
              onPageChange={(e) => {
                setFilter2({
                  ...filters2,
                  page: e,
                });
              }}
              pageSize={filters2.per_page}
              totalCount={Math.ceil(totalResult2 / filters2.per_page)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ReviewRating;
