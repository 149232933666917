import React from "react";
import Chart from "../../Chart";

const MultipleLineCharts = (props) => {
  const { label, statsData } = props;

  return (
    <>
      <div className="col-12 col-lg-6">
        <div className="cardWrapper">
          <div className="cardWrapperHeader d-flex justify-content-between align-items-center">
            <h3 className="font18x500 mb-0">{label}</h3>
          </div>
          <div className="cardContent">
            <div className="row">
              <div className="col-12">
                <Chart statsData={statsData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultipleLineCharts;
