import React, { useEffect, useState } from "react";
import { Line } from "@ant-design/plots";
import moment from "moment";

const SingleLineChart = ({ activeUser }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([]);
    activeUser?.map((item, i) => {
      setData((old) => {
        return [
          ...old,
          {
            date: moment(item?.date, "YYYY-MM-DD").format("DD MMM"),
            value: item?.clicks,
            name: "Clicks",
          },
        ];
      });
    });
  }, [activeUser]);

  const config = {
    data,
    height: 300,
    xField: "date",
    yField: "value",
    seriesField: "name",
    color: ["#97c1a9"],
    legend: {
      position: "bottom",
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 3000,
      },
    },
  };

  return (
    <>
      <Line {...config} />
    </>
  );
};

export default SingleLineChart;
