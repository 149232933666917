import React from "react";
import { BsWhatsapp } from "react-icons/bs";

const WhatsappBtn = (props) => {
  const { salonMobile, orderId, customerMobile, serviceType } = props;

  const sendMessage = () => {
    const msg = `Your *GetLook Unisex Salon* service is completed.%0A%0AYou can view your invoice @ ${process.env.REACT_APP_CUSTOMER_BASE_URL}/${serviceType}/orderdetails/${orderId} and rate the stylist.%0A%0AYou can reach out to us for future appointments on the same chat.%0A%0AAlternatively you can call us at ${salonMobile} for appointments.`;
    window.open(
      `//api.whatsapp.com/send?phone=91${customerMobile}&text=${msg}`
    );
  };

  return (
    <>
      <button
        onClick={() => {
          sendMessage();
        }}
      >
        <BsWhatsapp size={23} color="var(--status_color3)" />
      </button>
    </>
  );
};

export default WhatsappBtn;
