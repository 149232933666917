import React, { useEffect, useState } from "react";
import InputError from "../../../Shared Components/InputError";
import InputFieldLarge from "../../../Shared Components/InputFieldLarge";
import StaticDropdown from "../../../Shared Components/StaticDropdown";
import axios from "axios";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { membershipSchema } from "../../../utils/validationschema";
import useLoading from "../../../hooks/useLoading";
import useAuthContext from "../../../hooks/useAuthContext";
import {
  authHeader,
  CREATE_MEMBERSHIP,
  MEMBERSHIP_UPDATE,
} from "../../../utils/Constants";
import ContentEditor from "../Dynamic Page/ContentEditor";
import SubmitButton from "../../../Shared Components/SubmitButton";
import UpdationDetails from "../../../Shared Components/UpdationDetails";

const MembershipForm = ({ state, setState, apiCall }) => {
  const initialValues = {
    name: state?.data?.name ?? "",
    price: state?.data?.price ?? "",
    validity: state?.data?.validity ?? 0,
    serviceDiscount: state?.data?.serviceDiscount ?? 0,
    productDiscount: state?.data?.productDiscount ?? 0,
    features: state?.data?.features ?? "",
    statusName: state?.data?.status === 1 ? "Active" : "Inactive",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: membershipSchema,
    onSubmit: (data) => {
      formSubmit(data);
      //   console.log("data: ", data);
    },
  });

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const { token } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();

  const formSubmit = async (data) => {
    //form data
    const sFormData = new FormData();
    sFormData.append("name", data.name);
    sFormData.append("price", data.price);
    sFormData.append("validity", data.validity);
    sFormData.append("serviceDiscount", data.serviceDiscount);
    sFormData.append("productDiscount", data.productDiscount);
    sFormData.append("features", data.features);
    sFormData.append("status", data.statusName === "Active" ? 1 : 2);

    if (token) {
      if (state.data === undefined) {
        try {
          showLoader();
          const res = await axios.post(`${CREATE_MEMBERSHIP}`, sFormData, {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Membership Added", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      } else {
        try {
          showLoader();
          const res = await axios.put(
            `${MEMBERSHIP_UPDATE}${state.data._id}/form`,
            sFormData,
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            apiCall();
            hideLoader();
            setPositionToBody();
            toast.success("Membership Updated", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    }
  };

  return (
    <>
      <div className={state ? "formWrapper formWrapper-active" : "formWrapper"}>
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">
              {!state?.data ? "Create Membership" : "Edit Membership"}
            </h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit}>
              {/* Package Name* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Package Name*"
                  htmlFor="pName"
                  type="text"
                  name="name"
                  stateData={formik.values.name}
                  setStateData={formik.handleChange}
                />
                {formik.errors.name ? (
                  <InputError error={formik.errors.name} />
                ) : null}
              </div>

              {/* Price* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Price*"
                  htmlFor="price"
                  type="tel"
                  name="price"
                  stateData={formik.values.price}
                  setStateData={formik.handleChange}
                />
                {formik.errors.price ? (
                  <InputError error={formik.errors.price} />
                ) : null}
              </div>

              {/* Validity* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Validity (Days)*"
                  htmlFor="validity"
                  type="tel"
                  name="validity"
                  stateData={formik.values.validity}
                  setStateData={formik.handleChange}
                />
                {formik.errors.validity ? (
                  <InputError error={formik.errors.validity} />
                ) : null}
              </div>

              {/* Service Discount* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Service Discount*"
                  htmlFor="sDiscount"
                  type="tel"
                  name="serviceDiscount"
                  stateData={formik.values.serviceDiscount}
                  setStateData={formik.handleChange}
                />
                {formik.errors.serviceDiscount ? (
                  <InputError error={formik.errors.serviceDiscount} />
                ) : null}
              </div>

              {/* product Discount* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Product Discount*"
                  htmlFor="pDiscount"
                  type="tel"
                  name="productDiscount"
                  stateData={formik.values.productDiscount}
                  setStateData={formik.handleChange}
                />
                {formik.errors.productDiscount ? (
                  <InputError error={formik.errors.productDiscount} />
                ) : null}
              </div>

              {/* text editor */}
              <div className="mb-3">
                <label htmlFor="desc" className="font12x500 form-label mb-1">
                  Features*
                </label>
                <ContentEditor
                  stateData={formik.values.features}
                  setStateData={(e) => {
                    formik.setFieldValue("features", e);
                  }}
                />
                {formik.errors.features ? (
                  <InputError error={formik.errors.features} />
                ) : null}
              </div>

              {/* status */}
              <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: "Active", label: "Active" },
                    { value: "Inactive", label: "Inactive" },
                  ]}
                  label="Status*"
                  htmlFor="status"
                  type="text"
                  placeholder="Select Status"
                  name="statusName"
                  stateData={formik.values.statusName}
                  setStateData={(e) => {
                    formik.setFieldValue("statusName", e);
                  }}
                />
                {formik.errors.statusName ? (
                  <InputError error={formik.errors.statusName} />
                ) : null}
              </div>

              <SubmitButton
                data={!state.data}
                loader={loader}
                create="Create Membership"
                update="Update Membership"
              />

              {state?.data && <UpdationDetails data={state?.data} />}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default MembershipForm;
