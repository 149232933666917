import axios from "axios";
import React, { useEffect, useState } from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleTime } from "../../Functions/other";
import useAuthContext from "../../hooks/useAuthContext";
import useLoading from "../../hooks/useLoading";
import {
  authHeader,
  CHILD_SALON,
  LOGIN,
  VERIFY_CHILD_SALON,
} from "../../utils/Constants";
import Loader from "../Loader";

const inputs = Array(4).fill("");

const VerifyProcess = ({ state, setState }) => {
  const [inputOTP, setInputOTP] = useState("");
  const [OTP, setOTP] = useState(state?.otp);
  const [loader, showLoader, hideLoader] = useLoading();
  const { token, salonDetails } = useAuthContext();
  const navigate = useNavigate();

  //otp expireTime in seconds
  const [expireTime, setExpireTime] = useState(300);
  const [time, setTime] = useState("00:00");
  useEffect(() => {
    if (expireTime !== 0) {
      setTime(handleTime(expireTime));
      setTimeout(() => {
        setExpireTime(expireTime - 1);
      }, 1000);
    }
  }, [expireTime]);

  const functions = {
    handleKeyBoardFocus: (e) => {
      if ((e.keyCode === 8 || e.keyCode === 46) && e.target.value === "") {
        if (
          e.target &&
          e.target.parentElement &&
          e.target.parentElement.previousElementSibling &&
          e.target.parentElement.previousElementSibling.children[0]
        ) {
          e.target.parentElement.previousElementSibling.children[0].focus();
        }
      }
    },
    handleOTP: (e) => {
      if (e.target.value.length === 1) {
        if (
          e.target &&
          e.target.parentElement &&
          e.target.parentElement.nextElementSibling &&
          e.target.parentElement.nextElementSibling.children[0]
        ) {
          e.target.parentElement.nextElementSibling.children[0].focus();
        }
      }

      let otpNumber = "";
      const inputsValue = document.getElementsByClassName("otpField");
      for (const e of inputsValue) {
        otpNumber += e.value;
      }
      setInputOTP(otpNumber);
    },
    formSubmit: async (e) => {
      e.preventDefault();

      if (token) {
        try {
          showLoader();
          const res = await axios.post(
            `${VERIFY_CHILD_SALON}${salonDetails?._id}/user/verify-otp`,
            {
              mobile: state.mobile,
              otp: inputOTP,
              hash: state.hash,
              role: CHILD_SALON,
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            hideLoader();
            toast.success(res.data.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
            navigate("/registration", {
              state: {
                user: res.data.user,
                mobile: state.mobile,
                masterSalonId: salonDetails?._id,
              },
            });
          }
        } catch (err) {
          console.log("error: ", err);
          if (err.response.status === 502) {
            hideLoader();
            toast.error(err.response.data.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
    resendOtp: async () => {
      try {
        showLoader();
        const res = await axios.post(
          LOGIN,
          {
            mobile: state?.mobile,
          },
          {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );

        if (res.status === 200) {
          setOTP(res.data.otp);
          setExpireTime(300);
          hideLoader();
        }
      } catch (err) {
        console.log("error: ", err);
        if (err.response.status === 502) {
          hideLoader();
          toast.error(err.response.data.message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    },
  };

  console.log("OTP:", OTP);

  return (
    <>
      <div
        className={
          state?.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv"></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">Verify OTP</h1>
            <span
              onClick={() => {
                setState({
                  ...state,
                  step: 1,
                });
              }}
            >
              <HiOutlineArrowLeft />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={functions.formSubmit}>
              <div className="row">
                <div className="col-12 mb-4 d-flex justify-content-center">
                  {inputs.map((item, index) => {
                    return (
                      <div className="otpInputs me-2" key={index}>
                        <input
                          type="tel"
                          className="otpField form-control shadow-none"
                          maxLength={1}
                          onKeyDown={functions.handleKeyBoardFocus}
                          onChange={functions.handleOTP}
                        />
                      </div>
                    );
                  })}
                </div>

                <div className="d-flex justify-content-between">
                  <p>The OTP has been sent to {state?.mobile}</p>
                  {expireTime === 0 ? (
                    <p
                      className="resendOtp font14x500"
                      onClick={functions.resendOtp}
                    >
                      Resend OTP
                    </p>
                  ) : (
                    <p>Resend OTP in {time}</p>
                  )}
                </div>
              </div>

              <button
                type="submit"
                className="btn OtpBtn shadow-none font16x500"
              >
                {loader ? (
                  <div className="d-flex justify-content-center">
                    <Loader height="25" width="25" color="#fff" />
                  </div>
                ) : (
                  "Proceed"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyProcess;
