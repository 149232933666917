import React from "react";

const Amenities = ({ value, check, setRegistrationData }) => {
  const toggleAmenities = () => {
    setRegistrationData(check ? false : true);
  };

  return (
    <>
      <button
        type="button"
        className={`${
          check ? "slotTimePicker slotTimePickerSelected" : "slotTimePicker"
        }`}
        value={value}
        onClick={() => toggleAmenities()}
      >
        {value}
      </button>
    </>
  );
};

export default Amenities;
