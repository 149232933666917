import React from "react";
import { CaretDownOutlined } from "@ant-design/icons";
import { TimePicker } from "antd";
import moment from "moment";

const Time = (props) => {
  const {
    day,
    setSelectedDay,
    setSelectedTime,
    time,
    setTime,
    setIndex,
    selectedTime,
    i,
  } = props;

  const onChange = (time, timeString) => {
    console.log(time, timeString);
  };
  //HH - 24 hours
  //hh - 12 hours
  return (
    <TimePicker
      use12Hours
      onChange={onChange}
      minuteStep={30}
      format="h:mm A"
      showNow={false}
      value={moment(selectedTime, "h:mm A")}
      suffixIcon={<CaretDownOutlined />}
      onSelect={(value) => {
        const timeString = moment(value).format("h:mm A");
        setSelectedDay(day);
        setSelectedTime(timeString);
        setIndex(i);
        if (time === "opening_time") setTime("opening_time");
        if (time === "closing_time") setTime("closing_time");
      }}
      popupClassName="timePicker"
    />
  );
};

export default Time;
