import React from "react";
import { useFormik } from "formik";
import { CgClose } from "react-icons/cg";
import { mobileSchema } from "../../../../utils/validationschema";
import InputFieldLarge from "../../../../Shared Components/InputFieldLarge";
import InputError from "../../../../Shared Components/InputError";
import Loader from "../../../Loader";
import useLoading from "../../../../hooks/useLoading";
import { toast } from "react-toastify";
import axios from "axios";
import useAuthContext from "../../../../hooks/useAuthContext";
import { authHeader, LOGIN_AUTH } from "../../../../utils/Constants";

const LoginProcess = ({ state, setState }) => {
  const initialValues = {
    mobileNumber: "",
  };
  const [loader, showLoader, hideLoader] = useLoading();
  const { token } = useAuthContext();

  const formik = useFormik({
    initialValues,
    validationSchema: mobileSchema,
    onSubmit: (data) => {
      //   console.log("data", data);
      functions.formSubmit(data);
    },
  });

  const functions = {
    setPositionToBody: () => {
      setState({
        ...state,
        modal: false,
        step: 0,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
    formSubmit: async (data) => {
      if (token) {
        try {
          showLoader();
          const res = await axios.post(
            LOGIN_AUTH,
            {
              mobile: data.mobileNumber,
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            hideLoader();
            setState({
              modal: true,
              step: 2,
              check: "verify-process",
              otp: res?.data?.otp ? res?.data?.otp : "",
              hash: res?.data?.hash,
              mobile: formik.values.mobileNumber,
            });
          }
        } catch (err) {
          console.log("error: ", err);
          if (err.response.status === 502) {
            hideLoader();
            toast.error(err.response.data.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
  };

  return (
    <>
      <div
        className={
          state?.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv"></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">Registration Process</h1>
            <span onClick={functions.setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit}>
              {/* mobileNumber */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Mobile Number*"
                  htmlFor="mobileNumber"
                  type="tel"
                  name="mobileNumber"
                  stateData={formik.values.mobileNumber}
                  setStateData={formik.handleChange}
                />
                {formik.errors.mobileNumber ? (
                  <InputError error={formik.errors.mobileNumber} />
                ) : null}
              </div>

              <button
                type="submit"
                className="btn OtpBtn shadow-none font16x500"
              >
                {loader ? (
                  <div className="d-flex justify-content-center">
                    <Loader height="25" width="25" color="#fff" />
                  </div>
                ) : (
                  "Get OTP"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginProcess;
