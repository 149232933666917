import React from "react";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { stockSchema } from "../../utils/validationschema";
import InputFieldLarge from "../../Shared Components/InputFieldLarge";
import InputError from "../../Shared Components/InputError";
import StaticDropdown from "../../Shared Components/StaticDropdown";
import SubmitButton from "../../Shared Components/SubmitButton";
import UpdationDetails from "../../Shared Components/UpdationDetails";
import Loader from "../Loader";
import useLoading from "../../hooks/useLoading";
import { authHeader, UPDATE_STOCK } from "../../utils/Constants";
import axios from "axios";
import useAuthContext from "../../hooks/useAuthContext";

const UpdateStockForm = ({ state, setState, apiCall, id }) => {
  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
      check: "",
    });
    document.body.style.overflow = "unset";
  };

  const initialValues = {
    qty: "",
    qtyStatus: "",
    comment: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: stockSchema,
    onSubmit: (data) => {
      formSubmit(data);
      //   console.log("data", data);
    },
  });
  const [loader, showLoader, hideLoader] = useLoading();
  const { token } = useAuthContext();

  const formSubmit = async (data) => {
    if (token) {
      try {
        showLoader();
        console.log("update api called");
        const res = await axios.post(
          `${UPDATE_STOCK}`,
          {
            product: state?.data?._id,
            productId: state?.data?.productId,
            name: state?.data?.name,
            salon: id,
            qty: Number(data?.qty),
            qtyStatus: data?.qtyStatus,
            comment: data?.comment,
            price: state?.data?.price,
            totalCost: Number(data?.qty) * state?.data?.price,
          },
          {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );

        if (res.status === 200) {
          setPositionToBody();
          apiCall();
          hideLoader();
          toast.success("Stock Updated", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          console.log("else error: ", res.status);
        }
      } catch (error) {
        console.log("catch error: ", error);
        if (error?.response?.status === 502) {
          hideLoader();
          toast.error(error?.response?.data?.message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">Stock Update</h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit}>
              {/* stock*/}
              <div className="mb-3">
                <InputFieldLarge
                  label="Stock Count*"
                  htmlFor="stock"
                  type="tel"
                  name="qty"
                  stateData={formik.values.qty}
                  setStateData={formik.handleChange}
                />
                {formik.errors.qty ? (
                  <InputError error={formik.errors.qty} />
                ) : null}
              </div>

              {/* status */}
              <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: "IN", label: "In" },
                    { value: "OUT", label: "Out" },
                  ]}
                  label="In/Out*"
                  htmlFor="status"
                  type="text"
                  placeholder="Select Status"
                  name="qtyStatus"
                  stateData={formik.values.qtyStatus}
                  setStateData={(e) => {
                    formik.setFieldValue("qtyStatus", e);
                  }}
                />
                {formik.errors.qtyStatus ? (
                  <InputError error={formik.errors.qtyStatus} />
                ) : null}
              </div>

              {/* service description */}
              <div className="mb-3">
                <label
                  htmlFor="floatingTextarea2"
                  className="font12x500 mb-1 form-label"
                >
                  Comment*
                </label>
                <textarea
                  className="form-control shadow-none"
                  id="floatingTextarea2"
                  style={{ height: "180px" }}
                  name="comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                />
                {formik.errors.comment ? (
                  <InputError error={formik.errors.comment} />
                ) : null}
              </div>

              <button
                type="submit"
                className="btn OtpBtn shadow-none font16x500"
              >
                {loader ? (
                  <div className="d-flex justify-content-center">
                    <Loader height="25" width="25" color="#fff" />
                  </div>
                ) : (
                  "Update"
                )}
              </button>
            </form>

            {/* {state?.data && <UpdationDetails data={state?.data} />} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateStockForm;
