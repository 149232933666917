import React, { useState, useEffect } from "react";
import useAuthContext from "../../../hooks/useAuthContext";
import {
  ADMIN,
  authHeader,
  MASTER_SALON,
  MASTER_SALON_USER,
  SALON_BY_ID,
  serviceAtSalonSteps,
  UPDATE_ORDER_STATUS,
} from "../../../utils/Constants";
import { toast } from "react-toastify";
import axios from "axios";
import { AiOutlineClockCircle } from "react-icons/ai";
import {
  getAddedOnDate,
  getAddedOnDateTime,
} from "../../../Functions/dateTime";
import DynamicDropdown from "../OrderProduct/DynamicDropdown";
import DynamicDropdown2 from "./DynamicDropdown";
import StarRatings from "react-star-ratings";
import Loader from "../../Loader";
import useLoading from "../../../hooks/useLoading";
import AssignStylistModal from "./AssignStylistModal";
import serviceImage from "../../../uploads/service/service1.jpg";
import PaymentModeSelectionAtSalon from "./PaymentModeSelectionAtSalon";

const setValueAtSalon = (status) => {
  switch (status) {
    case "1":
      return "confirmed";
    case "3":
      return "started";
    case "4":
      return "completed";
    case "5":
      return "cancelled";
    default:
      return "pending";
  }
};

const Summary = ({ orderDetails, apiCall, stylistDetails }) => {
  const [commonData, setCommonData] = useState({
    check: "",
    modal: false,
    data: undefined,
  });

  console.log("commonData", commonData);

  const [orderStatus, setOrderStatus] = useState({
    status: setValueAtSalon(
      orderDetails?.serviceOrders?.servicesProcessingStatus
    ),
    number: orderDetails?.serviceOrders?.servicesProcessingStatus,
  });
  console.log("orderStatus: ", orderStatus);

  useEffect(() => {
    if (
      orderStatus.number === 3 &&
      orderDetails?.serviceOrders?.paymentMode === "COD"
    ) {
      setCommonData({
        check: "paymentMode",
        modal: true,
        data: {
          _id: orderDetails?._id,
          statusNumber: orderStatus.number,
          actualStatus: orderDetails?.serviceOrders?.servicesProcessingStatus,
          stylist: orderDetails?.serviceOrders?.stylist,
        },
      });
      document.body.style.overflow = "hidden";
    }
  }, [orderStatus]);

  const [stylist, setStylist] = useState({
    id: orderDetails?.serviceOrders?.stylist?._id,
    name: orderDetails?.serviceOrders?.stylist?.name,
    modal: false,
  });

  const { role, salonDetails, token } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();

  const functions = {
    changedStatus: async (e) => {
      e.preventDefault();
      if (orderDetails?.serviceOrders?.stylist || orderStatus.number === 4) {
        let backendStatus =
          Number(orderDetails?.serviceOrders?.servicesProcessingStatus) > 2
            ? Number(orderDetails?.serviceOrders?.servicesProcessingStatus) - 1
            : Number(orderDetails?.serviceOrders?.servicesProcessingStatus);

        if (
          //USE THIS WHEN YOU WANT STATUS IS CHANGED STEP BY STEP
          // orderStatus.number - 1 === backendStatus ||
          // orderStatus.number === 4

          //USE THIS WHEN YOU WANT STATUS IS NOT CHANGED STEP BY STEP
          orderStatus.number > backendStatus
        ) {
          if (token) {
            try {
              showLoader();
              const res = await axios.put(
                `${UPDATE_ORDER_STATUS}${orderDetails?._id}/service`,
                {
                  //   servicesProcessingStatus: orderStatus.number.toString(),
                  servicesProcessingStatus:
                    orderStatus.number > 1
                      ? (orderStatus.number + 1).toString()
                      : orderStatus.number.toString(),
                },
                {
                  //headers: authHeader,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                    "X-Requested-With": "XMLHttpRequest",
                  },
                }
              );
              if (res.status === 200) {
                apiCall();
                hideLoader();
                updateSalon();
                toast.success("Status Changed", {
                  position: "bottom-center",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                });
              } else {
                console.log("else error: ", res.status);
              }
            } catch (error) {
              console.log("catch error: ", error);
              if (error?.response?.status === 502) {
                hideLoader();
                toast.error(error?.response?.data?.message, {
                  position: "bottom-center",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                });
              }
            }
          }
        } else {
          //USE THIS WHEN YOU WANT STATUS IS CHANGED STEP BY STEP

          // toast.warn("Changed Status Step By Step", {
          //   position: "bottom-center",
          //   autoClose: 1000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          // });

          //USE THIS WHEN YOU WANT STATUS IS NOT CHANGED STEP BY STEP
          toast.warn("You can't go backward", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      } else {
        toast.warn("Please Assign Stylist First", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    },
    setPositionToBody: (e, x) => {
      setStylist({
        id: x,
        name: e,
        modal: true,
      });
      document.body.style.overflow = "hidden";
    },
    walletBreak: (w) => {
      let wallet = w;
      if (orderDetails?.productOrders) {
        wallet = wallet / 2;
        return wallet?.toFixed(2);
      } else {
        return wallet?.toFixed(2);
      }
    },
  };

  const updateSalon = async () => {
    if (token) {
      try {
        showLoader();
        const res = await axios.get(`${SALON_BY_ID}${salonDetails?._id}`, {
          //headers: authHeader,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });

        if (res.status === 200) {
          window.localStorage.setItem(
            "salon_details",
            JSON.stringify(res?.data?.salon)
          );
          hideLoader();
        } else {
          console.log("error", res.status);
        }
      } catch (error) {
        console.log("catch error: ", error);
        if (error.response.status === 502) {
          hideLoader();
          toast.error(error.response.data.message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    }
  };

  return (
    <>
      <div className="container ms-0 ps-0">
        <div className="row gap-lg-0 gap-4">
          {/* order status */}
          <div className="col-lg-4 col-12">
            <div className="cardWrapper">
              <div className="cardWrapperHeader">
                <h3 className="font22x500 mb-0">{`${
                  (role === ADMIN ||
                    role === MASTER_SALON ||
                    role === MASTER_SALON_USER) &&
                  salonDetails
                    ? "Update order status"
                    : "Order Status"
                }`}</h3>
              </div>

              <div className="cardContent">
                {(role === ADMIN ||
                  role === MASTER_SALON ||
                  role === MASTER_SALON_USER) &&
                salonDetails ? (
                  <>
                    <div className="w-100">
                      <DynamicDropdown
                        data={serviceAtSalonSteps.slice(1)}
                        htmlFor="Order Status"
                        type="text"
                        name="orderStatus"
                        placeholder="Select Order Status"
                        stateData={orderStatus.status}
                        setStateData={(e, x) => {
                          setOrderStatus({
                            status: e,
                            number: x,
                          });
                        }}
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn OtpBtn shadow-none mt-3"
                      style={{ width: "30%" }}
                      onClick={functions.changedStatus}
                      disabled={
                        parseInt(
                          orderDetails?.serviceOrders?.servicesProcessingStatus
                        ) >= 4 && true
                      }
                    >
                      {loader ? (
                        <div className="d-flex justify-content-center">
                          <Loader height="25" width="25" color="#fff" />
                        </div>
                      ) : (
                        "Change"
                      )}
                    </button>
                  </>
                ) : (
                  <div className="d-flex justify-content-between">
                    <span
                      className="font14x500"
                      style={{ color: "var(--text4)" }}
                    >
                      Status
                    </span>
                    <span className="font14x500 text-capitalize">
                      {setValueAtSalon(
                        orderDetails?.serviceOrders?.servicesProcessingStatus
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Assign Stylist */}
          <div
            className="col-lg-4 col-12"
            style={{
              pointerEvents:
                Number(
                  orderDetails?.serviceOrders?.servicesProcessingStatus
                ) === 5 && "none",
              cursor:
                Number(
                  orderDetails?.serviceOrders?.servicesProcessingStatus
                ) === 5 && "no-drop",
            }}
          >
            <div className="cardWrapper">
              <div className="cardWrapperHeader">
                <h3 className="font22x500 mb-0">{`${
                  (role === ADMIN ||
                    role === MASTER_SALON ||
                    role === MASTER_SALON_USER) &&
                  salonDetails
                    ? "Assign Stylist"
                    : "Stylist Details"
                }`}</h3>
              </div>
              <div className="cardContent">
                {(role === ADMIN ||
                  role === MASTER_SALON ||
                  role === MASTER_SALON_USER) &&
                  salonDetails && (
                    <div className="w-100 mb-3">
                      <DynamicDropdown2
                        data={stylistDetails}
                        htmlFor="stylist"
                        type="text"
                        name="stylist"
                        placeholder="Select Stylist"
                        stateData={stylist.name}
                        setStateData={(e, x) => {
                          functions.setPositionToBody(e, x);
                        }}
                      />
                    </div>
                  )}

                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Name
                  </span>
                  <span className="font14x500">
                    {orderDetails?.serviceOrders?.stylist?.name ?? "--"}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Phone Number
                  </span>
                  <span className="font14x500">
                    {orderDetails?.serviceOrders?.stylist?.mobile ?? "--"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* status summary */}
          <div className="col-lg-4 col-12">
            <div className="cardWrapper">
              <div className="cardWrapperHeader">
                <h3 className="font22x500 mb-0">Status Summary</h3>
              </div>
              <div className="cardContent">
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Confirmed
                  </span>
                  {orderDetails?.length !== 0 && (
                    <span className="font14x500">
                      {orderDetails?.serviceOrders
                        ?.servicesStatusTimestamps[1] &&
                      orderDetails?.serviceOrders
                        ?.servicesStatusTimestamps[1] !== null
                        ? getAddedOnDateTime(
                            orderDetails?.serviceOrders
                              ?.servicesStatusTimestamps[1]
                          )
                        : "--"}
                    </span>
                  )}
                </div>

                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Started
                  </span>
                  {orderDetails?.length !== 0 && (
                    <span className="font14x500">
                      {orderDetails?.serviceOrders
                        ?.servicesStatusTimestamps[3] &&
                      orderDetails?.serviceOrders
                        ?.servicesStatusTimestamps[3] !== null
                        ? getAddedOnDateTime(
                            orderDetails?.serviceOrders
                              ?.servicesStatusTimestamps[3]
                          )
                        : "--"}
                    </span>
                  )}
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Completed
                  </span>
                  {orderDetails?.length !== 0 && (
                    <span className="font14x500">
                      {orderDetails?.serviceOrders
                        ?.servicesStatusTimestamps[4] &&
                      orderDetails?.serviceOrders
                        ?.servicesStatusTimestamps[4] !== null
                        ? getAddedOnDateTime(
                            orderDetails?.serviceOrders
                              ?.servicesStatusTimestamps[4]
                          )
                        : "--"}
                    </span>
                  )}
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Cancelled
                  </span>
                  {orderDetails?.length !== 0 && (
                    <span className="font14x500">
                      {orderDetails?.serviceOrders
                        ?.servicesStatusTimestamps[5] &&
                      orderDetails?.serviceOrders
                        ?.servicesStatusTimestamps[5] !== null
                        ? getAddedOnDateTime(
                            orderDetails?.serviceOrders
                              ?.servicesStatusTimestamps[5]
                          )
                        : "--"}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* customer details */}
          <div className="col-lg-7 col-12 g-lg-4">
            <div className="cardWrapper">
              <div className="cardWrapperHeader">
                <h3 className="font22x500 mb-0">Customer Details</h3>
              </div>
              <div className="cardContent">
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Customer Name
                  </span>
                  <span className="font14x500 text-capitalize">
                    {orderDetails?.user?.name}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Email Id
                  </span>
                  <span className="font14x500">
                    {orderDetails?.user?.email !== ""
                      ? orderDetails?.user?.email
                      : "--"}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Phone Number
                  </span>
                  <span className="font14x500">
                    {orderDetails?.user?.mobile}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Appointment On
                  </span>
                  <span className="font14x500">
                    {orderDetails?.serviceOrders?.slotTime},{" "}
                    {getAddedOnDate(orderDetails?.serviceOrders?.slotDate)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* payment summary */}
          <div
            className="col-lg-5 col-12 g-lg-4"
            style={{ height: "fit-content" }}
          >
            <div className="cardWrapper">
              <div className="cardWrapperHeader">
                <h3 className="font22x500 mb-0">Payment Summary</h3>
              </div>
              <div className="cardContent">
                {/* <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Payment Method
                  </span>
                  <span className="font14x500">COD</span>
                </div> */}
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Subtotal (inclusive tax):
                  </span>
                  {orderDetails?.salon?.country?.htmlSymbol !== "" ? (
                    <span className="font14x500">{`${String.fromCharCode(
                      orderDetails?.salon?.country?.htmlSymbol
                    )} ${
                      orderDetails?.cartCalculations?.services?.total
                    }`}</span>
                  ) : (
                    <span className="font14x500">{`${orderDetails?.salon?.country?.currency} ${orderDetails?.cartCalculations?.services?.total}`}</span>
                  )}
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Discount
                  </span>
                  {orderDetails?.salon?.country?.htmlSymbol !== "" ? (
                    <span className="font14x500">{`${String.fromCharCode(
                      orderDetails?.salon?.country?.htmlSymbol
                    )} ${
                      orderDetails?.cartCalculations?.services?.discount
                    }`}</span>
                  ) : (
                    <span className="font14x500">{`${orderDetails?.salon?.country?.currency} ${orderDetails?.cartCalculations?.services?.discount}`}</span>
                  )}
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Coupon Discount
                  </span>
                  {orderDetails?.salon?.country?.htmlSymbol !== "" ? (
                    <span className="font14x500">{`${String.fromCharCode(
                      orderDetails?.salon?.country?.htmlSymbol
                    )} ${orderDetails?.cartCalculations?.services?.couponDiscount?.toFixed(
                      2
                    )}`}</span>
                  ) : (
                    <span className="font14x500">{`${
                      orderDetails?.salon?.country?.currency
                    } ${orderDetails?.cartCalculations?.services?.couponDiscount?.toFixed(
                      2
                    )}`}</span>
                  )}
                </div>
                {role === ADMIN &&
                  orderDetails?.cartCalculations?.services
                    ?.servicesMembershipDiscount &&
                  orderDetails?.cartCalculations?.services
                    ?.servicesMembershipDiscount > 0 && (
                    <div className="d-flex justify-content-between">
                      <span
                        className="font14x500"
                        style={{ color: "var(--text4)" }}
                      >
                        Membership Discount
                      </span>
                      {orderDetails?.salon?.country?.htmlSymbol !== "" ? (
                        <span className="font14x500">{`${String.fromCharCode(
                          orderDetails?.salon?.country?.htmlSymbol
                        )} ${orderDetails?.cartCalculations?.services?.servicesMembershipDiscount?.toFixed(
                          2
                        )}`}</span>
                      ) : (
                        <span className="font14x500">{`${
                          orderDetails?.salon?.country?.currency
                        } ${orderDetails?.cartCalculations?.services?.servicesMembershipDiscount?.toFixed(
                          2
                        )}`}</span>
                      )}
                    </div>
                  )}
                <hr />
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Total Payable Amount
                  </span>
                  {orderDetails?.salon?.country?.htmlSymbol !== "" ? (
                    <span className="font14x500">{`${String.fromCharCode(
                      orderDetails?.salon?.country?.htmlSymbol
                    )} ${orderDetails?.cartCalculations?.services?.grandTotal?.toFixed(
                      2
                    )}`}</span>
                  ) : (
                    <span className="font14x500">{`${
                      orderDetails?.salon?.country?.currency
                    } ${orderDetails?.cartCalculations?.services?.grandTotal?.toFixed(
                      2
                    )}`}</span>
                  )}
                </div>
                {role === ADMIN &&
                  orderDetails?.cartCalculations?.services?.commision > 0 && (
                    <div className="d-flex justify-content-between">
                      <span
                        className="font14x500"
                        style={{ color: "var(--text4)" }}
                      >
                        Groome Commision
                      </span>
                      {orderDetails?.salon?.country?.htmlSymbol !== "" ? (
                        <span className="font14x500">{`${String.fromCharCode(
                          orderDetails?.salon?.country?.htmlSymbol
                        )} ${orderDetails?.cartCalculations?.services?.commision?.toFixed(
                          2
                        )}`}</span>
                      ) : (
                        <span className="font14x500">{`${
                          orderDetails?.salon?.country?.currency
                        } ${orderDetails?.cartCalculations?.services?.commision?.toFixed(
                          2
                        )}`}</span>
                      )}
                    </div>
                  )}
                {role === ADMIN &&
                  orderDetails?.wallet > 0 &&
                  orderDetails?.qrCode === false && (
                    <div className="d-flex justify-content-between">
                      <span
                        className="font14x500"
                        style={{ color: "var(--text4)" }}
                      >
                        Wallet
                      </span>
                      {orderDetails?.salon?.country?.htmlSymbol !== "" ? (
                        <span className="font14x500">{`${String.fromCharCode(
                          orderDetails?.salon?.country?.htmlSymbol
                        )} ${functions.walletBreak(
                          orderDetails?.wallet
                        )}`}</span>
                      ) : (
                        <span className="font14x500">{`${
                          orderDetails?.salon?.country?.currency
                        } ${functions.walletBreak(
                          orderDetails?.wallet
                        )}`}</span>
                      )}
                    </div>
                  )}
              </div>
            </div>
          </div>

          {/* Service Details */}
          <div className="col-lg-7 col-12 g-lg-4">
            <div className="cardWrapper">
              <div className="cardWrapperHeader">
                <h3 className="font22x500 mb-0">Service Details</h3>
              </div>
              <div className="cardContent">
                {orderDetails?.serviceOrders?.services?.map((item, i) => {
                  return (
                    <div
                      className="servicesContainer row gx-0 pb-3 mb-3"
                      key={i}
                    >
                      <div className="col-lg-5 d-flex">
                        {item?.service?.image === null ? (
                          <img
                            src={serviceImage}
                            alt="service"
                            width={72}
                            height={72}
                            style={{ borderRadius: "4px" }}
                          />
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_Image_BaseURL}${item?.service?.image}`}
                            alt="service"
                            width={72}
                            height={72}
                            style={{ borderRadius: "4px" }}
                          />
                        )}
                        <div className="ps-2 pt-1">
                          <h3 className="mb-0 font14x500">
                            {item?.service?.name}
                          </h3>
                          <p
                            className="mb-0 font12x400"
                            style={{ color: "var(--text4)" }}
                          >
                            <AiOutlineClockCircle /> {item?.service?.duration}{" "}
                            mins
                          </p>
                          <p
                            className="mb-0 font12x400 lh-1"
                            style={{ color: "var(--text4)" }}
                          >
                            Quantity: {item?.count}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-7 d-flex justify-content-between align-items-center">
                        <span
                          className="font14x500"
                          style={{ color: "var(--text4)" }}
                        >
                          {`Service Price x ${item?.count}`}
                        </span>
                        <div>
                          <span className="font14x500 me-2">
                            {orderDetails?.salon?.country?.htmlSymbol !== "" ? (
                              <s>{`${String.fromCharCode(
                                orderDetails?.salon?.country?.htmlSymbol
                              )} ${
                                orderDetails?.serviceOrders?.serviceType ===
                                "At Salon"
                                  ? item?.service?.salonMrp * item?.count
                                  : item?.service?.homeMrp * item?.count
                              }`}</s>
                            ) : (
                              <s>{`${orderDetails?.salon?.country?.currency} ${
                                orderDetails?.serviceOrders?.serviceType ===
                                "At Salon"
                                  ? item?.service?.salonMrp * item?.count
                                  : item?.service?.homeMrp * item?.count
                              }`}</s>
                            )}
                          </span>
                          {orderDetails?.salon?.country?.htmlSymbol !== "" ? (
                            <span>{`${String.fromCharCode(
                              orderDetails?.salon?.country?.htmlSymbol
                            )} ${
                              orderDetails?.serviceOrders?.serviceType ===
                              "At Salon"
                                ? item?.service?.salonDiscountPrice *
                                  item?.count
                                : item?.service?.homeDiscountPrice * item?.count
                            }`}</span>
                          ) : (
                            <span>{`${orderDetails?.salon?.country?.currency} ${
                              orderDetails?.serviceOrders?.serviceType ===
                              "At Salon"
                                ? item?.service?.salonDiscountPrice *
                                  item?.count
                                : item?.service?.homeDiscountPrice * item?.count
                            }`}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* Rating & Review */}
          {orderDetails?.orderReviewAndRating !== null &&
            orderDetails?.orderReviewAndRating?.status !== 0 && (
              <div
                className="col-lg-5 col-12 g-lg-4"
                style={{ height: "fit-content" }}
              >
                <div className="cardWrapper">
                  <div className="cardWrapperHeader">
                    <h3 className="font22x500 mb-0">Rating & Review</h3>
                  </div>
                  <div className="cardContent">
                    <div className="d-flex align-items-center">
                      <p className="font22x700 mb-0 me-3">{`${orderDetails?.orderReviewAndRating?.rating} stars`}</p>
                      <StarRatings
                        rating={orderDetails?.orderReviewAndRating?.rating}
                        starEmptyColor="#a6a6a6"
                        starRatedColor="#FCC153"
                        starDimension="22px"
                        numberOfStars={5}
                        starSpacing="2"
                      />
                    </div>
                    <p className="font14x400 mt-2 lh-sm mb-0">
                      {orderDetails?.orderReviewAndRating?.review}
                    </p>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>

      {stylist?.modal && (
        <AssignStylistModal
          state={stylist}
          setState={setStylist}
          id={orderDetails?._id}
          apiCall={apiCall}
          isStylistAssigned={orderDetails?.serviceOrders?.stylist}
        />
      )}

      {commonData.modal && commonData.check === "paymentMode" && (
        <PaymentModeSelectionAtSalon
          state={commonData}
          setState={setCommonData}
          apiCall={apiCall}
        />
      )}
    </>
  );
};

export default Summary;
