import React, { useRef, useState } from "react";
import { CgClose } from "react-icons/cg";
import useAuthContext from "../../hooks/useAuthContext";
import InputFieldLarge from "../../Shared Components/InputFieldLarge";
import { stylistSchema } from "../../utils/validationschema";
import { useFormik } from "formik";
import InputError from "../../Shared Components/InputError";
import { authHeader, STYLIST_ADD, STYLIST_UPDATE } from "../../utils/Constants";
import axios from "axios";
import { toast } from "react-toastify";
import UpdationDetails from "../../Shared Components/UpdationDetails";
import StaticDropdown from "../../Shared Components/StaticDropdown";
import useLoading from "../../hooks/useLoading";
import SubmitButton from "../../Shared Components/SubmitButton";
import RadioButton from "../../Shared Components/RadioButton";

const AddStylistForm = ({ state, setState, apiCall, id }) => {
  const [oldImagePath, setoldImagePath] = useState("null");
  const salonDetails = JSON.parse(localStorage.getItem("salon_details"));
  const initialValues = {
    name: state?.data?.name ?? "",
    image: "",
    mobile: state?.data?.mobile ?? "",
    alternateNumber: state?.data?.alternateNumber ?? "",
    salary: state?.data?.salary ?? 0,
    advance: state?.data?.advance ?? 0,
    penalty: state?.data?.penalty ?? 0,
    weeklyOff: state?.data?.weeklyOff ?? "",
    gender: state?.data?.gender ?? "",
    statusName: state?.data?.status === 1 ? "Active" : "Inactive",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: stylistSchema,
    onSubmit: (data) => {
      // console.log("data", data);
      formSubmit(data);
    },
  });

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
      check: "",
    });
    document.body.style.overflow = "unset";
  };

  const stylistImage = useRef(null);
  const handleSingleImg = (e) => {
    e.preventDefault();
    stylistImage.current.click();
  };

  const [singleImage, setSingleImage] = useState(
    state?.data?.image
      ? `${process.env.REACT_APP_Image_BaseURL}${state?.data?.image}`
      : ""
  );
  const imageDataURL = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSingleImage(URL.createObjectURL(e.target.files[0]));
      formik.setFieldValue("image", Array.from(e.target.files));
    }
  };
  const deleteImage = (index) => {
    if (index === -1) {
      formik.setFieldValue("image", "");
      setSingleImage("");
      setoldImagePath(state?.data?.image ? state?.data?.image : null);
      stylistImage.current.value = "";
    }
  };

  const { token } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();

  const formSubmit = async (data) => {
    console.log("form submit called");

    //form data
    const sFormData = new FormData();
    sFormData.append("name", data.name);
    sFormData.append("image", data.image[0]);
    sFormData.append("mobile", data.mobile);
    sFormData.append("alternateNumber", data.alternateNumber);
    sFormData.append("salary", data.salary);
    sFormData.append("advance", data.advance);
    sFormData.append("penalty", data.penalty);
    sFormData.append("weeklyOff", data.weeklyOff);
    sFormData.append("gender", data.gender);
    sFormData.append("status", data.statusName === "Active" ? 1 : 2);
    if (state.data) {
      sFormData.append("updateImage", oldImagePath);
    }

    if (token) {
      if (state.data === undefined) {
        try {
          showLoader();
          const res = await axios.post(
            `${STYLIST_ADD}${!state.data ? id : state.data._id}/stylist/form`,
            sFormData,
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Stylist Added", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      } else {
        try {
          showLoader();
          console.log("update api called");
          const res = await axios.put(
            `${STYLIST_UPDATE}${state?.data?._id}/form`,
            sFormData,
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Stylist Updated", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">
              {!state.data ? "New Stylist" : "Edit Stylist"}
            </h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit}>
              {/* Name of Stylist */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Stylist Name*"
                  htmlFor="stylistname"
                  type="text"
                  name="name"
                  stateData={formik.values.name}
                  setStateData={formik.handleChange}
                />
                {formik.errors.name ? (
                  <InputError error={formik.errors.name} />
                ) : null}
              </div>

              {/* stylist image */}
              <div className="mb-3">
                <div className="mb-0">
                  <label
                    htmlFor="formFile"
                    className="font12x500 mb-1 form-label"
                  >
                    Image
                  </label>
                  <input
                    ref={stylistImage}
                    className="form-control shadow-none"
                    type="file"
                    id="formFile"
                    name="image"
                    accept={".png, .jpg, .jpeg"}
                    hidden
                    onChange={(e) => {
                      imageDataURL(e);
                    }}
                  />
                </div>
                <div className="uploadImg d-flex align-items-center mb-2">
                  <div
                    className="uploadBtn font12x400 me-3"
                    onClick={(e) => {
                      handleSingleImg(e);
                    }}
                  >
                    Upload
                  </div>
                  <span className="font12x400">
                    Allowed file types: jpg, jpeg, png
                  </span>
                </div>
                {singleImage && (
                  <>
                    <div className="imageWrapper">
                      <img
                        src={singleImage}
                        alt="delete"
                        width={61}
                        height={61}
                      />
                      <div
                        className="deleteDiv"
                        onClick={() => {
                          deleteImage(-1);
                        }}
                      >
                        <img src="/Assets/delete.svg" alt="delete" />
                      </div>
                    </div>
                  </>
                )}
                {formik.errors.image ? (
                  <InputError error={formik.errors.image} />
                ) : null}
              </div>

              {/* number */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Mobile Number*"
                  htmlFor="number"
                  type="tel"
                  name="mobile"
                  stateData={formik.values.mobile}
                  setStateData={formik.handleChange}
                />
                {formik.errors.mobile ? (
                  <InputError error={formik.errors.mobile} />
                ) : null}
              </div>

              {/* alternate number */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Alternate Number*"
                  htmlFor="number"
                  type="tel"
                  name="alternateNumber"
                  stateData={formik.values.alternateNumber}
                  setStateData={formik.handleChange}
                />
                {formik.errors.alternateNumber ? (
                  <InputError error={formik.errors.alternateNumber} />
                ) : null}
              </div>

              {/* gender type */}
              <div className="mb-3">
                <div className="mb-1">
                  <label
                    htmlFor="atsalon"
                    className="font12x500 form-label mb-1"
                  >
                    Gender Type*
                  </label>

                  <div className="container-fluid px-0">
                    <div className="row">
                      <RadioButton
                        label="Male"
                        htmlFor="gender"
                        name="gender"
                        value="Male"
                        stateData={formik.values.gender}
                        setStateData={(e) => {
                          formik.setFieldValue("gender", e);
                        }}
                      />

                      <RadioButton
                        label="Female"
                        htmlFor="female"
                        name="gender"
                        value="Female"
                        stateData={formik.values.gender}
                        setStateData={(e) => {
                          formik.setFieldValue("gender", e);
                        }}
                      />
                    </div>
                  </div>
                  {formik.errors.gender ? (
                    <InputError error={formik.errors.gender} />
                  ) : null}
                </div>
              </div>

              {/* status */}
              <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: "", label: "None" },
                    { value: "sunday", label: "Sunday" },
                    { value: "monday", label: "Monday" },
                    { value: "tuesday", label: "Tuesday" },
                    { value: "wednesday", label: "Wednesday" },
                    { value: "thursday", label: "Thursday" },
                    { value: "friday", label: "Friday" },
                    { value: "saturday", label: "Saturday" },
                  ]}
                  label="Weekly Off Day*"
                  htmlFor="status"
                  type="text"
                  placeholder="Select Day"
                  name="weeklyOff"
                  stateData={formik.values.weeklyOff}
                  setStateData={(e) => {
                    formik.setFieldValue("weeklyOff", e);
                  }}
                />
                {formik.errors.weeklyOff ? (
                  <InputError error={formik.errors.weeklyOff} />
                ) : null}
              </div>

              {/* salary */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Salary (Monthly)*"
                  htmlFor="salary"
                  type="tel"
                  name="salary"
                  stateData={formik.values.salary}
                  setStateData={formik.handleChange}
                />
                {formik.errors.salary ? (
                  <InputError error={formik.errors.salary} />
                ) : null}
              </div>

              {/* advance */}
              <div className="mb-3">
                <InputFieldLarge
                  label={`Advance (${
                    salonDetails?.country?.htmlSymbol !== ""
                      ? String.fromCharCode(salonDetails?.country?.htmlSymbol)
                      : salonDetails?.country?.currency
                  })*`}
                  htmlFor="advance"
                  type="tel"
                  name="advance"
                  stateData={formik.values.advance}
                  setStateData={formik.handleChange}
                />
                {formik.errors.advance ? (
                  <InputError error={formik.errors.advance} />
                ) : null}
              </div>

              {/* penalty */}
              <div className="mb-3">
                <InputFieldLarge
                  label={`Penalty (${
                    salonDetails?.country?.htmlSymbol !== ""
                      ? String.fromCharCode(salonDetails?.country?.htmlSymbol)
                      : salonDetails?.country?.currency
                  })*`}
                  htmlFor="penalty"
                  type="tel"
                  name="penalty"
                  stateData={formik.values.penalty}
                  setStateData={formik.handleChange}
                />
                {formik.errors.penalty ? (
                  <InputError error={formik.errors.penalty} />
                ) : null}
              </div>

              {/* status */}
              <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: "Active", label: "Active" },
                    { value: "Inactive", label: "Inactive" },
                  ]}
                  label="Status*"
                  htmlFor="status"
                  type="text"
                  placeholder="Select Status"
                  name="statusName"
                  stateData={formik.values.statusName}
                  setStateData={(e) => {
                    formik.setFieldValue("statusName", e);
                  }}
                />
                {formik.errors.statusName ? (
                  <InputError error={formik.errors.statusName} />
                ) : null}
              </div>

              <SubmitButton
                data={!state.data}
                loader={loader}
                create="Submit"
                update="Update"
              />
            </form>

            {state?.data && <UpdationDetails data={state?.data} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddStylistForm;
