import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProgressStep from "../../../../Components/Admin/OrderProduct/ProgressStep";
import Summary from "../../../../Components/Admin/OrderProduct/Summary";
import Layout from "../../../../Components/Layout";
import useAuthContext from "../../../../hooks/useAuthContext";
import useFetch from "../../../../hooks/useFetch";
import { GET_ORDER_BY_ID, productSteps } from "../../../../utils/Constants";
import { ToastContainer } from "react-toastify";
import UpdationDetails from "../../../../Shared Components/UpdationDetails";
import Loader from "../../../../Components/Loader";
import ViewOrderHeader from "../../../../Components/vieworderheader";

const ViewOrderProduct = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [order, totalResult, error, loader, getData] = useFetch({
    url: `${GET_ORDER_BY_ID}${params.id}`,
    resultField: "order",
  });

  useEffect(() => {
    if (params.id === "id") {
      navigate(-1);
    } else {
      window.localStorage.removeItem("salon_details");
      getData();
    }
  }, []);

  return (
    <>
      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <ViewOrderHeader />

      <div className="mobile">
        <div className="order_status_wrapper d-flex justify-content-between">
          <p className="mb-0 font16x500">
            Order#: <span>{order?.productOrders?.orderId}</span>
          </p>
          <p className="mb-0 font16x500 text-capitalize">
            {
              productSteps[
                Number(order?.productOrders?.productsProcessingStatus)
              ]
            }
          </p>
        </div>
      </div>

      <div className="mainWrapper view-order-page">
        {loader ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "70vh" }}
          >
            <Loader height="40" width="40" color="#772286" />
          </div>
        ) : (
          <>
            <div>
              <h3 className="font22x500 pageHeading mb-0 text-capitalize">
                {`Order Id: ${order?.productOrders?.orderId} (${order?.salon?.name}, ${order?.salon?.address?.city})`}
              </h3>
            </div>
            <ProgressStep
              statusTimeStamps={order?.productOrders?.productsStatusTimestamps}
              status={order?.productOrders?.productsProcessingStatus}
            />
            <Summary orderDetails={order} apiCall={getData} />
          </>
        )}

        {order && <UpdationDetails data={order?.productOrders} />}
      </div>

      <ToastContainer />
    </>
  );
};

export default ViewOrderProduct;
