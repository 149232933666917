import React, { useEffect, useState } from "react";
import Layout from "../../../../Components/Layout";
import useFetch from "../../../../hooks/useFetch";
import { CLIENTS_LIST } from "../../../../utils/Constants";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../../Components/Loader";
import moment from "moment";
import InputDate from "../../../../Shared Components/InputDate";
import SearchInput from "../../../../Shared Components/SearchInput";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";
import { BsWhatsapp } from "react-icons/bs";
import MobileByDateFilters from "../../../../Components/mobilebydatefilter";
import GenerateReportModal from "../../../../Components/Generate Report";

const Clients = () => {
  const salonNew = JSON.parse(localStorage.getItem("salon_details"));
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
    "salon._id": salonNew?._id && salonNew?._id,
    // fromDate: moment().startOf("month").format("YYYY-MM-DD"),
    // tillDate: moment().endOf("month").format("YYYY-MM-DD"),
    qrCode: true,
  });
  console.log(filters);

  const [param, setParam] = useState({
    // show: false,
  });
  console.log("param", param);

  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const functions = {
    addParam: () => {
      if (param.fromDate && param.tillDate) {
        const checkDate = moment(param.tillDate, "YYYY-MM-DD").isAfter(
          moment(param.fromDate, "YYYY-MM-DD")
        );
        if (checkDate) {
          setFilter({
            ...filters,
            ...param,
          });
        } else {
          toast.error("From date must be less than till date.", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    },
    resetParam: () => {
      setParam({
        show: false,
      });
      // filters.fromDate = moment().startOf("month").format("YYYY-MM-DD");
      // filters.tillDate = moment().endOf("month").format("YYYY-MM-DD");
      delete filters["user.mobile"];
      delete filters["user.name"];
      delete filters.q;
      delete filters.fromDate;
      delete filters.tillDate;
      getData();
    },
    redirectToWhatsapp: (mobile) => {
      window.open(`//api.whatsapp.com/send?phone=91${mobile}`);
    },
    onOpenModal: () => {
      setIsOpen(true);
      document.body.style.overflow = "hidden";
    },
  };

  const [customers, totalResult, error, loader, getData] = useFetch({
    url: `${CLIENTS_LIST}`,
    resultField: "customers",
    parameter: filters,
  });

  useEffect(() => {
    getData();
  }, [filters]);

  console.log(customers);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Clients"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileByDateFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          param={param}
          setParam={setParam}
          addParam={functions.addParam}
          resetParam={functions.resetParam}
          generateReport={() => {
            functions.onOpenModal();
          }}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Clients"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Clients"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper clients-page">
        <div>
          <div className="container ms-0 ps-0 d-flex justify-content-between align-items-center mb-2">
            <h3 className="font22x500 pageHeading mb-0">Clients</h3>
            <div className="desktopButton">
              <button
                className="btn addBtn shadow-none"
                onClick={() => {
                  functions.onOpenModal();
                }}
              >
                Generate Report
              </button>
            </div>
          </div>
          <div className="filter-container container ms-0 ps-0 mb-3">
            <div className="row">
              <div className="col-12 col-lg-5 d-flex align-items-end">
                <SearchInput
                  type="text"
                  placeholder="Search by name/mobile"
                  stateData={param}
                  setStateData={setParam}
                  parameter1="user.name"
                  parameter2="user.mobile"
                  addParam={functions.addParam}
                  resetParam={functions.resetParam}
                />
              </div>
              <div className="col-12 col-lg-7 d-flex justify-content-lg-end align-items-end">
                {/* date from */}
                <InputDate
                  label="From"
                  htmlFor="from"
                  type="date"
                  stateData={param?.fromDate}
                  setStateData={(e) => {
                    setParam({
                      ...param,
                      fromDate: moment(e).format("YYYY-MM-DD"),
                    });
                  }}
                />

                {/* date to */}
                <div className="ms-3">
                  <InputDate
                    label="Till"
                    htmlFor="till"
                    type="date"
                    stateData={param?.tillDate}
                    setStateData={(e) => {
                      setParam({
                        ...param,
                        tillDate: moment(e).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </div>

                <button
                  className="btn filterBtn shadow-none ms-3"
                  onClick={functions.addParam}
                >
                  Apply
                </button>
                <button
                  className="btn resetBtn shadow-none ms-3"
                  onClick={functions.resetParam}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>

        {customers?.length !== 0 ? (
          <>
            <div className="container desktop dataTable ms-0 ps-0">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">SL.No.</th>
                    <th scope="col">Name</th>
                    <th scope="col">Mobile</th>
                    <th scope="col">Last Service Date</th>
                    <th scope="col">Total Revenue</th>
                    <th scope="col">Appointments</th>
                    <th scope="col">Contact</th>
                  </tr>
                </thead>
                <tbody>
                  {customers?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {(filters.page - 1) * filters.per_page + (i + 1)}
                        </td>
                        <td>{item?.name}</td>
                        <td className="whatsappTab">{item?.mobile}</td>
                        <td>
                          {moment(item?.lastServiceDate).format("MMM DD, YYYY")}
                        </td>
                        {salonNew?.country?.htmlSymbol !== "" ? (
                          <td>
                            {`${String.fromCharCode(
                              salonNew?.country?.htmlSymbol
                            )} ${(
                              item?.productRevenue + item.serviceRevenue
                            ).toLocaleString()}`}
                          </td>
                        ) : (
                          <td>
                            {`${salonNew?.country?.currency} ${(
                              item?.productRevenue + item.serviceRevenue
                            ).toLocaleString()}`}
                          </td>
                        )}
                        <td>{item.serviceCount + item.productCount}</td>
                        <td>
                          <button
                            type="button"
                            className="btn font14x400 filterBtn shadow-none me-2"
                            onClick={() =>
                              functions.redirectToWhatsapp(item?.mobile)
                            }
                          >
                            Whatsapp
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="container mobile dataTable ms-0 ps-0">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Customer Details</th>
                    <th scope="col">History</th>
                    <th scope="col">Whatsapp</th>
                  </tr>
                </thead>
                <tbody>
                  {customers?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {item?.name} <br />
                          {item?.mobile}
                        </td>
                        <td
                          style={{
                            width: "150px",
                          }}
                        >
                          <div className="d-flex gap-1">
                            <p className="mb-0 font12x500">Date:</p>
                            <p className="mb-1 font12x400">
                              {moment(item?.lastServiceDate).format(
                                "MMM DD, YYYY"
                              )}
                            </p>
                          </div>
                          <div className="d-flex gap-1">
                            <p className="mb-0 font12x500">Revenue:</p>
                            {salonNew?.country?.htmlSymbol !== "" ? (
                              <p className="mb-1 font12x400">
                                {`${String.fromCharCode(
                                  salonNew?.country?.htmlSymbol
                                )} ${(
                                  item?.productRevenue + item.serviceRevenue
                                ).toLocaleString()}`}
                              </p>
                            ) : (
                              <p className="mb-1 font12x400">
                                {`${salonNew?.country?.currency} ${(
                                  item?.productRevenue + item.serviceRevenue
                                ).toLocaleString()}`}
                              </p>
                            )}
                          </div>
                          <div className="d-flex gap-1">
                            <p className="mb-0 font12x500">Appointments:</p>
                            <p className="mb-1 font12x400">
                              {item.serviceCount + item.productCount}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center">
                            <BsWhatsapp
                              style={{
                                color: "var(--btn_primary)",
                                fontSize: "20px",
                              }}
                              onClick={() =>
                                functions.redirectToWhatsapp(item?.mobile)
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Clients Found"
            )}
          </div>
        )}

        {/* {customers?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )} */}
      </div>

      {isOpen && (
        <GenerateReportModal
          state={isOpen}
          setState={setIsOpen}
          salonName={salonNew?.name?.split(" ").join("_")}
          saveFileName="Clients"
          filters={filters}
          setFilter={setFilter}
          getData={getData}
          url={CLIENTS_LIST}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default Clients;
