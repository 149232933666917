import React from "react";
import LoginProcess from "./LoginProcess";
import VerifyProcess from "./VerifyProcess";

const ChildSalonProcess = (props) => {
  const { state, setState } = props;

  switch (state.step) {
    case 1:
      return <LoginProcess state={state} setState={setState} />;
    case 2:
      return <VerifyProcess state={state} setState={setState} />;
    default: //nothing to render
  }
};

export default ChildSalonProcess;
