import { useEffect, useState } from "react";
import SearchInput from "../../../../Shared Components/SearchInput";

function MobileTemporaryUserFilters(props) {
  const { resetParam, addParam, setParam, param } = props;

  const [animateFilter, setAnimateFilter] = useState({
    transform: "translate(100%)",
  });

  useEffect(() => {
    if (props.isOpenFilter) {
      setAnimateFilter({
        transform: "translate(0%)",
      });
    } else {
      setAnimateFilter({
        transform: "translate(100%)",
      });
    }
  }, [props.isOpenFilter]);

  return (
    <div style={animateFilter} className="mobile-bydate-filters-container">
      <div
        onClick={() => {
          props.setIsOpenFilter(false);
        }}
        className="left-filter-section"
      ></div>
      <div className="right-filter-section">
        <div className="right-filter-sub-container pt-3 pb-3">
          <div className="mt-4">
            <p className="title">Filter</p>

            <div className="mb-4">
              <SearchInput
                type="text"
                placeholder="Search by mobile"
                stateData={param}
                setStateData={setParam}
                parameter1="name"
                parameter2="mobile"
                width="auto"
                addParam={addParam}
                resetParam={resetParam}
              />
            </div>
          </div>

          <div>
            <button className="reset" onClick={resetParam}>
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileTemporaryUserFilters;
