import React from "react";
import Loader from "../Loader";

const ReportOverview = (props) => {
  const { label, loader, reportsOverview } = props;

  return (
    <>
      <div className="col-12 col-lg-6">
        <div className="cardWrapper">
          <div className="cardWrapperHeader">
            <h3 className="font18x500 mb-0">{label}</h3>
          </div>
          <div className="cardContent" style={{ lineHeight: "40px" }}>
            {/* stylists */}
            <div className="d-flex justify-content-between">
              <span className="font14x500" style={{ color: "var(--text4)" }}>
                Stylists
              </span>
              <span className="font14x500">
                {loader ? (
                  <div className="d-flex align-items-center">
                    <span className="ms-2">
                      <Loader height="20" width="20" color="#772286" />
                    </span>
                  </div>
                ) : (
                  reportsOverview?.stylists
                )}
              </span>
            </div>

            {/* Cancelled Orders */}
            <div className="d-flex justify-content-between">
              <span className="font14x500" style={{ color: "var(--text4)" }}>
                Cancelled Orders
              </span>
              <span className="font14x500">
                {loader ? (
                  <div className="d-flex align-items-center">
                    <span className="ms-2">
                      <Loader height="20" width="20" color="#772286" />
                    </span>
                  </div>
                ) : (
                  reportsOverview?.cancelledOrders
                )}
              </span>
            </div>

            {/* Customers */}
            <div className="d-flex justify-content-between">
              <span className="font14x500" style={{ color: "var(--text4)" }}>
                Customers
              </span>
              <span className="font14x500">
                {loader ? (
                  <div className="d-flex align-items-center">
                    <span className="ms-2">
                      <Loader height="20" width="20" color="#772286" />
                    </span>
                  </div>
                ) : (
                  reportsOverview?.customers
                )}
              </span>
            </div>

            {/* Active Users */}
            <div className="d-flex justify-content-between">
              <span className="font14x500" style={{ color: "var(--text4)" }}>
                Active Users
              </span>
              <span className="font14x500">
                {loader ? (
                  <div className="d-flex align-items-center">
                    <span className="ms-2">
                      <Loader height="20" width="20" color="#772286" />
                    </span>
                  </div>
                ) : (
                  reportsOverview?.activeUsers
                )}
              </span>
            </div>

            {/* Services */}
            <div className="d-flex justify-content-between">
              <span className="font14x500" style={{ color: "var(--text4)" }}>
                Services
              </span>
              <span className="font14x500">
                {loader ? (
                  <div className="d-flex align-items-center">
                    <span className="ms-2">
                      <Loader height="20" width="20" color="#772286" />
                    </span>
                  </div>
                ) : (
                  reportsOverview?.services
                )}
              </span>
            </div>

            {/* Packages */}
            <div className="d-flex justify-content-between">
              <span className="font14x500" style={{ color: "var(--text4)" }}>
                Packages
              </span>
              <span className="font14x500">
                {loader ? (
                  <div className="d-flex align-items-center">
                    <span className="ms-2">
                      <Loader height="20" width="20" color="#772286" />
                    </span>
                  </div>
                ) : (
                  reportsOverview?.packages
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportOverview;
