import React, { useEffect, useState } from "react";
import { Day } from "../../../../utils/Constants";
import Time from "./Time";

const TimeSelection = ({ setTimings }) => {
  const [days, setDays] = useState(Day);

  const [selectedDay, setSelectedDay] = useState("");
  const [selectedTime, setSelectedTime] = useState("0:00");
  const [time, setTime] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const temp = [...days];
    temp.forEach((t, idx) => {
      if (idx === index) {
        Object.keys(t).map((day) => {
          if (day === selectedDay) {
            if (time === "opening_time") {
              t[day].openingTime = selectedTime;
            }
            if (time === "closing_time") {
              t[day].closingTime = selectedTime;
            }
          }
        });
      }
    });
    setDays(temp);
  }, [selectedDay, selectedTime]);

  useEffect(() => {
    let arr = [];
    days.map((t) => {
      return Object.keys(t).map((item) => {
        arr.push(`${t[item].openingTime}-${t[item].closingTime}`);
      });
    });
    setTimings(arr);
  }, [selectedDay, selectedTime, days]);

  const handleCheckbox = (day) => {
    const temp = [...days];
    temp.map((t) => {
      return Object.keys(t).map((item) => {
        if (day === item) {
          t[item].isChecked = !t[item].isChecked;
          if (t[item].isChecked === false) {
            t[item].openingTime = "0:00";
            t[item].closingTime = "0:00";
          } else {
            t[item].openingTime = "10:00 AM";
            t[item].closingTime = "8:00 PM";
          }
        }
      });
    });
    setDays(temp);
  };

  return (
    <>
      <div
        className="mb-3 timingsWrapper"
        style={{
          overflow: "auto",
        }}
      >
        <label className="font12x500 form-label mb-1">Store Timings*</label>
        <div className="timingsContainer">
          {days.map((itm, i) => {
            return (
              <div key={i}>
                {Object.keys(itm).map((day, index) => {
                  return (
                    <div className="row mb-2" key={index}>
                      <div className="col-5 d-flex gap-3 align-items-center">
                        <div
                          className="font14x400 col-2"
                          style={{ textTransform: "capitalize" }}
                        >
                          {day}
                        </div>
                        <div className="ms-5 d-flex justify-content-between">
                          <label className="switchBtn">
                            <input
                              type="checkbox"
                              className="me-2"
                              checked={itm[day].isChecked}
                              onChange={() => {
                                handleCheckbox(day);
                              }}
                            />
                            <span
                              className="openCloseCheck"
                              style={{
                                backgroundColor: itm[day].isChecked
                                  ? "#11CC93"
                                  : "#BCBCBC",
                              }}
                            ></span>
                          </label>
                          {itm[day].isChecked ? (
                            <span
                              className="font12x400 ms-3"
                              style={{ color: "var(--text4" }}
                            >
                              Open
                            </span>
                          ) : (
                            <span
                              className="font12x400 ms-3"
                              style={{ color: "var(--text4" }}
                            >
                              Closed
                            </span>
                          )}
                        </div>
                      </div>
                      {itm[day].isChecked && (
                        <div className="col-7 gap-3 d-flex align-items-center">
                          <div>
                            <Time
                              day={day}
                              setSelectedDay={setSelectedDay}
                              setSelectedTime={setSelectedTime}
                              time="opening_time"
                              setTime={setTime}
                              selectedTime={itm[day].openingTime}
                              setIndex={setIndex}
                              i={i}
                            />
                          </div>
                          <div>TO</div>
                          <div>
                            <Time
                              day={day}
                              setSelectedDay={setSelectedDay}
                              setSelectedTime={setSelectedTime}
                              time="closing_time"
                              setTime={setTime}
                              selectedTime={itm[day].closingTime}
                              setIndex={setIndex}
                              i={i}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TimeSelection;
