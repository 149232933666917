import React, { useEffect, useState } from "react";
import Layout from "../../../Components/Layout";
import DropdownSmall from "../../../Shared Components/DropdownSmall";
import SearchInput2 from "../../../Shared Components/SearchInput2";
import InputDate from "../../../Shared Components/InputDate";
import { ADMIN_TRANSACTION_SEARCH } from "../../../utils/Constants";
import useFetch from "../../../hooks/useFetch";
import Pagination from "../../../Components/Pagination";
import { getAddedOnDate } from "../../../Functions/dateTime";
import moment from "moment";
import Loader from "../../../Components/Loader";
import PageNav from "../../../Components/PageNav";
import { transactionPageLinks } from "../Transactions";
import { Link } from "react-router-dom";
import MobileSalonDashboardHeader from "../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileTransactionFilters from "../../../Components/Mobile/Admin/mobileTransactionFilter";
import MobileFilterHeader from "../../../Components/mobilefilterheader";

export const setPaymentStatusColor = (status) => {
  switch (status) {
    case "Initiated":
      return "--status_color8";
    case "Success":
      return "--status_color2";
    case "Cancelled":
      return "--status_color7";
    case "Failed":
      return "--status_color4";
    default:
      return -1;
  }
};

const AdminTransaction = () => {
  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
  });
  console.log(filters);

  const [param, setParam] = useState({ show: false });

  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const addParam = () => {
    setFilter({
      ...filters,
      ...param,
    });
  };

  const resetParam = () => {
    setParam({});
    delete filters.q;
    delete filters.fromDate;
    delete filters.tillDate;
    delete filters.payment;
    delete filters.paymentName;
    delete filters.paymentStatus;
    delete filters.paymentStatusName;
    getData();
  };

  const [adminTransactions, totalResult, error, loader, getData] = useFetch({
    url: `${ADMIN_TRANSACTION_SEARCH}`,
    resultField: "adminTransactions",
    parameter: filters,
  });

  useEffect(() => {
    window.localStorage.removeItem("salon_details");
    getData();
  }, [filters]);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Admin Transaction"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileTransactionFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          filters={filters}
          setFilter={setFilter}
          resetParam={resetParam}
          addParam={addParam}
          param={param}
          setParam={setParam}
          searchField="adminTransactionId"
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Admin Transaction"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Admin Transaction"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper">
        <div className="service-navigation-neworder-button-container">
          <div className="service-navigation-neworder-button d-flex justify-content-between pe-2">
            <PageNav links={transactionPageLinks} />
          </div>
        </div>
        <div className="filter-container container mt-4 ms-0 ps-0 mb-3">
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-end flex-wrap">
              <SearchInput2
                type="text"
                placeholder="Search by transaction id"
                stateData={param}
                setStateData={setParam}
                parameter1="adminTransactionId"
                parameter2={undefined}
                addParam={addParam}
                resetParam={resetParam}
              />

              {/* Payment Status */}
              <DropdownSmall
                data={[
                  { value: "All", label: "All" },
                  { value: "Initiated", label: "Initiated" },
                  { value: "Success", label: "Success" },
                  { value: "Cancelled", label: "Cancelled" },
                  { value: "Failed", label: "Failed" },
                ]}
                label="Payment Status"
                htmlFor="payStatus"
                type="text"
                stateData={filters?.paymentStatusName}
                setStateData={(e) => {
                  if (e === "All") {
                    delete filters?.paymentStatus;
                    setFilter({
                      ...filters,
                      paymentStatusName: e,
                    });
                  } else {
                    setFilter({
                      ...filters,
                      paymentStatus: e,
                      paymentStatusName: e,
                    });
                  }
                }}
              />

              {/* Payment Mode */}
              <DropdownSmall
                data={[
                  { value: "All", label: "All" },
                  { value: "COD", label: "COD" },
                  { value: "Online", label: "Online" },
                ]}
                label="Payment Mode"
                htmlFor="payMode"
                type="text"
                stateData={filters?.paymentName}
                setStateData={(e) => {
                  if (e === "All") {
                    delete filters?.payment;
                    setFilter({
                      ...filters,
                      paymentName: e,
                    });
                  } else {
                    console.log("e", e);
                    setFilter({
                      ...filters,
                      payment: e,
                      paymentName: e,
                    });
                  }
                }}
              />

              {/* date from */}
              <InputDate
                label="From"
                htmlFor="from"
                name="from"
                stateData={param?.fromDate}
                setStateData={(e) => {
                  setParam({
                    ...param,
                    fromDate: moment(e).format("YYYY-MM-DD"),
                  });
                }}
              />

              {/* date to */}
              <InputDate
                label="To"
                htmlFor="to"
                name="to"
                stateData={param?.tillDate}
                setStateData={(e) => {
                  setParam({
                    ...param,
                    tillDate: moment(e).format("YYYY-MM-DD"),
                  });
                }}
              />

              <button className="btn filterBtn shadow-none" onClick={addParam}>
                Apply
              </button>
              <button className="btn resetBtn shadow-none" onClick={resetParam}>
                Reset
              </button>
            </div>
          </div>
        </div>

        {adminTransactions?.length !== 0 ? (
          <div className="container dataTable ms-0 ps-0">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Transaction Id</th>
                  <th scope="col">Order Id</th>
                  <th scope="col">Store Name</th>
                  <th scope="col">Order Date</th>
                  <th scope="col">Total Amount</th>
                  <th scope="col">Payment Mode</th>
                  <th scope="col">Payment Status</th>
                </tr>
              </thead>
              <tbody>
                {adminTransactions?.map((transaction, i) => {
                  return (
                    <tr key={i}>
                      <td>{transaction?.adminTransactionId}</td>
                      <td style={{ color: "var(--text1)" }}>
                        <Link
                          to={`/admin/admin-order/product/${transaction?.adminOrder?._id}`}
                          style={{ color: "var(--text1)" }}
                        >
                          {transaction?.adminOrder?.adminOrderId}
                        </Link>
                      </td>
                      <td>{transaction?.salon?.name}</td>
                      <td>
                        {getAddedOnDate(transaction?.adminOrder?.createdAt)}
                      </td>
                      <td>{`${transaction?.amount}`}</td>
                      <td>{transaction?.paymentMode}</td>
                      <td>
                        <p
                          className="status mb-0"
                          style={{
                            backgroundColor: `var(${setPaymentStatusColor(
                              transaction?.paymentStatus
                            )})`,
                          }}
                        >
                          {transaction?.paymentStatus}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Admin Transactions Found"
            )}
          </div>
        )}

        {adminTransactions?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      {/* DELETE FUNCTIONALITY LEFT */}
    </>
  );
};

export default AdminTransaction;
