import React from "react";
import Loader from "../Components/Loader";

const SubmitButton = ({ data, create, update, loader, btnWidth }) => {
  return (
    <>
      <button
        type="Submit"
        className="btn OtpBtn shadow-none mt-3 font16x500"
        name="submit_btn"
        style={{ width: btnWidth }}
      >
        {data ? (
          loader ? (
            <div className="d-flex justify-content-center">
              <Loader height="25" width="25" color="#fff" />
            </div>
          ) : (
            create
          )
        ) : loader ? (
          <div className="d-flex justify-content-center">
            <Loader height="25" width="25" color="#fff" />
          </div>
        ) : (
          update
        )}
      </button>
    </>
  );
};

export default SubmitButton;
