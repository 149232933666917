import React, { useEffect, useState } from "react";
import Layout from "../../../../Components/Layout";
import AddStylistForm from "../../../../Components/StylistForm";
import useFetch from "../../../../hooks/useFetch";
import { STYLIST_SEARCH } from "../../../../utils/Constants";
import useAuthContext from "../../../../hooks/useAuthContext";
import DropdownSmall from "../../../../Shared Components/DropdownSmall";
import SearchInput2 from "../../../../Shared Components/SearchInput2";
import Pagination from "../../../../Components/Pagination";
import { ToastContainer } from "react-toastify";
import Loader from "../../../../Components/Loader";
import AttandanceButtons from "../../../../Components/StylistForm/Attandance";
import AttandanceSheet from "../../../../Components/StylistForm/Attandance/AttandanceSheet";
import stylistImage from "../../../../uploads/stylist/user1.jpg";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";
import MobileStylistFilters from "../../../../Components/Mobile/mobileStylistFilter";

const Stylist = () => {
  const [commonData, setCommonData] = useState({
    data: {},
    modal: false,
    check: "",
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [id, setId] = useState("");
  const { token } = useAuthContext();

  const salonNew = JSON.parse(localStorage.getItem("salon_details"));

  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
    salon: salonNew?._id && salonNew?._id,
  });
  // console.log(filters);

  const [param, setParam] = useState({
    show: false,
  });

  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const functions = {
    onEdit: (item) => {
      setCommonData({
        data: item,
        modal: true,
        check: "stylist",
      });
      document.body.style.overflow = "hidden";
    },
    onAdd: () => {
      setCommonData({
        data: undefined,
        modal: true,
        check: "stylist",
      });
      document.body.style.overflow = "hidden";
    },
    onViewSheet: (stylistId) => {
      setCommonData({
        data: stylistId,
        modal: true,
        check: "stylist_attendance",
      });
      document.body.style.overflow = "hidden";
    },
    onDelete: (id) => {
      setIsDeleteModalOpen(true);
      document.body.style.overflow = "hidden";
      setId(id);
    },
    addParam: () => {
      setFilter({
        ...filters,
        ...param,
      });
    },
    resetParam: () => {
      setParam({
        show: false,
      });
      delete filters.status;
      delete filters.statusName;
      delete filters.q;
      getData();
    },
  };

  const [stylists, totalResult, error, loader, getData] = useFetch({
    url: `${STYLIST_SEARCH}`,
    resultField: "stylists",
    parameter: filters,
  });

  useEffect(() => {
    getData();
  }, [filters]);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Stylists"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileStylistFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          onClick={() => {
            functions.onAdd();
          }}
          filters={filters}
          setFilter={setFilter}
          resetParam={functions.resetParam}
          addParam={functions.addParam}
          param={param}
          setParam={setParam}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Stylists"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Stylists"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper clients-page">
        <div>
          <div className="container ms-0 ps-0 d-flex justify-content-between align-items-center mb-2">
            <h3 className="font22x500 pageHeading mb-0">Stylists</h3>
            <div className="desktopButton">
              <button
                className="btn addBtn shadow-none"
                onClick={functions.onAdd}
              >
                Add New Stylist
              </button>
            </div>
          </div>
          <div className="filter-container container ms-0 ps-0 mb-3">
            <div className="row">
              <div className="col-12 col-lg-5 d-flex align-items-end">
                <SearchInput2
                  type="text"
                  placeholder="Search by name"
                  stateData={param}
                  setStateData={setParam}
                  parameter1="name"
                  parameter2={undefined}
                  addParam={functions.addParam}
                  resetParam={functions.resetParam}
                />
              </div>
              <div className="col-12 col-lg-7 d-flex justify-content-lg-end align-items-end">
                {/* status */}
                <DropdownSmall
                  data={[
                    { value: "All", label: "All" },
                    { value: "Active", label: "Active" },
                    { value: "Inactive", label: "Inactive" },
                  ]}
                  label="Status"
                  placeholder="Id Status"
                  htmlFor="status"
                  type="text"
                  stateData={filters?.statusName}
                  setStateData={(e) => {
                    if (e === "All") {
                      delete filters.status;
                      delete filters?.status;
                      setFilter({
                        ...filters,
                        statusName: e,
                      });
                    } else {
                      setFilter({
                        ...filters,
                        statusName: e === "Active" ? "Active" : "Inactive",
                        status: e === "Active" ? 1 : 2,
                      });
                    }
                  }}
                />

                <button
                  className="btn resetBtn shadow-none ms-3"
                  onClick={functions.resetParam}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>

        {stylists?.length !== 0 ? (
          <>
            <div className="container desktop dataTable ms-0 ps-0">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">SL.No.</th>
                    <th scope="col">Image</th>
                    <th scope="col">Stylist Info</th>
                    <th scope="col">Weekly Off</th>
                    <th scope="col">Advance</th>
                    <th scope="col">Penalty</th>
                    <th scope="col">Status</th>
                    <th scope="col">Attendance</th>
                    <th scope="col">Monthly Attendance</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {stylists?.map((stylist, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {(filters.page - 1) * filters.per_page + (i + 1)}
                        </td>
                        <td>
                          {stylist?.image === null ? (
                            <img
                              src={stylistImage}
                              alt="stylist"
                              width={72}
                              height={48}
                              style={{ borderRadius: "4px" }}
                            />
                          ) : (
                            <img
                              src={`${process.env.REACT_APP_Image_BaseURL}${stylist?.image}`}
                              alt="stylist"
                              width={72}
                              height={48}
                              style={{ borderRadius: "4px" }}
                            />
                          )}
                        </td>
                        <td>
                          {stylist?.name}
                          <br />
                          {stylist?.mobile}
                        </td>
                        <td className="text-capitalize">
                          {stylist?.weeklyOff !== ""
                            ? stylist?.weeklyOff
                            : "--"}
                        </td>
                        {salonNew?.country?.htmlSymbol !== "" ? (
                          <td>{`${String.fromCharCode(
                            salonNew?.country?.htmlSymbol
                          )} ${stylist?.advance}`}</td>
                        ) : (
                          <td>{`${salonNew?.country?.currency} ${stylist?.advance}`}</td>
                        )}
                        {salonNew?.country?.htmlSymbol !== "" ? (
                          <td>{`${String.fromCharCode(
                            salonNew?.country?.htmlSymbol
                          )} ${stylist?.penalty}`}</td>
                        ) : (
                          <td>{`${salonNew?.country?.currency} ${stylist?.penalty}`}</td>
                        )}
                        <td>
                          <p
                            className="status mb-0"
                            style={{
                              backgroundColor: `${
                                stylist?.status === 1
                                  ? "var(--status_color2)"
                                  : "var(--status_color1)"
                              }`,
                            }}
                          >
                            {`${stylist?.status === 1 ? "Active" : "Inactive"}`}
                          </p>
                        </td>
                        <td>
                          <AttandanceButtons
                            data={stylist}
                            token={token}
                            apiCall={getData}
                          />
                        </td>
                        <td>
                          <button
                            className="btn font14x400 filterBtn shadow-none me-2"
                            onClick={() => {
                              functions.onViewSheet(stylist?._id);
                            }}
                          >
                            View
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn font14x400 filterBtn shadow-none me-2"
                            onClick={() => {
                              functions.onEdit(stylist);
                            }}
                          >
                            Edit
                          </button>
                          {/* <span
                          className="deleteBtn"
                          onClick={() => {
                            functions.onDelete(stylist?._id);
                          }}
                        >
                          <RiDeleteBin6Line />
                        </span> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="container mobile dataTable ms-0 ps-0">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Image</th>
                    <th scope="col">Stylist Info</th>
                    <th scope="col">Attendance</th>
                    <th scope="col">Monthly Attendance</th>
                    <th scope="col">Weekly Off</th>
                    <th scope="col">Advance</th>
                    <th scope="col">Penalty</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {stylists?.map((stylist, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {stylist?.image === null ? (
                            <img
                              src={stylistImage}
                              alt="stylist"
                              width={25}
                              height={25}
                              style={{ borderRadius: "4px" }}
                            />
                          ) : (
                            <img
                              src={`${process.env.REACT_APP_Image_BaseURL}${stylist?.image}`}
                              alt="stylist"
                              width={25}
                              height={25}
                              style={{ borderRadius: "4px" }}
                            />
                          )}
                        </td>
                        <td>
                          {stylist?.name}
                          <br />
                          {stylist?.mobile}
                        </td>
                        <td>
                          <AttandanceButtons
                            data={stylist}
                            token={token}
                            apiCall={getData}
                          />
                        </td>
                        <td>
                          <button
                            className="btn font14x400 filterBtn shadow-none me-2"
                            onClick={() => {
                              functions.onViewSheet(stylist?._id);
                            }}
                          >
                            View
                          </button>
                        </td>
                        <td className="text-capitalize">
                          {stylist?.weeklyOff !== ""
                            ? stylist?.weeklyOff
                            : "--"}
                        </td>
                        {salonNew?.country?.htmlSymbol !== "" ? (
                          <td>{`${String.fromCharCode(
                            salonNew?.country?.htmlSymbol
                          )} ${stylist?.advance}`}</td>
                        ) : (
                          <td>{`${salonNew?.country?.currency} ${stylist?.advance}`}</td>
                        )}
                        {salonNew?.country?.htmlSymbol !== "" ? (
                          <td>{`${String.fromCharCode(
                            salonNew?.country?.htmlSymbol
                          )} ${stylist?.penalty}`}</td>
                        ) : (
                          <td>{`${salonNew?.country?.currency} ${stylist?.penalty}`}</td>
                        )}
                        <td>
                          <p
                            className="status mb-0"
                            style={{
                              backgroundColor: `${
                                stylist?.status === 1
                                  ? "var(--status_color2)"
                                  : "var(--status_color1)"
                              }`,
                            }}
                          >
                            {`${stylist?.status === 1 ? "Active" : "Inactive"}`}
                          </p>
                        </td>
                        <td>
                          <button
                            className="btn font14x400 filterBtn shadow-none me-2"
                            onClick={() => {
                              functions.onEdit(stylist);
                            }}
                          >
                            Edit
                          </button>
                          {/* <span
                          className="deleteBtn"
                          onClick={() => {
                            functions.onDelete(stylist?._id);
                          }}
                        >
                          <RiDeleteBin6Line />
                        </span> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Stylists Found"
            )}
          </div>
        )}

        {stylists?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      {/* delete modal */}
      {/* {isDeleteModalOpen && (
        <DeleteModal
          state={isDeleteModalOpen}
          setState={setIsDeleteModalOpen}
          url={USER_DELETE}
          id={id}
          text="Stylist"
          apiCall={getData}
        />
      )} */}

      {/* stylist form add and update both */}
      {commonData.modal && commonData?.check === "stylist" && (
        <AddStylistForm
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
          id={salonNew?._id}
        />
      )}

      {commonData.modal && commonData?.check === "stylist_attendance" && (
        <AttandanceSheet
          state={commonData}
          setState={setCommonData}
          token={token}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default Stylist;
