import React from "react";

const InputDateLarge = (props) => {
  const { label, htmlFor, stateData, setStateData, name, onlyDate } = props;
  return (
    <>
      <label htmlFor={htmlFor} className="font12x500 form-label mb-1">
        {label}
      </label>
      <input
        type={onlyDate ? "date" : "datetime-local"}
        placeholder="dd/mm/yyyy, HH:mm"
        name={name}
        id={htmlFor}
        value={stateData}
        onChange={setStateData}
      />
    </>
  );
};

export default InputDateLarge;
