import React, { useRef, useState } from "react";
import BlankStar from "../../vectors/BlankStar";
import ColorStar from "../../vectors/ColorStar";
import { CgClose } from "react-icons/cg";
import SelectOne2 from "../../ServiceForm/SelectOne2";
import axios from "axios";
import { toast } from "react-toastify";
import {
  authFormHeader,
  SALON_PRODUCTS_UPDATE,
  SALON_PRODUCT_ADD,
} from "../../../utils/Constants";
import DynamicDropdown from "../../../Shared Components/DynamicDropdown";
import InputFieldLarge from "../../../Shared Components/InputFieldLarge";
import useAuthContext from "../../../hooks/useAuthContext";
import UpdationDetails from "../../../Shared Components/UpdationDetails";
import { productSchema } from "../../../utils/validationschema";
import { useFormik } from "formik";
import InputError from "../../../Shared Components/InputError";
import useLoading from "../../../hooks/useLoading";
import SubmitButton from "../../../Shared Components/SubmitButton";
import RadioButton from "../../../Shared Components/RadioButton";
import CustomDropdown from "../../../Shared Components/CustomDropdown";

const AdminProductForm = ({ state, setState, apiCall, data, brandData }) => {
  const [productData, setProductData] = useState({
    isRatingFloat: false,
    isActive: state?.data?.isActive ?? false,
  });
  console.log("productData", productData);
  const [oldImagePath, setoldImagePath] = useState("null");

  const { token } = useAuthContext();

  const initialValues = {
    name: state?.data?.name ?? "",
    category: state?.data?.category?.name ?? "",
    order: state?.data?.order ?? 1,
    mrp: state?.data?.mrp ?? 0,
    discountPrice: state?.data?.discountPrice ?? 0,
    description: state?.data?.description ?? "",
    brandName: state?.data?.brandName ?? "",
    noOfPeople: state?.data?.noOfPeople ?? 0,
    rating: state?.data?.rating ?? 0,
    currentStock: state?.data?.currentStock ?? 0,
    quantity: state?.data?.quantity ?? "",
    genderType: state?.data?.genderType ?? "",
    productType: "Retail",
    image: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: productSchema,
    onSubmit: (data) => {
      formSubmit(data);
      // console.log("data", data);
    },
  });

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const productImage = useRef(null);
  const handleSingleImg = (e) => {
    e.preventDefault();
    productImage.current.click();
  };
  const [singleImage, setSingleImage] = useState(
    state?.data?.image
      ? `${process.env.REACT_APP_Image_BaseURL}${state?.data?.image}`
      : ""
  );
  const imageDataURL = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSingleImage(URL.createObjectURL(e.target.files[0]));
      formik.setFieldValue("image", Array.from(e.target.files));
    }
  };
  const deleteImage = (index) => {
    if (index === -1) {
      formik.setFieldValue("image", "");
      setSingleImage("");
      setoldImagePath(state?.data?.image ? state?.data?.image : null);
      productImage.current.value = "";
    }
  };

  const OnlyNumber = (e) => {
    e.target.value = e.target.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..?)\../g, "$1");
  };

  const checkFloat = (e) => {
    const number = Number(e.target.value);

    if (
      typeof number === "number" &&
      !Number.isNaN(number) &&
      !Number.isInteger(number)
    ) {
      setProductData({
        ...productData,
        rating: e.target.value,
        isRatingFloat: true,
      });
    } else {
      setProductData({
        ...productData,
        rating: e.target.value,
      });
    }
  };

  const [categoryId, setCategoryId] = useState(
    state?.data?.category?._id ?? ""
  );
  const [loader, showLoader, hideLoader] = useLoading();

  const formSubmit = async (data) => {
    console.log("form submit called");

    //form data
    const sFormData = new FormData();
    sFormData.append("name", data.name);
    sFormData.append("image", data.image[0]);
    sFormData.append("category", categoryId);
    sFormData.append("order", data.order);
    sFormData.append("mrp", data.mrp);
    sFormData.append("discountPrice", data.discountPrice);
    sFormData.append("description", data.description);
    sFormData.append("brandName", data.brandName);
    sFormData.append("currentStock", data.currentStock);
    sFormData.append("quantity", data.quantity);
    sFormData.append("isActive", productData.isActive);
    sFormData.append("noOfPeople", data.noOfPeople);
    sFormData.append("rating", data.rating);
    sFormData.append("status", productData.isActive ? 1 : 2);
    sFormData.append("country", "India"); //now static
    sFormData.append("productType", data?.productType);
    sFormData.append("genderType", data.genderType);
    if (state.data) {
      sFormData.append("updateImage", oldImagePath);
    }

    if (token) {
      if (state.data === undefined) {
        try {
          showLoader();
          const res = await axios.post(
            `${SALON_PRODUCT_ADD}owner=admin`,
            sFormData,
            {
              //headers: authFormHeader,
              headers: {
                "Content-type": "multipart/form-data",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 201) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Product Added", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error.response.data.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      } else {
        try {
          console.log("update api called");
          showLoader();
          const res = await axios.put(
            `${SALON_PRODUCTS_UPDATE}${state.data._id}/form`,
            sFormData,
            {
              //headers: authFormHeader,
              headers: {
                "Content-type": "multipart/form-data",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Product Updated", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            hideLoader();
            toast.error(error.response.data.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">
              {!state.data ? "New Product" : "Edit Product"}
            </h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
              {/* Name of product */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Product Name*"
                  htmlFor="productname"
                  type="text"
                  name="name"
                  stateData={formik.values.name}
                  setStateData={formik.handleChange}
                />
                {formik.errors.name ? (
                  <InputError error={formik.errors.name} />
                ) : null}
              </div>

              {/* Product Image */}
              <div className="mb-3">
                <div className="mb-0">
                  <label
                    htmlFor="formFile"
                    className="font12x500 mb-1 form-label"
                  >
                    Image
                  </label>
                  <input
                    ref={productImage}
                    className="form-control shadow-none"
                    type="file"
                    id="formFile"
                    name="image"
                    accept={".png, .jpg, .jpeg"}
                    hidden
                    onChange={(e) => {
                      imageDataURL(e);
                    }}
                  />
                </div>
                <div className="uploadImg d-flex align-items-center mb-3">
                  <div
                    className="uploadBtn font12x400 me-3"
                    onClick={(e) => {
                      handleSingleImg(e);
                    }}
                  >
                    Upload
                  </div>
                  <span className="font12x400">
                    Allowed file types: jpg, jpeg, png
                  </span>
                </div>
                {singleImage && (
                  <div className="imageWrapper">
                    <img
                      src={singleImage}
                      alt="delete"
                      width={61}
                      height={61}
                    />
                    <div
                      className="deleteDiv"
                      onClick={() => {
                        deleteImage(-1);
                      }}
                    >
                      <img src="/Assets/delete.svg" alt="delete" />
                    </div>
                  </div>
                )}
                {formik.errors.image ? (
                  <InputError error={formik.errors.image} />
                ) : null}
              </div>

              {/* category */}
              <div className="mb-3">
                <DynamicDropdown
                  data={data}
                  setCategoryId={setCategoryId}
                  label="Category*"
                  htmlFor="category"
                  type="text"
                  placeholder="Select Category"
                  name="category"
                  stateData={formik.values.category}
                  setStateData={(e) => {
                    formik.setFieldValue("category", e);
                  }}
                />
                {formik.errors.category ? (
                  <InputError error={formik.errors.category} />
                ) : null}
              </div>

              {/* gender type */}
              <div className="mb-3">
                <div className="mb-1">
                  <label
                    htmlFor="atsalon"
                    className="font12x500 form-label mb-1"
                  >
                    Gender Type*
                  </label>

                  <div className="container-fluid px-0">
                    <div className="row">
                      <RadioButton
                        label="Male"
                        htmlFor="gender"
                        name="gender"
                        value="Male"
                        stateData={formik.values.genderType}
                        setStateData={(e) => {
                          formik.setFieldValue("genderType", e);
                        }}
                      />

                      <RadioButton
                        label="Female"
                        htmlFor="female"
                        name="gender"
                        value="Female"
                        stateData={formik.values.genderType}
                        setStateData={(e) => {
                          formik.setFieldValue("genderType", e);
                        }}
                      />
                    </div>
                  </div>
                  {formik.errors.genderType ? (
                    <InputError error={formik.errors.genderType} />
                  ) : null}
                </div>
              </div>

              {/* quantity*/}
              <div className="mb-3">
                <InputFieldLarge
                  label="Quantity*"
                  htmlFor="quantity"
                  type="text"
                  name="quantity"
                  stateData={formik.values.quantity}
                  setStateData={formik.handleChange}
                  width="120px"
                />
                {formik.errors.quantity ? (
                  <InputError error={formik.errors.quantity} />
                ) : null}
              </div>

              {/* order */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Order*"
                  htmlFor="order"
                  type="tel"
                  name="order"
                  stateData={formik.values.order}
                  setStateData={formik.handleChange}
                  width="120px"
                />
                {formik.errors.order ? (
                  <InputError error={formik.errors.order} />
                ) : null}
              </div>

              {/* mrp */}
              <div className="mb-3">
                <InputFieldLarge
                  label="MRP*"
                  htmlFor="mrp"
                  type="tel"
                  name="mrp"
                  stateData={formik.values.mrp}
                  setStateData={formik.handleChange}
                />
                {formik.errors.mrp ? (
                  <InputError error={formik.errors.mrp} />
                ) : null}
              </div>

              {/* discounted price */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Discounted Price*"
                  htmlFor="d_price"
                  type="tel"
                  name="discountPrice"
                  stateData={formik.values.discountPrice}
                  setStateData={formik.handleChange}
                />
                {formik.errors.discountPrice ? (
                  <InputError error={formik.errors.discountPrice} />
                ) : null}
              </div>

              {/* product description */}
              <div className="mb-3">
                <label
                  htmlFor="floatingTextarea2"
                  className="font12x500 mb-1 form-label"
                >
                  Description (optional)
                </label>
                <textarea
                  className="form-control shadow-none"
                  id="floatingTextarea2"
                  style={{ height: "180px" }}
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
                {formik.errors.description ? (
                  <InputError error={formik.errors.description} />
                ) : null}
              </div>

              {/* brand used*/}
              <div className="brandWrapper mb-3">
                <CustomDropdown
                  formik={formik}
                  apiData={brandData}
                  label="Brand Name (optional)"
                  htmlFor="brand"
                  name="brandName"
                  stateData={formik.values.brandName}
                />

                {formik.errors.brandName ? (
                  <InputError error={formik.errors.brandName} />
                ) : null}
              </div>

              {/* product rating */}
              <div className="mb-4">
                <label
                  htmlFor=""
                  className="font16x500 mb-1 form-label labelBig"
                >
                  Product Rating*
                </label>
                <div className="d-flex">
                  {/* No. of People*/}
                  <div className="mb-3">
                    <InputFieldLarge
                      label="No. of People*"
                      htmlFor="people"
                      type="tel"
                      name="noOfPeople"
                      stateData={formik.values.noOfPeople}
                      setStateData={formik.handleChange}
                      width="120px"
                    />
                    {formik.errors.noOfPeople ? (
                      <InputError error={formik.errors.noOfPeople} />
                    ) : null}
                  </div>

                  <div className="ms-4">
                    {/* Rating out of 5*/}
                    {/* <InputFieldLarge
                      label="Rating out of 5*"
                      htmlFor="rating"
                      type="tel"
                      stateData=""
                      setStateData=""
                      width="120px"
                      maxLength={3}
                      checkFloat={serviceData.isRatingFloat}
                    /> */}
                    <div className="mb-3">
                      <label
                        htmlFor="rating"
                        className="font12x500 form-label mb-1"
                      >
                        Rating out of 5*
                      </label>
                      <input
                        type="text"
                        className="form-control shadow-none"
                        // onKeyUp={(e) => {
                        //   OnlyNumber(e);
                        // }}
                        id="rating"
                        value={formik.values.rating}
                        onChange={formik.handleChange}
                        // onChange={(e) => {
                        //   checkFloat(e);
                        // }}
                        autoComplete="off"
                        style={{ width: "120px" }}
                        maxLength={3}
                      />
                      {formik.errors.rating ? (
                        <InputError error={formik.errors.rating} />
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* rating(star) */}
                <div className="">
                  {[...Array(5)].map((v, i) => {
                    return (
                      <span key={i} className="me-2">
                        {formik.values.rating > i &&
                        formik.values.rating <= 5 ? (
                          <ColorStar color="#FFCE00" />
                        ) : (
                          <BlankStar />
                        )}
                      </span>
                    );
                  })}
                </div>
              </div>

              {/* stock*/}
              <div className="mb-3">
                <InputFieldLarge
                  label="Stock Availability*"
                  htmlFor="stock"
                  type="tel"
                  name="currentStock"
                  stateData={formik.values.currentStock}
                  setStateData={formik.handleChange}
                  width="120px"
                  readOnly={true}
                />
                {formik.errors.currentStock ? (
                  <InputError error={formik.errors.currentStock} />
                ) : null}
              </div>

              {/* product status */}
              <div className="mb-3">
                <SelectOne2
                  label="Product Status*"
                  htmlFor1="active"
                  htmlFor2="inactive"
                  name="status"
                  text1="Active"
                  text2="Inactive"
                  stateData={productData.isActive}
                  setStateData={(e) => {
                    setProductData({
                      ...productData,
                      isActive: e,
                    });
                  }}
                />
              </div>

              <SubmitButton
                data={!state.data}
                loader={loader}
                create="Post Product"
                update="Update Product"
              />
            </form>

            {state?.data && <UpdationDetails data={state?.data} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminProductForm;
