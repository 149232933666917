import React, { useEffect, useRef, useState } from "react";
import BlankStar from "../vectors/BlankStar";
import ColorStar from "../vectors/ColorStar";
import SelectBoth from "./SelectBoth";
import SelectOne from "./SelectOne";
import { CgClose } from "react-icons/cg";
import axios from "axios";
import useAuthContext from "../../hooks/useAuthContext";
import {
  ADMIN,
  authFormHeader,
  authHeader,
  SALON_SERVICES_UPDATE,
  SALON_SERVICE_ADD,
  SERVICE_CATEGORY_SEARCH,
} from "../../utils/Constants";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import SelectOne2 from "./SelectOne2";
import DynamicDropdown from "../../Shared Components/DynamicDropdown";
import InputFieldLarge from "../../Shared Components/InputFieldLarge";
import UpdationDetails from "../../Shared Components/UpdationDetails";
import { serviceSchema } from "../../utils/validationschema";
import InputError from "../../Shared Components/InputError";
import useLoading from "../../hooks/useLoading";
import SubmitButton from "../../Shared Components/SubmitButton";
import RadioButton from "../../Shared Components/RadioButton";
import CustomDropdown from "../../Shared Components/CustomDropdown";

const ServiceForm = ({
  state,
  setState,
  apiCall,
  id,
  brandData,
  serviceNameData,
  currencySymbol,
}) => {
  const [serviceData, setServiceData] = useState({
    isService: state?.data?.isService ?? false,
    isPackage: state?.data?.isPackage ?? false,
    isActive: state?.data?.isActive ?? false,
    isRatingFloat: false,
  });

  const [oldImagePath, setoldImagePath] = useState("null");

  const { token, role } = useAuthContext();
  const salonDetails = JSON.parse(localStorage.getItem("salon_details"));

  const initialValues = {
    name: state?.data?.name ?? "",
    category: state?.data?.category?.name ?? "",
    order: state?.data?.order ?? 1,
    salonMrp: state?.data?.salonMrp ?? 0,
    salonDiscountPrice: state?.data?.salonDiscountPrice ?? 0,
    homeMrp: state?.data?.homeMrp ?? 0,
    homeDiscountPrice: state?.data?.homeDiscountPrice ?? 0,
    description: state?.data?.description ?? "",
    timings: state?.data?.duration ?? 0,
    brandUsed: state?.data?.brandUsed ?? "",
    noOfPeople: state?.data?.noOfPeople ?? 0,
    rating: state?.data?.rating ?? 0,
    isSalon: state?.data?.isSalon ?? false,
    isHome: state?.data?.isHome ?? false,
    genderType: state?.data?.genderType ?? "",
    image: "",
    role: role,
  };

  const [categories, setCategories] = useState();

  const formik = useFormik({
    initialValues,
    validationSchema: serviceSchema,
    onSubmit: (data) => {
      formSubmit(data);
      // console.log("data", data);
    },
  });

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const serviceImage = useRef(null);
  const handleSingleImg = (e) => {
    e.preventDefault();
    serviceImage.current.click();
  };

  const [singleImage, setSingleImage] = useState(
    state?.data?.image
      ? `${process.env.REACT_APP_Image_BaseURL}${state?.data?.image}`
      : ""
  );
  const imageDataURL = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSingleImage(URL.createObjectURL(e.target.files[0]));
      formik.setFieldValue("image", Array.from(e.target.files));
    }
  };
  const deleteImage = (index) => {
    if (index === -1) {
      formik.setFieldValue("image", "");
      setSingleImage("");
      setoldImagePath(state?.data?.image ? state?.data?.image : null);
      serviceImage.current.value = "";
    }
  };

  const [loader, showLoader, hideLoader] = useLoading();
  const [loader2, showLoader2, hideLoader2] = useLoading();
  const [categoryId, setCategoryId] = useState(
    state?.data?.category?._id ?? ""
  );
  console.log("categoryId", categoryId);
  const formSubmit = async (data) => {
    console.log("form submit called");

    //form data
    const sFormData = new FormData();
    sFormData.append("name", data.name);
    sFormData.append("image", data.image[0]);
    sFormData.append("category", categoryId);
    sFormData.append("isService", serviceData.isService);
    sFormData.append("isPackage", serviceData.isPackage);
    sFormData.append("order", data.order);
    sFormData.append("salonMrp", data.salonMrp);
    sFormData.append("salonDiscountPrice", data.salonDiscountPrice);
    sFormData.append("homeMrp", data.homeMrp);
    sFormData.append("homeDiscountPrice", data.homeDiscountPrice);
    sFormData.append("description", data.description);
    sFormData.append("duration", Number(data.timings));
    sFormData.append("brandUsed", data.brandUsed);
    sFormData.append("isSalon", data.isSalon);
    sFormData.append("isHome", data.isHome);
    sFormData.append("isActive", serviceData.isActive);
    sFormData.append("status", serviceData.isActive ? 1 : 2);
    sFormData.append("genderType", data.genderType);
    sFormData.append("country", currencySymbol);
    if (role === ADMIN) {
      sFormData.append("noOfPeople", data.noOfPeople);
      sFormData.append("rating", data.rating);
    }
    if (state.data) {
      sFormData.append("updateImage", oldImagePath);
    }

    if (token) {
      if (state.data === undefined) {
        try {
          showLoader();
          const res = await axios.post(
            `${SALON_SERVICE_ADD}${
              !state.data ? id : state.data._id
            }/service/form`,
            sFormData,
            {
              //headers: authFormHeader,
              headers: {
                "Content-type": "multipart/form-data",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 201) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Service Added", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      } else {
        try {
          showLoader();
          console.log("update api called");
          const res = await axios.put(
            `${SALON_SERVICES_UPDATE}${state.data._id}/form`,
            sFormData,
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Service Updated", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    }
  };

  const getCategoriesByGender = async () => {
    if (token) {
      try {
        showLoader2();
        const res = await axios.get(SERVICE_CATEGORY_SEARCH, {
          params: {
            gender: formik.values.genderType,
          },
          //headers: authHeader,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });
        if (res.status === 200) {
          setCategories(res.data["serviceCategories"]);
          hideLoader2();
        } else {
          console.log("error", res.status);
        }
      } catch (error) {
        console.log("category API catch error: ", error);
        if (error.response.status === 502) {
          hideLoader2();
          toast.error(error.response.data.message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    }
  };

  useEffect(() => {
    getCategoriesByGender();
  }, [formik.values.genderType]);

  return (
    <>
      <div
        className={
          state?.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">
              {!state?.data ? "New Service" : "Edit Service"}
            </h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>

          <div className="formContent">
            <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
              {/*Name of Service*/}
              <div className="brandWrapper mb-3">
                <CustomDropdown
                  formik={formik}
                  apiData={serviceNameData}
                  label="Name of Service*"
                  htmlFor="servicename"
                  name="name"
                  stateData={formik.values.name}
                />

                {formik.errors.name ? (
                  <InputError error={formik.errors.name} />
                ) : null}
              </div>

              {/* service image */}
              <div className="mb-3">
                <div className="mb-0">
                  <label
                    htmlFor="formFile"
                    className="font12x500 mb-1 form-label"
                  >
                    Image
                  </label>
                  <input
                    ref={serviceImage}
                    className="form-control shadow-none"
                    type="file"
                    id="formFile"
                    name="image"
                    accept={".png, .jpg, .jpeg"}
                    hidden
                    onChange={(e) => {
                      imageDataURL(e);
                    }}
                  />
                </div>
                <div className="uploadImg d-flex align-items-center mb-2">
                  <div
                    className="uploadBtn font12x400 me-3"
                    onClick={(e) => {
                      handleSingleImg(e);
                    }}
                  >
                    Upload
                  </div>
                  <span className="font12x400">
                    Allowed file types: jpg, jpeg, png
                  </span>
                </div>
                {singleImage && (
                  <>
                    <div className="imageWrapper">
                      <img
                        src={singleImage}
                        alt="delete"
                        width={61}
                        height={61}
                      />
                      <div
                        className="deleteDiv"
                        onClick={() => {
                          deleteImage(-1);
                        }}
                      >
                        <img src="/Assets/delete.svg" alt="delete" />
                      </div>
                    </div>
                  </>
                )}
                {formik.errors.image ? (
                  <InputError error={formik.errors.image} />
                ) : null}
              </div>

              {/* gender type */}
              <div className="mb-4">
                <div className="mb-1">
                  <label
                    htmlFor="atsalon"
                    className="font12x500 form-label mb-1"
                  >
                    Gender Type*
                  </label>

                  <div className="container-fluid px-0">
                    <div className="row">
                      {(salonDetails?.type === "male" ||
                        salonDetails?.type === "unisex") && (
                        <RadioButton
                          label="Male"
                          htmlFor="gender"
                          name="gender"
                          value="Male"
                          stateData={formik.values.genderType}
                          setStateData={(e) => {
                            formik.setFieldValue("genderType", e);
                          }}
                        />
                      )}

                      {(salonDetails?.type === "female" ||
                        salonDetails?.type === "unisex") && (
                        <RadioButton
                          label="Female"
                          htmlFor="female"
                          name="gender"
                          value="Female"
                          stateData={formik.values.genderType}
                          setStateData={(e) => {
                            formik.setFieldValue("genderType", e);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {formik.errors.genderType ? (
                    <InputError error={formik.errors.genderType} />
                  ) : null}
                </div>
              </div>

              {/* category */}
              {!loader2 && (
                <div className="mb-3">
                  <DynamicDropdown
                    data={categories}
                    setCategoryId={setCategoryId}
                    label="Category*"
                    htmlFor="category"
                    type="text"
                    placeholder="Select Category"
                    name="category"
                    stateData={formik.values.category}
                    setStateData={(e) => {
                      formik.setFieldValue("category", e);
                    }}
                  />
                  {formik.errors.category ? (
                    <InputError error={formik.errors.category} />
                  ) : null}
                </div>
              )}

              {/* service type */}
              <div className="mb-3">
                <SelectOne
                  label="Service type*"
                  htmlFor1="service"
                  htmlFor2="package"
                  name="serviceType"
                  text1="Service"
                  text2="Package"
                  stateData={serviceData}
                  setStateData={(e, x) => {
                    setServiceData({
                      ...serviceData,
                      isService: e,
                      isPackage: x,
                    });
                  }}
                />
              </div>

              {/* order */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Order*"
                  htmlFor="order"
                  type="tel"
                  name="order"
                  stateData={formik.values.order}
                  setStateData={formik.handleChange}
                  width="120px"
                />
                {formik.errors.order ? (
                  <InputError error={formik.errors.order} />
                ) : null}
              </div>

              {/* service place */}
              <div className="mb-3">
                <SelectBoth
                  label="Service Place*"
                  htmlFor1="salon"
                  htmlFor2="home"
                  text1="At Store"
                  text2="At Home"
                  name1="isSalon"
                  name2="isHome"
                  formik={formik}
                  // stateData={formik.values}
                  // setStateData={formik.handleChange}
                  // stateData={serviceData}
                  // setStateData={setServiceData}
                />
              </div>

              {/* salon price */}
              {formik.values.isSalon && (
                <div className="mb-3">
                  <label
                    htmlFor="atsalon"
                    className="font16x500 form-label mb-1 labelBig"
                  >
                    At Store*
                  </label>

                  {/* salon mrp */}
                  <div className="mb-3">
                    <InputFieldLarge
                      label="MRP*"
                      htmlFor="mrp"
                      type="tel"
                      name="salonMrp"
                      stateData={formik.values.salonMrp}
                      setStateData={formik.handleChange}
                    />
                    {formik.errors.salonMrp ? (
                      <InputError error={formik.errors.salonMrp} />
                    ) : null}
                  </div>

                  {/* salon discounted price */}
                  <div className="mb-3">
                    <InputFieldLarge
                      label="Discounted Price*"
                      htmlFor="d_price"
                      type="tel"
                      name="salonDiscountPrice"
                      stateData={formik.values.salonDiscountPrice}
                      setStateData={formik.handleChange}
                    />
                    {formik.errors.salonDiscountPrice ? (
                      <InputError error={formik.errors.salonDiscountPrice} />
                    ) : null}
                  </div>
                </div>
              )}

              {/* home price */}
              {formik.values.isHome && (
                <div className="mb-3">
                  <label
                    htmlFor="athome"
                    className="font16x500 form-label mb-1 labelBig"
                  >
                    At Home*
                  </label>

                  {/* home mrp */}
                  <div className="mb-3">
                    <InputFieldLarge
                      label="MRP*"
                      htmlFor="mrp"
                      type="tel"
                      name="homeMrp"
                      stateData={formik.values.homeMrp}
                      setStateData={formik.handleChange}
                    />
                    {formik.errors.homeMrp ? (
                      <InputError error={formik.errors.homeMrp} />
                    ) : null}
                  </div>

                  {/* home discounted price */}
                  <div className="mb-3">
                    <InputFieldLarge
                      label="Discounted Price*"
                      htmlFor="d_price"
                      type="tel"
                      name="homeDiscountPrice"
                      stateData={formik.values.homeDiscountPrice}
                      setStateData={formik.handleChange}
                    />
                    {formik.errors.homeDiscountPrice ? (
                      <InputError error={formik.errors.homeDiscountPrice} />
                    ) : null}
                  </div>
                </div>
              )}

              {/* service description */}
              <div className="mb-3">
                <label
                  htmlFor="floatingTextarea2"
                  className="font12x500 mb-1 form-label"
                >
                  Description (optional)
                </label>
                <textarea
                  className="form-control shadow-none"
                  id="floatingTextarea2"
                  style={{ height: "180px" }}
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
                {formik.errors.description ? (
                  <InputError error={formik.errors.description} />
                ) : null}
              </div>

              {/* duration*/}
              <div className="mb-3">
                <InputFieldLarge
                  label="Duration (in minutes)*"
                  htmlFor="duration"
                  type="tel"
                  name="timings"
                  stateData={formik.values.timings}
                  setStateData={formik.handleChange}
                  width="120px"
                />
                {formik.errors.timings ? (
                  <InputError error={formik.errors.timings} />
                ) : null}
              </div>

              {/* brand used*/}
              <div className="brandWrapper mb-3">
                <CustomDropdown
                  formik={formik}
                  apiData={brandData}
                  label="Brand Used (optional)"
                  htmlFor="brand"
                  name="brandUsed"
                  stateData={formik.values.brandUsed}
                />

                {formik.errors.brandUsed ? (
                  <InputError error={formik.errors.brandUsed} />
                ) : null}
              </div>

              {/* service rating */}
              {role === ADMIN && (
                <div className="mb-4">
                  <label
                    htmlFor=""
                    className="font16x500 mb-1 form-label labelBig"
                  >
                    Service Rating*
                  </label>
                  <div className="d-flex">
                    {/* No. of People*/}
                    <div className="mb-3">
                      <InputFieldLarge
                        label="No. of People*"
                        htmlFor="people"
                        type="tel"
                        name="noOfPeople"
                        stateData={formik.values.noOfPeople}
                        setStateData={formik.handleChange}
                        width="120px"
                      />
                      {formik.errors.noOfPeople ? (
                        <InputError error={formik.errors.noOfPeople} />
                      ) : null}
                    </div>

                    <div className="ms-4">
                      {/* Rating out of 5*/}
                      {/* <InputFieldLarge
                      label="Rating out of 5*"
                      htmlFor="rating"
                      type="tel"
                      stateData=""
                      setStateData=""
                      width="120px"
                      maxLength={3}
                      checkFloat={serviceData.isRatingFloat}
                    /> */}
                      <div className="mb-3">
                        <label
                          htmlFor="rating"
                          className="font12x500 form-label mb-1"
                        >
                          Rating out of 5*
                        </label>
                        <input
                          type="text"
                          className="form-control shadow-none"
                          // onKeyUp={(e) => {
                          //   OnlyNumber(e);
                          // }}
                          id="rating"
                          value={formik.values.rating}
                          onChange={formik.handleChange}
                          // onChange={(e) => {
                          //   checkFloat(e);
                          // }}
                          autoComplete="off"
                          style={{ width: "120px" }}
                          maxLength={3}
                        />
                        {formik.errors.rating ? (
                          <InputError error={formik.errors.rating} />
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/* rating(star) */}
                  <div className="">
                    {[...Array(5)].map((v, i) => {
                      return (
                        <span key={i} className="me-2">
                          {formik.values.rating > i &&
                          formik.values.rating <= 5 ? (
                            <ColorStar color="#FFCE00" />
                          ) : (
                            <BlankStar />
                          )}
                        </span>
                      );
                    })}
                  </div>
                  {/* <StarRatings
                  rating={0.5}
                  starEmptyColor="#a6a6a6"
                  starRatedColor="#FFCE00"
                  starDimension="32px"
                  numberOfStars={5}
                /> */}
                </div>
              )}

              {/* service status */}
              <div className="mb-3">
                <SelectOne2
                  label="Service Status*"
                  htmlFor1="active"
                  htmlFor2="inactive"
                  name="status"
                  text1="Active"
                  text2="Inactive"
                  stateData={serviceData.isActive}
                  setStateData={(e) => {
                    setServiceData({
                      ...serviceData,
                      isActive: e,
                    });
                  }}
                />
              </div>

              <SubmitButton
                data={!state.data}
                loader={loader}
                create="Post Service"
                update="Update Service"
              />
            </form>

            {state?.data && <UpdationDetails data={state?.data} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceForm;
