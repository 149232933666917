import React, { useEffect, useState } from "react";
import Layout from "../../../../../Components/Layout";
import Loader from "../../../../../Components/Loader";
import PageNav from "../../../../../Components/PageNav";
import Pagination from "../../../../../Components/Pagination";
import { getAddedOnDateTime } from "../../../../../Functions/dateTime";
import useFetch from "../../../../../hooks/useFetch";
import { SALON_CATEGORY_SEARCH } from "../../../../../utils/Constants";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ToastContainer } from "react-toastify";
import DeleteModal from "../../../../../Components/DeleteRecord";
import SalonCategoryForm from "../../../../../Components/Admin/Categories/SalonCategoryForm";
import { categoriesLinks } from "../Service";
import DropdownSmall from "../../../../../Shared Components/DropdownSmall";
import SearchInput2 from "../../../../../Shared Components/SearchInput2";
import salonImage from "../../../../../uploads/salonCategory/salonCategory1.jpg";
import MobileSalonDashboardHeader from "../../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileCategoriesFilters from "../../../../../Components/Mobile/Admin/mobileCategoriesFilter";
import MobileFilterHeader from "../../../../../Components/mobilefilterheader";

const SalonCategory = () => {
  const [commonData, setCommonData] = useState({
    data: {},
    modal: false,
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [id, setId] = useState("");

  const [param, setParam] = useState({ show: false });
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const functions = {
    onEdit: (item) => {
      setCommonData({
        data: item,
        modal: true,
      });
      document.body.style.overflow = "hidden";
    },
    onAdd: () => {
      setCommonData({
        data: undefined,
        modal: true,
      });
      document.body.style.overflow = "hidden";
    },
    // onDelete: (id) => {
    //   setIsDeleteModalOpen(true);
    //   document.body.style.overflow= "hidden";
    //   setId(id);
    // },
    addParam: () => {
      setFilter({
        ...filters,
        ...param,
      });
    },
    resetParam: () => {
      setParam({ show: false });
      delete filters.status;
      delete filters.q;
      delete filters.statusName;
      getData();
    },
  };

  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
    sort_by: "order",
    sort_order: "desc",
  });
  console.log(filters);

  const [salonCategories, totalResult, error, loader, getData] = useFetch({
    url: `${SALON_CATEGORY_SEARCH}`,
    resultField: "salonCategories",
    parameter: filters,
  });

  useEffect(() => {
    window.localStorage.removeItem("salon_details");
    getData();
  }, [filters]);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Salon Categories"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileCategoriesFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          onClick={functions.onAdd}
          filters={filters}
          setFilter={setFilter}
          resetParam={functions.resetParam}
          param={param}
          setParam={setParam}
          addParam={functions.addParam}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Salon Categories"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Salon Categories"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper">
        <div>
          <div className="service-navigation-neworder-button-container">
            <div className="service-navigation-neworder-button d-flex justify-content-between pe-2">
              <PageNav links={categoriesLinks} />
              <div className="desktopButton">
                <button
                  className="btn addBtn shadow-none"
                  onClick={functions.onAdd}
                >
                  Add Store Category
                </button>
              </div>
            </div>
          </div>

          <div className="filter-container container mt-4 ms-0 ps-0 mb-3">
            <div className="row">
              <div className="col-5 col-lg-5 d-flex align-items-end">
                <SearchInput2
                  type="text"
                  placeholder="Search by name"
                  stateData={param}
                  setStateData={setParam}
                  parameter1="name"
                  parameter2={undefined}
                  addParam={functions.addParam}
                  resetParam={functions.resetParam}
                />
              </div>
              <div className="col-7 col-lg-7 d-flex justify-content-end align-items-end">
                {/* status */}
                <DropdownSmall
                  data={[
                    { value: "All", label: "All" },
                    { value: "Active", label: "Active" },
                    { value: "Inactive", label: "Inactive" },
                  ]}
                  label="Status"
                  htmlFor="status"
                  type="text"
                  stateData={filters?.statusName}
                  setStateData={(e) => {
                    if (e === "All") {
                      delete filters?.status;
                      setFilter({
                        ...filters,
                        statusName: e,
                      });
                    } else {
                      setFilter({
                        ...filters,
                        statusName: e === "Active" ? "Active" : "Inactive",
                        status: e === "Active" ? 1 : 2,
                      });
                    }
                  }}
                />

                <button
                  className="btn resetBtn shadow-none ms-3"
                  onClick={functions.resetParam}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>

        {salonCategories?.length !== 0 ? (
          <div className="container dataTable ms-0 ps-0">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Image</th>
                  <th scope="col">Order</th>
                  <th scope="col">Created On</th>
                  <th scope="col">Updated on</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {salonCategories?.map((item, i) => {
                  return (
                    <tr key={item?._id}>
                      <td>{item?.name}</td>
                      <td>
                        {item?.image === null ? (
                          <img
                            src={salonImage}
                            alt="s_category"
                            width={72}
                            height={48}
                            style={{ borderRadius: "4px" }}
                          />
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_Image_BaseURL}${item?.image}`}
                            alt="s_category"
                            width={72}
                            height={48}
                            style={{ borderRadius: "4px" }}
                          />
                        )}
                      </td>
                      <td>{item?.order}</td>
                      <td>{getAddedOnDateTime(item?.createdAt)}</td>
                      <td>{getAddedOnDateTime(item?.updatedAt)}</td>
                      <td>
                        <p
                          className="status mb-0"
                          style={{
                            backgroundColor: `${
                              item?.status === 1
                                ? "var(--status_color2)"
                                : "var(--status_color1)"
                            }`,
                          }}
                        >
                          {`${item?.status === 1 ? "Active" : "Inactive"}`}
                        </p>
                      </td>
                      <td>
                        <button
                          className="btn font14x400 filterBtn shadow-none me-2"
                          onClick={() => {
                            functions.onEdit(item);
                          }}
                        >
                          Edit
                        </button>
                        {/* <span
                          className="deleteBtn"
                          onClick={() => {
                            functions.onDelete(item?._id);
                          }}
                        >
                          <RiDeleteBin6Line />
                        </span> */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Store Category Found"
            )}
          </div>
        )}

        {salonCategories?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      {/* delete modal */}
      {/* {isDeleteModalOpen && (
        <DeleteModal
          state={isDeleteModalOpen}
          setState={setIsDeleteModalOpen}
          url={USER_DELETE}
          id={id}
          text="Service Category"
          apiCall={getData}
        />
      )} */}

      {/* service category form add and update both */}
      {commonData.modal && (
        <SalonCategoryForm
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default SalonCategory;
