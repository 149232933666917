import React from "react";

const FilterRadioButton = (props) => {
  const { key, label, htmlFor, name, setStateData, stateData } = props;
  return (
    <>
      <div
        key={key}
        className="mb-3 d-flex justify-content-between align-items-center"
      >
        <label className="font14x400 form-check-label" htmlFor={htmlFor}>
          {label}
        </label>

        <div className="radioCustom">
          <input
            className="m-0"
            type="radio"
            id={htmlFor}
            name={name}
            value={stateData}
            onChange={(e) => {
              setStateData(e.target.value);
            }}
            // checked={stateData === value ? true : false}
          />
        </div>
      </div>
    </>
  );
};

export default FilterRadioButton;
