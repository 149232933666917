import React, { useContext, useEffect, useState } from "react";
import {
  authHeader,
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  GET_CHILD_SALONS,
  MASTER_SALON,
  SALON_BY_ID,
} from "../../utils/Constants";
import salonLogo from "../../uploads/salon/logo1.jpg";
import CartHeader from "../CartHeader";
import useAuthContext from "../../hooks/useAuthContext";
import CartPaymentProcess from "../CartPaymentProcess";
import { toast } from "react-toastify";
import axios from "axios";
import { CartContext } from "../../utils/Private Routes/MasterSalonPrivateRoutes";
import ChildSalonProcess from "../ChildSalonProcess";
import useFetch from "../../hooks/useFetch";
import useLoading from "../../hooks/useLoading";

const Header = () => {
  const [commonData, setCommonData] = useState({
    modal: false,
    step: 0,
    check: "",
  });

  const { getCart } = useContext(CartContext);

  const { token, role } = useAuthContext();

  const salonDetails = JSON.parse(localStorage.getItem("salon_details"));
  const [loader, showLoader, hideLoader] = useLoading();

  const [childSalons, totalResult, error, loader2, getData] = useFetch({
    url: `${GET_CHILD_SALONS}${
      salonDetails?.masterSalon ? salonDetails?.masterSalon : salonDetails?._id
    }/childSalon`,
    resultField: "childSalons",
  });

  useEffect(() => {
    getData();
  }, []);

  const functions = {
    logOut: () => {
      window.localStorage.clear();
      window.location.href = "/";
    },
    onClick: () => {
      setCommonData({
        modal: true,
        step: 1,
        check: "cart",
      });
      document.body.style.overflow = "hidden";
    },
    addItemIntoCart: async (id, count, stock) => {
      let actualCount = count ? count + 1 : 1;
      if (actualCount <= stock) {
        if (token && actualCount) {
          try {
            const res = await axios.put(
              `${CART_ADD_ITEM}?product=${id}&count=${actualCount}`,
              {},
              {
                //headers: authHeader,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                  "X-Requested-With": "XMLHttpRequest",
                },
              }
            );

            if (res.status === 200) {
              getCart();
              toast.success("Item added into cart", {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            } else {
              console.log("else error: ", res.status);
            }
          } catch (error) {
            console.log("catch error: ", error);
            if (error?.response?.status === 502) {
              toast.error(error?.response?.data?.message, {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            }
          }
        }
      } else {
        toast.error("Maximum Limit Exceeded", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    },
    removeItem: async (id) => {
      if (token) {
        try {
          const res = await axios.put(
            `${CART_REMOVE_ITEM}?product=${id}`,
            {},
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            getCart();
            toast.success("Item Removed", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
    removeItemFromCart: async (id, count) => {
      if (count > 1) {
        if (token) {
          try {
            const res = await axios.put(
              `${CART_ADD_ITEM}?product=${id}&count=${count - 1}`,
              {},
              {
                //headers: authHeader,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                  "X-Requested-With": "XMLHttpRequest",
                },
              }
            );

            if (res.status === 200) {
              getCart();
              toast.success("Item Removed", {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            } else {
              console.log("else error: ", res.status);
            }
          } catch (error) {
            console.log("catch error: ", error);
            if (error?.response?.status === 502) {
              toast.error(error?.response?.data?.message, {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            }
          }
        }
      } else {
        if (token) {
          try {
            const res = await axios.put(
              `${CART_REMOVE_ITEM}?product=${id}`,
              {},
              {
                //headers: authHeader,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                  "X-Requested-With": "XMLHttpRequest",
                },
              }
            );

            if (res.status === 200) {
              getCart();
              toast.success("Item Removed", {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            } else {
              console.log("else error: ", res.status);
            }
          } catch (error) {
            console.log("catch error: ", error);
            if (error?.response?.status === 502) {
              toast.error(error?.response?.data?.message, {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            }
          }
        }
      }
    },
    addChildSalon: () => {
      setCommonData({
        modal: true,
        step: 1,
        check: "child-salon",
      });
      document.body.style.overflow = "hidden";
    },
    loginAsChildSalon: async (id) => {
      if (token && id) {
        try {
          showLoader();
          const res = await axios.get(`${SALON_BY_ID}${id}`, {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });

          if (res.status === 200) {
            window.localStorage.setItem(
              "salon_details",
              JSON.stringify(res?.data?.salon)
            );
            hideLoader();
            window.location.reload();
          } else {
            console.log("error", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            toast.error(error.response.data.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
  };

  return (
    <>
      <div className="dashboardNavbarWrapper">
        <div className="container-fluid headerDetails">
          <div className="menuWrapper d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              {salonDetails?.logo === null ? (
                <img
                  src={salonLogo}
                  alt="salon"
                  className="me-3"
                  width={36}
                  height={36}
                  style={{ borderRadius: "4px" }}
                />
              ) : (
                <img
                  src={`${process.env.REACT_APP_Image_BaseURL}${salonDetails?.logo}`}
                  alt="salon"
                  className="me-3"
                  width={36}
                  height={36}
                  style={{ borderRadius: "4px" }}
                />
              )}

              <p className="mb-0 font16x400">{`${salonDetails?.name}, ${salonDetails?.address?.city}`}</p>

              {role === MASTER_SALON && childSalons?.length !== 0 && (
                <div className="childSalonsDropdown">
                  <img
                    src="/Assets/angleDown.svg"
                    alt="salon"
                    width={25}
                    height={25}
                    className="ms-1"
                  />

                  <div className="hoverChildSalons">
                    <ul className="list-unstyled m-0">
                      {salonDetails?.masterSalon && (
                        <li
                          className="childSalonList"
                          onClick={() => {
                            functions.loginAsChildSalon(
                              salonDetails?.masterSalon
                            );
                          }}
                        >
                          Return to Master
                        </li>
                      )}

                      {childSalons &&
                        childSalons?.map((item) => {
                          return (
                            <li
                              key={item?._id}
                              className="childSalonList"
                              onClick={() => {
                                functions.loginAsChildSalon(item?._id);
                              }}
                            >{`${item?.salonId}_${item?.name}`}</li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              )}
            </div>

            <div className="menuContainer d-flex align-items-center">
              <div className="d-flex align-items-center me-5">
                <div className="cartImageWrapper" style={{ cursor: "default" }}>
                  <img
                    src="/Assets/headerWallet.svg"
                    alt="cart"
                    width={48}
                    height={48}
                  />
                </div>
                <p
                  className="mb-0 font16x500 ms-2"
                  style={{
                    color: salonDetails?.wallet < 0 && "var(--status_color4)",
                  }}
                >{`${
                  salonDetails?.country?.htmlSymbol !== ""
                    ? String.fromCharCode(salonDetails?.country?.htmlSymbol)
                    : salonDetails?.country?.currency
                }${parseInt(salonDetails?.wallet).toLocaleString()}`}</p>
              </div>

              <CartHeader displayOn="header" onClick={functions.onClick} />

              <div className="d-flex ms-5">
                {role === MASTER_SALON && (
                  <button
                    className="btn font14x400 filterBtn shadow-none"
                    onClick={functions.addChildSalon}
                  >
                    Add Your Store
                  </button>
                )}

                <button
                  className="btn font14x400 filterBtn shadow-none ms-3"
                  onClick={functions.logOut}
                >
                  Log Out
                </button>
              </div>
            </div>
          </div>
        </div>
        {salonDetails?.wallet < 0 && (
          <div className="balanceError">
            Your wallet balance is low please contact the admin to recharge.
          </div>
        )}
      </div>

      {/* cart */}
      {commonData.modal && commonData.check === "cart" && (
        <CartPaymentProcess
          state={commonData}
          setState={setCommonData}
          addItemIntoCart={functions.addItemIntoCart}
          removeItemFromCart={functions.removeItemFromCart}
          removeItem={functions.removeItem}
        />
      )}

      {/* cart */}
      {commonData.modal && commonData.check === "child-salon" && (
        <ChildSalonProcess state={commonData} setState={setCommonData} />
      )}
    </>
  );
};

export default Header;
