import React from "react";

const PaymentDetails = ({ cart }) => {
  return (
    <>
      <div className="paymentDetailsWrapper py-3">
        <h2 className="font14x500 mb-0 pb-2">Payment Details</h2>
        <div className="paymentDetailsContainer py-2">
          <div className="d-flex justify-content-between">
            <span className="font14x400" style={{ color: "var(--text4)" }}>
              Subtotal (inclusive tax):
            </span>
            <span className="font14x500">{`${String.fromCharCode(
              cart.products[0].product?.currencySymbol
            )}${cart?.cartCalculations?.products?.total}`}</span>
          </div>
          <div
            className="d-flex justify-content-between"
            style={{ color: "var(--btn_primary)" }}
          >
            <span className="font14x400">Discount:</span>
            <span className="font14x500">{`- ${String.fromCharCode(
              cart.products[0].product?.currencySymbol
            )}${cart?.cartCalculations?.products?.discount}`}</span>
          </div>
          <div
            className="d-flex justify-content-between"
            style={{ color: "var(--btn_primary)" }}
          >
            <span className="font14x400">Coupon Discount:</span>
            <span className="font14x500">{`- ${String.fromCharCode(
              cart.products[0].product?.currencySymbol
            )}${cart?.cartCalculations?.products?.couponDiscount}`}</span>
          </div>
          <div
            className="d-flex justify-content-between"
            style={{ color: "var(--btn_primary)" }}
          >
            <span className="font14x400">Delivery Charges:</span>
            <span className="font14x500">{`+ ${String.fromCharCode(
              cart.products[0].product?.currencySymbol
            )}${cart?.cartCalculations?.products?.deliveryCharges}`}</span>
          </div>
        </div>
        <div className="d-flex justify-content-between py-2">
          <span className="font16x700">Grand Total:</span>
          <span className="font14x500">{`${String.fromCharCode(
            cart.products[0].product?.currencySymbol
          )}${cart?.cartCalculations?.products?.grandTotal}`}</span>
        </div>
      </div>
    </>
  );
};

export default PaymentDetails;
