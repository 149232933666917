import React, { useRef, useState } from "react";
import { CgClose } from "react-icons/cg";
import axios from "axios";
import useAuthContext from "../../hooks/useAuthContext";
import { SALON_SERVICE_UPLOAD } from "../../utils/Constants";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { uploadExcelSchema } from "../../utils/validationschema";
import InputError from "../../Shared Components/InputError";
import useLoading from "../../hooks/useLoading";
import Loader from "../Loader";

const UploadServiceForm = ({ state, setState, apiCall, id }) => {
  const { token } = useAuthContext();
  const [resObj, setResObj] = useState();
  const initialValues = {
    uploadExcel: [],
  };

  const [singleDoc, setSingleDoc] = useState("");
  const imageDataURL = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log(e.target.files[0]);
      setSingleDoc(e.target.files[0].name);
      formik.setFieldValue("uploadExcel", Array.from(e.target.files));
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: uploadExcelSchema,
    onSubmit: (data) => {
      formSubmit(data);
      console.log("data", data);
    },
  });

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const fileUpload = useRef(null);
  const handleSingleImg = (e) => {
    e.preventDefault();
    fileUpload.current.click();
  };

  const [loader, showLoader, hideLoader] = useLoading();

  const formSubmit = async (data) => {
    console.log("form submit called");

    //form data
    const sFormData = new FormData();
    sFormData.append("excelFile", data.uploadExcel[0]);

    if (token) {
      try {
        showLoader();
        const res = await axios.post(
          `${SALON_SERVICE_UPLOAD}${id}/service/upload/form`,
          sFormData,
          {
            //headers: authFormHeader,
            headers: {
              "Content-type": "multipart/form-data",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );

        if (res.status === 200) {
          setResObj(res.data);
          apiCall();
          hideLoader();
          toast.success("File Uploaded", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          console.log("else error: ", res.status);
        }
      } catch (error) {
        console.log("catch error: ", error);
        if (error.response.status === 502) {
          hideLoader();
          toast.error(error?.response?.data?.message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    }
  };

  const downloadReport = (link) => {
    window.open(link);
    setPositionToBody();
  };

  return (
    <>
      <div
        className={
          state?.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv"></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">
              {resObj?.error.isError ? "Upload Error" : "Upload Service"}
            </h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>

          <div className="formContent">
            {resObj?.error.isError ? (
              <div className="">
                <p className="mb-0 mt-2 font14x500 textColor4">
                  Data Uploaded: <span>{resObj?.uploadedTotal}</span>
                </p>
                <button
                  className="btn OtpBtn font16x500 mt-3 shadow-none"
                  onClick={() =>
                    downloadReport(
                      `${
                        process.env.REACT_APP_API_URL
                      }/download?downloadFileName=${encodeURIComponent(
                        resObj?.error?.fileName
                      )}&saveFileName=${encodeURIComponent(
                        `service_upload_error`
                      )}&type=${encodeURIComponent(resObj?.error?.type)}`
                    )
                  }
                >
                  Download Error File
                </button>
              </div>
            ) : (
              <form
                onSubmit={formik.handleSubmit}
                encType="multipart/form-data"
              >
                {/* service image */}
                <div className="mb-3">
                  <div className="mb-0">
                    <label
                      htmlFor="formFile"
                      className="font12x500 mb-1 form-label"
                    >
                      Choose a file
                    </label>
                    <input
                      ref={fileUpload}
                      className="form-control shadow-none"
                      type="file"
                      id="formFile"
                      name="uploadExcel"
                      accept={".xlsx,.xls"}
                      hidden
                      onChange={(e) => {
                        imageDataURL(e);
                      }}
                    />
                  </div>
                  <div className="uploadImg d-flex align-items-center mb-2">
                    <div
                      className="uploadBtn font12x400 me-3"
                      onClick={(e) => {
                        handleSingleImg(e);
                      }}
                    >
                      Upload
                    </div>
                    <span className="font12x400">Allowed file types: xlsx</span>
                  </div>
                  {singleDoc && <p className="mb-0">{singleDoc}</p>}
                  {formik.errors.uploadExcel ? (
                    <InputError error={formik.errors.uploadExcel} />
                  ) : null}
                </div>

                <button
                  type="Submit"
                  className="btn OtpBtn shadow-none mt-3 font16x500"
                >
                  {loader ? (
                    <div className="d-flex justify-content-center">
                      <Loader height="25" width="25" color="#fff" />
                    </div>
                  ) : (
                    "Upload"
                  )}
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadServiceForm;
