import moment from "moment";
import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import useFetch from "../../../hooks/useFetch";
import { STYLIST_ATTENDANCE_LIST } from "../../../utils/Constants";
import Loader from "../../Loader";

const setColor = (value) => {
  switch (value) {
    case "A":
      return "#F7635B";
    case "P":
      return "#11CC93";
    case "HD":
      return "#FFCE00";
    case "WO":
      return "#A6A6A6";
    default:
      return -1;
  }
};

const AttandanceSheet = ({ state, setState }) => {
  const [data, setData] = useState([]);
  const [currentMonthData, setCurrentMonth] = useState(false);
  // console.log("data", data);
  const [counts, setCounts] = useState({
    A: 0,
    P: 0,
    HD: 0,
    WO: 0,
    total: 0,
  });

  const [filters, setFilter] = useState({
    stylist: state?.data && state?.data,
    sort_order: "asc",
    sort_by: "date",
    status: 1,
    date: moment().startOf("month").format("YYYY-MM-DD"),
  });
  // console.log(filters);

  const functions = {
    setPositionToBody: () => {
      setState({
        ...state,
        modal: false,
        check: "",
      });
      document.body.style.overflow = "unset";
    },
    generateFormattedAttendance: (stylistAttendance) => {
      let A = 0,
        P = 0,
        HD = 0,
        WO = 0,
        total = 0;
      const formattedAttendance = [];
      let startMonthDate = "";

      if (!currentMonthData) {
        startMonthDate = moment().startOf("month").format("YYYY-MM-DD");
      } else {
        startMonthDate = moment()
          .startOf("month")
          .subtract(1, "month")
          .format("YYYY-MM-DD");
      }

      const date = new Date(startMonthDate);

      const currentMonth = date.getMonth();
      let index = 0,
        loopCounter = 1;
      let weekArray = [];
      while (loopCounter <= 31) {
        if (date.getMonth() == currentMonth) {
          if (stylistAttendance[index]?.date === date.toISOString()) {
            const { date, attendance, value } = stylistAttendance[index];
            total += value;
            if (attendance == "P") P++;
            else if (attendance == "WO") WO++;
            else if (attendance == "A") A++;
            else HD++;
            weekArray.push({ date, attendance, value });
            index++;
          } else {
            weekArray.push({ date: date.toISOString() });
          }
          date.setDate(date.getDate() + 1);
        } else {
          weekArray.push({});
        }
        if (loopCounter % 7 == 0) {
          formattedAttendance.push(weekArray);
          weekArray = [];
        }
        if (loopCounter == 31) {
          formattedAttendance.push(weekArray);
        }
        loopCounter++;
      }
      return { formattedAttendance, A, P, HD, WO, total };
    },
    execute: (stylistAttendance) => {
      let { formattedAttendance, A, P, HD, WO, total } =
        functions.generateFormattedAttendance(stylistAttendance);
      setCounts({
        A,
        P,
        HD,
        WO,
        total,
      });
      setData([]);
      formattedAttendance.map((element) =>
        setData((old) => {
          return [...old, element];
        })
      );
    },
    monthToggle: () => {
      if (currentMonthData) {
        setCurrentMonth(false);
        setFilter((old) => {
          return {
            ...old,
            date: moment().startOf("month").format("YYYY-MM-DD"),
          };
        });
      } else {
        setCurrentMonth(true);
        setFilter((old) => {
          return {
            ...old,
            date: moment()
              .subtract(1, "month")
              .startOf("month")
              .format("YYYY-MM-DD"),
          };
        });
      }
    },
  };

  const [stylistsAttendance, totalResult, error, loader, getData] = useFetch({
    url: `${STYLIST_ATTENDANCE_LIST}`,
    resultField: "stylistsAttendance",
    parameter: filters,
  });

  useEffect(() => {
    getData();
  }, [filters]);

  useEffect(() => {
    functions.execute(stylistsAttendance);
  }, [stylistsAttendance]);

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={functions.setPositionToBody}></div>
        <div className="formContainer" style={{ width: "auto" }}>
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">Monthly attendance view</h1>
            <div className="d-flex">
              <div className="desktopButton">
                <button
                  className="btn font14x400 formHeadingBtn shadow-none me-2"
                  onClick={() => {
                    functions.monthToggle();
                  }}
                >
                  {`${currentMonthData ? "Current Month" : "Previous Month"}`}
                </button>
              </div>
              <span onClick={functions.setPositionToBody}>
                <CgClose />
              </span>
            </div>
          </div>

          {loader ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "100%", height: "50vh" }}
            >
              <Loader height="40" width="40" color="#772286" />
            </div>
          ) : (
            <div className="formContent">
              <div className="mobile">
                <button
                  className="btn font14x400 formHeadingBtn shadow-none mb-3"
                  onClick={() => {
                    functions.monthToggle();
                  }}
                >
                  {`${currentMonthData ? "Current Month" : "Previous Month"}`}
                </button>
              </div>

              {/* Attendance counts */}
              <div className="attendanceWrapper mb-4">
                <table className="attendanceContainer">
                  <thead>
                    <tr>
                      <th>
                        <div className="d-flex justify-content-center">
                          <p
                            className="attandanceCheck m-0"
                            style={{
                              backgroundColor: setColor("P"),
                              color: "#fff",
                              border: `1px solid ${setColor("P")}`,
                            }}
                          >
                            P
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-center">
                          <p
                            className="attandanceCheck m-0"
                            style={{
                              backgroundColor: setColor("A"),
                              color: "#fff",
                              border: `1px solid ${setColor("A")}`,
                            }}
                          >
                            A
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-center">
                          <p
                            className="attandanceCheck m-0"
                            style={{
                              backgroundColor: setColor("WO"),
                              color: "#fff",
                              border: `1px solid ${setColor("WO")}`,
                            }}
                          >
                            WO
                          </p>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex justify-content-center">
                          <p
                            className="attandanceCheck m-0"
                            style={{
                              backgroundColor: setColor("HD"),
                              color: "#fff",
                              border: `1px solid ${setColor("HD")}`,
                            }}
                          >
                            HD
                          </p>
                        </div>
                      </th>
                      <th>
                        <p className="mb-0">Total</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="attendance_status">{counts?.P}</td>
                      <td className="attendance_status">{counts?.A}</td>
                      <td className="attendance_status">{counts?.WO}</td>
                      <td className="attendance_status">{counts?.HD}</td>
                      <td className="attendance_status">{counts?.total / 2}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* attendance calender */}
              {data &&
                data?.map((item, index) => {
                  return (
                    <div className="attendanceWrapper mb-4" key={index}>
                      <table className="attendanceContainer">
                        <thead>
                          <tr>
                            <th>Date</th>
                            {item?.map((item2, i) => {
                              return <th key={i}>{index * 7 + 1 + i}</th>;
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="attendance_status">Status</td>
                            {item?.map((item2, i) => {
                              return (
                                <td key={i}>
                                  {item2?.attendance ? (
                                    <span
                                      className="attandanceCheck"
                                      style={{
                                        backgroundColor: setColor(
                                          item2?.attendance
                                        ),
                                        color: "#fff",
                                        border: `1px solid ${setColor(
                                          item2?.attendance
                                        )}`,
                                      }}
                                    >
                                      {item2?.attendance}
                                    </span>
                                  ) : (
                                    "--"
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AttandanceSheet;
