import React, { useEffect, useState } from "react";
import Layout from "../../../../Components/Layout";
import useFetch from "../../../../hooks/useFetch";
import { ADMIN_ORDER_SEARCH, productSteps } from "../../../../utils/Constants";
import { getAddedOnDate } from "../../../../Functions/dateTime";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../../Components/Pagination";
import moment from "moment";
import InputDate from "../../../../Shared Components/InputDate";
import DropdownSmall from "../../../../Shared Components/DropdownSmall";
import SearchInput2 from "../../../../Shared Components/SearchInput2";
import Loader from "../../../../Components/Loader";
import PageNav from "../../../../Components/PageNav";
import { toast, ToastContainer } from "react-toastify";
import { ordersPageLinks } from "../Product";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";
import MobileAdminProductOrderFilters from "../../../../Components/Mobile/Admin/mobileAdminProductOrderFilter";

const AdminProductOrder = () => {
  const [param, setParam] = useState({});
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [paymentMode, setPaymentMode] = useState("");
  // console.log("paymentMode", paymentMode);

  const navigate = useNavigate();
  const functions = {
    viewProductOrderDetails: (order) => {
      navigate(`/admin/admin-order/product/${order?._id}`);
    },
    addParam: () => {
      if (param.fromDate && param.tillDate) {
        const checkDate = moment(param.tillDate, "YYYY-MM-DD").isAfter(
          moment(param.fromDate, "YYYY-MM-DD")
        );
        if (checkDate) {
          setFilter({
            ...filters,
            ...param,
          });
        } else {
          toast.error("From date must be less than till date.", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    },
    resetParam: () => {
      setParam({});
      delete filters.q;
      delete filters.fromDate;
      delete filters.tillDate;
      delete filters["productOrders.productsProcessingStatus"];
      delete filters["productOrders.productsProcessingStatusName"];
      getData();
    },
  };

  const [filters, setFilter] = useState({
    productOrders: "notNull",
    page: 1,
    per_page: 10,
  });
  console.log(filters);

  const [orders, totalResult, error, loader, getData] = useFetch({
    url: `${ADMIN_ORDER_SEARCH}`,
    resultField: "orders",
    parameter: filters,
  });

  useEffect(() => {
    window.localStorage.removeItem("salon_details");
    getData();
  }, [filters]);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Admin Product Orders"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileAdminProductOrderFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          param={param}
          setParam={setParam}
          addParam={functions.addParam}
          resetParam={functions.resetParam}
          filters={filters}
          setFilter={setFilter}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Admin Product Orders"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Admin Product Orders"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper">
        <div className="service-navigation-neworder-button-container">
          <div className="service-navigation-neworder-button">
            <PageNav links={ordersPageLinks} />
          </div>
        </div>

        <div className="filter-container container mt-4 ms-0 ps-0 mb-3">
          <div className="d-flex justify-content-between align-items-end">
            {/* search */}
            <SearchInput2
              type="text"
              placeholder="Search by Order Id"
              stateData={param}
              setStateData={setParam}
              parameter1="adminOrderId"
              parameter2={undefined}
              width={190}
              addParam={functions.addParam}
              resetParam={functions.resetParam}
            />

            {/* Payment Mode */}
            <DropdownSmall
              data={[
                { value: "COD", label: "COD" },
                { value: "PayU", label: "PayU" },
              ]}
              label="Payment Mode"
              htmlFor="order"
              type="text"
              placeholder="All Orders"
              stateData={paymentMode}
              setStateData={(e) => {
                setPaymentMode(e);
              }}
            />

            {/* Order Status */}
            <DropdownSmall
              data={[
                { value: "Pending", label: "Pending" },
                { value: "Confirmed", label: "Confirmed" },
                { value: "Shipped", label: "Shipped" },
                { value: "Delivered", label: "Delivered" },
                { value: "Cancelled", label: "Cancelled" },
              ]}
              label="Order Status"
              htmlFor="Sort"
              type="text"
              placeholder="Sort By"
              stateData={filters["orderProcessingStatusName"]}
              setStateData={(e) => {
                setFilter({
                  ...filters,
                  orderProcessingStatusName: e,
                  orderProcessingStatus:
                    e === "Pending"
                      ? "0"
                      : e === "Confirmed"
                      ? "1"
                      : e === "Shipped"
                      ? "2"
                      : e === "Delivered"
                      ? "3"
                      : "4",
                });
              }}
            />

            {/* date from */}
            <InputDate
              label="From"
              htmlFor="from"
              type="date"
              stateData={param?.fromDate}
              setStateData={(e) => {
                setParam({
                  ...param,
                  fromDate: moment(e).format("YYYY-MM-DD"),
                });
              }}
            />

            {/* date to */}
            <InputDate
              label="To"
              htmlFor="to"
              type="date"
              stateData={param?.tillDate}
              setStateData={(e) => {
                setParam({
                  ...param,
                  tillDate: moment(e).format("YYYY-MM-DD"),
                });
              }}
            />

            <button
              className="btn filterBtn shadow-none"
              onClick={functions.addParam}
            >
              Apply
            </button>
            <button
              className="btn resetBtn shadow-none"
              onClick={functions.resetParam}
            >
              Reset
            </button>
          </div>
        </div>

        {orders?.length !== 0 ? (
          <div className="container dataTable ms-0 ps-0">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Order Id</th>
                  <th scope="col">Store Details</th>
                  <th scope="col">Order Date</th>
                  <th scope="col">Total Amount</th>
                  <th scope="col">Payment Mode</th>
                  <th scope="col">Order Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {orders?.map((order, i) => {
                  return (
                    <tr key={i}>
                      <td>{order?.adminOrderId}</td>
                      <td>
                        {`${order?.salon?.salonId}_${order?.salon?.name}`}
                        <br />
                        {order?.salon?.contactNumber}
                      </td>
                      <td>{getAddedOnDate(order?.createdAt)}</td>
                      <td>{`${String.fromCharCode(
                        order?.products[0]?.product?.currencySymbol
                      )} ${order?.cartCalculations?.products?.grandTotal?.toFixed(
                        2
                      )}`}</td>
                      <td>{order?.adminTransactions[0]?.paymentMode}</td>
                      <td className="text-capitalize">
                        {productSteps[order?.orderProcessingStatus]}
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn font14x400 filterBtn shadow-none me-2"
                          onClick={() =>
                            functions.viewProductOrderDetails(order)
                          }
                          // disabled={
                          //   order?.adminTransactions[
                          //     order?.transactions?.length - 1
                          //   ]?.paymentStatus === "Success"
                          //     ? false
                          //     : true
                          // }
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Product Orders Found"
            )}
          </div>
        )}

        {orders?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      <ToastContainer />
    </>
  );
};

export default AdminProductOrder;
