import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";

const DynamicDropdown = (props) => {
  const {
    data,
    htmlFor,
    label,
    name,
    stateData,
    setStateData,
    placeholder,
    setSalonId,
  } = props;

  const [options, setOptions] = useState([
    {
      id: "",
      value: "None",
      label: "None",
    },
  ]);
  const pushObj = useCallback(() => {
    data.forEach((item) => {
      setOptions((current) => [
        ...current,
        {
          id: item?._id,
          value: `${item?.salonId}_${item?.name}`,
          label: `${item?.salonId}_${item?.name}`,
        },
      ]);
    });
  }, [data]);

  useEffect(() => {
    if (data) {
      pushObj();
    }
  }, [data]);

  const styles = {
    control: (styles, { isFocused }) => {
      return {
        ...styles,
        borderRadius: "8px",
        textAlign: "left",
        border: "1px solid var(--border_primary)",
        border: isFocused
          ? "1px solid var(--border_primary)"
          : "1px solid var(--border_primary)",
        boxShadow: "none",
        cursor: "pointer",
        height: "48px",
      };
    },
    indicatorSeparator: (styles) => {
      return {
        display: "none",
      };
    },
    placeholder: (styles) => {
      return {
        ...styles,
        fontSize: "16px",
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        fontSize: "16px",
        textTransform: "capitalize",
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        borderRadius: "8px",
        margin: 0,
        backgroundColor: "var(--border_secondary)",
      };
    },
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        color: "#000",
        backgroundColor: isSelected && "#fff",
        borderBottom: "1px solid var(--border_primary)",
        textAlign: "left",
        margin: 0,
        cursor: "pointer",
        fontSize: "16px",
        padding: "10px 11px",
        textTransform: "capitalize",
        ":last-child": {
          borderBottom: "none",
        },
      };
    },
  };

  return (
    <>
      <div className="selectDropdown">
        {label && (
          <label htmlFor={htmlFor} className="font12x500 form-label mb-1">
            {label}
          </label>
        )}
        <Select
          options={options}
          placeholder={placeholder}
          styles={styles}
          name={name}
          value={options.filter((option) => {
            return option.value === stateData;
          })}
          onChange={(e) => {
            setStateData(e.value);
            setSalonId(e.id);
          }}
        />
      </div>
    </>
  );
};

export default DynamicDropdown;
