import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import Calender from "react-awesome-calendar";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../../Components/Layout";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import { getDateTime } from "../../../../Functions/dateTime";
import {
  getColorAccToStylist,
  getPrettyFormat,
} from "../../../../Functions/other";
import useFetch from "../../../../hooks/useFetch";
import { ORDER_SEARCH, STYLIST_SEARCH } from "../../../../utils/Constants";

// const setBookingTabColor = (value) => {
//   switch (value) {
//     case "0":
//       return "#fe7d8f";
//     case "1":
//       return "#97c1a9";
//     case "2":
//       return "#cbaacb";
//     case "3":
//       return "#90cbaa";
//     case "4":
//       return "#99ccff";
//     default:
//       return -1;
//   }
// };

const SalonBooking = () => {
  const [events, setEvents] = useState([]);

  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  console.log("events", events);

  const navigate = useNavigate();
  const salonNew = JSON.parse(localStorage.getItem("salon_details"));

  const [stylists, totalResult2, error2, loader2, getStylist] = useFetch({
    url: `${STYLIST_SEARCH}`,
    resultField: "stylists",
    parameter: {
      salon: salonNew?._id && salonNew?._id,
      status: 1,
      f: "name",
    },
  });

  const colorStylistMap = getColorAccToStylist(stylists);
  console.log("colorStylistMap", colorStylistMap);

  const [filters, setFilter] = useState({
    "salon._id": salonNew?._id && salonNew?._id,
    serviceOrders: "notNull",
    fromSlotDate: moment().startOf("month").format("YYYY-MM-DD"),
    tillSlotDate: moment().endOf("month").format("YYYY-MM-DD"),
    f: "user,serviceOrders,salon,qrCode",
  });

  const [orders, totalResult, error, loader, getData] = useFetch({
    url: `${ORDER_SEARCH}`,
    resultField: "orders",
    parameter: filters,
  });

  const functions = {
    getSlots: useCallback(() => {
      orders?.map((item, i) => {
        if (item?.serviceOrders?.servicesProcessingStatus !== "5") {
          return setEvents((oldEvents) => [
            ...oldEvents,
            {
              id: item?._id,
              // color: setBookingTabColor(
              //   item?.serviceOrders?.servicesProcessingStatus
              // ),
              color: item?.serviceOrders?.stylist?._id
                ? colorStylistMap.get(
                    item?.serviceOrders?.stylist?._id?.toString()
                  )
                : "#f2f2f2",
              from: getDateTime(
                item?.serviceOrders?.slotDate,
                item?.serviceOrders?.slotTime.split("-")[0],
                0
              ),
              to: getDateTime(
                item?.serviceOrders?.slotDate,
                item?.serviceOrders?.slotTime.split("-")[0],
                functions.getTotalServiceTime(item)
              ),
              from: getDateTime(
                item?.serviceOrders?.slotDate,
                item?.serviceOrders?.slotTime.split("-")[0],
                0
              ),
              title: `${item?.serviceOrders?.stylist?.name ?? "--"} , ${
                item?.user?.name
              }, ${item?.serviceOrders?.serviceType}, ${getPrettyFormat(
                functions.getTotalServiceTime(item)
              )}, ${item?.serviceOrders?.services?.map((itm) => {
                return `${itm?.service?.name}`;
              })}`,
            },
          ]);
        }
      });
    }, [orders]),
    getSingleOrderDetails: (index) => {
      navigate(`/salon/order/service/${index}`);
    },
    getTotalServiceTime: (ordersActual) => {
      let totalTime = 0;
      ordersActual?.serviceOrders?.services?.map((itm, i) => {
        return (totalTime += itm.service.duration);
      });
      return totalTime;
    },
  };

  useEffect(() => {
    getData();
  }, [filters]);

  useEffect(() => {
    getStylist();
  }, []);

  useEffect(() => {
    setEvents([]);
    functions.getSlots();
  }, [orders]);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      <div className="desktop-salon-nav-header">
        <Layout />
      </div>

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Dashboard"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={false}
          title="Calendar"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="mainWrapper booking-page pt-0">
        <div className="pe-3 calender-container">
          <Calender
            events={events}
            onChange={(e) => {
              setFilter({
                ...filters,
                fromSlotDate: moment(e.month + 1, "MM")
                  .startOf("month")
                  .format("YYYY-MM-DD"),
                tillSlotDate: moment(e.month + 1, "MM")
                  .endOf("month")
                  .format("YYYY-MM-DD"),
              });
            }}
            onClickEvent={(e) => {
              functions.getSingleOrderDetails(e);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SalonBooking;
