import React, { useEffect, useState } from "react";
import Layout from "../../../Components/Layout";
import { leadStatus, LEAD_DELETE, LEAD_LIST } from "../../../utils/Constants";
import DeleteModal from "../../../Components/DeleteRecord";
import useFetch from "../../../hooks/useFetch";
import { ToastContainer } from "react-toastify";
import DropdownSmall from "../../../Shared Components/DropdownSmall";
import Pagination from "../../../Components/Pagination";
import SearchInput2 from "../../../Shared Components/SearchInput2";
import Loader from "../../../Components/Loader";
import MobileSalonDashboardHeader from "../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileFilterHeader from "../../../Components/mobilefilterheader";
import { getAddedOnDateTime } from "../../../Functions/dateTime";
import LeadForm from "../../../Components/LeadForm";
import MobileLeadsFilters from "../../../Components/Mobile/Admin/mobileLeadsFilter";

const Lead = () => {
  const [commonData, setCommonData] = useState({
    data: {},
    modal: false,
    check: "",
  });

  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
  });

  const [param, setParam] = useState({ show: false });

  const functions = {
    addParam: () => {
      setFilter({
        ...filters,
        ...param,
      });
    },
    resetParam: () => {
      setParam({});
      delete filters.status;
      delete filters.statusName;
      delete filters.q;
      getData();
    },
    updateLead: (item, check) => {
      setCommonData({
        data: item,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
    deleteLead: (id, check) => {
      setCommonData({
        data: id,
        modal: true,
        check: check,
      });
      document.body.style.overflow = "hidden";
    },
  };

  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [leads, totalResult, error, loader, getData] = useFetch({
    url: `${LEAD_LIST}`,
    resultField: "leads",
    parameter: filters,
  });

  useEffect(() => {
    window.localStorage.removeItem("salon_details");
    getData();
  }, [filters]);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Leads"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileLeadsFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          resetParam={functions.resetParam}
          filters={filters}
          setFilter={setFilter}
          addParam={functions.addParam}
          setParam={setParam}
          param={param}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Leads"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Leads"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>

      <div className="mainWrapper clients-page">
        <div className="container ms-0 ps-0 d-flex justify-content-between align-items-center mb-2">
          <h3 className="font22x500 pageHeading mb-0">Lead List</h3>
        </div>
        <div className="filter-container container ms-0 ps-0 mb-3">
          <div className="row">
            <div className="col-5 d-flex align-items-end">
              <SearchInput2
                type="text"
                placeholder="Search by name/mobile"
                stateData={param}
                setStateData={setParam}
                parameter1="phone"
                parameter2="name"
                addParam={functions.addParam}
                resetParam={functions.resetParam}
              />
            </div>
            <div className="col-7 d-flex justify-content-end align-items-end">
              {/* status */}
              <DropdownSmall
                data={[
                  { value: "Pending", label: "Pending" },
                  { value: "Processing", label: "Processing" },
                  { value: "Served", label: "Served" },
                ]}
                label="Status"
                placeholder="Status"
                htmlFor="status"
                type="text"
                stateData={filters?.statusName}
                setStateData={(e) => {
                  setFilter({
                    ...filters,
                    statusName: e,
                    status:
                      e === "Pending" ? "0" : e === "Processing" ? "1" : "2",
                  });
                }}
              />

              <button
                className="btn resetBtn shadow-none ms-3"
                onClick={functions.resetParam}
              >
                Reset
              </button>
            </div>
          </div>
        </div>

        {leads?.length !== 0 ? (
          <div className="container dataTable ms-0 ps-0">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Sl. No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Phone No</th>
                  <th scope="col">Business Name</th>
                  <th scope="col">City</th>
                  <th scope="col">Country</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {leads?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{(filters.page - 1) * filters.per_page + (i + 1)}</td>
                      <td>{item?.name}</td>
                      <td>{item?.phone}</td>
                      <td>{item?.businessName}</td>
                      <td>{item?.city}</td>
                      <td>{item?.country?.name}</td>
                      <td>{getAddedOnDateTime(item?.createdAt)}</td>
                      <td>{leadStatus[item?.status]?.label}</td>
                      <td>
                        <button
                          className="btn font14x400 filterBtn shadow-none me-2"
                          onClick={() => {
                            functions.updateLead(item, "update_lead");
                          }}
                        >
                          Edit
                        </button>
                        <button
                          className="btn font14x400 filterBtn shadow-none me-2"
                          onClick={() => {
                            functions.deleteLead(item?._id, "delete_lead");
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Lead Found"
            )}
          </div>
        )}

        {leads?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      {/* service form add and update both */}
      {commonData.modal && commonData.check === "update_lead" && (
        <LeadForm
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
        />
      )}

      {/* delete modal */}
      {commonData.modal && commonData.check === "delete_lead" && (
        <DeleteModal
          state={commonData.modal}
          setState={(e) =>
            setCommonData({
              data: undefined,
              modal: e,
              check: "",
            })
          }
          url={LEAD_DELETE}
          id={commonData.data}
          text="Lead"
          apiCall={getData}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default Lead;
