import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListView from "../../../../Components/Dashboard/AnalyticsComponents/Categories/ListView";
import AllDataOrders from "../../../../Components/Dashboard/AnalyticsComponents/Orders/AllDataOrders";
import PaymentType from "../../../../Components/Dashboard/AnalyticsComponents/Other/PaymentType";
import StylistPerformance from "../../../../Components/Dashboard/AnalyticsComponents/Other/StylistPerformance";
import AllDataRevenue from "../../../../Components/Dashboard/AnalyticsComponents/Revenue/AllDataRevenue";
import CurrentDateTime from "../../../../Components/Dashboard/CurrentDateTime";
import ReportOverview from "../../../../Components/Dashboard/ReportOverview";
import Layout from "../../../../Components/Layout";
import useFetch from "../../../../hooks/useFetch";
import InputDate from "../../../../Shared Components/InputDate";
import { ADMIN, SALON_DASHBOARD } from "../../../../utils/Constants";
import MultipleLineCharts from "../../../../Components/Dashboard/AnalyticsComponents/LineCharts/MultipleLineCharts";
import DailyUsers from "../../../../Components/Dashboard/AnalyticsComponents/LineCharts/DailyUsers";
import DropdownSmallDate from "../../../../Shared Components/DropdownSmallDate";
import Last7DaysGraph from "../../../../Components/Dashboard/AnalyticsComponents/Other/Last7DaysGraph";
import DropdownSmall from "../../../../Shared Components/DropdownSmall";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";
import MobileSalonDashboardFilters from "../../../../Components/Mobile/mobileSalonDashboardfilter";
import { toast, ToastContainer } from "react-toastify";

const Dashboard = () => {
  const navigate = useNavigate();
  const [commonData, setCommonData] = useState({
    modal: false,
    qrCode: false,
  });

  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const role = JSON.parse(localStorage.getItem("role"));

  useEffect(() => {
    const salon = JSON.parse(localStorage.getItem("salon_details"));
    if (!salon && role === ADMIN) {
      navigate("/allSalons");
    }
  }, []);

  const salonNew = JSON.parse(localStorage.getItem("salon_details"));
  const [param, setParam] = useState({});
  const [filters, setFilter] = useState({
    fromDate: moment().startOf("month").format("YYYY-MM-DD"),
    tillDate: moment().endOf("month").format("YYYY-MM-DD"),
    qrCode: true,
  });

  const functions = {
    addParam: () => {
      if (param.fromDate && param.tillDate) {
        const checkDate = moment(param.tillDate, "YYYY-MM-DD").isAfter(
          moment(param.fromDate, "YYYY-MM-DD")
        );
        if (checkDate) {
          setFilter({
            ...filters,
            ...param,
          });
        } else {
          toast.error("From date must be less than till date.", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    },
    resetParam: () => {
      setParam({});
      filters.fromDate = moment().startOf("month").format("YYYY-MM-DD");
      filters.tillDate = moment().endOf("month").format("YYYY-MM-DD");
      delete filters.statName;
      getData();
    },
    onClick7DaysBtn: () => {
      setCommonData({
        modal: true,
      });
      document.body.style.overflow = "hidden";
    },
  };

  const [salonDashboard, totalResult, error, loader, getData] = useFetch({
    url: `${SALON_DASHBOARD}${salonNew?._id}`,
    resultField: "salonDashboard",
    parameter: filters,
  });

  useEffect(() => {
    getData();
  }, [filters]);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Dashboard"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileSalonDashboardFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          onClick={functions.onClick7DaysBtn}
          filters={filters}
          setFilter={setFilter}
          param={param}
          setParam={setParam}
          addParam={functions.addParam}
          resetParam={functions.resetParam}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Dashboard"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>

      <div className="mainWrapper salon-dashboard">
        <div className="container ps-0 m-0 salon-dashboard-filter">
          <div className="container ms-0 ps-0 d-flex justify-content-between align-items-center mb-2">
            <h3 className="font22x500 pageHeading mb-0">Dashboard</h3>
          </div>

          <div className="row">
            <div className="col-9">
              <div className="d-flex justify-content-between align-items-end flex-wrap">
                <div className="me-3">
                  {/* date from */}
                  <InputDate
                    label="From"
                    htmlFor="from"
                    type="date"
                    stateData={param?.fromDate}
                    setStateData={(e) => {
                      setParam({
                        ...param,
                        fromDate: moment(e).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </div>

                <div className="me-3">
                  {/* date to */}
                  <InputDate
                    label="To"
                    htmlFor="to"
                    type="date"
                    stateData={param?.tillDate}
                    setStateData={(e) => {
                      setParam({
                        ...param,
                        tillDate: moment(e).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </div>

                <button
                  className="btn filterBtn shadow-none"
                  onClick={functions.addParam}
                >
                  Apply
                </button>
                <button
                  className="btn resetBtn shadow-none ms-3"
                  onClick={functions.resetParam}
                >
                  Reset
                </button>

                {/* Show Stats */}
                <div className="ms-3">
                  <DropdownSmallDate
                    data={[
                      {
                        value1: `${moment()
                          .startOf("month")
                          .format("YYYY-MM-DD")}`,
                        value2: `${moment()
                          .endOf("month")
                          .format("YYYY-MM-DD")}`,
                        label: "This Month",
                      },
                      {
                        value1: `${moment().format("YYYY-MM-DD")}`,
                        value2: `${moment().format("YYYY-MM-DD")}`,
                        label: "Today",
                      },
                      {
                        value1: `${moment()
                          .subtract(1, "days")
                          .format("YYYY-MM-DD")}`,
                        value2: `${moment()
                          .subtract(1, "days")
                          .format("YYYY-MM-DD")}`,
                        label: "Yesterday",
                      },
                    ]}
                    label="Show Stats"
                    htmlFor="stats"
                    type="text"
                    placeholder="Show Stats"
                    stateData={filters?.statName}
                    setStateData={(a, b, c) => {
                      setFilter({
                        ...filters,
                        statName: c,
                        fromDate: a,
                        tillDate: b,
                      });
                    }}
                  />
                </div>

                {/* On */}
                <div className="ms-3">
                  <DropdownSmall
                    data={[
                      {
                        value: true,
                        label: "Store",
                      },
                      {
                        value: false,
                        label: "Groome",
                      },
                    ]}
                    label="On"
                    htmlFor="code"
                    type="text"
                    placeholder="Select Code"
                    stateData={filters?.qrCode ? "Store" : "Groome"}
                    setStateData={(a) => {
                      setFilter({
                        ...filters,
                        qrCode: a,
                      });
                    }}
                    isLabelSelected={true}
                  />
                </div>

                <button
                  className="btn addBtn shadow-none mt-3"
                  onClick={functions.onClick7DaysBtn}
                >
                  Next 7 Days
                </button>
              </div>
            </div>
            <div className="col-3 d-flex justify-content-end align-items-center">
              <CurrentDateTime />
            </div>
          </div>
        </div>

        <AllDataOrders
          ordersCount={salonDashboard.ordersCount}
          loader={loader}
        />

        {/* revenue and booking */}
        <div className="mt-5 me-3 revenue-booking-container">
          <div className="container p-0">
            <div className="row gap-lg-0 gap-4">
              <MultipleLineCharts
                label="Revenue"
                statsData={salonDashboard?.revenuePerDay}
              />
              <MultipleLineCharts
                label="Booking"
                statsData={salonDashboard?.bookingsCompletedPerDay}
              />
            </div>
          </div>
        </div>

        <AllDataRevenue
          revenueCalculation={salonDashboard.revenueCalculation}
          loader={loader}
        />

        {/* payment and stylist */}
        <div className="mt-5 me-3 payment-stylist-container">
          <div className="container p-0">
            <div className="row gap-lg-0 gap-4">
              <PaymentType
                label="Payment Type"
                paymentType={salonDashboard.paymentModePerformance}
              />
              <StylistPerformance
                label="Stylist Performance"
                stylistData={salonDashboard.stylistPerformance}
              />
            </div>
          </div>
        </div>

        {/* daily active users & report Overview */}
        <div className="mt-5 me-3 daily-active-user-report-container">
          <div className="container p-0">
            <div className="row gap-lg-0 gap-4">
              <DailyUsers
                label="Daily Active Users"
                activeUser={salonDashboard?.dailyUserClicks}
              />
              <ReportOverview
                label="Report Overview"
                reportsOverview={salonDashboard?.reportsOverview}
                loader={loader}
              />
            </div>
          </div>
        </div>

        {/* categories */}
        <div
          className="paymentsStatsWrapper mt-5 me-3"
          style={{ paddingBottom: "15px" }}
        >
          <h3 className="font22x500 pageHeading mb-4">
            Top Selling Categories
          </h3>
          <div className="container p-0">
            <div className="row gap-lg-0 gap-4">
              <ListView
                label="Product"
                categories={salonDashboard.productCategoryPerformance}
              />
              <ListView
                label="Service"
                categories={salonDashboard.serviceCategoryPerformance}
              />
            </div>
          </div>
          <div className="mt-3 text-end">
            <i>Note: Calculation is based on revenue wise</i>
          </div>
        </div>
      </div>

      {/* last 7 days graph */}
      {commonData.modal && (
        <Last7DaysGraph state={commonData} setState={setCommonData} />
      )}

      <ToastContainer />
    </>
  );
};

export default Dashboard;
