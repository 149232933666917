import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import Layout from "../../../../Components/Layout";
import BannerForm from "../../../../Components/Admin/Banner Form";
import { ToastContainer } from "react-toastify";
import DropdownSmall from "../../../../Shared Components/DropdownSmall";
import TypeForm from "../../../../Components/Admin/Banner Form/TypeForm";
import {
  BANNERS_TYPE,
  BANNER_DELETE,
  BANNER_SEARCH,
} from "../../../../utils/Constants";
import useFetch from "../../../../hooks/useFetch";
import Pagination from "../../../../Components/Pagination";
import DeleteModal from "../../../../Components/DeleteRecord";
import { getAddedOnDate } from "../../../../Functions/dateTime";
import SearchInput2 from "../../../../Shared Components/SearchInput2";
import Loader from "../../../../Components/Loader";
import { customizationLinks } from "../FAQ";
import PageNav from "../../../../Components/PageNav";
import bannerImage from "../../../../uploads/banner/banner1.jpg";
import SearchCity from "../../../../Components/Admin/Banner Form/City/Search";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";
import MobileBannerFilters from "../../../../Components/Mobile/Admin/mobileBannerFilter";

const BannerLink = () => {
  const [commonData, setCommonData] = useState({
    data: {},
    modal: false,
  });
  // const [typeData, setTypeData] = useState({
  //   data: {},
  //   modal: false,
  // });

  // const [type, loaderSmall, getCategory] = useCategory({
  //   url: BANNERS_TYPE,
  //   resultField: "bannerTypes",
  // });

  const onEdit = (item) => {
    setCommonData({
      data: item,
      modal: true,
    });
    document.body.style.overflow = "hidden";
  };
  const onAdd = () => {
    setCommonData({
      data: undefined,
      modal: true,
    });
    document.body.style.overflow = "hidden";
  };
  // const onAddType = () => {
  //   setTypeData({
  //     data: undefined,
  //     modal: true,
  //   });
  //   document.body.style.overflow= "hidden";
  // };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [id, setId] = useState("");
  const onDelete = (id) => {
    setIsDeleteModalOpen(true);
    document.body.style.overflow = "hidden";
    setId(id);
  };

  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
  });
  console.log(filters);

  const [param, setParam] = useState({ show: false });
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const addParam = () => {
    setFilter({
      ...filters,
      ...param,
    });
  };

  const resetParam = () => {
    setParam({ show: false });
    delete filters.bannerStatus;
    delete filters.city;
    delete filters.type;
    delete filters.q;
    delete filters.typeCategoryName;
    getData();
  };

  const [banners, totalResult, error, loader, getData] = useFetch({
    url: `${BANNER_SEARCH}`,
    resultField: "banners",
    parameter: filters,
  });

  useEffect(() => {
    window.localStorage.removeItem("salon_details");
    getData();
  }, [filters]);
  // useEffect(() => {
  //   getCategory();
  // }, []);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Banner and Link"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileBannerFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          onClick={onAdd}
          resetParam={resetParam}
          filters={filters}
          setFilter={setFilter}
          param={param}
          setParam={setParam}
          addParam={addParam}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Banner and Link"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Banner and Link"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper">
        <div>
          <div className="service-navigation-neworder-button-container">
            <div className="service-navigation-neworder-button d-flex justify-content-between pe-2">
              <PageNav links={customizationLinks} />
              <div className="">
                {/* <button
                className="btn addBtn shadow-none me-5"
                onClick={onAddType}
              >
                Add New Type
              </button> */}
                <div className="desktopButton">
                  <button className="btn addBtn shadow-none" onClick={onAdd}>
                    Add New Banner
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="filter-container container mt-4 ms-0 ps-0 mb-3">
            <div className="row">
              <div className="col-5 d-flex align-items-end">
                <SearchInput2
                  type="text"
                  placeholder="Search by title"
                  stateData={param}
                  setStateData={setParam}
                  parameter1="title"
                  parameter2={undefined}
                  addParam={addParam}
                  resetParam={resetParam}
                  width="200px"
                />
              </div>
              <div className="col-7 d-flex align-items-end justify-content-end">
                <div className="me-3">
                  {/* status */}
                  <DropdownSmall
                    data={[
                      { value: "Published", label: "Published" },
                      { value: "Draft", label: "Draft" },
                    ]}
                    label="Status"
                    htmlFor="status"
                    type="text"
                    placeholder="Select Status"
                    stateData={filters?.bannerStatus}
                    setStateData={(e) => {
                      setFilter({
                        ...filters,
                        bannerStatus: e,
                      });
                    }}
                  />
                </div>

                <SearchCity
                  stateData={param?.city}
                  width="200px"
                  setStateData={(e) => {
                    setParam({
                      ...param,
                      city: e,
                    });
                  }}
                />

                {/* type */}
                {/* <DropdownSmallDynamic
                  data={type}
                  label="Type"
                  htmlFor="type"
                  type="text"
                  stateData={filters?.typeCategoryName}
                  setStateData={(e, x) => {
                    if (e === "" && filters.type) {
                      delete filters?.type;
                      setFilter({
                        ...filters,
                        typeCategoryName: x,
                      });
                    } else {
                      setFilter({
                        ...filters,
                        type: e,
                        typeCategoryName: x,
                      });
                    }
                  }}
                /> */}

                <button
                  className="btn filterBtn shadow-none ms-3"
                  onClick={addParam}
                >
                  Apply
                </button>

                <button
                  className="btn resetBtn shadow-none ms-3"
                  onClick={resetParam}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>

        {banners?.length !== 0 ? (
          <div className="container dataTable ms-0 ps-0">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Banner Title</th>
                  <th scope="col">Banner</th>
                  <th scope="col">Order</th>
                  <th scope="col">City</th>
                  <th scope="col">Status</th>
                  <th scope="col">Created At</th>
                  {/* <th scope="col">Type</th> */}
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {banners?.map((banner, i) => {
                  return (
                    <tr key={i}>
                      <td>{banner?.title}</td>
                      <td>
                        {banner?.image === null ? (
                          <img
                            src={bannerImage}
                            alt="banner"
                            width={72}
                            height={48}
                            style={{ borderRadius: "4px" }}
                          />
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_Image_BaseURL}${banner?.image}`}
                            alt="banner"
                            width={72}
                            height={48}
                            style={{ borderRadius: "4px" }}
                          />
                        )}
                      </td>
                      <td>{banner?.order}</td>
                      <td>{banner?.city}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div
                            className="statusDot me-1"
                            style={{
                              backgroundColor: `${
                                banner?.bannerStatus === "Draft"
                                  ? "var(--text4)"
                                  : "var(--status_color3)"
                              }`,
                            }}
                          ></div>
                          <p className="mb-0">{banner?.bannerStatus}</p>
                        </div>
                      </td>
                      <td>{getAddedOnDate(banner?.createdAt)}</td>
                      {/* <td>{banner?.type?.name}</td> */}
                      <td>
                        <button
                          className="btn font14x400 filterBtn shadow-none me-2"
                          onClick={() => {
                            onEdit(banner);
                          }}
                        >
                          Edit
                        </button>
                        <span
                          className="deleteBtn"
                          onClick={() => {
                            onDelete(banner?._id);
                          }}
                        >
                          <RiDeleteBin6Line />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Banner Found"
            )}
          </div>
        )}

        {banners?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      {/* delete modal */}
      {isDeleteModalOpen && (
        <DeleteModal
          id={id}
          apiCall={getData}
          state={isDeleteModalOpen}
          setState={setIsDeleteModalOpen}
          url={BANNER_DELETE}
          text="Banner"
        />
      )}

      {/* banner form add and update both */}
      {commonData.modal && (
        <BannerForm
          // data={type}
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
        />
      )}

      {/* type form add and update both */}
      {/* {typeData.modal && (
        <TypeForm
          state={typeData}
          setState={setTypeData}
          apiCall={getCategory}
        />
      )} */}

      <ToastContainer />
    </>
  );
};

export default BannerLink;
