import React, { useEffect, useState } from "react";
import Layout from "../../../../Components/Layout";
import { RiDeleteBin6Line } from "react-icons/ri";
import useAuthContext from "../../../../hooks/useAuthContext";
import useFetch from "../../../../hooks/useFetch";
import {
  DYNAMIC_PAGE_DELETE,
  DYNAMIC_PAGE_SEARCH,
  DYNAMIC_PAGE_UPDATE,
} from "../../../../utils/Constants";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import DeleteModal from "../../../../Components/DeleteRecord";
import { customizationLinks } from "../FAQ";
import PageNav from "../../../../Components/PageNav";
import AddDynamicPageForm from "../../../../Components/Admin/Dynamic Page";
import Loader from "../../../../Components/Loader";
import useLoading from "../../../../hooks/useLoading";
import SearchInput2 from "../../../../Shared Components/SearchInput2";
import { getAddedOnDateTime } from "../../../../Functions/dateTime";
import Pagination from "../../../../Components/Pagination";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileDynamicPageFilters from "../../../../Components/Mobile/Admin/mobileDynamicPageFilter";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";

const DynamicPages = () => {
  const [commonData, setCommonData] = useState({
    data: {},
    modal: false,
  });

  const [editData, setEditData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState("");

  const { token } = useAuthContext();

  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
  });
  console.log(filters);

  const [param, setParam] = useState({ show: false });
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const functions = {
    onEdit: (item) => {
      setCommonData({
        data: item,
        modal: true,
      });
      document.body.style.overflow = "hidden";
    },
    onDelete: (id) => {
      console.log("id:", id);
      setIsModalOpen(true);
      document.body.style.overflow = "hidden";
      setId(id);
    },
    onAdd: () => {
      setCommonData({
        data: undefined,
        modal: true,
      });
      document.body.style.overflow = "hidden";
    },
    addParam: () => {
      setFilter({
        ...filters,
        ...param,
      });
    },
    resetParam: () => {
      setParam({ show: false });
      delete filters.q;
      getData();
    },
  };

  const [dynamicPages, totalResult, error, loader, getData] = useFetch({
    url: `${DYNAMIC_PAGE_SEARCH}`,
    resultField: "dynamicPages",
    parameter: filters,
  });

  useEffect(() => {
    window.localStorage.removeItem("salon_details");
    getData();
  }, [filters]);

  // const onUpdate = (id, data, key) => {
  //   const val = editData.map((edit) => {
  //     if (edit.id === id) {
  //       return {
  //         ...edit,
  //         [key]: data,
  //       };
  //     }
  //     return edit;
  //   });
  //   setEditData(val);
  // };

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);
  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Dynamic Pages"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileDynamicPageFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          onClick={functions.onAdd}
          addParam={functions.addParam}
          resetParam={functions.resetParam}
          param={param}
          setParam={setParam}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Dynamic Pages"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Dynamic Pages"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper">
        <div>
          <div className="service-navigation-neworder-button-container">
            <div className="service-navigation-neworder-button d-flex justify-content-between pe-2">
              <PageNav links={customizationLinks} />
              <div className="desktopButton">
                <button
                  className="btn addBtn shadow-none"
                  onClick={functions.onAdd}
                >
                  Add Dynamic Page
                </button>
              </div>
            </div>
          </div>

          <div className="filter-container container mt-4 ms-0 ps-0 mb-3">
            <div className="row">
              <div className="col-5 d-flex">
                <SearchInput2
                  type="text"
                  placeholder="Search by title"
                  stateData={param}
                  setStateData={setParam}
                  parameter1="title"
                  parameter2={undefined}
                  addParam={functions.addParam}
                  resetParam={functions.resetParam}
                />
              </div>
            </div>
          </div>
        </div>

        {dynamicPages?.length !== 0 ? (
          <div className="container dataTable ms-0 ps-0">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Description</th>
                  <th scope="col">Slug</th>
                  <th scope="col">Created On</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {dynamicPages?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item?.title}</td>
                      <td>{item?.description}</td>
                      <td>{item?.slug}</td>
                      <td>{getAddedOnDateTime(item?.createdAt)}</td>
                      <td>
                        <p
                          className="status mb-0"
                          style={{
                            backgroundColor: `${
                              item?.status === 1
                                ? "var(--status_color2)"
                                : "var(--status_color1)"
                            }`,
                          }}
                        >
                          {`${item?.status === 1 ? "Active" : "Inactive"}`}
                        </p>
                      </td>
                      <td>
                        <button
                          className="btn font14x400 filterBtn shadow-none me-2"
                          onClick={() => {
                            functions.onEdit(item);
                          }}
                        >
                          Edit
                        </button>
                        <span
                          className="deleteBtn"
                          onClick={() => {
                            functions.onDelete(item?._id);
                          }}
                        >
                          <RiDeleteBin6Line />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Dynamic Pages Found"
            )}
          </div>
        )}

        {dynamicPages?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      {/* delete modal */}
      {isModalOpen && (
        <DeleteModal
          state={isModalOpen}
          setState={setIsModalOpen}
          url={DYNAMIC_PAGE_DELETE}
          id={id}
          text="Dynamic Page"
          apiCall={getData}
        />
      )}

      {/* dynamic page add and update both */}
      {commonData.modal && (
        <AddDynamicPageForm
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default DynamicPages;
