import React from "react";
import { Link, useLocation } from "react-router-dom";
import useAuthContext from "../../hooks/useAuthContext";
import {
  ADMIN,
  CHILD_SALON,
  MASTER_SALON,
  MASTER_SALON_USER,
} from "../../utils/Constants";

const Sidenav = () => {
  const location = useLocation();

  const { role } = useAuthContext();

  return (
    <>
      <aside>
        <div className="sidenavWrapper">
          {role === ADMIN && location?.pathname?.includes("/admin") ? (
            <>
              <Link to="/admin/dashboard">
                <div className="text-center">
                  <img src="/Assets/logo2.svg" alt="logo" width={45} />
                </div>
              </Link>

              <div className="navigation">
                <div className="list">
                  <Link to="/admin/lead">
                    <span className="icon">
                      <img
                        src={`${
                          location?.pathname?.includes("/admin/lead")
                            ? "/Assets/leadColor.svg"
                            : "/Assets/lead.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          location?.pathname?.includes("/admin/lead") &&
                          "visible",
                        opacity:
                          location?.pathname?.includes("/admin/lead") && 1,
                      }}
                    >
                      Leads
                    </span>
                  </Link>
                </div>
                <div className="list">
                  <Link to="/admin/qr-order/service">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/admin/order/service" ||
                          location.pathname === "/admin/order/product" ||
                          location.pathname === "/admin/qr-order/service" ||
                          location.pathname === "/admin/qr-order/product" ||
                          location.pathname === "/admin/membership-order" ||
                          location?.pathname?.includes("/admin/order/")
                            ? "/Assets/orderColor.svg"
                            : "/Assets/order.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          (location.pathname === "/admin/order/service" ||
                            location.pathname === "/admin/order/product" ||
                            location.pathname === "/admin/qr-order/service" ||
                            location.pathname === "/admin/qr-order/product" ||
                            location.pathname === "/admin/membership-order" ||
                            location?.pathname?.includes("/admin/order/")) &&
                          "visible",
                        opacity:
                          (location.pathname === "/admin/order/service" ||
                            location.pathname === "/admin/order/product" ||
                            location.pathname === "/admin/qr-order/service" ||
                            location.pathname === "/admin/qr-order/product" ||
                            location.pathname === "/admin/membership-order" ||
                            location?.pathname?.includes("/admin/order/")) &&
                          1,
                      }}
                    >
                      Orders
                    </span>
                  </Link>
                </div>
                <div className="list">
                  <Link to="/admin/allSalons">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/admin/allSalons"
                            ? "/Assets/salonListColor.svg"
                            : "/Assets/salonList.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          location.pathname === "/admin/allSalons" && "visible",
                        opacity: location.pathname === "/admin/allSalons" && 1,
                      }}
                    >
                      Store List
                    </span>
                  </Link>
                </div>
                <div className="list">
                  <Link to="/admin/users">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/admin/users" ||
                          location.pathname === "/admin/temporary-users"
                            ? "/Assets/userColor.svg"
                            : "/Assets/user.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          (location.pathname === "/admin/users" ||
                            location.pathname === "/admin/temporary-users") &&
                          "visible",
                        opacity:
                          (location.pathname === "/admin/users" ||
                            location.pathname === "/admin/temporary-users") &&
                          1,
                      }}
                    >
                      Users
                    </span>
                  </Link>
                </div>
                <div className="list">
                  <Link to="/admin/membership">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/admin/membership"
                            ? "/Assets/membershipColor.svg"
                            : "/Assets/membership.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          location.pathname === "/admin/membership" &&
                          "visible",
                        opacity: location.pathname === "/admin/membership" && 1,
                      }}
                    >
                      Membership
                    </span>
                  </Link>
                </div>
                <div className="list">
                  <Link to="/admin/app-coupons">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/admin/app-coupons" ||
                          location.pathname === "/admin/offer&deals"
                            ? "/Assets/marketingColor.svg"
                            : "/Assets/marketing.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          (location.pathname === "/admin/app-coupons" ||
                            location.pathname === "/admin/offer&deals") &&
                          "visible",
                        opacity:
                          (location.pathname === "/admin/app-coupons" ||
                            location.pathname === "/admin/offer&deals") &&
                          1,
                      }}
                    >
                      Marketing
                    </span>
                  </Link>
                </div>
                <div className="list">
                  <Link to="/admin/addProduct">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/admin/addProduct"
                            ? "/Assets/productColor.svg"
                            : "/Assets/product.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          location.pathname === "/admin/addProduct" &&
                          "visible",
                        opacity: location.pathname === "/admin/addProduct" && 1,
                      }}
                    >
                      Products
                    </span>
                  </Link>
                </div>
                <div className="list">
                  <Link to="/admin/serviceCategory">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/admin/serviceCategory" ||
                          location.pathname === "/admin/salonCategory" ||
                          location.pathname === "/admin/productCategory" ||
                          location.pathname === "/admin/service-name" ||
                          location.pathname === "/admin/brand"
                            ? "/Assets/categoriesColor.svg"
                            : "/Assets/categories.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          (location.pathname === "/admin/serviceCategory" ||
                            location.pathname === "/admin/salonCategory" ||
                            location.pathname === "/admin/productCategory" ||
                            location.pathname === "/admin/service-name" ||
                            location.pathname === "/admin/brand") &&
                          "visible",
                        opacity:
                          (location.pathname === "/admin/serviceCategory" ||
                            location.pathname === "/admin/salonCategory" ||
                            location.pathname === "/admin/productCategory" ||
                            location.pathname === "/admin/service-name" ||
                            location.pathname === "/admin/brand") &&
                          1,
                      }}
                    >
                      Constants
                    </span>
                  </Link>
                </div>
                <div className="list">
                  <Link to="/admin/transactions">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/admin/transactions"
                            ? "/Assets/transactionColor.svg"
                            : "/Assets/transaction.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          location.pathname === "/admin/transactions" &&
                          "visible",
                        opacity:
                          location.pathname === "/admin/transactions" && 1,
                      }}
                    >
                      Transactions
                    </span>
                  </Link>
                </div>
                <div className="list pb-3">
                  <Link to="/admin/faq">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/admin/faq" ||
                          location.pathname === "/admin/dynamicPages" ||
                          location.pathname === "/admin/banner&link"
                            ? "/Assets/customizationColor.svg"
                            : "/Assets/customization.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          (location.pathname === "/admin/faq" ||
                            location.pathname === "/admin/dynamicPages" ||
                            location.pathname === "/admin/banner&link") &&
                          "visible",
                        opacity:
                          (location.pathname === "/admin/faq" ||
                            location.pathname === "/admin/dynamicPages" ||
                            location.pathname === "/admin/banner&link") &&
                          1,
                      }}
                    >
                      Customization
                    </span>
                  </Link>
                </div>
              </div>
            </>
          ) : role === MASTER_SALON ||
            role === ADMIN ||
            role === CHILD_SALON ||
            role === MASTER_SALON_USER ? (
            <>
              <Link to="/salon/dashboard">
                <div className="text-center">
                  <img src="/Assets/logo2.svg" alt="logo" width={45} />
                </div>
              </Link>

              <div className="navigation">
                <div className="list">
                  <Link to="/salon/qr-order/service">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/salon/your-orders" ||
                          location.pathname === "/salon/order/service" ||
                          location.pathname === "/salon/order/product" ||
                          location.pathname === "/salon/qr-order/service" ||
                          location.pathname === "/salon/qr-order/product" ||
                          location?.pathname?.includes("/salon/order/")
                            ? "/Assets/orderColor.svg"
                            : "/Assets/order.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          (location.pathname === "/salon/your-orders" ||
                            location.pathname === "/salon/order/service" ||
                            location.pathname === "/salon/order/product" ||
                            location.pathname === "/salon/qr-order/service" ||
                            location.pathname === "/salon/qr-order/product" ||
                            location?.pathname?.includes("/salon/order/")) &&
                          "visible",
                        opacity:
                          (location.pathname === "/salon/your-orders" ||
                            location.pathname === "/salon/order/service" ||
                            location.pathname === "/salon/order/product" ||
                            location.pathname === "/salon/qr-order/service" ||
                            location.pathname === "/salon/qr-order/product" ||
                            location?.pathname?.includes("/salon/order/")) &&
                          1,
                      }}
                    >
                      Orders
                    </span>
                  </Link>
                </div>
                <div className="list">
                  <Link to="/salon/booking">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/salon/booking"
                            ? "/Assets/bookingColor.svg"
                            : "/Assets/booking.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          location.pathname === "/salon/booking" && "visible",
                        opacity: location.pathname === "/salon/booking" && 1,
                      }}
                    >
                      Calendar
                    </span>
                  </Link>
                </div>
                <div className="list">
                  <Link to="/salon/clients">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/salon/clients"
                            ? "/Assets/clientColor.svg"
                            : "/Assets/client.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>

                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          location.pathname === "/salon/clients" && "visible",
                        opacity: location.pathname === "/salon/clients" && 1,
                      }}
                    >
                      Clients
                    </span>
                  </Link>
                </div>
                <div className="list">
                  <Link to="/salon/store">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/salon/store"
                            ? "/Assets/storeColor.svg"
                            : "/Assets/store.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>

                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          location.pathname === "/salon/store" && "visible",
                        opacity: location.pathname === "/salon/store" && 1,
                      }}
                    >
                      Store Profile
                    </span>
                  </Link>
                </div>
                <div className="list">
                  <Link to="/salon/service">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/salon/service"
                            ? "/Assets/servicesColor.svg"
                            : "/Assets/services.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          location.pathname === "/salon/service" && "visible",
                        opacity: location.pathname === "/salon/service" && 1,
                      }}
                    >
                      Services
                    </span>
                  </Link>
                </div>
                <div className="list">
                  <Link to="/salon/product">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/salon/product"
                            ? "/Assets/salonProductColor.svg"
                            : "/Assets/salonProduct.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          location.pathname === "/salon/product" && "visible",
                        opacity: location.pathname === "/salon/product" && 1,
                      }}
                    >
                      Products
                    </span>
                  </Link>
                </div>
                <div className="list">
                  <Link to="/salon/stylist">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/salon/stylist"
                            ? "/Assets/stylistColor.svg"
                            : "/Assets/stylist.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          location.pathname === "/salon/stylist" && "visible",
                        opacity: location.pathname === "/salon/stylist" && 1,
                      }}
                    >
                      Stylists
                    </span>
                  </Link>
                </div>
                <div className="list">
                  <Link to="/salon/offer">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/salon/offer"
                            ? "/Assets/marketingColor.svg"
                            : "/Assets/marketing.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          location.pathname === "/salon/offer" && "visible",
                        opacity: location.pathname === "/salon/offer" && 1,
                      }}
                    >
                      Offers
                    </span>
                  </Link>
                </div>

                <div className="list">
                  <Link to="/salon/disableSlot">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/salon/disableSlot"
                            ? "/Assets/blockedSlotColor.svg"
                            : "/Assets/blockedSlot.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          location.pathname === "/salon/disableSlot" &&
                          "visible",
                        opacity:
                          location.pathname === "/salon/disableSlot" && 1,
                      }}
                    >
                      Block Slot
                    </span>
                  </Link>
                </div>
                <div className="list">
                  <Link to="/salon/buy/product">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/salon/buy/product"
                            ? "/Assets/productColor.svg"
                            : "/Assets/product.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          location.pathname === "/salon/buy/product" &&
                          "visible",
                        opacity:
                          location.pathname === "/salon/buy/product" && 1,
                      }}
                    >
                      Buy Products
                    </span>
                  </Link>
                </div>
                <div className="list">
                  <Link to="/salon/payment">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/salon/payment"
                            ? "/Assets/paymentColor.svg"
                            : "/Assets/payment.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          location.pathname === "/salon/payment" && "visible",
                        opacity: location.pathname === "/salon/payment" && 1,
                      }}
                    >
                      Payment
                    </span>
                  </Link>
                </div>
                <div className="list pb-3">
                  <Link to="/salon/review&rating">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/salon/review&rating"
                            ? "/Assets/review&RatingColor.svg"
                            : "/Assets/review&Rating.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          location.pathname === "/salon/review&rating" &&
                          "visible",
                        opacity:
                          location.pathname === "/salon/review&rating" && 1,
                      }}
                    >
                      Review & Rating
                    </span>
                  </Link>
                </div>
                <div className="list pb-3">
                  <Link to="/salon/wallet">
                    <span className="icon">
                      <img
                        src={`${
                          location.pathname === "/salon/wallet"
                            ? "/Assets/sWalletColor.svg"
                            : "/Assets/sWallet.svg"
                        }`}
                        alt="logo"
                        width={30}
                      />
                    </span>
                    <span
                      className="title font12x400"
                      style={{
                        visibility:
                          location.pathname === "/salon/wallet" && "visible",
                        opacity: location.pathname === "/salon/wallet" && 1,
                      }}
                    >
                      Store Wallet
                    </span>
                  </Link>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </aside>
    </>
  );
};

export default Sidenav;
