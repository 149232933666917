import React from "react";
import PieChartCategories from "../Categories/PieChartCategories";
import stylistImage from "../../../../uploads/stylist/user1.jpg";

const colors = [
  "#97c1a9",
  "#fe7d8f",
  "#cbaacb",
  "#99ccff",
  "#90cbaa",
  // "#EC407A",
];

const StylistPerformance = (props) => {
  const { label, stylistData } = props;
  const salonNew = JSON.parse(localStorage.getItem("salon_details"));

  return (
    <>
      <div className="col-12 col-lg-6">
        <div className="cardWrapper">
          <div className="cardWrapperHeader">
            <h3 className="font18x500 mb-0">{label}</h3>
          </div>
          <div className="cardContent">
            <div className="row">
              <div className="col-12">
                <PieChartCategories categories={stylistData} />
              </div>
              <div className="scrollContainer col-12 d-flex">
                {stylistData?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="dataTab d-flex align-items-center mb-4 ms-0"
                      style={{
                        color: colors[i],
                      }}
                    >
                      <div className="">
                        {item?.image === null ? (
                          <img
                            src={stylistImage}
                            alt=""
                            width={40}
                            height={40}
                            style={{
                              borderRadius: "50%",
                              border: "3px solid",
                              borderColor: colors[i],
                            }}
                          />
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_Image_BaseURL}${item?.image}`}
                            alt=""
                            width={40}
                            height={40}
                            style={{
                              borderRadius: "50%",
                              border: "3px solid",
                              borderColor: colors[i],
                            }}
                          />
                        )}
                      </div>
                      <div
                        className="ms-2 lh-base"
                        style={{
                          width: "max-content",
                        }}
                      >
                        <p className="mb-0 font16x500">{item?.name}</p>
                        <p className="mb-0 font16x500">
                          {`${
                            salonNew?.country?.htmlSymbol !== ""
                              ? String.fromCharCode(
                                  salonNew?.country?.htmlSymbol
                                )
                              : salonNew?.country?.currency
                          }${parseInt(item?.revenue).toLocaleString()}`}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StylistPerformance;
