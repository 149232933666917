import React, { useEffect, useState } from "react";
import Layout from "../../../../Components/Layout";
import useFetch from "../../../../hooks/useFetch";
import { ADMIN, SALON_WALLET } from "../../../../utils/Constants";
import SearchInput2 from "../../../../Shared Components/SearchInput2";
import Pagination from "../../../../Components/Pagination";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../../Components/Loader";
import moment from "moment";
import InputDate from "../../../../Shared Components/InputDate";
import { getAddedOnDateTime } from "../../../../Functions/dateTime";
import MakeRecharge from "../../../../Components/Store Wallet/MakeRecharge";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";
import MobileWalletFilters from "../../../../Components/Mobile/mobileWalletFilter";
import { useNavigate } from "react-router-dom";

const setEntryTypeColor = (value) => {
  switch (value) {
    case "CR":
      return "#13BF6D";
    case "DR":
      return "#fe7d8f";
    default:
      return -1;
  }
};

const Wallet = () => {
  const [commonData, setCommonData] = useState({
    data: {},
    modal: false,
  });
  const salonNew = JSON.parse(localStorage.getItem("salon_details"));
  const role = JSON.parse(localStorage.getItem("role"));
  const navigate = useNavigate();
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
    salon: salonNew?._id && salonNew?._id,
  });
  console.log(filters);

  const [param, setParam] = useState({
    // show: false,
  });
  console.log("param", param);

  const functions = {
    addParam: () => {
      if (param.fromDate && param.tillDate) {
        const checkDate = moment(param.tillDate, "YYYY-MM-DD").isAfter(
          moment(param.fromDate, "YYYY-MM-DD")
        );
        if (checkDate) {
          setFilter({
            ...filters,
            ...param,
          });
        } else {
          toast.error("From date must be less than till date.", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    },
    resetParam: () => {
      setParam({
        show: false,
      });
      delete filters["user.mobile"];
      delete filters["user.name"];
      delete filters.q;
      delete filters.fromDate;
      delete filters.tillDate;
      getData();
    },
    redirectToOrder: (qrCode, entryFor, orderId) => {
      if (qrCode) {
        if (entryFor === "S") {
          navigate(`/salon/qr-order/service/${orderId}`);
        } else {
          navigate(`/salon/qr-order/product/${orderId}`);
        }
      } else {
        if (entryFor === "S") {
          navigate(`/salon/order/service/${orderId}`);
        } else {
          navigate(`/salon/order/product/${orderId}`);
        }
      }
    },
    onAddRecharge: () => {
      setCommonData({
        data: undefined,
        modal: true,
      });
      document.body.style.overflow = "hidden";
    },
  };

  const [salonWallets, totalResult, error, loader, getData] = useFetch({
    url: `${SALON_WALLET}`,
    resultField: "salonWallets",
    parameter: filters,
  });

  useEffect(() => {
    getData();
  }, [filters]);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Store Wallet"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileWalletFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          param={param}
          setParam={setParam}
          addParam={functions.addParam}
          resetParam={functions.resetParam}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Store Wallet"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Store Wallet"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper clients-page">
        <div>
          <div className="container ms-0 ps-0 d-flex justify-content-between align-items-center mb-2">
            <h3 className="font22x500 pageHeading mb-0">Store Wallet</h3>
            <div className="desktop">
              <div className="d-flex">
                <p
                  className="font22x500 mb-0"
                  style={{
                    color:
                      salonNew?.wallet < 0 ? "var(--status_color4)" : "#13BF6D",
                  }}
                >
                  {salonWallets?.length !== 0 &&
                    `${
                      salonNew?.country?.htmlSymbol !== ""
                        ? String.fromCharCode(salonNew?.country?.htmlSymbol)
                        : salonNew?.country?.currency
                    } ${parseInt(
                      salonWallets[0]?.salon?.wallet
                    ).toLocaleString()}`}
                </p>
                {role === ADMIN && (
                  <button
                    className="btn addBtn shadow-none ms-3"
                    onClick={functions.onAddRecharge}
                  >
                    Recharge
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="mobile">
            <div className="balanceWrapper d-flex justify-content-between align-items-center mx-2 mt-2">
              <p className="mb-0 font16x700">
                Balance:
                <span
                  className="ms-1"
                  style={{
                    color:
                      salonNew?.wallet < 0 ? "var(--status_color4)" : "#13BF6D",
                  }}
                >
                  {salonWallets?.length !== 0 &&
                    `${
                      salonNew?.country?.htmlSymbol !== ""
                        ? String.fromCharCode(salonNew?.country?.htmlSymbol)
                        : salonNew?.country?.currency
                    } ${parseInt(
                      salonWallets[0]?.salon?.wallet
                    ).toLocaleString()}`}
                </span>
              </p>
              {role === ADMIN && (
                <button
                  className="btn addBtn shadow-none"
                  onClick={functions.onAddRecharge}
                >
                  Recharge
                </button>
              )}
            </div>
          </div>

          <div className="filter-container container ms-0 ps-0 mb-3">
            <div className="row">
              <div className="col-12 col-lg-5 d-flex align-items-end">
                <SearchInput2
                  type="text"
                  placeholder="Search by Order Id"
                  stateData={param}
                  setStateData={setParam}
                  parameter1="customOrderId"
                  parameter2={undefined}
                  addParam={functions.addParam}
                  resetParam={functions.resetParam}
                />
              </div>
              <div className="col-12 col-lg-7 d-flex justify-content-lg-end align-items-end">
                {/* date from */}
                <InputDate
                  label="From"
                  htmlFor="from"
                  type="date"
                  stateData={param?.fromDate}
                  setStateData={(e) => {
                    setParam({
                      ...param,
                      fromDate: moment(e).format("YYYY-MM-DD"),
                    });
                  }}
                />

                {/* date to */}
                <div className="ms-3">
                  <InputDate
                    label="Till"
                    htmlFor="till"
                    type="date"
                    stateData={param?.tillDate}
                    setStateData={(e) => {
                      setParam({
                        ...param,
                        tillDate: moment(e).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </div>

                <button
                  className="btn filterBtn shadow-none ms-3"
                  onClick={functions.addParam}
                >
                  Apply
                </button>
                <button
                  className="btn resetBtn shadow-none ms-3"
                  onClick={functions.resetParam}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>

        {salonWallets?.length !== 0 ? (
          <>
            <div className="container desktop dataTable ms-0 ps-0">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">SL.No.</th>
                    <th scope="col">Order Id</th>
                    <th scope="col">Type</th>
                    <th scope="col">Entry Type</th>
                    <th scope="col">Entry For</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Created On</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {salonWallets?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {(filters.page - 1) * filters.per_page + (i + 1)}
                        </td>
                        <td>{item?.customOrderId ?? "--"}</td>
                        <td>{item?.type}</td>
                        <td
                          className="font14x500"
                          style={{ color: setEntryTypeColor(item?.entryType) }}
                        >
                          {item?.entryType}
                        </td>
                        <td>{item?.entryFor ?? "--"}</td>
                        {salonNew?.country?.htmlSymbol !== "" ? (
                          <td>
                            {`${String.fromCharCode(
                              salonNew?.country?.htmlSymbol
                            )} ${(item?.amount).toLocaleString()}`}
                          </td>
                        ) : (
                          <td>
                            {`${
                              salonNew?.country?.currency
                            } ${(item?.amount).toLocaleString()}`}
                          </td>
                        )}
                        <td>{getAddedOnDateTime(item?.createdAt)}</td>
                        <td>
                          {item?.qrCode !== undefined &&
                          item?.entryFor !== undefined &&
                          item?.order !== undefined ? (
                            <button
                              type="button"
                              className="btn font14x400 filterBtn shadow-none me-2"
                              onClick={() =>
                                functions.redirectToOrder(
                                  item?.qrCode,
                                  item?.entryFor,
                                  item?.order
                                )
                              }
                            >
                              Go to Order
                            </button>
                          ) : (
                            "--"
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="container mobile dataTable ms-0 ps-0">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Type</th>
                    <th scope="col">Entry Type</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Entry For</th>
                    <th scope="col">Order Id</th>
                    <th scope="col">Created On</th>
                  </tr>
                </thead>
                <tbody>
                  {salonWallets?.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        onClick={() =>
                          functions.redirectToOrder(
                            item?.qrCode,
                            item?.entryFor,
                            item?.order
                          )
                        }
                        style={{
                          pointerEvents:
                            item?.qrCode !== undefined &&
                            item?.entryFor !== undefined &&
                            item?.order !== undefined
                              ? "unset"
                              : "none",
                          color:
                            item?.qrCode === undefined &&
                            item?.entryFor === undefined &&
                            item?.order === undefined &&
                            "grey",
                        }}
                      >
                        <td>{item?.type}</td>
                        <td
                          className="font14x500"
                          style={{ color: setEntryTypeColor(item?.entryType) }}
                        >
                          {item?.entryType}
                        </td>
                        {salonNew?.country?.htmlSymbol !== "" ? (
                          <td>
                            {`${String.fromCharCode(
                              salonNew?.country?.htmlSymbol
                            )} ${(item?.amount).toLocaleString()}`}
                          </td>
                        ) : (
                          <td>
                            {`${
                              salonNew?.country?.currency
                            } ${(item?.amount).toLocaleString()}`}
                          </td>
                        )}
                        <td>{item?.entryFor ?? "--"}</td>
                        <td>{item?.customOrderId ?? "--"}</td>
                        <td>
                          {moment(item?.createdAt).format("DD-MMM-YYYY")}
                          <br />
                          {moment(item?.createdAt).format("HH:mm")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Wallet Details Found"
            )}
          </div>
        )}

        {salonWallets?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      {/* recharge form add and update both */}
      {commonData.modal && (
        <MakeRecharge
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
          id={salonNew?._id}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default Wallet;
