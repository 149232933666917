import React, { createContext, useEffect, useState } from "react";

export const Auth = createContext(null);

const AuthContext = ({ children }) => {
  // salon details
  const [salonDetails, setSalonDetails] = useState("");

  // salon token
  const [token, setToken] = useState("");

  // salon role
  const [role, setRole] = useState("");

  // update salon details
  const updateSalon = (props) => {
    setSalonDetails(props);
  };

  // update token details
  const updateToken = (props) => {
    setToken(props);
  };

  // update role details
  const updateRole = (props) => {
    setRole(props);
  };

  // setting initial value of salon and token
  useEffect(() => {
    const salonNew = JSON.parse(localStorage.getItem("salon_details"));
    setSalonDetails(salonNew);

    const token = JSON.parse(localStorage.getItem("token"));
    setToken(token);

    const role = JSON.parse(localStorage.getItem("role"));
    setRole(role);
  }, [AuthContext]);

  return (
    <>
      <Auth.Provider
        value={{
          salonDetails,
          updateSalon,
          token,
          updateToken,
          role,
          updateRole,
        }}
      >
        {children}
      </Auth.Provider>
    </>
  );
};

export default AuthContext;
