import React from "react";
import UserForm from "..";
import UserWalletForm from "../../User Wallet";
import SendNotification from "../SendNotification";
import ViewWalletHistory from "../ViewWalletHistory";
import LoginProcess from "./LoginProcess";
import VerifyProcess from "./VerifyProcess";

const UserCreationProcess = (props) => {
  const { state, setState, apiCall, data } = props;

  switch (state.step) {
    case 1:
      return <LoginProcess state={state} setState={setState} />;
    case 2:
      return <VerifyProcess state={state} setState={setState} />;
    case 3:
      return (
        <UserForm
          state={state}
          setState={setState}
          apiCall={apiCall}
          data={data}
        />
      );
    case 4:
      return <SendNotification state={state} setState={setState} />;
    case 5:
      return <ViewWalletHistory state={state} setState={setState} />;
    case 6:
      return (
        <UserWalletForm state={state} setState={setState} apiCall={apiCall} />
      );
    default: //nothing to render
  }

  return <>UserCreationProcess</>;
};

export default UserCreationProcess;
