import {
  ADMIN,
  CHILD_SALON,
  MASTER_SALON,
  MASTER_SALON_USER,
} from "../utils/Constants";

export const isMasterSalonLogin = () => {
  const role = JSON.parse(localStorage.getItem("role"));
  const token = JSON.parse(localStorage.getItem("token"));
  if (
    token &&
    (role === MASTER_SALON ||
      role === ADMIN ||
      role === CHILD_SALON ||
      role === MASTER_SALON_USER)
  ) {
    return true;
  }
  return false;
};

// export const isChildSalonLogin = () => {
//   const role = JSON.parse(localStorage.getItem("role"));
//   const token = JSON.parse(localStorage.getItem("token"));
//   if (token && role === CHILD_SALON) {
//     return true;
//   }
//   return false;
// };

export const isAdminLogin = () => {
  const role = JSON.parse(localStorage.getItem("role"));
  const token = JSON.parse(localStorage.getItem("token"));
  if (token && role === ADMIN) {
    return true;
  }
  return false;
};
