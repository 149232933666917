import React from "react";
import Loader from "../../../Loader";
import Skeleton from "react-loading-skeleton";

const HomeServiceOrders = (props) => {
  const { ordersCount, loader } = props;

  return (
    <>
      {loader ? (
        <Skeleton width={242} height={122} />
      ) : (
        ordersCount?.servicesAtHome !== 0 && (
          <div className="statsTabWrapper">
            <p
              className="font14x500 mb-4"
              style={{
                color: "var(--text3)",
              }}
            >
              Home Service Orders
            </p>
            <span
              className="font36x700"
              style={{
                color: "var(--text2)",
              }}
            >
              {loader ? (
                <span className="d-flex align-items-center">
                  0
                  <span className="ms-2">
                    <Loader height="20" width="20" color="#772286" />
                  </span>
                </span>
              ) : (
                ordersCount?.servicesAtHome?.toLocaleString()
              )}
            </span>
          </div>
        )
      )}
    </>
  );
};

export default HomeServiceOrders;
