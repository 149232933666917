import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../../Components/Layout";
import Loader from "../../../../Components/Loader";
import Categories from "../../../../Components/Salon Buy Products/Categories";
import useAuthContext from "../../../../hooks/useAuthContext";
import useCategory from "../../../../hooks/useCategory";
import useFetch from "../../../../hooks/useFetch";
import SearchInput2 from "../../../../Shared Components/SearchInput2";
import {
  authHeader,
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  PRODUCT_CATEGORY_SEARCH,
  SALON_PRODUCTS_SEARCH,
} from "../../../../utils/Constants";
import productImage from "../../../../uploads/product/product1.jpg";
import StarRatings from "react-star-ratings";
import Pagination from "../../../../Components/Pagination";
import { toast, ToastContainer } from "react-toastify";
import CartHeader from "../../../../Components/CartHeader";
import CartPaymentProcess from "../../../../Components/CartPaymentProcess";
import axios from "axios";
import { CartContext } from "../../../../utils/Private Routes/MasterSalonPrivateRoutes";
import { isNumberContainDecimal } from "../../../../Functions/other";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";
import MobileBuyProductFilters from "../../../../Components/Mobile/mobileBuyProductFilter";

const BuyProduct = () => {
  const [commonData, setCommonData] = useState({
    modal: false,
    step: 0,
  });

  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [category, loaderSmall, getCategory] = useCategory({
    url: PRODUCT_CATEGORY_SEARCH,
    resultField: "productCategories",
  });

  const [filters, setFilter] = useState({
    owner: "admin",
    status: 1,
    page: 1,
    per_page: 8,
  });

  const { token } = useAuthContext();
  const { cart, cartLoader, getCart } = useContext(CartContext);

  const [param, setParam] = useState({
    show: false,
  });

  const [gotProducts, setGotProducts] = useState([]);
  // console.log("gotProducts", gotProducts);

  const [products, totalResult, error, loader, getData] = useFetch({
    url: `${SALON_PRODUCTS_SEARCH}`,
    resultField: "products",
    parameter: filters,
  });

  const functions = {
    addParam: () => {
      setFilter({
        ...filters,
        ...param,
      });
    },
    resetParam: () => {
      setParam({});
      delete filters.category;
      delete filters.categoryName;
      delete filters.q;
      getData();
    },
    onClick: () => {
      setCommonData({
        modal: true,
        step: 1,
      });
      document.body.style.overflow = "hidden";
    },
    addItemIntoCart: async (id, count, stock) => {
      let actualCount = count ? count + 1 : 1;

      if (actualCount <= stock) {
        if (token && actualCount) {
          try {
            const res = await axios.put(
              `${CART_ADD_ITEM}?product=${id}&count=${actualCount}`,
              {},
              {
                //headers: authHeader,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                  "X-Requested-With": "XMLHttpRequest",
                },
              }
            );

            if (res.status === 200) {
              getCart();
              // getData();
              // updateData();
              toast.success("Item added into cart", {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            } else {
              console.log("else error: ", res.status);
            }
          } catch (error) {
            console.log("catch error: ", error);
            if (error?.response?.status === 502) {
              toast.error(error?.response?.data?.message, {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            }
          }
        }
      } else {
        toast.error("Maximum Limit Exceeded", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    },
    removeItem: async (id) => {
      if (token) {
        try {
          const res = await axios.put(
            `${CART_REMOVE_ITEM}?product=${id}`,
            {},
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            getCart();
            // getData();
            toast.success("Item Removed", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
    removeItemFromCart: async (id, count) => {
      if (count > 1) {
        if (token) {
          try {
            const res = await axios.put(
              `${CART_ADD_ITEM}?product=${id}&count=${count - 1}`,
              {},
              {
                //headers: authHeader,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                  "X-Requested-With": "XMLHttpRequest",
                },
              }
            );

            if (res.status === 200) {
              getCart();
              // getData();
              toast.success("Item Removed", {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            } else {
              console.log("else error: ", res.status);
            }
          } catch (error) {
            console.log("catch error: ", error);
            if (error?.response?.status === 502) {
              toast.error(error?.response?.data?.message, {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            }
          }
        }
      } else {
        if (token) {
          try {
            const res = await axios.put(
              `${CART_REMOVE_ITEM}?product=${id}`,
              {},
              {
                //headers: authHeader,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                  "X-Requested-With": "XMLHttpRequest",
                },
              }
            );

            if (res.status === 200) {
              getCart();
              // getData();
              toast.success("Item Removed", {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            } else {
              console.log("else error: ", res.status);
            }
          } catch (error) {
            console.log("catch error: ", error);
            if (error?.response?.status === 502) {
              toast.error(error?.response?.data?.message, {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            }
          }
        }
      }
    },
  };

  useEffect(() => {
    getData();
  }, [filters]);
  useEffect(() => {
    getCategory();
  }, []);
  useEffect(() => {
    if (products) {
      updateData();
    }
  }, [products, cart]);

  const updateData = () => {
    let localProducts = [...products]; //deep copy for products, and its  reference is changed

    if (cart?.products?.length !== 0) {
      localProducts?.map((product) => {
        cart?.products?.map((item) => {
          if (item?.product?._id === product?._id) {
            product.isProductCart = item;
          }
        });
      });
      setGotProducts(localProducts);
    } else {
      console.log("empty cart");
      setGotProducts(products);
    }
  };

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Buy Products"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileBuyProductFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          category={category}
          filters={filters}
          setFilter={setFilter}
          resetParam={functions.resetParam}
          addParam={functions.addParam}
          param={param}
          setParam={setParam}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Buy Products"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Buy Products"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper clients-page">
        <div className="container ms-0 ps-0 d-flex justify-content-between align-items-center mb-2">
          <h3 className="font22x500 pageHeading">Products</h3>
        </div>
        <div className="filter-container container ms-0 ps-0 mb-2">
          <div className="row">
            <div className="col-5 d-flex align-items-end">
              <SearchInput2
                type="text"
                placeholder="Search by name"
                stateData={param}
                setStateData={setParam}
                parameter1="name"
                parameter2={undefined}
                addParam={functions.addParam}
                resetParam={functions.resetParam}
              />
            </div>
            <div className="col-7 d-flex justify-content-lg-end align-items-end">
              <div className="me-3">
                <button
                  className="btn resetBtn shadow-none ms-3"
                  onClick={functions.resetParam}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          <Categories
            data={category}
            loader={loaderSmall}
            filters={filters}
            setFilter={setFilter}
          />
        </div>

        {/* use cart loader here */}
        {gotProducts?.length !== 0 ? (
          <div className="container ms-0 ps-md-0 mb-3">
            <div className="row">
              {gotProducts?.map((product, i) => {
                return (
                  <div
                    className="col-12 col-sm-6 col-md-4 col-lg-3 mt-4"
                    key={i}
                  >
                    <div className="card">
                      <div
                        style={{
                          height: "150px",
                        }}
                      >
                        {product?.image === null ? (
                          <img
                            src={productImage}
                            alt="product"
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_Image_BaseURL}${product?.image}`}
                            alt="product"
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        )}
                      </div>
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div>
                            <h5
                              className="card-title font14x700 mb-1"
                              title={product?.name}
                            >
                              {product?.name?.length > 15
                                ? `${product?.name?.substring(0, 15)}...`
                                : `${product?.name}`}
                            </h5>
                            <p
                              className="mb-0 font12x400"
                              style={{ color: "var(--border_primary)" }}
                            >
                              {product?.quantity}
                            </p>
                          </div>

                          {/* add button */}
                          {product?.isProductCart !== undefined ? (
                            <div>
                              <p className="cartAddBtn mb-0 font16x500 justify-content-between">
                                <span
                                  onClick={() => {
                                    functions.removeItemFromCart(
                                      product?.isProductCart?.product?._id,
                                      product?.isProductCart?.count
                                    );
                                  }}
                                >
                                  -
                                </span>
                                <span>{product?.isProductCart?.count}</span>
                                <span
                                  onClick={() => {
                                    functions.addItemIntoCart(
                                      product?.isProductCart?.product?._id,
                                      product?.isProductCart?.count,
                                      product?.currentStock
                                    );
                                  }}
                                >
                                  +
                                </span>
                              </p>
                            </div>
                          ) : product?.currentStock === 0 ? (
                            <div>
                              <p className="outOfStockBtn mb-0 font16x500">
                                Out of stock
                              </p>
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                functions.addItemIntoCart(
                                  product?._id,
                                  undefined,
                                  product?.currentStock
                                );
                              }}
                            >
                              <p className="cartAddBtn mb-0 font16x500">Add+</p>
                            </div>
                          )}
                        </div>

                        <p className="card-text mb-0 mt-2">
                          <span className="font16x700">
                            {`${String.fromCharCode(product?.currencySymbol)} ${
                              product?.discountPrice
                            }`}
                          </span>
                          <span className="font14x400 ms-2">
                            <s>{`${String.fromCharCode(
                              product?.currencySymbol
                            )} ${product?.mrp}`}</s>
                          </span>
                          <span
                            className="font12x400 ms-2"
                            style={{
                              color: "var(--status_color3)",
                            }}
                          >
                            {Math.round(
                              ((product?.mrp - product?.discountPrice) /
                                product?.mrp) *
                                100
                            )}
                            % off
                          </span>
                        </p>
                        <hr />
                        <div>
                          <div className="productRatingContainer">
                            <StarRatings
                              rating={
                                product?.totalRating / product?.noOfPeople
                              }
                              starEmptyColor="#a6a6a6"
                              starRatedColor="#FCC153"
                              starDimension="12px"
                              numberOfStars={5}
                              starSpacing="1px"
                            />
                            <div
                              className="font10x500 ms-2"
                              style={{ color: "var(--text4)" }}
                            >
                              {isNumberContainDecimal(
                                product?.totalRating / product?.noOfPeople
                              )
                                ? (
                                    product?.totalRating / product?.noOfPeople
                                  ).toFixed(1)
                                : product?.totalRating / product?.noOfPeople}
                            </div>
                          </div>
                          <div className="mt-2">
                            <p className="mb-0" title={product?.description}>
                              {product?.description?.length > 90
                                ? `${product?.description?.substring(0, 90)}...`
                                : `${product?.description}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Product Found"
            )}
          </div>
        )}

        {gotProducts?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      <CartHeader displayOn="buy_product" onClick={functions.onClick} />

      {commonData.modal && (
        <CartPaymentProcess
          state={commonData}
          setState={setCommonData}
          addItemIntoCart={functions.addItemIntoCart}
          removeItemFromCart={functions.removeItemFromCart}
          removeItem={functions.removeItem}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default BuyProduct;
