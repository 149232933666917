import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { USER_WALLET_HISTORY } from "../../../utils/Constants";
import useFetch from "../../../hooks/useFetch";
import Loader from "../../Loader";
import moment from "moment";
import Pagination from "../../Pagination";

const setEntryTypeColor = (value) => {
  switch (value) {
    case "CR":
      return "#13BF6D";
    case "DR":
      return "#fe7d8f";
    default:
      return -1;
  }
};

const ViewWalletHistory = ({ state, setState }) => {
  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
      check: "",
      step: 0,
    });
    document.body.style.overflow = "unset";
  };

  const [filters, setFilter] = useState({
    user: state?.data?._id,
    page: 1,
    per_page: 10,
  });

  const [userWallets, totalResult, error, loader, getData] = useFetch({
    url: `${USER_WALLET_HISTORY}`,
    resultField: "userWallets",
    parameter: filters,
  });

  useEffect(() => {
    getData();
  }, [filters]);

  return (
    <>
      <div
        className={
          state.modal
            ? "deleteModalWrapper deleteModalWrapper-active"
            : "deleteModalWrapper"
        }
      >
        <div className="deleteModalDiv" onClick={setPositionToBody}></div>
        <div className="walletHistoryWrapper">
          <div className="headingWrapper d-flex justify-content-between">
            <h1 className="font22x500 mb-0">Wallet History</h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="historyTabsWrapper">
            {userWallets?.length !== 0 ? (
              userWallets?.map((item, i) => {
                return (
                  <div className="tabsDiv" key={i}>
                    <div className="d-flex">
                      <img src="/Assets/walletIcon.svg" alt="wallet" />
                      <div className="ms-3">
                        <p
                          className="mb-0 font14x400"
                          style={{
                            color: "var(--text5)",
                          }}
                        >
                          {moment(item?.createdAt).format("DD MMM YYYY")}
                        </p>
                        <p className="mb-0 font16x500">
                          {item?.entryTitle
                            ? item?.entryTitle
                            : item?.customOrderId
                            ? `Paid to Order: ${item?.customOrderId}`
                            : item?.customMembershipOrderId
                            ? `Paid to Membership Order: ${item?.customMembershipOrderId}`
                            : ""}
                        </p>
                        <p
                          className="mb-0 font14x400"
                          style={{
                            color: "var(--text5)",
                          }}
                        >
                          {item?.entryType === "CR" &&
                            `Expire On: ${moment(item?.expiresOn).format(
                              "DD MMM YYYY"
                            )}`}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p
                        className="mb-0 font14x500"
                        style={{
                          color: setEntryTypeColor(item?.entryType),
                        }}
                      >
                        {item?.entryType === "CR"
                          ? `+ ${item?.amount}`
                          : `- ${item?.amount}`}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: "100%", height: "50vh" }}
              >
                {loader ? (
                  <Loader height="40" width="40" color="#772286" />
                ) : (
                  "No Wallet history found"
                )}
              </div>
            )}

            {userWallets?.length !== 0 && (
              <div className="d-flex justify-content-center">
                <Pagination
                  currentPage={filters.page}
                  onPageChange={(e) => {
                    setFilter({
                      ...filters,
                      page: e,
                    });
                  }}
                  pageSize={filters.per_page}
                  totalCount={Math.ceil(totalResult / filters.per_page)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewWalletHistory;
