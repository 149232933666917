import React, { useContext } from "react";
import { ReviewMonitoringContext } from "../../../Pages/Salon/Master Salon/Reviews&Ratings";
import Loader from "../../Loader";

const TotalReviews = () => {
  const { analytics, loader } = useContext(ReviewMonitoringContext);

  return (
    <>
      <div className="statsTabWrapper">
        <h4 className="font14x500" style={{ color: "var(--text3)" }}>
          TOTAL REVIEWS
        </h4>
        <h5 className="font12x500" style={{ color: "var(--text4)" }}>
          All time Stats
        </h5>
        <div className="mt-3">
          <span
            className="font36x700"
            style={{
              color: "var(--text2)",
            }}
          >
            {loader ? (
              <span className="d-flex align-items-center">
                0
                <span className="ms-2">
                  <Loader height="20" width="20" color="#772286" />
                </span>
              </span>
            ) : analytics?.totalReviews?.length === 0 ? (
              "0"
            ) : analytics?.totalReviews ? (
              analytics?.totalReviews[0]?.reviewsCount
            ) : (
              "0"
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default TotalReviews;
