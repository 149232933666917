import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import MainFile from "../../../../Components/RegistrationForm";
import axios from "axios";

export const registrationContext = createContext();

const RegistrationForm = () => {
  const [step, setStep] = useState(1);
  const [location, setLocation] = useState({
    lat: "",
    lng: "",
    // lat: "30.3256502",
    // lng: "77.8770454",
    locationName: "",
  });
  console.log("location", location);

  const { state } = useLocation();

  const [registrationData, setRegistrationData] = useState({
    logo: "",
    salonName: "",
    salonDescription: "",
    images: [],
    salonEmail: "",
    salonContactNumber: state && state.mobile,
    line_1: "",
    line_2: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    salonTimings: [],
    salonType: "",
    classType: "",
    priceRating: "",
    salonCategory: [],
    isAC: false,
    isCardPayment: false,
    isToilets: false,
    isParkingAvailable: false,
    isUber: false,
    tandcCheck: false,
    user: state.user ? state.user : null,
    masterSalonId: state.masterSalonId ? state.masterSalonId : null,
  });

  useEffect(() => {
    console.log("form data index: ", registrationData);
  }, [registrationData]);

  const functions = {
    nextStep: () => {
      setStep(step + 1);
    },
    prevStep: () => {
      setStep(step - 1);
    },
    getMyCurrentLocation: () => {
      const location = window.navigator && window.navigator.geolocation;

      if (location) {
        location.getCurrentPosition(async (p) => {
          window.localStorage.setItem("latitude", p.coords.latitude);
          window.localStorage.setItem("longitude", p.coords.longitude);

          functions.handleLocation(p.coords.latitude, p.coords.longitude);
        });
      }
    },
    handleLocation: async (latitude, longitude) => {
      if (latitude && longitude) {
        const res = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${latitude},${longitude}&key=${process.env.REACT_APP_API_MAP}`
        );

        // console.log("res", res);

        if (res.status === 200) {
          setLocation({
            lat: res.data.results[0].geometry.location.lat,
            lng: res.data.results[0].geometry.location.lng,
            locationName: res.data.results[0].formatted_address,
          });

          res?.data?.results &&
            res.data.results.forEach((addresses) => {
              addresses.address_components.forEach((address) => {
                address.types.forEach((item) => {
                  if (item === "locality") {
                    setRegistrationData((old) => {
                      return {
                        ...old,
                        city: address?.long_name,
                      };
                    });
                  }
                  if (item === "administrative_area_level_1") {
                    setRegistrationData((old) => {
                      return {
                        ...old,
                        state: address?.long_name,
                      };
                    });
                  }
                  if (item === "country") {
                    setRegistrationData((old) => {
                      return {
                        ...old,
                        country: address?.long_name,
                      };
                    });
                  }
                  if (item === "postal_code") {
                    setRegistrationData((old) => {
                      return {
                        ...old,
                        pincode: address?.long_name,
                      };
                    });
                  }
                });
              });
            });
        }
      } else {
        console.log("map error");
      }
    },
  };

  //location cords
  useEffect(() => {
    functions.getMyCurrentLocation();
  }, []);

  return (
    <>
      <registrationContext.Provider
        value={{
          location,
          setLocation,
          step,
          registrationData,
          setRegistrationData,
          functions,
        }}
      >
        <MainFile />
      </registrationContext.Provider>
      <ToastContainer />
    </>
  );
};

export default RegistrationForm;
