import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { toast, ToastContainer } from "react-toastify";
import useAuthContext from "../../hooks/useAuthContext";
import useFetch from "../../hooks/useFetch";
import {
  authHeader,
  SALON_APPLY_COUPON,
  SALON_COUPON_LIST,
} from "../../utils/Constants";
import { CartContext } from "../../utils/Private Routes/MasterSalonPrivateRoutes";
import CouponsList from "./CouponsList";

const Coupon = ({ state, setState }) => {
  const { token, salonDetails } = useAuthContext();
  const [inputCode, setInputCode] = useState("");
  const [couponList, setCouponList] = useState([]);
  // console.log("couponsList", couponList);
  const { cart, getCart } = useContext(CartContext);

  console.log("stateC", state);

  const [salonCoupons, totalResult, error, loader, getData] = useFetch({
    url: `${SALON_COUPON_LIST}`,
    resultField: "salonCoupons",
  });

  const functions = {
    applyCoupon: async (code) => {
      if (token && code) {
        try {
          const res = await axios.put(
            `${SALON_APPLY_COUPON}?code=${code}`,
            {},
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            getCart();
            toast.success("Coupon Applied", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
            setInputCode("");
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
    checkCoupon: (code) => {
      let couponUsedByUser = 0;

      if (salonCoupons && salonDetails?._id) {
        salonCoupons.forEach((item) => {
          if (item?.code === code) {
            if (
              cart?.cartCalculations?.products?.grandTotal >= item?.minValue
            ) {
              couponUsedByUser = 0;

              item?.usedByUsers?.forEach((userId) => {
                if (salonDetails?._id == userId) {
                  couponUsedByUser += 1;
                }
              });

              if (item.allowedUsage > item.usedByUsers.length) {
                if (item.singleUserLimit > couponUsedByUser) {
                  functions.applyCoupon(code);
                }
              } else {
                toast.error("Coupon is not applicable", {
                  position: "bottom-center",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                });
              }
            } else {
              toast.error(
                `For this coupon, order must be above ${String.fromCharCode(
                  salonDetails?.currencySymbol
                )}${item?.minValue}`,
                {
                  position: "bottom-center",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                }
              );
            }
          }
        });
      }
    },
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    let couponUsedByUser = 0;

    if (salonCoupons && salonDetails?._id) {
      salonCoupons.forEach((item) => {
        couponUsedByUser = 0;

        item.usedByUsers.forEach((userId) => {
          if (salonDetails?._id == userId) {
            couponUsedByUser += 1;
          }
        });

        if (item.allowedUsage > item.usedByUsers.length) {
          if (item.singleUserLimit <= couponUsedByUser) {
            item.isApplied = true;
          }
        } else {
          item.isApplied = true;
        }
      });
      setCouponList(salonCoupons);
    }
  }, [salonCoupons]);

  return (
    <>
      <div
        className={
          state?.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv"></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">Coupon</h1>
            <span
              onClick={() => {
                setState({
                  ...state,
                  step: 1,
                });
              }}
            >
              <HiOutlineArrowLeft />
            </span>
          </div>
          <div className="formContent">
            <div className="couponInputWrapper">
              <div className="d-flex justify-content-between align-items-center">
                <input
                  type="text"
                  className="couponInput w-100"
                  placeholder="Enter Coupon Code"
                  value={inputCode}
                  onChange={(e) => {
                    setInputCode(e.target.value);
                  }}
                />
                <button
                  className="mb-0 font14x500 ms-3"
                  style={{
                    color: "var(--btn_primary)",
                  }}
                  onClick={() => {
                    functions?.checkCoupon(inputCode);
                  }}
                >
                  APPLY
                </button>
              </div>
            </div>

            {/* all coupons */}
            <CouponsList
              couponList={couponList}
              checkCoupon={functions.checkCoupon}
              loader={loader}
            />

            {/* proceed button */}
            <button
              type="Submit"
              className="btn OtpBtn shadow-none mt-3 font16x500"
              onClick={() => {
                setState({
                  ...state,
                  step: 1,
                });
              }}
            >
              {salonCoupons?.length !== 0 ? "Proceed" : "Back"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Coupon;
