import React, { useEffect, useState } from "react";
import { Pie } from "@ant-design/plots";

const PieChartCategories = ({ categories }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([]);
    categories?.slice(0, 6).map((item, i) => {
      setData((old) => {
        return [
          ...old,
          {
            type: item?.name,
            value: item?.revenue,
          },
        ];
      });
    });
  }, [categories]);

  const config = {
    width: 300,
    height: 300,
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    label: {
      type: "outer",
      content: "{percentage}",
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
    legend: false,
    color: ["#97c1a9", "#fe7d8f", "#cbaacb", "#99ccff", "#90cbaa"],
  };
  return (
    <>
      {data?.length !== 0 ? (
        <Pie {...config} />
      ) : (
        <p className="mb-0 font14x400">No data found</p>
      )}
    </>
  );
};

export default PieChartCategories;
