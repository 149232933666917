import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import useAuthContext from "../../../hooks/useAuthContext";
import useFetch from "../../../hooks/useFetch";
import useLoading from "../../../hooks/useLoading";
import {
  authHeader,
  SLOT_DISABLE,
  SLOT_LIST_BY_SALONID,
} from "../../../utils/Constants";
import Loader from "../../Loader";
import SlotTimeSelection from "./SlotTimeSelection";
import UnblockAllSlots from "./UnblockAllSlots";

const UnblockSlotForm = ({ state, setState, apiCall }) => {
  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
      check: "",
    });
    document.body.style.overflow = "unset";
  };

  const [unblockSlotData, setUnblockSlotData] = useState({
    date: state?.data?.day && moment(state?.data?.day).format("YYYY-MM-DD"),
    time: [],
    unblockAllStots: false,
  });
  console.log("unblockSlotData", unblockSlotData);

  const [allSlotsBlock, setAllSlotsBlock] = useState([]);

  const { salonDetails, token } = useAuthContext();
  const [slotv2, totalResult, error, loader, getData] = useFetch({
    url: `${SLOT_LIST_BY_SALONID}${salonDetails?._id}?date=${unblockSlotData?.date}`,
    resultField: "slotv2",
  });

  const [loader2, showLoader, hideLoader] = useLoading();

  const formSubmit = async (e) => {
    e.preventDefault();
    console.log("form submit called");

    if (token) {
      try {
        showLoader();
        const res = await axios.put(
          `${SLOT_DISABLE}${salonDetails?._id}/slots`,
          {
            date: unblockSlotData.date,
            slots: unblockSlotData.time,
          },
          {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );

        if (res.status === 200) {
          setPositionToBody();
          apiCall();
          hideLoader();
          toast.success("Slot Unblocked", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          console.log("else error: ", res.status);
        }
      } catch (error) {
        console.log("catch error: ", error);
        if (error?.response?.status === 502) {
          hideLoader();
          toast.error(error?.response?.data?.message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    }
  };

  const checkSlotStatus = (slots) => {
    let check = false;

    if (allSlotsBlock?.length === slots?.length) {
      check = true;
    } else {
      check = false;
    }

    return check;
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setAllSlotsBlock([]);
    slotv2?.slots?.map((item) => {
      if (item.status === 0) {
        return setAllSlotsBlock((old) => {
          return [...old, item];
        });
      }
    });
  }, [slotv2]);

  useEffect(() => {
    setUnblockSlotData({
      ...unblockSlotData,
      unblockAllStots: checkSlotStatus(slotv2?.slots),
      time: slotv2?.slots,
    });
  }, [allSlotsBlock]);

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">{"Unblock Slot"}</h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formSubmit}>
              <div>
                <p className="font16x500">
                  {moment(unblockSlotData?.date).format("ddd, DD MMM YYYY")}
                </p>
              </div>

              {/* unblock all slots */}
              <UnblockAllSlots
                state={unblockSlotData}
                setState={setUnblockSlotData}
                data={slotv2?.slots}
              />

              <div className="mb-3">
                <SlotTimeSelection
                  loader={loader}
                  state={unblockSlotData}
                  setState={setUnblockSlotData}
                />
              </div>

              <button type="submit" className="btn OtpBtn shadow-none mt-3">
                {loader2 ? (
                  <div className="d-flex justify-content-center">
                    <Loader height="25" width="25" color="#fff" />
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnblockSlotForm;
