import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { RiDeleteBin6Line } from "react-icons/ri";
import Layout from "../../../Components/Layout";
import {
  SALON_SEARCH,
  USER_DELETE,
  USER_SEARCH,
} from "../../../utils/Constants";
import Pagination from "../../../Components/Pagination";
import useFetch from "../../../hooks/useFetch";
import { getAddedOnDate } from "../../../Functions/dateTime";
import DeleteModal from "../../../Components/DeleteRecord";
import DropdownSmall from "../../../Shared Components/DropdownSmall";
import Loader from "../../../Components/Loader";
import userImage from "../../../uploads/user/user1.svg";
import SearchInput from "../../../Shared Components/SearchInput";
import UserCreationProcess from "../../../Components/Admin/User Form/UserCreationProcess";
import PageNav from "../../../Components/PageNav";
import MobileSalonDashboardHeader from "../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileFilterHeader from "../../../Components/mobilefilterheader";
import MobileUserFilters from "../../../Components/Mobile/Admin/mobileUserFilter";

export const userLinks = [
  {
    link: "/admin/users",
    label: "Users",
  },
  {
    link: "/admin/temporary-users",
    label: "Temporary Users",
  },
];

const Users = () => {
  const [commonData, setCommonData] = useState({
    data: {},
    step: 0,
    modal: false,
    check: "",
  });

  const onEdit = (item) => {
    setCommonData({
      data: item,
      modal: true,
      step: 3,
      check: "user",
    });
    document.body.style.overflow = "hidden";
  };
  const onAdd = () => {
    setCommonData({
      data: undefined,
      modal: true,
      step: 3,
      check: "user",
    });
    document.body.style.overflow = "hidden";
  };
  const onSendNotification = () => {
    setCommonData({
      data: undefined,
      modal: true,
      step: 4,
      check: "notification",
    });
    document.body.style.overflow = "hidden";
  };
  const onViewWalletHistory = (item) => {
    setCommonData({
      data: { _id: item },
      modal: true,
      step: 5,
      check: "wallet_history",
    });
    document.body.style.overflow = "hidden";
  };
  const onAddWallet = (item) => {
    setCommonData({
      data: item,
      modal: true,
      step: 6,
      check: "wallet",
    });
    document.body.style.overflow = "hidden";
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [id, setId] = useState("");
  const onDelete = (id) => {
    setIsDeleteModalOpen(true);
    document.body.style.overflow = "hidden";
    setId(id);
  };

  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
  });
  console.log(filters);

  const [param, setParam] = useState({
    show: false,
  });
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const addParam = () => {
    setFilter({
      ...filters,
      ...param,
    });
  };

  const resetParam = () => {
    setParam({});
    delete filters.status;
    delete filters.statusName;
    delete filters.q;
    delete filters.mobile;
    getData();
  };

  const [salons, totalResult2, error2, loader2, getSalons] = useFetch({
    url: SALON_SEARCH,
    resultField: "salons",
    parameter: {
      f: "salonId,name",
      status: 1,
    },
  });

  const [users, totalResult, error, loader, getData] = useFetch({
    url: `${USER_SEARCH}`,
    resultField: "users",
    parameter: filters,
  });

  useEffect(() => {
    getSalons();
  }, []);

  useEffect(() => {
    window.localStorage.removeItem("salon_details");
    getData();
  }, [filters]);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Users"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileUserFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          addWallet={() => onAddWallet(undefined)}
          addUser={onAdd}
          sendNotification={onSendNotification}
          resetParam={resetParam}
          filters={filters}
          setFilter={setFilter}
          addParam={addParam}
          setParam={setParam}
          param={param}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Users"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Users"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper">
        <div>
          <div className="service-navigation-neworder-button-container">
            <div className="service-navigation-neworder-button d-flex justify-content-between pe-2">
              <PageNav links={userLinks} />
              <div className="desktopButton">
                <button
                  className="btn addBtn shadow-none me-3"
                  onClick={() => onAddWallet(undefined)}
                >
                  Wallet Colab
                </button>
                <button
                  className="btn addBtn shadow-none me-3"
                  onClick={onSendNotification}
                >
                  Send Notification
                </button>
                <button className="btn addBtn shadow-none" onClick={onAdd}>
                  Add New User
                </button>
              </div>
            </div>
          </div>
          <div className="filter-container container ms-0 ps-0 mb-3">
            <div className="row">
              <div className="col-5 d-flex align-items-end">
                <SearchInput
                  type="text"
                  placeholder="Search by name/mobile"
                  stateData={param}
                  setStateData={setParam}
                  parameter1="name"
                  parameter2="mobile"
                  addParam={addParam}
                  resetParam={resetParam}
                />
              </div>
              <div className="col-7 d-flex justify-content-end align-items-end">
                {/* status */}
                <DropdownSmall
                  data={[
                    { value: "All", label: "All" },
                    { value: "Enabled", label: "Enabled" },
                    { value: "Disabled", label: "Disabled" },
                  ]}
                  label="Status"
                  placeholder="Id Status"
                  htmlFor="status"
                  type="text"
                  stateData={filters?.statusName}
                  setStateData={(e) => {
                    if (e === "All") {
                      delete filters?.status;
                      setFilter({
                        ...filters,
                        statusName: e,
                      });
                    } else {
                      setFilter({
                        ...filters,
                        statusName: e === "Enabled" ? "Enabled" : "Disabled",
                        status: e === "Enabled" ? 1 : 2,
                      });
                    }
                  }}
                />

                <button
                  className="btn resetBtn shadow-none ms-3"
                  onClick={resetParam}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>

        {users?.length !== 0 ? (
          <div className="container dataTable ms-0 ps-0">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Image</th>
                  <th scope="col">User Details</th>
                  <th scope="col">Salon</th>
                  <th scope="col">OTP</th>
                  <th scope="col">Email Id</th>
                  <th scope="col">Country</th>
                  <th scope="col">Wallet Balance</th>
                  {/* <th scope="col">Fcm Token</th>
                  <th scope="col">Device Id</th> */}
                  <th scope="col">Created On</th>
                  <th scope="col">Id Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {users?.map((user, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        {user?.image === null ? (
                          <img
                            src={userImage}
                            alt="user"
                            width={48}
                            height={48}
                            style={{ borderRadius: "4px" }}
                          />
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_Image_BaseURL}${user?.image}`}
                            alt="user"
                            width={48}
                            height={48}
                            style={{ borderRadius: "4px" }}
                          />
                        )}
                      </td>
                      <td>
                        {user?.name}
                        <br />
                        {user?.mobile}
                      </td>
                      <td>
                        {user?.salon ? (
                          <>
                            <p className="mb-0">{user?.salon?.salonId}</p>
                            <p className="mb-0">{user?.salon?.name}</p>
                          </>
                        ) : (
                          "--"
                        )}
                      </td>
                      <td>{user?.otp}</td>
                      <td>{user?.email ?? "--"}</td>
                      <td>{user?.country?.name}</td>
                      <td>
                        <div className="d-flex justify-content-between align-items-center">
                          <div> {user?.wallet}</div>
                          <div className="ms-2">
                            <img
                              src="/Assets/wallet.svg"
                              width={36}
                              height={27}
                              alt="wallet"
                              onClick={() => onAddWallet(user?._id)}
                              style={{ cursor: "pointer" }}
                            />
                            <img
                              src="/Assets/history.svg"
                              width={36}
                              height={27}
                              alt="history"
                              onClick={() => onViewWalletHistory(user?._id)}
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                        </div>
                      </td>
                      {/* <td>654654</td>
                      <td>0001</td> */}
                      <td>{getAddedOnDate(user?.createdAt)}</td>
                      <td>
                        <p
                          className="status mb-0"
                          style={{
                            backgroundColor: `${
                              user?.status === 1
                                ? "var(--status_color2)"
                                : "var(--status_color1)"
                            }`,
                          }}
                        >
                          {`${user?.status === 1 ? "Enabled" : "Disabled"}`}
                        </p>
                      </td>
                      <td>
                        <button
                          className="btn font14x400 filterBtn shadow-none me-2"
                          onClick={() => {
                            onEdit(user);
                          }}
                        >
                          Edit
                        </button>
                        <span
                          className="deleteBtn"
                          onClick={() => {
                            onDelete(user?._id);
                          }}
                        >
                          <RiDeleteBin6Line />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Users Found"
            )}
          </div>
        )}

        {users?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      {/* delete modal */}
      {isDeleteModalOpen && (
        <DeleteModal
          state={isDeleteModalOpen}
          setState={setIsDeleteModalOpen}
          url={USER_DELETE}
          id={id}
          text="User"
          apiCall={getData}
        />
      )}

      {commonData.modal && (
        <UserCreationProcess
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
          data={salons}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default Users;
