import React from "react";

const RadioButton = (props) => {
  const { htmlFor, name, stateData, setStateData, value, label } = props;
  return (
    <>
      <div className="radioCustom col-6 d-flex align-items-center">
        <input
          type="radio"
          id={htmlFor}
          name={name}
          value={value}
          onChange={(e) => {
            setStateData(e.target.value);
          }}
          checked={stateData === value && true}
        />
        <label className="font14x400 form-check-label" htmlFor={htmlFor}>
          {label}
        </label>
      </div>
    </>
  );
};

export default RadioButton;
