import React from "react";
import { HiOutlineEye } from "react-icons/hi";

const ViewDataBtn = (props) => {
  const { handleClick, isDisabled = false } = props;

  return (
    <>
      <button
        className="viewDataBtn"
        onClick={handleClick}
        disabled={isDisabled}
      >
        <HiOutlineEye
          size={24}
          color={`${isDisabled ? "var(--text3)" : "var(--btn_primary)"}`}
        />
      </button>
    </>
  );
};

export default ViewDataBtn;
