import axios from "axios";
import React, { useEffect, useState } from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { toast } from "react-toastify";
import useAuthContext from "../../../../hooks/useAuthContext";
import useLoading from "../../../../hooks/useLoading";
import {
  authHeader,
  LOGIN_AUTH,
  VERIFY_OTP_AUTH,
} from "../../../../utils/Constants";
import Loader from "../../../Loader";

const inputs = Array(4).fill("");

const VerifyProcess = ({ state, setState }) => {
  const [inputOTP, setInputOTP] = useState("");
  const [loader, showLoader, hideLoader] = useLoading();
  const [OTP, setOTP] = useState(state?.otp);
  const { token } = useAuthContext();

  console.log("OTP:", OTP);

  //otp expireTime in seconds
  const [expireTime, setExpireTime] = useState(5);
  useEffect(() => {
    if (expireTime !== 0) {
      setTimeout(() => {
        setExpireTime(expireTime - 1);
      }, 60000);
    }
  }, [expireTime]);

  const functions = {
    handleKeyBoardFocus: (e) => {
      if ((e.keyCode === 8 || e.keyCode === 46) && e.target.value === "") {
        if (
          e.target &&
          e.target.parentElement &&
          e.target.parentElement.previousElementSibling &&
          e.target.parentElement.previousElementSibling.children[0]
        ) {
          e.target.parentElement.previousElementSibling.children[0].focus();
        }
      }
    },
    handleOTP: (e) => {
      if (e.target.value.length === 1) {
        if (
          e.target &&
          e.target.parentElement &&
          e.target.parentElement.nextElementSibling &&
          e.target.parentElement.nextElementSibling.children[0]
        ) {
          e.target.parentElement.nextElementSibling.children[0].focus();
        }
      }

      let otpNumber = "";
      const inputsValue = document.getElementsByClassName("otpField");
      for (const e of inputsValue) {
        otpNumber += e.value;
      }
      console.log(otpNumber);
      setInputOTP(otpNumber);
    },
    formSubmit: async (e) => {
      e.preventDefault();

      if (token) {
        try {
          showLoader();
          const res = await axios.post(
            VERIFY_OTP_AUTH,
            {
              mobile: state?.mobile,
              hash: state?.hash,
              otp: inputOTP,
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          console.log("res", res);

          if (res.status === 200) {
            hideLoader();
            // toast.success(res.data.message, {
            //   position: "bottom-center",
            //   autoClose: 1000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            // });
            setState({
              modal: true,
              step: 3,
              check: "user",
              mobile: state?.mobile,
            });
          }
        } catch (err) {
          if (err?.response?.status >= 400 && err?.response?.status <= 599) {
            hideLoader();
            toast.error(err?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
          console.log("err", err);
        }
      }
    },
    resendOtp: async () => {
      try {
        showLoader();
        const res = await axios.post(
          LOGIN_AUTH,
          {
            mobile: state?.mobile,
          },
          {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );

        if (res.status === 200) {
          setOTP(res.data.otp);
          setExpireTime(5);
          hideLoader();
        }
      } catch (err) {
        console.log("error: ", err);
        if (err.response.status === 502) {
          hideLoader();
          toast.error(err.response.data.message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    },
  };

  return (
    <>
      <div
        className={
          state?.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv"></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">Verify Process</h1>
            <span
              onClick={() => {
                setState({
                  ...state,
                  step: 1,
                });
              }}
            >
              <HiOutlineArrowLeft />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={functions.formSubmit}>
              <div className="row">
                <div className="col-12 mb-4 d-flex justify-content-center">
                  {inputs.map((item, index) => {
                    return (
                      <div className="otpInputs me-2" key={index}>
                        <input
                          type="tel"
                          className="otpField form-control shadow-none"
                          maxLength={1}
                          onKeyDown={functions.handleKeyBoardFocus}
                          onChange={functions.handleOTP}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="">
                {expireTime === 0 ? (
                  <p
                    className="resendOtp font14x500"
                    onClick={functions.resendOtp}
                  >
                    Resend OTP
                  </p>
                ) : (
                  <p>
                    Resend OTP in{" "}
                    {`${expireTime < 10 ? "0" + expireTime : expireTime} mins`}
                    {/* <p>
                          Timer:{" "}
                          {`00:${
                            expireTime < 10 ? "0" + expireTime : expireTime
                          }`}
                        </p> */}
                  </p>
                )}
              </div>

              <button
                type="submit"
                className="btn OtpBtn shadow-none font16x500"
              >
                {loader ? (
                  <div className="d-flex justify-content-center">
                    <Loader height="25" width="25" color="#fff" />
                  </div>
                ) : (
                  "Proceed"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyProcess;
