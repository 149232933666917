import React, { useContext, useEffect, useRef, useState } from "react";
import { step1Schema } from "../../utils/validationschema";
import InputField from "./sub components/InputField";
import { toast } from "react-toastify";
import InputError from "../../Shared Components/InputError";
import { useFormik } from "formik";
import { registrationContext } from "../../Pages/Salon/Master Salon/RegistrationForm";

const Step1 = () => {
  const { functions, registrationData, setRegistrationData } =
    useContext(registrationContext);

  const next = () => {
    functions.nextStep();
  };

  const formik = useFormik({
    initialValues: registrationData,
    validationSchema: step1Schema,
    onSubmit: (data) => {
      // console.log("data", data);
      setRegistrationData({
        ...registrationData,
        salonName: data.salonName,
        salonDescription: data.salonDescription,
        logo: data.logo,
        images: data.images,
      });
      next();
    },
  });

  const salon_logo = useRef(null);
  const salon_photos = useRef(null);
  const handleSingleImg = (e) => {
    e.preventDefault();
    salon_logo.current.click();
  };
  const handleMultipleImg = (e) => {
    e.preventDefault();
    salon_photos.current.click();
  };

  const [singleImage, setSingleImage] = useState("");
  const imageDataURL = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSingleImage(URL.createObjectURL(e.target.files[0]));
      formik.setFieldValue("logo", Array.from(e.target.files));
    }
  };

  const [multiImage, setMultiImage] = useState();
  useEffect(() => {
    //multi image url
    let urls = [];
    formik.values.images.forEach((file) => {
      urls.push(URL.createObjectURL(file));
    });
    setMultiImage(urls);
  }, [formik.values.images]);

  const deleteImage = (index) => {
    if (index === -1) {
      formik.setFieldValue("logo", "");
      setSingleImage("");
      salon_logo.current.value = "";
    } else {
      let filterImage = formik.values.images.filter((ele, i) => {
        return i !== index;
      });
      formik.setFieldValue("images", filterImage);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {/* salon name */}
        <div className="mb-3">
          <InputField
            label="Store Name*"
            htmlFor="salonname"
            type="text"
            name="salonName"
            registrationData={formik.values.salonName}
            setRegistrationData={formik.handleChange}
          />
          {formik.errors.salonName ? (
            <InputError error={formik.errors.salonName} />
          ) : null}
        </div>

        {/* salon Logo */}
        <div className="mb-3">
          <div className="mb-0">
            <label htmlFor="formFile" className="font12x500 mb-1 form-label">
              Store Logo
            </label>
            <input
              ref={salon_logo}
              className="form-control shadow-none"
              type="file"
              id="formFile"
              name="logo"
              accept={".png, .jpg, .jpeg"}
              hidden
              onChange={(e) => {
                imageDataURL(e);
              }}
            />
          </div>
          <div className="uploadImg d-flex align-items-center mb-2">
            <div
              className="uploadBtn font12x400 me-3"
              onClick={(e) => {
                handleSingleImg(e);
              }}
            >
              Upload
            </div>
            <span className="font12x400">
              Allowed file types: jpg, jpeg, png
            </span>
          </div>
          {singleImage && (
            <div className="imageWrapper">
              <img src={singleImage} alt="delete" width={61} height={61} />
              <div
                className="deleteDiv"
                onClick={() => {
                  deleteImage(-1);
                }}
              >
                <img src="/Assets/delete.svg" alt="delete" />
              </div>
            </div>
          )}
          {formik.errors.logo ? (
            <InputError error={formik.errors.logo} />
          ) : null}
        </div>

        {/* salon description */}
        <div className="mb-3">
          <label
            htmlFor="floatingTextarea2"
            className="font12x500 mb-1 form-label"
          >
            Description (optional)
          </label>
          <textarea
            className="form-control shadow-none"
            id="floatingTextarea2"
            style={{ height: "100px" }}
            name="salonDescription"
            value={formik.values.salonDescription}
            onChange={formik.handleChange}
          />
          {formik.errors.salonDescription ? (
            <InputError error={formik.errors.salonDescription} />
          ) : null}
        </div>

        {/* salon Photos */}
        <div className="mb-3">
          <div className="mb-0">
            <label htmlFor="formFile" className="font12x500 mb-1 form-label">
              Store Photos
            </label>
            <input
              ref={salon_photos}
              className="form-control shadow-none"
              key={Math.random()}
              type="file"
              id="formFile"
              name="images"
              hidden
              accept={".png, .jpg, .jpeg"}
              multiple
              onChange={(e) => {
                const selectedFile = e.target.files;
                if (selectedFile && selectedFile.length <= 5) {
                  // const newFiles = Array.from(selectedFile);
                  // setRegistrationData({
                  //   ...registrationData,
                  //   images: [...registrationData.images, ...newFiles],
                  // });
                  formik.setFieldValue("images", Array.from(selectedFile));
                } else {
                  toast.error("Maximum Limit Exceeded", {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                  });
                }
              }}
            />
          </div>
          <div className="uploadImg d-flex align-items-center mb-2">
            <button
              className="font12x400 me-3"
              onClick={(e) => {
                handleMultipleImg(e);
              }}
            >
              Upload
            </button>
            <div className="d-flex flex-column">
              <span className="font12x400">
                Allowed file types: jpg, jpeg, png
              </span>
              <span className="font12x400">You can upload max 5 photos</span>
            </div>
          </div>
          {multiImage && (
            <div className="imageContainer">
              {multiImage.map((item, i) => {
                return (
                  <div className="imageWrapper" key={i}>
                    <img src={item} alt="delete" width={61} height={61} />
                    <div
                      className="deleteDiv"
                      onClick={() => {
                        deleteImage(i);
                      }}
                    >
                      <img src="/Assets/delete.svg" alt="delete" />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {formik.errors.images ? (
            <InputError error={formik.errors.images} />
          ) : null}
        </div>

        <button type="submit" className="btn OtpBtn shadow-none mt-3">
          Next
        </button>
      </form>
    </>
  );
};

export default Step1;
