import React, { useEffect, useState } from "react";
import Layout from "../../../../Components/Layout";
import Loader from "../../../../Components/Loader";
import Pagination from "../../../../Components/Pagination";
import useFetch from "../../../../hooks/useFetch";
import DropdownSmall from "../../../../Shared Components/DropdownSmall";
import SearchInput2 from "../../../../Shared Components/SearchInput2";
import {
  SALON_OFFER_DELETE,
  SALON_OFFER_SEARCH,
} from "../../../../utils/Constants";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ToastContainer } from "react-toastify";
import DeleteModal from "../../../../Components/DeleteRecord";
import SalonOfferForm from "../../../../Components/Salon Offer";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";
import MobileOfferFilters from "../../../../Components/Mobile/mobileOfferFilter";

const Offer = () => {
  const [commonData, setCommonData] = useState({
    data: {},
    modal: false,
  });

  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const onEdit = (item) => {
    setCommonData({
      data: item,
      modal: true,
    });
    document.body.style.overflow = "hidden";
  };

  const onAdd = () => {
    setCommonData({
      data: undefined,
      modal: true,
    });
    document.body.style.overflow = "hidden";
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [id, setId] = useState("");
  const onDelete = (id) => {
    setIsDeleteModalOpen(true);
    document.body.style.overflow = "hidden";
    setId(id);
  };
  const salonNew = JSON.parse(localStorage.getItem("salon_details"));
  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
    sort_by: "order",
    sort_order: "desc",
  });
  console.log(filters);

  const [param, setParam] = useState({ show: false });
  console.log("param", param);

  const addParam = () => {
    setFilter({
      ...filters,
      ...param,
    });
  };

  const resetParam = () => {
    setParam({ show: false });
    delete filters.status;
    delete filters.statusName;
    delete filters.q;
    getData();
  };

  const [salonOffers, totalResult, error, loader, getData] = useFetch({
    url: `${SALON_OFFER_SEARCH}${salonNew?._id}/offer/search`,
    resultField: "salonOffers",
    parameter: filters,
  });

  useEffect(() => {
    getData();
  }, [filters]);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Offers"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileOfferFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          onClick={() => {
            onAdd();
          }}
          filters={filters}
          setFilter={setFilter}
          resetParam={resetParam}
          addParam={addParam}
          param={param}
          setParam={setParam}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Offers"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Offers"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper clients-page">
        <div>
          <div className="container ms-0 ps-0 d-flex justify-content-between align-items-center mb-2">
            <h3 className="font22x500 pageHeading mb-0">Store Offers</h3>
            <div className="desktopButton">
              <button className="btn addBtn shadow-none" onClick={onAdd}>
                Create New offer
              </button>
            </div>
          </div>
        </div>

        <div className="filter-container container mt-4 ms-0 ps-0 mb-3">
          <div className="row">
            <div className="col-5 d-flex align-items-end">
              <SearchInput2
                type="text"
                placeholder="Search by title"
                stateData={param}
                setStateData={setParam}
                parameter1="title"
                parameter2={undefined}
                addParam={addParam}
                resetParam={resetParam}
              />
            </div>
            <div className="col-7 d-flex justify-content-end align-items-end">
              {/* status */}
              <DropdownSmall
                data={[
                  { value: "All", label: "All" },
                  { value: "Active", label: "Active" },
                  { value: "Inactive", label: "Inactive" },
                ]}
                label="Status"
                placeholder="Id Status"
                htmlFor="status"
                type="text"
                stateData={filters?.statusName}
                setStateData={(e) => {
                  if (e === "All") {
                    delete filters?.status;
                    setFilter({
                      ...filters,
                      statusName: e,
                    });
                  } else {
                    setFilter({
                      ...filters,
                      statusName: e === "Active" ? "Active" : "Inactive",
                      status: e === "Active" ? 1 : 2,
                    });
                  }
                }}
              />
              <button
                className="btn resetBtn shadow-none ms-3"
                onClick={resetParam}
              >
                Reset
              </button>
            </div>
          </div>
        </div>

        {salonOffers?.length !== 0 ? (
          <div className="container dataTable ms-0 ps-0">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Order</th>
                  <th scope="col">Description</th>
                  <th scope="col">Offer Details</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {salonOffers?.map((offer, i) => {
                  return (
                    <tr key={i}>
                      <td>{offer?.title}</td>
                      <td>{offer?.order}</td>
                      <td>{offer?.description}</td>
                      <td title={offer?.offerDetails}>
                        {offer?.offerDetails?.length > 80
                          ? `${offer?.offerDetails?.substring(0, 80)}...`
                          : `${offer?.offerDetails}`}
                      </td>
                      <td>
                        <p
                          className="status mb-0"
                          style={{
                            backgroundColor: `${
                              offer?.status === 1
                                ? "var(--status_color2)"
                                : "var(--status_color1)"
                            }`,
                          }}
                        >
                          {offer?.status === 1 ? "Active" : "Inactive"}
                        </p>
                      </td>
                      <td>
                        <button
                          className="btn font14x400 filterBtn shadow-none me-2"
                          onClick={() => {
                            onEdit(offer);
                          }}
                        >
                          Edit
                        </button>
                        <span
                          className="deleteBtn"
                          onClick={() => {
                            onDelete(offer?._id);
                          }}
                        >
                          <RiDeleteBin6Line />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Offers Found"
            )}
          </div>
        )}

        {salonOffers?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      {/* delete modal */}
      {isDeleteModalOpen && (
        <DeleteModal
          id={id}
          apiCall={getData}
          state={isDeleteModalOpen}
          setState={setIsDeleteModalOpen}
          url={SALON_OFFER_DELETE}
          text="Offer"
        />
      )}

      {/* coupon form add and update both */}
      {commonData.modal && (
        <SalonOfferForm
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
          id={salonNew?._id}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default Offer;
