import React, { useState } from "react";
import {
  ADMIN_ORDER_UPDATE,
  authHeader,
  productSteps,
} from "../../../utils/Constants";
import { toast } from "react-toastify";
import axios from "axios";
import useAuthContext from "../../../hooks/useAuthContext";
import { getAddedOnDateTime } from "../../../Functions/dateTime";
import DynamicDropdown from "../OrderProduct/DynamicDropdown";
import useLoading from "../../../hooks/useLoading";
import Loader from "../../Loader";
import RenderMap from "../../Map/index";
import productImage from "../../../uploads/product/product1.jpg";

const setValue = (status) => {
  switch (status) {
    case 1:
      return "confirmed";
    case 2:
      return "shipped";
    case 3:
      return "delivered";
    case 4:
      return "cancelled";
    default:
      return "pending";
  }
};

const array = [
  {
    count: 2,
    product: { name: "test" },
  },
];

const Summary = ({ orderDetails, apiCall }) => {
  const [orderStatus, setOrderStatus] = useState({
    status: setValue(orderDetails?.orderProcessingStatus),
    number: orderDetails?.orderProcessingStatus,
  });
  console.log("orderStatus", orderStatus);

  const { token } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();

  const changedStatus = async (e) => {
    e.preventDefault();
    if (
      //USE THIS WHEN YOU WANT STATUS IS CHANGED STEP BY STEP
      // orderStatus.number - 1 === orderDetails?.orderProcessingStatus ||
      // orderStatus.number === 4

      //USE THIS WHEN YOU WANT STATUS IS NOT CHANGED STEP BY STEP
      orderStatus.number > orderDetails?.orderProcessingStatus
    ) {
      if (token) {
        try {
          showLoader();
          const res = await axios.put(
            `${ADMIN_ORDER_UPDATE}${orderDetails?._id}`,
            {
              orderProcessingStatus: orderStatus.number,
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );
          if (res.status === 200) {
            apiCall();
            hideLoader();
            toast.success("Status Changed", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    } else {
      //USE THIS WHEN YOU WANT STATUS IS CHANGED STEP BY STEP

      // toast.warn("Changed Status Step By Step", {
      //   position: "bottom-center",
      //   autoClose: 1000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      // });

      //USE THIS WHEN YOU WANT STATUS IS NOT CHANGED STEP BY STEP
      toast.warn("You can't go backward", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  return (
    <>
      <div className="container ms-0 ps-0">
        <div className="row gap-lg-0 gap-4">
          {/* order status */}
          <div className="col-lg-6 col-12">
            <div className="cardWrapper">
              <div className="cardWrapperHeader">
                <h3 className="font22x500 mb-0">Update order status</h3>
              </div>
              <div className="cardContent d-flex">
                <div className="w-100">
                  <DynamicDropdown
                    data={productSteps.slice(1)}
                    htmlFor="Order Status"
                    type="text"
                    name="orderStatus"
                    placeholder="Select Order Status"
                    stateData={orderStatus.status}
                    setStateData={(e, x) => {
                      setOrderStatus({
                        status: e,
                        number: x,
                      });
                    }}
                  />
                </div>
                <button
                  type="submit"
                  className="btn OtpBtn shadow-none ms-3"
                  style={{ width: "30%" }}
                  onClick={changedStatus}
                  disabled={orderDetails?.orderProcessingStatus >= 3 && true}
                >
                  {loader ? (
                    <div className="d-flex justify-content-center">
                      <Loader height="25" width="25" color="#fff" />
                    </div>
                  ) : (
                    "Change"
                  )}
                </button>
              </div>
            </div>
          </div>

          {/* status summary */}
          <div className="col-lg-6 col-12">
            <div className="cardWrapper">
              <div className="cardWrapperHeader">
                <h3 className="font22x500 mb-0">Status Summary</h3>
              </div>
              <div className="cardContent">
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Confirmed
                  </span>
                  {orderDetails?.length !== 0 && (
                    <span className="font14x500">
                      {orderDetails?.orderProcessingTimestamps[1] &&
                      orderDetails?.orderProcessingTimestamps[1] !== null
                        ? getAddedOnDateTime(
                            orderDetails?.orderProcessingTimestamps[1]
                          )
                        : "--"}
                    </span>
                  )}
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Shipped
                  </span>
                  {orderDetails?.length !== 0 && (
                    <span className="font14x500">
                      {orderDetails?.orderProcessingTimestamps[2] &&
                      orderDetails?.orderProcessingTimestamps[2] !== null
                        ? getAddedOnDateTime(
                            orderDetails?.orderProcessingTimestamps[2]
                          )
                        : "--"}
                    </span>
                  )}
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Delivered
                  </span>
                  {orderDetails?.length !== 0 && (
                    <span className="font14x500">
                      {orderDetails?.orderProcessingTimestamps[3] &&
                      orderDetails?.orderProcessingTimestamps[3] !== null
                        ? getAddedOnDateTime(
                            orderDetails?.orderProcessingTimestamps[3]
                          )
                        : "--"}
                    </span>
                  )}
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Cancelled
                  </span>
                  {orderDetails?.length !== 0 && (
                    <span className="font14x500">
                      {orderDetails?.orderProcessingTimestamps[4] &&
                      orderDetails?.orderProcessingTimestamps[4] !== null
                        ? getAddedOnDateTime(
                            orderDetails?.orderProcessingTimestamps[4]
                          )
                        : "--"}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Store details */}
          <div className="col-lg-7 col-12 g-lg-4">
            <div className="cardWrapper">
              <div className="cardWrapperHeader">
                <h3 className="font22x500 mb-0">Store Details</h3>
              </div>
              <div className="cardContent">
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Store Name
                  </span>
                  <span className="font14x500 text-capitalize">
                    {orderDetails?.salon?.name}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Email Id
                  </span>
                  <span className="font14x500">
                    {orderDetails?.salon?.email ?? "--"}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Phone Number
                  </span>
                  <span className="font14x500">
                    {orderDetails?.salon?.contactNumber}
                  </span>
                </div>

                <div className="">
                  <hr />
                  <div className="d-flex justify-content-between">
                    <span
                      className="font14x500"
                      style={{ color: "var(--text4)" }}
                    >
                      Billing Address
                    </span>
                    <span className="font14x500 text-end">
                      <span>{orderDetails?.billingAddress?.line_1}, </span>
                      {orderDetails?.billingAddress?.line_2 && (
                        <span>{orderDetails?.billingAddress?.line_2}, </span>
                      )}
                      <span>{orderDetails?.billingAddress?.city}, </span> <br />
                      <span>{orderDetails?.billingAddress?.state}, </span>
                      <span>{orderDetails?.billingAddress?.country}, </span>
                      <span>{orderDetails?.billingAddress?.pincode}</span>
                    </span>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <span
                      className="font14x500"
                      style={{ color: "var(--text4)" }}
                    >
                      Shipping Address
                    </span>
                    <span className="font14x500 text-end">
                      <span>{orderDetails?.shippingAddress?.line_1}, </span>
                      {orderDetails?.shippingAddress?.line_2 && (
                        <span>{orderDetails?.shippingAddress?.line_2}, </span>
                      )}
                      <span>{orderDetails?.shippingAddress?.city}, </span>{" "}
                      <br />
                      <span>{orderDetails?.shippingAddress?.state}, </span>
                      <span>{orderDetails?.shippingAddress?.country}, </span>
                      <span>{orderDetails?.shippingAddress?.pincode}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Location */}
          <div className="col-lg-5 col-12 g-lg-4">
            <div className="cardWrapper">
              <div className="cardWrapperHeader">
                <h3 className="font22x500 mb-0">Location</h3>
              </div>
              <div className="cardContent p-0">
                <div
                  style={{
                    position: "relative",
                    height: "300px",
                  }}
                >
                  <RenderMap
                    google={window.google}
                    latitude={orderDetails?.shippingAddress?.lat}
                    longitude={orderDetails?.shippingAddress?.lng}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* payment summary */}
          <div
            className="col-lg-5 col-12 g-lg-4"
            style={{ height: "fit-content" }}
          >
            <div className="cardWrapper">
              <div className="cardWrapperHeader">
                <h3 className="font22x500 mb-0">Payment Summary</h3>
              </div>
              <div className="cardContent">
                {/* <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Payment Method
                  </span>
                  <span className="font14x500">COD</span>
                </div> */}
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Subtotal (inclusive tax):
                  </span>
                  <span className="font14x500">{`${String.fromCharCode(
                    orderDetails?.products &&
                      orderDetails.products[0].product.currencySymbol
                  )} ${orderDetails?.cartCalculations?.products?.total}`}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Discount
                  </span>
                  <span className="font14x500">{`${String.fromCharCode(
                    orderDetails?.products &&
                      orderDetails.products[0].product.currencySymbol
                  )} ${
                    orderDetails?.cartCalculations?.products?.discount
                  }`}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Coupon Discount
                  </span>
                  <span className="font14x500">{`${String.fromCharCode(
                    orderDetails?.products &&
                      orderDetails.products[0].product.currencySymbol
                  )} ${orderDetails?.cartCalculations?.products?.couponDiscount?.toFixed(
                    2
                  )}`}</span>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Total Payable Amount
                  </span>
                  <span className="font14x500">{`${String.fromCharCode(
                    orderDetails?.products &&
                      orderDetails.products[0].product.currencySymbol
                  )} ${orderDetails?.cartCalculations?.products?.grandTotal?.toFixed(
                    2
                  )}`}</span>
                </div>
                {/* <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Groome Commision
                  </span>
                  <span className="font14x500">{`${String.fromCharCode(
                    orderDetails?.products &&
                      orderDetails.products[0].product.currencySymbol
                  )} ${orderDetails?.cartCalculations?.products?.commision?.toFixed(
                    2
                  )}`}</span>
                </div> */}
              </div>
            </div>
          </div>

          {/* Product Summary */}
          <div className="col-lg-7 col-12 g-lg-4">
            <div className="cardWrapper">
              <div className="cardWrapperHeader">
                <h3 className="font22x500 mb-0">Product Summary</h3>
              </div>
              <div className="cardContent">
                {orderDetails?.products?.map((item, i) => {
                  return (
                    <div
                      className="servicesContainer row gx-0 pb-3 mb-3"
                      key={i}
                    >
                      <div className="col-lg-7 d-flex">
                        {item?.product?.image === null ? (
                          <img
                            src={productImage}
                            alt="service"
                            width={72}
                            height={72}
                            style={{ borderRadius: "4px" }}
                          />
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_Image_BaseURL}${item?.product?.image}`}
                            alt="service"
                            width={72}
                            height={72}
                            style={{ borderRadius: "4px" }}
                          />
                        )}
                        <div className="ps-2 pt-1">
                          <h3 className="mb-0 font14x500">
                            {item?.product?.name}
                          </h3>
                          <p
                            className="mb-0 font12x400"
                            style={{ color: "var(--text4)" }}
                          >
                            {item?.product?.quantity}
                          </p>
                          <p
                            className="mb-0 font12x400 lh-1"
                            style={{ color: "var(--text4)" }}
                          >
                            Quantity: {item?.count}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-5 d-flex justify-content-between align-items-center">
                        <span
                          className="font14x500"
                          style={{ color: "var(--text4)" }}
                        >
                          {`Product Price x ${item?.count}`}
                        </span>
                        <div>
                          <span className="font14x500 me-2">
                            <s>{`${String.fromCharCode(
                              orderDetails?.products[0].product?.currencySymbol
                            )} ${item?.product?.mrp * item?.count}`}</s>
                          </span>
                          <span>{`${String.fromCharCode(
                            orderDetails?.products[0].product?.currencySymbol
                          )} ${
                            item?.product?.discountPrice * item?.count
                          }`}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* Rating & Review */}
          {/* {orderDetails?.reviewAndRating !== null &&
            orderDetails?.reviewAndRating?.status !== 0 && (
              <div
                className="col-lg-5 col-12 g-lg-4"
                style={{ height: "fit-content" }}
              >
                <div className="cardWrapper">
                  <div className="cardWrapperHeader">
                    <h3 className="font22x500 mb-0">Rating & Review</h3>
                  </div>
                  <div className="cardContent">
                    <div className="d-flex align-items-center">
                      <p className="font22x700 mb-0 me-3">{`${orderDetails?.reviewAndRating?.rating} stars`}</p>
                      <StarRatings
                        rating={orderDetails?.reviewAndRating?.rating}
                        starEmptyColor="#a6a6a6"
                        starRatedColor="#FCC153"
                        starDimension="22px"
                        numberOfStars={5}
                        starSpacing="2"
                      />
                    </div>
                    <p className="font14x400 mt-2 lh-sm mb-0">
                      {orderDetails?.reviewAndRating?.review}
                    </p>
                  </div>
                </div>
              </div>
            )} */}
        </div>
      </div>
    </>
  );
};

export default Summary;
