import React, { useEffect, useState } from "react";
import Layout from "../../../../Components/Layout";
import useFetch from "../../../../hooks/useFetch";
import {
  MEMBERSHIP_LIST,
  MEMBERSHIP_ORDER_LIST,
} from "../../../../utils/Constants";
import Pagination from "../../../../Components/Pagination";
import InputDate from "../../../../Shared Components/InputDate";
import DropdownSmall from "../../../../Shared Components/DropdownSmall";
import SearchInput2 from "../../../../Shared Components/SearchInput2";
import moment from "moment";
import Loader from "../../../../Components/Loader";
import PageNav from "../../../../Components/PageNav";
import { ordersPageLinks } from "../Product";
import { toast, ToastContainer } from "react-toastify";
import DropdownSmallDynamic from "../../../../Shared Components/DropdownSmallDynamic";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";
import MobileMembershipOrderFilters from "../../../../Components/Mobile/Admin/mobileMembershipOrderFilter";

const gettingStatusName = (status) => {
  switch (status) {
    case 0:
      return "Pending";
    case 1:
      return "Active";
    case 2:
      return "Expired";
    default:
      return -1;
  }
};
const settingStatusColor = (status) => {
  switch (status) {
    case 0:
      return "#FFCE00";
    case 1:
      return "#11cc93";
    case 2:
      return "#f7635b";
    default:
      return -1;
  }
};

const MembershipOrder = () => {
  const [param, setParam] = useState({});
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const functions = {
    addParam: () => {
      if (param.fromDate && param.tillDate) {
        const checkDate = moment(param.tillDate, "YYYY-MM-DD").isAfter(
          moment(param.fromDate, "YYYY-MM-DD")
        );
        if (checkDate) {
          setFilter({
            ...filters,
            ...param,
          });
        } else {
          toast.error("From date must be less than till date.", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    },
    resetParam: () => {
      setParam({});
      delete filters.q;
      delete filters.fromDate;
      delete filters.tillDate;
      delete filters.statusName;
      delete filters.status;
      delete filters["membership.name"];
      getData();
    },
  };

  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
  });
  console.log(filters);

  const [membershipOrders, totalResult, error, loader, getData] = useFetch({
    url: `${MEMBERSHIP_ORDER_LIST}`,
    resultField: "membershipOrders",
    parameter: filters,
  });
  const [memberships, totalResult2, error2, loader2, getMembership] = useFetch({
    url: `${MEMBERSHIP_LIST}`,
    resultField: "memberships",
    parameter: {
      f: "name",
    },
  });

  useEffect(() => {
    window.localStorage.removeItem("salon_details");
    getData();
  }, [filters]);

  useEffect(() => {
    getMembership();
  }, []);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Membership Orders"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileMembershipOrderFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          memberships={memberships}
          param={param}
          setParam={setParam}
          addParam={functions.addParam}
          resetParam={functions.resetParam}
          filters={filters}
          setFilter={setFilter}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Membership Orders"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Membership Orders"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper">
        <div className="service-navigation-neworder-button-container">
          <div className="service-navigation-neworder-button">
            <PageNav links={ordersPageLinks} />
          </div>
        </div>

        <div className="filter-container container mt-4 ms-0 ps-0 mb-3">
          <div className="d-flex justify-content-between align-items-end flex-wrap">
            {/* search */}
            <SearchInput2
              type="text"
              placeholder="Search by Order Id/ user name"
              stateData={param}
              setStateData={setParam}
              parameter1="membershipOrderId"
              parameter2="user.name"
              width={190}
              addParam={functions.addParam}
              resetParam={functions.resetParam}
            />

            {/* packageName */}
            <DropdownSmallDynamic
              data={memberships}
              loader={loader2}
              label=" Package Name"
              htmlFor="packageName"
              type="text"
              placeholder="Select package name"
              stateData={filters["membership.name"]}
              setStateData={(e, x) => {
                setFilter({
                  ...filters,
                  "membership.name": x,
                });
              }}
            />

            {/* Membership Status */}
            <DropdownSmall
              data={[
                { value: "Active", label: "Active" },
                { value: "Expired", label: "Expired" },
              ]}
              label="Membership Status"
              htmlFor="status"
              type="text"
              stateData={filters.statusName}
              setStateData={(e) => {
                setFilter({
                  ...filters,
                  statusName: e,
                  status: e === "Active" ? "1" : "2",
                });
              }}
            />

            {/* date from */}
            <InputDate
              label="From"
              htmlFor="from"
              type="date"
              stateData={param?.fromDate}
              setStateData={(e) => {
                setParam({
                  ...param,
                  fromDate: moment(e).format("YYYY-MM-DD"),
                });
              }}
            />

            {/* date to */}
            <InputDate
              label="To"
              htmlFor="to"
              type="date"
              stateData={param?.tillDate}
              setStateData={(e) => {
                setParam({
                  ...param,
                  tillDate: moment(e).format("YYYY-MM-DD"),
                });
              }}
            />

            <button
              className="btn filterBtn shadow-none"
              onClick={functions.addParam}
            >
              Apply
            </button>
            <button
              className="btn resetBtn shadow-none"
              onClick={functions.resetParam}
            >
              Reset
            </button>
          </div>
        </div>

        {membershipOrders?.length !== 0 ? (
          <div className="container dataTable ms-0 ps-0">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Order Id</th>
                  {/* <th scope="col">Transaction Id</th> */}
                  <th scope="col">Package Name</th>
                  <th scope="col">Customer Details</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Payment Mode</th>
                  <th scope="col">Payment Status</th>
                  <th scope="col">Date</th>
                  <th scope="col">Membership Status</th>
                </tr>
              </thead>
              <tbody>
                {membershipOrders?.map((order, i) => {
                  return (
                    <tr key={i}>
                      <td>{order?.membershipOrderId}</td>
                      {/* <td>
                        {
                          order?.membershipTransactions[0]
                            ?.membershipTransactionId
                        }
                      </td> */}
                      <td>{order?.membership?.name}</td>
                      <td>
                        {order?.user?.name} <br />
                        {order?.user?.mobile}
                      </td>
                      <td>{order?.membership?.price}</td>
                      <td>{order?.membershipTransactions[0]?.paymentMode}</td>
                      <td>{order?.paymentStatus}</td>
                      <td>
                        <p className="mb-0" style={{ color: "#11cc93" }}>
                          {moment(order?.createdAt).format("DD MMM YYYY")}
                        </p>
                        <p className="mb-0" style={{ color: "#f7635b" }}>
                          {moment(order?.createdAt)
                            .add(order?.membership?.validity, "days")
                            .format("DD MMM YYYY")}
                        </p>
                      </td>
                      <td style={{ color: settingStatusColor(order?.status) }}>
                        {gettingStatusName(order?.status)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Membership Orders Found"
            )}
          </div>
        )}

        {membershipOrders?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      <ToastContainer />
    </>
  );
};

export default MembershipOrder;
