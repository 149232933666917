import React, { useContext, useEffect, useState } from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { HiOutlineChevronRight } from "react-icons/hi";
import useAuthContext from "../../hooks/useAuthContext";
import useFetch from "../../hooks/useFetch";
import InputFieldLarge from "../../Shared Components/InputFieldLarge";
import PaymentMethods from "../../Shared Components/PaymentMethods";
import {
  ADMIN_TRANSACTION_UPDATE,
  authHeader,
  CART_EMPTY,
  CREATE_ADMIN_ORDER,
  CREATE_ADMIN_TRANSACTION,
  LOGGED_IN_USER_ADDRESSES,
} from "../../utils/Constants";
import { CartContext } from "../../utils/Private Routes/MasterSalonPrivateRoutes";
import PaymentDetails from "../Cart/PaymentDetails";
import { useFormik } from "formik";
import { checkoutSchema } from "../../utils/validationschema";
import InputError from "../../Shared Components/InputError";
import axios from "axios";
import { toast } from "react-toastify";
import useLoading from "../../hooks/useLoading";
import { Link, useNavigate } from "react-router-dom";
import useRazorpay from "react-razorpay";

const Checkout = ({ state, setState }) => {
  const Razorpay = useRazorpay();

  const { cart, getCart } = useContext(CartContext);
  const { token, salonDetails } = useAuthContext();
  const navigate = useNavigate();

  const [shippingAddress, setShippingAddress] = useState({});

  const initialValues = {
    paymentMode: "",
    gstNumber: salonDetails?.paymentDetail?.gstId,
    legalEntitiesName: salonDetails?.paymentDetail?.legalEntitiesName,
    billingAddress: salonDetails?.address,
    shippingAddress: {},
  };
  const formik = useFormik({
    initialValues,
    validationSchema: checkoutSchema,
    onSubmit: (data) => {
      functions.createOrder(data);
      // console.log("data", data);
    },
  });

  const [loader2, showLoader, hideLoader] = useLoading();

  const [userAddresses, totalResult, error, loader, getData] = useFetch({
    url: `${LOGGED_IN_USER_ADDRESSES}`,
    resultField: "userAddresses",
    parameter: { status: 1 },
  });
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (state?.shippingAddress) {
      formik.values.shippingAddress = state?.shippingAddress;
      setShippingAddress(state?.shippingAddress);
    } else {
      userAddresses?.map((address) => {
        if (address?.isDefault) {
          formik.values.shippingAddress = address;
          setShippingAddress(address);
        }
      });
    }
  }, [state, userAddresses]);

  const functions = {
    createOrder: async (data) => {
      if (token) {
        try {
          showLoader();
          const res = await axios.post(
            `${CREATE_ADMIN_ORDER}`,
            {
              orderBy: "salon",
              salon: salonDetails?._id,
              billingAddress: data?.billingAddress,
              shippingAddress: {
                line_1: data?.shippingAddress?.line_1,
                line_2: data?.shippingAddress?.line_2,
                city: data?.shippingAddress?.city,
                state: data?.shippingAddress?.state,
                country: data?.shippingAddress?.country,
                pincode: data?.shippingAddress?.pincode,
                lat: data?.shippingAddress?.lat,
                lng: data?.shippingAddress?.lng,
              },
              gstNumber: data?.gstNumber,
              legalEntitiesName: data?.legalEntitiesName,
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );
          console.log("order res", res);

          if (res.status === 200) {
            // setPositionToBody();
            // apiCall();
            hideLoader();
            functions.createTransaction(
              res?.data?.adminOrder?._id,
              data?.paymentMode
            );
            toast.success("Order Created", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res?.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
    createTransaction: async (id, payMode) => {
      if (token && id) {
        try {
          showLoader();
          const res = await axios.post(
            `${CREATE_ADMIN_TRANSACTION}`,
            {
              adminOrder: id,
              paymentMode: payMode,
              payment: payMode === "COD" ? "COD" : "Online",
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );
          console.log("transaction res", res);

          if (res.status === 200) {
            // setPositionToBody();
            // apiCall();
            if (
              process.env.REACT_APP_PAYMENT_GATEWAY_REQUIRED === "true" &&
              formik.values.paymentMode !== "COD"
            ) {
              functions.callingPaymentGateway({
                razorpayOrderId:
                  res?.data?.adminTransaction?.razorPayDetails?.orderId,
                adminTId: res?.data?.adminTransaction?._id,
                amount: res?.data?.adminTransaction?.amount,
                user: res?.data?.adminTransaction?.user,
              });
            } else {
              functions.updateTransaction({
                adminTId: res?.data?.adminTransaction?._id,
              });
            }
            hideLoader();
            toast.success("Transaction Created", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res?.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
    callingPaymentGateway: (params) => {
      //razorpay payment gateway
      const options = {
        key: "rzp_test_sBSpw2AJZSiWZz", // Enter the Key ID generated from the Dashboard
        amount: params?.amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Groome Test",
        description: "Test Transaction",
        image: "/Assets/logo2.svg",
        order_id: params.razorpayOrderId, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
        handler: function (response) {
          functions.updateTransaction({
            razOrderid: response.razorpay_order_id,
            razPaymentId: response.razorpay_payment_id,
            razSignature: response.razorpay_signature,
            adminTId: params?.adminTId,
            reason: "success",
          });
          console.log("razorpay success", response);
        },
        prefill: {
          name: params?.user?.name ?? "Test Name",
          email: params?.user?.email ?? "test@gmail.com",
          contact: params?.user?.mobile ?? "7838624872",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#772286",
        },
      };
      const rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        console.log("razorpay failed", response);
        if (response.error.code === "SERVER_ERROR") {
          functions.updateTransaction({
            adminTId: params.adminTId,
            reason: "server_error",
          });
        } else {
          functions.updateTransaction({
            razOrderid: response.error.metadata.order_id,
            razPaymentId: response.error.metadata.payment_id,
            adminTId: params.adminTId,
            reason: response.error.reason,
          });
        }
      });
      rzp1.open();
    },
    updateTransaction: async (params) => {
      console.log("params", params);
      let updateTransactionBody = {};

      if (
        process.env.REACT_APP_PAYMENT_GATEWAY_REQUIRED === "true" &&
        formik.values.paymentMode !== "COD"
      ) {
        if (params.reason === "success") {
          updateTransactionBody = {
            paymentStatus: "Success",
            razorpay_order_id: params.razOrderid,
            razorpay_payment_id: params.razPaymentId,
            razorpay_signature: params.razSignature,
          };
        } else if (params.reason === "payment_cancelled") {
          updateTransactionBody = {
            paymentStatus: "Cancelled",
            razorpay_order_id: params.razOrderid,
            razorpay_payment_id: params.razPaymentId,
          };
        } else if (params.reason === "payment_failed") {
          updateTransactionBody = {
            paymentStatus: "Failed",
            razorpay_order_id: params.razOrderid,
            razorpay_payment_id: params.razPaymentId,
          };
        } else {
          updateTransactionBody = {
            paymentStatus: "Failed",
          };
        }
      } else {
        updateTransactionBody = {
          paymentStatus: "Success",
        };
      }

      if (token) {
        try {
          showLoader();
          const res = await axios.put(
            `${ADMIN_TRANSACTION_UPDATE}${params.adminTId}`,
            updateTransactionBody,
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );
          console.log("transaction update res", res);

          if (res.status === 200) {
            // setPositionToBody();
            // apiCall();
            hideLoader();
            if (res?.data?.adminTransaction?.paymentStatus === "Success") {
              functions.cartEmpty();
            }
            toast.success("Transaction Status Updated", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res?.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
    cartEmpty: async () => {
      if (token) {
        try {
          showLoader();
          const res = await axios.put(
            `${CART_EMPTY}`,
            {},
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            getCart();
            functions.setPositionToBody();
            hideLoader();
            toast.success("Cart Empty", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
            functions.redirectToOrders();
          } else {
            console.log("else error: ", res?.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
    redirectToOrders: () => {
      navigate("/salon/your-orders");
    },
    setPositionToBody: () => {
      setState({
        modal: false,
        step: 0,
      });
      document.body.style.overflow = "unset";
    },
  };

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv"></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">Checkout</h1>
            <span
              onClick={() => {
                setState({
                  ...state,
                  step: 1,
                });
              }}
            >
              <HiOutlineArrowLeft />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit}>
              {/* payment methods */}
              <div className="mb-4">
                <div className="mb-1">
                  <div style={{ borderBottom: "1px solid #EBEBEB" }}>
                    <label
                      htmlFor="atsalon"
                      className="font16x700 form-label mb-1 labelBig"
                    >
                      Payment Methods*
                    </label>
                  </div>

                  <PaymentMethods
                    label="COD"
                    imgPath="/Assets/cash.svg"
                    htmlFor="cod"
                    name="paymentMode"
                    value="COD"
                    stateData={formik.values.paymentMode}
                    setStateData={(e) => {
                      formik.setFieldValue("paymentMode", e);
                    }}
                  />

                  <PaymentMethods
                    label="PayU"
                    imgPath="/Assets/payU.svg"
                    htmlFor="payU"
                    name="paymentMode"
                    value="PayU"
                    stateData={formik.values.paymentMode}
                    setStateData={(e) => {
                      formik.setFieldValue("paymentMode", e);
                    }}
                  />
                </div>
                {formik.errors.paymentMode ? (
                  <InputError error={formik.errors.paymentMode} />
                ) : null}
              </div>

              {/* GST Details */}
              <div className="mb-4">
                <label
                  htmlFor="atsalon"
                  className="font16x700 form-label mb-2 labelBig"
                >
                  GST Details*
                </label>

                {/* GST Number* */}
                <div className="mb-3">
                  <InputFieldLarge
                    label="GST Number*"
                    htmlFor="gstNumber"
                    type="text"
                    name="gstNumber"
                    stateData={formik.values.gstNumber}
                    setStateData={formik.handleChange}
                    readOnly={true}
                  />
                  {formik.errors.gstNumber ? (
                    <InputError error={formik.errors.gstNumber} />
                  ) : null}
                </div>

                <div className="mb-3">
                  <InputFieldLarge
                    label="Legal Entity Name*"
                    htmlFor="entityname"
                    type="text"
                    name="legalEntitiesName"
                    stateData={formik.values.legalEntitiesName}
                    setStateData={formik.handleChange}
                    readOnly={true}
                  />
                  {formik.errors.legalEntitiesName ? (
                    <InputError error={formik.errors.legalEntitiesName} />
                  ) : null}
                </div>

                {!salonDetails?.paymentDetail && (
                  <div>
                    <p className="font14x500">
                      You don't have payment details{" "}
                      <Link
                        to="/salon/payment"
                        style={{ color: "var(--btn_primary)" }}
                      >
                        click to add
                      </Link>
                    </p>
                  </div>
                )}
              </div>

              {/* Addresses */}
              <div className="mb-4">
                <label
                  htmlFor="atsalon"
                  className="font16x700 form-label mb-2 labelBig"
                >
                  Addresses
                </label>

                {/* Billing address */}
                <div className="addressWrapper mb-4">
                  <div className="addressWrapperHeader">
                    <h3 className="font14x500 mb-0">Billing Address</h3>
                  </div>
                  <div className="addressContent font14x400">
                    <p className="mb-0">{salonDetails?.address?.line_1}</p>
                    {salonDetails?.address?.line_2 !== "" && (
                      <p className="mb-0">{salonDetails?.address?.line_2}</p>
                    )}
                    <p className="mb-0">{salonDetails?.address?.city}</p>
                    <p className="mb-0">{salonDetails?.address?.state}</p>
                    <p className="mb-0">{salonDetails?.address?.country}</p>
                    <p className="mb-0">{salonDetails?.address?.pincode}</p>
                  </div>
                </div>

                <div className="addressWrapper">
                  <div className="addressWrapperHeader d-flex justify-content-between align-items-center">
                    <h3 className="font14x500 mb-0">Shipping Address</h3>
                    <p
                      className="mb-0 font12x500"
                      style={{
                        cursor: "pointer",
                        color: "var(--text1)",
                      }}
                      onClick={() => {
                        setState({
                          ...state,
                          step: 4,
                          selectedAddressId: formik.values.shippingAddress?._id,
                        });
                      }}
                    >
                      {userAddresses?.length > 0
                        ? "Change Address"
                        : "Create Address"}
                    </p>
                  </div>
                  <div className="addressContent font14x400">
                    <p className="mb-0">{shippingAddress?.line_1}</p>
                    {shippingAddress?.line_2 !== "" && (
                      <p className="mb-0">{shippingAddress?.line_2}</p>
                    )}
                    <p className="mb-0">{shippingAddress?.city}</p>
                    <p className="mb-0">{shippingAddress?.state}</p>
                    <p className="mb-0">{shippingAddress?.country}</p>
                    <p className="mb-0">{shippingAddress?.pincode}</p>
                  </div>
                </div>
                {formik.errors.shippingAddress ? (
                  <InputError error={formik.errors.shippingAddress} />
                ) : null}
              </div>

              {/* payment details */}
              <PaymentDetails cart={cart} />

              {/* proceed btn*/}
              <div className="proceedWrapper d-flex justify-content-between align-items-center py-2 px-3">
                <div className="">
                  <p className="mb-0 font12x400">Grand Total</p>
                  <p className="mb-0 font16x500">{`${String.fromCharCode(
                    cart?.products[0]?.product?.currencySymbol
                  )}${cart?.cartCalculations?.products?.grandTotal}`}</p>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setState({
                      ...state,
                      step: 3,
                    });
                  }}
                >
                  <button type="submit" className="font16x500">
                    Proceed
                    <HiOutlineChevronRight style={{ marginBottom: 3 }} />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
