import moment from "moment";

export const capitalizeString = (value) => {
  const arr = value.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

export const checkFileSize = (files) => {
  let valid = true;
  if (files) {
    files?.map((file) => {
      const size = file?.size / 1024;
      if (size > 1024) {
        valid = false;
      }
    });
  }
  return valid;
};

export const checkFileFormat = (files) => {
  let valid = true;
  if (files) {
    files?.map((file) => {
      if (!["image/jpeg", "image/png"].includes(file?.type)) {
        valid = false;
      }
    });
  }
  return valid;
};
export const checkFileFormatExcel = (files) => {
  let valid = true;
  if (files) {
    files?.map((file) => {
      if (
        ![
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ].includes(file?.type)
      ) {
        valid = false;
      }
    });
  }
  return valid;
};

export const checkFileFormatPDF = (files) => {
  let valid = true;
  if (files) {
    files?.map((file) => {
      if (!["application/pdf"].includes(file?.type)) {
        valid = false;
      }
    });
  }
  return valid;
};

export const changedTimingsFormat = (timings) => {
  let formatTime = {};
  let array = [];

  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  for (let i = 0; i <= timings.length - 1; i++) {
    formatTime = Object.assign({
      ...formatTime,
      [days[i]]: {
        openingTime:
          timings[i].split("-")[0] !== "0:00"
            ? moment(timings[i].split("-")[0], "hh:mm A").format("h:mm A")
            : "0:00",
        closingTime:
          timings[i].split("-")[0] !== "0:00"
            ? moment(timings[i].split("-")[1], "hh:mm A").format("h:mm A")
            : "0:00",
        isChecked: timings[i] === "0:00-0:00" ? false : true,
      },
    });
  }

  array.push(formatTime);

  return array;
};

export const isNumberContainDecimal = (number) => {
  let boolean = false;

  let n = number - Math.floor(number) !== 0;

  if (n) {
    boolean = true;
  } else {
    boolean = false;
  }

  return boolean;
};

export const getPrettyFormat = (totalMinutes) => {
  let hours = Math.floor(totalMinutes / 60);
  let finalString = "";
  let minutes = totalMinutes % 60;
  finalString = hours + " Hr " + minutes + " Mins";

  if (hours === 0) {
    finalString = hours + " Hr " + totalMinutes + " Mins";
  }
  return finalString;
};

export const handleTime = (expireTime) => {
  let s = expireTime % 60 > 9 ? expireTime % 60 : "0" + (expireTime % 60);
  let m =
    Math.floor(expireTime / 60) > 9
      ? Math.floor(expireTime / 60)
      : "0" + Math.floor(expireTime / 60);
  return `${m}:${s}`;
};

export const getColorAccToStylist = (stylist) => {
  const colorArray = [
    "#196774",
    "#90A19D",
    "#F0941F",
    "#EF6024",
    "#FF5F5D",
    "#3F7C85",
    "#00CCBF",
    "#72F2EB",
    "#747E7E",
    "#FFCB9A",
    "#D2E8E3",
    "#D8B08C",
  ];
  const map1 = new Map();

  for (let i = 0; i < stylist.length; i++) {
    map1.set(stylist[i]?._id.toString(), colorArray[i]);
  }

  return map1;
};
