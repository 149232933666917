import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import {
  attandanceBtns,
  authHeader,
  STYLIST_ATTENDANCE_UPDATE,
} from "../../../utils/Constants";

const setColor = (value) => {
  switch (value) {
    case "A":
      return "#F7635B";
    case "P":
      return "#11CC93";
    case "HD":
      return "#FFCE00";
    case "WO":
      return "#A6A6A6";
    default:
      return -1;
  }
};

const AttandanceButtons = ({ data, token, apiCall }) => {
  const functions = {
    markAttendance: async (attendance, value) => {
      if (token && attendance && data?._id) {
        try {
          const res = await axios.put(
            `${STYLIST_ATTENDANCE_UPDATE}${data?._id}/attendance`,
            {
              value,
              attendance,
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            apiCall();
            toast.success("Attendance Marked", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    },
  };

  return (
    <>
      <div className="checkBoxContainer2">
        {attandanceBtns?.map((item, i) => {
          return (
            <button
              key={i}
              type="button"
              className="attandanceCheck"
              style={{
                backgroundColor:
                  data?.stylistAttendance?.attendance === item?.attendance &&
                  setColor(item?.attendance),
                color:
                  data?.stylistAttendance?.attendance === item?.attendance &&
                  "#fff",
                border:
                  data?.stylistAttendance?.attendance === item?.attendance &&
                  `1px solid ${setColor(item?.attendance)}`,
              }}
              value={item?.attendance}
              onClick={() =>
                functions.markAttendance(item?.attendance, item?.value)
              }
              disabled={data?.status === 2 && true}
            >
              {item?.attendance}
            </button>
          );
        })}
      </div>
    </>
  );
};

export default AttandanceButtons;
