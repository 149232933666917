import React from "react";
import Cart from "../Cart";
import Checkout from "../Checkout";
import Coupon from "../Coupon";
import EditAddressSalon from "../EditAddressSalon";
import ShippingAddress from "../ShippingAddress";

const CartPaymentProcess = ({
  state,
  setState,
  addItemIntoCart,
  removeItemFromCart,
  removeItem,
}) => {
  switch (state.step) {
    case 1:
      return (
        <Cart
          state={state}
          setState={setState}
          addItemIntoCart={addItemIntoCart}
          removeItemFromCart={removeItemFromCart}
          removeItem={removeItem}
        />
      );
    case 2:
      return <Coupon state={state} setState={setState} />;
    case 3:
      return <Checkout state={state} setState={setState} />;
    case 4:
      return <ShippingAddress state={state} setState={setState} />;
    case 5:
      return <EditAddressSalon state={state} setState={setState} />;
    case 6:
      return "order";
    default: //nothing to render
  }
};

export default CartPaymentProcess;
