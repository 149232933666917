import axios from "axios";
import React, { useRef, useState } from "react";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import useAuthContext from "../../../hooks/useAuthContext";
import useCategory from "../../../hooks/useCategory";
import DynamicDropdown from "../../../Shared Components/DynamicDropdown";
import InputFieldLarge from "../../../Shared Components/InputFieldLarge";
import StaticDropdown from "../../../Shared Components/StaticDropdown";
import UpdationDetails from "../../../Shared Components/UpdationDetails";
import {
  authFormHeader,
  BANNERS_TYPE,
  BANNER_ADD,
  BANNER_UPDATE,
} from "../../../utils/Constants";
import { useFormik } from "formik";
import { bannerSchema } from "../../../utils/validationschema";
import InputError from "../../../Shared Components/InputError";
import SubmitButton from "../../../Shared Components/SubmitButton";
import useLoading from "../../../hooks/useLoading";
import AddCity from "./City";

const BannerForm = ({ state, setState, apiCall, data }) => {
  const initialValues = {
    title: state?.data?.title ?? "",
    description: state?.data?.description ?? "",
    type: state?.data?.type?.name ?? "",
    order: state?.data?.order ?? 1,
    link: state?.data?.link ?? "",
    bannerStatus: state?.data?.bannerStatus ?? "",
    placement: state?.data?.placement ?? "",
    city: state?.data?.city ?? "Other",
    lat: state?.data?.lat ?? "0.0",
    lng: state?.data?.lng ?? "0.0",
    locationName: state?.data?.locationName ?? "",
    isCitySelected: state?.data?.city ? true : false,
    image: "",
  };
  const [oldImagePath, setoldImagePath] = useState("null");

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    validationSchema: bannerSchema,
    onSubmit: (data) => {
      formSubmit(data);
    },
  });

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const bannerImage = useRef(null);
  const handleSingleImg = (e) => {
    e.preventDefault();
    bannerImage.current.click();
  };

  const [singleImage, setSingleImage] = useState(
    state?.data?.image
      ? `${process.env.REACT_APP_Image_BaseURL}${state?.data?.image}`
      : ""
  );
  const imageDataURL = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSingleImage(URL.createObjectURL(e.target.files[0]));
      formik.setFieldValue("image", Array.from(e.target.files));
    }
  };
  const deleteImage = (index) => {
    if (index === -1) {
      formik.setFieldValue("image", "");
      setSingleImage("");
      setoldImagePath(state?.data?.image ? state?.data?.image : null);
      bannerImage.current.value = "";
    }
  };

  const { token } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();

  // const [typeId, setTypeId] = useState("");
  // const [typeId, setTypeId] = useState(state?.data?.type?._id ?? "");

  const formSubmit = async (data) => {
    console.log("form submit called");

    //form data
    const sFormData = new FormData();
    sFormData.append("title", data.title);
    sFormData.append("image", data.image[0]);
    // sFormData.append("type", typeId);
    sFormData.append("description", data.description);
    sFormData.append("order", data.order);
    sFormData.append("city", data.city);
    sFormData.append("locationName", data.locationName);
    sFormData.append(
      "lat",
      data.city === "Other" || data.city === "other" ? "0.0" : data.lat
    );
    sFormData.append(
      "lng",
      data.city === "Other" || data.city === "other" ? "0.0" : data.lng
    );
    sFormData.append("link", data.link);
    sFormData.append("bannerStatus", data.bannerStatus);
    sFormData.append("placement", data.placement);
    sFormData.append("status", data.bannerStatus === "Draft" ? 0 : 1);
    if (state.data) {
      sFormData.append("updateImage", oldImagePath);
    }

    if (token) {
      if (state.data === undefined) {
        try {
          showLoader();
          const res = await axios.post(`${BANNER_ADD}`, sFormData, {
            //headers: authFormHeader,
            headers: {
              "Content-type": "multipart/form-data",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Banner Added", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      } else {
        try {
          showLoader();
          console.log("update api called");
          const res = await axios.put(
            `${BANNER_UPDATE}${state.data._id}/form`,
            sFormData,
            {
              //headers: authFormHeader,
              headers: {
                "Content-type": "multipart/form-data",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Banner Updated", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">
              {!state.data ? "Add New Banner" : "Edit Banner"}
            </h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
              {/* Title* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Title*"
                  htmlFor="title"
                  name="title"
                  type="text"
                  stateData={formik.values.title}
                  setStateData={formik.handleChange}
                />
                {formik.errors.title ? (
                  <InputError error={formik.errors.title} />
                ) : null}
              </div>

              {/* Description* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Description (optional)"
                  htmlFor="Description"
                  type="text"
                  name="description"
                  stateData={formik.values.description}
                  setStateData={formik.handleChange}
                />
                {formik.errors.description ? (
                  <InputError error={formik.errors.description} />
                ) : null}
              </div>

              {/* Type */}
              {/* <div className="mb-3">
                <DynamicDropdown
                  data={data}
                  setCategoryId={setTypeId}
                  label="Type"
                  htmlFor="type"
                  name="type"
                  placeholder="Select Type"
                  stateData={formik.values.type}
                  setStateData={(e) => {
                    formik.setFieldValue("type", e);
                  }}
                />
                {formik.errors.type ? (
                  <InputError error={formik.errors.type} />
                ) : null}
              </div> */}

              {/* order */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Order*"
                  htmlFor="order"
                  type="tel"
                  name="order"
                  stateData={formik.values.order}
                  setStateData={formik.handleChange}
                  width="120px"
                />
                {formik.errors.order ? (
                  <InputError error={formik.errors.order} />
                ) : null}
              </div>

              {/* city */}
              <div className="mb-3">
                <AddCity
                  stateData={formik.values.city}
                  setStateData={(e) => {
                    formik.setFieldValue("city", e);
                  }}
                  google={window.google}
                  formik={formik}
                />
                {formik.errors.city ? (
                  <InputError error={formik.errors.city} />
                ) : null}
              </div>

              {/* lat */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Latitude*"
                  htmlFor="lat"
                  type="text"
                  name="lat"
                  stateData={formik.values.lat}
                  setStateData={formik.handleChange}
                />
                {formik.errors.lat ? (
                  <InputError error={formik.errors.lat} />
                ) : null}
              </div>

              {/* lng */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Longitude*"
                  htmlFor="lng"
                  type="text"
                  name="lng"
                  stateData={formik.values.lng}
                  setStateData={formik.handleChange}
                />
                {formik.errors.lng ? (
                  <InputError error={formik.errors.lng} />
                ) : null}
              </div>

              {/* Link */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Link*"
                  htmlFor="link"
                  type="text"
                  stateData={formik.values.link}
                  setStateData={formik.handleChange}
                />
                {formik.errors.link ? (
                  <InputError error={formik.errors.link} />
                ) : null}
              </div>

              {/* status */}
              <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: "Published", label: "Published" },
                    { value: "Draft", label: "Draft" },
                  ]}
                  label="Status*"
                  htmlFor="status"
                  name="bannerStatus"
                  placeholder="Select Status"
                  stateData={formik.values.bannerStatus}
                  setStateData={(e) => {
                    formik.setFieldValue("bannerStatus", e);
                  }}
                />
                {formik.errors.bannerStatus ? (
                  <InputError error={formik.errors.bannerStatus} />
                ) : null}
              </div>

              {/* Placement */}
              <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: "Home Top", label: "Home Top" },
                    { value: "Page Middle", label: "Page Middle" },
                    { value: "Page Bottom", label: "Page Bottom" },
                  ]}
                  label="Placement*"
                  htmlFor="Placement"
                  name="placement"
                  placeholder="Select Placement"
                  stateData={formik.values.placement}
                  setStateData={(e) => {
                    formik.setFieldValue("placement", e);
                  }}
                />
                {formik.errors.placement ? (
                  <InputError error={formik.errors.placement} />
                ) : null}
              </div>

              {/* banner image */}
              <div className="mb-3">
                <div className="mb-0">
                  <label
                    htmlFor="formFile"
                    className="font12x500 mb-1 form-label"
                  >
                    Image
                  </label>
                  <input
                    ref={bannerImage}
                    className="form-control shadow-none"
                    type="file"
                    id="formFile"
                    name="image"
                    accept={".png, .jpg, .jpeg"}
                    hidden
                    onChange={(e) => {
                      imageDataURL(e);
                    }}
                  />
                </div>
                <div className="uploadImg d-flex align-items-center mb-3">
                  <div
                    className="uploadBtn font12x400 me-3"
                    onClick={(e) => {
                      handleSingleImg(e);
                    }}
                  >
                    Upload
                  </div>
                  <span className="font12x400">
                    Allowed file types: jpg, jpeg, png
                  </span>
                </div>
                {singleImage && (
                  <div className="imageWrapper">
                    <img
                      src={singleImage}
                      alt="delete"
                      width={61}
                      height={61}
                    />
                    <div
                      className="deleteDiv"
                      onClick={() => {
                        deleteImage(-1);
                      }}
                    >
                      <img src="/Assets/delete.svg" alt="delete" />
                    </div>
                  </div>
                )}
                {formik.errors.image ? (
                  <InputError error={formik.errors.image} />
                ) : null}
              </div>

              <SubmitButton
                data={!state.data}
                loader={loader}
                create="Submit"
                update="Update"
              />
            </form>

            {state?.data && <UpdationDetails data={state?.data} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerForm;
