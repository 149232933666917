import React, { useRef } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import { VscChromeClose } from "react-icons/vsc";

const SearchInput = (props) => {
  const {
    type,
    placeholder,
    stateData,
    setStateData,
    width,
    parameter1,
    parameter2,
    addParam,
    resetParam,
  } = props;

  const searchRef = useRef(null);
  const handleChange = () => {
    var matches = searchRef.current?.value.match(/[A-z]/g);

    if (matches != null) {
      setStateData({
        ...stateData,
        q: `${parameter1}:${searchRef.current.value}`,
        page: 1,
      });
    } else {
      stateData.q && delete stateData.q;
      if (parameter2) {
        setStateData({
          ...stateData,
          [parameter2]: searchRef.current.value,
          page: 1,
        });
      } else {
        setStateData({
          ...stateData,
          q: `${parameter1}:${searchRef.current.value}`,
          page: 1,
        });
      }
    }
  };

  const valueCheck = (stateData) => {
    if (stateData?.q) {
      return stateData?.q.split(":")[1];
    } else if (stateData[parameter2]) {
      return stateData[parameter2];
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="searchWrapper" style={{ width: width }}>
        <div className="w-100">
          <input
            ref={searchRef}
            type={type}
            className="form-control shadow-none"
            autoComplete="off"
            placeholder={placeholder}
            value={valueCheck(stateData)}
            onChange={handleChange}
          />
        </div>
        {!stateData.show ? (
          <div
            className="searchBtn"
            onClick={() => {
              addParam();
              setStateData({
                ...stateData,
                show: true,
              });
            }}
          >
            <HiOutlineSearch />
          </div>
        ) : (
          <div
            className="crossBtn"
            onClick={() => {
              resetParam();
              setStateData({
                show: false,
              });
            }}
          >
            <VscChromeClose />
          </div>
        )}
      </div>
    </>
  );
};

export default SearchInput;
