import moment from "moment";
import * as Yup from "yup";
import {
  checkFileFormat,
  checkFileFormatExcel,
  checkFileFormatPDF,
  checkFileSize,
} from "../Functions/other";
import { ADMIN, MASTER_SALON_USER } from "./Constants";

export const mobileSchema = Yup.object().shape({
  mobileNumber: Yup.number()
    // .length(10, "Number should be 10 digits")
    // .matches(/^[6,7,8,9]{1}[\d]{0,9}$/, "Enter valid mobile number")
    .required("Phone number is required"),
  country: Yup.string().required("Please select country from dropdown"),
});

export const step1Schema = Yup.object().shape({
  salonName: Yup.string().required("Name is required"),
  // salonDescription: Yup.string().required("Description is required"),
  salonDescription: Yup.string().optional("This field is optional"),
  logo: Yup.array()
    // .required("Logo is required")
    .optional()
    .test(
      "is-correct-file-size",
      "File size should be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is JPG, JPEG, PNG",
      checkFileFormat
    ),
  images: Yup.array()
    // .required("Salon images is required")
    .optional()
    .test(
      "is-correct-file-size",
      "File size should be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is JPG, JPEG, PNG",
      checkFileFormat
    ),
});

export const step2Schema = Yup.object().shape({
  // salonEmail: Yup.string()
  //   .required("Email is required")
  //   .email("Email is Invalid"),
  salonEmail: Yup.string()
    .optional("this field is optional")
    .email("Email is Invalid"),
  salonContactNumber: Yup.string()
    // .length(10, "Contact Number should be 10 digits")
    // .matches(/^[6,7,8,9]{1}[\d]{0,9}$/, "Enter valid mobile number")
    .required("Number is required"),
  line_1: Yup.string().required("Line1 is required"),
  line_2: Yup.string().optional("Line2 is optional"),
  // city: Yup.string().required("City is required"),
  // state: Yup.string().required("State is required"),
  // country: Yup.string().required("Country is required"),
  // pincode: Yup.string()
  //   .required("Pincode is required")
  //   .length(6, "Pincode should be 6 digits"),
});

export const step3Schema = Yup.object().shape({
  // salonTimings: Yup.array().test({
  //   message: "Salon Timings is required",
  //   test: (arr) => arr.length === 0,
  // }),
  salonCategory: Yup.array().min(1, "Salon Category is required"),
  salonType: Yup.string().required("Store Type is required"),
  classType: Yup.string().required("Store Class is required"),
  priceRating: Yup.string().required("Price Rating is required"),
  tandcCheck: Yup.boolean().oneOf([true], "Field must be checked"),
});

export const editSalonSchema = Yup.object().shape({
  salonName: Yup.string().required("Name is required"),
  // salonDescription: Yup.string().required("Description is required"),
  salonDescription: Yup.string().optional("Optional"),
  logo: Yup.array()
    // .required("Logo is required")
    .optional()
    .test(
      "is-correct-file-size",
      "File size should be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is JPG, JPEG, PNG",
      checkFileFormat
    ),
  images: Yup.array()
    // .required("Salon images is required")
    .optional()
    .test(
      "is-correct-file-size",
      "File size should be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is JPG, JPEG, PNG",
      checkFileFormat
    ),
  // .test("is-correct-length", "You can upload upto 5 images only", (value) => {
  //   return value.length >= 5;
  // }),
  // salonEmail: Yup.string()
  //   .required("Email is required")
  //   .email("Email is Invalid"),
  salonEmail: Yup.string().optional("Optional").email("Email is Invalid"),
  discount: Yup.number().optional("Optional"),
  salonContactNumber: Yup.string()
    // .length(10, "Contact Number should be 10 digits")
    // .matches(/^[6,7,8,9]{1}[\d]{0,9}$/, "Enter valid mobile number")
    .required("Number is required"),
  commision_product: Yup.number()
    .required("Product commision is required")
    .positive("Enter only positive number")
    .min(0),
  commision_serviceAtHome: Yup.number()
    .required("ServiceAtHome commision is required")
    .positive("Enter only positive number")
    .min(0),
  commision_serviceAtSalon: Yup.number()
    .required("ServiceAtSalon commision is required")
    .positive("Enter only positive number")
    .min(0),
  line_1: Yup.string().required("Line1 is required"),
  line_2: Yup.string().optional("Line2 is optional"),
  // salonTimings: Yup.array().test({
  //   message: "Salon Timings is required",
  //   test: (arr) => arr.length === 0,
  // }),
  // salonCategory: Yup.string().required("Category is required"),
  salonType: Yup.string().required("Store Type is required"),
  classType: Yup.string().required("Store Class is required"),
  priceRating: Yup.string().required("Price Rating is required"),
  tandcCheck: Yup.boolean().oneOf([true], "Field must be checked"),
});

export const editSalonAddresses = Yup.object().shape({
  line_1: Yup.string().required("Line1 is required"),
  line_2: Yup.string().optional("Line2 is optional"),
});

export const typeSchema = Yup.object().shape({
  name: Yup.string().required("Type name is required"),
});

export const bannerSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().optional(),
  // type: Yup.string().required("Type is required"),
  order: Yup.number()
    .required("Order is required")
    .positive("Enter only positive number"),
  link: Yup.string().required("Link is required"),
  bannerStatus: Yup.string().required("Banner Status is required"),
  placement: Yup.string().required("Placement is required"),
  city: Yup.string()
    .required("City is required")
    .test("is-city-selected", "Not a valid city", (value, context) => {
      return (
        context.parent.isCitySelected === true ||
        value === "Other" ||
        value === "other"
      );
    }),
  lat: Yup.string().required("Latitude is required"),
  lng: Yup.string().required("Longitude is required"),
  image: Yup.array()
    // .required("Banner image is required")
    .optional()
    .test(
      "is-correct-file-size",
      "File size should be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is JPG, JPEG, PNG",
      checkFileFormat
    ),
});

export const FAQSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  statusName: Yup.string().required("Status is required"),
  description: Yup.string().required("Description is required"),
});

export const dynamicPageSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  statusName: Yup.string().required("Status is required"),
  description: Yup.string().required("Description is required"),
  content: Yup.string().required("Content is required"),
  image: Yup.array()
    // .required("Dynamic page image is required")
    .optional()
    .test(
      "is-correct-file-size",
      "File size should be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is JPG, JPEG, PNG",
      checkFileFormat
    ),
});

export const offerCategorySchema = Yup.object().shape({
  name: Yup.string().required("Category name is required"),
  order: Yup.number()
    .required("Order is required")
    .positive("Enter only positive number"),
});

export const offerSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  couponCode: Yup.string().required("Coupon code is required"),
  category: Yup.string().required("Category is required"),
  order: Yup.number()
    .required("Order is required")
    .positive("Enter only positive number"),
  statusName: Yup.string().required("Status is required"),
  terms: Yup.string().required("Terms is required"),
  image: Yup.array()
    // .required("Offer Image is required")
    .optional()
    .test(
      "is-correct-file-size",
      "File size should be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is JPG, JPEG, PNG",
      checkFileFormat
    ),
});

export const SalonOfferSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  statusName: Yup.string().required("Status is required"),
  details: Yup.string().required("Details is required"),
  order: Yup.number()
    .required("Order is required")
    .positive("Enter only positive number"),
});

export const userSchema = Yup.object().shape({
  name: Yup.string().when("isDataComing", {
    is: false,
    then: Yup.string().required("Name is required"),
  }),
  email: Yup.string().when("isDataComing", {
    is: false,
    then: Yup.string().required("Email is required").email("Invalid email"),
  }),
  mobile: Yup.string().required("Mobile Number is required"),
  // .length(10, "Mobile Number should be 10 digits"),
  statusName: Yup.string().required("Status is required"),
  role: Yup.string().required("Role is required"),
  salon: Yup.string().when("role", {
    is: MASTER_SALON_USER,
    then: Yup.string().required("Salon is required"),
  }),
  image: Yup.array()
    // .required("User Image is required")
    .optional()
    .test(
      "is-correct-file-size",
      "File size should be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is JPG, JPEG, PNG",
      checkFileFormat
    ),
});

export const sendNotificationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  image: Yup.array()
    // .required("User Image is required")
    .optional()
    .test(
      "is-correct-file-size",
      "File size should be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is JPG, JPEG, PNG",
      checkFileFormat
    ),
  sendTo: Yup.string().required("Send to is required"),
});

export const productSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  category: Yup.string().required("Product Category is required"),
  quantity: Yup.string().required("Quantity is required"),
  order: Yup.number()
    .required("Order is required")
    .positive("Enter only positive number"),
  mrp: Yup.number()
    .required("Mrp is required")
    .min(0)
    .positive("Enter only positive number"),
  discountPrice: Yup.number()
    .required("Discount Price is required")
    .min(0)
    .positive("Enter only positive number")
    .test(
      "Discount Price should be less than MRP",
      "Discount Price should be less than MRP",
      (value, context) => {
        return value <= context.parent.mrp;
      }
    ),
  description: Yup.string().optional(),
  // brandName: Yup.string().required("Brand Name is required"),
  brandName: Yup.string().optional(),
  noOfPeople: Yup.number().when("role", {
    is: ADMIN,
    then: Yup.number()
      .required("No of People is required")
      .positive("Enter only positive number"),
  }),
  rating: Yup.number().when("role", {
    is: ADMIN,
    then: Yup.number()
      .required("Rating is required")
      .positive("Enter only positive number")
      .max(5, "Rating Between 1 to 5"),
  }),
  currentStock: Yup.number()
    .required("Current Stock is required")
    .positive("Enter only positive number")
    .min(0),
  image: Yup.array()
    // .required("Product Image is required")
    .optional()
    .test(
      "is-correct-file-size",
      "File size should be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is JPG, JPEG, PNG",
      checkFileFormat
    ),
  genderType: Yup.string().required("Gender type is required"),
  productType: Yup.string().required("Type is required"),
  // isActive: Yup.boolean().oneOf([true], "Field must be checked"),
});

export const salonCouponSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  code: Yup.string().required("Code is required"),
  order: Yup.number()
    .required("Order is required")
    .positive("Enter only positive number"),
  conditionType: Yup.string().required("Condition type is required"),
  condition: Yup.number()
    .required("Condition is required")
    .positive("Condition invalid"),
  minValue: Yup.number()
    .required("Minimum Value is required")
    .positive("Enter only positive number")
    .min(0),
  statusName: Yup.string().required("Status is required"),
  couponOnWeekends: Yup.string().required(
    "Coupon Status on Weekend is required"
  ),
  allowedUsage: Yup.number()
    .required("Allowed Usage is required")
    .positive("Allowed Usage invalid"),
  singleUserLimit: Yup.number()
    .required("Single User Limit is required")
    .positive("Single User Limit invalid"),
  description: Yup.string().required("Description is required"),
  terms: Yup.string().required("Terms is required"),
  validFrom: Yup.date().required("Date valid from is required"),
  validTo: Yup.date()
    .required("Date valid to is required")
    .test(
      "Till Date should be greater than From Date",
      "Till Date should be greater than From Date",
      (value, context) => {
        return moment(value, "YYYY-MM-DD HH:mm").isAfter(
          moment(context.parent.validFrom, "YYYY-MM-DD HH:mm")
        );
      }
    ),
});

export const couponSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  code: Yup.string().required("Code is required"),
  salon: Yup.string().required("Store is required"),
  order: Yup.number()
    .required("Order is required")
    .positive("Enter only positive number"),
  conditionType: Yup.string().required("Condition type is required"),
  condition: Yup.number()
    .required("Condition is required")
    .positive("Condition invalid")
    .when("conditionType", {
      is: "Percentage",
      then: Yup.number()
        .min(1, "Enter number between 1 to 100")
        .max(100, "Enter number between 1 to 100"),
    }),
  minValue: Yup.number()
    .required("Minimum Value is required")
    .positive("Enter only positive number")
    .min(0)
    .test(
      "Minimum order value must be greater than or equal to condition value",
      "Minimum order value must be greater than or equal to condition value",
      (value, context) => {
        return value >= context.parent.condition;
      }
    ),
  statusName: Yup.string().required("Status is required"),
  couponOnWeekends: Yup.string().required(
    "Coupon Status on weekend is required"
  ),
  allowedUsage: Yup.number()
    .required("Allowed Usage is required")
    .positive("Allowed Usage invalid"),
  singleUserLimit: Yup.number()
    .required("Single User Limit is required")
    .positive("Single User Limit invalid"),
  description: Yup.string().required("Description is required"),
  terms: Yup.string().required("Terms is required"),
  validFrom: Yup.date().required("Date valid from is required"),
  validTo: Yup.date()
    .required("Date valid to is required")
    .test(
      "Till Date should be greater than From Date",
      "Till Date should be greater than From Date",
      (value, context) => {
        return moment(value, "YYYY-MM-DD HH:mm").isAfter(
          moment(context.parent.validFrom, "YYYY-MM-DD HH:mm")
        );
      }
    ),
});

export const serviceSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  category: Yup.string().required("Service Category is required"),
  order: Yup.number()
    .required("Order is required")
    .positive("Enter only positive number"),
  salonMrp: Yup.number().when("isSalon", {
    is: true,
    then: Yup.number()
      .required("Store Mrp is required")
      .min(0)
      .positive("Enter only positive number"),
  }),
  salonDiscountPrice: Yup.number().when("isSalon", {
    is: true,
    then: Yup.number()
      .required("Store Discount Price is required")
      .min(0)
      .positive("Enter only positive number")
      .test(
        "Discount Price should be less than Store MRP",
        "Discount Price should be less than Store MRP",
        (value, context) => {
          return value <= context.parent.salonMrp;
        }
      ),
  }),
  homeMrp: Yup.number().when("isHome", {
    is: true,
    then: Yup.number()
      .required("Home Mrp is required")
      .min(0)
      .positive("Enter only positive number"),
  }),
  homeDiscountPrice: Yup.number().when("isHome", {
    is: true,
    then: Yup.number()
      .required("Home Discount Price is required")
      .min(0)
      .positive("Enter only positive number")
      .test(
        "Discount Price should be less than Home MRP",
        "Discount Price should be less than Home MRP",
        (value, context) => {
          return value <= context.parent.homeMrp;
        }
      ),
  }),
  description: Yup.string().optional(),
  timings: Yup.number()
    .required("Duration is required")
    .positive("Enter only positive number"),
  // brandUsed: Yup.string().required("Brand Used is required"),
  brandUsed: Yup.string().optional(),
  noOfPeople: Yup.number().when("role", {
    is: ADMIN,
    then: Yup.number()
      .required("No of People is required")
      .positive("Enter only positive number"),
  }),
  rating: Yup.number().when("role", {
    is: ADMIN,
    then: Yup.number()
      .required("Rating is required")
      .positive("Enter only positive number")
      .max(5, "Rating Between 1 to 5"),
  }),
  image: Yup.array()
    // .required("Service Image is required")
    .optional()
    .test(
      "is-correct-file-size",
      "File size should be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is JPG, JPEG, PNG",
      checkFileFormat
    ),
  genderType: Yup.string().required("Gender type is required"),
  // isSalon: Yup.boolean().oneOf([true], "Field must be checked"),
  // // .optional("jcgidsg"),
  // isHome: Yup.boolean().oneOf([true], "Field must be checked"),
  // .optional("ASasczc"),
});

export const paymentDetailsSchema = Yup.object().shape({
  bankName: Yup.string().required("Bank Name is required"),
  accountNumber: Yup.string()
    .required("Account Number is required")
    // .matches(/^\d{9,18}$/, "Enter valid account number")
    .min(9, "Account Number must be at least 9 to 18 digits min")
    .max(18, "Account Number must be at least 9 to 18 digits"),
  ifscCode: Yup.string()
    .required("IFSC Code is required")
    .length(11, "IFSC Code must be of length 11")
    .matches(
      /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/,
      "First 4 characters must be alphabets and last 7 characters must be numbers"
    ),
  legalEntitiesName: Yup.string().required("Legal Entities Name is required"),
  panNumber: Yup.string()
    .required("Pan Number is required")
    .length(10, "length should be 10")
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN Number"),
  gstId: Yup.string()
    .required("GST Id is required")
    .length(15, "GST Id must be of length 15")
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Invalid GST Id"
    ),
  panImage: Yup.array()
    // .required("Pan Image is required")
    .optional()
    .test(
      "is-correct-file-size",
      "File size should be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is JPG, JPEG, PNG",
      checkFileFormat
    ),
  gstDocument: Yup.array()
    // .required("GST document is required")
    .optional()
    .test(
      "is-correct-file-size",
      "File size should be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is JPG, JPEG, PNG",
      checkFileFormat
    ),
  otherDocuments: Yup.array()
    .optional()
    .test(
      "is-correct-file-size",
      "File size should be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is PDF",
      checkFileFormatPDF
    ),
});

export const slotDisableSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  validFrom: Yup.date().required("Date valid from is required"),
});

export const stylistSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  image: Yup.array()
    // .required("Product Image is required")
    .optional()
    .test(
      "is-correct-file-size",
      "File size should be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is JPG, JPEG, PNG",
      checkFileFormat
    ),
  mobile: Yup.string().required("Mobile Number is required"),
  // .length(10, "Mobile Number should be 10 digits"),
  alternateNumber: Yup.string()
    .required("Alternate Number is required")
    .test(
      "is-not-equal-to-mobile",
      "Alternate number must be different from mobile number",
      (value, context) => {
        return value !== context.parent.mobile;
      }
    ),
  // .length(10, "Alternate Number should be 10 digits"),
  salary: Yup.number()
    .required("Salary is required")
    .positive("Enter only positive number"),
  advance: Yup.number()
    .required("Advance is required")
    .positive("Enter only positive number")
    .min(0),
  penalty: Yup.number()
    .required("Penalty is required")
    .positive("Enter only positive number")
    .min(0),
  statusName: Yup.string().required("Status is required"),
  gender: Yup.string().required("Gender is required"),
  weeklyOff: Yup.string().optional(),
});

export const disableSlotSchema = Yup.object().shape({
  reason: Yup.string().required("Reason is required"),
  // date: Yup.date().test("Date is required"),
  // time: Yup.string().required("Time is required"),
});

export const categorySchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  order: Yup.number()
    .required("Order is required")
    .positive("Enter only positive number"),
  statusName: Yup.string().required("Status is required"),
  image: Yup.array()
    // .required("Image is required")
    .optional()
    .test(
      "is-correct-file-size",
      "File size should be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is JPG, JPEG, PNG",
      checkFileFormat
    ),
});

export const checkoutSchema = Yup.object().shape({
  paymentMode: Yup.string().required("Payment Mode is required"),
  gstNumber: Yup.string()
    .required("GST Number is required")
    .length(15, "GST Number must be of length 15")
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Invalid GST Number"
    ),
  legalEntitiesName: Yup.string().required("Legal Entities Name is required"),
  shippingAddress: Yup.object().test(
    "is-empty",
    "Shipping Address is required",
    (value, context) => {
      return Object.keys(context.parent.shippingAddress).length;
    }
  ),
});

export const orderDetailsSchema = Yup.object().shape({
  deliveryCharges: Yup.number()
    .required("Delivery Charges is required")
    .positive("Enter only positive number")
    .min(0),
  minOrderValue: Yup.number()
    .required("Min Order Value is required")
    .positive("Enter only positive number")
    .min(0),
  maxOrderValue: Yup.number()
    .required("Max Order Value is required")
    .positive("Enter only positive number")
    .min(0)
    .test(
      "Max order value is greater than delivery charges",
      "Max order value is greater than delivery charges",
      (value, context) => {
        return value > context.parent.deliveryCharges;
      }
    ),
});

export const stockSchema = Yup.object().shape({
  qty: Yup.number()
    .required("Stock is required")
    .min(0)
    .positive("Enter only positive number"),
  qtyStatus: Yup.string().required("Status is required"),
  comment: Yup.string().required("Comment is required"),
});

export const orderPaymentModeSchema = Yup.object().shape({
  paymentMode: Yup.string().required("Payment Mode is required"),
});

export const cancelOrderAdminSchema = Yup.object().shape({
  cancelReason: Yup.string().required("Cancel reason is required"),
});

export const membershipSchema = Yup.object().shape({
  name: Yup.string().required("Package Name is required"),
  statusName: Yup.string().required("Status is required"),
  price: Yup.number()
    .required("Price is required")
    .min(0)
    .positive("Enter only positive number"),
  serviceDiscount: Yup.number().required("Service Discount is required"),
  productDiscount: Yup.number().required("Product Discount is required"),
  features: Yup.string().required("Features is required"),
  validity: Yup.number()
    .required("Validity is required")
    .min(0)
    .positive("Enter only positive number"),
});

export const userWalletSchema = Yup.object().shape({
  addTo: Yup.string().when("isSingleUser", {
    is: false,
    then: Yup.string().required("Add to is required"),
  }),
  entryTitle: Yup.string().required("Entry Title is required"),
  amount: Yup.number()
    .required("Amount is required")
    .min(0)
    .positive("Enter only positive number"),
  entryType: Yup.string().required("Entry Type is required"),
  validity: Yup.date().required("Validity is required"),
});

export const rechargeSchema = Yup.object().shape({
  type: Yup.string().required("Type is required"),
  amount: Yup.number()
    .required("Amount is required")
    .min(0)
    .positive("Enter only positive number"),
  entryType: Yup.string().required("Entry Type is required"),
  // paymentMode: Yup.string().required("Payment Mode is required"),
});

export const uploadExcelSchema = Yup.object().shape({
  uploadExcel: Yup.array()
    .optional()
    .test(
      "is-correct-file-size",
      "File size should be less then 1 mb",
      checkFileSize
    )
    .test(
      "is-correct-format",
      "File format is incorrect. Supported format is xlsx",
      checkFileFormatExcel
    ),
});

export const LeadSchema = Yup.object().shape({
  statusName: Yup.string().required("Status is required"),
});

export const countrySchema = Yup.object().shape({
  name: Yup.string().required("Country is required"),
  // phoneCode: Yup.string().required("Phone Code is required"),
  // acronym: Yup.string().required("Acronym is required"),
  // currency: Yup.string().required("Currency is required"),
  htmlSymbol: Yup.string().optional(),
});
