import React from "react";

const PaymentMethods = (props) => {
  const { label, imgPath, htmlFor, name, stateDate, setStateData, value } =
    props;
  return (
    <>
      <div className="py-3" style={{ borderBottom: "1px solid #EBEBEB" }}>
        <label htmlFor={htmlFor} className="w-100">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <img src={imgPath} alt="payMethod" />
              <p className="mb-0 text-black font16x500 ms-2">{label}</p>
            </div>
            <div className="radioCustom d-flex align-items-center">
              <input
                type="radio"
                id={htmlFor}
                name={name}
                value={value}
                onChange={(e) => {
                  setStateData(e.target.value);
                }}
              />
            </div>
          </div>
        </label>
      </div>
    </>
  );
};

export default PaymentMethods;
