import React, { useRef, useState } from "react";
import InputError from "../../../Shared Components/InputError";
import InputFieldLarge from "../../../Shared Components/InputFieldLarge";
import StaticDropdown from "../../../Shared Components/StaticDropdown";
import axios from "axios";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { dynamicPageSchema } from "../../../utils/validationschema";
import useLoading from "../../../hooks/useLoading";
import useAuthContext from "../../../hooks/useAuthContext";
import {
  authHeader,
  CREATE_DYNAMIC_PAGE,
  DYNAMIC_PAGE_UPDATE,
} from "../../../utils/Constants";
import Loader from "../../Loader";
import ContentEditor from "./ContentEditor";
import SubmitButton from "../../../Shared Components/SubmitButton";
import UpdationDetails from "../../../Shared Components/UpdationDetails";

const AddDynamicPageForm = ({ state, setState, apiCall }) => {
  const [oldImagePath, setoldImagePath] = useState("null");

  const initialValues = {
    title: state?.data?.title ?? "",
    image: "",
    statusName: state?.data?.status === 1 ? "Active" : "Inactive",
    description: state?.data?.description ?? "",
    content: state?.data?.content ?? "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: dynamicPageSchema,
    onSubmit: (data) => {
      formSubmit(data);
      // console.log("data: ", data);
    },
  });

  const dynamicPImage = useRef(null);
  const handleSingleImg = (e) => {
    e.preventDefault();
    dynamicPImage.current.click();
  };

  const [singleImage, setSingleImage] = useState(
    state?.data?.image
      ? `${process.env.REACT_APP_Image_BaseURL}${state?.data?.image}`
      : ""
  );
  const imageDataURL = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSingleImage(URL.createObjectURL(e.target.files[0]));
      formik.setFieldValue("image", Array.from(e.target.files));
    }
  };
  const deleteImage = (index) => {
    if (index === -1) {
      formik.setFieldValue("image", "");
      setSingleImage("");
      setoldImagePath(state?.data?.image ? state?.data?.image : null);
      dynamicPImage.current.value = "";
    }
  };

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const { token } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();

  const formSubmit = async (data) => {
    //form data
    const sFormData = new FormData();
    sFormData.append("title", data.title);
    sFormData.append("image", data.image[0]);
    sFormData.append("description", data.description);
    sFormData.append("content", data.content);
    sFormData.append("status", data.statusName === "Active" ? 1 : 2);
    if (state.data) {
      sFormData.append("updateImage", oldImagePath);
    }

    if (token) {
      if (state.data === undefined) {
        try {
          showLoader();
          const res = await axios.post(`${CREATE_DYNAMIC_PAGE}`, sFormData, {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Dynamic Page Added", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      } else {
        try {
          showLoader();
          const res = await axios.put(
            `${DYNAMIC_PAGE_UPDATE}${state.data._id}/form`,
            sFormData,
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            apiCall();
            hideLoader();
            setPositionToBody();
            toast.success("Dynamic Page Updated", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    }
  };

  return (
    <>
      <div className={state ? "formWrapper formWrapper-active" : "formWrapper"}>
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">
              {!state?.data ? "Add New Dynamic Page" : "Edit Dynamic Page"}
            </h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
              {/* Title* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Title*"
                  htmlFor="title"
                  type="text"
                  name="title"
                  stateData={formik.values.title}
                  setStateData={formik.handleChange}
                />
                {formik.errors.title ? (
                  <InputError error={formik.errors.title} />
                ) : null}
              </div>

              {/* service image */}
              <div className="mb-3">
                <div className="mb-0">
                  <label
                    htmlFor="formFile"
                    className="font12x500 mb-1 form-label"
                  >
                    Image*
                  </label>
                  <input
                    ref={dynamicPImage}
                    className="form-control shadow-none"
                    type="file"
                    id="formFile"
                    name="image"
                    accept={".png, .jpg, .jpeg"}
                    hidden
                    onChange={(e) => {
                      imageDataURL(e);
                    }}
                  />
                </div>
                <div className="uploadImg d-flex align-items-center mb-2">
                  <div
                    className="uploadBtn font12x400 me-3"
                    onClick={(e) => {
                      handleSingleImg(e);
                    }}
                  >
                    Upload
                  </div>
                  <span className="font12x400">
                    Allowed file types: jpg, jpeg, png
                  </span>
                </div>
                {singleImage && (
                  <>
                    <div className="imageWrapper">
                      <img
                        src={singleImage}
                        alt="delete"
                        width={61}
                        height={61}
                      />
                      <div
                        className="deleteDiv"
                        onClick={() => {
                          deleteImage(-1);
                        }}
                      >
                        <img src="/Assets/delete.svg" alt="delete" />
                      </div>
                    </div>
                  </>
                )}
                {formik.errors.image ? (
                  <InputError error={formik.errors.image} />
                ) : null}
              </div>

              {/* Description */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Description*"
                  htmlFor="description"
                  type="text"
                  name="description"
                  stateData={formik.values.description}
                  setStateData={formik.handleChange}
                />
                {formik.errors.description ? (
                  <InputError error={formik.errors.description} />
                ) : null}
              </div>

              {/* status */}
              <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: "Active", label: "Active" },
                    { value: "Inactive", label: "Inactive" },
                  ]}
                  label="Status*"
                  htmlFor="status"
                  type="text"
                  placeholder="Select Status"
                  name="statusName"
                  stateData={formik.values.statusName}
                  setStateData={(e) => {
                    formik.setFieldValue("statusName", e);
                  }}
                />
                {formik.errors.statusName ? (
                  <InputError error={formik.errors.statusName} />
                ) : null}
              </div>

              {/* text editor */}
              <div className="mb-3">
                <label htmlFor="desc" className="font12x500 form-label mb-1">
                  Content*
                </label>
                <ContentEditor
                  stateData={formik.values.content}
                  setStateData={(e) => {
                    formik.setFieldValue("content", e);
                  }}
                />
                {formik.errors.content ? (
                  <InputError error={formik.errors.content} />
                ) : null}
              </div>

              <SubmitButton
                data={!state.data}
                loader={loader}
                create="Add Page"
                update="Update Page"
              />

              {state?.data && <UpdationDetails data={state?.data} />}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDynamicPageForm;
