import React from "react";
import InputError from "../../Shared Components/InputError";

const SelectBoth = (props) => {
  const {
    label,
    htmlFor1,
    htmlFor2,
    text1,
    text2,
    stateData,
    setStateData,
    formik,
    name1,
    name2,
  } = props;
  return (
    <>
      <div className="mb-3">
        <label
          htmlFor="floatingTextarea2"
          className="font16x500 mb-2 form-label labelBig"
        >
          {label}
        </label>
        <div className="container-fluid px-0">
          <div className="row">
            <div className="agreeCheck col-6">
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  className="me-2"
                  id={htmlFor1}
                  name={name1}
                  // onChange={() => {
                  //   setStateData({
                  //     ...stateData,
                  //     isSalon: !stateData.isSalon,
                  //     salonMrp: 0,
                  //     salonDiscountPrice: 0,
                  //   });
                  // }}
                  onChange={formik.handleChange}
                  checked={formik.values.isSalon && true}
                />
                <label
                  className="font14x400 form-check-label"
                  htmlFor={htmlFor1}
                >
                  {text1}
                </label>
              </div>
              {formik.errors.isSalon ? (
                <InputError error={formik.errors.isSalon} />
              ) : null}
            </div>

            <div className="agreeCheck col-6">
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  className="me-2"
                  id={htmlFor2}
                  name={name2}
                  // onChange={() => {
                  //   setStateData({
                  //     ...stateData,
                  //     isHome: !stateData.isHome,
                  //     homeMrp: 0,
                  //     homeDiscountPrice: 0,
                  //   });
                  // }}
                  onChange={formik.handleChange}
                  checked={formik.values.isHome && true}
                />
                <label
                  className="font14x400 form-check-label"
                  htmlFor={htmlFor2}
                >
                  {text2}
                </label>
              </div>
              {formik.errors.isHome ? (
                <InputError error={formik.errors.isHome} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectBoth;
