import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useAuthContext from "../../hooks/useAuthContext";
import {
  ADMIN,
  CHILD_SALON,
  MASTER_SALON,
  MASTER_SALON_USER,
} from "../../utils/Constants";
import MobileSalonDashboardSideNavHeader from "./mobilesalondashboardsidenavheader";

function MobileSalonDashboardSideNav(props) {
  const { pathname } = useLocation();
  const { role } = useAuthContext();
  const [animateSideNav, setAnimateSideNav] = useState({
    transform: "translate(-100%)",
  });

  const logOut = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };

  useEffect(() => {
    if (props.isOpenSideNav) {
      setAnimateSideNav({
        transform: "translate(0%)",
      });
    } else {
      setAnimateSideNav({
        transform: "translate(-100%)",
      });
    }
  }, [props.isOpenSideNav]);

  return (
    <>
      {role === ADMIN && pathname?.includes("/admin") ? (
        <div style={animateSideNav} className="mobile-salon-dashboad-side-nav">
          <div className="left-side-nav-section">
            <MobileSalonDashboardSideNavHeader
              setIsOpenSideNav={props.setIsOpenSideNav}
            />
            <Link
              to="/admin/dashboard"
              className={
                pathname === "/admin/dashboard" ? "dashboard-text" : ""
              }
            >
              <img src="/Assets/dashboardColor.svg" width={24} height={24} />
              <p>Dashboard</p>
            </Link>
            <Link
              to="/admin/lead"
              className={pathname === "/admin/lead" ? "dashboard-text" : ""}
            >
              <img src="/Assets/leadColor.svg" width={24} height={24} />
              <p>Leads</p>
            </Link>
            <Link
              to="/admin/qr-order/service"
              className={
                pathname === "/admin/order/service" ||
                pathname === "/admin/order/product" ||
                pathname === "/admin/qr-order/service" ||
                pathname === "/admin/qr-order/product" ||
                pathname === "/admin/membership-order" ||
                pathname?.includes("/admin/order/")
                  ? "dashboard-text"
                  : ""
              }
            >
              <img src="/Assets/orderColor.svg" width={24} height={24} />
              <p>Orders</p>
            </Link>
            <Link
              to="/admin/allSalons"
              className={
                pathname === "/admin/allSalons" ? "dashboard-text" : ""
              }
            >
              <img src="/Assets/salonListColor.svg" width={24} height={24} />
              <p>Store List</p>
            </Link>

            <Link
              to="/admin/users"
              className={
                pathname === "/admin/users" ||
                pathname === "/admin/temporary-users"
                  ? "dashboard-text"
                  : ""
              }
            >
              <img src="/Assets/userColor.svg" width={24} height={24} />
              <p>Users</p>
            </Link>

            <Link
              to="/admin/membership"
              className={
                pathname === "/admin/membership" ? "dashboard-text" : ""
              }
            >
              <img src="/Assets/membershipColor.svg" width={24} height={24} />
              <p>Membership</p>
            </Link>

            <Link
              to="/admin/app-coupons"
              className={
                pathname === "/admin/app-coupons" ||
                pathname === "/admin/offer&deals"
                  ? "dashboard-text"
                  : ""
              }
            >
              <img src="/Assets/marketingColor.svg" width={24} height={24} />
              <p>Marketing</p>
            </Link>

            <Link
              to="/admin/addProduct"
              className={
                pathname === "/admin/addProduct" ? "dashboard-text" : ""
              }
            >
              <img src="/Assets/productColor.svg" width={24} height={24} />
              <p>Products</p>
            </Link>

            <Link
              to="/admin/serviceCategory"
              className={
                pathname === "/admin/serviceCategory" ||
                pathname === "/admin/salonCategory" ||
                pathname === "/admin/productCategory" ||
                pathname === "/admin/service-name" ||
                pathname === "/admin/brand"
                  ? "dashboard-text"
                  : ""
              }
            >
              <img src="/Assets/categoriesColor.svg" width={24} height={24} />
              <p>Constants</p>
            </Link>

            <Link
              to="/admin/transactions"
              className={
                pathname === "/admin/transactions" ? "dashboard-text" : ""
              }
            >
              <img src="/Assets/transactionColor.svg" width={24} height={24} />
              <p>Transactions</p>
            </Link>

            <Link
              to="/admin/faq"
              className={
                pathname === "/admin/faq" ||
                pathname === "/admin/dynamicPages" ||
                pathname === "/admin/banner&link"
                  ? "dashboard-text"
                  : ""
              }
            >
              <img
                src="/Assets/customizationColor.svg"
                width={24}
                height={24}
              />
              <p>Customization</p>
            </Link>
            <Link to="" onClick={logOut}>
              <img src="/Assets/logoutIcon.svg" width={24} height={24} />
              <p>Log out</p>
            </Link>
          </div>
          <div
            onClick={() => {
              // props.setIsOpenSideNav(false);
            }}
            className="right-side-nav-section"
          ></div>
        </div>
      ) : role === MASTER_SALON ||
        role === ADMIN ||
        role === CHILD_SALON ||
        role === MASTER_SALON_USER ? (
        <div style={animateSideNav} className="mobile-salon-dashboad-side-nav">
          <div className="left-side-nav-section">
            <MobileSalonDashboardSideNavHeader
              setIsOpenSideNav={props.setIsOpenSideNav}
            />
            <Link
              to="/salon/dashboard"
              className={
                pathname === "/salon/dashboard" ? "dashboard-text" : ""
              }
            >
              <img src="/Assets/dashboardColor.svg" width={24} height={24} />
              <p>Dashboard</p>
            </Link>
            <Link
              to="/salon/qr-order/service"
              className={
                pathname === "/salon/qr-order/service" ||
                pathname === "/salon/qr-order/product" ||
                pathname === "/salon/order/service" ||
                pathname === "/salon/order/product" ||
                pathname === "/salon/your-orders"
                  ? "dashboard-text"
                  : ""
              }
            >
              <img src="/Assets/orders.svg" />
              <p>Orders</p>
            </Link>

            <Link
              to="/salon/booking"
              className={pathname === "/salon/booking" ? "dashboard-text" : ""}
            >
              <img src="/Assets/bookingslots.svg" />
              <p>Calendar</p>
            </Link>

            <Link
              to="/salon/clients"
              className={pathname === "/salon/clients" ? "dashboard-text" : ""}
            >
              <img src="/Assets/clientColor.svg" width={24} height={24} />
              <p>Clients</p>
            </Link>

            <Link
              to="/salon/store"
              className={pathname === "/salon/store" ? "dashboard-text" : ""}
            >
              <img src="/Assets/layer.svg" />
              <p>Store profile</p>
            </Link>

            <Link
              to="/salon/service"
              className={pathname === "/salon/service" ? "dashboard-text" : ""}
            >
              <img src="/Assets/sidenavservices.svg" />
              <p>Services</p>
            </Link>

            <Link
              to="/salon/product"
              className={pathname === "/salon/product" ? "dashboard-text" : ""}
            >
              <img src="/Assets/sideproduct.svg" />
              <p>Products</p>
            </Link>

            <Link
              to="/salon/stylist"
              className={pathname === "/salon/stylist" ? "dashboard-text" : ""}
            >
              <img src="/Assets/stylists.svg" />
              <p>Stylists</p>
            </Link>

            <Link
              to="/salon/offer"
              className={pathname === "/salon/offer" ? "dashboard-text" : ""}
            >
              <img src="/Assets/offers.svg" />
              <p>Offers</p>
            </Link>

            <Link
              to="/salon/disableSlot"
              className={
                pathname === "/salon/disableSlot" ? "dashboard-text" : ""
              }
            >
              <img src="/Assets/blockedSlotColor.svg" width={24} height={24} />
              <p>Block Slot</p>
            </Link>

            <Link
              to="/salon/buy/product"
              className={
                pathname === "/salon/buy/product" ? "dashboard-text" : ""
              }
            >
              <img src="/Assets/buyproduct.svg" />
              <p>Buy Products</p>
            </Link>

            <Link
              to="/salon/payment"
              className={pathname === "/salon/payment" ? "dashboard-text" : ""}
            >
              <img src="/Assets/sidepayment.svg" />
              <p>Payments</p>
            </Link>

            <Link
              to="/salon/review&rating"
              className={
                pathname === "/salon/review&rating" ? "dashboard-text" : ""
              }
            >
              <img src="/Assets/reviewmanagement.svg" />
              <p>Review & Rating</p>
            </Link>

            <Link
              to="/salon/wallet"
              className={pathname === "/salon/wallet" ? "dashboard-text" : ""}
            >
              <img src="/Assets/sWalletColor.svg" width={24} height={24} />
              <p>Store Wallet</p>
            </Link>

            <Link to="" onClick={logOut}>
              <img src="/Assets/logoutIcon.svg" width={24} height={24} />
              <p>Log out</p>
            </Link>
          </div>
          <div
            onClick={() => {
              // props.setIsOpenSideNav(false);
            }}
            className="right-side-nav-section"
          ></div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default MobileSalonDashboardSideNav;
