import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import Layout from "../../../../Components/Layout";
import Loader from "../../../../Components/Loader";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import PaymentForm from "../../../../Components/PaymentForm";
import useFetch from "../../../../hooks/useFetch";
import { PAYMENT_DETAIL } from "../../../../utils/Constants";

const Payment = () => {
  const [commonData, setCommonData] = useState({
    data: {},
    modal: false,
  });

  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const functions = {
    onEdit: (item) => {
      setCommonData({
        data: item,
        modal: true,
      });
      document.body.style.overflow = "hidden";
    },
    onAdd: () => {
      setCommonData({
        data: undefined,
        modal: true,
      });
      document.body.style.overflow = "hidden";
    },
  };

  const salonDetails = JSON.parse(localStorage.getItem("salon_details"));

  const [paymentDetails, totalResults, error, loader, getData] = useFetch({
    url: `${PAYMENT_DETAIL}salon=${salonDetails?._id}`,
    resultField: "paymentDetails",
  });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (isOpenSideNav) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav]);

  return (
    <>
      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Payment Details"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={false}
          title="Payment Details"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper clients-page">
        <div className="desktop">
          <div>
            <div>
              <h3 className="font22x500 pageHeading">Payment Details</h3>
            </div>
            {paymentDetails?.length !== 0 ? (
              <div className="container ms-0 ps-0 mb-3 mainContent">
                <div className="p-5">
                  {/* Account Details */}
                  <div className="mb-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="font22x500 ContentHeading">
                        Account Details
                      </h3>
                      <button
                        className="btn addBtn shadow-none"
                        onClick={() => {
                          functions.onEdit(paymentDetails[0]);
                        }}
                      >
                        Edit
                      </button>
                    </div>
                    <div>
                      <p className="mb-0">
                        A/C No: <span>{paymentDetails[0]?.accountNumber}</span>
                      </p>
                      <p className="mb-0">
                        Bank: <span>{paymentDetails[0]?.bankName}</span>
                      </p>
                      <p className="mb-0">
                        IFSC: <span>{paymentDetails[0]?.ifscCode}</span>
                      </p>
                    </div>
                  </div>

                  {/* Payment Method */}
                  <div className="mb-4">
                    <h3 className="font22x500 ContentHeading">
                      Payment Method
                    </h3>
                    <div>
                      <p className="mb-0">Online Payment via Getlook, COD</p>
                    </div>
                  </div>

                  {/* Uploaded Documents */}
                  <div className="mb-4">
                    <h3 className="font22x500 ContentHeading">
                      Uploaded Documents
                    </h3>
                    <div>
                      <p className="mb-0">
                        PAN Card: <span>{paymentDetails[0]?.panNumber}</span>
                      </p>
                      <p className="mb-0">
                        GST: <span>{paymentDetails[0]?.gstId}</span>
                      </p>
                    </div>
                  </div>

                  {/* Legal Entity Name */}
                  <div className="mb-4">
                    <h3 className="font22x500 ContentHeading">
                      Legal Entity Name
                    </h3>
                    <div>
                      <p className="mb-0">
                        {paymentDetails[0]?.legalEntitiesName}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex mt-3">
                    {paymentDetails[0]?.panImage && (
                      <div className="">
                        <a
                          href={`${process.env.REACT_APP_Image_BaseURL}${paymentDetails[0]?.panImage}`}
                          target="_blank"
                        >
                          <button className="btn addBtn shadow-none me-3">
                            Download PAN
                          </button>
                        </a>
                      </div>
                    )}

                    {paymentDetails[0]?.gstDocument && (
                      <div className="">
                        <a
                          href={`${process.env.REACT_APP_Image_BaseURL}${paymentDetails[0]?.gstDocument}`}
                          target="_blank"
                        >
                          <button className="btn addBtn shadow-none me-3">
                            Download GST
                          </button>
                        </a>
                      </div>
                    )}

                    {paymentDetails[0]?.otherDocuments && (
                      <div className="">
                        <a
                          href={`${process.env.REACT_APP_Image_BaseURL}${paymentDetails[0]?.otherDocuments}`}
                          target="_blank"
                        >
                          <button className="btn addBtn shadow-none me-3">
                            Download Other Document
                          </button>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : loader ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: "100%", height: "50vh" }}
              >
                <Loader height="40" width="40" color="#772286" />
              </div>
            ) : (
              <div>
                <p>
                  You don't add your payment details yet. Please click on the
                  add button to add payment details.
                </p>
                <button
                  className="btn addBtn shadow-none"
                  onClick={functions.onAdd}
                >
                  Add
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="mobile p-3">
          <div className="mobileCardWrapper mb-3">
            <div className="mobileCardHeading d-flex justify-content-between align-items-center">
              <h3 className="mb-0 font16x500">Account Details</h3>
              <p
                className="mb-0 text-decoration-underline font14x400"
                style={{
                  color: "var(--btn_primary)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  functions.onEdit(paymentDetails[0]);
                }}
              >
                Edit
              </p>
            </div>
            <div className="mobileCardBody pt-2">
              <div className="d-flex justify-content-between align-items-center">
                <p
                  className="mb-2 font12x400"
                  style={{
                    color: "var(--text5)",
                  }}
                >
                  A/C No:
                </p>
                <p className="mb-2 font14x400">
                  {paymentDetails[0]?.accountNumber}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p
                  className="mb-2 font12x400"
                  style={{
                    color: "var(--text5)",
                  }}
                >
                  Bank:
                </p>
                <p className="mb-2 font14x400">{paymentDetails[0]?.bankName}</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p
                  className="mb-2 font12x400"
                  style={{
                    color: "var(--text5)",
                  }}
                >
                  IFSC:
                </p>
                <p className="mb-2 font14x400">{paymentDetails[0]?.ifscCode}</p>
              </div>
            </div>
          </div>

          <div className="mobileCardWrapper mb-3">
            <div className="mobileCardHeading d-flex justify-content-between align-items-center">
              <h3 className="mb-0 font16x500">Payment Method</h3>
            </div>
            <div className="mobileCardBody pt-2">
              <div className="d-flex justify-content-between align-items-center">
                <p
                  className="mb-2 font12x400"
                  style={{
                    color: "var(--text5)",
                  }}
                >
                  Online Payment via Getlook, COD
                </p>
              </div>
            </div>
          </div>

          <div className="mobileCardWrapper mb-3">
            <div className="mobileCardHeading d-flex justify-content-between align-items-center">
              <h3 className="mb-0 font16x500">Account Details</h3>
            </div>
            <div className="mobileCardBody pt-2">
              <div className="d-flex justify-content-between align-items-center">
                <p
                  className="mb-2 font12x400"
                  style={{
                    color: "var(--text5)",
                  }}
                >
                  PAN Card:
                </p>
                <p className="mb-2 font14x400">
                  {paymentDetails[0]?.panNumber}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p
                  className="mb-2 font12x400"
                  style={{
                    color: "var(--text5)",
                  }}
                >
                  GST:
                </p>
                <p className="mb-2 font14x400">{paymentDetails[0]?.gstId}</p>
              </div>
            </div>
          </div>

          <div className="mobileCardWrapper mb-3">
            <div className="mobileCardHeading d-flex justify-content-between align-items-center">
              <h3 className="mb-0 font16x500">Legal Entity Name</h3>
            </div>
            <div className="mobileCardBody pt-2">
              <div className="d-flex justify-content-between align-items-center">
                <p
                  className="mb-2 font12x400"
                  style={{
                    color: "var(--text5)",
                  }}
                >
                  {paymentDetails[0]?.legalEntitiesName}
                </p>
              </div>
            </div>
          </div>

          <div className="mobileCardWrapper mb-3">
            <div className="mobileCardHeading d-flex justify-content-between align-items-center">
              <h3 className="mb-0 font16x500">Download Documents</h3>
            </div>
            <div className="mobileCardBody pt-2">
              {paymentDetails[0]?.panImage && (
                <div className="d-flex align-items-center mb-2">
                  <img src="/Assets/downloadIcon.svg" alt="download" />
                  <a
                    href={`${process.env.REACT_APP_Image_BaseURL}${paymentDetails[0]?.panImage}`}
                    target="_blank"
                  >
                    <p
                      className="mb-0 ms-1 font14x400 text-decoration-underline"
                      style={{
                        color: "var(--btn_primary)",
                      }}
                    >
                      Pan
                    </p>
                  </a>
                </div>
              )}

              {paymentDetails[0]?.gstDocument && (
                <div className="d-flex align-items-center mb-2">
                  <img src="/Assets/downloadIcon.svg" alt="download" />
                  <a
                    href={`${process.env.REACT_APP_Image_BaseURL}${paymentDetails[0]?.gstDocument}`}
                    target="_blank"
                  >
                    <p
                      className="mb-0 ms-1 font14x400 text-decoration-underline"
                      style={{
                        color: "var(--btn_primary)",
                      }}
                    >
                      Gst
                    </p>
                  </a>
                </div>
              )}

              {paymentDetails[0]?.otherDocuments && (
                <div className="d-flex align-items-center mb-2">
                  <img src="/Assets/downloadIcon.svg" alt="download" />
                  <a
                    href={`${process.env.REACT_APP_Image_BaseURL}${paymentDetails[0]?.otherDocuments}`}
                    target="_blank"
                  >
                    <p
                      className="mb-0 ms-1 font14x400 text-decoration-underline"
                      style={{
                        color: "var(--btn_primary)",
                      }}
                    >
                      Other
                    </p>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* payment form */}
      {commonData.modal && (
        <PaymentForm
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
          salonId={salonDetails._id}
          paymentId={paymentDetails[0] && paymentDetails[0]._id}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default Payment;
