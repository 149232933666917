import React, { useEffect, useRef, useState } from "react";
import { CgClose } from "react-icons/cg";
import { useFormik } from "formik";
import { editSalonSchema } from "../../utils/validationschema";
import InputField from "../RegistrationForm/sub components/InputField";
import InputError from "../../Shared Components/InputError";
import { toast } from "react-toastify";
import DynamicDropdown from "../RegistrationForm/sub components/DynamicDropdown";
import {
  ADMIN,
  authFormHeader,
  MASTER_SALON,
  salonType,
  SALON_CATEGORY,
  SALON_UPDATE,
} from "../../utils/Constants";
import SubmitButton from "../../Shared Components/SubmitButton";
import useLoading from "../../hooks/useLoading";
import axios from "axios";
import useCategory from "../../hooks/useCategory";
import CategorySelection from "./CategorySelection";
import TimeSelection from "./salon timings/TimeSelection";
import useAuthContext from "../../hooks/useAuthContext";
import StaticDropdown from "../../Shared Components/StaticDropdown";
import Amenities from "../RegistrationForm/sub components/Amenities";
import Location from "../EditAddressSalon/LocationMap";

const EditSalon = ({ state, setState, apiCall }) => {
  const { token, role } = useAuthContext();
  const salonNew = JSON.parse(localStorage.getItem("salon_details"));
  const [isChange, setIsChange] = useState(false);

  const [newAddress, setNewAddress] = useState({
    city: state.data?.address?.city ?? "",
    state: state.data?.address?.state ?? "",
    country: state.data?.address?.country ?? "",
    pincode: state.data?.address?.pincode ?? "",
    lat: state.data?.lat ?? salonNew?.location?.lat,
    lng: state.data?.lng ?? salonNew?.location?.lng,
    locationName: state.data?.locationName ?? salonNew?.location?.locationName,
  });

  const [salonDetails, setSalonDetails] = useState({
    salonCategory: [],
    timings: state?.data?.timings ?? [],
  });
  // console.log("salonDetails", salonDetails);

  useEffect(() => {
    state?.data?.category?.map((item, i) => {
      return setSalonDetails((old) => {
        return {
          ...old,
          salonCategory: [...old?.salonCategory, item?._id],
        };
      });
    });
  }, [state?.data?.category]);

  const initialValues = {
    logo: "",
    salonName: state?.data?.name ?? "",
    salonDescription: state?.data?.description ?? "",
    images: [],
    salonEmail: state?.data?.email ?? "",
    discount: state?.data?.discount ?? 0,
    salonContactNumber: state?.data?.contactNumber ?? "",
    commision_product: state?.data?.commision_product ?? 0,
    commision_serviceAtHome: state?.data?.commision_serviceAtHome ?? 0,
    commision_serviceAtSalon: state?.data?.commision_serviceAtSalon ?? 0,
    line_1: state?.data?.address?.line_1 ?? "",
    line_2: state?.data?.address?.line_2 ?? "",
    // city: state?.data?.address?.city ?? "",
    // state: state?.data?.address?.state ?? "",
    // country: state?.data?.address?.country ?? "",
    // pincode: state?.data?.address?.pincode ?? "",
    salonType: state?.data?.type ?? "",
    classType: state?.data?.classType ?? "",
    priceRating: state?.data?.priceRating ?? "",
    isAC: state?.data?.amenities?.isAC ? true : false,
    isCardPayment: state?.data?.amenities?.isCardPayment ? true : false,
    isToilets: state?.data?.amenities?.isToilets ? true : false,
    isParkingAvailable: state?.data?.amenities?.isParkingAvailable
      ? true
      : false,
    isUber: state?.data?.amenities?.isUber ? true : false,
  };
  const [oldImagePath, setOldImagePath] = useState({
    logo: "null",
    allImages: state?.data?.images ? state?.data?.images : [],
    viewImages: state?.data?.images ? state?.data?.images : [],
    updateImages: [],
  });
  console.log("oldImagePath", oldImagePath);

  const formik = useFormik({
    initialValues,
    validationSchema: editSalonSchema,
    onSubmit: (data) => {
      // console.log("data", data);
      formSubmit(data);
    },
  });

  //custom hook for getting category
  const [category, loaderSmall, getCategory] = useCategory({
    url: SALON_CATEGORY,
    resultField: "salonCategories",
  });
  useEffect(() => {
    getCategory();
  }, []);

  const [loader, showLoader, hideLoader] = useLoading();

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const salon_logo = useRef(null);
  const salon_photos = useRef(null);
  const handleSingleImg = (e) => {
    e.preventDefault();
    console.log("e", e);
    salon_logo.current.click();
  };
  const handleMultipleImg = (e) => {
    e.preventDefault();
    salon_photos.current.click();
  };

  const [singleImage, setSingleImage] = useState(
    state?.data?.logo
      ? `${process.env.REACT_APP_Image_BaseURL}${state?.data?.logo}`
      : ""
  );
  const imageDataURL = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSingleImage(URL.createObjectURL(e.target.files[0]));
      formik.setFieldValue("logo", Array.from(e.target.files));
    }
  };

  const [multiImage, setMultiImage] = useState([]);
  console.log("multiImage", multiImage);
  // useEffect(() => {
  // let urls = [];
  // formik.values.images.forEach((file) => {
  //   urls.push(URL.createObjectURL(file));
  // });
  // setMultiImage(urls);
  // setMultiImage(oldImagePath.viewImages.concat(urls));
  // }, [formik.values.images]);
  const setImagePathToViewImages = (selectedFile) => {
    console.log("selected file: ", selectedFile);
    let urls = [];
    selectedFile?.forEach((file) => {
      urls.push(URL.createObjectURL(file));
      setOldImagePath((old) => {
        return {
          ...old,
          viewImages: [...old.viewImages, file.name],
        };
      });
    });
    let urls2 = urls.toString().split(",");
    urls2?.map((item) => {
      setMultiImage((old) => [...old, item]);
    });
  };

  useEffect(() => {
    oldImagePath.viewImages?.length !== 0 &&
      oldImagePath.viewImages?.map((item) => {
        setMultiImage((old) => [
          ...old,
          `${process.env.REACT_APP_Image_BaseURL}${item}`,
        ]);
      });
  }, []);

  const deleteImage = (index) => {
    if (index === -1) {
      formik.setFieldValue("logo", "");
      setSingleImage("");
      setOldImagePath({
        ...oldImagePath,
        logo: state?.data?.logo ? state?.data?.logo : null,
      });
      salon_logo.current.value = "";
    } else {
      let newDeleteString = oldImagePath.viewImages[index];
      let newDeleteStringUrl = multiImage[index];
      console.log("newDeleteString", newDeleteString);
      let filterImage = multiImage.filter((ele, i) => {
        return ele !== newDeleteStringUrl;
      });
      setMultiImage(filterImage);

      let filterImage2 = oldImagePath.viewImages.filter((ele, i) => {
        return ele !== newDeleteString;
      });
      setOldImagePath((old) => {
        return {
          ...old,
          viewImages: filterImage2,
        };
      });

      if (oldImagePath.allImages.indexOf(newDeleteString) > -1) {
        setOldImagePath((old) => {
          return {
            ...old,
            updateImages: [...old.updateImages, newDeleteString],
          };
        });
      }

      let filterImage3 = formik.values.images.filter((ele, i) => {
        return ele.name !== newDeleteString;
      });
      formik.setFieldValue("images", filterImage3);
    }
  };

  const formSubmit = async (data) => {
    const rFormData = new FormData();
    rFormData.append("logo", data.logo[0]);
    rFormData.append("name", data.salonName);
    rFormData.append("description", data.salonDescription);
    if (data.images.length !== 0) {
      for (let i = 0; i < data.images.length; i++) {
        rFormData.append("images", data.images[i]);
      }
    }
    for (let i = 0; i < salonDetails.timings.length; i++) {
      rFormData.append("timings", salonDetails.timings[i]);
    }
    rFormData.append("email", data.salonEmail);

    if (role === ADMIN) {
      rFormData.append("discount", data.discount);
      rFormData.append("contactNumber", data.salonContactNumber);
      rFormData.append("commision_product", data.commision_product);
      rFormData.append("commision_serviceAtHome", data.commision_serviceAtHome);
    }

    rFormData.append("commision_serviceAtSalon", data.commision_serviceAtSalon);
    rFormData.append("line_1", data.line_1);
    rFormData.append("line_2", data.line_2);
    rFormData.append("city", newAddress?.city);
    rFormData.append("state", newAddress?.state);
    rFormData.append("country", newAddress?.country);
    rFormData.append("pincode", newAddress?.pincode);
    rFormData.append("lat", newAddress?.lat);
    rFormData.append("lng", newAddress?.lng);
    rFormData.append("locationName", newAddress?.locationName);
    rFormData.append("type", data.salonType);
    rFormData.append("isAC", data.isAC);
    rFormData.append("isCardPayment", data.isCardPayment);
    rFormData.append("isToilets", data.isToilets);
    rFormData.append("isParkingAvailable", data.isParkingAvailable);
    rFormData.append("isUber", data.isUber);
    rFormData.append("classType", data.classType);
    rFormData.append("priceRating", data.priceRating);
    for (let i = 0; i < salonDetails.salonCategory.length; i++) {
      rFormData.append("category", salonDetails.salonCategory[i]);
    }
    if (state.data) {
      rFormData.append("updateLogo", oldImagePath.logo);
      if (data.images.length !== 0 || oldImagePath.updateImages.length !== 0) {
        rFormData.append("allImages", JSON.stringify(oldImagePath.allImages));
      }
      if (oldImagePath.updateImages.length !== 0) {
        rFormData.append(
          "updateImages",
          JSON.stringify(oldImagePath.updateImages)
        );
      }
    }

    if (token) {
      try {
        showLoader();
        const res = await axios.put(
          `${SALON_UPDATE}${state?.data?._id}/form`,
          rFormData,
          {
            //headers: authFormHeader,
            headers: {
              "Content-type": "multipart/form-data",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );

        if (res.status === 200) {
          hideLoader();
          setPositionToBody();
          apiCall();
          toast.success("Store Details Updated", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          console.log("error", res.status);
        }
      } catch (error) {
        console.log("catch error: ", error);
        if (error.response.status === 502) {
          hideLoader();
          toast.error(error.response.data.message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div
            className="formHeadingDiv"
            style={{ zIndex: isChange ? "0" : "10" }}
          >
            <h1 className="font22x500 mb-0">Edit Store</h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit}>
              {/* salon name */}
              <div className="mb-3">
                <InputField
                  label="Store Name*"
                  htmlFor="salonname"
                  type="text"
                  name="salonName"
                  registrationData={formik.values.salonName}
                  setRegistrationData={formik.handleChange}
                />
                {formik.errors.salonName ? (
                  <InputError error={formik.errors.salonName} />
                ) : null}
              </div>
              {/* salon Logo */}
              <div className="mb-3">
                <div className="mb-0">
                  <label
                    htmlFor="formFile"
                    className="font12x500 mb-1 form-label"
                  >
                    Store Logo
                  </label>
                  <input
                    ref={salon_logo}
                    className="form-control shadow-none"
                    type="file"
                    id="formFile"
                    name="logo"
                    accept={".png, .jpg, .jpeg"}
                    hidden
                    onChange={(e) => {
                      imageDataURL(e);
                    }}
                  />
                </div>
                <div className="uploadImg d-flex align-items-center mb-2">
                  <div
                    className="uploadBtn font12x400 me-3"
                    name="single_photo"
                    onClick={(e) => {
                      handleSingleImg(e);
                    }}
                  >
                    Upload
                  </div>
                  <span className="font12x400">
                    Allowed file types: jpg, jpeg, png
                  </span>
                </div>
                {singleImage && (
                  <div className="imageWrapper">
                    <img
                      src={singleImage}
                      alt="delete"
                      width={61}
                      height={61}
                    />
                    <div
                      className="deleteDiv"
                      onClick={() => {
                        deleteImage(-1);
                      }}
                    >
                      <img src="/Assets/delete.svg" alt="delete" />
                    </div>
                  </div>
                )}
                {formik.errors.logo ? (
                  <InputError error={formik.errors.logo} />
                ) : null}
              </div>
              {/* salon description */}
              <div className="mb-3">
                <label
                  htmlFor="floatingTextarea2"
                  className="font12x500 mb-1 form-label"
                >
                  Description (optional)
                </label>
                <textarea
                  className="form-control shadow-none"
                  id="floatingTextarea2"
                  style={{ height: "100px" }}
                  name="salonDescription"
                  value={formik.values.salonDescription}
                  onChange={formik.handleChange}
                />
                {formik.errors.salonDescription ? (
                  <InputError error={formik.errors.salonDescription} />
                ) : null}
              </div>
              {/* salon Photos */}
              <div className="mb-3">
                <div className="mb-0">
                  <label
                    htmlFor="formFile"
                    className="font12x500 mb-1 form-label"
                  >
                    Store Photos
                  </label>
                  <input
                    ref={salon_photos}
                    className="form-control shadow-none"
                    key={Math.random()}
                    type="file"
                    id="formFile"
                    name="images"
                    hidden
                    accept={".png, .jpg, .jpeg"}
                    multiple
                    onChange={(e) => {
                      const selectedFile = e.target.files;
                      if (
                        selectedFile &&
                        selectedFile.length <=
                          5 - oldImagePath.viewImages.length
                      ) {
                        // const newFiles = Array.from(selectedFile);
                        // setRegistrationData({
                        //   ...registrationData,
                        //   images: [...registrationData.images, ...newFiles],
                        // });
                        formik.setFieldValue(
                          "images",
                          formik.values.images.concat(Array.from(selectedFile))
                        );
                        setImagePathToViewImages(Array.from(selectedFile));
                      } else {
                        toast.error("Maximum Limit Exceeded", {
                          position: "bottom-center",
                          autoClose: 1000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                        });
                      }
                    }}
                  />
                </div>
                <div className="uploadImg d-flex align-items-center mb-2">
                  <div
                    className="uploadBtn font12x400 me-3"
                    name="multi_photo"
                    onClick={(e) => {
                      handleMultipleImg(e);
                    }}
                  >
                    Upload
                  </div>
                  <div className="d-flex flex-column">
                    <span className="font12x400">
                      Allowed file types: jpg, jpeg, png
                    </span>
                    <span className="font12x400">
                      You can upload max 5 photos
                    </span>
                  </div>
                </div>
                {multiImage && (
                  <div className="imageContainer">
                    {multiImage?.map((item, i) => {
                      return (
                        <div className="imageWrapper" key={i}>
                          <img src={item} alt="delete" width={61} height={61} />
                          <div
                            className="deleteDiv"
                            onClick={() => {
                              deleteImage(i);
                            }}
                          >
                            <img src="/Assets/delete.svg" alt="delete" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {formik.errors.images ? (
                  <InputError error={formik.errors.images} />
                ) : null}
              </div>
              {/* salon email */}
              <div className="mb-3">
                <InputField
                  label="Email address (optional)"
                  htmlFor="salonemail"
                  type="email"
                  name="salonEmail"
                  registrationData={formik.values.salonEmail}
                  setRegistrationData={formik.handleChange}
                />
                {formik.errors.salonEmail ? (
                  <InputError error={formik.errors.salonEmail} />
                ) : null}
              </div>
              {/* salon contact number */}
              <div className="mb-3">
                <InputField
                  label="Store Contact No*"
                  htmlFor="sCNo"
                  type="tel"
                  name="salonContactNumber"
                  registrationData={formik.values.salonContactNumber}
                  setRegistrationData={formik.handleChange}
                  readOnly={role === MASTER_SALON && true}
                />
                {formik.errors.salonContactNumber ? (
                  <InputError error={formik.errors.salonContactNumber} />
                ) : null}
              </div>

              {/* Discount */}
              {role === ADMIN && (
                <>
                  <div className="mb-3">
                    <InputField
                      label="Discount (%)"
                      htmlFor="discount"
                      type="tel"
                      name="discount"
                      registrationData={formik.values.discount}
                      setRegistrationData={formik.handleChange}
                    />
                    {formik.errors.discount ? (
                      <InputError error={formik.errors.discount} />
                    ) : null}
                  </div>

                  {/* commision product */}
                  <div className="mb-3">
                    <InputField
                      label="Product Commision (%)"
                      htmlFor="pCommision"
                      type="tel"
                      name="commision_product"
                      registrationData={formik.values.commision_product}
                      setRegistrationData={formik.handleChange}
                    />
                    {formik.errors.commision_product ? (
                      <InputError error={formik.errors.commision_product} />
                    ) : null}
                  </div>

                  {/* Service At Home Commision */}
                  <div className="mb-3">
                    <InputField
                      label="Service At Home Commision (%)"
                      htmlFor="sHCommision"
                      type="tel"
                      name="commision_serviceAtHome"
                      registrationData={formik.values.commision_serviceAtHome}
                      setRegistrationData={formik.handleChange}
                    />
                    {formik.errors.commision_serviceAtHome ? (
                      <InputError
                        error={formik.errors.commision_serviceAtHome}
                      />
                    ) : null}
                  </div>

                  {/* Service At Salon Commision */}
                  <div className="mb-3">
                    <InputField
                      label="Service At Salon Commision (%)"
                      htmlFor="sSCommision"
                      type="tel"
                      name="commision_serviceAtSalon"
                      registrationData={formik.values.commision_serviceAtSalon}
                      setRegistrationData={formik.handleChange}
                    />
                    {formik.errors.commision_serviceAtSalon ? (
                      <InputError
                        error={formik.errors.commision_serviceAtSalon}
                      />
                    ) : null}
                  </div>
                </>
              )}

              {/* select location is not needed right now (Google MAP) */}
              <Location
                setIsChange={setIsChange}
                newAddress={newAddress}
                setNewAddress={setNewAddress}
              />
              {/* line_1 */}
              <div className="mb-3">
                <InputField
                  label="Address Line 1*"
                  htmlFor="line1"
                  type="text"
                  name="line_1"
                  registrationData={formik.values.line_1}
                  setRegistrationData={formik.handleChange}
                />
                {formik.errors.line_1 ? (
                  <InputError error={formik.errors.line_1} />
                ) : null}
              </div>
              {/* line_2 */}
              <div className="mb-3">
                <InputField
                  label="Address Line 2 (optional)"
                  htmlFor="line2"
                  type="text"
                  name="line_2"
                  registrationData={formik.values.line_2}
                  setRegistrationData={formik.handleChange}
                />
                {formik.errors.line_2 ? (
                  <InputError error={formik.errors.line_2} />
                ) : null}
              </div>
              {/* city */}
              <div className="mb-3">
                <InputField
                  label="City*"
                  htmlFor="city"
                  type="text"
                  name="city"
                  registrationData={newAddress?.city}
                  readOnly={true}
                />
              </div>
              {/* state */}
              <div className="mb-3">
                <InputField
                  label="State*"
                  htmlFor="state"
                  type="text"
                  name="state"
                  registrationData={newAddress?.state}
                  readOnly={true}
                />
              </div>
              {/* country */}
              <div className="mb-3">
                <label htmlFor="country" className="font12x500 form-label mb-1">
                  Country*
                </label>
                <input
                  type="text"
                  className="form-control shadow-none"
                  id="country"
                  name="country"
                  value={newAddress?.country}
                  readOnly={true}
                />
              </div>
              {/* pincode */}
              <div className="mb-3">
                <InputField
                  label="Pincode*"
                  htmlFor="pin"
                  type="tel"
                  name="pincode"
                  registrationData={newAddress?.pincode}
                  readOnly={true}
                />
              </div>
              {/* salon timings */}
              <TimeSelection
                timings={salonDetails?.timings}
                setTimings={(e) => {
                  setSalonDetails({
                    ...salonDetails,
                    timings: e,
                  });
                }}
              />
              {/* categories */}
              <CategorySelection
                data={category}
                registrationData={salonDetails}
                setRegistrationData={setSalonDetails}
              />
              {/* type of salon */}
              <div className="mb-3">
                <DynamicDropdown
                  data={salonType}
                  label="Type of Store*"
                  htmlFor="state"
                  type="text"
                  name="salonType"
                  placeholder="Select Type"
                  stateData={formik.values.salonType}
                  setStateData={(e) => {
                    formik.setFieldValue("salonType", e);
                  }}
                />
                {formik.errors.salonType ? (
                  <InputError error={formik.errors.salonType} />
                ) : null}
              </div>
              {/* Class of Salon* */}
              <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: "Luxury", label: "Luxury" },
                    { value: "Premium", label: "Premium" },
                    { value: "Economy", label: "Economy" },
                  ]}
                  label="Class of Store*"
                  htmlFor="classType"
                  type="text"
                  placeholder="Select Class"
                  name="classType"
                  stateData={formik.values.classType}
                  setStateData={(e) => {
                    formik.setFieldValue("classType", e);
                  }}
                />
                {formik.errors.classType ? (
                  <InputError error={formik.errors.classType} />
                ) : null}
              </div>
              {/* Price rating* */}
              <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: 1, label: "1" },
                    { value: 2, label: "2" },
                    { value: 3, label: "3" },
                  ]}
                  label="Price Rating*"
                  htmlFor="priceRating"
                  type="text"
                  placeholder="Select Price Rating"
                  name="priceRating"
                  stateData={formik.values.priceRating}
                  setStateData={(e) => {
                    formik.setFieldValue("priceRating", e);
                  }}
                />
                {formik.errors.priceRating ? (
                  <InputError error={formik.errors.priceRating} />
                ) : null}
              </div>
              {/* amenities */}
              <div className="categoryCheck">
                <div className="mb-3">
                  <label className="font12x500 form-label mb-1">
                    Amenities (Optional)
                  </label>
                  <div className="checkBoxContainer">
                    <Amenities
                      value="AC"
                      check={formik.values.isAC}
                      setRegistrationData={(e) => {
                        formik.setFieldValue("isAC", e);
                      }}
                    />
                    <Amenities
                      value="Card Payment"
                      check={formik.values.isCardPayment}
                      setRegistrationData={(e) => {
                        formik.setFieldValue("isCardPayment", e);
                      }}
                    />
                    <Amenities
                      value="Toilets"
                      check={formik.values.isToilets}
                      setRegistrationData={(e) => {
                        formik.setFieldValue("isToilets", e);
                      }}
                    />
                    <Amenities
                      value="Parking Available"
                      check={formik.values.isParkingAvailable}
                      setRegistrationData={(e) => {
                        formik.setFieldValue("isParkingAvailable", e);
                      }}
                    />
                    <Amenities
                      value="Uber"
                      check={formik.values.isUber}
                      setRegistrationData={(e) => {
                        formik.setFieldValue("isUber", e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <SubmitButton
                data={!state.data}
                loader={loader}
                create="Submit"
                update="Update"
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSalon;
