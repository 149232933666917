import React, { useState, useEffect } from "react";
import Select from "react-select";

const DropdownSmallDate = (props) => {
  const { data, htmlFor, label, stateData, setStateData, width } = props;

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (data) {
      setOptions(data);
    }
  }, [data]);

  const styles = {
    container: (styles) => {
      return {
        ...styles,
        height: 32,
      };
    },
    control: (styles, { isFocused }) => {
      return {
        ...styles,
        borderRadius: "4px",
        textAlign: "left",
        border: "1px solid var(--border_primary)",
        border: isFocused
          ? "1px solid var(--border_primary)"
          : "1px solid var(--border_primary)",
        boxShadow: "none",
        cursor: "pointer",
        minHeight: 32,
        height: 32,
      };
    },
    indicatorSeparator: (styles) => {
      return {
        display: "none",
      };
    },
    IndicatorsContainer: (styles) => {
      return {
        ...styles,
        height: 32,
      };
    },
    placeholder: (styles) => {
      return {
        ...styles,
        fontSize: "14px",
        display: "none",
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        fontSize: "14px",
        textTransform: "capitalize",
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        borderRadius: "4px",
        margin: 0,
        backgroundColor: "var(--border_secondary)",
      };
    },
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        color: "#000",
        backgroundColor: isSelected && "#fff",
        borderBottom: "1px solid var(--border_primary)",
        textAlign: "left",
        margin: 0,
        cursor: "pointer",
        fontSize: "14px",
        padding: "8px 11px",
        textTransform: "capitalize",
        ":last-child": {
          borderBottom: "none",
        },
      };
    },
  };

  return (
    <>
      <div
        className="selectDropdownSmall"
        style={{
          width: width,
        }}
      >
        {label && (
          <label htmlFor={htmlFor} className="font12x400 form-label mb-1">
            {label}
          </label>
        )}
        <Select
          options={options}
          styles={styles}
          value={options.filter((option) => {
            return option.label === stateData;
          })}
          onChange={(e) => {
            setStateData(e.value1, e.value2, e.label);
          }}
        />
      </div>
    </>
  );
};

export default DropdownSmallDate;
