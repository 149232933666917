import moment from "moment";
import { useEffect, useState } from "react";
import FilterRadioButton from "../../../../Shared Components/FilterRadioButton";
import InputDate from "../../../../Shared Components/InputDate";
import SearchInput2 from "../../../../Shared Components/SearchInput2";
import { paymentMode, paymentStatus } from "../../../../utils/Constants";

function MobileTransactionFilters(props) {
  const {
    filters,
    setFilter,
    resetParam,
    addParam,
    param,
    setParam,
    searchField,
  } = props;

  const [animateFilter, setAnimateFilter] = useState({
    transform: "translate(100%)",
  });

  useEffect(() => {
    if (props.isOpenFilter) {
      setAnimateFilter({
        transform: "translate(0%)",
      });
    } else {
      setAnimateFilter({
        transform: "translate(100%)",
      });
    }
  }, [props.isOpenFilter]);

  return (
    <div style={animateFilter} className="mobile-bydate-filters-container">
      <div
        onClick={() => {
          props.setIsOpenFilter(false);
        }}
        className="left-filter-section"
      ></div>
      <div className="right-filter-section">
        <div className="right-filter-sub-container pt-3 pb-3">
          <p className="title">Filter</p>

          <div className="mb-4">
            <SearchInput2
              type="text"
              placeholder="Search by transaction id"
              stateData={param}
              setStateData={setParam}
              parameter1={searchField}
              parameter2={undefined}
              width="auto"
              addParam={addParam}
              resetParam={resetParam}
            />
          </div>

          <div>
            <button className="bydate-filter-button d-flex justify-content-between align-items-center bg-white border-0 w-100">
              <p className="mb-0 font16x500">Payment Status</p>
              <p className="mb-0 font16x500">-</p>
            </button>

            <div className="right-date-filter-container">
              {/* radio buttons */}
              {paymentStatus?.map((item, i) => {
                return (
                  <FilterRadioButton
                    key={i}
                    label={item?.label}
                    htmlFor={item?.label}
                    name="status"
                    stateData={item?.value}
                    setStateData={(e) => {
                      setFilter({
                        ...filters,
                        paymentStatus: e,
                      });
                    }}
                  />
                );
              })}
            </div>
          </div>

          <div>
            <button className="bydate-filter-button d-flex justify-content-between align-items-center bg-white border-0 w-100">
              <p className="mb-0 font16x500">Payment Mode</p>
              <p className="mb-0 font16x500">-</p>
            </button>

            <div className="right-date-filter-container">
              {/* radio buttons */}
              {paymentMode?.map((item, i) => {
                return (
                  <FilterRadioButton
                    key={i}
                    label={item?.label}
                    htmlFor={item?.label}
                    name="status"
                    stateData={item?.value}
                    setStateData={(e) => {
                      setFilter({
                        ...filters,
                        payment: e,
                      });
                    }}
                  />
                );
              })}
            </div>
          </div>

          <div className="">
            <button
              className="bydate-filter-button d-flex justify-content-between align-items-center bg-white border-0 w-100"
              style={{
                borderBottom: "1px solid var(--border)",
              }}
            >
              <p className="mb-0">By Date</p>
              <p className="mb-0">-</p>
            </button>

            <div className="right-date-filter-container">
              <div className="mb-2">
                <InputDate
                  label="From"
                  htmlFor="from"
                  type="date"
                  stateData={param?.fromDate}
                  setStateData={(e) => {
                    setParam({
                      ...param,
                      fromDate: moment(e).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </div>
              <div>
                <InputDate
                  label="To"
                  htmlFor="to"
                  type="date"
                  stateData={param?.tillDate}
                  setStateData={(e) => {
                    setParam({
                      ...param,
                      tillDate: moment(e).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div>
            <button className="apply" onClick={addParam}>
              Apply
            </button>
            <button className="reset" onClick={resetParam}>
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileTransactionFilters;
