import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { disableSlotSchema } from "../../../utils/validationschema";
import DateSelection from "./DateSelection";
import { useFormik } from "formik";
import InputError from "../../../Shared Components/InputError";
import useFetch from "../../../hooks/useFetch";
import {
  authHeader,
  SLOT_DISABLE,
  SLOT_LIST_BY_SALONID,
} from "../../../utils/Constants";
import { toast } from "react-toastify";
import axios from "axios";
import useAuthContext from "../../../hooks/useAuthContext";
import Loader from "../../Loader";
import useLoading from "../../../hooks/useLoading";
import BlockAllSlots from "./BlockAllSlots";
import moment from "moment";
import SlotTimeSelection from "./SlotTimeSelection";
import InputFieldLarge from "../../../Shared Components/InputFieldLarge";

const SlotDisableForm = ({ state, setState, apiCall }) => {
  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
      check: "",
    });
    document.body.style.overflow = "unset";
  };

  const [disableSlotData, setDisableSlotData] = useState({
    date: "",
    time: [],
    blockAllStots: false,
  });
  // console.log("disableSlotData", disableSlotData);

  const [allSlotsBlock, setAllSlotsBlock] = useState([]);

  const { salonDetails, token } = useAuthContext();

  const [slotv2, totalResult, error, loader, getData] = useFetch({
    url: `${SLOT_LIST_BY_SALONID}${salonDetails?._id}?date=${disableSlotData.date}`,
    resultField: "slotv2",
  });

  const initialValues = {
    reason: "",
    // date: "",
    // time: [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: disableSlotSchema,
    onSubmit: (data) => {
      formSubmit(data);
      // console.log("data", data);
    },
  });

  const [loader2, showLoader, hideLoader] = useLoading();

  const formSubmit = async (data) => {
    console.log("form submit called");

    if (token) {
      try {
        showLoader();
        const res = await axios.put(
          `${SLOT_DISABLE}${salonDetails?._id}/slots`,
          {
            date: disableSlotData.date,
            slots: disableSlotData.time,
            reason: data.reason,
          },
          {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );

        if (res.status === 200) {
          setPositionToBody();
          apiCall();
          hideLoader();
          toast.success("Slot Disabled", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          console.log("else error: ", res.status);
        }
      } catch (error) {
        console.log("catch error: ", error);
        if (error?.response?.status === 502) {
          hideLoader();
          toast.error(error?.response?.data?.message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    }
  };

  const checkSlotStatus = (slots) => {
    let check = false;

    if (allSlotsBlock?.length === slots?.length) {
      check = true;
    } else {
      check = false;
    }

    return check;
  };

  useEffect(() => {
    if (disableSlotData.date !== "") getData();
  }, [disableSlotData.date]);

  useEffect(() => {
    setAllSlotsBlock([]);
    slotv2?.slots?.map((item) => {
      if (item.status === 0) {
        return setAllSlotsBlock((old) => {
          return [...old, item];
        });
      }
    });
  }, [slotv2]);

  useEffect(() => {
    setDisableSlotData({
      ...disableSlotData,
      blockAllStots: checkSlotStatus(slotv2?.slots),
      time: slotv2?.slots,
    });
  }, [allSlotsBlock]);

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">{"Block Slot"}</h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-4">
                <DateSelection
                  state={disableSlotData}
                  setState={setDisableSlotData}
                  data={slotv2?.date}
                />
              </div>

              {/* block all slots */}
              {disableSlotData.date !== "" && (
                <BlockAllSlots
                  state={disableSlotData}
                  setState={setDisableSlotData}
                  data={slotv2?.slots}
                />
              )}

              {disableSlotData.date !== "" && (
                <div className="mb-3">
                  <SlotTimeSelection
                    data={slotv2?.slots}
                    loader={loader}
                    state={disableSlotData}
                    setState={setDisableSlotData}
                  />
                </div>
              )}

              {/* Reason */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Reason*"
                  htmlFor="reason"
                  type="text"
                  name="reason"
                  stateData={formik.values.reason}
                  setStateData={formik.handleChange}
                />
                {formik.errors.reason ? (
                  <InputError error={formik.errors.reason} />
                ) : null}
              </div>

              <button type="submit" className="btn OtpBtn shadow-none mt-3">
                {loader2 ? (
                  <div className="d-flex justify-content-center">
                    <Loader height="25" width="25" color="#fff" />
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SlotDisableForm;
