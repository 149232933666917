function MobileSalonDashboardHeader(props) {
  return (
    <div className="mobile-salon-dashboad-header align-items-center justify-content-between p-3">
      <div>
        <button
          onClick={() => {
            props.setIsOpenSideNav(true);
          }}
          className="bg-white border-0"
        >
          <img src="/Assets/hamberger.svg" />
        </button>
      </div>
      <div>
        <p className="mb-0">{props.title}</p>
      </div>

      <div className="search-filter-container d-flex">
        {props.isShowThreeDot ? (
          <button
            onClick={() => {
              props.setIsOpenFilter(true);
            }}
          >
            <img src="/Assets/dots.svg" />
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default MobileSalonDashboardHeader;
