import React, { useState } from "react";
import { getAddedOnDateTime } from "../../Functions/dateTime";
import RenderMap from "../Map/index";
import productImage from "../../uploads/product/product1.jpg";
import { AiOutlineReload } from "react-icons/ai";
import Rating from "./Rating";
import { ADD_REVIEW_ADMIN_ORDER, authHeader } from "../../utils/Constants";
import { toast } from "react-toastify";
import useAuthContext from "../../hooks/useAuthContext";
import useLoading from "../../hooks/useLoading";
import axios from "axios";
import { MdStarRate } from "react-icons/md";
import Loader from "../Loader";

const setValue = (status) => {
  switch (status) {
    case 1:
      return "confirmed";
    case 2:
      return "shipped";
    case 3:
      return "delivered";
    default:
      return "pending";
  }
};

const Summary = ({ orderDetails, apiCall }) => {
  const [orderStatus, setOrderStatus] = useState({
    status: setValue(orderDetails?.orderProcessingStatus),
    number: orderDetails?.orderProcessingStatus,
  });
  console.log("orderStatus", orderStatus);

  const [reviews, setReviews] = useState({
    review: "",
    rating: 0,
  });
  console.log("csd", reviews);

  const { token } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();

  const formSubmit = async (e) => {
    e.preventDefault();
    if (token) {
      try {
        showLoader();
        console.log("update api called");
        const res = await axios.post(
          `${ADD_REVIEW_ADMIN_ORDER}${orderDetails?._id}`,
          {
            rating: reviews.rating,
            review: reviews.review,
          },
          {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );

        if (res.status === 200) {
          apiCall();
          hideLoader();
          toast.success("Reviews Added", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          console.log("else error: ", res.status);
        }
      } catch (error) {
        console.log("catch error: ", error);
        if (error?.response?.status === 502) {
          hideLoader();
          toast.error(error?.response?.data?.message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    }
  };

  return (
    <>
      <div className="container ms-0 ps-0">
        <div className="row gap-lg-0 gap-4">
          {/* Address */}
          <div className="col-lg-7 col-12">
            <div className="cardWrapper">
              <div className="cardWrapperHeader">
                <h3 className="font22x500 mb-0">Address</h3>
              </div>
              <div className="cardContent">
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Store Name
                  </span>
                  <span className="font14x500 text-capitalize">
                    {orderDetails?.salon?.name}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Email Id
                  </span>
                  <span className="font14x500">
                    {orderDetails?.salon?.email ?? "--"}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Phone Number
                  </span>
                  <span className="font14x500">
                    {orderDetails?.salon?.contactNumber}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    GST Number
                  </span>
                  <span className="font14x500">{orderDetails?.gstNumber}</span>
                </div>

                <div className="">
                  <hr />
                  <div className="d-flex justify-content-between">
                    <span
                      className="font14x500"
                      style={{ color: "var(--text4)" }}
                    >
                      Billing Address
                    </span>
                    <span className="font14x500 text-end">
                      <span>{orderDetails?.billingAddress?.line_1}, </span>
                      {orderDetails?.billingAddress?.line_2 && (
                        <span>{orderDetails?.billingAddress?.line_2}, </span>
                      )}
                      <span>{orderDetails?.billingAddress?.city}, </span> <br />
                      <span>{orderDetails?.billingAddress?.state}, </span>
                      <span>{orderDetails?.billingAddress?.country}, </span>
                      <span>{orderDetails?.billingAddress?.pincode}</span>
                    </span>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <span
                      className="font14x500"
                      style={{ color: "var(--text4)" }}
                    >
                      Shipping Address
                    </span>
                    <span className="font14x500 text-end">
                      <span>{orderDetails?.shippingAddress?.line_1}, </span>
                      {orderDetails?.shippingAddress?.line_2 && (
                        <span>{orderDetails?.shippingAddress?.line_2}, </span>
                      )}
                      <span>{orderDetails?.shippingAddress?.city}, </span>{" "}
                      <br />
                      <span>{orderDetails?.shippingAddress?.state}, </span>
                      <span>{orderDetails?.shippingAddress?.country}, </span>
                      <span>{orderDetails?.shippingAddress?.pincode}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* status summary */}
          <div className="col-lg-5 col-12">
            <div className="cardWrapper">
              <div className="cardWrapperHeader">
                <h3 className="font22x500 mb-0">Status Summary</h3>
              </div>
              <div className="cardContent">
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Confirmed
                  </span>
                  {orderDetails?.length !== 0 && (
                    <span className="font14x500">
                      {orderDetails?.orderProcessingTimestamps[1] &&
                      orderDetails?.orderProcessingTimestamps[1] !== null
                        ? getAddedOnDateTime(
                            orderDetails?.orderProcessingTimestamps[1]
                          )
                        : "--"}
                    </span>
                  )}
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Shipped
                  </span>
                  {orderDetails?.length !== 0 && (
                    <span className="font14x500">
                      {orderDetails?.orderProcessingTimestamps[2] &&
                      orderDetails?.orderProcessingTimestamps[2] !== null
                        ? getAddedOnDateTime(
                            orderDetails?.orderProcessingTimestamps[2]
                          )
                        : "--"}
                    </span>
                  )}
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Delivered
                  </span>
                  {orderDetails?.length !== 0 && (
                    <span className="font14x500">
                      {orderDetails?.orderProcessingTimestamps[3] &&
                      orderDetails?.orderProcessingTimestamps[3] !== null
                        ? getAddedOnDateTime(
                            orderDetails?.orderProcessingTimestamps[3]
                          )
                        : "--"}
                    </span>
                  )}
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Cancelled
                  </span>
                  {orderDetails?.length !== 0 && (
                    <span className="font14x500">
                      {orderDetails?.orderProcessingTimestamps[4] &&
                      orderDetails?.orderProcessingTimestamps[4] !== null
                        ? getAddedOnDateTime(
                            orderDetails?.orderProcessingTimestamps[4]
                          )
                        : "--"}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Order Details */}
          <div className="col-lg-7 col-12 g-lg-4">
            <div className="cardWrapper">
              <div className="cardWrapperHeader">
                <h3 className="font22x500 mb-0">Order Details</h3>
              </div>
              <div className="cardContent">
                {orderDetails?.products?.map((item, i) => {
                  return (
                    <div
                      className="servicesContainer row gx-0 pb-3 mb-3"
                      key={i}
                    >
                      <div className="col-lg-7 d-flex">
                        {item?.product?.image === null ? (
                          <img
                            src={productImage}
                            alt="service"
                            width={72}
                            height={72}
                            style={{ borderRadius: "4px" }}
                          />
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_Image_BaseURL}${item?.product?.image}`}
                            alt="service"
                            width={72}
                            height={72}
                            style={{ borderRadius: "4px" }}
                          />
                        )}
                        <div className="ps-2 pt-1">
                          <h3 className="mb-0 font14x500">
                            {item?.product?.name}
                          </h3>
                          <p
                            className="mb-0 font12x400"
                            style={{ color: "var(--text4)" }}
                          >
                            {item?.product?.quantity}
                          </p>
                          <p
                            className="mb-0 font12x400 lh-1"
                            style={{ color: "var(--text4)" }}
                          >
                            Quantity: {item?.count}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-5 d-flex justify-content-between align-items-center">
                        <span
                          className="font14x500"
                          style={{ color: "var(--text4)" }}
                        >
                          {`Product Price x ${item?.count}`}
                        </span>
                        <div>
                          <span className="font14x500 me-2">
                            <s>{`${String.fromCharCode(
                              orderDetails?.products[0].product?.currencySymbol
                            )} ${item?.product?.mrp * item?.count}`}</s>
                          </span>
                          <span>{`${String.fromCharCode(
                            orderDetails?.products[0].product?.currencySymbol
                          )} ${
                            item?.product?.discountPrice * item?.count
                          }`}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* Location */}
          <div className="col-lg-5 col-12 g-lg-4">
            <div className="cardWrapper">
              <div className="cardWrapperHeader">
                <h3 className="font22x500 mb-0">Location</h3>
              </div>
              <div className="cardContent location-cardcontent p-0">
                <div
                  style={{
                    position: "relative",
                    height: "300px",
                  }}
                >
                  <RenderMap
                    google={window.google}
                    latitude={orderDetails?.shippingAddress?.lat}
                    longitude={orderDetails?.shippingAddress?.lng}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* payment summary */}
          <div className="col-lg-5 col-12 g-lg-4">
            <div className="cardWrapper">
              <div className="cardWrapperHeader">
                <h3 className="font22x500 mb-0">Payment Summary</h3>
              </div>
              <div className="cardContent">
                {/* <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Payment Method
                  </span>
                  <span className="font14x500">COD</span>
                </div> */}
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Subtotal (inclusive tax):
                  </span>
                  <span className="font14x500">{`${String.fromCharCode(
                    orderDetails?.products &&
                      orderDetails.products[0].product.currencySymbol
                  )} ${orderDetails?.cartCalculations?.products?.total}`}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Discount
                  </span>
                  <span className="font14x500">{`- ${String.fromCharCode(
                    orderDetails?.products &&
                      orderDetails.products[0].product.currencySymbol
                  )} ${
                    orderDetails?.cartCalculations?.products?.discount
                  }`}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Coupon Discount
                  </span>
                  <span className="font14x500">{`- ${String.fromCharCode(
                    orderDetails?.products &&
                      orderDetails.products[0].product.currencySymbol
                  )} ${
                    orderDetails?.cartCalculations?.products?.couponDiscount
                  }`}</span>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                  <span
                    className="font14x500"
                    style={{ color: "var(--text4)" }}
                  >
                    Total Payable Amount
                  </span>
                  <span className="font14x500">{`${String.fromCharCode(
                    orderDetails?.products &&
                      orderDetails.products[0].product.currencySymbol
                  )} ${
                    orderDetails?.cartCalculations?.products?.grandTotal
                  }`}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Rating & Review */}
          {(orderDetails?.orderProcessingStatus === 3 ||
            orderDetails?.orderProcessingStatus === 4) && (
            <div className="col-lg-7 col-12 g-lg-4">
              <div className="cardWrapper">
                <div className="cardWrapperHeader">
                  <h3 className="font22x500 mb-0">Rate This Order</h3>
                </div>
                {!orderDetails?.adminOrderReviewAndRating ? (
                  <div className="cardContent">
                    <div className="d-flex gap-2 mb-3">
                      <Rating reviews={reviews} setReviews={setReviews} />
                    </div>
                    <div>
                      <textarea
                        className="form-control shadow-none py-2"
                        id="floatingTextarea2"
                        style={{ height: "150px" }}
                        name="description"
                        placeholder="Write a review for this order."
                        value={reviews?.review}
                        onChange={(e) => {
                          setReviews({
                            ...reviews,
                            review: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="mt-3 text-end">
                      <button
                        type="submit"
                        className="btn font14x400 addBtn shadow-none"
                        style={{ borderRadius: "8px" }}
                        onClick={formSubmit}
                      >
                        {loader ? (
                          <div className="d-flex justify-content-center">
                            <Loader height="20" width="20" color="#fff" />
                          </div>
                        ) : (
                          "Submit Review"
                        )}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="cardContent">
                    <div className="mb-4 d-flex align-items-center">
                      <p className="mb-0 font14x400">
                        Thank you for rating! You rated
                      </p>
                      <span className="ms-2">
                        <button
                          className="font14x400 d-flex align-items-center ratingBtn"
                          style={{
                            backgroundColor: "var(--misc_color3)",
                            border: "1px solid var(--btn_primary)",
                            color: "var(--btn_primary)",
                          }}
                        >
                          <span>
                            {orderDetails?.adminOrderReviewAndRating?.rating}
                          </span>
                          <span className="d-flex">
                            <MdStarRate />
                          </span>
                        </button>
                      </span>
                    </div>
                    <div className="viewReview">
                      {orderDetails?.adminOrderReviewAndRating?.review}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* call and order again */}
          {(orderDetails?.orderProcessingStatus === 3 ||
            orderDetails?.orderProcessingStatus === 4) && (
            <div
              className="col-lg-5 col-12 g-lg-4"
              style={{ height: "fit-content" }}
            >
              <div
                className="cardWrapper"
                style={{ backgroundColor: "transparent", boxShadow: "none" }}
              >
                <div
                  className="cardContent"
                  style={{
                    backgroundColor: "#fff",
                    boxShadow: "0px 4px 12px rgb(146 146 146 / 24%",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="d-flex align-items-center">
                    <img src="/Assets/support.svg" alt="" />
                    <div className="lh-sm ms-3">
                      <p className="font16x700 mb-0">
                        Need help with your booking?
                      </p>
                      <p
                        className="font14x400 mb-0"
                        style={{ color: "var(--text5)" }}
                      >
                        Chat with our support executive
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-3 text-center">
                  <button
                    type="submit"
                    className="btn font14x400 addBtn shadow-none"
                    style={{ borderRadius: "8px" }}
                  >
                    <AiOutlineReload className="me-2" />
                    Order Again
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Summary;
