import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";

const Notification = ({ notification, show, setShow }) => {
  const [animate, setAnimate] = useState({});

  useEffect(() => {
    setAnimate({
      opacity: 1,
    });
  }, []);

  const handleClickOnClose = () => {
    setAnimate({
      opacity: 0,
    });
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  const sendToOrderPage = (link) => {
    handleClickOnClose();
    console.log("link", link);
    window.open(link);
  };

  return (
    <>
      <div style={animate} className={show && "notificationWrapper"}>
        <div className="notificationContainer">
          <div className="d-flex">
            <div>
              <p className="font20x700 mb-0" style={{ color: "#772286" }}>
                {/* Groome Order Placed */}
                {notification.title}
              </p>
              <p className="font16x500 mb-0 mt-3">
                {/* Deepanshu Tyagi has placed an order with order Id: 142129. */}
                {notification.body}
              </p>
            </div>
            <div className="ms-4">
              <CgClose
                style={{ fontSize: 25, cursor: "pointer" }}
                onClick={() => handleClickOnClose()}
              />
            </div>
          </div>
          <div className="text-center mt-5">
            <button
              className="btn addBtn shadow-none font16x500"
              onClick={() => sendToOrderPage(notification.link)}
            >
              View Order
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
