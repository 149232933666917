import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import useAuthContext from "../../../hooks/useAuthContext";
import InputFieldLarge from "../../../Shared Components/InputFieldLarge";
import StaticDropdown from "../../../Shared Components/StaticDropdown";
import UpdationDetails from "../../../Shared Components/UpdationDetails";
import {
  authHeader,
  COUPON_ADD,
  COUPON_UPDATE,
} from "../../../utils/Constants";
import { useFormik } from "formik";
import { couponSchema } from "../../../utils/validationschema";
import InputError from "../../../Shared Components/InputError";
import InputDateLarge from "../../../Shared Components/InputdateLarge";
import useLoading from "../../../hooks/useLoading";
import SubmitButton from "../../../Shared Components/SubmitButton";
import DynamicDropdown from "./DynamicDropdown";

const CouponForm = ({ state, setState, apiCall, data }) => {
  const initialValues = {
    title: state?.data?.title ?? "",
    code: state?.data?.code ?? "",
    salon: state?.data?.salon
      ? `${state?.data?.salon?.salonId}_${state?.data?.salon?.name}`
      : "",
    conditionType: state?.data?.conditionType ?? "",
    condition: state?.data?.condition ?? 0,
    minValue: state?.data?.minValue ?? 0,
    order: state?.data?.order ?? 1,
    allowedUsage: state?.data?.allowedUsage ?? 0,
    singleUserLimit: state?.data?.singleUserLimit ?? 0,
    description: state?.data?.description ?? "",
    terms: state?.data?.terms ?? "",
    statusName: state?.data?.isActive === true ? "Active" : "Inactive",
    couponOnWeekends:
      state?.data?.applicableOnWeekends === false ? "No" : "Yes",
    validFrom: state?.data?.validFrom ?? "",
    validTo: state?.data?.validTo ?? "",
  };

  const [salonId, setSalonId] = useState(state?.data?.salon?._id ?? "");
  console.log("salonId", salonId);

  const formik = useFormik({
    initialValues,
    validationSchema: couponSchema,
    onSubmit: (data) => {
      formSubmit(data);
    },
  });

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const { token } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();

  const formSubmit = async (data) => {
    console.log("form submit called");

    if (token) {
      if (state.data === undefined) {
        try {
          showLoader();
          const res = await axios.post(
            `${COUPON_ADD}`,
            {
              title: data.title,
              code: data.code,
              conditionType: data.conditionType,
              condition: data.condition,
              minValue: data?.minValue,
              order: data?.order,
              salon: salonId,
              allowedUsage: data.allowedUsage,
              singleUserLimit: data.singleUserLimit,
              description: data.description,
              terms: data.terms,
              isActive: data.statusName === "Active" ? true : false,
              applicableOnWeekends:
                data.couponOnWeekends === "Yes" ? true : false,
              status: data.statusName === "Active" ? 1 : 2,
              validFrom: new Date(data.validFrom),
              validTo: new Date(data.validTo),
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Coupon Added", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      } else {
        try {
          showLoader();
          console.log("update api called");
          const res = await axios.put(
            `${COUPON_UPDATE}${state?.data?._id}`,
            {
              title: data?.title,
              code: data?.code,
              conditionType: data?.conditionType,
              condition: data?.condition,
              minValue: data?.minValue,
              order: data?.order,
              salon: salonId,
              allowedUsage: data?.allowedUsage,
              singleUserLimit: data?.singleUserLimit,
              description: data?.description,
              terms: data?.terms,
              validFrom: data?.validFrom,
              validTo: data?.validTo,
              isActive: data.statusName === "Active" ? true : false,
              status: data.statusName === "Active" ? 1 : 2,
              applicableOnWeekends:
                data.couponOnWeekends === "Yes" ? true : false,
            },
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setPositionToBody();
            hideLoader();
            apiCall();
            toast.success("Coupon Updated", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">
              {!state.data ? "Create New Coupon" : "Edit Coupon"}
            </h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit}>
              {/* Title* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Title*"
                  htmlFor="title"
                  name="title"
                  type="text"
                  stateData={formik.values.title}
                  setStateData={formik.handleChange}
                />
                {formik.errors.title ? (
                  <InputError error={formik.errors.title} />
                ) : null}
              </div>

              {/* Coupon Code* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Coupon Code*"
                  htmlFor="Coupon Code"
                  type="text"
                  name="code"
                  stateData={formik.values.code}
                  setStateData={formik.handleChange}
                />
                {formik.errors.code ? (
                  <InputError error={formik.errors.code} />
                ) : null}
              </div>

              {/* salon list */}
              <div className="mb-3">
                <DynamicDropdown
                  data={data}
                  setSalonId={setSalonId}
                  label="Salon*"
                  htmlFor="salon"
                  type="text"
                  name="salon"
                  placeholder="Select Salon"
                  stateData={formik.values.salon}
                  setStateData={(e) => {
                    formik.setFieldValue("salon", e);
                  }}
                />
                {formik.errors.salon ? (
                  <InputError error={formik.errors.salon} />
                ) : null}
              </div>

              {/* order */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Order*"
                  htmlFor="order"
                  type="tel"
                  name="order"
                  stateData={formik.values.order}
                  setStateData={formik.handleChange}
                  width="120px"
                />
                {formik.errors.order ? (
                  <InputError error={formik.errors.order} />
                ) : null}
              </div>

              {/* Condition Type */}
              <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: "Percentage", label: "Percentage" },
                    { value: "Discount", label: "Discount" },
                  ]}
                  label="Condition Type*"
                  htmlFor="Condition Type"
                  type="text"
                  placeholder="Condition Type"
                  name="conditionType"
                  stateData={formik.values.conditionType}
                  setStateData={(e) => {
                    formik.setFieldValue("conditionType", e);
                  }}
                />
                {formik.errors.conditionType ? (
                  <InputError error={formik.errors.conditionType} />
                ) : null}
              </div>

              {/* Condition Value* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Condition Value*"
                  htmlFor="Condition Value"
                  type="tel"
                  name="condition"
                  stateData={formik.values.condition}
                  setStateData={formik.handleChange}
                />
                {formik.errors.condition ? (
                  <InputError error={formik.errors.condition} />
                ) : null}
              </div>

              {/* Minimum Order Value (₹)**/}
              <div className="mb-3">
                <InputFieldLarge
                  label="Minimum Order Value (₹)*"
                  htmlFor="minValue"
                  type="tel"
                  name="minValue"
                  stateData={formik.values.minValue}
                  setStateData={formik.handleChange}
                />
                {formik.errors.minValue ? (
                  <InputError error={formik.errors.minValue} />
                ) : null}
              </div>

              {/* valid from  */}
              <div className="mb-3">
                <InputDateLarge
                  htmlFor="validFrom"
                  label="Valid from*"
                  name="validFrom"
                  stateData={moment(formik.values.validFrom).format(
                    "YYYY-MM-DDTHH:mm"
                  )}
                  setStateData={formik.handleChange}
                />
                {formik.errors.validFrom ? (
                  <InputError error={formik.errors.validFrom} />
                ) : null}
              </div>

              {/* valid till  */}
              <div className="mb-3">
                <InputDateLarge
                  htmlFor="validTill"
                  label="Valid Till*"
                  name="validTo"
                  stateData={moment(formik.values.validTo).format(
                    "YYYY-MM-DDTHH:mm"
                  )}
                  setStateData={formik.handleChange}
                />
                {formik.errors.validTo ? (
                  <InputError error={formik.errors.validTo} />
                ) : null}
              </div>

              {/* status */}
              <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: "Active", label: "Active" },
                    { value: "Inactive", label: "Inactive" },
                  ]}
                  label="Status*"
                  htmlFor="status"
                  type="text"
                  placeholder="Select Status"
                  name="statusName"
                  stateData={formik.values.statusName}
                  setStateData={(e) => {
                    formik.setFieldValue("statusName", e);
                  }}
                />
                {formik.errors.statusName ? (
                  <InputError error={formik.errors.statusName} />
                ) : null}
              </div>

              {/* applicable on weekends */}
              <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" },
                  ]}
                  label="Coupon Applicable on Weekend*"
                  htmlFor="couponWeekend"
                  type="text"
                  placeholder="Select Status"
                  name="couponOnWeekends"
                  stateData={formik.values.couponOnWeekends}
                  setStateData={(e) => {
                    formik.setFieldValue("couponOnWeekends", e);
                  }}
                />
                {formik.errors.couponOnWeekends ? (
                  <InputError error={formik.errors.couponOnWeekends} />
                ) : null}
              </div>

              {/* Allowed Usage* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Allowed Usage*"
                  htmlFor="Allowed Usage*"
                  type="tel"
                  name="allowedUsage"
                  stateData={formik.values.allowedUsage}
                  setStateData={formik.handleChange}
                />
                {formik.errors.allowedUsage ? (
                  <InputError error={formik.errors.allowedUsage} />
                ) : null}
              </div>

              {/* Single User Limit**/}
              <div className="mb-3">
                <InputFieldLarge
                  label="Single User Limit*"
                  htmlFor="Single User Limit"
                  type="tel"
                  name="singleUserLimit"
                  stateData={formik.values.singleUserLimit}
                  setStateData={formik.handleChange}
                />
                {formik.errors.singleUserLimit ? (
                  <InputError error={formik.errors.singleUserLimit} />
                ) : null}
              </div>

              {/* description */}
              <div className="mb-3">
                <label
                  htmlFor="floatingTextarea2"
                  className="font12x500 mb-1 form-label"
                >
                  Description*
                </label>
                <textarea
                  className="form-control shadow-none"
                  id="floatingTextarea2"
                  style={{ height: "180px" }}
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
                {formik.errors.description ? (
                  <InputError error={formik.errors.description} />
                ) : null}
              </div>

              {/* Terms* */}
              <div className="mb-3">
                <label
                  htmlFor="floatingTextarea2"
                  className="font12x500 mb-1 form-label"
                >
                  Terms*
                </label>
                <textarea
                  className="form-control shadow-none"
                  id="floatingTextarea2"
                  style={{ height: "180px" }}
                  name="terms"
                  value={formik.values.terms}
                  onChange={formik.handleChange}
                />
                {formik.errors.terms ? (
                  <InputError error={formik.errors.terms} />
                ) : null}
              </div>

              <SubmitButton
                data={!state.data}
                loader={loader}
                create="Create"
                update="Update"
              />
            </form>

            {state?.data && <UpdationDetails data={state?.data} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default CouponForm;
