import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function RightSideSection(props) {
  const { editStore, gotToPayment } = props;

  const [animateFilter, setAnimateFilter] = useState({
    transform: "translate(100%)",
  });

  useEffect(() => {
    if (props.isOpenFilter) {
      setAnimateFilter({
        transform: "translate(0%)",
      });
    } else {
      setAnimateFilter({
        transform: "translate(100%)",
      });
    }
  }, [props.isOpenFilter]);

  return (
    <div style={animateFilter} className="right-side-section-container">
      <div
        onClick={() => {
          props.setIsOpenFilter(false);
        }}
        className="left-section"
      ></div>
      <div className="right-section">
        <div className="right-sub-container pt-3 pb-3">
          <div className="salon-document-edit-salon-container">
            <button className="apply" onClick={editStore}>
              Edit Store
            </button>
            <button className="apply" onClick={gotToPayment}>
              Salon Documents
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RightSideSection;
