import React, { useRef, useState } from "react";
import { CgClose } from "react-icons/cg";
import { TbFileUpload } from "react-icons/tb";
import UploadDoc from "./UploadDoc";
import InputFieldLarge from "../../Shared Components/InputFieldLarge";
import UpdationDetails from "../../Shared Components/UpdationDetails";
import { useFormik } from "formik";
import { paymentDetailsSchema } from "../../utils/validationschema";
import InputError from "../../Shared Components/InputError";
import {
  authFormHeader,
  authHeader,
  PAYMENT_DETAIL_ADD,
  PAYMENT_DETAIL_UPDATE,
  SALON_BY_ID,
  SALON_UPDATE,
} from "../../utils/Constants";
import { toast } from "react-toastify";
import axios from "axios";
import useAuthContext from "../../hooks/useAuthContext";
import SubmitButton from "../../Shared Components/SubmitButton";
import useLoading from "../../hooks/useLoading";

const PaymentForm = ({ state, setState, salonId, paymentId, apiCall }) => {
  const initialValues = {
    bankName: state?.data?.bankName ?? "",
    accountNumber: state?.data?.accountNumber ?? "",
    ifscCode: state?.data?.ifscCode ?? "",
    legalEntitiesName: state?.data?.legalEntitiesName ?? "",
    panNumber: state?.data?.panNumber ?? "",
    gstId: state?.data?.gstId ?? "",
    panImage: "",
    gstDocument: "",
    otherDocuments: "",
  };
  // const [oldImagePath, setoldImagePath] = useState({
  //   panImage: state?.data?.panImage ? state?.data?.panImage : null,
  //   gstDocument: state?.data?.gstDocument ? state?.data?.gstDocument : null,
  //   otherDocuments: state?.data?.otherDocuments
  //     ? state?.data?.otherDocuments
  //     : null,
  // });
  const [oldImagePath, setoldImagePath] = useState({
    panImage: "null",
    gstDocument: "null",
    otherDocuments: "null",
  });

  console.log("oldImagePath: ", oldImagePath);

  const formik = useFormik({
    initialValues,
    validationSchema: paymentDetailsSchema,
    onSubmit: (data) => {
      formSubmit(data);
      // console.log("data", data);
    },
  });

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const licenseDoc = useRef(null);
  const handleSingleDoc = (e) => {
    e.preventDefault();
    licenseDoc.current.click();
  };

  const [singleDoc, setSingleDoc] = useState(
    state?.data?.otherDocuments ? state?.data?.otherDocuments : ""
  );
  const imageDataURL = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSingleDoc(e.target.files[0].name);
      formik.setFieldValue("otherDocuments", Array.from(e.target.files));
      setoldImagePath({
        ...oldImagePath,
        otherDocuments: state?.data?.otherDocuments
          ? state?.data?.otherDocuments
          : null,
      });
    }
  };

  const { token, salonDetails } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();

  const formSubmit = async (data) => {
    console.log("form submit called");

    const sFormData = new FormData();
    sFormData.append("bankName", data.bankName);
    sFormData.append("panImage", data.panImage[0]);
    sFormData.append("gstDocument", data.gstDocument[0]);
    sFormData.append("otherDocuments", data.otherDocuments[0]);
    sFormData.append("accountNumber", data.accountNumber);
    sFormData.append("ifscCode", data.ifscCode);
    sFormData.append("legalEntitiesName", data.legalEntitiesName);
    sFormData.append("deliveryCharges", data.deliveryCharges);
    sFormData.append("panNumber", data.panNumber);
    sFormData.append("gstId", data.gstId);
    sFormData.append("status", 1);
    sFormData.append("isActive", true);
    if (state.data) {
      sFormData.append("updatePanImage", oldImagePath.panImage);
      sFormData.append("updateGstDocument", oldImagePath.gstDocument);
      sFormData.append("updateOtherDocuments", oldImagePath.otherDocuments);
    }

    if (token) {
      if (state.data === undefined) {
        try {
          showLoader();
          const res = await axios.post(
            `${PAYMENT_DETAIL_ADD}${salonId}/paymentDetail/form`,
            sFormData,
            {
              //headers: authFormHeader,
              headers: {
                "Content-type": "multipart/form-data",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            updateSalon();
            hideLoader();
            toast.success("Payment Details Added", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      } else {
        try {
          showLoader();
          console.log("update api called");
          const res = await axios.put(
            `${PAYMENT_DETAIL_UPDATE}${paymentId}/form`,
            sFormData,
            {
              //headers: authFormHeader,
              headers: {
                "Content-type": "multipart/form-data",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            updateSalon();
            hideLoader();
            toast.success("Payment Details Updated", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    }
  };

  const updateSalon = async () => {
    if (token) {
      try {
        showLoader();
        const res = await axios.get(`${SALON_BY_ID}${salonDetails?._id}`, {
          //headers: authHeader,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "X-Requested-With": "XMLHttpRequest",
          },
        });

        if (res.status === 200) {
          window.localStorage.setItem(
            "salon_details",
            JSON.stringify(res?.data?.salon)
          );
          hideLoader();
        } else {
          console.log("error", res.status);
        }
      } catch (error) {
        console.log("catch error: ", error);
        if (error.response.status === 502) {
          hideLoader();
          toast.error(error.response.data.message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state?.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">
              {!state.data ? "Add Payment Details" : "Edit Payment Details"}
            </h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
              {/* Account Details */}
              <div className="mb-4">
                <label
                  htmlFor="atsalon"
                  className="font16x500 form-label mb-2 labelBig"
                >
                  Account Details
                </label>

                {/* Bank Name*/}
                <div className="mb-3">
                  <InputFieldLarge
                    label="Bank Name*"
                    htmlFor="bankname"
                    type="text"
                    name="bankName"
                    stateData={formik.values.bankName}
                    setStateData={formik.handleChange}
                  />
                  {formik.errors.bankName ? (
                    <InputError error={formik.errors.bankName} />
                  ) : null}
                </div>

                {/* Account Number */}
                <div className="mb-3">
                  <InputFieldLarge
                    label="Account Number*"
                    htmlFor="accnumber"
                    type="tel"
                    name="accountNumber"
                    stateData={formik.values.accountNumber}
                    setStateData={formik.handleChange}
                  />
                  {formik.errors.accountNumber ? (
                    <InputError error={formik.errors.accountNumber} />
                  ) : null}
                </div>

                {/* IFSC Code */}
                <div className="mb-3">
                  <InputFieldLarge
                    label="IFSC Code*"
                    htmlFor="ifsc"
                    type="text"
                    name="ifscCode"
                    stateData={formik.values.ifscCode}
                    setStateData={formik.handleChange}
                  />
                  {formik.errors.ifscCode ? (
                    <InputError error={formik.errors.ifscCode} />
                  ) : null}
                </div>

                {/* Legal Entity Name */}
                <div className="mb-3">
                  <InputFieldLarge
                    label="Legal Entity Name*"
                    htmlFor="entityname"
                    type="text"
                    name="legalEntitiesName"
                    stateData={formik.values.legalEntitiesName}
                    setStateData={formik.handleChange}
                  />
                  {formik.errors.legalEntitiesName ? (
                    <InputError error={formik.errors.legalEntitiesName} />
                  ) : null}
                </div>
              </div>

              <div className="mb-3">
                <label
                  htmlFor="atsalon"
                  className="font16x500 form-label mb-2 labelBig"
                >
                  Seller Documents
                </label>

                <div className="">
                  {/* PAN Numer */}
                  <div className="mb-3">
                    <InputFieldLarge
                      label="PAN Number*"
                      htmlFor="pannumber"
                      type="text"
                      name="panNumber"
                      stateData={formik.values.panNumber}
                      setStateData={formik.handleChange}
                    />
                    {formik.errors.panNumber ? (
                      <InputError error={formik.errors.panNumber} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <UploadDoc
                      label="Upload a scan/photo of your PAN Card"
                      name="panImage"
                      state={
                        state?.data?.panImage ? state?.data?.panImage : null
                      }
                      setStateData={(e) => {
                        formik.setFieldValue("panImage", Array.from(e));
                      }}
                      imagePath={
                        state?.data?.panImage
                          ? `${process.env.REACT_APP_Image_BaseURL}${state?.data?.panImage}`
                          : ""
                      }
                      setImagePath={(e) => {
                        setoldImagePath({
                          ...oldImagePath,
                          panImage: e,
                        });
                      }}
                    />
                    {formik.errors.panImage ? (
                      <InputError error={formik.errors.panImage} />
                    ) : null}
                  </div>
                </div>

                <div className="mb-4">
                  {/* GST ID */}
                  <div className="mb-3">
                    <InputFieldLarge
                      label="GST ID*"
                      htmlFor="gstId"
                      type="text"
                      name="gstId"
                      stateData={formik.values.gstId}
                      setStateData={formik.handleChange}
                    />
                    {formik.errors.gstId ? (
                      <InputError error={formik.errors.gstId} />
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <UploadDoc
                      label="Upload a scan/photo of your GST document"
                      name="gstDocument"
                      state={
                        state?.data?.gstDocument
                          ? state?.data?.gstDocument
                          : null
                      }
                      setStateData={(e) => {
                        formik.setFieldValue("gstDocument", Array.from(e));
                      }}
                      imagePath={
                        state?.data?.gstDocument
                          ? `${process.env.REACT_APP_Image_BaseURL}${state?.data?.gstDocument}`
                          : ""
                      }
                      setImagePath={(e) => {
                        setoldImagePath({
                          ...oldImagePath,
                          gstDocument: e,
                        });
                      }}
                    />
                    {formik.errors.gstDocument ? (
                      <InputError error={formik.errors.gstDocument} />
                    ) : null}
                  </div>
                </div>

                {/* license Doc */}
                <div className="mb-3">
                  <div className="mb-0">
                    <label
                      htmlFor="otherDoc"
                      className="font16x400 form-label mb-2 labelBig"
                    >
                      Please upload any relevant license document (optional)
                    </label>
                    <input
                      ref={licenseDoc}
                      className="form-control shadow-none"
                      type="file"
                      id="formFile"
                      name="otherDocuments"
                      accept={".pdf"}
                      hidden
                      onChange={(e) => {
                        imageDataURL(e);
                      }}
                    />
                  </div>
                  <div className="uploadLicense mb-3">
                    <div
                      className="uploadBtn font14x400 me-3"
                      onClick={(e) => {
                        handleSingleDoc(e);
                      }}
                    >
                      <TbFileUpload className="me-2" />
                      Upload Document
                    </div>
                  </div>
                  {singleDoc && <p className="mb-0">{singleDoc}</p>}
                  {formik.errors.otherDocuments ? (
                    <InputError error={formik.errors.otherDocuments} />
                  ) : null}
                </div>
              </div>

              <SubmitButton
                data={!state.data}
                loader={loader}
                create="Add"
                update="Update"
              />
            </form>

            {state?.data && <UpdationDetails data={state?.data} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentForm;
