import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import productImage from "../../uploads/product/product1.jpg";

const CartItem = ({
  item,
  addItemIntoCart,
  removeItemFromCart,
  removeItem,
}) => {
  return (
    <>
      <div className="cartItemWrapper d-flex justify-content-between py-3">
        <div className="d-flex">
          {item?.product?.image === null ? (
            <img
              src={productImage}
              alt="product"
              width={96}
              height={96}
              style={{ borderRadius: 8 }}
            />
          ) : (
            <img
              src={`${process.env.REACT_APP_Image_BaseURL}${item?.product?.image}`}
              alt="product"
              width={96}
              height={96}
              style={{ borderRadius: 8 }}
            />
          )}
          <div className=" ms-3">
            <h2 className="mb-1 font16x500" title={item?.product?.name}>
              {item?.product?.name?.length > 20
                ? `${item?.product?.name?.substring(0, 20)}...`
                : `${item?.product?.name}`}
            </h2>
            <p
              className="mb-1 font12x400 lh-sm"
              title={item?.product?.description}
            >
              {item?.product?.description?.length > 90
                ? `${item?.product?.description?.substring(0, 90)}...`
                : `${item?.product?.description}`}
            </p>
            <p className="mb-0 font12x400" style={{ color: "var(--text5" }}>
              Qty: {item?.product?.quantity}
            </p>
          </div>
        </div>
        <i className="text-end">
          <span
            className="deleteBtn"
            onClick={() => {
              removeItem(item?.product?._id);
            }}
          >
            <RiDeleteBin6Line />
          </span>
        </i>
      </div>
      <div className="qtyWrapper d-flex justify-content-between py-3">
        <div className="d-flex align-items-center">
          <p className="mb-0 font12x400">Quantity:</p>
          <div>
            <p className="cartAddBtn mb-0 ms-2 font16x500 justify-content-between">
              <span
                onClick={() => {
                  removeItemFromCart(item?.product?._id, item?.count);
                }}
              >
                -
              </span>
              <span>{item?.count}</span>
              <span
                onClick={() => {
                  addItemIntoCart(
                    item?.product?._id,
                    item?.count,
                    item?.product?.currentStock
                  );
                }}
              >
                +
              </span>
            </p>
          </div>
        </div>
        <div>
          <p className="card-text mb-0 mt-2">
            <span className="font14x400">
              <s>{`₹ ${item?.product?.mrp * item?.count}`}</s>
            </span>
            <span className="font16x700 ms-2">{`₹${
              item?.product?.discountPrice * item?.count
            }`}</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default CartItem;
