import React from "react";
import { HiOutlineChevronRight } from "react-icons/hi";

const CouponSection = ({ state, setState, cart }) => {
  const functions = {
    onClick: () => {
      setState({
        ...state,
        modal: true,
        step: 2,
      });
      document.body.style.overflow = "hidden";
    },
  };

  return (
    <>
      {cart?.appliedSalonCoupon ? (
        <div className="usedCouponSectionWrapper mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <img src="/Assets/coupon.svg" alt="coupon" />
              <p className="mb-0 font16x700 ms-2">Used Coupon</p>
            </div>
            <div className="couponChevron">
              <p
                className="mb-0 font14x400"
                style={{
                  color: "var(--btn_primary)",
                  cursor: "pointer",
                }}
                onClick={functions.onClick}
              >
                Change offer
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <div className="d-flex">
              <img
                src="/Assets/greenCheck.svg"
                alt="check"
                style={{
                  marginLeft: 3,
                }}
              />
              <p className="mb-0 font14x500 ms-2">{`Code ${cart?.appliedSalonCoupon?.code} applied!`}</p>
            </div>
            <div className="couponChevron">
              <p className="mb-0 font16x500">{`- ${String.fromCharCode(
                cart?.products[0]?.product?.currencySymbol
              )}${cart?.cartCalculations?.products?.couponDiscount}`}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="couponSectionWrapper mt-3" onClick={functions.onClick}>
          <div className="d-flex">
            <img src="/Assets/coupon.svg" alt="coupon" />
            <p className="mb-0 font16x700 ms-2">Use Coupon</p>
          </div>
          <div className="couponChevron">
            <HiOutlineChevronRight />
          </div>
        </div>
      )}
    </>
  );
};

export default CouponSection;
