import React, { createContext, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isMasterSalonLogin } from "../../Functions/isLogin";
import useFetch from "../../hooks/useFetch";
import { CART_LIST } from "../Constants";

export const CartContext = createContext();

const MasterSalonPrivateRoutes = () => {
  const [cart, totalResult, error, loader, getCart] = useFetch({
    url: `${CART_LIST}`,
    resultField: "cart",
  });

  useEffect(() => {
    getCart();
  }, []);

  return isMasterSalonLogin() ? (
    <CartContext.Provider
      value={{
        cart,
        cartLoader: loader,
        getCart,
      }}
    >
      <Outlet />
    </CartContext.Provider>
  ) : (
    <Navigate to="/" />
  );
};

export default MasterSalonPrivateRoutes;
