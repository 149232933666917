import React, { useEffect, useState } from "react";
import Layout from "../../../../Components/Layout";
import { TEMPORARY_USER_SEARCH } from "../../../../utils/Constants";
import Pagination from "../../../../Components/Pagination";
import useFetch from "../../../../hooks/useFetch";
import Loader from "../../../../Components/Loader";
import SearchInput from "../../../../Shared Components/SearchInput";
import { userLinks } from "..";
import PageNav from "../../../../Components/PageNav";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileTemporaryUserFilters from "../../../../Components/Mobile/Admin/mobileTemporaryUserFilter";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";

const TemporaryUsers = () => {
  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
  });
  console.log(filters);

  const [param, setParam] = useState({
    show: false,
  });
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const addParam = () => {
    setFilter({
      ...filters,
      ...param,
    });
  };

  const resetParam = () => {
    setParam({});
    delete filters.q;
    delete filters.mobile;
    getData();
  };

  const [temporaryUser, totalResult, error, loader, getData] = useFetch({
    url: `${TEMPORARY_USER_SEARCH}`,
    resultField: "temporaryUser",
    parameter: filters,
  });

  useEffect(() => {
    window.localStorage.removeItem("salon_details");
    getData();
  }, [filters]);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Temporary Users"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileTemporaryUserFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          resetParam={resetParam}
          addParam={addParam}
          setParam={setParam}
          param={param}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Temporary Users"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Temporary Users"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper">
        <div>
          <div className="service-navigation-neworder-button-container">
            <div className="service-navigation-neworder-button d-flex justify-content-between pe-2">
              <PageNav links={userLinks} />
            </div>
            <div className="filter-container container ms-0 ps-0 mb-3 mt-4">
              <div className="row">
                <div className="col-5 d-flex align-items-end">
                  <SearchInput
                    type="text"
                    placeholder="Search by mobile"
                    stateData={param}
                    setStateData={setParam}
                    parameter1="name"
                    parameter2="mobile"
                    addParam={addParam}
                    resetParam={resetParam}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {temporaryUser?.length !== 0 ? (
          <div
            className="container dataTable ms-0 ps-0"
            // style={{
            //   width: "500px",
            // }}
          >
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">User Mobile</th>
                  <th scope="col">Country</th>
                  <th scope="col">OTP</th>
                </tr>
              </thead>
              <tbody>
                {temporaryUser?.map((user, i) => {
                  return (
                    <tr key={i}>
                      <td
                        style={{
                          width: "30%",
                        }}
                      >
                        {user?.mobile}
                      </td>
                      <td>{user?.country?.name}</td>
                      <td>{user?.otp}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Temporary Users Found"
            )}
          </div>
        )}

        {temporaryUser?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TemporaryUsers;
