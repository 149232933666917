import moment from "moment";
import React, { useEffect, useState } from "react";

const CurrentDateTime = () => {
  const [time, setTime] = useState(moment().format("hh:mm a"));

  useEffect(() => {
    setInterval(() => {
      setTime(moment().format("hh:mm a"));
    }, 1000);
  }, [time]);

  return (
    <>
      <div className="desktop">
        <p
          className="font22x400 mb-0 text-end"
          style={{ color: "var(--text3)" }}
        >
          {moment().format("DD MMMM")}
        </p>
        <p className="font22x500 text-end" style={{ color: "var(--text2)" }}>
          {time}
        </p>
      </div>

      <div className="mobile mt-3">
        <p
          className="font14x400 mb-0 text-end"
          style={{ color: "var(--text3)" }}
        >
          {moment().format("DD MMMM")}
        </p>
        <p className="font14x500 text-end" style={{ color: "var(--text2)" }}>
          {time}
        </p>
      </div>
    </>
  );
};

export default CurrentDateTime;
