import axios from "axios";
import React from "react";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import useAuthContext from "../../../hooks/useAuthContext";
import InputFieldLarge from "../../../Shared Components/InputFieldLarge";
import UpdationDetails from "../../../Shared Components/UpdationDetails";
import {
  authHeader,
  OFFER_DEAL_TYPE_ADD,
  OFFER_DEAL_TYPE_UPDATE,
} from "../../../utils/Constants";
import { useFormik } from "formik";
import { offerCategorySchema } from "../../../utils/validationschema";
import InputError from "../../../Shared Components/InputError";
import useLoading from "../../../hooks/useLoading";
import SubmitButton from "../../../Shared Components/SubmitButton";

const CategoryForm = ({ state, setState, apiCall }) => {
  const initialValues = {
    name: state?.data?.name ?? "",
    status: 1,
    order: state?.data?.order ?? 1,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: offerCategorySchema,
    onSubmit: (data) => {
      formSubmit(data);
    },
  });

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
    });
    document.body.style.overflow = "unset";
  };

  const { token } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();

  const formSubmit = async (data) => {
    console.log("form submit called");

    if (token) {
      if (state.data === undefined) {
        try {
          showLoader();
          const res = await axios.post(`${OFFER_DEAL_TYPE_ADD}`, data, {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Offer Category Added", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error?.response?.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      } else {
        try {
          showLoader();
          console.log("update api called");
          const res = await axios.put(
            `${OFFER_DEAL_TYPE_UPDATE}${state.data._id}`,
            data,
            {
              //headers: authHeader,
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );

          if (res.status === 200) {
            setPositionToBody();
            apiCall();
            hideLoader();
            toast.success("Offer Category Updated", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            console.log("else error: ", res.status);
          }
        } catch (error) {
          console.log("catch error: ", error);
          if (error.response.status === 502) {
            hideLoader();
            toast.error(error?.response?.data?.message, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }
      }
    }
  };

  return (
    <>
      <div
        className={
          state.modal ? "formWrapper formWrapper-active" : "formWrapper"
        }
      >
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">
              {!state.data ? "Add New Category" : "Edit Category"}
            </h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit}>
              {/* Category Title */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Category Title*"
                  htmlFor="title"
                  type="text"
                  name="name"
                  stateData={formik.values.name}
                  setStateData={formik.handleChange}
                />
                {formik.errors.name ? (
                  <InputError error={formik.errors.name} />
                ) : null}
              </div>

              {/* order */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Order*"
                  htmlFor="order"
                  type="tel"
                  name="order"
                  stateData={formik.values.order}
                  setStateData={formik.handleChange}
                  width="120px"
                />
                {formik.errors.order ? (
                  <InputError error={formik.errors.order} />
                ) : null}
              </div>

              <SubmitButton
                data={!state.data}
                loader={loader}
                create="Add Category"
                update="Update Category"
              />
            </form>

            {state?.data && <UpdationDetails data={state?.data} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryForm;
