import React, { useEffect, useState } from "react";
import Layout from "../../../../Components/Layout";
import { INVENTORY_LIST_AMDIN } from "../../../../utils/Constants";
import { getAddedOnDateTime } from "../../../../Functions/dateTime";
import { ToastContainer } from "react-toastify";
import useFetch from "../../../../hooks/useFetch";
import Pagination from "../../../../Components/Pagination";
import Loader from "../../../../Components/Loader";
import SearchInput2 from "../../../../Shared Components/SearchInput2";
import PageNav from "../../../../Components/PageNav";
import { productLinks } from "..";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileProductTransactionFilters from "../../../../Components/Mobile/mobileProductTransactionFilter";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";

const ProductTransactionAdmin = () => {
  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
  });
  console.log(filters);

  const [param, setParam] = useState({
    show: false,
  });
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const functions = {
    addParam: () => {
      setFilter({
        ...filters,
        ...param,
      });
    },
    resetParam: () => {
      setParam({});
      delete filters.q;
      getData();
    },
  };

  const [adminInventories, totalResult, error, loader, getData] = useFetch({
    url: `${INVENTORY_LIST_AMDIN}`,
    resultField: "adminInventories",
    parameter: filters,
  });

  useEffect(() => {
    getData();
  }, [filters]);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Product Transactions"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileProductTransactionFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          resetParam={functions.resetParam}
          param={param}
          setParam={setParam}
          addParam={functions.addParam}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Product Transaction"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Product Transaction"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper">
        <div className="service-navigation-neworder-button-container">
          <div className="service-navigation-neworder-button d-flex justify-content-between pe-2">
            <PageNav links={productLinks} />
          </div>
        </div>

        <div className="filter-container container ms-0 ps-0 mb-3">
          <div className="row">
            <div className="col-12 col-lg-5 d-flex align-items-end mt-4">
              <SearchInput2
                type="text"
                placeholder="Search by product id"
                stateData={param}
                setStateData={setParam}
                parameter1="productId"
                parameter2={undefined}
                addParam={functions.addParam}
                resetParam={functions.resetParam}
              />
            </div>
          </div>
        </div>

        {adminInventories?.length !== 0 ? (
          <div className="container dataTable ms-0 ps-0">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">SL.No.</th>
                  <th scope="col">Product Details</th>
                  <th scope="col">Added On</th>
                  <th scope="col">Stock</th>
                  <th scope="col">Status</th>
                  <th scope="col">Comment</th>
                </tr>
              </thead>
              <tbody>
                {adminInventories?.map((item, i) => {
                  return (
                    <tr key={item?._id}>
                      <td>{(filters.page - 1) * filters.per_page + (i + 1)}</td>
                      <td>
                        {item?.productId}
                        <br />
                        {item?.name}
                      </td>
                      <td>{getAddedOnDateTime(item?.createdAt)}</td>
                      <td>{item?.qty}</td>
                      <td>
                        <p
                          className="status mb-0"
                          style={{
                            backgroundColor: `${
                              item?.qtyStatus === "IN"
                                ? "var(--status_color2)"
                                : "var(--status_color1)"
                            }`,
                          }}
                        >
                          {`${item?.qtyStatus === "IN" ? "In" : "Out"}`}
                        </p>
                      </td>
                      <td>{item?.comment}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Product Transactions Found"
            )}
          </div>
        )}

        {adminInventories?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      <ToastContainer />
    </>
  );
};

export default ProductTransactionAdmin;
