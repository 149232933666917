import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "antd/dist/antd.css";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./mobile.css";
import "./responsive.css";
import "./globals.css";
import "./mobile.css";
import "./responsive.css";
import App from "./App";
import AuthContext from "./Contexts/AuthContext";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserRouter as Router } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <AuthContext>
    <Router>
      <App />
    </Router>
  </AuthContext>
  // </React.StrictMode>
);
