import React, { useState } from "react";
import InputError from "../../../Shared Components/InputError";
import InputFieldLarge from "../../../Shared Components/InputFieldLarge";
import StaticDropdown from "../../../Shared Components/StaticDropdown";
import axios from "axios";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { userWalletSchema } from "../../../utils/validationschema";
import useLoading from "../../../hooks/useLoading";
import useAuthContext from "../../../hooks/useAuthContext";
import {
  ADD_MANY_USER_WALLET,
  ADD_SINGLE_USER_WALLET,
  ADD_USER_WALLET,
  authHeader,
} from "../../../utils/Constants";
import Loader from "../../Loader";
import DynamicDropdown from "./DynamicDropdown";
import moment from "moment";
import InputDateLarge from "../../../Shared Components/InputdateLarge";

const UserWalletForm = ({ state, setState, apiCall, users }) => {
  const initialValues = {
    addTo: "",
    entryTitle: "",
    amount: 0,
    validity: "",
    entryType: "",
    isSingleUser: state?.data ? true : false,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: userWalletSchema,
    onSubmit: (data) => {
      formSubmit(data);
      // console.log("data: ", data);
    },
  });

  const setPositionToBody = () => {
    setState({
      ...state,
      modal: false,
      check: "",
      step: 0,
    });
    document.body.style.overflow = "unset";
  };

  const { token } = useAuthContext();
  const [loader, showLoader, hideLoader] = useLoading();

  const formSubmit = async (data) => {
    if (state?.data) {
    }
    if (token) {
      try {
        showLoader();
        const res = await axios.post(
          state?.data
            ? `${ADD_SINGLE_USER_WALLET}${state?.data}`
            : `${ADD_MANY_USER_WALLET}`,
          state?.data
            ? {
                entryType: data.entryType,
                amount: data.amount,
                validity: data.validity,
                entryTitle: data.entryTitle,
              }
            : {
                addTo: data.addTo,
                entryType: data.entryType,
                amount: data.amount,
                validity: data.validity,
                entryTitle: data.entryTitle,
              },
          {
            //headers: authHeader,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );

        if (res.status === 200) {
          setPositionToBody();
          apiCall();
          hideLoader();
          toast.success("Wallet Update", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          console.log("else error: ", res.status);
        }
      } catch (error) {
        console.log("catch error: ", error);
        if (error?.response?.status === 502) {
          hideLoader();
          toast.error(error?.response?.data?.message, {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    }
  };

  return (
    <>
      <div className={state ? "formWrapper formWrapper-active" : "formWrapper"}>
        <div className="formDiv" onClick={setPositionToBody}></div>
        <div className="formContainer">
          <div className="formHeadingDiv">
            <h1 className="font22x500 mb-0">Wallet</h1>
            <span onClick={setPositionToBody}>
              <CgClose />
            </span>
          </div>
          <div className="formContent">
            <form onSubmit={formik.handleSubmit}>
              {/* entry Title* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Entry Title*"
                  htmlFor="eTitle"
                  type="text"
                  name="entryTitle"
                  stateData={formik.values.entryTitle}
                  setStateData={formik.handleChange}
                />
                {formik.errors.entryTitle ? (
                  <InputError error={formik.errors.entryTitle} />
                ) : null}
              </div>

              {/* user list */}
              {!state?.data && (
                <div className="mb-3">
                  <StaticDropdown
                    data={[
                      { value: "all", label: "All users" },
                      { value: "membership", label: "All membership users" },
                    ]}
                    label="Add To*"
                    htmlFor="addTo"
                    type="text"
                    placeholder="Select User Type"
                    name="addTo"
                    stateData={formik.values.addTo}
                    setStateData={(e) => {
                      formik.setFieldValue("addTo", e);
                    }}
                  />
                  {formik.errors.addTo ? (
                    <InputError error={formik.errors.addTo} />
                  ) : null}
                </div>
              )}

              {/* amount* */}
              <div className="mb-3">
                <InputFieldLarge
                  label="Amount*"
                  htmlFor="amount"
                  type="tel"
                  name="amount"
                  stateData={formik.values.amount}
                  setStateData={formik.handleChange}
                />
                {formik.errors.amount ? (
                  <InputError error={formik.errors.amount} />
                ) : null}
              </div>

              {/* entryType */}
              <div className="mb-3">
                <StaticDropdown
                  data={[
                    { value: "CR", label: "Credit" },
                    { value: "DR", label: "Debit" },
                  ]}
                  label="Entry Type*"
                  htmlFor="entryType"
                  type="text"
                  placeholder="Select Status"
                  name="entryType"
                  stateData={formik.values.entryType}
                  setStateData={(e) => {
                    formik.setFieldValue("entryType", e);
                  }}
                />
                {formik.errors.entryType ? (
                  <InputError error={formik.errors.entryType} />
                ) : null}
              </div>

              {/* Validity* */}
              {/* <div className="mb-3">
                <InputFieldLarge
                  label="Validity (Days)*"
                  htmlFor="validity"
                  type="tel"
                  name="validity"
                  stateData={formik.values.validity}
                  setStateData={formik.handleChange}
                />
                {formik.errors.validity ? (
                  <InputError error={formik.errors.validity} />
                ) : null}
              </div> */}

              <div className="mb-3">
                <InputDateLarge
                  htmlFor="validity"
                  label="Validity*"
                  name="validity"
                  stateData={moment(formik.values.validity).format(
                    "YYYY-MM-DD"
                  )}
                  setStateData={formik.handleChange}
                  onlyDate={true}
                />
                {formik.errors.validity ? (
                  <InputError error={formik.errors.validity} />
                ) : null}
              </div>

              <button
                type="submit"
                className="btn OtpBtn shadow-none font16x500"
              >
                {loader ? (
                  <div className="d-flex justify-content-center">
                    <Loader height="25" width="25" color="#fff" />
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserWalletForm;
