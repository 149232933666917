import React from "react";
import NewReviews from "./MonitoringComponents/NewReviews";
import RatingChange from "./MonitoringComponents/RatingChange";
import TotalRatings from "./MonitoringComponents/TotalRatings";
import TotalReviews from "./MonitoringComponents/TotalReviews";

const ReviewMonitoring = () => {
  return (
    <>
      <div className="tabsWrapper px-3 pt-3">
        <TotalRatings />
        <TotalReviews />
        <RatingChange />
        <NewReviews />
      </div>
    </>
  );
};

export default ReviewMonitoring;
