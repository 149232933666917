import React from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

const RenderMap = ({ latitude, longitude, google }) => {
  return (
    <>
      <Map
        google={google}
        center={{
          lat: latitude,
          lng: longitude,
        }}
        initialCenter={{
          lat: latitude,
          lng: longitude,
        }}
        zoom={13}
        disableDefaultUI={true}
      >
        <Marker
          position={{
            lat: latitude,
            lng: longitude,
          }}
        />
      </Map>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: `${process.env.REACT_APP_API_MAP}`,
})(RenderMap);
