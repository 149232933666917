import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { RiDeleteBin6Line } from "react-icons/ri";
import Layout from "../../../../Components/Layout";
import OfferForm from "../../../../Components/Admin/Offer Form";
import useFetch from "../../../../hooks/useFetch";
import {
  OFFERS_DEALS_TYPE,
  OFFER_DEAL_DELETE,
  OFFER_DEAL_SEARCH,
} from "../../../../utils/Constants";
import SearchInput2 from "../../../../Shared Components/SearchInput2";
import DeleteModal from "../../../../Components/DeleteRecord";
import Pagination from "../../../../Components/Pagination";
import CategoryForm from "../../../../Components/Admin/Offer Form/CategoryForm";
import useCategory from "../../../../hooks/useCategory";
import DropdownSmallDynamic from "../../../../Shared Components/DropdownSmallDynamic";
import Loader from "../../../../Components/Loader";
import { marketingLinks } from "../Coupons";
import PageNav from "../../../../Components/PageNav";
import offerImage from "../../../../uploads/offerAndDeal/offerAndDeal1.jpg";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";
import MobileOfferDealFilters from "../../../../Components/Mobile/Admin/mobileOfferDealFilter";

const OfferDeals = () => {
  const [commonData, setCommonData] = useState({
    data: {},
    modal: false,
  });
  const [categoryData, setCategoryData] = useState({
    data: {},
    modal: false,
  });

  const onEdit = (item) => {
    setCommonData({
      data: item,
      modal: true,
    });
    document.body.style.overflow = "hidden";
  };
  const onAdd = () => {
    setCommonData({
      data: undefined,
      modal: true,
    });
    document.body.style.overflow = "hidden";
  };
  const onAddCategory = () => {
    setCategoryData({
      data: undefined,
      modal: true,
    });
    document.body.style.overflow = "hidden";
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [id, setId] = useState("");
  const onDelete = (id) => {
    setIsDeleteModalOpen(true);
    document.body.style.overflow = "hidden";
    setId(id);
  };

  const [category, loaderSmall, getCategory] = useCategory({
    url: OFFERS_DEALS_TYPE,
    resultField: "offerAndDealCatgeories",
  });

  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
    sort_by: "order",
    sort_order: "desc",
  });
  console.log(filters);

  const [param, setParam] = useState({ show: false });
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const addParam = () => {
    setFilter({
      ...filters,
      ...param,
    });
  };

  const resetParam = () => {
    setParam({ show: false });
    delete filters.category;
    delete filters.categoryName;
    delete filters.q;
    getData();
  };

  const [offersAndDeals, totalResult, error, loader, getData] = useFetch({
    url: `${OFFER_DEAL_SEARCH}`,
    resultField: "offersAndDeals",
    parameter: filters,
  });

  useEffect(() => {
    window.localStorage.removeItem("salon_details");
    getData();
  }, [filters]);
  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Offer and Deal"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileOfferDealFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          onClick={onAdd}
          onClick2={onAddCategory}
          resetParam={resetParam}
          filters={filters}
          setFilter={setFilter}
          category={category}
          addParam={addParam}
          param={param}
          setParam={setParam}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Offer and Deal"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Offer and Deal"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper">
        <div>
          <div className="service-navigation-neworder-button-container">
            <div className="service-navigation-neworder-button d-flex justify-content-between pe-2">
              <PageNav links={marketingLinks} />
              <div className="desktopButton">
                <button
                  className="btn addBtn shadow-none me-5"
                  onClick={onAddCategory}
                >
                  Add New Category
                </button>
                <button className="btn addBtn shadow-none" onClick={onAdd}>
                  Create New offer
                </button>
              </div>
            </div>
          </div>

          <div className="filter-container container mt-4 ms-0 ps-0 mb-3">
            <div className="row">
              <div className="col-5 d-flex align-items-end">
                <SearchInput2
                  type="text"
                  placeholder="Search by title"
                  stateData={param}
                  setStateData={setParam}
                  parameter1="title"
                  parameter2={undefined}
                  addParam={addParam}
                  resetParam={resetParam}
                />
              </div>
              <div className="col-7 d-flex justify-content-end align-items-end">
                {/* category */}
                <DropdownSmallDynamic
                  data={category}
                  loader={loaderSmall}
                  label="Category*"
                  htmlFor="category"
                  type="text"
                  placeholder="Select Category"
                  stateData={filters?.categoryName}
                  setStateData={(e, x) => {
                    if (e === "" && filters?.category) {
                      delete filters?.category;
                      setFilter({
                        ...filters,
                        categoryName: x,
                      });
                    } else {
                      setFilter({
                        ...filters,
                        category: e,
                        categoryName: x,
                      });
                    }
                  }}
                />
                <button
                  className="btn resetBtn shadow-none ms-3"
                  onClick={resetParam}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>

        {offersAndDeals?.length !== 0 ? (
          <div className="container dataTable ms-0 ps-0">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Banner</th>
                  <th scope="col">Order</th>
                  <th scope="col">Category</th>
                  <th scope="col">Coupon Code</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {offersAndDeals?.map((offer, i) => {
                  return (
                    <tr key={i}>
                      <td>{offer?.title}</td>
                      <td>
                        {offer?.image === null ? (
                          <img
                            src={offerImage}
                            alt="offer"
                            width={72}
                            height={48}
                            style={{ borderRadius: "4px" }}
                          />
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_Image_BaseURL}${offer?.image}`}
                            alt="offer"
                            width={72}
                            height={48}
                            style={{ borderRadius: "4px" }}
                          />
                        )}
                      </td>
                      <td>{offer?.order}</td>
                      <td>{offer?.category?.name}</td>
                      <td>
                        <span className="couponPink">{offer?.couponCode}</span>
                      </td>
                      <td>
                        <p
                          className="status mb-0"
                          style={{
                            backgroundColor: `${
                              offer?.isActive === true
                                ? "var(--status_color2)"
                                : "var(--status_color1)"
                            }`,
                          }}
                        >
                          {offer?.isActive === true ? "Active" : "Inactive"}
                        </p>
                      </td>
                      <td>
                        <button
                          className="btn font14x400 filterBtn shadow-none me-2"
                          onClick={() => {
                            onEdit(offer);
                          }}
                        >
                          Edit
                        </button>
                        <span
                          className="deleteBtn"
                          onClick={() => {
                            onDelete(offer?._id);
                          }}
                        >
                          <RiDeleteBin6Line />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Offers Found"
            )}
          </div>
        )}

        {offersAndDeals?.length !== 0 && (
          <div className="d-flex justify-content-center">
            <Pagination
              currentPage={filters.page}
              onPageChange={(e) => {
                setFilter({
                  ...filters,
                  page: e,
                });
              }}
              pageSize={filters.per_page}
              totalCount={Math.ceil(totalResult / filters.per_page)}
            />
          </div>
        )}
      </div>

      {/* delete modal */}
      {isDeleteModalOpen && (
        <DeleteModal
          id={id}
          apiCall={getData}
          state={isDeleteModalOpen}
          setState={setIsDeleteModalOpen}
          url={OFFER_DEAL_DELETE}
          text="Offer"
        />
      )}

      {/* coupon form add and update both */}
      {commonData.modal && (
        <OfferForm
          data={category}
          loader={loaderSmall}
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
        />
      )}

      {/* type form add and update both */}
      {categoryData.modal && (
        <CategoryForm
          state={categoryData}
          setState={setCategoryData}
          apiCall={getCategory}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default OfferDeals;
