import React, { useRef, useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";

const UploadDoc = ({
  label,
  setStateData,
  name,
  state,
  imagePath,
  setImagePath,
}) => {
  const image = useRef(null);
  const handleSingleImg = (e) => {
    e.preventDefault();
    image.current.click();
  };

  const [singleImage, setSingleImage] = useState(imagePath);
  console.log("singleImage: ", singleImage);
  const imageDataURL = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSingleImage(URL.createObjectURL(e.target.files[0]));
      setStateData(e.target.files);
      setImagePath(state);
    }
  };
  return (
    <>
      <div className="uploadDoc mt-3 mb-1 d-flex justify-content-center">
        <div className="mb-0">
          <input
            ref={image}
            className="form-control shadow-none"
            name={name}
            type="file"
            id="formFile"
            accept={".png, .jpg, .jpeg"}
            hidden
            onChange={(e) => {
              imageDataURL(e);
            }}
          />
        </div>
        <div className="uploadDocBtn text-center">
          {singleImage && (
            <img
              src={singleImage}
              alt="image"
              width={118}
              height={73}
              className="img-fluid mb-2"
            />
          )}
          <br />
          <label
            htmlFor="atsalon"
            className="font16x400 form-label mb-2 labelBig"
          >
            {label}
          </label>
          <div
            className="uploadBtn font14x400"
            onClick={(e) => {
              handleSingleImg(e);
            }}
          >
            Upload Image
            <MdOutlineFileUpload className="ms-2 mb-1" />
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadDoc;
