import React, { useState } from "react";
import { MdStarRate } from "react-icons/md";

const ratings = [1, 2, 3, 4, 5];

const Rating = ({ reviews, setReviews }) => {
  const styles = {
    conditionTrue: {
      backgroundColor: "var(--misc_color3)",
      border: "1px solid var(--btn_primary)",
      borderRadius: "8px",
      color: "var(--btn_primary)",
      padding: "0 12px",
    },
    conditionFalse: {
      backgroundColor: "#fff",
      border: "1px solid var(--misc_color4)",
      borderRadius: "8px",
      color: "var(--misc_color4)",
      padding: "0 12px",
    },
  };

  const [clickRating, setClickRating] = useState(0);

  const getRating = (item) => {
    setReviews({
      ...reviews,
      rating: item,
    });
    setClickRating(item);
  };

  return (
    <>
      {ratings.map((item, i) => {
        return (
          <button
            key={i}
            className="font14x400 d-flex align-items-center ratingBtn"
            onClick={() => {
              getRating(item);
            }}
            style={
              clickRating >= item ? styles.conditionTrue : styles.conditionFalse
            }
          >
            <span>{item}</span>
            <span className="d-flex">
              <MdStarRate />
            </span>
          </button>
        );
      })}
    </>
  );
};

export default Rating;
