import React, { useEffect, useState } from "react";
import CurrentDateTime from "../../../Components/Dashboard/CurrentDateTime";
import Layout from "../../../Components/Layout";
import MobileFilterHeader from "../../../Components/mobilefilterheader";
import MobileSalonDashboardHeader from "../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../Components/mobilesalondashboardsidenav";
import InputDate from "../../../Shared Components/InputDate";

const AdminDashboard = () => {
  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {/* {isOpenFilter ? (
        <MobileFilterHeader
          title="Dashboard"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )} */}

      {/* {isOpenFilter ? (
        <MobileByDateFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )} */}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={false}
          title="Dashboard"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper salon-dashboard">
        <div className="container ps-0 m-0 salon-dashboard-filter">
          <div className="container ms-0 ps-0 d-flex justify-content-between align-items-center mb-2">
            <h3 className="font22x500 pageHeading mb-0">Dashboard</h3>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="d-flex align-items-end">
                <div className="me-3">
                  {/* date from */}
                  <InputDate label="From" htmlFor="from" type="date" />
                </div>

                <div className="me-3">
                  {/* date to */}
                  <InputDate label="To" htmlFor="to" type="date" />
                </div>

                <button
                  className="btn filterBtn shadow-none"
                  // onClick={functions.addParam}
                >
                  Apply
                </button>
                <button
                  className="btn resetBtn shadow-none ms-3"
                  // onClick={functions.resetParam}
                >
                  Reset
                </button>
              </div>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <CurrentDateTime />
            </div>
          </div>
        </div>

        {/* <AllDataOrders
          heading1="ORDERS"
          total1={2}
          percent1="0.0"
          from1={2}
          heading2="SERVICE ORDERS"
          total2={2}
          percent2="0.0"
          from2={2}
          heading3="PRODUCT ORDERS"
          total3={2}
          percent3="0.0"
          from3={2}
          heading4="TOTAL REVENUE"
          total4={2000}
          percent4="0.0"
          from4={2000}
        /> */}

        {/* <div className="container pt-5 ps-0 m-0">
          <div className="row">
            <div className="col-6">
              <Chart heading="Order Analytics" />
            </div>
            <div className="col-6">
              <ReportOverview />
            </div>
          </div>
        </div> */}
      </div>

      {/* <MobileAdminDashboard/> */}
    </>
  );
};

export default AdminDashboard;
