import moment from "moment";
import { useEffect, useState } from "react";
import InputDate from "../../Shared Components/InputDate";
import SearchInput from "../../Shared Components/SearchInput";

function MobileByDateFilters(props) {
  const { param, setParam, addParam, resetParam, generateReport } = props;
  const [animateFilter, setAnimateFilter] = useState({
    transform: "translate(100%)",
  });

  useEffect(() => {
    if (props.isOpenFilter) {
      setAnimateFilter({
        transform: "translate(0%)",
      });
    } else {
      setAnimateFilter({
        transform: "translate(100%)",
      });
    }
  }, [props.isOpenFilter]);

  return (
    <div style={animateFilter} className="mobile-bydate-filters-container">
      <div
        onClick={() => {
          props.setIsOpenFilter(false);
        }}
        className="left-filter-section"
      ></div>
      <div className="right-filter-section">
        <div className="mt-3" onClick={generateReport}>
          <button className="apply">Generate Report</button>
        </div>

        <div className="right-filter-sub-container pt-3 pb-3">
          <p className="title">Filter</p>

          <div className="mb-4">
            <SearchInput
              type="text"
              placeholder="Search by name/mobile"
              stateData={param}
              setStateData={setParam}
              parameter1="user.name"
              parameter2="user.mobile"
              width="auto"
              addParam={addParam}
              resetParam={resetParam}
            />
          </div>

          <div className="">
            <button
              className="bydate-filter-button d-flex justify-content-between align-items-center bg-white border-0 w-100"
              style={{
                borderBottom: "1px solid var(--border)",
              }}
            >
              <p className="mb-0">By Date</p>
              <p className="mb-0">-</p>
            </button>

            <div className="right-date-filter-container">
              <div className="mb-2">
                <InputDate
                  label="From"
                  htmlFor="from"
                  type="date"
                  stateData={param?.fromDate}
                  setStateData={(e) => {
                    setParam({
                      ...param,
                      fromDate: moment(e).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </div>
              <div>
                <InputDate
                  label="To"
                  htmlFor="to"
                  type="date"
                  stateData={param?.tillDate}
                  setStateData={(e) => {
                    setParam({
                      ...param,
                      tillDate: moment(e).format("YYYY-MM-DD"),
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mt-1">
            <button className="apply" onClick={addParam}>
              Apply
            </button>
            <button className="reset" onClick={resetParam}>
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileByDateFilters;
