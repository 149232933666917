import React from "react";

const SelectOne = (props) => {
  const {
    label,
    htmlFor1,
    htmlFor2,
    name,
    text1,
    text2,
    stateData,
    setStateData,
  } = props;

  return (
    <>
      <div className="mb-3">
        <label
          htmlFor="floatingTextarea2"
          className="font16x500 mb-2 form-label labelBig"
        >
          {label}
        </label>
        <div className="container-fluid px-0">
          <div className="row">
            <div className="radioCustom col-6 d-flex align-items-center">
              <input
                type="radio"
                id={htmlFor1}
                name={name}
                onChange={(e) => {
                  setStateData(true, false);
                }}
                checked={stateData.isService && true}
              />
              <label className="font14x400 form-check-label" htmlFor={htmlFor1}>
                {text1}
              </label>
            </div>
            <div className="radioCustom col-6 d-flex align-items-center">
              <input
                type="radio"
                id={htmlFor2}
                name={name}
                onChange={(e) => {
                  setStateData(false, true);
                }}
                checked={stateData.isPackage && true}
              />
              <label className="font14x400 form-check-label" htmlFor={htmlFor2}>
                {text2}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectOne;
