import moment from "moment";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import Layout from "../../../../Components/Layout";
import Loader from "../../../../Components/Loader";
import MobileBlockSlotFilters from "../../../../Components/Mobile/mobileBlockSlotFilter";
import MobileStylistFilters from "../../../../Components/Mobile/mobileStylistFilter";
import MobileFilterHeader from "../../../../Components/mobilefilterheader";
import MobileSalonDashboardHeader from "../../../../Components/mobilesalondashboardheader";
import MobileSalonDashboardSideNav from "../../../../Components/mobilesalondashboardsidenav";
import MobileSalonDashboardSideNavHeader from "../../../../Components/mobilesalondashboardsidenav/mobilesalondashboardsidenavheader";
import SlotDisableForm from "../../../../Components/SlotDisableForm/BlockSlots";
import UnblockSlotForm from "../../../../Components/SlotDisableForm/UnblockSlots";
import useFetch from "../../../../hooks/useFetch";
import DropdownSmall from "../../../../Shared Components/DropdownSmall";
import { BLOCK_SLOT_LIST_BY_SALONID } from "../../../../utils/Constants";

const DisableSlot = () => {
  const [commonData, setCommonData] = useState({
    data: {},
    modal: false,
    check: "",
  });
  const [blockSlots, setBlockSlots] = useState([]);

  const [isOpenSideNav, setIsOpenSideNav] = useState(false);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [filterDates, setFilterDates] = useState([]);
  console.log("filterDates", filterDates);

  const salonNew = JSON.parse(localStorage.getItem("salon_details"));

  const [filters, setFilter] = useState({
    page: 1,
    per_page: 10,
    salon: salonNew?._id && salonNew?._id,
    fromDate: moment().format("YYYY-MM-DD"),
    tillDate: moment().add(6, "days").format("YYYY-MM-DD"),
    "slots.status": 0,
    sort_by: "date",
    sort_order: "desc",
  });
  // console.log(filters);

  const [slotv2, totalResult, error, loader, getData] = useFetch({
    url: `${BLOCK_SLOT_LIST_BY_SALONID}`,
    resultField: "slotv2",
    parameter: filters,
  });

  const functions = {
    onDisable: () => {
      setCommonData({
        data: undefined,
        modal: true,
        check: "block",
      });
      document.body.style.overflow = "hidden";
    },
    resetParam: () => {
      delete filters.date;
      filters.fromDate = moment().format("YYYY-MM-DD");
      filters.tillDate = moment().add(6, "days").format("YYYY-MM-DD");
      getData();
    },
    getFilteredBlockSlots: () => {
      const xyz = slotv2?.map((item, i) => {
        return {
          day: item?.date,
          slots: item?.slots
            .filter((item2) => item2.status === 0)
            .map((item) => {
              return item?.time;
            }),
          reason: item?.reason,
        };
      });
      setBlockSlots(xyz);
    },
    onEdit: (item) => {
      setCommonData({
        data: item,
        modal: true,
        check: "unblock",
      });
      document.body.style.overflow = "hidden";
    },
  };

  useEffect(() => {
    getData();
  }, [filters]);
  useEffect(() => {
    functions.getFilteredBlockSlots();
  }, [slotv2]);
  useEffect(() => {
    [...Array(7)]?.map((v, i) => {
      return setFilterDates((old) => {
        return [
          ...old,
          {
            value: moment().add(i, "days").format("YYYY-MM-DD"),
            label: moment().add(i, "days").format("DD MMM YYYY"),
          },
        ];
      });
    });
  }, []);

  useEffect(() => {
    if (isOpenSideNav || isOpenFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpenSideNav, isOpenFilter]);

  return (
    <>
      {isOpenFilter ? (
        <MobileFilterHeader
          title="Blocked Slots"
          setIsOpenSideNav={setIsOpenSideNav}
          setIsOpenFilter={setIsOpenFilter}
        />
      ) : (
        ""
      )}

      {isOpenFilter ? (
        <MobileBlockSlotFilters
          isOpenFilter={isOpenFilter}
          setIsOpenFilter={setIsOpenFilter}
          data={filterDates}
          onClick={() => {
            functions.onDisable();
          }}
          filters={filters}
          setFilter={setFilter}
          resetParam={functions.resetParam}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNavHeader
          title="Blocked Slots"
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenSideNav ? (
        <MobileSalonDashboardSideNav
          isOpenSideNav={isOpenSideNav}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      ) : (
        ""
      )}

      {isOpenFilter || isOpenSideNav ? (
        ""
      ) : (
        <MobileSalonDashboardHeader
          isShowThreeDot={true}
          title="Blocked Slots"
          setIsOpenFilter={setIsOpenFilter}
          setIsOpenSideNav={setIsOpenSideNav}
        />
      )}

      <div className="desktop-salon-nav-header">
        <Layout />
      </div>
      <div className="mainWrapper clients-page">
        <div className="container ms-0 ps-0 d-flex justify-content-between align-items-center mb-4">
          <h3 className="font22x500 pageHeading mb-0">Blocked Slots</h3>
          <div className="desktopButton">
            <button
              className="btn addBtn shadow-none"
              onClick={functions.onDisable}
            >
              Block Slot
            </button>
          </div>
        </div>

        <div className="filter-container container mt-4 ms-0 ps-0 mb-3">
          <div className="row">
            <div className="col-12 col-lg-5 d-flex align-items-end">
              {/* date */}
              <DropdownSmall
                data={filterDates}
                label="Choose Date"
                htmlFor="date"
                type="text"
                stateData={filters?.date}
                setStateData={(e) => {
                  delete filters.fromDate;
                  delete filters.tillDate;
                  setFilter({
                    ...filters,
                    date: e,
                  });
                }}
              />

              <button
                className="btn resetBtn shadow-none ms-3"
                onClick={functions.resetParam}
              >
                Reset
              </button>
            </div>
          </div>
        </div>

        {blockSlots?.length !== 0 ? (
          <div className="container dataTable ms-0 ps-0">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">SL.No.</th>
                  <th scope="col">Day</th>
                  <th scope="col">Slots Timing</th>
                  <th scope="col">Reason</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {blockSlots?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{(filters.page - 1) * filters.per_page + (i + 1)}</td>
                      <td>{moment(item?.day).format("ddd, DD MMM YYYY")}</td>
                      <td>
                        {item?.slots?.map((timing, idx) => {
                          return (
                            <p key={idx} className="mb-1">
                              {timing}
                            </p>
                          );
                        })}
                      </td>
                      <td>{item?.reason}</td>
                      <td>
                        <button
                          className="btn font14x400 filterBtn shadow-none me-2"
                          onClick={() => {
                            functions.onEdit(item);
                          }}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "50vh" }}
          >
            {loader ? (
              <Loader height="40" width="40" color="#772286" />
            ) : (
              "No Blocked Slots Found"
            )}
          </div>
        )}
      </div>

      {/* block slot form */}
      {commonData.modal && commonData.check === "block" && (
        <SlotDisableForm
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
        />
      )}

      {/* unblock slot form */}
      {commonData.modal && commonData.check === "unblock" && (
        <UnblockSlotForm
          state={commonData}
          setState={setCommonData}
          apiCall={getData}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default DisableSlot;
