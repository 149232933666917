function MobileFilterHeader(props) {
  return (
    <div className="mobile-filter-header align-items-center justify-content-between p-3">
      <div>
        <button
          onClick={() => {
            props.setIsOpenFilter(false);
          }}
          className="bg-white border-0"
        >
          <img src="/Assets/hamberger.svg" />
        </button>
      </div>
      <div>
        <p className="mb-0">{props.title}</p>
      </div>

      <div className="search-filter-container d-flex">
        <button
          onClick={() => {
            props.setIsOpenFilter(false);
          }}
        >
          <img src="/Assets/cross.svg" />
        </button>
      </div>
    </div>
  );
}

export default MobileFilterHeader;
