import React from "react";
import { Link, useLocation } from "react-router-dom";

const PageNav = ({ links }) => {
  const location = useLocation();

  return (
    <>
      <div className="pageNavContainer">
        <div className="d-flex">
          {links?.map((itm, i) => {
            return (
              <Link to={itm?.link} key={i}>
                <p
                  className={`mb-0 ${
                    location.pathname === itm?.link && "pageNavLinkActive"
                  }`}
                >
                  {itm?.label}
                </p>
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PageNav;
